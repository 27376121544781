import { QueryClient } from "@tanstack/react-query";

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000, // 1 minute
    staleTime: 60 * 1000 * 10 // 10 minutes
    // refetchInterval: 60 * 1000,
    // refetchIntervalInBackground: true
  }
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
