import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const createSettlement = ({ churchId, expenseId, settlementData }) => {
  return axios.post(
    `/churches/${churchId}/expenses/${expenseId}/settlements`,
    settlementData
  );
};

export const useCreateSettlement = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateAdvanceSettlementModal = useExpenseStore(
    state => state.closeCreateAdvanceSettlementModal
  );

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("settlements");

      setNotification({
        type: "success",
        message: "Settlement created successfully!"
      });

      closeCreateAdvanceSettlementModal();
    },
    ...config,
    mutationFn: createSettlement
  });
};
