import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getTemplates = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/message-templates`);
};

export const useTemplates = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["templates"],
    queryFn: () => getTemplates({ churchId })
  });
};
