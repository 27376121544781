import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getNegaritPorts = ({ API_KEY }) => {
  return axios.get(
    `https://api.negarit.net/api/api_request/sms_ports?API_KEY=${API_KEY}`
  );
};

export const useNegaritPorts = ({ API_KEY, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["negarit-ports"],
    queryFn: () => getNegaritPorts({ API_KEY })
  });
};
