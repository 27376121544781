import styled from "@emotion/styled";
import { Coins } from "@phosphor-icons/react";
import { ActionCard, TransactionsTable } from "../components";
import { useExpenseStore, useRevenueStore } from "../store";

const ActionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  grid-area: actions;
`;

export const Overview = () => {
  const openRecordRevenueModal = useRevenueStore(
    state => state.openRecordRevenueModal
  );
  const openRecordExpenseModal = useExpenseStore(
    state => state.openRecordExpenseModal
  );

  return (
    <>
      <ActionGrid>
        <ActionCard
          name="Record Revenue"
          icon={<Coins size={38} />}
          onAction={openRecordRevenueModal}
        />
        <ActionCard
          name="Record Expense"
          icon={<Coins size={38} />}
          onAction={openRecordExpenseModal}
        />
        <ActionCard name="Record Payroll" icon={<Coins size={38} />} />
      </ActionGrid>
      <TransactionsTable />
    </>
  );
};
