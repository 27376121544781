import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useChurchStore } from "../store";

export const deleteChurch = ({ churchId }) => {
  return axios.delete(`/churches/${churchId}`);
};

export const useDeleteChurch = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const selectChurch = useChurchStore.getState().selectChurch;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["churches"]);
      setNotification({
        type: "success",
        message: "Church deleted successfully!"
      });
      selectChurch(null);
      navigate("/churches");
      closeDeleteModal();
    },
    ...config,
    mutationFn: deleteChurch
  });
};
