import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getReconciles = ({ churchId }) => {
  return axios.get(
    `/churches/${churchId}/revenue?filters[0][column]=revenue_method_id&filters[0][value]=2&filters[0][operator]=eq&filters[0][column]=is_reconciled&filters[0][value]=0&filters[0][operator]=eq`
  );
};

export const useReconciles = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["reconciles", churchId],
    queryFn: () => getReconciles({ churchId })
  });
};
