import styled from "@emotion/styled";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Stack
} from "@mui/material";
import { useChurchStore } from "features/churches";
import { useTranslation } from "react-i18next";
import { BsChatText } from "react-icons/bs";
import { FixedSizeList } from "react-window";
import { useTemplates } from "../api";
import { useMessagesStore } from "../store";

const Icon = styled.div`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};

  & > * {
    width: 24px;
    height: 24px;
  }
`;

export function TemplateSelectorModal() {
  const selectTemplateModalOpen = useMessagesStore(
    state => state.selectTemplateModalOpen
  );
  const closeSelectTemplateModal = useMessagesStore(
    state => state.closeSelectTemplateModal
  );
  const setMessage = useMessagesStore(state => state.setMessage);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const templatesQuery = useTemplates({
    churchId: selectedChurch?.id
  });
  const { t } = useTranslation();

  return (
    <Modal open={selectTemplateModalOpen} onClose={closeSelectTemplateModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("message.newMessage.SelectTemplate")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
        >
          <FixedSizeList
            height={400}
            itemSize={60}
            itemData={templatesQuery?.data?.result || []}
            itemCount={templatesQuery?.data?.result?.length || 0}
            overscanCount={5}
          >
            {props => {
              const { data, index, style } = props;

              return (
                <ListItem style={style} key={index} sx={{ p: 0 }}>
                  <Icon>
                    <BsChatText />
                  </Icon>
                  <ListItemButton
                    onClick={() => {
                      setMessage(data[index]["message"]);
                      closeSelectTemplateModal();
                    }}
                  >
                    <ListItemText
                      primary={`${data[index]["name"]}`}
                      secondary={`${data[index]["message"]}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }}
          </FixedSizeList>
        </Stack>
      </Box>
    </Modal>
  );
}
