import { Grid, Typography } from "@mui/material";
import { PhoneInputField, Select, TextField } from "components/UI";
import { ADDRESSES, useMembersStore } from "features/members";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCities, useCountries, useRegions, useZones } from "../../api";

export const EmergencyContactSection = ({
  register,
  errors,
  watch,
  control,
  setValue,
  isEditMode
}) => {
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const { t } = useTranslation();

  const countriesQuery = useCountries();
  const regionsQuery = useRegions({
    countryId: watch("emergency_contact_country_id"),
    config: {
      enabled: !!watch("emergency_contact_country_id")
    }
  });
  const zonesQuery = useZones({
    regionId: watch("emergency_contact_region_id"),
    config: {
      enabled: !!watch("emergency_contact_region_id")
    }
  });
  const citiesQuery = useCities({
    zoneId: watch("emergency_contact_zone_id"),
    config: {
      enabled: !!watch("emergency_contact_zone_id")
    }
  });

  useEffect(() => {
    if (countriesQuery.data) {
      if (isEditMode) {
        setValue(
          "emergency_contact_country_id",
          fullMemberData?.emergency_contact_country_id
        );
      } else {
        setValue("emergency_contact_country_id", "66");
      }
    }
  }, [countriesQuery.data, isEditMode]);

  useEffect(() => {
    if (regionsQuery.data && isEditMode) {
      setValue(
        "emergency_contact_region_id",
        fullMemberData?.emergency_contact_region_id
      );
    }
  }, [regionsQuery.data, isEditMode]);

  useEffect(() => {
    if (zonesQuery.data && isEditMode) {
      setValue(
        "emergency_contact_zone_id",
        fullMemberData?.emergency_contact_zone_id
      );
    }
  }, [zonesQuery.data, isEditMode]);

  useEffect(() => {
    if (citiesQuery.data && isEditMode) {
      setValue(
        "emergency_contact_city_id",
        fullMemberData?.emergency_contact_city_id
      );
    }
  }, [citiesQuery.data, isEditMode]);

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step1.EmergencyContact")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.firstName")}
            name="emergency_contact_first_name"
            error={!!errors.emergency_contact_first_name?.message}
            helperText={errors.emergency_contact_first_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.fatherName")}
            name="emergency_contact_father_name"
            error={!!errors.emergency_contact_father_name?.message}
            helperText={errors.emergency_contact_father_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.grandFatherName")}
            name="emergency_contact_last_name"
            error={!!errors.emergency_contact_last_name?.message}
            helperText={errors.emergency_contact_last_name?.message}
            register={register}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="emergency_contact_phone"
            control={control}
            render={({ field }) => (
              <PhoneInputField
                label={t("form.common.phone")}
                variant="filled"
                error={!!errors.emergency_contact_phone?.message}
                helperText={errors.emergency_contact_phone?.message}
                {...field}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("addMembers.step1.Relationship")}
            name="emergency_contact_relationship"
            error={!!errors.emergency_contact_relationship?.message}
            helperText={errors.emergency_contact_relationship?.message}
            register={register}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.country")}
            name="emergency_contact_country_id"
            options={
              countriesQuery.data?.result?.map(country => ({
                optionLabel: country?.name,
                value: country?.id
              })) || []
            }
            register={register}
            error={!!errors.emergency_contact_country_id?.message}
            helperText={errors.emergency_contact_country_id?.message}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.region")}
            name={`emergency_contact_region_id`}
            options={
              regionsQuery.data?.result?.map(region => ({
                optionLabel: region?.name,
                value: region?.id
              })) || []
            }
            register={register}
            error={!!errors.emergency_contact_region_id?.message}
            helperText={errors.emergency_contact_region_id?.message}
            onChange={e => {
              setValue("emergency_contact_region_id", e.target.value, {
                shouldValidate: true
              });

              if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                setValue(
                  "emergency_contact_zone_id",
                  ADDRESSES.ADDIS_ABABA.ZONE_ID,
                  {
                    shouldValidate: true
                  }
                );
                setValue(
                  "emergency_contact_city_id",
                  ADDRESSES.ADDIS_ABABA.CITY_ID,
                  {
                    shouldValidate: true
                  }
                );
              } else {
                setValue("emergency_contact_zone_id", "", {
                  shouldValidate: true
                });
                setValue("emergency_contact_city_id", "", {
                  shouldValidate: true
                });
              }
            }}
          />
        </Grid>
        {watch("emergency_contact_region_id") !==
          ADDRESSES.ADDIS_ABABA.REGION_ID && (
          <>
            <Grid item xs={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.zone")}
                name={`emergency_contact_zone_id`}
                options={
                  zonesQuery.data?.result?.map(zone => ({
                    optionLabel: zone?.name,
                    value: zone?.id
                  })) || []
                }
                register={register}
                error={!!errors.emergency_contact_zone_id?.message}
                helperText={errors.emergency_contact_zone_id?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.city")}
                name={`emergency_contact_city_id`}
                options={
                  citiesQuery.data?.result?.map(city => ({
                    optionLabel: city?.name,
                    value: city?.id
                  })) || []
                }
                register={register}
                error={!!errors.emergency_contact_city_id?.message}
                helperText={errors.emergency_contact_city_id?.message}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
