import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useConfirmReconcileCheque, useRevenueStore } from "features/finances";
import { useState } from "react";
import { BaseModal } from "./BaseModal";

const SmallText = styled.span`
  color: #8e8e8e;
  font-size: 10px;
  font-weight: 500;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

export function ChequeReconcileModal({ data }) {
  const chequeReconcileOpen = useRevenueStore(
    state => state.chequeReconcileOpen
  );
  const closeChequeReconcileModal = useRevenueStore(
    state => state.closeChequeReconcileModal
  );
  const [method, setMethod] = useState("cheque");
  const [receiptNumber, setReceiptNumber] = useState("");
  const confirmReconcileMutation = useConfirmReconcileCheque();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const approveCheque = () => {
    confirmReconcileMutation.mutate({
      churchId: selectedChurch?.id,
      chequeId: data?.id,
      chequeData: {
        receipt_number: receiptNumber
      }
    });
  };

  return (
    <BaseModal
      title="Cheque Reconcile"
      isOpen={chequeReconcileOpen}
      onClose={closeChequeReconcileModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <div />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              flavor="white"
              size="small"
              onClick={closeChequeReconcileModal}
            >
              Cancel
            </Button>
            <Button size="small" onClick={approveCheque}>
              Approve Check
            </Button>
          </Stack>
        </Stack>
      }
    >
      <SmallText>Transaction Number {data?.transaction_number}</SmallText>
      <Field>
        <Label htmlFor="fullName">Full Name</Label>
        <Input
          id="fullName"
          defaultValue={
            data?.full_name ||
            `${data?.member?.first_name} ${data?.member?.last_name}`
          }
          disabled
        />
      </Field>
      <Field>
        <Label htmlFor="fullName">Revenue type or reason</Label>
        <Select id="fullName" disabled>
          <option>Tithe</option>
          <option>Gift</option>
          <option>Project Contribution</option>
          <option>Offering</option>
          <option>First Fruit</option>
          <option>Service Fee</option>
          <option>Sales</option>
          <option>Event Registration</option>
          <option>Other</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="fullName">Account</Label>
        <Input id="fullName" disabled />
      </Field>
      <Field>
        <Label htmlFor="fullName">Description</Label>
        <Input id="fullName" disabled />
      </Field>
      <Field>
        <Label htmlFor="fullName">Method</Label>
        <Select
          id="fullName"
          value={method}
          onChange={e => setMethod(e.target.value)}
          disabled
        >
          <option value="cash">Cash</option>
          <option value="cheque">Cheque</option>
          <option value="bankTransfer">Bank Transfer</option>
          <option value="mobileTransfer">Mobile Transfer</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="fullName">Check Number</Label>
        <Input id="fullName" disabled />
      </Field>
      <Field>
        <Label htmlFor="fullName">Bank</Label>
        <Input id="fullName" disabled />
      </Field>
      <Field>
        <Label htmlFor="fullName">Account Holder Full Name</Label>
        <Input id="fullName" disabled />
      </Field>
      <Field>
        <Label htmlFor="fullName">Account Number</Label>
        <Input id="fullName" disabled />
      </Field>
      <Field>
        <Label htmlFor="fullName">Phone Number</Label>
        <Input id="fullName" disabled />
      </Field>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="fullName">Amount</Label>
          <Input id="fullName" disabled />
        </Field>
        <Field>
          <Label htmlFor="fullName">Currency</Label>
          <Select id="fullName" disabled>
            <option>ETB</option>
          </Select>
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="fullName">Deposit receipt number</Label>
        <Input
          id="fullName"
          value={receiptNumber}
          onChange={e => setReceiptNumber(e.target.value)}
        />
      </Field>
      <Field>
        <Label htmlFor="fullName">Received date</Label>
        <Input id="fullName" type="date" disabled />
      </Field>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="fullName">Due date</Label>
          <Input id="fullName" type="date" disabled />
        </Field>
        <Field>
          <Label htmlFor="fullName">Cheque Received date</Label>
          <Input id="fullName" type="date" disabled />
        </Field>
      </Stack>
    </BaseModal>
  );
}
