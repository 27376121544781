import moment from "moment";
import { EthiopianDateUtil } from "mui-ethiopian-datepicker";

export const getEthiopianDate = (date, delimiter = "-") => {
  const ethiopianDate = `${
    EthiopianDateUtil.toEth(moment(date).toDate()).Day
  }${delimiter}${
    EthiopianDateUtil.toEth(moment(date).toDate()).Month
  }${delimiter}${EthiopianDateUtil.toEth(moment(date).toDate()).Year}`;

  return ethiopianDate;
};
