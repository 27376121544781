import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const deleteTeamMember = ({ churchId, teamId, memberId }) => {
  return axios.delete(
    `/churches/${churchId}/teams/${teamId}/members/${memberId}`
  );
};

export const useDeleteTeamMember = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["team_members"]);
      queryClient.invalidateQueries(["previous_team_members"]);
      setNotification({
        type: "success",
        message: "Team member deleted successfully!"
      });
      closeDeleteModal();
    },
    ...config,
    mutationFn: deleteTeamMember
  });
};
