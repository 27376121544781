import { Stack, Typography } from "@mui/material";
import { Button, TextField } from "components/UI";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export const ComplianceForm = ({
  register,
  errors,
  documents,
  setDocuments
}) => {
  const hiddenLetterFileInput = useRef(null);

  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <TextField
        label={t("Asset.AssetRegistration.Maintenance Schedule")}
        name="maintenance_schedule"
        error={!!errors.maintenance_schedule?.message}
        helperText={errors.maintenance_schedule?.message}
        register={register}
        type="date"
      />
      <TextField
        label={t("Asset.AssetRegistration.Last Maintenance Date")}
        name="last_maintenance_date"
        error={!!errors.last_maintenance_date?.message}
        helperText={errors.last_maintenance_date?.message}
        register={register}
        type="date"
      />
      <TextField
        label={t("Asset.AssetRegistration.Warranty Expiry Date")}
        name="warranty_expiry_date"
        error={!!errors.warranty_expiry_date?.message}
        helperText={errors.warranty_expiry_date?.message}
        register={register}
        type="date"
      />
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <Stack alignItems="flex-start">
          <input
            accept="image/*, .doc, .docx, .pdf"
            type="file"
            ref={hiddenLetterFileInput}
            onChange={event => {
              const files = event.target.files;
              setDocuments(Array.from(files));
            }}
            hidden
          />
          <Typography variant="subtitle2">
            {t("Asset.AssetRegistration.Document Upload")}
          </Typography>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              hiddenLetterFileInput.current.click();
            }}
          >
            {t("Asset.AssetRegistration.Upload Document")}
          </Button>
        </Stack>
        {documents.map(document => (
          <span key={document?.name}>{document?.name}</span>
        ))}
      </Stack>
      <TextField
        label={t("Asset.AssetRegistration.Compliance Status")}
        name="compliance_status"
        error={!!errors.compliance_status?.message}
        helperText={errors.compliance_status?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Notes")}
        name="notes"
        error={!!errors.notes?.message}
        helperText={errors.notes?.message}
        register={register}
      />
    </Stack>
  );
};
