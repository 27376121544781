import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormHelperText, Link, Stack, Typography } from "@mui/material";
import {
  Button,
  Checkbox,
  ErrorMessage,
  LanguageSelector,
  Notification,
  PhoneInputField,
  TextField
} from "components/UI";
import i18next from "i18next";
import _ from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { createAccountWithEmailAndPassword } from "../api";
import { createAccountSchema } from "../validation";

export function CreateAccount() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [serverErrors, setServerErrors] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentLanguage = i18next.language;

  const errorAliases = {
    en: {
      email: ["The email has already been taken"],
      phone: ["The phone has already been taken"]
    },
    am: {
      email: ["ኢሜይሉ አስቀድሞ ተወስዷል"],
      phone: ["ስልኩ አስቀድሞ ተወስዷል"]
    }
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: searchParams.get("email") || "",
      phone: "",
      password: "",
      confirmPassword: "",
      acceptTerms: false
    },
    resolver: yupResolver(createAccountSchema)
  });

  const onSubmit = async values => {
    setLoading(true);
    setErrorMessage("");
    setServerErrors(null);

    const credentials = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      password: values.password,
      ...(searchParams.get("account_id") && {
        account_id: searchParams.get("account_id")
      })
    };

    try {
      const data = await createAccountWithEmailAndPassword(credentials);

      if (data.status) {
        setLoading(false);

        navigate(`/auth/verification?email=${credentials.email}`);
      }
    } catch (error) {
      setLoading(false);

      setErrorMessage(error.message);

      setServerErrors(error.errors);
    }
  };

  return (
    <Box
      sx={{ my: "auto" }}
      px={{ xs: 4, md: 6, lg: 12, xl: 22 }}
      py={{ lg: 4 }}
    >
      <Notification />
      <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
        {t("auth.createAccount.createAccount")}
      </Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 4 }}
        spacing={1}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            label={t("auth.createAccount.firstName")}
            name="firstName"
            error={!!errors.firstName?.message}
            helperText={t(errors.firstName?.message)}
            register={register}
            autoComplete="off"
          />
          <TextField
            label={t("auth.createAccount.lastName")}
            name="lastName"
            error={
              !!errors.lastName?.message || !_.isEmpty(serverErrors?.email)
            }
            helperText={t(errors.lastName?.message)}
            register={register}
            autoComplete="off"
          />
        </Stack>
        <TextField
          label={t("auth.createAccount.emailAddress")}
          name="email"
          error={!!errors.email?.message || !_.isEmpty(serverErrors?.email)}
          helperText={
            t(errors.email?.message) || (
              <ErrorMessage
                messages={
                  serverErrors?.email?.length > 0
                    ? errorAliases[currentLanguage]["email"]
                    : []
                }
              />
            )
          }
          register={register}
          autoComplete="email"
          disabled={searchParams.get("email")}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <PhoneInputField
              label={t("auth.createAccount.phoneNumber")}
              variant="filled"
              error={!!errors.phone?.message || !_.isEmpty(serverErrors?.phone)}
              helperText={
                t(errors.phone?.message) || (
                  <ErrorMessage
                    messages={
                      serverErrors?.phone?.length > 0
                        ? errorAliases[currentLanguage]["phone"]
                        : []
                    }
                  />
                )
              }
              {...field}
            />
          )}
        />
        <TextField
          label={t("auth.createAccount.newPassword")}
          name="password"
          type="password"
          error={!!errors.password?.message}
          helperText={t(errors.password?.message)}
          register={register}
        />
        <TextField
          label={t("auth.createAccount.confirmPassword")}
          name="confirmPassword"
          type="password"
          error={!!errors.confirmPassword?.message}
          helperText={t(errors.confirmPassword?.message)}
          register={register}
        />
        <Stack alignItems="center">
          <Checkbox
            name="acceptTerms"
            buttons={[
              {
                checkboxLabel: `
                ${t("auth.createAccount.agree")}
                <a href="#">
                ${t("auth.createAccount.termsAndConditions")}
                </a>`,
                value: true,
                id: "acceptTerms"
              }
            ]}
            error={!!errors.acceptTerms?.message}
            helperText={t(errors.acceptTerms?.message)}
            register={register}
          />
        </Stack>
        <Button
          type="submit"
          size="large"
          fullWidth
          flavor="primary"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {t("auth.createAccount.createAccount")}
        </Button>
        {Boolean(errorMessage) && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
        <Typography variant="body1">
          {t("auth.createAccount.doHaveAccount")}{" "}
          <Link component={RouterLink} to="/auth/sign-in" color="inherit">
            {t("auth.createAccount.signIn")}
          </Link>
        </Typography>
        {/* <Divider sx={{ mt: 4, mb: 2 }}>Or</Divider> */}
        {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <SocialButton icon={<FcGoogle />}>
            {t("auth.common.signInWithGoogle")}
          </SocialButton>
          <SocialButton icon={<BsFacebook color="#039BE5" />}>
            {t("auth.common.signInWithFacebook")}
          </SocialButton>
        </Stack> */}
      </Stack>
      <Stack alignItems="center" mt={4}>
        <LanguageSelector />
      </Stack>
    </Box>
  );
}
