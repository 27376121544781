import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MicrosoftTeamsLogo } from "@phosphor-icons/react";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useChurchTeams, useTeamAnalytics } from "../api";
import { SectionBar } from "./SectionBar";
import { PieChart } from "./charts/PieChart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 22px;
`;

const SectionTitle = styled.h4`
  margin: 0;
`;

const FlatCard = styled.div`
  background: ${({ color }) => color};
  border-radius: 8px;
  padding: 44px 22px;
  flex: 1;
`;

const EngagementFlatCard = styled.div`
  background: #f3f4f6;
  border-radius: 6px;
  flex: 1;
  border-left: 22px solid ${({ color }) => color};
  padding: 25px 17px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendIndicator = styled.div`
  width: 24px;
  height: 24px;
  background: ${({ color }) => color};
`;

export const TeamsView = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  const columns = [
    {
      field: "name",
      headerName: t("dashboard.team.TeamName"),
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{row.name}</Typography>
          </Stack>
        );
      }
    },
    {
      field: "parent",
      headerName: t("dashboard.team.ParentTeam"),
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.parent?.name}</Typography>;
      }
    },
    {
      field: "members",
      headerName: t("dashboard.team.NumberofTeamMembers"),
      flex: 2,
      renderCell: ({ row }) => {
        return <span>{row.members_count}</span>;
      }
    },
    {
      field: "created_at",
      headerName: t("dashboard.team.CreatedDate"),
      flex: 1,
      renderCell: ({ row }) => {
        return <span>{moment(row.created_at).format("DD-MM-YYYY")}</span>;
      }
    }
  ];

  const teamAnalyticsQuery = useTeamAnalytics({
    churchId: selectedChurch?.id
  });

  const churchTeamsQuery = useChurchTeams({
    churchId: selectedChurch?.id
  });

  return (
    <Container>
      <SectionBar
        icon={<MicrosoftTeamsLogo size={24} />}
        title={t("dashboard.team.TeamsData")}
        color="#65A6A0"
      />

      <Section>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={3}>
            <SectionTitle>{t("dashboard.team.TotalNumberofTeam")}</SectionTitle>
            <FlatCard color="#65A6A0">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ minWidth: "300px" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: "white" }}
                >
                  {t("dashboard.team.TotalTeams")}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: "white" }}
                >
                  {teamAnalyticsQuery.data?.team_analytics?.total_teams ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
          </Stack>
          <Stack spacing={1}>
            <SectionTitle>{t("dashboard.team.TeamsStatus")}</SectionTitle>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FlatCard color="#74DFD5">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ minWidth: "300px" }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 700 }}>
                    {t("dashboard.team.CellGroupTeams")}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: 700, color: "white" }}
                  >
                    {teamAnalyticsQuery.data?.team_analytics?.cell_groups ?? 0}
                  </Typography>
                </Stack>
              </FlatCard>
              <FlatCard color="#FBD8B0">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ minWidth: "300px" }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 700 }}>
                    {t("dashboard.team.MinistryTeams")}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: 700, color: "white" }}
                  >
                    {teamAnalyticsQuery.data?.team_analytics?.ministry_teams ??
                      0}
                  </Typography>
                </Stack>
              </FlatCard>
            </Stack>
          </Stack>
        </Stack>
      </Section>

      <Section>
        <Stack spacing={2}>
          <SectionTitle>
            {t("dashboard.team.MembersTeamEngagement")}
          </SectionTitle>
          <Stack direction="row" alignItems="center" spacing={3}>
            <EngagementFlatCard color="#74DFD5">
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    {t("dashboard.team.CellGroupEngagement")}
                  </Typography>
                  <Typography variant="h3" sx={{ fontWeight: 700 }}>
                    {teamAnalyticsQuery.data?.members_team_engagement
                      ?.cell_group_percent ?? 0}
                    %
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  {t("dashboard.team.PercentOfMembersEngagedinCellGroup")}
                </Typography>
              </Stack>
            </EngagementFlatCard>
            <EngagementFlatCard color="#FBD8B0">
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    {t("dashboard.team.MinistryEngagement")}
                  </Typography>
                  <Typography variant="h3" sx={{ fontWeight: 700 }}>
                    {teamAnalyticsQuery.data?.members_team_engagement
                      ?.ministry_team_percent ?? 0}
                    %
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  {t("dashboard.team.PercentOfMembersEngagedinMinistry")}
                </Typography>
              </Stack>
            </EngagementFlatCard>
            <EngagementFlatCard color="#65A6A0">
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    {t("dashboard.team.LeadershipEngagement")}
                  </Typography>
                  <Typography variant="h3" sx={{ fontWeight: 700 }}>
                    {teamAnalyticsQuery.data?.members_team_engagement
                      ?.leadership ?? 0}
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  {t("dashboard.team.PercentOfMembersEngagedinLeadership")}
                </Typography>
              </Stack>
            </EngagementFlatCard>
          </Stack>
          <Stack pt={8}>
            <SectionTitle>
              {t("dashboard.team.MembersCellGroupEngagement")}
            </SectionTitle>
            <Stack direction="row" alignItems="center" py={2} px={4}>
              <PieChart
                showLegend={false}
                labels={[
                  t("dashboard.team.MembersNotEngagedinCellGroup"),
                  t("dashboard.team.MembersEngagedinCellGroup")
                ]}
                data={[
                  teamAnalyticsQuery.data?.members_team_engagement
                    ?.cell_group_no_engagement ?? 0,
                  teamAnalyticsQuery.data?.members_team_engagement
                    ?.cell_group_percent ?? 0
                ]}
                height={300}
              />
              <Stack sx={{ flex: 1 }}>
                <Legend>
                  <LegendIndicator color="#FBD8B0" />
                  <h5>{t("dashboard.team.MembersNotEngagedinCellGroup")}</h5>
                </Legend>
                <Legend>
                  <LegendIndicator color="#74DFD5" />
                  <h5>{t("dashboard.team.MembersEngagedinCellGroup")}</h5>
                </Legend>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Section>

      <Stack spacing={2}>
        <SectionTitle>{t("dashboard.team.AllTeamsInChurch")}</SectionTitle>
        <DataGrid
          autoHeight
          rows={churchTeamsQuery.data?.data ?? []}
          columns={columns}
          rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
          density="comfortable"
          getRowId={row => row.id}
          disableColumnMenu
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: "#65A6A0",
              color: "black"
            }
          }}
        />
      </Stack>
    </Container>
  );
};
