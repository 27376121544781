import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  Button,
  Notification,
  PhoneInputField,
  Select,
  TextField
} from "components/UI";
import { useChurchStore } from "features/churches";
import i18next from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import {
  useRoles,
  useUpdateOtherProfile,
  useUpdateUserRole,
  useUser
} from "../api";
import { UserLogs } from "../components/UserLogs";
import { profileSchema } from "../validation";

export function EditUserProfile() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const profileMutation = useUpdateOtherProfile();
  const { t } = useTranslation();
  // const [isEditMode, setIsEditMode] = useState(false);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [roles, setRoles] = useState([]);
  const userRoleMutation = useUpdateUserRole();
  const { id } = useParams();

  const { data: rolesData } = useRoles({
    churchId: selectedChurch?.id
  });

  useEffect(() => {
    if (rolesData) {
      const rolesResponse = rolesData?.result?.map(role => ({
        optionLabel: role?.name,
        value: role?.id
      }));

      setRoles(rolesResponse);
    }
  }, [rolesData]);

  const { data: userData } = useUser({
    churchId: selectedChurch?.id,
    userId: id
  });

  useEffect(() => {
    if (userData) {
      reset({
        first_name: userData?.result?.first_name,
        last_name: userData?.result?.last_name,
        email: userData?.result?.email,
        phone: userData?.result?.phone,
        role_id: userData?.result?.role?.id
      });
    }
  }, [userData]);

  const {
    control,
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    formState: { errors: errorsProfile },
    reset
  } = useForm({
    defaultValues: {
      first_name: userData?.result?.first_name,
      last_name: userData?.result?.last_name,
      email: userData?.result?.email,
      phone: userData?.result?.phone,
      role_id: userData?.result?.role?.id
    },
    resolver: yupResolver(profileSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("users.userProfile.userProfile"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  useEffect(() => {
    setHeaderBackground(false);

    return () => setHeaderBackground(true);
  }, []);

  const handleUpdateProfile = data => {
    profileMutation.mutate({
      churchId: selectedChurch?.id,
      userId: id,
      userData: _.omit(data, ["email", "phone", "role_id"])
    });
  };

  return (
    <Box>
      <Notification />
      <Stack spacing={4} sx={{ mt: 4 }}>
        <Grid container columnGap={4}>
          <Grid item xs={4}>
            <Stack
              component="form"
              onSubmit={handleSubmitProfile(handleUpdateProfile)}
              spacing={2}
            >
              <TextField
                label={t("users.userProfile.firstName")}
                name="first_name"
                error={!!errorsProfile.first_name?.message}
                helperText={errorsProfile.first_name?.message}
                register={registerProfile}
              />
              <TextField
                label={t("users.userProfile.lastName")}
                name="last_name"
                error={!!errorsProfile.lastName?.message}
                helperText={errorsProfile.lastName?.message}
                register={registerProfile}
              />
              <TextField
                label={t("users.userProfile.emailAddress")}
                name="email"
                error={!!errorsProfile.email?.message}
                helperText={errorsProfile.email?.message}
                register={registerProfile}
                autoComplete="email"
                disabled={true}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t("users.userProfile.phoneNumber")}
                    variant="filled"
                    error={!!errorsProfile.phone?.message}
                    helperText={!!errorsProfile.phone?.message}
                    {...field}
                    disabled={true}
                  />
                )}
              />
              {/* <TextField
                label={t("users.userProfile.roleInChurch")}
                name="role"
                error={!!errorsProfile.role?.message}
                helperText={errorsProfile.role?.message}
                register={registerProfile}
                defaultValue=""
              /> */}
              <Select
                label={t("systemAdmins.inviteUsers.Role")}
                name="role_id"
                options={roles}
                register={registerProfile}
                error={!!errorsProfile.role_id?.message}
                helperText={errorsProfile.role_id?.message}
                onChange={e => {
                  const roleId = e.target.value;

                  userRoleMutation.mutate({
                    churchId: selectedChurch?.id,
                    userId: id,
                    role: {
                      role_id: roleId
                    }
                  });
                }}
              />
              <Stack pt={4}>
                <Button type="submit" flavor="primary">
                  Save Changes
                </Button>
              </Stack>
            </Stack>
            {/* <Typography
              variant="body2"
              color="error"
              mt={15}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Delete User Account
            </Typography> */}
          </Grid>
          <Grid item xs>
            <UserLogs />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

const Role = ({ name, active = false, onClick }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "191px",
        backgroundColor: active ? "primary.main" : "white",
        color: active ? "primary.contrastText" : "black",
        p: 1,
        borderRadius: 1,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "neutral.main",
          color: "black"
        }
      }}
      onClick={onClick}
    >
      <Typography
        variant="caption"
        sx={{
          fontWeight: 500
        }}
        noWrap
      >
        {name}
      </Typography>
    </Stack>
  );
};

export function RoleMenu() {
  return (
    <Stack
      sx={{
        backgroundColor: "white",
        p: 2,
        borderRadius: 4
      }}
      spacing={1}
    >
      {["Admin", "User"]?.map(role => (
        <Role key={role} name={role} active={role === "Admin"} />
      ))}
    </Stack>
  );
}
