import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useUsersStore } from "../stores";

export const inviteUser = ({ churchId, userData }) => {
  return axios.post(`/churches/${churchId}/users`, userData);
};

export const useInviteUser = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUsersInviteModal = useUsersStore.getState().closeUsersInviteModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      setNotification({
        type: "success",
        message: "User invitation sent!"
      });
      closeUsersInviteModal();
    },
    ...config,
    mutationFn: inviteUser
  });
};
