import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPorts = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/message-ports`);
};

export const usePorts = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["ports"],
    queryFn: () => getPorts({ churchId })
  });
};
