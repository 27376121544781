import styled from "@emotion/styled";
import { Alert, Avatar, Breadcrumbs, Stack, Typography } from "@mui/material";
import { Button, Notification } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore, useUpdateChurch } from "features/churches";
import { useFileUpload } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 20px;
  flex-basis: 30%;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #e2e8f0;
`;

const Container = styled.div`
  margin-top: 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
`;

const LeftSection = styled.div`
  flex-basis: 20%;
  border-right: 1px solid #e2e8f0;
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 100vh;
`;

const RightSection = styled.div`
  flex-basis: 80%;
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-basis: 70%;
`;

const Tab = styled.div`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 10px 20px;
  border-bottom: 2px solid
    ${({ selected }) => (selected ? "#3CADB7" : "transparent")};
  color: ${({ selected }) => (selected ? "#3CADB7" : "#737373")};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  font-size: 14px;
  line-height: 21px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e8f0;
  border-radius: 2px;
  padding-block: 14px;
  padding-inline: 14px 24px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000;
`;

const CardDescription = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #8e8e8e;
`;

const CardAction = styled.div``;

const SaveButton = styled(Button)`
  padding: 8px 75px;
`;

const tabs = ["Members", "Teams", "Message", "Finance", "System Admin"];
const navigations = ["Service of terms", "ID Card"];

const VIEWS = {
  MEMBERS: {
    TERMS: {
      DEFAULT: "default",
      ADD: "add"
    },
    ID: "id"
  }
};

export const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [activeNav, setActiveNav] = useState(navigations[0]);
  const [view, setView] = useState(VIEWS.MEMBERS.TERMS.DEFAULT);
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [selectedFrontFile, openFrontFileDialog, resetFrontFile] =
    useFileUpload();
  const [selectedBackFile, openBackFileDialog, resetBackFile] = useFileUpload();
  const churchUpdateMutation = useUpdateChurch();

  useTitle("Settings");

  useEffect(() => {
    setHeaderTitle("Settings");
    return () => setHeaderTitle("");
  }, []);

  useEffect(() => {
    if (selectedFrontFile) {
      const formData = new FormData();
      const fileUploaded = event.target.files[0];
      formData.append("id_card_front_template", fileUploaded);
      churchUpdateMutation.mutate(
        {
          churchData: formData,
          churchId: selectedChurch.id
        },
        {
          onSuccess: () => {
            resetFrontFile();
          }
        }
      );
    }
  }, [selectedFrontFile]);

  useEffect(() => {
    if (selectedBackFile) {
      const formData = new FormData();
      const fileUploaded = event.target.files[0];
      formData.append("id_card_back_template", fileUploaded);
      churchUpdateMutation.mutate(
        {
          churchData: formData,
          churchId: selectedChurch.id
        },
        {
          onSuccess: () => {
            resetBackFile();
          }
        }
      );
    }
  }, [selectedBackFile]);

  return (
    <Container>
      <Notification />
      <Header>
        <Title>{selectedTab} setting</Title>
        <Tabs>
          {tabs.map(tab => (
            <Tab
              key={tab}
              selected={selectedTab === tab}
              onClick={tab !== tabs[0] ? undefined : () => setSelectedTab(tab)}
              disabled={tab !== tabs[0]}
            >
              {tab}
            </Tab>
          ))}
        </Tabs>
      </Header>
      <Wrapper>
        <LeftSection>
          {navigations.map(navigation => (
            <Typography
              key={navigation}
              variant="subtitle2"
              p="10px"
              sx={{
                fontWeight: `${activeNav === navigation ? 700 : 400}`,
                background: `${activeNav === navigation ? "#E6F7F8" : "white"}`,
                cursor: "pointer",
                borderRadius: "4px",
                ":hover": {
                  background: "#E6F7F8"
                }
              }}
              onClick={() => {
                setActiveNav(navigation);

                if (navigation === navigations[0]) {
                  setView(VIEWS.MEMBERS.TERMS.DEFAULT);
                }

                if (navigation === navigations[1]) {
                  setView(VIEWS.MEMBERS.ID);
                }
              }}
            >
              {navigation}
            </Typography>
          ))}
        </LeftSection>
        <RightSection>
          {view === VIEWS.MEMBERS.TERMS.DEFAULT && (
            <Card>
              <CardContent>
                <CardTitle>Service of terms</CardTitle>
                <CardDescription>
                  Add clarity and transparency to your church&apos;s operations
                  by adding your Service of Terms
                </CardDescription>
              </CardContent>
              <CardAction>
                <Button onClick={() => setView(VIEWS.MEMBERS.TERMS.ADD)}>
                  {selectedChurch?.terms_and_conditions ? "Edit" : "Add"}{" "}
                  service of terms
                </Button>
              </CardAction>
            </Card>
          )}

          {view === VIEWS.MEMBERS.TERMS.ADD && (
            <AddTermsOfService setView={setView} />
          )}

          {view === VIEWS.MEMBERS.ID && (
            <>
              <Card>
                <CardContent>
                  <CardTitle>ID Card Background - Front</CardTitle>
                  <CardDescription>
                    This is the front side background of the ID card
                  </CardDescription>
                </CardContent>
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "200px",
                    height: "100px",
                    border: "1px solid #e2e8f0"
                  }}
                  src={
                    selectedFrontFile
                      ? URL.createObjectURL(selectedFrontFile)
                      : `${IMAGE_URL}/${selectedChurch?.id_card_front_template}`
                  }
                  alt="F"
                  imgProps={{
                    style: {
                      objectPosition: "bottom"
                    }
                  }}
                />
                <CardAction>
                  <Button onClick={openFrontFileDialog}>Upload</Button>
                </CardAction>
              </Card>
              <Card>
                <CardContent>
                  <CardTitle>ID Card Background - Back</CardTitle>
                  <CardDescription>
                    This is the back side background of the ID card
                  </CardDescription>
                </CardContent>
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "200px",
                    height: "100px",
                    border: "1px solid #e2e8f0"
                  }}
                  src={
                    selectedBackFile
                      ? URL.createObjectURL(selectedBackFile)
                      : `${IMAGE_URL}/${selectedChurch?.id_card_back_template}`
                  }
                  alt="B"
                  imgProps={{
                    style: {
                      objectPosition: "bottom"
                    }
                  }}
                />
                <CardAction>
                  <Button onClick={openBackFileDialog}>Upload</Button>
                </CardAction>
              </Card>
              <Alert severity="info">
                File Format: Please upload your customized ID design in either
                PNG or JPEG format.
                <br /> ID Card Dimensions: Overall ID card size is 8.56 cm x 5.4
                cm (width x height).
                <br /> Space for Member Data: 5.31 cm x 3.2 cm (width x height).
                <br /> Design Requirements: Your ID design must include a header
                and footer only.
                <br /> Ensure that the design fits within the specified
                dimensions to allow for proper rendering of member data.
              </Alert>
            </>
          )}
        </RightSection>
      </Wrapper>
    </Container>
  );
};

const AddTermsOfService = ({ setView }) => {
  const churchMutation = useUpdateChurch();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const editor = useRef();

  const handleSaveTerms = ({ content }) => {
    const data = {
      terms_and_conditions: content
    };

    churchMutation.mutate({
      churchData: data,
      churchId: selectedChurch.id
    });
  };

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          variant="caption"
          color="text.primary"
          onClick={() => setView(VIEWS.MEMBERS.TERMS.DEFAULT)}
        >
          Service of terms
        </Typography>
        <Typography variant="caption" color="text.primary">
          {selectedChurch?.terms_and_conditions ? "Edit" : "Add"} service of
          terms
        </Typography>
      </Breadcrumbs>
      <Typography variant="subtitle1" fontWeight={700}>
        {selectedChurch?.terms_and_conditions ? "Edit" : "Add"} Service of terms
      </Typography>
      <Stack spacing={1}>
        <Typography variant="subtitle2">Service of terms</Typography>
        <SunEditor
          setOptions={{
            buttonList: [
              ["undo", "redo", "fontSize"],
              ["bold", "underline", "italic", "strike", "removeFormat"],
              ["align", "list", "table", "link"]
            ]
          }}
          defaultValue={selectedChurch?.terms_and_conditions ?? ""}
          getSunEditorInstance={sunEditor => (editor.current = sunEditor)}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Button
          type="button"
          flavor="tertiary"
          onClick={() => setView(VIEWS.MEMBERS.TERMS.DEFAULT)}
          disabled={churchMutation.isLoading}
        >
          Cancel
        </Button>
        <SaveButton
          type="submit"
          disabled={churchMutation.isLoading}
          onClick={() =>
            handleSaveTerms({
              content: editor.current.getContents()
            })
          }
        >
          Save
        </SaveButton>
      </Stack>
    </Stack>
  );
};
