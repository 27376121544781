import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const deleteRevenueMethod = ({ churchId, revenueMethodId }) => {
  return axios.delete(
    `/churches/${churchId}/revenue-methods/${revenueMethodId}`
  );
};

export const useDeleteRevenueMethod = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closeUpdateRevenueMethodModal =
    useRevenueStore.getState().closeUpdateRevenueMethodModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["revenue-methods"]);
      setNotification({
        type: "success",
        message: "Revenue method deleted successfully!"
      });
      closeDeleteModal();
      closeUpdateRevenueMethodModal();
    },
    ...config,
    mutationFn: deleteRevenueMethod
  });
};
