import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getSubcities = ({ cityId }) => {
  return axios.get(`/addresses/cities/${cityId}/sub_cities`);
};

export const useSubcities = ({ cityId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["subcities", cityId],
    queryFn: () => getSubcities({ cityId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
