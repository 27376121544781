import styled from "@emotion/styled";
import { InputAdornment, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  ChartLineDown,
  ChartLineUp,
  Funnel,
  MagnifyingGlass
} from "@phosphor-icons/react";
import { TextField } from "components/UI";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  grid-area: transactions;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  margin-right: 80px;
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: auto;
`;

const NavigationItem = styled.span`
  font-size: 12px;
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "#64748b"};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SearchIcon = styled(MagnifyingGlass)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const FilterIcon = styled(Funnel)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const TransactionsTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  console.log(page);

  const columns = [
    {
      field: "number",
      headerName: "Transaction Number",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            {row.income ? (
              <ChartLineUp color="#74DFD5" size={24} />
            ) : (
              <ChartLineDown color="#F8BC77" size={24} />
            )}
            <span>{row.number}</span>
          </Stack>
        );
      }
    },
    {
      field: "name",
      headerName: "Description/Name",
      flex: 1
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1
    },
    {
      field: "debits",
      headerName: "Debits",
      flex: 1
    },
    {
      field: "credits",
      headerName: "Credits",
      flex: 1
    },
    {
      field: "date",
      headerName: "Transaction date",
      flex: 1
    }
  ];

  const placeholderData = [
    {
      id: "ad-ds-rw",
      number: "e34321",
      name: "Tsega",
      amount: "5000",
      debits: "Debits",
      credits: "Credits",
      date: "12-02-2023",
      income: false
    },
    {
      id: "ad-xs-rw",
      number: "e34321",
      name: "Tsega",
      amount: "5000",
      debits: "Debits",
      credits: "Credits",
      date: "12-02-2023",
      income: true
    }
  ];

  return (
    <Container>
      <Header>
        <Title>Transactions</Title>
        <Navigation>
          <NavigationItem active>All</NavigationItem>
          <NavigationItem>Revenue</NavigationItem>
          <NavigationItem>Expense</NavigationItem>
        </Navigation>
        <Actions>
          <TextField
            neutral={1}
            placeholder="Search transactions"
            register={() => {}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={16} />
                </InputAdornment>
              )
            }}
            autoComplete="off"
          />
          <FilterIcon size={16} />
        </Actions>
      </Header>
      <DataGrid
        autoHeight
        rows={placeholderData}
        rowCount={0}
        loading={false}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
    </Container>
  );
};
