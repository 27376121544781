import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Stack as StackIcon } from "@phosphor-icons/react";
import { Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { StatInfoCard } from "features/dashboard";
import { useTranslation } from "react-i18next";
import { useEmployeesPaginated } from "../api";
import {
  AddEmployeeModal,
  EmployeesTable,
  EmployeesTableHeader
} from "../components";
import { useEmployeesStore } from "../store";

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-block: 1rem 2rem;
`;

export const HR = () => {
  const openAddEmployeeModal = useEmployeesStore(
    state => state.openAddEmployeeModal
  );
  const addEmployeeModalOpen = useEmployeesStore(
    state => state.addEmployeeModalOpen
  );
  const closeAddEmployeeModal = useEmployeesStore(
    state => state.closeAddEmployeeModal
  );

  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const { t } = useTranslation();

  const employeesPaginatedQuery = useEmployeesPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10
  });

  return (
    <Stack>
      <StatGrid>
        <StatInfoCard
          title={t("HR.HR.TotalEmployees")}
          stat={employeesPaginatedQuery.data?.result?.total_employees ?? 0}
          icon={<StackIcon size={24} />}
          color="rgba(0, 176, 185, 1)"
          background="#82DBD899"
        />
        <StatInfoCard
          title={t("HR.HR.TotalActiveEmployees")}
          stat={
            employeesPaginatedQuery.data?.result?.total_active_employees ?? 0
          }
          icon={<StackIcon size={24} />}
          color="rgba(0, 176, 185, 1)"
          background="#82DBD899"
        />
        <StatInfoCard
          title={t("HR.HR.TotalInactive Employees")}
          stat={
            employeesPaginatedQuery.data?.result?.total_inactive_employees ?? 0
          }
          icon={<StackIcon size={24} />}
          color="rgba(0, 176, 185, 1)"
          background="#82DBD899"
        />
      </StatGrid>
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          alignSelf="flex-end"
          spacing={1}
        >
          <Button onClick={openAddEmployeeModal}>
            {t("HR.HR.AddEmployee")}
          </Button>
          {/* <Button flavor="outlined" disabled>
            Import Employees
          </Button>
          <Button flavor="outlined" disabled>
            Export Employees
          </Button> */}
        </Stack>
        <EmployeesTableHeader tabValue={0} setTabValue={() => {}} />
        <EmployeesTable />
      </Stack>
      <AddEmployeeModal
        open={addEmployeeModalOpen}
        onClose={closeAddEmployeeModal}
      />
      <Notification />
    </Stack>
  );
};
