import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Notification, PhoneInputField, TextField } from "components/UI";
import i18next from "i18next";
import _ from "lodash";
import { useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import { useChangePassword, useUpdateProfile } from "../api";
import { UserLogs } from "../components/UserLogs";
import { changePasswordSchema, profileSchema } from "../validation";

const Container = styled(Stack)`
  padding-block: 32px;
  padding-inline: 28px;
  background: #fbfbfb;
`;

export function UserProfile() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const auth = useAuthUser();
  const passwordMutation = useChangePassword();
  const profileMutation = useUpdateProfile();
  const { t } = useTranslation();

  useTitle("Profile");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(changePasswordSchema)
  });

  const {
    control,
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    formState: { errors: errorsProfile }
  } = useForm({
    resolver: yupResolver(profileSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("users.userProfile.userProfile"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  useEffect(() => {
    setHeaderBackground(false);

    return () => setHeaderBackground(true);
  }, []);

  useEffect(() => {
    if (passwordMutation.isSuccess) {
      reset();
    }
  }, [passwordMutation.isSuccess]);

  const handleChangePassword = data => {
    passwordMutation.mutate({
      passwordData: _.omit(data, ["confirm_password"])
    });
  };

  const handleUpdateProfile = data => {
    const userData = _.omit(data, ["email", "phone"]);

    profileMutation.mutate({
      userData
    });
  };

  return (
    <Box>
      <Notification />
      <Stack spacing={4} sx={{ mt: 4 }}>
        <Grid container columnGap={4}>
          <Grid xs={4}>
            <Grid direction="column" container spacing={8}>
              <Grid item xs>
                <Stack
                  component="form"
                  onSubmit={handleSubmitProfile(handleUpdateProfile)}
                  spacing={2}
                >
                  <TextField
                    label={t("users.userProfile.firstName")}
                    name="first_name"
                    error={!!errorsProfile.first_name?.message}
                    helperText={errorsProfile.first_name?.message}
                    register={registerProfile}
                    defaultValue={auth().first_name}
                  />
                  <TextField
                    label={t("users.userProfile.lastName")}
                    name="last_name"
                    error={!!errorsProfile.lastName?.message}
                    helperText={errorsProfile.lastName?.message}
                    register={registerProfile}
                    defaultValue={auth().last_name}
                  />
                  <TextField
                    label={t("users.userProfile.emailAddress")}
                    name="email"
                    error={!!errorsProfile.email?.message}
                    helperText={errorsProfile.email?.message}
                    register={registerProfile}
                    autoComplete="email"
                    defaultValue={auth().email}
                    disabled={true}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInputField
                        label={t("users.userProfile.phoneNumber")}
                        variant="filled"
                        error={!!errorsProfile.phone?.message}
                        helperText={errorsProfile.phone?.message}
                        {...field}
                        disabled={true}
                      />
                    )}
                    defaultValue={auth().phone}
                  />
                  {/* <TextField
                    label={t("users.userProfile.roleInChurch")}
                    name="role"
                    error={!!errorsProfile.role?.message}
                    helperText={errorsProfile.role?.message}
                    register={registerProfile}
                    defaultValue=""
                  /> */}
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    disabled={profileMutation.isLoading}
                  >
                    {t("users.userProfile.updateChanges")}
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs>
                <Container
                  component="form"
                  spacing={2}
                  onSubmit={handleSubmit(handleChangePassword)}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    {t("users.userProfile.changePassword")}
                  </Typography>
                  <TextField
                    label={t("users.userProfile.oldPassword")}
                    name="current_password"
                    type="password"
                    error={!!errors.current_password?.message}
                    helperText={errors.current_password?.message}
                    register={register}
                  />
                  <TextField
                    label={t("users.userProfile.newPassword")}
                    name="new_password"
                    type="password"
                    error={!!errors.new_password?.message}
                    helperText={errors.new_password?.message}
                    register={register}
                  />
                  <TextField
                    label={t("users.userProfile.confirmPassword")}
                    name="confirm_password"
                    type="password"
                    error={!!errors.confirm_password?.message}
                    helperText={errors.confirm_password?.message}
                    register={register}
                  />
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    disabled={passwordMutation.isLoading}
                  >
                    {t("users.userProfile.changePassword")}
                  </Button>
                </Container>
              </Grid>
              {/* <Grid item xs>
                <MyRoles />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid xs>
            <UserLogs />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

// const MyRoles = () => {
//   const [anchorEl, setAnchorEl] = useState(null);

//   const open = Boolean(anchorEl);

//   const handleClick = event => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <Box
//       sx={{
//         overflow: "hidden"
//       }}
//     >
//       <Box
//         sx={{
//           p: 2,
//           backgroundColor: "secondary.main",
//           color: "primary.main",
//           fontWeight: 700
//         }}
//       >
//         My role in churches
//       </Box>
//       <Stack
//         sx={{
//           p: 1,
//           backgroundColor: "#FBFBFB"
//         }}
//       >
//         <Table>
//           <TableBody>
//             <TableRow>
//               <TableCell>Bethel Church</TableCell>
//               <TableCell>
//                 <Chip
//                   label="Admin"
//                   sx={{
//                     backgroundColor: "rgba(49, 134, 234, 0.43)"
//                   }}
//                   onClick={handleClick}
//                 />
//               </TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </Stack>
//       <Popover
//         anchorEl={anchorEl}
//         id="account-menu"
//         open={open}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "right"
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "center"
//         }}
//         PaperProps={{
//           elevation: 2,
//           sx: {
//             borderRadius: 4,
//             boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)"
//           }
//         }}
//       >
//         <RoleMenu />
//       </Popover>
//     </Box>
//   );
// };
