import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMessagesStore } from "../store";

export const sendMessage = ({ churchId, messageData }) => {
  return axios.post(`/churches/${churchId}/send-messages`, messageData);
};

export const useSendMessage = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const setMessage = useMessagesStore.getState().setMessage;
  const setServerErrors = useMessagesStore.getState().setServerErrors;

  return useMutation({
    onMutate: () => {
      setServerErrors(null);
    },
    onSuccess: (_, { notificationMessage }) => {
      queryClient.invalidateQueries("messages");
      queryClient.invalidateQueries("send-messages");
      setNotification({
        type: "success",
        message: notificationMessage ?? "Message sent successfully!"
      });
      setMessage("");
    },
    onError: ({ errors }) => {
      setServerErrors(errors);
      setNotification({
        type: "error",
        message: "Something went wrong. Please try again later."
      });
    },
    ...config,
    mutationFn: sendMessage
  });
};
