import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useTeamsStore } from "../stores";

export const addTeamMember = ({ churchId, teamId, memberData }) => {
  return axios.post(
    `/churches/${churchId}/teams/${teamId}/team-member`,
    memberData
  );
};

export const useAddTeamMember = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeAddTeamMemberModal =
    useTeamsStore.getState().closeAddTeamMemberModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("team_members");
      queryClient.invalidateQueries("members");
      setNotification({
        type: "success",
        message: "Team member added successfully!"
      });
      closeAddTeamMemberModal();
    },
    ...config,
    mutationFn: addTeamMember
  });
};
