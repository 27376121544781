import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const deletePort = ({ churchId, portId }) => {
  return axios.delete(`/churches/${churchId}/message-ports/${portId}`);
};

export const useDeletePort = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["ports"]);
      setNotification({
        type: "success",
        message: "Port deleted successfully!"
      });
      closeDeleteModal();
    },
    ...config,
    mutationFn: deletePort
  });
};
