import styled from "@emotion/styled";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Toggle } from "components/UI";
import _ from "lodash";
import { useState } from "react";

const Block = styled.div`
  background: #f9fafb;
  border-radius: 4px;
  padding: 17px 27px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const BlockHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  h4 {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
`;

const BlockRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
  padding: 8px 0;
`;

export const PermissionBlock = ({
  name,
  permissions,
  selectedPermissions,
  setSelectedPermissions
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const modifyName = name => _.capitalize(_.replace(name, /_/g, " "));

  return (
    <Block>
      <BlockHeader onClick={() => setCollapsed(!collapsed)}>
        <h4>{name}</h4>
        <IconButton>
          {collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      </BlockHeader>
      {!collapsed && (
        <BlockContent>
          {permissions.map(permission => (
            <BlockRow key={permission.id}>
              <span>{modifyName(permission.name)}</span>
              <Toggle
                checked={selectedPermissions.includes(permission.id)}
                onChange={e => {
                  let permissionsCopy = _.cloneDeep(selectedPermissions);

                  if (e.target.checked) {
                    permissionsCopy.push(permission.id);
                    setSelectedPermissions(permissionsCopy);
                  } else {
                    permissionsCopy = _.pull(permissionsCopy, permission.id);
                    setSelectedPermissions(permissionsCopy);
                  }
                }}
              />
            </BlockRow>
          ))}
        </BlockContent>
      )}
    </Block>
  );
};
