import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const deletePartner = ({ partnerId }) => {
  return axios.delete(`/partners/${partnerId}`);
};

export const useDeletePartner = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closePartnerPreview = useMembersStore.getState().closePartnerPreview;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["partners"]);
      setNotification({
        type: "success",
        message: "Partner deleted successfully!"
      });
      closePartnerPreview();
      closeDeleteModal();
    },
    ...config,
    mutationFn: deletePartner
  });
};
