import { Box, Grid } from "@mui/material";
import { Header } from "components/UI";
import { Outlet } from "react-router-dom";
import { ChurchProfileSidebar } from "./ChurchProfileSidebar";

export function ChurchProfileLayout() {
  return (
    <Grid container component="main" sx={{ minHeight: "100vh" }}>
      <Grid
        item
        display={{ xs: "none", md: "block" }}
        xs={false}
        sm={4}
        md={3}
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          width: "25%"
        }}
      >
        <ChurchProfileSidebar />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={9}
        sx={{
          marginLeft: { md: "25%" }
        }}
      >
        <Header />
        <Box
          sx={{
            pb: 6,
            px: 4,
            my: "auto"
          }}
        >
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
}
