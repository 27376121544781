import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";

export const deleteUser = ({ churchId, userId }) => {
  return axios.delete(`/churches/${churchId}/users/${userId}`);
};

export const useDeleteUser = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const signOut = useSignOut();
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);

      setNotification({
        type: "success",
        message: "User deleted successfully!"
      });

      signOut();

      navigate("/auth/sign-in");
    },
    ...config,
    mutationFn: deleteUser
  });
};
