import styled from "@emotion/styled";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { Button, LanguageSelector, Notification } from "components/UI";
import moment from "moment";
import { useState } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resendOtp, verifyAccount } from "../api";
import { OtpInput } from "../components";

const LinkButton = styled.button`
  all: unset;
  text-decoration: underline;
  color: ${({ primary, theme }) => primary && theme.palette.primary.main};
  cursor: pointer;

  &:disabled {
    color: #aaaaaa;
    cursor: not-allowed;
  }
`;

export function Verification() {
  const [otp, setOtp] = useState("");
  const [isResendActive, setIsResendActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendSuccessMessage, setResendSuccessMessage] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [resendTimer, setResendTimer] = useState(
    moment().add(3, "minutes").format()
  );
  const { t } = useTranslation();

  const verify = async () => {
    setLoading(true);
    setErrorMessage("");
    setResendSuccessMessage("");

    const verifyData = {
      otp,
      email: searchParams.get("email")
    };

    try {
      await verifyAccount(verifyData);

      setLoading(false);

      navigate("/auth/login");
    } catch (error) {
      setLoading(false);

      setErrorMessage(error.errors);
    }
  };

  const resend = async () => {
    setErrorMessage("");
    setResendSuccessMessage("");

    const resendData = {
      email: searchParams.get("email")
    };

    try {
      await resendOtp(resendData);

      setResendSuccessMessage(t("auth.verification.resendSuccess"));

      setResendTimer(moment().add(3, "minutes").format());

      setIsResendActive(false);
    } catch (error) {
      setErrorMessage(error.errors);
    }
  };

  return (
    <Box sx={{ my: "auto" }} px={{ xs: 4, sm: 16, md: 6, lg: 22, xl: 32 }}>
      <Notification />
      <Typography
        component="h1"
        variant="h4"
        sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}
      >
        {t("auth.verification.verification")}
      </Typography>
      <Typography component="h2" variant="body1" sx={{ textAlign: "center" }}>
        {t("auth.verification.description")} {searchParams.get("email")}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Stack alignItems="center">
          <OtpInput value={otp} onChange={value => setOtp(value)} />
        </Stack>
        <Typography component="h4" variant="body2" sx={{ textAlign: "center" }}>
          {t("auth.verification.didntReceiveCode")}
        </Typography>
        <Box sx={{ textAlign: "center", my: 2 }}>
          <LinkButton primary={1} disabled={!isResendActive} onClick={resend}>
            {t("auth.verification.resend")}
          </LinkButton>
          <span> {t("auth.verification.in")} </span>
          <Countdown
            key={resendTimer}
            date={resendTimer}
            zeroPadTime={2}
            renderer={({ formatted: { minutes, seconds } }) => (
              <span>
                {minutes}:{seconds}
              </span>
            )}
            onComplete={() => {
              setIsResendActive(true);
            }}
          />
        </Box>
        {Boolean(resendSuccessMessage) && (
          <Typography
            component="h4"
            variant="body2"
            sx={{ textAlign: "center", mb: 2 }}
          >
            {resendSuccessMessage}
          </Typography>
        )}
        <Box sx={{ textAlign: "center" }}>
          {t("auth.verification.getVia")}{" "}
          <LinkButton disabled={true}>
            {t("auth.verification.phone")}
          </LinkButton>
        </Box>
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={verify}
          disabled={loading}
        >
          {t("auth.verification.verifyAndCreateAccount")}
        </Button>
        {Boolean(errorMessage) && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
      </Box>
      <Stack alignItems="center" mt={4}>
        <LanguageSelector />
      </Stack>
    </Box>
  );
}
