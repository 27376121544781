import styled from "@emotion/styled";
import { Avatar, AvatarGroup } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Skeleton = styled.div`
  width: 367px;
  height: 82px;
  background: #f3f3f3;
  border-radius: 5px;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Pills = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const SkeletonHeaderPill = styled.div`
  width: 129px;
  height: 13px;
  background: #d9d9d9;
  border-radius: 6px;
`;

const SkeletonBodyPill = styled.div`
  width: 180px;
  height: 13px;
  background: #e8e8e8;
  border-radius: 6px;
`;

const OutlinedAvatarGroup = styled(AvatarGroup)`
  & .MuiAvatar-root {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Skeletons = () => {
  return (
    <Container>
      {[3, 2, 1].map(skeleton => (
        <Skeleton key={skeleton}>
          <Pills>
            <SkeletonHeaderPill />
            <SkeletonBodyPill />
          </Pills>
          <OutlinedAvatarGroup max={3}>
            {Array.from({ length: skeleton }, (_, i) => i).map(avatar => (
              <Avatar key={avatar} alt="Remy Sharp" src="" />
            ))}
          </OutlinedAvatarGroup>
        </Skeleton>
      ))}
    </Container>
  );
};
