const extraColumns = [
  "academic_status",
  "birth_city_id",
  "birth_country_id",
  "birth_region_id",
  "birth_zone_id",
  "city_id",
  "country_id",
  "date_of_birth",
  "house_number",
  "is_believer",
  "is_member",
  "kebele",
  "mother_language",
  "nationality_id",
  "region_id",
  "second_language",
  "specific_location",
  "status",
  "subcity_id",
  "woreda_id",
  "zone_id",
  "primary_first_name",
  "primary_last_name",
  "primary_father_name",
  "primary_phone",
  "primary_relationship",
  "primary_religion",
  "primary_attending_church",
  "secondary_first_name",
  "secondary_last_name",
  "secondary_father_name",
  "secondary_phone",
  "secondary_relationship",
  "secondary_religion",
  "secondary_attending_church"
];
const uniqueExtraColumns = [...new Set(extraColumns)];

export const extraKidColumns = uniqueExtraColumns.map(column => {
  if (column === "birth_country_id") {
    return {
      field: column,
      headerName: "Country",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.birth_country?.name}</span>;
      }
    };
  }

  if (column === "birth_region_id") {
    return {
      field: column,
      headerName: "Region",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.birth_region?.name}</span>;
      }
    };
  }

  if (column === "birth_city_id") {
    return {
      field: column,
      headerName: "City",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.birth_city?.name}</span>;
      }
    };
  }

  if (column === "birth_zone_id") {
    return {
      field: column,
      headerName: "Zone",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.birth_zone?.name}</span>;
      }
    };
  }

  if (column === "country_id") {
    return {
      field: column,
      headerName: "Country",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.country?.name}</span>;
      }
    };
  }

  if (column === "region_id") {
    return {
      field: column,
      headerName: "Region",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.region?.name}</span>;
      }
    };
  }

  if (column === "city_id") {
    return {
      field: column,
      headerName: "City",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.city?.name}</span>;
      }
    };
  }

  if (column === "zone_id") {
    return {
      field: column,
      headerName: "Zone",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.zone?.name}</span>;
      }
    };
  }

  if (column === "subcity_id") {
    return {
      field: column,
      headerName: "Subcity",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.subcity?.name}</span>;
      }
    };
  }

  if (column === "woreda_id") {
    return {
      field: column,
      headerName: "Woreda",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.woreda?.name}</span>;
      }
    };
  }

  if (column === "nationality_id") {
    return {
      field: column,
      headerName: "Nationality",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.nationality?.nationality}</span>;
      }
    };
  }

  if (["is_believer", "is_member"].includes(column)) {
    return {
      field: column,
      headerName: column
        .replaceAll("_", " ")
        .toLowerCase()
        .split(" ")
        .map((word, index) => {
          if (index !== 0 && word === "id") {
            return word.replace("id", "");
          }

          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ")
        .trim(),
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row?.column === 1 ? "Yes" : "No"}</span>;
      }
    };
  }

  return {
    field: column,
    headerName: column
      .replaceAll("_", " ")
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
    flex: 1,
    minWidth: 120
  };
});

export const extraKidColumnsVisibility = {};
extraKidColumns.forEach(({ field }) => {
  extraKidColumnsVisibility[field] = false;
});

export const excludeKidColumns = ["phone", "operation"];

const MAX_NUMBER_OF_COLUMNS_THRESHOLD = 7;
export const isExceedingThreshold = (
  columnsVisibility,
  maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS_THRESHOLD
) => {
  return (
    Object.values(columnsVisibility).filter(Boolean).length > maxNumberOfColumns
  );
};
