import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getUserLogs = ({ churchId, userId }) => {
  return axios.get(`/churches/${churchId}/logs/${userId}`);
};

export const useUserLogs = ({ churchId, userId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["user-logs", churchId, userId],
    queryFn: () => getUserLogs({ churchId, userId })
  });
};
