import styled from "@emotion/styled";
import { CloudUpload } from "@mui/icons-material";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 10px;
  border-radius: 5px;
  border-color: ${({ error, theme }) =>
    error ? theme.palette.error.main : "#3dadb7"};
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const Preview = styled.div`
  margin: 0 0 10px 0;
`;

export const Dropzone = ({
  name,
  register,
  unregister,
  watch,
  setValue,
  error,
  helperText
}) => {
  const files = watch(name);

  const onDrop = useCallback(
    droppedFiles => setValue(name, droppedFiles, { shouldValidate: true }),
    [setValue, name]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: {
      "image/*": [".jpeg", ".png"]
    },
    onDrop
  });

  const { t } = useTranslation();

  const filePreviewThumbnail = files?.map(file => (
    <Avatar
      key={file.name}
      variant="rounded"
      sx={{ height: 100, width: 100 }}
      src={URL.createObjectURL(file)}
      alt={file.name}
      onLoad={() => {
        URL.revokeObjectURL(file);
      }}
    />
  ));

  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <>
      <Container {...getRootProps()} error={error}>
        <input {...getInputProps()} />
        <Stack alignItems="center">
          {files?.length ? (
            <Preview>{filePreviewThumbnail}</Preview>
          ) : (
            <>
              <CloudUpload color="primary" />
              <Box>{t("ui.dropzone.description")}</Box>
              <Box>{t("ui.dropzone.or")}</Box>
            </>
          )}
          <Button size="medium" variant="contained" onClick={open}>
            {t("ui.dropzone.browse")}
          </Button>
        </Stack>
      </Container>
      {error && (
        <Typography
          variant="caption"
          color="error"
          sx={{ padding: "0 0.75rem" }}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};
