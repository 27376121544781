import styled from "@emotion/styled";
import { DeleteOutline } from "@mui/icons-material";
import { Modal, Stack, Typography } from "@mui/material";
import { useChurchStore } from "features/churches";
import { useParams } from "react-router-dom";
import { useDeleteTeam } from "../api";
import { useTeamsStore } from "../stores";

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  // height: 200px;
  max-width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 12px;
  background-color: #eeeeee;
  color: #f44949;
  font-size: 12px;
`;

const Content = styled(Stack)`
  padding-block: 30px;
  text-align: center;
`;

const DeleteButton = styled.button`
  background-color: #f44949;
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #000000;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
`;

export const DeleteNote = ({ name }) => {
  const deleteTeamModalOpen = useTeamsStore(state => state.deleteTeamModalOpen);
  const closeDeleteTeamModal = useTeamsStore(
    state => state.closeDeleteTeamModal
  );
  const deleteMutation = useDeleteTeam();
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const deleteTeam = () => {
    deleteMutation.mutate({
      churchId: selectedChurch?.id,
      teamId: id
    });
  };

  return (
    <Modal open={deleteTeamModalOpen} onClose={closeDeleteTeamModal}>
      <Container>
        <Header>
          <DeleteOutline />
          <span>Delete Note</span>
        </Header>
        <Content spacing={2} alignItems="center">
          <Typography variant="caption" sx={{ color: "#4D4D4D" }}>
            This will delete {name} team permanently.
            <br /> Are you sure to delete?
          </Typography>
          <Stack direction="row" spacing={2}>
            <CancelButton onClick={closeDeleteTeamModal}>
              No, keep it
            </CancelButton>
            <DeleteButton onClick={deleteTeam}>Yes, Delete</DeleteButton>
          </Stack>
        </Content>
      </Container>
    </Modal>
  );
};
