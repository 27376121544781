import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Coins, X } from "@phosphor-icons/react";
import { Button, Notification } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApproveSettlement, useExpense, useSettlements } from "../api";
import { RejectionReasonModal } from "../components";
import { useExpenseStore } from "../store";

const Container = styled.div`
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f5f9;
  padding: 18px 20px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;

  th {
    padding-block: 16px;
    background: #e6f7f8;
    font-weight: 400;
  }

  & td {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
  }
`;

const Banner = styled.div`
  border-radius: 2px;
  background: #f8bc77;
  width: 391px;
  height: 83px;
  color: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const BannerTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

const Amount = styled.span`
  font-size: 12px;
`;

const Figure = styled.span`
  font-size: 32px;
  font-weight: 700;
`;

const InformationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  columns: 2;
  font-size: 12px;
  width: 50%;
`;

const Information = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    color: #778899;
    flex: 1;
  }
`;

const ApproveButton = styled.button`
  all: unset;
  cursor: pointer;
  padding: 4px;
  border-radius: 2px;
  background: #50c40d;
  color: #ffffff;
  font-size: 10px;
`;

const RejectButton = styled.button`
  all: unset;
  cursor: pointer;
  padding: 4px;
  border-radius: 2px;
  background: #f4352f;
  color: #ffffff;
  font-size: 10px;
`;

const ReceiptPreview = styled.div`
  padding: 18px 11px;
  border-radius: 4px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const ReceiptPreviewTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const ReceiptPhoto = styled.img`
  height: 431px;
  width: 365px;
`;

export const ExpenseSettlementApproval = () => {
  const navigate = useNavigate();
  const openRejectionReasonModal = useExpenseStore(
    state => state.openRejectionReasonModal
  );
  const { id } = useParams();
  const [settlement, setSettlement] = useState(null);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const expenseQuery = useExpense({
    churchId: selectedChurch?.id,
    expenseId: id
  });
  const settlementsQuery = useSettlements({
    churchId: selectedChurch?.id,
    expenseId: id
  });
  const approveSettlementMutation = useApproveSettlement();

  const approveSettlement = settlementId => {
    approveSettlementMutation.mutate({
      churchId: selectedChurch?.id,
      expenseId: id,
      settlementId: settlementId
    });
  };

  return (
    <Container>
      <RejectionReasonModal data={settlement} />
      <Notification />
      <Header>
        <Title>Expense Settlement Approval</Title>
        <CloseButton onClick={() => navigate(`/finances/temp/revenue`)}>
          <span>Close</span>
          <X size={16} />
        </CloseButton>
      </Header>
      <Banner>
        <Stack flex={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <BannerTitle>Advance to clear</BannerTitle>
            <Coins
              size={32}
              style={{
                mixBlendMode: "soft-light"
              }}
            />
          </Stack>
          <Amount>
            <Figure>
              {expenseQuery.data?.result?.requisition?.amount ||
                expenseQuery.data?.result?.requisition_record?.amount}
            </Figure>{" "}
            ETB
          </Amount>
        </Stack>
      </Banner>
      <InformationList>
        <Information>
          <span>Requestor Full Name</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.user?.name ||
              expenseQuery.data?.result?.requisition_record?.user?.name}
          </strong>
        </Information>
        <Information>
          <span>Department</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.team?.name ||
              expenseQuery.data?.result?.requisition_record?.team?.name}
          </strong>
        </Information>
        <Information>
          <span>Amount</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.amount ||
              expenseQuery.data?.result?.requisition_record?.amount}{" "}
            ETB
          </strong>
        </Information>
        <Information>
          <span>Reason</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.reason ||
              expenseQuery.data?.result?.requisition_record?.reason}
          </strong>
        </Information>
        <Information>
          <span>Category</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.expense_type?.name ||
              expenseQuery.data?.result?.requisition_record?.expense_type?.name}
          </strong>
        </Information>
        <Information>
          <span>Account</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.chart_of_account
              ?.account_name?.name ||
              expenseQuery.data?.result?.requisition_record?.chart_of_account
                ?.account_name?.name}
          </strong>
        </Information>
      </InformationList>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <Table>
          <thead>
            <tr>
              <th>Reason</th>
              <th>Amount</th>
              <th>Action</th>
              <th>Next Approver</th>
            </tr>
          </thead>
          <tbody>
            {settlementsQuery.data?.result?.map(settlement => (
              <tr key={settlement.id} onClick={() => setSettlement(settlement)}>
                <td>{settlement.reason}</td>
                <td>{settlement.amount}</td>
                <td>
                  {settlement.rejected_by === null &&
                    settlement.approved_by === null && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                      >
                        <ApproveButton
                          onClick={e => {
                            e.stopPropagation();
                            approveSettlement(settlement.id);
                          }}
                        >
                          Approve
                        </ApproveButton>
                        <RejectButton
                          onClick={e => {
                            e.stopPropagation();
                            setSettlement(settlement);
                            openRejectionReasonModal();
                          }}
                        >
                          Reject
                        </RejectButton>
                      </Stack>
                    )}
                </td>
                <td>Tsega Abebe Lemma</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {settlement?.receipt_photo && (
          <ReceiptPreview>
            <ReceiptPreviewTitle>Receipt Photo</ReceiptPreviewTitle>
            <ReceiptPhoto
              src={`${IMAGE_URL}/${settlement?.receipt_photo}`}
              alt="Receipt"
            />
            <Button flavor="outlined">View Full Size</Button>
          </ReceiptPreview>
        )}
      </Stack>
    </Container>
  );
};
