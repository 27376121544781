import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Stack } from "@mui/material";
import { Button, Select, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTeam, useTeamTypes, useTeams, useUpdateTeam } from "../api";
import { useTeamsStore } from "../stores";
import { teamSchema } from "../validation";

export function UpdateTeamModal() {
  const updateTeamModalOpen = useTeamsStore(state => state.updateTeamModalOpen);
  const closeUpdateTeamModal = useTeamsStore(
    state => state.closeUpdateTeamModal
  );
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const teamMutation = useUpdateTeam();
  const teamQuery = useTeam({
    churchId: selectedChurch?.id,
    teamId: id
  });
  const teamTypeQuery = useTeamTypes({
    churchId: selectedChurch?.id
  });
  const teamsQuery = useTeams({
    churchId: selectedChurch?.id
  });
  const [teamTypes, setTeamTypes] = useState([]);
  const [teams, setTeams] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (teamQuery.isSuccess) {
      reset({
        ...teamQuery.data.result,
        parent_team_id:
          teamQuery.data.result.parent_team_id === null
            ? "none"
            : teamQuery.data.result.parent_team_id
      });
    }
  }, [teamQuery.isSuccess]);

  useEffect(() => {
    if (teamTypeQuery.isSuccess) {
      const teamTypeOptions = [];
      for (let teamType of teamTypeQuery.data.result) {
        teamTypeOptions.push({
          optionLabel: _.capitalize(teamType.name),
          value: teamType.id
        });
      }
      setTeamTypes(teamTypeOptions);
    }
  }, [teamTypeQuery.isSuccess]);

  useEffect(() => {
    if (teamsQuery.isSuccess) {
      const teamOptions = [{ optionLabel: "None", value: "none" }];
      for (let teamType of teamsQuery.data.result) {
        teamOptions.push({
          optionLabel: _.capitalize(teamType.name),
          value: teamType.id
        });
      }
      setTeams(teamOptions);
    }
  }, [teamsQuery.isSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(teamSchema)
  });

  const onSubmit = values => {
    const data = { ...values };

    if (values["parent_team_id"] === "none") {
      data["parent_team_id"] = null;
    }

    teamMutation.mutate({
      churchId: selectedChurch?.id,
      teamId: id,
      teamData: data
    });
  };

  return (
    <Modal open={updateTeamModalOpen} onClose={closeUpdateTeamModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          Update team
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label={t("teams.createTeam.NameOfTheTeam")}
            name="name"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            register={register}
            isLabelBold={1}
          />
          <Select
            label={t("teams.createTeam.ParentTeam")}
            name="parent_team_id"
            options={teams}
            error={!!errors.parent_team_id?.message}
            helperText={errors.parent_team_id?.message}
            register={register}
            isLabelBold={1}
          />
          <Select
            label={t("teams.createTeam.TypeOfTeam")}
            name="team_type_id"
            options={teamTypes}
            error={!!errors.team_type_id?.message}
            helperText={errors.team_type_id?.message}
            register={register}
            isLabelBold={1}
          />
          <TextField
            label={t("teams.createTeam.TeamDescription")}
            name="description"
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            register={register}
            multiline
            minRows={4}
            fullWidth
            isLabelBold={1}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              flavor="secondary"
              onClick={closeUpdateTeamModal}
              disabled={teamMutation.isLoading}
            >
              {t("form.common.cancel")}
            </Button>
            <Button
              type="submit"
              size="large"
              disabled={teamMutation.isLoading}
            >
              {t("form.common.update")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
