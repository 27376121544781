import styled from "@emotion/styled";
import { Avatar, Chip, Divider, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { UserCircle } from "@phosphor-icons/react";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useUsersPaginated } from "features/users";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserAnalytics } from "../api";
import { SectionBar } from "./SectionBar";
import { PieChart } from "./charts/PieChart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 22px;
`;

const SectionTitle = styled.h4`
  margin: 0;
`;

const FlatCard = styled.div`
  background: #f3f4f6;
  border-radius: 6px;
  flex: 1;
  border-left: 22px solid ${({ color }) => (color ? color : "#018abe")};
  padding: 11px;
`;

const LegendBox = styled.div`
  min-width: 200px;
  max-width: 200px;
  min-height: 70px;
  border-radius: 4px;
  padding: 18px 14px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;

  h2,
  h4 {
    margin: 0;
    padding: 0;
  }
`;

export const SystemAdminsView = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();

  const columns = [
    {
      field: "name",
      headerName: t("dashboard.systemUser.Name"),
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row?.image_url}`}
              alt={row?.first_name}
              sx={{ border: "2px solid", borderColor: "primary.main" }}
            />
            <Typography>
              {row?.first_name} {row?.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "role",
      headerName: t("dashboard.systemUser.Role"),
      flex: 1,
      renderCell: ({ row }) => {
        return <Chip label={row?.role?.name} color="primary" />;
      }
    },
    {
      field: "status",
      headerName: t("dashboard.systemUser.Status"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Chip
            label={row?.account_users?.[0]?.status ? "Active" : "Inactive"}
            color={row?.account_users?.[0]?.status ? "success" : "error"}
          />
        );
      }
    },
    {
      field: "loggedIn",
      headerName: t("dashboard.systemUser.LastLoggedIn"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.latest_user_login
              ? moment(row?.latest_user_login?.logged_in_at).format(
                  "DD-MM-YYYY"
                )
              : "NA"}
          </Typography>
        );
      }
    }
  ];

  const userAnalyticsQuery = useUserAnalytics({
    churchId: selectedChurch?.id
  });

  const usersPaginatedQuery = useUsersPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize
  });

  return (
    <Container>
      <SectionBar
        icon={<UserCircle size={24} />}
        title={t("dashboard.systemUser.SystemUsersData")}
        color="#E69348"
      />

      <Section>
        <Stack spacing={4}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <FlatCard color="#E69348">
              <Stack spacing={1}>
                <Stack
                  sx={{
                    alignSelf: "flex-end",
                    p: 1,
                    background: "#E69348",
                    color: "white",
                    borderRadius: "50%"
                  }}
                >
                  <UserCircle size={24} />
                </Stack>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("dashboard.systemUser.TotalSystemUser")}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {userAnalyticsQuery.data?.total_users ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
            <FlatCard color="#4ADE80">
              <Stack spacing={1}>
                <Stack
                  sx={{
                    alignSelf: "flex-end",
                    p: 1,
                    background: "#E69348",
                    color: "white",
                    borderRadius: "50%"
                  }}
                >
                  <UserCircle size={24} />
                </Stack>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("dashboard.systemUser.TotalActiveSystemUsers")}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {userAnalyticsQuery.data?.active_users ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
            <FlatCard color="#F87171">
              <Stack spacing={1}>
                <Stack
                  sx={{
                    alignSelf: "flex-end",
                    p: 1,
                    background: "#E69348",
                    color: "white",
                    borderRadius: "50%"
                  }}
                >
                  <UserCircle size={24} />
                </Stack>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("dashboard.systemUser.TotalInactiveSystemUsers")}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {userAnalyticsQuery.data?.inactive_users ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
          </Stack>
          <Divider />
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <SectionTitle>
                {t("dashboard.systemUser.UserStatus")}
              </SectionTitle>
            </Stack>
            <Stack direction="row">
              <Stack flex={1}>
                <PieChart
                  showLegend={false}
                  labels={[
                    t("dashboard.systemUser.InactiveUser"),
                    t("dashboard.systemUser.ActiveUser")
                  ]}
                  data={[
                    userAnalyticsQuery.data?.inactive_users ?? 0,
                    userAnalyticsQuery.data?.active_users ?? 0
                  ]}
                  height={300}
                />
              </Stack>
              <Stack justifyContent="center" spacing={2}>
                <LegendBox color="#74DFD5">
                  <h4>{t("dashboard.systemUser.ActiveUser")}</h4>
                  <h2>{userAnalyticsQuery.data?.active_users ?? 0}</h2>
                </LegendBox>
                <LegendBox color="#FBD8B0">
                  <h4>{t("dashboard.systemUser.InactiveUser")}</h4>
                  <h2>{userAnalyticsQuery.data?.inactive_users ?? 0}</h2>
                </LegendBox>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Section>

      <Section>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SectionTitle>
            {t("dashboard.systemUser.MostLoggedInUser")}
          </SectionTitle>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          mt={4}
          sx={{ overflowX: "auto", gap: "10px", textAlign: "center" }}
        >
          {userAnalyticsQuery.data?.most_logged_users?.map(user => (
            <UserInfo key={user} data={user} />
          ))}
        </Stack>
      </Section>

      <Stack spacing={2}>
        <SectionTitle>
          {t("dashboard.systemUser.AllSystemUsersInChurch")}
        </SectionTitle>
        <DataGrid
          autoHeight
          rows={usersPaginatedQuery?.data?.result?.data || []}
          rowCount={usersPaginatedQuery?.data?.result?.total || 0}
          columns={columns}
          pagination
          rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
          density="comfortable"
          getRowId={row => row.id}
          disableColumnMenu
          disableSelectionOnClick
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={newPage => setPage(newPage)}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: "#E69348",
              color: "black"
            }
          }}
        />
      </Stack>
    </Container>
  );
};

const UserInfo = ({ data }) => {
  return (
    <Stack alignItems="center" spacing={1} sx={{ paddingY: "4px" }}>
      <Avatar
        src={`${IMAGE_URL}/${data?.image_url}`}
        alt={`${data?.first_name} ${data?.last_name}`}
        sx={{
          height: "62px",
          width: "62px",
          outline: "2px solid",
          outlineColor: "primary.main"
        }}
      />
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {data?.first_name} {data?.last_name}
      </Typography>
      <Chip label={data?.role?.name ?? "User"} color="primary" />
      <Typography variant="caption">Last seen</Typography>
      <Typography variant="caption" sx={{ fontWeight: 500 }}>
        {moment(data?.latest_user_login?.logged_in_at).fromNow()}
      </Typography>
    </Stack>
  );
};
