import { Groups } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { Button } from "components/UI";
import { useTranslation } from "react-i18next";
import { useTeamsStore } from "../stores";
import { CreateTeamModal } from "./CreateTeamModal";
import { Skeletons } from "./Skeletons";

export function EmptyTeams() {
  const openCreateTeamModal = useTeamsStore(state => state.openCreateTeamModal);
  // const openCreateTeamTypeModal = useTeamsStore(
  //   state => state.openCreateTeamTypeModal
  // );
  const { t } = useTranslation();

  return (
    <Box>
      <Stack alignItems="center" sx={{ mt: 9, mb: 2 }}>
        <Skeletons />
      </Stack>
      <Stack alignItems="center" justifyContent="center" spacing={2}>
        <Typography fontWeight="700" variant="subtitle1">
          {t("teams.teamsEmptyState.StartByCreatingATeam")}
        </Typography>
        <Stack spacing={2}>
          {/* <Button
            variant="contained"
            startIcon={<Groups />}
            onClick={openCreateTeamTypeModal}
          >
            {t("teams.teamsEmptyState.CreateATeamType")}
          </Button> */}
          <Button
            variant="contained"
            startIcon={<Groups />}
            onClick={openCreateTeamModal}
          >
            {t("teams.teamsEmptyState.CreateTeam")}
          </Button>
        </Stack>
        <CreateTeamModal />
      </Stack>
    </Box>
  );
}
