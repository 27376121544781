import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useBudgetGroupsPaginated,
  useFinanceSettingsStore
} from "features/finances";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

export const BudgetTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const openSetBudgetModal = useFinanceSettingsStore(
    state => state.openSetBudgetModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const budgetGroupsQuery = useBudgetGroupsPaginated({
    churchId: selectedChurch?.id,
    page,
    pageSize
  });
  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "Department Name",
      flex: 1,
      renderCell: ({ row }) => <span>{row.team?.name}</span>
    },
    {
      field: "total_assigned",
      headerName: "Budget Assigned",
      flex: 1
    },
    {
      field: "total_used",
      headerName: "Budget Used",
      flex: 1
    },
    {
      field: "total_left",
      headerName: "Budget Left",
      flex: 1
    }
  ];

  return (
    <Container>
      <Header>
        <Title>Department Budget</Title>
        <Button onClick={() => openSetBudgetModal()}>Set Budget</Button>
      </Header>
      <DataGrid
        autoHeight
        rows={budgetGroupsQuery.data?.result?.data || []}
        rowCount={budgetGroupsQuery.data?.result?.total || 0}
        loading={budgetGroupsQuery.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.team?.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
        onRowClick={({ row }) => {
          console.log(row);
          navigate(`/finances/temp/budget/${row.team.id}`);
        }}
      />
    </Container>
  );
};
