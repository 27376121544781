import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const rejectSettlement = ({ churchId, expenseId, settlementId }) => {
  return axios.patch(
    `/churches/${churchId}/expenses/${expenseId}/settlements/${settlementId}/reject`
  );
};

export const useRejectSettlement = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeRejectionReasonModal =
    useExpenseStore.getState().closeRejectionReasonModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("settlements");

      setNotification({
        type: "success",
        message: "Settlement rejected successfully!"
      });

      closeRejectionReasonModal();
    },
    ...config,
    mutationFn: rejectSettlement
  });
};
