import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getRequisitionsPaginated = ({ churchId, page, pageSize }) => {
  return axios.get(
    `/churches/${churchId}/requisitions-paginated?page=${page}&per_page=${pageSize}`
  );
};

export const useRequisitionsPaginated = ({
  churchId,
  page,
  pageSize,
  config
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["requisitions-paginated", churchId, page, pageSize],
    queryFn: () => getRequisitionsPaginated({ churchId, page, pageSize })
  });
};
