import styled from "@emotion/styled";
import { Alert, Notification } from "components/UI";
import { Outlet } from "react-router-dom";
import { useTitle } from "react-use";
import {
  CreateTemplateModal,
  MessageSidebar,
  TemplateSelectorModal
} from "../components";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  margin-block: 28px;
  overflow: hidden;
  height: 913px;
`;

const Wrapper = styled.div`
  padding-top: 32px;
`;

export function Messages() {
  useTitle("Messages");

  return (
    <Wrapper>
      <Alert message={"dashboard.common.demoMessage"} />
      <Container>
        <Notification />
        <CreateTemplateModal />
        <TemplateSelectorModal />
        <MessageSidebar />
        <Outlet />
      </Container>
    </Wrapper>
  );
}
