import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useRevenueMethods, useRevenueTypes } from "../api";
import {
  CreateRevenueMethodModal,
  CreateRevenueTypeModal,
  UpdateRevenueMethodModal,
  UpdateRevenueTypeModal
} from "../components";
import { useRevenueStore } from "../store";

const Container = styled.div`
  padding-block: 38px;
  display: grid;
  grid-template-columns: 206px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "menu content";
  gap: 16px;
`;

const Menu = styled.div`
  min-height: 100vh;
  border-radius: 4px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
`;

const MenuTitle = styled.div`
  border-bottom: 1px solid #cbd5e1;
  background: #e6f7f8;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 13px;
`;

const MenuItems = styled.div`
  flex: 1;
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 12px;
`;

const MenuItem = styled.div`
  padding: 10px 16px;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.palette.primary.main : "transparent"};
  border-radius: 4px;
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "#000"};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 700px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;

  th {
    padding-bottom: 14px;
    border-bottom: 1px solid #d4d4d4;
  }

  & td {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
  }
`;

export const RevenueSettings = () => {
  const [activeTab, setActiveTab] = useState("type");
  const [revenueType, setRevenueType] = useState(null);
  const [revenueMethod, setRevenueMethod] = useState(null);
  const openCreateRevenueTypeModal = useRevenueStore(
    state => state.openCreateRevenueTypeModal
  );
  const openCreateRevenueMethodModal = useRevenueStore(
    state => state.openCreateRevenueMethodModal
  );
  const openUpdateRevenueTypeModal = useRevenueStore(
    state => state.openUpdateRevenueTypeModal
  );
  const openUpdateRevenueMethodModal = useRevenueStore(
    state => state.openUpdateRevenueMethodModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const revenueTypesQuery = useRevenueTypes({
    churchId: selectedChurch?.id
  });
  const revenueMethodsQuery = useRevenueMethods({
    churchId: selectedChurch?.id
  });

  const isNotDefaultType = id => {
    return id > 10;
  };

  const isNotDefaultMethod = id => {
    return id > 5;
  };

  return (
    <Container>
      <CreateRevenueTypeModal />
      <UpdateRevenueTypeModal data={revenueType} />
      <CreateRevenueMethodModal />
      <UpdateRevenueMethodModal data={revenueMethod} />
      <Notification />
      <Menu>
        <MenuTitle>Revenue Setting</MenuTitle>
        <MenuItems>
          <MenuItem
            active={activeTab === "type"}
            onClick={() => setActiveTab("type")}
          >
            Revenue Type
          </MenuItem>
          <MenuItem
            active={activeTab === "method"}
            onClick={() => setActiveTab("method")}
          >
            Revenue Method
          </MenuItem>
        </MenuItems>
      </Menu>
      <Content>
        <Stack alignItems="flex-end">
          {activeTab === "type" && (
            <Button
              style={{
                fontSize: "14px"
              }}
              onClick={openCreateRevenueTypeModal}
            >
              Create Revenue Type
            </Button>
          )}
          {activeTab === "method" && (
            <Button
              style={{
                fontSize: "14px"
              }}
              onClick={openCreateRevenueMethodModal}
            >
              Create Revenue Method
            </Button>
          )}
        </Stack>
        <TableContainer>
          {activeTab === "type" && (
            <Table>
              <thead>
                <tr>
                  <th>Type Name</th>
                  <th>Description type</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {revenueTypesQuery.data?.result?.map(revenueType => (
                  <tr key={revenueType.id}>
                    <td>{revenueType.name}</td>
                    <td>{revenueType.description}</td>
                    <td>
                      {isNotDefaultType(revenueType.id) && (
                        <Button
                          style={{
                            fontSize: "12px"
                          }}
                          onClick={() => {
                            setRevenueType(revenueType);
                            openUpdateRevenueTypeModal();
                          }}
                        >
                          Update
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {activeTab === "method" && (
            <Table>
              <thead>
                <tr>
                  <th>Revenue Method</th>
                  <th>Description type</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {revenueMethodsQuery.data?.result?.map(revenueMethod => (
                  <tr key={revenueMethod.id}>
                    <td>{revenueMethod.name}</td>
                    <td>{revenueMethod.description}</td>
                    <td>
                      {isNotDefaultMethod(revenueMethod.id) && (
                        <Button
                          style={{
                            fontSize: "12px"
                          }}
                          onClick={() => {
                            setRevenueMethod(revenueMethod);
                            openUpdateRevenueMethodModal();
                          }}
                        >
                          Update
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </Content>
    </Container>
  );
};
