import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const updateExpenseTypes = ({
  churchId,
  expenseTypeData,
  expenseTypeId
}) => {
  return axios.patch(
    `/churches/${churchId}/expense-types/${expenseTypeId}`,
    expenseTypeData
  );
};

export const useUpdateExpenseTypes = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateExpenseTypeModal =
    useExpenseStore.getState().closeUpdateExpenseTypeModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["expense-types"]);

      setNotification({
        type: "success",
        message: "Expense type updated successfully!"
      });

      closeUpdateExpenseTypeModal();
    },
    ...config,
    mutationFn: updateExpenseTypes
  });
};
