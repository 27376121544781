import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useMembersStore } from "features/members";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import { previousChurchInfoSchema } from "./schema";
import { PreviousChurchInfoSection } from "./sections";

export function PublicPreviousChurchInfoForm() {
  const { t } = useTranslation();
  const { prev, next } = useSteps();
  const memberData = useMembersStore(state => state.memberData);
  const setMemberData = useMembersStore(state => state.setMemberData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      transfer_from_other_church: memberData.transfer_from_other_church
        ? memberData.transfer_from_other_church
        : "",
      previous_church_name: memberData.previous_church_name
        ? memberData.previous_church_name
        : "",
      date_of_resignation: memberData.date_of_resignation
        ? memberData.date_of_resignation
        : null,
      pastor_title: memberData.pastor_title ? memberData.pastor_title : "",
      pastor_full_name: memberData.pastor_full_name
        ? memberData.pastor_full_name
        : "",
      pastor_phone_number: memberData.pastor_phone_number
        ? memberData.pastor_phone_number
        : "",
      reason_for_resignation: memberData.reason_for_resignation
        ? memberData.reason_for_resignation
        : ""
    },
    resolver: yupResolver(previousChurchInfoSchema)
  });

  const onSubmit = data => {
    setMemberData(data);
    next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PreviousChurchInfoSection
        register={register}
        errors={errors}
        control={control}
        watch={watch}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 8 }}
      >
        <Button
          type="submit"
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={prev}
        >
          {t("form.common.back")}
        </Button>
        <Button type="submit" variant="contained" endIcon={<ArrowForward />}>
          {t("form.common.next")}
        </Button>
      </Stack>
    </form>
  );
}
