import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import { Notification } from "components/UI";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  CreateBankAccountModal,
  CreateRequisitionModal,
  ErrorModal,
  Menu,
  RecordExpenseModal,
  RecordRevenueModal,
  StatCard,
  SuccessModal,
  Tabs,
  UpdateBankAccountModal,
  UpdateRevenueModal
} from "../components";

const Container = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-template-rows: 176px 1fr;
  gap: 16px;
  grid-template-areas:
    "sidemenu actions"
    "sidemenu transactions";
`;

export const FinancesLayout = () => {
  const tabs = [
    {
      name: "Finance Status",
      translation: "finances.tab.FinanceStatus"
    },
    {
      name: "Balance in Bank",
      translation: "finances.tab.BalanceInBank"
    }
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]["name"]);

  return (
    <Container>
      <Notification />
      <SuccessModal />
      <ErrorModal />
      <RecordRevenueModal />
      <UpdateRevenueModal />
      <CreateBankAccountModal />
      <UpdateBankAccountModal />
      <RecordExpenseModal />
      <CreateRequisitionModal />
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <StatGrid>
        <StatCard title="Total Balance" stat="200000" color="#7FA457" />
        <StatCard title="Total Revenue" stat="100000" color="#74DFD5" />
        <StatCard title="Total Expense" stat="200000" color="#F8BC77" />
      </StatGrid>
      <Divider />
      <Content>
        <Menu />
        <Outlet />
      </Content>
    </Container>
  );
};
