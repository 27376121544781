import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Divider, Stack } from "@mui/material";
import { formatISO, parseISO } from "date-fns";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateKid, useKid, useUpdateKid } from "../api";
import { useMembersStore } from "../stores";
import { MemberDataManipulator } from "../utils";
import { createKidSchema } from "../validation";
import {
  KidAddressSection,
  KidFamilyInformationSection,
  KidPersonalProfileSection
} from "./sections";

export function KidForm({ isEditMode }) {
  const kidCreateMutation = useCreateKid();
  const kidUpdateMutation = useUpdateKid();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const kidData = useMembersStore(state => state.kidData);
  const setKidData = useMembersStore(state => state.setKidData);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      first_name: "",
      father_name: "",
      last_name: "",
      gender: "",
      nationality_id: "",
      birth_country_id: "",
      birth_region_id: "",
      birth_zone_id: "",
      birth_city_id: "",
      date_of_birth: null,
      mother_language: "",
      second_language: "",
      academic_status: "",
      status: "kid",
      is_primary: "",
      primary_id: "",
      primary_first_name: "",
      primary_father_name: "",
      primary_last_name: "",
      primary_relationship: "",
      primary_religion: "",
      primary_phone: "",
      primary_attending_church: "",
      is_secondary: "",
      secondary_id: "",
      secondary_first_name: "",
      secondary_father_name: "",
      secondary_last_name: "",
      secondary_relationship: "",
      secondary_religion: "",
      secondary_phone: "",
      secondary_attending_church: "",
      country_id: "",
      region_id: "",
      zone_id: "",
      city_id: "",
      subcity_id: "",
      woreda_id: "",
      kebele: "",
      house_number: "",
      specific_location: ""
    },
    resolver: yupResolver(createKidSchema)
  });

  useEffect(() => {
    if (isEditMode && kidData) {
      reset({
        first_name: kidData?.first_name ?? "",
        father_name: kidData?.father_name ?? "",
        last_name: kidData?.last_name ?? "",
        gender: kidData?.gender ?? "",
        nationality_id: kidData?.nationality_id
          ? `${kidData?.nationality_id}`
          : "",
        birth_country_id: kidData?.birth_country_id ?? "",
        birth_region_id: kidData?.birth_region_id ?? "",
        birth_zone_id: kidData?.birth_zone_id ?? "",
        birth_city_id: kidData?.birth_city_id ?? "",
        date_of_birth: kidData.date_of_birth
          ? parseISO(kidData.date_of_birth)
          : null,
        mother_language: kidData?.mother_language ?? "",
        second_language: kidData?.second_language ?? "",
        academic_status: kidData?.academic_status ?? "",
        status: kidData?.status ?? "kid",
        is_primary: kidData?.is_primary ? `${kidData?.is_primary}` : "",
        primary_id: kidData?.primary_id ?? "",
        primary_first_name: kidData?.primary_first_name ?? "",
        primary_father_name: kidData?.primary_father_name ?? "",
        primary_last_name: kidData?.primary_last_name ?? "",
        primary_relationship: kidData?.primary_relationship ?? "",
        primary_religion: kidData?.primary_religion ?? "",
        primary_phone: kidData?.primary_phone ?? "",
        primary_attending_church: kidData?.primary_attending_church ?? "",
        is_secondary: kidData?.is_secondary ? `${kidData?.is_secondary}` : "",
        secondary_id: kidData?.secondary_id ?? "",
        secondary_first_name: kidData?.secondary_first_name ?? "",
        secondary_father_name: kidData?.secondary_father_name ?? "",
        secondary_last_name: kidData?.secondary_last_name ?? "",
        secondary_relationship: kidData?.secondary_relationship ?? "",
        secondary_religion: kidData?.secondary_religion ?? "",
        secondary_phone: kidData?.secondary_phone ?? "",
        secondary_attending_church: kidData?.secondary_attending_church ?? "",
        country_id: kidData?.country_id ? `${kidData?.country_id}` : "",
        region_id: kidData?.region_id ? `${kidData?.region_id}` : "",
        zone_id: kidData?.zone_id ? `${kidData?.zone_id}` : "",
        city_id: kidData?.city_id ? `${kidData?.city_id}` : "",
        subcity_id: kidData?.subcity_id ? `${kidData?.subcity_id}` : "",
        woreda_id: kidData?.woreda_id ? `${kidData?.woreda_id}` : "",
        kebele: kidData?.kebele ?? "",
        house_number: kidData?.house_number ?? "",
        specific_location: kidData?.specific_location ?? ""
      });

      setValue("is_primary", `${kidData?.is_primary}`);
      setValue("is_secondary", `${kidData?.is_secondary}`);
    }
  }, [isEditMode, kidData]);

  const kidQuery = useKid({
    churchId: selectedChurch?.id,
    kidId: id,
    config: {
      enabled: !!id
    }
  });

  useEffect(() => {
    if (kidQuery.data) {
      setKidData(kidQuery.data.result);
    }
  }, [kidQuery.data]);

  const onSubmit = data => {
    let cleanKidData = _.cloneDeep(data);

    const memberDataManipulator = new MemberDataManipulator();

    memberDataManipulator.convertValuesToDates(cleanKidData, ["date_of_birth"]);

    memberDataManipulator.removeEmptyValues(cleanKidData);

    memberDataManipulator.convertValuesToNumbers(cleanKidData, [
      "is_primary",
      "is_secondary"
    ]);

    const parents = [];

    if (cleanKidData.is_primary === 1) {
      parents.push({
        member_id: isEditMode
          ? kidData.parents?.[0]?.member_id
          : cleanKidData.primary_id,
        relation_type: isEditMode
          ? kidData.parents?.[0]?.relation_type
          : cleanKidData.primary_relationship
      });

      memberDataManipulator.removeFromObject(cleanKidData, [
        "primary_id",
        "primary_religion",
        "primary_attending_church"
      ]);

      cleanKidData.parents = parents;

      memberDataManipulator.formatArrayStructure(cleanKidData, ["parents"]);
    }

    if (cleanKidData.is_secondary === 1) {
      parents.push({
        member_id: isEditMode
          ? kidData.parents?.[kidData?.parents.length - 1]?.member_id
          : cleanKidData.secondary_id,
        relation_type: isEditMode
          ? kidData.parents?.[kidData?.parents.length - 1]?.relation_type
          : cleanKidData.secondary_relationship
      });

      memberDataManipulator.removeFromObject(cleanKidData, [
        "secondary_id",
        "secondary_religion",
        "secondary_attending_church"
      ]);

      cleanKidData.parents = parents;

      memberDataManipulator.formatArrayStructure(cleanKidData, ["parents"]);
    }

    const kidFormData =
      memberDataManipulator.convertObjectToFormData(cleanKidData);

    if (data.image_kid) {
      kidFormData.append("image_kid", data.image_kid);
    }

    kidFormData.append("is_kid", "1");

    kidFormData.set(
      "date_of_birth",
      formatISO(parseISO(kidFormData.get("date_of_birth")), {
        representation: "date"
      })
    );

    if (isEditMode) {
      kidUpdateMutation.mutate({
        churchId: selectedChurch?.id,
        kidData: kidFormData,
        kidId: id
      });
    } else {
      kidCreateMutation.mutate({
        churchId: selectedChurch?.id,
        kidData: kidFormData
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DevTool control={control} />
      <KidPersonalProfileSection
        register={register}
        errors={errors}
        isEditMode={isEditMode}
        setValue={setValue}
        watch={watch}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <KidFamilyInformationSection
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        setValue={setValue}
      />
      <Divider sx={{ my: 3 }} />
      <KidAddressSection
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
      />
      {(kidCreateMutation.isError || kidUpdateMutation.isError) && (
        <Stack mt={4}>
          {!isEditMode && kidCreateMutation.isError && (
            <Alert variant="filled" severity="error">
              {typeof kidCreateMutation.error?.errors === "string" ? (
                <div>{kidCreateMutation.error?.errors}</div>
              ) : (
                Object.values(kidCreateMutation.error?.errors)?.map(err => (
                  <div key={err}>{err}</div>
                ))
              )}
            </Alert>
          )}

          {isEditMode && kidUpdateMutation.isError && (
            <Alert variant="filled" severity="error">
              {typeof kidUpdateMutation.error?.errors === "string" ? (
                <div>{kidUpdateMutation.error?.errors}</div>
              ) : (
                Object.values(kidUpdateMutation.error?.errors)?.map(err => (
                  <div key={err}>{err}</div>
                ))
              )}
            </Alert>
          )}
        </Stack>
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 8 }}
      >
        <Button
          color="neutral"
          size="large"
          variant="contained"
          disabled={kidCreateMutation.isLoading || kidUpdateMutation.isLoading}
          onClick={() => {
            reset();
            navigate("/members");
          }}
        >
          {t("form.common.cancel")}
        </Button>
        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={kidCreateMutation.isLoading || kidUpdateMutation.isLoading}
        >
          {t("form.common.submit")}
        </Button>
      </Stack>
    </form>
  );
}
