import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useTeamsStore } from "../stores";

export const deleteTeam = ({ churchId, teamId }) => {
  return axios.delete(`/churches/${churchId}/teams/${teamId}`);
};

export const useDeleteTeam = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteTeamModal = useTeamsStore.getState().closeDeleteTeamModal;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["teams"]);
      queryClient.invalidateQueries(["team_types"]);
      setNotification({
        type: "success",
        message: "Team deleted successfully!"
      });
      closeDeleteTeamModal();
      navigate("/teams");
    },
    ...config,
    mutationFn: deleteTeam
  });
};
