import styled from "@emotion/styled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses
} from "@mui/material";
import { Button } from "components/UI";
import { useAssetsStore, useCreateAsset } from "features/assets";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsXSquare } from "react-icons/bs";
import { AssetModalSteps } from "../AssetModalSteps";
import { AcquisitionForm } from "../Forms/AcquisitionForm";
import { BasicInfoForm } from "../Forms/BasicInfoForm";
import { ComplianceForm } from "../Forms/ComplianceForm";
import { FinancialInfoForm } from "../Forms/FinancialInfoForm";

const CloseIcon = styled(BsXSquare)`
  color: #ffffff;
  height: 22px;
  width: 22px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
`;

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(233, 233, 233, 1)"
  }
}));

export const AddAssetModal = ({ open, onClose }) => {
  const addAssetFormActiveStep = useAssetsStore(
    state => state.addAssetFormActiveStep
  );
  const setAddAssetFormActiveStep = useAssetsStore(
    state => state.setAddAssetFormActiveStep
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const { t } = useTranslation();

  const [documents, setDocuments] = useState([]);

  const createAssetMutation = useCreateAsset();

  const {
    register,
    formState: { errors, defaultValues },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      name: "",
      type: "",
      description: "",
      quantity: "",
      acquisition_method: "",
      acquisition_date: "",
      purchase_price: "",
      vendor: "",
      assigned_to: "",
      depreciation_method: "",
      location: "",
      status: "",
      condition: "",
      depreciation_rate: "",
      book_value: "",
      maintenance_schedule: "",
      last_maintenance_date: "",
      warranty_expiry_date: "",
      documents: null,
      compliance_status: "",
      notes: ""
    },
    mode: "onBlur"
  });

  const forms = {
    1: <BasicInfoForm register={register} errors={errors} />,
    2: <AcquisitionForm register={register} errors={errors} />,
    3: <FinancialInfoForm register={register} errors={errors} />,
    4: (
      <ComplianceForm
        register={register}
        errors={errors}
        documents={documents}
        setDocuments={setDocuments}
      />
    )
  };

  const onSubmit = values => {
    const formData = new FormData();

    for (let key in values) {
      formData.append(key, values[key]);
    }

    if (documents.length !== 0) {
      for (const [index, document] of documents.entries()) {
        formData.append(`document[${index}]`, document);
      }
      formData.delete("documents");
    } else {
      formData.delete("documents");
    }

    createAssetMutation.mutate(
      {
        churchId: selectedChurch?.id,
        assetData: formData
      },
      {
        onSuccess: () => {
          reset({
            ...defaultValues
          });
        }
      }
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        setAddAssetFormActiveStep(1);
        onClose();
      }}
    >
      <DialogTitle sx={{ bgcolor: "primary.main" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" color="primary.contrastText">
            {t("Asset.AssetRegistration.AssetRegistration")}
          </Typography>
          <CloseIcon
            onClick={() => {
              setAddAssetFormActiveStep(1);
              onClose();
            }}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack py={1} spacing={2}>
          <BorderLinearProgress
            variant="determinate"
            value={(addAssetFormActiveStep / 4) * 100}
          />
          <AssetModalSteps />
          <Stack px={3}>{forms[addAssetFormActiveStep]}</Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button flavor="text" onClick={onClose}>
          {t("form.common.cancel")}
        </Button>
        {addAssetFormActiveStep !== 1 && (
          <Button
            flavor="outlined"
            onClick={() =>
              setAddAssetFormActiveStep(addAssetFormActiveStep - 1)
            }
          >
            {t("form.common.back")}
          </Button>
        )}
        {addAssetFormActiveStep !== 4 && (
          <Button
            onClick={() =>
              setAddAssetFormActiveStep(addAssetFormActiveStep + 1)
            }
          >
            {t("form.common.next")}
          </Button>
        )}
        {addAssetFormActiveStep === 4 && (
          <Button onClick={handleSubmit(onSubmit)}>
            {t("form.common.submit")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
