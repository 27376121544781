import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Modal, Stack } from "@mui/material";
import { TextField } from "components/UI";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useUsersStore } from "../stores";

const roleSchema = yup
  .object({
    role_name: yup.string().required("Name is required"),
    role_description: yup.string().required("Description is required")
  })
  .required();

export function CreateRoleModal() {
  const createRoleModalOpen = useUsersStore(state => state.createRoleModalOpen);
  const closeCreateRoleModal = useUsersStore(
    state => state.closeCreateRoleModal
  );

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      role_name: "",
      role_description: ""
    },
    resolver: yupResolver(roleSchema)
  });

  const onSubmit = data => {
    console.log(data);
  };

  return (
    <Modal open={createRoleModalOpen} onClose={closeCreateRoleModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          Create New Role
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label="Role Name"
            name="role_name"
            error={!!errors.role_name?.message}
            helperText={errors.role_name?.message}
            register={register}
            isLabelBold={1}
          />
          <TextField
            label="Role Description"
            name="role_description"
            error={!!errors.role_description?.message}
            helperText={errors.role_description?.message}
            register={register}
            multiline
            minRows={4}
            fullWidth
            isLabelBold={1}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => {}}
              disabled={false}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={false}
            >
              Create Role
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
