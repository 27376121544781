import styled from "@emotion/styled";
import {
  FilterAltOutlined,
  Search,
  VisibilityOutlined
} from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebounce, useSearchParam } from "react-use";
import {
  FILTER_OPERATIONS,
  MEMBER_STATES,
  SEARCH_PLACEHOLDERS,
  TAB_NAMES
} from "../constants";
import { useMembersStore } from "../stores";

const StyledTab = styled(Tab)`
  color: white;

  &.Mui-selected {
    border-radius: 5px;
    background-color: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export function MembersTableHeader({ isFilterMode = false }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const queries = useMembersStore(state => state.queries);
  const setQueries = useMembersStore(state => state.setQueries);
  const openColumnsVisibilityModal = useMembersStore(
    state => state.openColumnsVisibilityModal
  );
  const currentTab = useMembersStore(state => state.currentTab);
  const setCurrentTab = useMembersStore(state => state.setCurrentTab);

  const tabParam = useSearchParam("t");

  useEffect(() => {
    switch (tabParam) {
      case "requests":
        setCurrentTab(TAB_NAMES.APPLICATION_REQUESTS);
        break;

      case "inactive":
        setCurrentTab(TAB_NAMES.INACTIVE_MEMBERS);
        break;

      case "kids":
        setCurrentTab(TAB_NAMES.KIDS);
        break;

      case "partners":
        setCurrentTab(TAB_NAMES.PARTNERS);
        break;
    }
  }, [tabParam]);

  useDebounce(
    () => {
      setDebouncedValue(searchTerm);
    },
    500,
    [searchTerm]
  );

  useEffect(() => {
    if (debouncedValue) {
      setQueries({
        ...queries,
        search: {
          first_name: debouncedValue
        }
      });
    } else {
      if (currentTab === TAB_NAMES.MEMBERS) {
        setQueries({
          filter: {
            status: [
              {
                operator: FILTER_OPERATIONS.EQUAL,
                value: MEMBER_STATES.ACTIVE
              }
            ]
          }
        });
      }

      if (currentTab === TAB_NAMES.APPLICATION_REQUESTS) {
        setQueries({
          filter: {
            status: [
              {
                operator: FILTER_OPERATIONS.NOT_EQUAL,
                value: MEMBER_STATES.ACTIVE
              },
              {
                operator: FILTER_OPERATIONS.NOT_EQUAL,
                value: MEMBER_STATES.INACTIVE
              }
            ]
          }
        });
      }

      if (currentTab === TAB_NAMES.INACTIVE_MEMBERS) {
        setQueries({
          filter: {
            status: [
              {
                operator: FILTER_OPERATIONS.EQUAL,
                value: MEMBER_STATES.INACTIVE
              }
            ]
          }
        });
      }

      if (currentTab === TAB_NAMES.KIDS) {
        setQueries({
          filter: {}
        });
      }

      if (currentTab === TAB_NAMES.PARTNERS) {
        setQueries({});
      }
    }
  }, [debouncedValue, currentTab]);

  return (
    <Box
      sx={{
        color: "white",
        backgroundColor: "primary.main",
        py: 1,
        px: 2,
        borderRadius: "5px"
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTab}
          onChange={(event, value) => {
            setCurrentTab(value);

            if (value === TAB_NAMES.MEMBERS) {
              setQueries({
                filter: {
                  status: [
                    {
                      operator: FILTER_OPERATIONS.EQUAL,
                      value: MEMBER_STATES.ACTIVE
                    }
                  ]
                }
              });
            }

            if (value === TAB_NAMES.APPLICATION_REQUESTS) {
              setQueries({
                filter: {
                  status: [
                    {
                      operator: FILTER_OPERATIONS.NOT_EQUAL,
                      value: MEMBER_STATES.ACTIVE
                    },
                    {
                      operator: FILTER_OPERATIONS.NOT_EQUAL,
                      value: MEMBER_STATES.INACTIVE
                    }
                  ]
                }
              });
            }

            if (value === TAB_NAMES.INACTIVE_MEMBERS) {
              setQueries({
                filter: {
                  status: [
                    {
                      operator: FILTER_OPERATIONS.EQUAL,
                      value: MEMBER_STATES.INACTIVE
                    }
                  ]
                }
              });
            }

            if (value === TAB_NAMES.KIDS) {
              setQueries({
                filter: {}
              });
            }

            if (value === TAB_NAMES.PARTNERS) {
              setQueries({});
            }
          }}
          indicatorColor=""
          variant="scrollable"
          scrollButtons="auto"
        >
          <StyledTab
            label={t("members.membersHeader.applicationRequest")}
            value="applicationRequests"
          />
          <StyledTab
            label={t("members.membersHeader.members")}
            value="members"
          />
          <StyledTab
            label={t("members.membersHeader.inactiveMembers")}
            value="inactiveMembers"
          />
          <StyledTab label={t("members.membersHeader.kids")} value="kids" />
          <StyledTab
            label={t("members.membersHeader.partners")}
            value="partners"
          />
        </Tabs>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder={`${t("addMembers.step3.Search")} ${t(
              SEARCH_PLACEHOLDERS[currentTab]
            )}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search color="primary" />
                </InputAdornment>
              ),
              sx: {
                px: 1,
                borderRadius: "50px",
                backgroundColor: "white",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  }
                }
              }
            }}
            onChange={e => setSearchTerm(e.target.value)}
          />
          {!isFilterMode && (
            <Button
              variant="text"
              color="secondary"
              startIcon={<FilterAltOutlined />}
              onClick={() => navigate("/members/filter")}
            >
              <Typography variant="subtitle2">
                {t("members.membersHeader.filter")}
              </Typography>
            </Button>
          )}
          <Button
            variant="text"
            color="secondary"
            startIcon={<VisibilityOutlined />}
            onClick={openColumnsVisibilityModal}
          >
            <Typography variant="subtitle2">
              {t("members.membersHeader.view")}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
