import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Modal, Stack } from "@mui/material";
import { Select, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import * as yup from "yup";
import {
  useNegaritCampaigns,
  useNegaritPorts,
  usePortTypes,
  useUpdatePort
} from "../api";
import { useMessagesStore } from "../store";

const typeMapping = {
  integer: "number",
  decimal: "number",
  float: "number",
  string: "text"
};

const portSchema = yup
  .object({
    name: yup.string().required("Name is required")
  })
  .required();

export function UpdatePortModal({ portData }) {
  const updatePortModalOpen = useMessagesStore(
    state => state.updatePortModalOpen
  );
  const closeUpdatePortModal = useMessagesStore(
    state => state.closeUpdatePortModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const portTypesQuery = usePortTypes();
  const portMutation = useUpdatePort();
  const [portTypes, setPortTypes] = useState([]);
  const [portTypeOptions, setPortTypeOptions] = useState([]);
  const [selectedPortType, setSelectedPortType] = useState();

  const [apiKey, setApiKey] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const { t } = useTranslation();

  const negaritPortQuery = useNegaritPorts({
    config: {
      enabled: !!debouncedValue || !!portData?.configurations?.api_key
    },
    API_KEY: debouncedValue || portData?.configurations?.api_key
  });

  const negaritCampaignQuery = useNegaritCampaigns({
    config: {
      enabled: !!debouncedValue || !!portData?.configurations?.api_key
    },
    API_KEY: debouncedValue || portData?.configurations?.api_key
  });

  useDebounce(
    () => {
      setDebouncedValue(apiKey);
    },
    2000,
    [apiKey]
  );

  useEffect(() => {
    const portTypeOptions = [];

    if (portTypesQuery.isSuccess) {
      setPortTypes(portTypesQuery.data.result);

      for (let portType of portTypesQuery.data.result) {
        portTypeOptions.push({
          optionLabel: portType.name,
          value: portType.id
        });
      }
      setPortTypeOptions(portTypeOptions);
    }
  }, [portTypesQuery.isSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(portSchema)
  });

  useEffect(() => {
    if (portData) {
      setApiKey(portData?.configurations?.api_key);
      setSelectedPortType(portData?.message_port_type);

      reset({
        name: portData?.name,
        api_key: portData?.configurations?.api_key,
        campaign_id: portData?.configurations?.campaign_id,
        message_port_id: portData?.configurations?.message_port_id
      });
    }
  }, [portData]);

  const onSubmit = data => {
    const updatedPortData = {
      ...data,
      message_port_type_id: selectedPortType?.id
    };
    portMutation.mutate({
      churchId: selectedChurch?.id,
      portId: portData?.id,
      portData: updatedPortData
    });
  };

  return (
    <Modal open={updatePortModalOpen} onClose={closeUpdatePortModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("message.updateMessagePort.UpdatePort")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Select
            label={t("message.createMessagePort.PortType")}
            isLabelBold={1}
            options={portTypeOptions}
            register={() => {}}
            value={selectedPortType?.id}
            onChange={e => {
              setSelectedPortType(
                portTypes.find(type => type.id === Number(e.target.value))
              );
              reset();
            }}
          />
          <TextField
            label={t("message.createMessagePort.Name")}
            name="name"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            register={register}
            isLabelBold={1}
            autoComplete="off"
          />
          {selectedPortType?.name === "Negarit" && (
            <>
              <TextField
                label={t("message.createMessagePort.ApiKey")}
                name="api_key"
                register={register}
                error={!!errors.api_key?.message}
                helperText={errors.api_key?.message}
                isLabelBold={1}
                autoComplete="off"
                value={apiKey}
                onChange={e => setApiKey(e.target.value)}
              />
              {negaritCampaignQuery.data && (
                <Select
                  isLabelBold={1}
                  label="Campaign Id"
                  name="campaign_id"
                  options={
                    negaritCampaignQuery.data?.campaigns?.map(campaign => ({
                      optionLabel: campaign?.name,
                      value: campaign?.id
                    })) || []
                  }
                  register={register}
                  error={!!errors.campaign_id?.message}
                  helperText={errors.campaign_id?.message}
                />
              )}
              {negaritPortQuery.data && (
                <Select
                  isLabelBold={1}
                  label="Message Port Id"
                  name="message_port_id"
                  options={
                    negaritPortQuery.data?.sms_ports?.map(sms_port => ({
                      optionLabel: sms_port?.name,
                      value: sms_port?.id
                    })) || []
                  }
                  register={register}
                  error={!!errors.message_port_id?.message}
                  helperText={errors.message_port_id?.message}
                />
              )}
            </>
          )}
          {selectedPortType &&
            selectedPortType.name !== "Negarit" &&
            Object.keys(selectedPortType?.configurations).map(configuration => (
              <TextField
                key={configuration}
                label={_.startCase(_.lowerCase(configuration))}
                name={configuration}
                register={register}
                isLabelBold={1}
                autoComplete="off"
                type={
                  typeMapping[selectedPortType.configurations[configuration]]
                }
              />
            ))}
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={closeUpdatePortModal}
              disabled={
                !negaritPortQuery.data ||
                !negaritCampaignQuery.data ||
                portMutation.isLoading
              }
            >
              {t("message.createMessagePort.Cancel")}
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={
                !negaritPortQuery.data ||
                !negaritCampaignQuery.data ||
                portMutation.isLoading
              }
            >
              {t("message.updateMessagePort.UpdatePort")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
