import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useFinanceSettingsStore = create(
  devtools(
    set => ({
      createChartOfAccountNameOpen: false,
      updateChartOfAccountNameOpen: false,
      openCreateChartOfAccountNameModal: () =>
        set({ createChartOfAccountNameOpen: true }),
      closeCreateChartOfAccountNameModal: () =>
        set({ createChartOfAccountNameOpen: false }),
      openUpdateChartOfAccountNameModal: () =>
        set({ updateChartOfAccountNameOpen: true }),
      closeUpdateChartOfAccountNameModal: () =>
        set({ updateChartOfAccountNameOpen: false }),
      createChartOfAccountOpen: false,
      updateChartOfAccountOpen: false,
      openCreateChartOfAccountModal: () =>
        set({ createChartOfAccountOpen: true }),
      closeCreateChartOfAccountModal: () =>
        set({ createChartOfAccountOpen: false }),
      openUpdateChartOfAccountModal: () =>
        set({ updateChartOfAccountOpen: true }),
      closeUpdateChartOfAccountModal: () =>
        set({ updateChartOfAccountOpen: false }),
      createApproverOpen: false,
      updateApproverOpen: false,
      openCreateApproverModal: () => set({ createApproverOpen: true }),
      closeCreateApproverModal: () => set({ createApproverOpen: false }),
      openUpdateApproverModal: () => set({ updateApproverOpen: true }),
      closeUpdateApproverModal: () => set({ updateApproverOpen: false }),
      createBudgetYearOpen: false,
      updateBudgetYearOpen: false,
      openCreateBudgetYearModal: () => set({ createBudgetYearOpen: true }),
      closeCreateBudgetYearModal: () => set({ createBudgetYearOpen: false }),
      openUpdateBudgetYearModal: () => set({ updateBudgetYearOpen: true }),
      closeUpdateBudgetYearModal: () => set({ updateBudgetYearOpen: false }),
      setBudgetOpen: false,
      openSetBudgetModal: () => set({ setBudgetOpen: true }),
      closeSetBudgetModal: () => set({ setBudgetOpen: false })
    }),
    {
      name: "finance-settings-store"
    }
  )
);
