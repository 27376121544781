import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import { Avatar, Chip, InputAdornment, Stack, Typography } from "@mui/material";
import { TextField } from "components/UI";

const Container = styled.div`
  width: 100%;
  padding: 51px 37px 51px 44px;
  background: white;
  grid-column: 2 / span 2;
  overflow: hidden;
`;

const Title = styled.h3``;

const Subtitle = styled.h5`
  color: #929292;
  font-weight: 300;
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 700px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding-bottom: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
    font-size: 14px;
  }

  & td {
    text-align: center;
    padding: 10px;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background: #f3f3f3;
    border-radius: 5px;
  }
`;

const messages = Array.from({ length: 8 }).fill({
  name: "Helen Tamru Maru",
  image: "https://source.unsplash.com/random",
  text: "Lorem ipsum dolor sit amet,",
  type: "Text Message",
  port: "SMS",
  status: "Sent",
  timestamp: "1/1/23, 9:00 PM"
});

export const DeletedMessagesPanel = () => {
  return (
    <Container>
      <Title>Deleted Messages</Title>
      <TextField
        neutral={1}
        placeholder="Search message"
        register={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
      <Subtitle>Recent Messages</Subtitle>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Conversations</th>
              <th>Type</th>
              <th>Port</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {messages.map(message => (
              <tr key={message}>
                <td>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar
                      alt={message.name}
                      src={message.image}
                      sx={{ width: 48, height: 48 }}
                    />
                    <Stack>
                      <Typography variant="subtitle2" textAlign="left">
                        {message.name}
                      </Typography>
                      <Typography variant="caption" textAlign="left" noWrap>
                        {message.text}
                      </Typography>
                    </Stack>
                  </Stack>
                </td>
                <td>
                  <Typography variant="caption">{message.type}</Typography>
                </td>
                <td>
                  <Typography variant="caption">{message.port}</Typography>
                </td>
                <td>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                  >
                    <Chip
                      label={
                        <Typography variant="caption">
                          {message.status}
                        </Typography>
                      }
                    />
                    <Typography variant="caption">
                      {message.timestamp}
                    </Typography>
                  </Stack>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
