import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useDeleteExpense, useExpenseStore } from "features/finances";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

export function UpdateExpenseModal({ data }) {
  const updateExpenseModalOpen = useExpenseStore(
    state => state.updateExpenseModalOpen
  );
  const closeUpdateExpenseModal = useExpenseStore(
    state => state.closeUpdateExpenseModal
  );
  const deleteExpenseMutation = useDeleteExpense();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  return (
    <BaseModal
      title="Record Expense"
      isOpen={updateExpenseModalOpen}
      onClose={closeUpdateExpenseModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            flavor="neutral"
            size="small"
            onClick={() => {
              deleteExpenseMutation.mutate({
                churchId: selectedChurch?.id,
                expenseId: data.id
              });
            }}
          >
            Delete
          </Button>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button flavor="white" size="small">
              Cancel
            </Button>
            <Button size="small">Save changes</Button>
          </Stack>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="fullName">Requisition ID</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Requested by</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Reason</Label>
        <Select id="fullName">
          <option>Tithe</option>
          <option>Gift</option>
          <option>Project Contribution</option>
          <option>Offering</option>
          <option>First Fruit</option>
          <option>Service Fee</option>
          <option>Sales</option>
          <option>Event Registration</option>
          <option>Other</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="fullName">Category</Label>
        <Select id="fullName">
          <option>Tithe</option>
          <option>Gift</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="fullName">Description</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Team</Label>
        <Select id="fullName">
          <option>Cash</option>
          <option>Cheque</option>
        </Select>
      </Field>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="fullName">Amount</Label>
          <Input id="fullName" />
        </Field>
        <Field>
          <Label htmlFor="fullName">Currency</Label>
          <Select id="fullName">
            <option>ETB</option>
          </Select>
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="fullName">Cheque Number</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Account</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Verified by</Label>
        <Input id="fullName" />
      </Field>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Label htmlFor="uploadPhoto">Attach request</Label>
        <Button id="uploadPhoto" flavor="outlined">
          Upload file
        </Button>
      </Stack>
      <Field>
        <Label htmlFor="fullName">Request date</Label>
        <Input id="fullName" type="date" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Expense date</Label>
        <Input id="fullName" type="date" />
      </Field>
    </BaseModal>
  );
}
