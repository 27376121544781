import styled from "@emotion/styled";
import { InputAdornment, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Funnel, GearSix, MagnifyingGlass } from "@phosphor-icons/react";
import { Button, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { useExpenseStore, useRequisitionsPaginated } from "features/finances";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

const PrimaryActions = styled.div`
  display: flex;
  gap: 8px;
`;

const SecondaryActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchIcon = styled(MagnifyingGlass)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const FilterIcon = styled(Funnel)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const RevenueSettingButton = styled.button`
  outline: none;
  border: none;
  background: none;
  color: #789;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-weight: 500;
`;

const Status = styled.div`
  border-radius: 2px;
  background: ${({ color }) => color};
  color: #ffffff;
  padding: 4px 8px;
  text-transform: capitalize;
  cursor: pointer;
`;

export const RequisitionTable = ({ onStatusAction }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const openCreateRequisitionModal = useExpenseStore(
    state => state.openCreateRequisitionModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const requisitionsQuery = useRequisitionsPaginated({
    churchId: selectedChurch?.id,
    page,
    pageSize
  });
  const openRequisitionFlowModal = useExpenseStore(
    state => state.openRequisitionFlowModal
  );

  const statusColors = {
    not_approved: "#8E8E8E",
    approved: "#50C40D",
    rejected: "#F4352F"
  };

  const statusAction = requisition => {
    if (requisition.requisition_status.toLowerCase() === "not_approved") {
      onStatusAction(requisition, "approver");
      openRequisitionFlowModal();
    }

    if (requisition.requisition_status.toLowerCase() === "approved") {
      onStatusAction(requisition, "accountant");
      openRequisitionFlowModal();
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Requisition ID",
      flex: 1
    },
    {
      field: "name",
      headerName: "Requested By",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            {row.user?.first_name} {row.user?.last_name}
          </>
        );
      }
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.team?.name}</>;
      }
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.expense_type?.name}</>;
      }
    },
    {
      field: "account",
      headerName: "Account",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.chart_of_account?.account_name?.name}</>;
      }
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{moment(row.created_at).format("DD-MM-YYYY")}</>;
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Status
            color={statusColors[row.requisition_status.toLowerCase()]}
            onClick={() => statusAction(row)}
          >
            {row.requisition_status}
          </Status>
        );
      }
    }
  ];

  return (
    <Container>
      <Stack alignItems="flex-start" spacing={4}>
        <Header>
          <Title>Requisition Table</Title>
          <PrimaryActions>
            <RevenueSettingButton>
              <GearSix size={16} />
              <span>Requisition Setting</span>
            </RevenueSettingButton>
            <Button onClick={openCreateRequisitionModal}>Request</Button>
          </PrimaryActions>
        </Header>
        <SecondaryActions>
          <TextField
            neutral={1}
            placeholder="Search requisition"
            register={() => {}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={16} />
                </InputAdornment>
              )
            }}
            autoComplete="off"
          />
          <FilterIcon size={16} />
        </SecondaryActions>
      </Stack>
      <DataGrid
        autoHeight
        rows={requisitionsQuery.data?.result?.data || []}
        rowCount={requisitionsQuery.data?.result?.total || 0}
        loading={requisitionsQuery.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
    </Container>
  );
};
