import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAssetsStore } from "../store";

export const AssetModalSteps = () => {
  const addAssetFormActiveStep = useAssetsStore(
    state => state.addAssetFormActiveStep
  );

  const { t } = useTranslation();

  const steps = [
    { name: t("Asset.AssetRegistration.Basic asset information"), step: 1 },
    {
      name: t("Asset.AssetRegistration.Acquisition, Location and Assignment"),
      step: 2
    },
    {
      name: t(
        "Asset.AssetRegistration.Status, Condition and Financial Information"
      ),
      step: 3
    },
    {
      name: t(
        "Asset.AssetRegistration.Maintenance, Warranty, Documentation and Compliance"
      ),
      step: 4
    }
  ];

  const currentStep = useMemo(
    () => steps.find(step => step.step === addAssetFormActiveStep),
    [addAssetFormActiveStep]
  );

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack
          sx={{
            width: "20px",
            height: "20px",
            bgcolor: "rgba(85, 95, 109, 1)",
            borderRadius: "50%"
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px"
            }}
          >
            {addAssetFormActiveStep}
          </Typography>
        </Stack>
        <Typography variant="subtitle2">{currentStep.name}</Typography>
      </Stack>
    </Stack>
  );
};
