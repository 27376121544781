import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const createRequisition = ({ churchId, requisitionData }) => {
  return axios.post(`/churches/${churchId}/requisitions`, requisitionData);
};

export const useCreateRequisition = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateRequisitionModal = useExpenseStore(
    state => state.closeCreateRequisitionModal
  );

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("requisitions");

      setNotification({
        type: "success",
        message: "Requisition created successfully!"
      });

      closeCreateRequisitionModal();
    },
    ...config,
    mutationFn: createRequisition
  });
};
