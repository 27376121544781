import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Logo from "assets/images/logo.svg";
import SidebarPhoto from "assets/images/Tablet.webp";
import { Header } from "components/UI";
import { useAuthUser } from "react-auth-kit";
import { Outlet } from "react-router-dom";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Sidebar = styled.div`
  flex-basis: 40%;
  display: none;
  background: linear-gradient(180deg, #82dbd8 1.49%, #3bacb6 45.31%),
    url(${SidebarPhoto});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block: 40px;
  padding-inline: 60px;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
  }`};
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    flex-basis: 50%;
  }`}
`;

const YotorLogo = styled.img`
  height: 100px;
  width: 100px;
`;

export function ChurchLayout({ hasBack = false }) {
  const auth = useAuthUser();

  return (
    <Container>
      <Sidebar>
        <YotorLogo src={Logo} alt="Yotor logo" />
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "48px",
            fontWeight: 700,
            lineHeight: "72px",
            textTransform: "uppercase"
          }}
          color="white"
        >
          Welcome,
        </Typography>
        <Typography variant="h6" color="white" sx={{ fontWeight: 300 }}>
          {auth().first_name} {auth().last_name}
        </Typography>
        <Typography variant="caption" color="white" mt={2}>
          Beta version
        </Typography>
      </Sidebar>
      <Content>
        <Header hasBack={hasBack} />
        <Outlet />
      </Content>
    </Container>
  );
}
