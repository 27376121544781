import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useEmployeesStore } from "../store";

export const createEmployee = ({ churchId, employeeData }) => {
  return axios.post(`/churches/${churchId}/employees`, employeeData);
};

export const useCreateEmployee = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeAddEmployeeModal =
    useEmployeesStore.getState().closeAddEmployeeModal;
  const setAddEmployeeFormActiveStep =
    useEmployeesStore.getState().setAddEmployeeFormActiveStep;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("employees");

      setNotification({
        type: "success",
        message: "Employee created successfully!"
      });

      closeAddEmployeeModal();
      setAddEmployeeFormActiveStep(1);
    },
    ...config,
    mutationFn: createEmployee
  });
};
