import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button, Radio } from "components/UI";
import { useChurchStore } from "features/churches";
import { useExpenseStore, usePaymentRequisition } from "features/finances";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

export function MakePaymentModal({ data }) {
  const makePaymentOpen = useExpenseStore(state => state.makePaymentOpen);
  const closeMakePaymentModal = useExpenseStore(
    state => state.closeMakePaymentModal
  );
  const paymentMutation = usePaymentRequisition();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  return (
    <BaseModal
      title="Make Payment"
      isOpen={makePaymentOpen}
      onClose={closeMakePaymentModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button flavor="white" size="small">
            Cancel
          </Button>
          <Button
            size="small"
            onClick={() => {
              paymentMutation.mutate({
                churchId: selectedChurch?.id,
                requisitionId: data?.id,
                requisitionData: {}
              });
            }}
          >
            Submit
          </Button>
        </Stack>
      }
      minHeight="200px"
    >
      <Radio
        label="Select payment method"
        name="paymentMethod"
        buttons={[
          {
            radioLabel: "Via cheque",
            value: "cheque",
            id: "cheque"
          }
        ]}
        row={false}
        register={() => {}}
        error=""
        helperText=""
      />
      <Field>
        <Label htmlFor="chequeNumber">Cheque Number</Label>
        <Input id="chequeNumber" />
      </Field>
    </BaseModal>
  );
}
