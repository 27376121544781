import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";

const variants = {
  primary: ({ theme }) => css`
    border: 2px solid ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.main};
    color: #ffffff;

    &:hover {
      background-color: ${theme.palette.primary.main};
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      background-color: ${theme.palette.primary.main};
      border-color: ${theme.palette.neutral.main};
      color: #ffffff;
      opacity: 0.5;
    }
  `,
  secondary: ({ theme }) => css`
    border: 2px solid ${theme.palette.secondary.main};
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.primary.main};

    &:hover {
      background-color: ${theme.palette.secondary.main};
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      background-color: ${theme.palette.neutral.main};
      border-color: ${theme.palette.neutral.main};
      color: #cccccc;
    }
  `,
  outlined: ({ theme }) => css`
    border: 2px solid ${theme.palette.primary.main};
    background-color: transparent;
    color: ${theme.palette.primary.main};

    &:hover {
      background-color: transparent;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      background-color: ${theme.palette.neutral.main};
      border-color: ${theme.palette.neutral.main};
      color: #cccccc;
    }
  `,
  neutral: ({ theme }) => css`
    border: 2px solid ${theme.palette.neutral.main};
    background-color: ${theme.palette.neutral.main};
    color: ${theme.palette.primary.main};

    &:hover {
      background-color: ${theme.palette.neutral.main};
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      background-color: ${theme.palette.neutral.main};
      border-color: ${theme.palette.neutral.main};
      color: #cccccc;
    }
  `,
  white: ({ theme }) => css`
    border: 2px solid #ffffff;
    background-color: #ffffff;
    color: ${theme.palette.primary.main};

    &:hover {
      background-color: #ffffff;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      background-color: ${theme.palette.neutral.main};
      border-color: ${theme.palette.neutral.main};
      color: #cccccc;
    }
  `,
  rounded: ({ theme }) => css`
    border: 2px solid #ffffff;
    background-color: #ffffff;
    color: ${theme.palette.primary.main};
    border-radius: 50px;

    &:hover {
      background-color: #ffffff;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      background-color: ${theme.palette.neutral.main};
      border-color: ${theme.palette.neutral.main};
      color: #cccccc;
    }
  `
};

const StyledButton = styled(MuiButton)`
  ${({ flavor }) => variants[flavor]};
`;

export const Button = ({ flavor = "primary", children, ...rest }) => {
  return (
    <StyledButton flavor={flavor} {...rest}>
      {children}
    </StyledButton>
  );
};
