import { Chat, Edit } from "@mui/icons-material";
import { Avatar, IconButton, Popover, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteModal } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeletePartner, usePartnersPaginated } from "../api";
import { useMembersStore } from "../stores";
import { extraPartnerColumns } from "../utils/partnerColumns";
import { PartnersTableDropdownMenu } from "./PartnersTableDropdownMenu";

export const PartnersTable = ({ isFilterMode = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const [partnerName, setPartnerName] = useState("");
  const open = Boolean(anchorEl);
  const setPartnerPreviewData = useMembersStore(
    state => state.setPartnerPreviewData
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const deleteMutation = useDeletePartner();
  const closePartnerPreview = useMembersStore(
    state => state.closePartnerPreview
  );
  const queries = useMembersStore(state => state.queries);
  const setQueries = useMembersStore(state => state.setQueries);
  const { t } = useTranslation();
  const partnerColumnsVisible = useMembersStore(
    state => state.partnerColumnsVisible
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const addPartner = useMessagesStore(state => state.addPartner);
  const removePartner = useMessagesStore(state => state.removePartner);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const navigate = useNavigate();

  const partnersPaginatedQuery = usePartnersPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize,
    queries: queries
  });

  useEffect(() => {
    return () => {
      closePartnerPreview();
    };
  }, []);

  const handleClick = (event, id, name) => {
    setAnchorEl(event.currentTarget);
    setPartnerId(id);
    setPartnerName(name);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const messagePartner = partner => {
    clearRecipients();
    removePartner(partner.id);
    addRecipient(
      `${partner.id}_${partner.first_name}_${partner.last_name}`,
      partner
    );
    addPartner(partner.id, { id: partner.id });
    navigate("/messages");
  };

  const columns = [
    {
      field: "name",
      headerName: t("members.membersTable.name"),
      minWidth: 120,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row.image_url}`}
              alt={_.capitalize(row.first_name)}
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>
              {row.first_name} {row.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "gender",
      headerName: t("members.membersTable.gender"),
      flex: 1,
      minWidth: 120
    },
    {
      field: "phone",
      headerName: t("members.membersTable.phone"),
      minWidth: 120,
      flex: 1
    },
    {
      field: "email",
      headerName: t("members.membersTable.email"),
      flex: 1,
      minWidth: 120
    },
    {
      field: "operation",
      headerName: t("members.membersTable.operation"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                messagePartner(row);
              }}
            >
              <Chat />
            </IconButton>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                handleClick(e, row.id, row.first_name);
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      },
      flex: 1,
      minWidth: 120
    },
    ...extraPartnerColumns
  ];

  const handleSortModelChange = async sortModel => {
    const field = sortModel[0]?.field;
    const sort = sortModel[0]?.sort;

    if (field || sort) {
      setQueries({
        ...queries,
        sort: {
          [field]: sort
        }
      });
    } else {
      setQueries({});
    }
  };

  return (
    <>
      <DeleteModal
        name={partnerName}
        onDelete={() => {
          deleteMutation.mutate({
            churchId: selectedChurch?.id,
            partnerId: partnerId
          });
        }}
      />
      <DataGrid
        autoHeight
        rows={partnersPaginatedQuery?.data?.result?.data || []}
        rowCount={partnersPaginatedQuery?.data?.result?.total || 0}
        loading={partnersPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection={isFilterMode}
        onRowClick={({ row }) => {
          setPartnerPreviewData(row);
        }}
        paginationMode="server"
        sortingMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
        columnVisibilityModel={partnerColumnsVisible}
      />
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <PartnersTableDropdownMenu />
      </Popover>
    </>
  );
};
