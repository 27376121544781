import { Box, Modal, Stack, Typography } from "@mui/material";
import { Button, Select, TextField } from "components/UI";
import { useTranslation } from "react-i18next";
import { useMembersStore } from "../stores";

export function TerminateMemberModal() {
  const { t } = useTranslation();
  const terminateMemberModalOpen = useMembersStore(
    state => state.terminateMemberModalOpen
  );
  const closeTerminateMemberModal = useMembersStore(
    state => state.closeTerminateMemberModal
  );

  return (
    <Modal open={terminateMemberModalOpen} onClose={closeTerminateMemberModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          Termination
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={3}
          component="form"
        >
          <Select
            label="Reason for termination"
            name="parent_team_id"
            options={[]}
            register={() => {}}
            isLabelBold={1}
          />
          <TextField
            label="Applied Date"
            name="name"
            register={() => {}}
            isLabelBold={1}
            type="date"
          />
          <Stack alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              Letter of termination
            </Typography>
            <Button flavor="outlined">Upload doc</Button>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              flavor="secondary"
              onClick={closeTerminateMemberModal}
            >
              {t("form.common.cancel")}
            </Button>
            <Button type="submit" size="large" variant="contained">
              Terminate
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
