import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPartnerGrowth = ({ churchId, fromDate, toDate, period }) => {
  return axios.get(
    `/churches/${churchId}/dashboard/partner-growth-data?from_date=${fromDate}&to_date=${toDate}&period=${period}`
  );
};

export const usePartnerGrowth = ({
  churchId,
  fromDate,
  toDate,
  period,
  config
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["partner-growth", churchId, fromDate, toDate, period],
    queryFn: () => getPartnerGrowth({ churchId, fromDate, toDate, period }),
    select: response => response.result,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0
  });
};
