import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { useMembersStore } from "../stores";
import { queryClient } from "lib/react-query";

export const createPublicMember = ({ formId, memberData }) => {
  return axios.post(`/church/${formId}/member/register`, memberData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const useCreatePublicMember = ({ config } = {}) => {
  const setProfileImage = useMembersStore.getState().setProfileImage;
  const setIdImage = useMembersStore.getState().setIdImage;

  return useMutation({
    onSuccess: () => {
      setProfileImage(null);
      setIdImage(null);
      queryClient.invalidateQueries("members");
    },
    ...config,
    mutationFn: createPublicMember
  });
};
