import styled from "@emotion/styled";
import { DeleteOutline } from "@mui/icons-material";
import { Modal, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCentralStore } from "stores";

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  max-width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 12px;
  background-color: #eeeeee;
  color: #f44949;
  font-size: 12px;
`;

const Content = styled(Stack)`
  padding-block: 30px;
  text-align: center;
`;

const DeleteButton = styled.button`
  background-color: #f44949;
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #000000;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
`;

export function DeleteModal({ title = "Delete", name, onDelete }) {
  const deleteModalOpen = useCentralStore(state => state.deleteModalOpen);
  const closeDeleteModal = useCentralStore(state => state.closeDeleteModal);
  const { t } = useTranslation();

  return (
    <Modal open={deleteModalOpen} onClose={closeDeleteModal}>
      <Container>
        <Header>
          <DeleteOutline />
          <span>{title}</span>
        </Header>
        <Content spacing={2} alignItems="center">
          <Typography variant="caption" sx={{ color: "#4D4D4D" }}>
            {t("ui.deleteModal.intro")}{" "}
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              {name}
            </Typography>{" "}
            {t("ui.deleteModal.outro")}
            <br /> {t("ui.deleteModal.confirmation")}
          </Typography>
          <Stack direction="row" spacing={2}>
            <CancelButton onClick={closeDeleteModal}>
              {t("ui.deleteModal.no")}
            </CancelButton>
            <DeleteButton onClick={onDelete}>
              {t("ui.deleteModal.yes")}
            </DeleteButton>
          </Stack>
        </Content>
      </Container>
    </Modal>
  );
}
