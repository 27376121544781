import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useChartOfAccounts,
  useCreateExpense,
  useExpenseStore,
  useExpenseTypes
} from "features/finances";
import { useTeams } from "features/teams";
import { useFileUpload } from "hooks";
import { useForm } from "react-hook-form";
import { convertObjectToFormData } from "utils/convertObjectToFormData";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const Filename = styled.span`
  font-size: 10px;
`;

const expenseSchema = yup.object({
  requisitionId: yup.string(),
  requestedBy: yup.string(),
  reason: yup.string(),
  category: yup.string(),
  description: yup.string(),
  team: yup.string(),
  amount: yup.number(),
  currency: yup.string(),
  chequeNumber: yup.string(),
  account: yup.string(),
  verifiedBy: yup.string(),
  requestDate: yup.string(),
  expenseDate: yup.string()
});

export function RecordExpenseModal() {
  const recordExpenseModalOpen = useExpenseStore(
    state => state.recordExpenseModalOpen
  );
  const closeRecordExpenseModal = useExpenseStore(
    state => state.closeRecordExpenseModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const expenseTypesQuery = useExpenseTypes({
    churchId: selectedChurch?.id
  });
  const chartOfAccountsQuery = useChartOfAccounts({
    churchId: selectedChurch?.id
  });
  const teamsQuery = useTeams({
    churchId: selectedChurch?.id
  });
  const createExpenseMutation = useCreateExpense();
  const [selectedFile, openFileDialog] = useFileUpload();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      requisitionId: "",
      requestedBy: "",
      reason: "",
      category: "",
      description: "",
      team: "",
      amount: 0,
      currency: "",
      chequeNumber: "",
      account: "",
      verifiedBy: "",
      requestDate: "",
      expenseDate: ""
    },
    resolver: yupResolver(expenseSchema)
  });

  const onSubmitAndNew = values => {
    const expenseData = {
      requisition_number: values.requisitionId,
      requested_by: values.requestedBy,
      reason: values.reason,
      expense_type_id: values.category,
      team_id: values.team,
      description: values.description,
      amount: values.amount,
      check_number: values.chequeNumber,
      chart_of_account_id: values.account
    };

    if (selectedFile) {
      expenseData["attachment_request"] = selectedFile;
    }

    const expenseFormData = convertObjectToFormData(expenseData);

    createExpenseMutation.mutate(
      {
        churchId: selectedChurch?.id,
        expenseData: expenseFormData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  const onSubmitAndExit = values => {
    const expenseData = {
      requisition_number: values.requisitionId,
      requested_by: values.requestedBy,
      reason: values.reason,
      expense_type_id: values.category,
      team_id: values.team,
      description: values.description,
      amount: values.amount,
      check_number: values.chequeNumber,
      chart_of_account_id: values.account
    };

    if (selectedFile) {
      expenseData["attachment_request"] = selectedFile;
    }

    const expenseFormData = convertObjectToFormData(expenseData);

    createExpenseMutation.mutate(
      {
        churchId: selectedChurch?.id,
        expenseData: expenseFormData
      },
      {
        onSuccess: () => {
          closeRecordExpenseModal();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Record Expense"
      isOpen={recordExpenseModalOpen}
      onClose={closeRecordExpenseModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button size="small" onClick={handleSubmit(onSubmitAndNew)}>
            Save and New
          </Button>
          <Button size="small">Save and Print</Button>
          <Button size="small" onClick={handleSubmit(onSubmitAndExit)}>
            Save and Exit
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="requisitionId">Requisition ID</Label>
        <Input id="requisitionId" {...register("requisitionId")} />
      </Field>
      <Field>
        <Label htmlFor="requestedBy">Requested by</Label>
        <Input id="requestedBy" {...register("requestedBy")} />
      </Field>
      <Field>
        <Label htmlFor="reason">Reason</Label>
        <Input id="reason" {...register("reason")} />
      </Field>
      <Field>
        <Label htmlFor="category">Category</Label>
        <Select id="category" {...register("category")}>
          {expenseTypesQuery.data?.result?.map(expenseType => (
            <option key={expenseType.id} value={expenseType.id}>
              {expenseType.name}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="description">Description</Label>
        <Input id="description" {...register("description")} />
      </Field>
      <Field>
        <Label htmlFor="team">Team</Label>
        <Select id="team" {...register("team")}>
          {teamsQuery.data?.result?.map(team => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </Select>
      </Field>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="amount">Amount</Label>
          <Input id="amount" {...register("amount")} />
        </Field>
        <Field>
          <Label htmlFor="currency">Currency</Label>
          <Select id="currency" {...register("currency")}>
            <option>ETB</option>
          </Select>
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="cheque">Cheque Number</Label>
        <Input id="cheque" {...register("chequeNumber")} />
      </Field>
      <Field>
        <Label htmlFor="account">Account</Label>
        <Select id="account" {...register("account")}>
          {chartOfAccountsQuery.data?.result?.map(chartOfAccount => (
            <option key={chartOfAccount.id} value={chartOfAccount.id}>
              {chartOfAccount.account_name}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="verifiedBy">Verified by</Label>
        <Input id="verifiedBy" {...register("verifiedBy")} />
      </Field>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Label htmlFor="uploadPhoto">Attach request</Label>
        <Button id="uploadPhoto" flavor="outlined" onClick={openFileDialog}>
          Upload file
        </Button>
      </Stack>
      {selectedFile && <Filename>{selectedFile.name}</Filename>}
      <Field>
        <Label htmlFor="requestDate">Request date</Label>
        <Input id="requestDate" type="date" {...register("requestDate")} />
      </Field>
      <Field>
        <Label htmlFor="expenseDate">Expense date</Label>
        <Input id="expenseDate" type="date" {...register("expenseDate")} />
      </Field>
    </BaseModal>
  );
}
