import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import queryString from "query-string";
import { useCentralStore } from "stores";

export const deleteTemplates = ({ churchId, templateIds }) => {
  const templateIdQueries = queryString.stringify(
    { id: templateIds },
    { arrayFormat: "index" }
  );

  return axios.delete(
    `/churches/${churchId}/message-templates?${templateIdQueries}`
  );
};

export const useDeleteTemplates = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["templates"]);
      setNotification({
        type: "success",
        message: "Templates deleted successfully!"
      });
      closeDeleteModal();
    },
    ...config,
    mutationFn: deleteTemplates
  });
};
