import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useDenominationStore } from "../store";

export const createDenomination = ({ denominationData }) => {
  return axios.post(`/denominations`, denominationData);
};

export const useCreateDenomination = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const selectDenomination = useDenominationStore.getState().selectDenomination;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries("denominations");
      setNotification({
        type: "success",
        message: "Denomination created successfully!"
      });
      selectDenomination(data.result);
      navigate("/churches/create");
    },
    ...config,
    mutationFn: createDenomination
  });
};
