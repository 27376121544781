import styled from "@emotion/styled";
import { Box, Modal, Stack } from "@mui/material";
import { Button } from "components/UI";
import { TABLES, TAB_TO_TABLE_NAMES } from "../constants";
import { useMembersStore } from "../stores";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  display: grid;
  place-items: center;
  cursor: pointer;

  &:checked {
    background-color: #3dadb7;
    border-color: #3dadb7;

    &::before {
      content: "✔";
      display: inline-block;
      text-align: center;
      font-size: 10px;
      color: white;
    }
  }
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
`;

const getTableColumns = (tab, memberColumns, kidColumns, partnerColumns) => {
  if (TAB_TO_TABLE_NAMES[tab] === TABLES.MEMBERS) {
    return memberColumns;
  }

  if (TAB_TO_TABLE_NAMES[tab] === TABLES.KIDS) {
    return kidColumns;
  }

  if (TAB_TO_TABLE_NAMES[tab] === TABLES.PARTNERS) {
    return partnerColumns;
  }
};

export const ColumnsVisibilityModal = () => {
  const columnsVisibilityModalOpen = useMembersStore(
    state => state.columnsVisibilityModalOpen
  );
  const closeColumnsVisibilityModal = useMembersStore(
    state => state.closeColumnsVisibilityModal
  );
  const columnsVisible = useMembersStore(state => state.columnsVisible);
  const kidColumnsVisible = useMembersStore(state => state.kidColumnsVisible);
  const partnerColumnsVisible = useMembersStore(
    state => state.partnerColumnsVisible
  );
  const setColumnVisibility = useMembersStore(
    state => state.setColumnVisibility
  );
  const setAllColumnsVisible = useMembersStore(
    state => state.setAllColumnsVisible
  );
  const resetColumnsVisibility = useMembersStore(
    state => state.resetColumnsVisibility
  );
  const currentTab = useMembersStore(state => state.currentTab);

  return (
    <Modal
      open={columnsVisibilityModalOpen}
      onClose={closeColumnsVisibilityModal}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 1,
          width: "400px"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            textTransform: "capitalize"
          }}
        >
          {TAB_TO_TABLE_NAMES[currentTab]} table columns
        </Box>
        <Stack sx={{ backgroundColor: "white" }}>
          <Stack
            sx={{
              px: 2,
              py: 3,
              maxHeight: "400px",
              overflowY: "auto"
            }}
            spacing={2}
          >
            <CheckboxContainer>
              <Checkbox
                type="checkbox"
                id="all"
                name="all"
                checked={Object.values(
                  getTableColumns(
                    currentTab,
                    columnsVisible,
                    kidColumnsVisible,
                    partnerColumnsVisible
                  )
                ).every(column => column === true)}
                onChange={() => setAllColumnsVisible(currentTab)}
              />
              <CheckboxLabel htmlFor="all">All</CheckboxLabel>
            </CheckboxContainer>
            {Object.keys(
              getTableColumns(
                currentTab,
                columnsVisible,
                kidColumnsVisible,
                partnerColumnsVisible
              )
            ).map(column => (
              <CheckboxContainer key={column}>
                <Checkbox
                  type="checkbox"
                  id={column}
                  name={column}
                  checked={
                    getTableColumns(
                      currentTab,
                      columnsVisible,
                      kidColumnsVisible,
                      partnerColumnsVisible
                    )[column]
                  }
                  onChange={e => {
                    const checked = e.target.checked;
                    setColumnVisibility(currentTab, column, checked);
                  }}
                />
                <CheckboxLabel htmlFor={column}>
                  {column
                    .replaceAll("_", " ")
                    .toLowerCase()
                    .split(" ")
                    .map((word, index) => {
                      if (index !== 0 && word === "id") {
                        return word.replace("id", "");
                      }

                      return word.charAt(0).toUpperCase() + word.slice(1);
                    })
                    .join(" ")
                    .trim()}
                </CheckboxLabel>
              </CheckboxContainer>
            ))}
          </Stack>
          <Stack alignItems="center">
            <Button
              flavor="white"
              onClick={() => resetColumnsVisibility(currentTab)}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
