import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const deactivateApprover = ({ churchId, approverData, approverId }) => {
  return axios.patch(
    `/churches/${churchId}/approvers/${approverId}/deactivate`,
    approverData
  );
};

export const useDeactivateApprover = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateApproverModal =
    useFinanceSettingsStore.getState().closeUpdateApproverModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["approvers"]);
      setNotification({
        type: "success",
        message: "Approver deactivated successfully!"
      });
      closeUpdateApproverModal();
    },
    ...config,
    mutationFn: deactivateApprover
  });
};
