import styled from "@emotion/styled";
import { Modal, Stack } from "@mui/material";
import { XCircle } from "@phosphor-icons/react";
import { Button } from "components/UI";
import { useRevenueStore } from "features/finances";

const Container = styled.div`
  border-radius: 8px;
  background: #fff;
  width: 400px;
  height: 387px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Title = styled.span`
  color: #fc5758;
  font-size: 16px;
  font-weight: 700;
`;

const Message = styled.span`
  text-align: center;
  max-width: 200px;
`;

export const ErrorModal = () => {
  const errorModalOpen = useRevenueStore(state => state.errorModalOpen);
  const closeErrorModal = useRevenueStore(state => state.closeErrorModal);

  return (
    <Modal open={errorModalOpen} onClose={closeErrorModal}>
      <Container>
        <Wrapper>
          <Stack alignItems="center" spacing={1}>
            <XCircle size={55} color="#FC5758" weight="fill" />
            <Title>Failed!</Title>
          </Stack>
          <Message>Something went wrong when approving a cheque</Message>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button flavor="white">Cancel</Button>
            <Button>Try again</Button>
          </Stack>
        </Wrapper>
      </Container>
    </Modal>
  );
};
