import moment from "moment";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useMessagesStore = create(
  devtools(set => ({
    portId: "",
    recipients: new Map(),
    members: new Map(),
    partners: new Map(),
    teams: new Map(),
    message: "",
    isScheduled: 0,
    isDraft: 0,
    dateTime: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
    messages: [],
    templates: [],
    deleteMessageModalOpen: false,
    createTemplateModalOpen: false,
    updateTemplateModalOpen: false,
    createPortModalOpen: false,
    updatePortModalOpen: false,
    editMessageModalOpen: false,
    groupMessageStatusModalOpen: false,
    templateMessage: "",
    selectTemplateModalOpen: false,
    serverErrors: null,
    setServerErrors: errors => set({ serverErrors: errors }),
    setTemplateMessage: messageContent =>
      set({ templateMessage: messageContent }),
    openSelectTemplateModal: () => set({ selectTemplateModalOpen: true }),
    closeSelectTemplateModal: () => set({ selectTemplateModalOpen: false }),
    addRecipient: (recipientId, recipientData) =>
      set(state => ({
        recipients: new Map(state.recipients).set(recipientId, recipientData)
      })),
    removeRecipient: recipientId =>
      set(state => {
        const removedRecipients = new Map(state.recipients);
        removedRecipients.delete(recipientId);
        return { recipients: removedRecipients };
      }),
    clearRecipients: () =>
      set(state => {
        const clearedRecipients = new Map(state.recipients);
        clearedRecipients.clear();
        return { recipients: clearedRecipients };
      }),
    addMember: (memberId, member) =>
      set(state => ({
        members: new Map(state.members).set(memberId, member)
      })),
    removeMember: memberId =>
      set(state => {
        const removedMembers = new Map(state.members);
        removedMembers.delete(memberId);
        return { members: removedMembers };
      }),
    clearMembers: () =>
      set(state => {
        const clearedMembers = new Map(state.members);
        clearedMembers.clear();
        return { members: clearedMembers };
      }),
    addTeam: (teamId, team) =>
      set(state => ({
        teams: new Map(state.teams).set(teamId, team)
      })),
    removeTeam: teamId =>
      set(state => {
        const removedTeams = new Map(state.teams);
        removedTeams.delete(teamId);
        return { teams: removedTeams };
      }),
    clearTeams: () =>
      set(state => {
        const clearedTeams = new Map(state.teams);
        clearedTeams.clear();
        return { teams: clearedTeams };
      }),
    addPartner: (partnersId, partners) =>
      set(state => ({
        partners: new Map(state.partners).set(partnersId, partners)
      })),
    removePartner: partnersId =>
      set(state => {
        const removedPartners = new Map(state.partners);
        removedPartners.delete(partnersId);
        return { partners: removedPartners };
      }),
    clearPartners: () =>
      set(state => {
        const clearedPartners = new Map(state.partners);
        clearedPartners.clear();
        return { partners: clearedPartners };
      }),
    setPortId: portId => set({ portId }),
    setIsScheduled: isScheduled => set({ isScheduled }),
    setIsDraft: isDraft => set({ isDraft }),
    setDateTime: dateTime => set({ dateTime }),
    setRecipients: recipients => set({ recipients }),
    setMessage: message => set({ message }),
    setMessages: messages => set({ messages }),
    openDeleteMessageModal: () => set({ deleteMessageModalOpen: true }),
    closeDeleteMessageModal: () => set({ deleteMessageModalOpen: false }),
    openCreateTemplateModal: () => set({ createTemplateModalOpen: true }),
    closeCreateTemplateModal: () => set({ createTemplateModalOpen: false }),
    openUpdateTemplateModal: () => set({ updateTemplateModalOpen: true }),
    closeUpdateTemplateModal: () => set({ updateTemplateModalOpen: false }),
    openCreatePortModal: () => set({ createPortModalOpen: true }),
    closeCreatePortModal: () => set({ createPortModalOpen: false }),
    openUpdatePortModal: () => set({ updatePortModalOpen: true }),
    closeUpdatePortModal: () => set({ updatePortModalOpen: false }),
    openEditMessageModal: () => set({ editMessageModalOpen: true }),
    closeEditMessageModal: () => set({ editMessageModalOpen: false }),
    openGroupMessageStatusModal: () =>
      set({ groupMessageStatusModalOpen: true }),
    closeGroupMessageStatusModal: () =>
      set({ groupMessageStatusModalOpen: false })
  })),
  {
    name: "messages-store"
  }
);
