import { Grid, Typography } from "@mui/material";
import { Radio } from "components/UI";
import { useTranslation } from "react-i18next";

export const ChildAndDependencySection = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <Grid
      direction={{
        xs: "column",
        md: "row"
      }}
      container
      columnSpacing={12}
    >
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step3.ChildInfo")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={12}>
          <Radio
            label={t("addMembers.step3.HaveChildrenOrDependencies")}
            name="child_and_dependencies"
            buttons={[
              { radioLabel: t("form.common.yes"), value: "1", id: "child_yes" },
              { radioLabel: t("form.common.no"), value: "0", id: "child_no" }
            ]}
            row={true}
            register={register}
            error={!!errors.child_and_dependencies?.message}
            helperText={errors.child_and_dependencies?.message}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
