import { Grid, Typography } from "@mui/material";
import { Radio, Select, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { RELIGIONS } from "features/members";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const SalvationProfileSection = ({
  register,
  errors,
  watch,
  control
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Grid
      direction={{
        xs: "column",
        md: "row"
      }}
      container
      columnSpacing={12}
    >
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step5.SpiritualLifeInformation")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2} columns={{ xs: 2, md: 12 }}>
        <Grid item xs={2} md={6}>
          <Select
            label={t("addMembers.step5.FormerBelief")}
            name="former_belief"
            options={RELIGIONS[i18n.language].map(religion => ({
              optionLabel: religion,
              value: religion
            }))}
            error={!!errors.former_belief?.message}
            helperText={errors.former_belief?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={2} md={6}>
          <TextField
            label={t("addMembers.step5.HowDidYouAcceptJesusChrist")}
            name="how_you_accept_christ"
            error={!!errors.how_you_accept_christ?.message}
            helperText={errors.how_you_accept_christ?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={2} md={6}>
          <Controller
            name="salvation_date"
            control={control}
            render={({ field }) => (
              <DateField
                label={t("addMembers.step5.SalvationDate")}
                name="salvation_date"
                error={!!errors.salvation_date?.message}
                helperText={errors.salvation_date?.message}
                {...field}
                disableFutureDates
              />
            )}
          />
        </Grid>
        <Grid item xs={2} md={6}>
          <Radio
            label={t("addMembers.step5.AreYouBaptized")}
            name="is_baptized"
            buttons={[
              {
                radioLabel: t("form.common.yes"),
                value: "1",
                id: "baptized_yes"
              },
              { radioLabel: t("form.common.no"), value: "0", id: "baptized_no" }
            ]}
            row={true}
            register={register}
            error={!!errors.is_baptized?.message}
            helperText={errors.is_baptized?.message}
            required
          />
        </Grid>
        {watch("is_baptized") === "1" && (
          <>
            <Grid item xs={2} md={6}>
              <TextField
                label={t("addMembers.step5.BaptizedChurch")}
                name="baptism_church"
                error={!!errors.baptism_church?.message}
                helperText={errors.baptism_church?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={6}>
              <Controller
                name="baptism_date"
                control={control}
                render={({ field }) => (
                  <DateField
                    label={t("addMembers.step5.DateOfBaptism")}
                    name="baptism_date"
                    error={!!errors.baptism_date?.message}
                    helperText={errors.baptism_date?.message}
                    {...field}
                    disableFutureDates
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={2} md={6}>
          <Controller
            name="membership_date"
            control={control}
            render={({ field }) => (
              <DateField
                label={t("addMembers.step5.MembershipDate")}
                name="membership_date"
                error={!!errors.membership_date?.message}
                helperText={errors.membership_date?.message}
                {...field}
                required
                disableFutureDates
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
