import styled from "@emotion/styled";
import { Error } from "@mui/icons-material";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { Button } from "components/UI";

const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error.main};
  padding: 8px 57px;

  :hover {
    background-color: ${({ theme }) => theme.palette.error.main};
  }
`;

export function ErrorModal({ isOpen, onClose }) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "800px",
          height: "535px",
          overflow: "hidden"
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100%",
            backgroundColor: "white"
          }}
          spacing="35px"
        >
          <Error color="error" sx={{ height: "100px", width: "100px" }} />
          <Stack alignItems="center" spacing={3} px={8}>
            <Typography variant="h4" color="error" fontWeight={700}>
              Something went wrong
            </Typography>
            <Typography>
              There seems to be an issue processing your data. If you believe
              this to be an error, kindly contact your church for further
              assistance in resolving the issue. Thank you for your
              understanding.
            </Typography>
          </Stack>
          <CloseButton onClick={onClose}>Close</CloseButton>
        </Stack>
      </Box>
    </Modal>
  );
}
