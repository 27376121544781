import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const createChartOfAccountNames = ({ churchId, chartOfAccountData }) => {
  return axios.post(
    `/churches/${churchId}/chart-of-accounts-names`,
    chartOfAccountData
  );
};

export const useCreateChartOfAccountNames = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateChartOfAccountNameModal =
    useFinanceSettingsStore.getState().closeCreateChartOfAccountNameModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("chart-account-names");
      queryClient.invalidateQueries("chart-account-names-paginated");
      setNotification({
        type: "success",
        message: "Chart of account name created successfully!"
      });
      closeCreateChartOfAccountNameModal();
    },
    ...config,
    mutationFn: createChartOfAccountNames
  });
};
