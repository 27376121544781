import { formatISO } from "date-fns";
import { has } from "lodash";

export class MemberDataManipulator {
  convertBoolToString(bool) {
    return Boolean(Number(bool)) === true ? "Yes" : "No";
  }

  convertNumberStringToNumber(numberString) {
    return Number(numberString);
  }

  convertObjectToFormData(object) {
    const formData = new FormData();

    for (const key in object) {
      if (has(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  }

  convertValuesToNumbers(object, keys) {
    for (let key of keys) {
      if (has(object, key)) {
        object[key] = Number(object[key]);
      }
    }

    return object;
  }

  convertValuesToDates(object, keys) {
    for (let key of keys) {
      if (has(object, key)) {
        object[key] = object[key] ? formatISO(new Date(object[key])) : null;
      }
    }

    return object;
  }

  convertValuesToShortDates(object, keys) {
    for (let key of keys) {
      if (has(object, key)) {
        object[key] = object[key]
          ? formatISO(new Date(object[key]), { representation: "date" })
          : null;
      }
    }

    return object;
  }

  formatArrayStructure(object, keys) {
    for (const key of keys) {
      if (has(object, key)) {
        object[key].forEach((child, index) => {
          Object.entries(child).forEach(([key, value]) => {
            const newKey = `${keys}[${index}][${key}]`;
            object[newKey] = value;
          });
        });

        delete object[key];
      }
    }

    return object;
  }

  formatStringArray(object, keys) {
    for (const key of keys) {
      if (has(object, key)) {
        object[key].forEach((child, index) => {
          Object.values(child).forEach(value => {
            if (value) {
              const newKey = `${keys}[${index}]`;
              object[newKey] = value;
            }
          });
        });

        delete object[key];
      }
    }

    return object;
  }

  removeEmptyValues(object) {
    for (const key in object) {
      const value = object[key];
      const isEmptyString = value === "";
      const isNull = value === null || value === "null";
      const isUndefined = value === undefined || value === "undefined";
      const isEmptyArray = Array.isArray(value) && value.length === 0;

      if (isEmptyString || isNull || isUndefined || isEmptyArray) {
        delete object[key];
      }
    }

    return object;
  }

  removeFromObject(object, keys) {
    for (const key of keys) {
      if (has(object, key)) {
        delete object[key];
      }
    }

    return object;
  }
}
