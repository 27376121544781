import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { Select, TextField } from "components/UI";
import { Fragment } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const AcademicStatusSection = ({ register, errors, control }) => {
  const { t } = useTranslation();

  const newAcademicField = {
    academic_status: "",
    professional_skill: ""
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "education_and_skills"
  });

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step2.AcademicStatus")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <Grid item xs={4}>
              <Select
                label={t("addMembers.step2.SelectAcademicStatus")}
                name={`education_and_skills.${index}.academic_status`}
                options={[
                  {
                    optionLabel: t("addMembers.step2.Illiterate"),
                    value: "Unable to read or write"
                  },
                  {
                    optionLabel: t("addMembers.step2.Literate"),
                    value: "Literate"
                  },
                  { optionLabel: "1-6", value: "1-6" },
                  { optionLabel: "7-10", value: "7-10" },
                  { optionLabel: "11-12", value: "11-12" },
                  {
                    optionLabel: t("addMembers.step2.TVETCertificate"),
                    value: "TVET Certificate"
                  },
                  {
                    optionLabel: t("addMembers.step2.CollegeDiploma"),
                    value: "College Diploma"
                  },
                  {
                    optionLabel: t("addMembers.step2.UndergraduateStudent"),
                    value: "Undergraduate Student"
                  },
                  {
                    optionLabel: t("addMembers.step2.Bachelor"),
                    value: "Bachelors"
                  },
                  {
                    optionLabel: t("addMembers.step2.PostgraduateStudent"),
                    value: "Postgraduate Student"
                  },
                  {
                    optionLabel: t("addMembers.step2.Masters"),
                    value: "Masters"
                  },
                  { optionLabel: t("addMembers.step2.Phd"), value: "PhD" }
                ]}
                register={register}
                error={
                  !!errors.education_and_skills?.[index]?.academic_status
                    ?.message
                }
                helperText={
                  errors.education_and_skills?.[index]?.academic_status?.message
                }
                required
              />
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" alignItems="center">
                <TextField
                  label={t("addMembers.step2.ProfessionalSkills")}
                  name={`education_and_skills.${index}.professional_skill`}
                  error={
                    !!errors.education_and_skills?.[index]?.professional_skill
                      ?.message
                  }
                  helperText={
                    errors.education_and_skills?.[index]?.professional_skill
                      ?.message
                  }
                  register={register}
                />
                {index !== 0 && (
                  <IconButton
                    sx={{
                      alignSelf: "flex-end"
                    }}
                    onClick={() => remove(index)}
                  >
                    <RemoveCircleOutline color="primary" />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Fragment>
        ))}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <IconButton onClick={() => append(newAcademicField)}>
            <AddCircleOutline color="white" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
