import axios from "axios";
import { API_URL } from "config";
import _ from "lodash";
import moment from "moment";
import qs from "query-string";
import { useCentralStore } from "stores";
import { getToken } from "utils/getToken";
import { QueryBuilder } from "../utils/queryBuilder";

export const viewExportService = async ({
  type = "members",
  churchId,
  format,
  orientation,
  columns,
  queries
}) => {
  const queryBuilder = new QueryBuilder();

  if (queries.filter) {
    queryBuilder.filter(queries.filter);
  }

  if (queries.search) {
    queryBuilder.search(queries.search);
  }

  if (queries.sort) {
    queryBuilder.sort(queries.sort);
  }

  if (!_.isEmpty(columns)) {
    queryBuilder.append(
      "columns",
      qs
        .stringify({ columns: columns }, { arrayFormat: "comma" })
        .replace("columns=", "")
    );
  }

  queryBuilder.append("format", format);
  queryBuilder.append("orientation", orientation);

  const queryString = queryBuilder.build();

  let url = "";
  if (type === "members") {
    url = `${API_URL}/churches/${churchId}/members-export${queryString}`;
  } else if (type === "inactive-members") {
    url = `${API_URL}/churches/${churchId}/members-inactive-export${queryString}`;
  } else if (type === "pending-members") {
    url = `${API_URL}/churches/${churchId}/members-pending-export${queryString}`;
  } else if (type === "kids") {
    url = `${API_URL}/churches/${churchId}/children-export${queryString}`;
  } else if (type === "partners") {
    url = `${API_URL}/churches/${churchId}/partners-export${queryString}`;
  }

  let data;
  let dataFilename;

  try {
    const response = await axios({
      method: "get",
      url: url,
      responseType: "blob",
      headers: {
        Authorization: getToken()
      }
    });

    if (format === "pdf") {
      const filename = `${type}_${moment().format("DD-MM-YYYY-hh-mm-A")}.${
        format === "excel" ? "xlsx" : format
      }`;

      data = new Blob([response.data]);
      dataFilename = filename;
    } else {
      const filename = `${type}_${moment().format("DD-MM-YYYY-hh-mm-A")}.${
        format === "excel" ? "xlsx" : format
      }`;

      data = response.data;
      dataFilename = filename;
    }

    return { data, filename: dataFilename };
  } catch (error) {
    const setNotification = useCentralStore.getState().setNotification;

    if (
      error?.response?.data?.errors?.includes("unauthorized") ||
      error?.response?.status === 403
    ) {
      setNotification({
        type: "error",
        title: "Unauthorized",
        message: "You are unauthorized from performing this action."
      });
    } else {
      setNotification({ type: "error", message: "Unable to export data" });
    }

    return { data, filename: dataFilename }; // Return empty values in case of an error
  }
};
