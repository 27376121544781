import * as yup from "yup";

export const teamTypeSchema = yup.object({
  name: yup.string().required("Team type name is required"),
  description: yup.string().required("Team type description is required")
});

export const teamSchema = yup.object({
  name: yup.string().required("Team name is required"),
  parent_team_id: yup.string().nullable(true),
  team_type_id: yup.string().required("Team type is required"),
  description: yup.string().required("Team description is required")
});
