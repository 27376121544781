import styled from "@emotion/styled";
import {
  CalendarMonth,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSendMessage } from "../api";
import { useMessagesStore } from "../store";

const Select = styled.select`
  background: #e6e6e6;
  border-radius: 10px;
  border: none;
  flex: 1;
  font-size: 12px;
  padding-block: 4px;
`;

const RoundButton = ({ children, ...rest }) => (
  <Button
    sx={{
      fontSize: "12px",
      backgroundColor: "primary.main",
      color: "primary.contrastText",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "primary.main",
        color: "primary.contrastText"
      }
    }}
    {...rest}
  >
    {children}
  </Button>
);

const PickerWrapper = styled.div`
  & .react-datepicker__header {
    background: ${({ theme }) => theme.palette.secondary.main};
  }

  & .react-datepicker__current-month {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const CalendarButton = forwardRef(({ onClick }, ref) => (
  <IconButton color="primary" onClick={onClick} ref={ref}>
    <CalendarMonth />
  </IconButton>
));

CalendarButton.displayName = "CalendarButton";

export function ScheduleOptions() {
  const [hour, setHour] = useState(1);
  const [minutes, setMinutes] = useState(0);
  const [amPm, setAmPm] = useState("AM");
  const [date, setDate] = useState(new Date());
  const message = useMessagesStore(state => state.message);
  const portId = useMessagesStore(state => state.portId);
  const isScheduled = useMessagesStore(state => state.isScheduled);
  const isDraft = useMessagesStore(state => state.isDraft);
  const members = useMessagesStore(state => state.members);
  const teams = useMessagesStore(state => state.teams);
  const partners = useMessagesStore(state => state.partners);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const sendMessageMutation = useSendMessage();
  const { t } = useTranslation();

  const incrementHour = () => {
    if (hour < 12) {
      setHour(hour + 1);
    }
  };

  const decrementHour = () => {
    if (hour > 1) {
      setHour(hour - 1);
    }
  };

  const incrementMinutes = () => {
    if (minutes < 59) {
      setMinutes(minutes + 1);
    }
  };

  const decrementMinutes = () => {
    if (minutes > 0) {
      setMinutes(minutes - 1);
    }
  };

  const changeAmPm = () => {
    if (amPm === "AM") {
      setAmPm("PM");
    } else {
      setAmPm("AM");
    }
  };

  const scheduleMessage = () => {
    const selectedDateAndTime = `${moment(date).format("YYYY-MM-DD")} ${String(
      hour
    ).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${amPm}`;

    const messageData = {
      message_port_id: Number(portId),
      message: message,
      addresses: [
        {
          ...(members.size > 0 && { members: [...members.values()] }),
          ...(teams.size > 0 && { teams: [...teams.values()] }),
          ...(partners.size > 0 && { partners: [...partners.values()] })
        }
      ],
      is_scheduled: isScheduled,
      is_draft: isDraft,
      date_time: moment(selectedDateAndTime, "YYYY-MM-DD hh:mm A").format(
        "YYYY-MM-DDTHH:mm:ssZ"
      )
    };

    sendMessageMutation.mutate({
      churchId: selectedChurch?.id,
      messageData,
      notificationMessage: "Message scheduled successfully!"
    });
  };

  return (
    <Stack
      sx={{
        borderRadius: "10px",
        backgroundColor: "white",
        px: 6,
        py: 4,
        maxWidth: "320px"
      }}
      spacing={4}
    >
      <Typography
        variant="caption"
        color="primary"
        sx={{ textAlign: "center", fontWeight: 600 }}
      >
        {t("message.scheduledMessage.ScheduleMessage")}
      </Typography>
      <Stack direction="row" alignItems="center">
        <PickerWrapper>
          <DatePicker
            selected={date}
            onChange={selectedDate => setDate(selectedDate)}
            customInput={<CalendarButton />}
            showPopperArrow={false}
            withPortal
            minDate={moment().toDate()}
          />
        </PickerWrapper>
        <Typography variant="caption">
          {moment(date).format("ddd, MMM DD YYYY")}
        </Typography>
        <Stack alignItems="center">
          <IconButton color="primary" onClick={incrementHour}>
            <KeyboardArrowUp />
          </IconButton>
          <Typography variant="caption">{hour}</Typography>
          <IconButton color="primary" onClick={decrementHour}>
            <KeyboardArrowDown />
          </IconButton>
        </Stack>
        <Stack alignItems="center">
          <IconButton color="primary" onClick={incrementMinutes}>
            <KeyboardArrowUp />
          </IconButton>
          <Typography variant="caption">
            {String(minutes).padStart(2, "0")}
          </Typography>
          <IconButton color="primary" onClick={decrementMinutes}>
            <KeyboardArrowDown />
          </IconButton>
        </Stack>
        <Stack alignItems="center">
          <IconButton color="primary" onClick={changeAmPm}>
            <KeyboardArrowUp />
          </IconButton>
          <Typography variant="caption">{amPm}</Typography>
          <IconButton color="primary" onClick={changeAmPm}>
            <KeyboardArrowDown />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="caption">Repeat this</Typography>
        <Select>
          <option>Send once</option>
        </Select>
      </Stack>
      <RoundButton onClick={scheduleMessage}>
        Send on {moment(date).format("MMM DD")} at {hour}:
        {String(minutes).padStart(2, "0")} {amPm}
      </RoundButton>
    </Stack>
  );
}
