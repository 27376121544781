import { matchIsValidTel } from "mui-tel-input";
import * as yup from "yup";

export const signInSchema = yup.object({
  email: yup
    .string()
    .required("validation.email.required")
    .email("validation.email.invalid"),
  password: yup.string().required("validation.password.required")
});

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .required("validation.email.required")
    .email("validation.email.invalid")
});

export const createAccountSchema = yup.object({
  firstName: yup
    .string()
    .required("validation.firstName.required")
    .matches(/^[aA-zZ\s]+$/, "validation.firstName.string"),
  lastName: yup
    .string()
    .required("validation.lastName.required")
    .matches(/^[aA-zZ\s]+$/, "validation.lastName.string"),
  email: yup
    .string()
    .required("validation.email.required")
    .email("validation.email.invalid"),
  phone: yup
    .string()
    .required("validation.phone.required")
    .test("isPhoneValid", "validation.phone.invalid", value =>
      matchIsValidTel(value)
    ),
  password: yup
    .string()
    .required("validation.password.required")
    .min(8, "validation.password.min")
    .matches(/[a-z]+/, "validation.password.lowercase")
    .matches(/[A-Z]+/, "validation.password.uppercase")
    .matches(/\d+/, "validation.password.number"),
  confirmPassword: yup
    .string()
    .required("validation.password.confirm")
    .oneOf([yup.ref("password"), null], "validation.password.match"),
  acceptTerms: yup.boolean().oneOf([true], "validation.terms.required")
});

export const newPasswordSchema = yup.object({
  password: yup
    .string()
    .required("validation.password.required")
    .min(8, "validation.password.min")
    .matches(/[a-z]+/, "validation.password.lowercase")
    .matches(/[A-Z]+/, "validation.password.uppercase")
    .matches(/\d+/, "validation.password.number"),
  confirmPassword: yup
    .string()
    .required("validation.password.confirm")
    .oneOf([yup.ref("password"), null], "validation.password.match")
});
