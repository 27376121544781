import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { BsInfoCircle } from "react-icons/bs";

const AlertContainer = styled.div`
  padding: 11px 19px;
  background-color: ${({ bg }) => bg};
  border: 1.5px solid ${({ borderColor }) => borderColor};
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 15px;

  .startIcon {
    min-height: 24px;
    min-width: 24px;
    background-color: ${({ iconBg }) => iconBg};
    display: grid;
    place-items: center;
    font-size: 14px;
    border-radius: 100%;
    color: #ffffff;
  }

  .endIcon {
    min-height: 24px;
    min-width: 24px;
    background-color: #ffffff;
    display: grid;
    place-items: center;
    border-radius: 100%;
    margin-left: auto;
    cursor: pointer;
  }
`;

export const Alert = ({ message, startIcon = <BsInfoCircle />, endIcon }) => {
  const { t } = useTranslation();

  return (
    <AlertContainer iconBg="#3186EA" bg="#D8E6F9" borderColor="#B4CDEF">
      {startIcon && <span className="startIcon">{startIcon}</span>}
      {t(message)}
      {endIcon && <span className="endIcon">{endIcon}</span>}
    </AlertContainer>
  );
};
