/* eslint-disable no-unsafe-optional-chaining */
import styled from "@emotion/styled";
import {
  DeleteOutline,
  DoNotDisturbOutlined,
  MoreVert,
  UndoOutlined
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import RingsPhoto from "assets/images/Rings.png";
import { Button, DeleteModal, Notification } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import i18next from "i18next";
import _ from "lodash";
import moment from "moment/moment";
import { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { getEthiopianDate } from "utils/getEthiopianDate";
import { getGregorianDate } from "utils/getGregorianDate";
import "yet-another-react-lightbox/styles.css";
import {
  exportProfile,
  printProfile,
  useApproveMember,
  useDeleteMember,
  useMember,
  useMergeChildren,
  usePotentialSpouses,
  useRejectMember,
  useTerminateMember,
  useUpdateMember
} from "../api";
import { SpouseMatchModal, TerminateMemberModal } from "../components";
import { useMembersStore } from "../stores";

const Container = styled.div`
  padding: 22px 24px;
`;

const Tabs = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 16px;
  margin-bottom: 22px;
`;

const Tab = styled.li`
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "inherit"};
  font-weight: ${({ active }) => (active ? 700 : 400)};
  border-radius: 5px;
  margin-bottom: 9px;
  cursor: pointer;
`;

const Section = styled.div`
  padding: 20px;
  border-radius: 8px;
  background: white;
  /* border: 0.5px solid gray; */
`;

const BasicInfoSection = styled(Section)`
  background: linear-gradient(90deg, #72d0d3 0%, #218596 103.53%);
  min-height: 272px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  color: white;
`;

const FamilySection = styled(Section)`
  display: flex;
  padding: 0;
`;

const TeamSection = styled(Section)`
  display: flex;
  align-items: center;
`;

const Pill = styled.div`
  background: ${({ color }) => (color ? color : "#fbd8b0")};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;
`;

const DetailSection = styled(Section)`
  display: flex;
`;

const WhiteButton = styled(Button)`
  background: white;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const ApproveButton = styled(WhiteButton)`
  color: #22c55e;

  &:hover {
    color: #22c55e;
  }
`;

const RejectButton = styled(WhiteButton)`
  color: #ef4444;

  &:hover {
    color: #ef4444;
  }
`;

const LinkButton = styled.button`
  border: none;
  outline: none;
  background: none;
  color: white;
  border-bottom: 1px solid white;
  cursor: pointer;
`;

export const MemberProfileV2 = () => {
  const tabs = ["members.profile.MemberProfile"];
  const [activeTab, setActiveTab] = useState("members.profile.MemberProfile");
  const [activeDetailsTab, setActiveDetailsTab] = useState("Profile");
  const navigations = [
    { name: "Profile", translation: "addMembers.step1.PersonalProfile" },
    { name: "Address", translation: "addMembers.step1.Address" },
    {
      name: "Emergency Contact",
      translation: "addMembers.step1.EmergencyContact"
    },
    {
      name: "Academic and Job Status",
      translation: "addMembers.step2.AcademicStatus"
    },
    { name: "Marital Status", translation: "addMembers.step3.MaritalStatus" },
    { name: "Child Info", translation: "addMembers.step3.ChildInfo" },
    {
      name: "Previous Church info",
      translation: "addMembers.step4.PreviousChurchInfo"
    },
    {
      name: "Current Church",
      translation: "addMembers.step5.CurrentChurchEngagementInfo"
    },
    {
      name: "Engagement Info",
      translation: "addMembers.step5.MinistryEngagementInformation"
    }
  ];
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const { t } = useTranslation();
  const setFullMemberData = useMembersStore(state => state.setFullMemberData);
  const openSpouseMatchedModal = useMembersStore(
    state => state.openSpouseMatchedModal
  );
  const setMatchedSpouseData = useMembersStore(
    state => state.setMatchedSpouseData
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const { id } = useParams();
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setNotification = useCentralStore(state => state.setNotification);
  const approveMutation = useApproveMember();
  const rejectMutation = useRejectMember();
  const memberDeleteMutation = useDeleteMember();
  const [datePreview, setDatePreview] = useState("amh");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useMember({
    churchId: selectedChurch?.id,
    memberId: id,
    config: {
      onSuccess: response => {
        setFullMemberData(response.result);
      }
    }
  });

  const { refetch: matchSpouse } = usePotentialSpouses({
    churchId: selectedChurch?.id,
    memberId: id,
    config: {
      enabled: false,
      onSuccess: response => {
        if (response.status) {
          if (response.result) {
            setMatchedSpouseData(response.result);
            openSpouseMatchedModal();
          } else {
            setNotification({
              type: "info",
              message: "Spouse not found"
            });
          }
        }
      }
    }
  });

  const mergeChildrenMutation = useMergeChildren();

  useEffect(() => {
    setHeaderTitle(t("members.profile.MemberProfile"));
    return () => setHeaderTitle("");
  }, [i18next.language]);

  const handleExport = () => {
    exportProfile({
      type: "member",
      churchId: selectedChurch?.id,
      id: id,
      format: "pdf",
      orientation: "landscape"
    });
  };

  const handlePrint = () => {
    printProfile({
      type: "member",
      churchId: selectedChurch?.id,
      id: id,
      format: "pdf",
      orientation: "landscape"
    });
  };

  const getSpouseName = () => {
    if (fullMemberData?.spouse) {
      return `${fullMemberData?.spouse?.first_name} ${fullMemberData?.spouse?.father_name} ${fullMemberData?.spouse?.last_name}`;
    } else {
      return `${fullMemberData?.spouse_first_name} ${fullMemberData?.spouse_father_name} ${fullMemberData?.spouse_last_name}`;
    }
  };

  const childrenList =
    fullMemberData?.spouse && !fullMemberData?.has_children_merged
      ? [...fullMemberData?.children, ...fullMemberData?.spouse?.children]
      : fullMemberData?.children;

  return (
    <Container>
      <DeleteModal
        title={t("members.profile.DeleteMember")}
        onDelete={() => {
          memberDeleteMutation.mutate({
            churchId: selectedChurch?.id,
            memberId: fullMemberData?.id
          });
        }}
        name={`${fullMemberData?.first_name} ${fullMemberData?.father_name}`}
      />
      <Tabs>
        {tabs.map(tabItem => (
          <Tab
            key={tabItem}
            active={tabItem === activeTab}
            onClick={() => setActiveTab(tabItem)}
          >
            {t(tabItem)}
          </Tab>
        ))}
      </Tabs>
      {activeTab === "members.profile.MemberProfile" && (
        <>
          <Stack spacing="13px">
            <BasicInfoSection>
              {fullMemberData?.status !== "pending" && (
                <IconButton
                  aria-label="more"
                  sx={{
                    color: "white",
                    alignSelf: "flex-end"
                  }}
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" spacing="20px">
                  <Avatar
                    sx={{
                      width: "204px",
                      height: "204px",
                      border: "5px solid",
                      borderColor: "white"
                    }}
                    src={`${IMAGE_URL}/${fullMemberData?.profile_image_url}`}
                    alt={fullMemberData?.first_name}
                  />
                  <Stack spacing={2}>
                    <Typography variant="h4" sx={{ fontWeight: 700 }}>
                      {fullMemberData?.first_name} {fullMemberData?.father_name}{" "}
                      {fullMemberData?.last_name}
                    </Typography>
                    <Stack>
                      <Typography variant="subtitle2">
                        {t("form.common.email")}
                      </Typography>
                      <Typography sx={{ fontWeight: 700 }}>
                        {fullMemberData?.email}
                      </Typography>
                    </Stack>
                    <Divider sx={{ background: "white" }} />
                    <Stack direction="row" alignItems="center" spacing={5}>
                      <Stack>
                        <Typography variant="subtitle2">
                          {t("form.common.phone")}
                        </Typography>
                        <Typography sx={{ fontWeight: 700 }}>
                          {fullMemberData?.phone}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2">
                          {t("form.common.gender")}
                        </Typography>
                        <Typography sx={{ fontWeight: 700 }}>
                          {fullMemberData?.gender}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2">
                          {t("form.common.age")}
                        </Typography>
                        {fullMemberData?.date_of_birth ? (
                          <Typography sx={{ fontWeight: 700 }}>
                            {moment().diff(
                              moment(fullMemberData?.date_of_birth),
                              "years"
                            )}
                          </Typography>
                        ) : null}
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2">
                          {t("addMembers.step3.MaritalStatus")}
                        </Typography>
                        <Typography sx={{ fontWeight: 700 }}>
                          {fullMemberData?.marital_status}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                {fullMemberData?.status !== "pending" &&
                  fullMemberData?.status !== "inactive" && (
                    <Stack spacing={2}>
                      {fullMemberData?.status !== "reject" && (
                        <WhiteButton
                          variant="contained"
                          onClick={() => {
                            clearRecipients();
                            removeMember(fullMemberData.id);
                            addRecipient(
                              `${fullMemberData?.id}_${fullMemberData?.first_name}_${fullMemberData?.last_name}`,
                              fullMemberData
                            );
                            addMember(fullMemberData.id, {
                              id: fullMemberData.id
                            });
                            navigate("/messages");
                          }}
                        >
                          {t("members.profile.SendMessage")}
                        </WhiteButton>
                      )}
                      <WhiteButton
                        variant="contained"
                        onClick={() =>
                          navigate(`/members/${fullMemberData?.id}/update`)
                        }
                      >
                        {t("members.profile.EditMember")}
                      </WhiteButton>

                      {fullMemberData?.status !== "reject" && (
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <LinkButton
                            onClick={() => navigate(`/members/${id}/id`)}
                          >
                            {t("members.profile.CreateID")}
                          </LinkButton>
                          <LinkButton onClick={handleExport}>
                            {t("members.profile.ExportProfile")}
                          </LinkButton>
                          <LinkButton onClick={handlePrint}>
                            {t("members.profile.PrintProfile")}
                          </LinkButton>
                        </Stack>
                      )}
                    </Stack>
                  )}

                {fullMemberData?.status === "pending" && (
                  <Stack spacing={2}>
                    <ApproveButton
                      variant="contained"
                      onClick={() =>
                        approveMutation.mutate({
                          churchId: selectedChurch?.id,
                          memberId: fullMemberData?.id
                        })
                      }
                      disabled={
                        approveMutation.isLoading || rejectMutation.isLoading
                      }
                    >
                      Approve
                    </ApproveButton>
                    <RejectButton
                      variant="contained"
                      onClick={() =>
                        rejectMutation.mutate({
                          churchId: selectedChurch?.id,
                          memberId: fullMemberData?.id
                        })
                      }
                      disabled={
                        rejectMutation.isLoading || approveMutation.isLoading
                      }
                    >
                      Reject
                    </RejectButton>
                    <WhiteButton
                      variant="contained"
                      onClick={() =>
                        navigate(`/members/${fullMemberData?.id}/update`)
                      }
                      disabled={
                        rejectMutation.isLoading || approveMutation.isLoading
                      }
                    >
                      {t("members.profile.EditMember")}
                    </WhiteButton>
                  </Stack>
                )}
              </Stack>
            </BasicInfoSection>

            <FamilySection>
              <Stack flex={1} spacing="20px" py="20px" px="24px">
                <Stack direction="row" alignItems="center" spacing="20px">
                  {/* <Avatar
                sx={{
                  width: "140px",
                  height: "140px",
                  border: "2px solid",
                  borderColor: "primary.main"
                }}
                src={`${IMAGE_URL}/${fullMemberData?.profile_image_url}`}
                alt={fullMemberData?.first_name}
              /> */}
                  <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={10}>
                      {fullMemberData?.marital_status !== "single" && (
                        <Stack>
                          <Typography variant="subtitle2">
                            {t("addMembers.step3.SpouseName")}
                          </Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              sx={{
                                fontWeight: 700,
                                color: "primary.main",
                                cursor: fullMemberData?.spouse
                                  ? "pointer"
                                  : "inherit"
                              }}
                              onClick={() => {
                                if (fullMemberData?.spouse) {
                                  window.open(
                                    `/members/${fullMemberData?.spouse?.id}`,
                                    "__blank"
                                  );
                                }
                              }}
                            >
                              {getSpouseName()}
                            </Typography>
                            {fullMemberData?.status === "active" &&
                              fullMemberData?.marital_status === "married" &&
                              fullMemberData?.is_spouse_of === null && (
                                <Button
                                  flavor="secondary"
                                  onClick={() => matchSpouse()}
                                >
                                  Match
                                </Button>
                              )}
                          </Stack>
                        </Stack>
                      )}
                      <Stack>
                        <Typography variant="subtitle2">
                          {t("members.profile.Children")}
                        </Typography>
                        {_.isEmpty(fullMemberData?.children) && (
                          <Typography
                            sx={{ fontWeight: 700, color: "primary.main" }}
                          >
                            No children
                          </Typography>
                        )}
                        <Stack>
                          {!_.isEmpty(fullMemberData?.children) &&
                            childrenList.map(child => (
                              <Typography
                                key={child.id}
                                sx={{
                                  fontWeight: 700,
                                  color: "primary.main",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  if (child.member_id !== null) {
                                    window.open(
                                      `/members/${child.member_id}`,
                                      "__blank"
                                    );
                                  }

                                  if (child.is_kid === 1) {
                                    window.open(
                                      `/kids/${child.pivot.children_id}`,
                                      "__blank"
                                    );
                                  }
                                }}
                              >
                                {child.first_name} {child.father_name}{" "}
                                {child.last_name}
                              </Typography>
                            ))}
                          {!_.isEmpty(fullMemberData?.children) &&
                            fullMemberData?.spouse &&
                            !_.isEmpty(fullMemberData?.spouse?.children) &&
                            fullMemberData?.has_children_merged === "0" && (
                              <Button
                                onClick={() =>
                                  mergeChildrenMutation.mutate({
                                    churchId: selectedChurch?.id,
                                    memberId: id,
                                    spouseId: fullMemberData?.spouse?.id
                                  })
                                }
                              >
                                Merge Children
                              </Button>
                            )}
                        </Stack>
                      </Stack>
                    </Stack>
                    {fullMemberData?.marital_status !== "single" && (
                      <Stack direction="row" alignItems="center" spacing={5}>
                        <Stack>
                          <Typography variant="subtitle2">
                            {t("form.common.phone")}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 700, color: "primary.main" }}
                          >
                            {fullMemberData?.spouse_phone_number}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              {fullMemberData?.marital_status !== "single" && (
                <Stack
                  sx={{
                    background: `linear-gradient(257.23deg, #FBD8B0 100%, #FBD8B0 100%), url(${RingsPhoto})`,
                    backgroundBlendMode: "multiply",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    py: "40px",
                    px: "16px",
                    color: "white",
                    width: "327px",
                    alignItems: "stretch"
                  }}
                  spacing={1}
                >
                  <Typography variant="h4" sx={{ fontWeight: 700 }}>
                    <Trans i18nKey="members.profile.MaritalDate" />
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {fullMemberData?.marital_date ? (
                      <Typography>
                        {datePreview === "eng"
                          ? getGregorianDate(fullMemberData?.marital_date)
                          : getEthiopianDate(fullMemberData?.marital_date)}
                      </Typography>
                    ) : null}
                    {fullMemberData?.marital_date && (
                      <Typography
                        onClick={() => {
                          if (datePreview === "amh") setDatePreview("eng");
                          if (datePreview === "eng") setDatePreview("amh");
                        }}
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          cursor: "pointer",
                          color: "white",
                          userSelect: "none"
                        }}
                      >
                        {datePreview}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              )}
            </FamilySection>

            <TeamSection>
              <Stack flex={1} sx={{ borderRight: "0.5px solid #9CA3AF" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                  {t("members.profile.TeamEngagement")}
                </Typography>
                <Typography variant="caption">
                  {t("members.profile.MemberTeamEngagement")}
                </Typography>
                <Stack direction="row" spacing="18px" mt="18px">
                  {fullMemberData?.teams?.map(team => (
                    <Pill key={team.id}>{team.name}</Pill>
                  ))}
                </Stack>
              </Stack>
              <Stack flex={1} sx={{ marginLeft: "38px" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                  {t("members.profile.ChurchContribution")}
                </Typography>
                <Typography variant="caption">
                  {t("members.profile.TotalChurchContribution")}
                </Typography>
                <Stack direction="row" spacing="18px" mt="18px">
                  {/* <Pill color="#74DFD5">5790 Birr</Pill> */}
                </Stack>
              </Stack>
            </TeamSection>

            <DetailSection>
              <Stack pr={1} sx={{ borderRight: "0.5px solid #9CA3AF" }}>
                {navigations.map(navigation => (
                  <Typography
                    key={navigation.name}
                    variant="subtitle2"
                    p="10px"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: `${
                        activeDetailsTab === navigation.name ? 700 : 400
                      }`,
                      background: `${
                        activeDetailsTab === navigation.name
                          ? "#F3F4F6"
                          : "white"
                      }`,
                      cursor: "pointer",
                      borderRadius: "4px",
                      ":hover": {
                        background: "#F3F4F6"
                      }
                    }}
                    onClick={() => setActiveDetailsTab(navigation.name)}
                  >
                    {t(navigation.translation)}
                  </Typography>
                ))}
              </Stack>
              <Box pl={4} flex={1} sx={{ textTransform: "capitalize" }}>
                {navigationLookup[activeDetailsTab]}
              </Box>
            </DetailSection>
          </Stack>
          <Popover
            anchorEl={anchorEl}
            id="profile-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              elevation: 2
            }}
          >
            <ProfileMenu />
          </Popover>
        </>
      )}
      <TerminateMemberModal />
      <Notification />
      <SpouseMatchModal />
    </Container>
  );
};

const PersonalProfile = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const [datePreview, setDatePreview] = useState("amh");

  return (
    <Stack alignItems="flex-start" direction="row">
      <Stack spacing={4} flex={1}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.MotherName")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.mother_first_name}{" "}
            {fullMemberData?.mother_father_name}{" "}
            {fullMemberData?.mother_grand_father_name}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Nationality")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.nationality?.nationality}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={4}>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step1.IdType")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.id_type}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step1.IdNumber")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.id_number}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Birthplace")}
          </Typography>
          <Stack direction="row" spacing={4}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.country")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.birth_country?.name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.region")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.birth_region?.name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.city")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.birth_city?.name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.dateOfBirth")}
              </Typography>
              <Stack direction="row" spacing={1}>
                {fullMemberData?.date_of_birth ? (
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {datePreview === "eng"
                      ? getGregorianDate(fullMemberData?.date_of_birth)
                      : getEthiopianDate(fullMemberData?.date_of_birth)}
                  </Typography>
                ) : null}
                {fullMemberData?.date_of_birth ? (
                  <Typography
                    onClick={() => {
                      if (datePreview === "amh") setDatePreview("eng");
                      if (datePreview === "eng") setDatePreview("amh");
                    }}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "primary.main",
                      userSelect: "none"
                    }}
                  >
                    {datePreview}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Language")}
          </Typography>
          <Stack>
            {fullMemberData?.languages?.map(language => (
              <Typography
                key={language}
                sx={{ color: "primary.main", fontWeight: 700 }}
              >
                {language}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2">
          {t("members.profile.IDCard")}
        </Typography>
        <Avatar
          sx={{
            width: "280px",
            height: "154px",
            borderRadius: "8px"
          }}
          imgProps={{
            style: {
              objectFit: "contain"
            }
          }}
          src={`${IMAGE_URL}/${fullMemberData?.id_image_url}`}
          alt="Id card"
          variant="square"
        />
      </Stack>
    </Stack>
  );
};

const Address = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.PhoneNumber")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.phone}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.OfficeNumber")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.office_number}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.email")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.email}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.POBox")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.po_box}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step1.SocialMediaLink")}
        </Typography>
        {fullMemberData?.social_media_links?.map(socialLink => (
          <Typography
            key={socialLink}
            sx={{
              color: "primary.main",
              fontWeight: 700,
              textTransform: "lowercase"
            }}
          >
            {socialLink}
          </Typography>
        ))}
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step1.Nationality")}
        </Typography>
        <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
          {fullMemberData?.nationality?.nationality}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.country")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_country?.name}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.region")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_region?.name}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.zone")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_zone?.name}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.city")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_city?.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.subcity")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_sub_city?.name}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.woreda")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_woreda?.name}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.kebele")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_kebele}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.houseNumber")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.house_number}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step1.SpecificLocation")}
        </Typography>
        <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
          {fullMemberData?.specific_location}
        </Typography>
      </Stack>
    </Stack>
  );
};

const EmergencyContact = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("form.common.fullName")}
        </Typography>
        <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
          {fullMemberData?.emergency_contact_first_name}{" "}
          {fullMemberData?.emergency_contact_father_name}{" "}
          {fullMemberData?.emergency_contact_last_name}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.phone")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.emergency_contact_phone}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Relationship")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.emergency_contact_relationship}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("form.common.address")}
        </Typography>
        <Stack direction="row" spacing={4}>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("form.common.country")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.emergency_contact_country?.name}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("form.common.region")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.emergency_contact_region?.name}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("form.common.city")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.emergency_contact_city?.name}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const AcademicAndJobStatus = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step2.AcademicStatus")}
        </Typography>
        {fullMemberData?.education_and_skills?.map(
          (education_and_skill, index) => (
            <Typography
              key={index}
              sx={{ color: "primary.main", fontWeight: 700 }}
            >
              {education_and_skill.academic_status}:{" "}
              {education_and_skill.professional_skill}
            </Typography>
          )
        )}
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step2.FullTimeJob")}
        </Typography>
        <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
          {fullMemberData?.job_status}
        </Typography>
      </Stack>
      {fullMemberData?.job_status !== "student" && (
        <>
          <Stack direction="row" spacing={4}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step2.Profession")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.profession}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step2.Workplace")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.work_place}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step2.PartTimeJob")}
        </Typography>
        <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
          {fullMemberData?.custom_job_status}
        </Typography>
      </Stack>
    </Stack>
  );
};

const MaritalStatus = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [datePreview, setDatePreview] = useState("amh");

  const getSpouseName = () => {
    if (fullMemberData?.spouse) {
      return `${fullMemberData?.spouse?.first_name} ${fullMemberData?.spouse?.father_name} ${fullMemberData?.spouse?.last_name}`;
    } else {
      return `${fullMemberData?.spouse_first_name} ${fullMemberData?.spouse_father_name} ${fullMemberData?.spouse_last_name}`;
    }
  };

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step3.MaritalStatus")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.marital_status}
          </Typography>
        </Stack>
        {fullMemberData?.marital_status !== "single" && (
          <>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.MaritalDate")}
              </Typography>
              <Stack direction="row" spacing={1}>
                {fullMemberData?.marital_date ? (
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {datePreview === "eng"
                      ? getGregorianDate(fullMemberData?.marital_date)
                      : getEthiopianDate(fullMemberData?.marital_date)}
                  </Typography>
                ) : null}
                {fullMemberData?.marital_date ? (
                  <Typography
                    onClick={() => {
                      if (datePreview === "amh") setDatePreview("eng");
                      if (datePreview === "eng") setDatePreview("amh");
                    }}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "primary.main",
                      userSelect: "none"
                    }}
                  >
                    {datePreview}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.TypeOfMarriage")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.type_of_marriage}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
      {fullMemberData?.marital_status !== "single" && (
        <>
          <Stack direction="row" spacing={4}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.IsSpouseABeliever")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.is_spouse_believer === 1 ? "Yes" : "No"}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.IsSpouseAMember")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.is_spouse_member === 1 ? "Yes" : "No"}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.fullName")}
              </Typography>
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: 700
                }}
              >
                {getSpouseName()}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.phone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.spouse_phone_number}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.dateOfBirth")}
              </Typography>
              <Stack direction="row" spacing={1}>
                {fullMemberData?.spouse_date_of_birth ? (
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {datePreview === "eng"
                      ? getGregorianDate(fullMemberData?.spouse_date_of_birth)
                      : getEthiopianDate(fullMemberData?.spouse_date_of_birth)}
                  </Typography>
                ) : null}
                {fullMemberData?.spouse_date_of_birth ? (
                  <Typography
                    onClick={() => {
                      if (datePreview === "amh") setDatePreview("eng");
                      if (datePreview === "eng") setDatePreview("amh");
                    }}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "primary.main",
                      userSelect: "none"
                    }}
                  >
                    {datePreview}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step3.SpouseMotherName")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.spouse_mother_name}{" "}
              {fullMemberData?.spouse_mother_father_name}{" "}
              {fullMemberData?.spouse_mother_grand_father_name}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.SpouseProfessionalSkills")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.spouse_professional_skill}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.SpouseOfficeName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.spouse_office_name}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step3.SpouseChurchName")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.is_spouse_member === 1
                ? selectedChurch?.name
                : fullMemberData?.spouse_church_name}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};

const ChildInfo = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const [datePreview, setDatePreview] = useState("amh");

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step3.HaveChildrenOrDependencies")}
        </Typography>
        <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
          {fullMemberData?.child_and_dependencies === 1 ? "Yes" : "No"}
        </Typography>
      </Stack>
      {!_.isEmpty(fullMemberData?.children) &&
        fullMemberData?.children?.map(child => (
          <Fragment key={child.id}>
            <Stack direction="row" spacing={4}>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.fullName")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {child?.first_name} {child?.father_name} {child?.last_name}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.gender")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {child?.gender}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.Nationality")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {child?.nationality?.nationality}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.RelationshipWithChild")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {child?.member_children?.[0]?.relation_type}
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.PlaceOfBirth")}
              </Typography>
              <Stack direction="row" spacing={4}>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.country")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {child?.birth_country?.name}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.region")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {child?.birth_region?.name}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.city")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {child?.birth_city?.name}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.dateOfBirth")}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {child?.date_of_birth ? (
                      <Typography
                        sx={{ color: "primary.main", fontWeight: 700 }}
                      >
                        {datePreview === "eng"
                          ? getGregorianDate(child?.date_of_birth)
                          : getEthiopianDate(child?.date_of_birth)}
                      </Typography>
                    ) : null}
                    {child?.date_of_birth ? (
                      <Typography
                        onClick={() => {
                          if (datePreview === "amh") setDatePreview("eng");
                          if (datePreview === "eng") setDatePreview("amh");
                        }}
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          cursor: "pointer",
                          color: "primary.main",
                          userSelect: "none"
                        }}
                      >
                        {datePreview}
                      </Typography>
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step1.MotherName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {child?.mother_name} {child?.mother_father_name}{" "}
                {child?.mother_last_name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={4}>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.IsChildBeliever")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {child?.is_believer === 1 ? "Yes" : "No"}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.IsChildAMember")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {child?.is_member === 1 ? "Yes" : "No"}
                </Typography>
              </Stack>
            </Stack>
          </Fragment>
        ))}
    </Stack>
  );
};

const PreviousChurchInfo = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const [datePreview, setDatePreview] = useState("amh");

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step4.AreYouTransferredFromOtherChurch")}
        </Typography>
        <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
          {fullMemberData?.transfer_from_other_church === 1 ? "Yes" : "No"}
        </Typography>
      </Stack>
      {fullMemberData?.transfer_from_other_church === 1 && (
        <>
          <Stack direction="row" spacing={4}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step4.FormerChurchName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.previous_church_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step4.DateOfResignation")}
              </Typography>
              <Stack direction="row" spacing={1}>
                {fullMemberData?.date_of_resignation ? (
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {datePreview === "eng"
                      ? getGregorianDate(fullMemberData?.date_of_resignation)
                      : getEthiopianDate(fullMemberData?.date_of_resignation)}
                  </Typography>
                ) : null}
                {fullMemberData?.date_of_resignation ? (
                  <Typography
                    onClick={() => {
                      if (datePreview === "amh") setDatePreview("eng");
                      if (datePreview === "eng") setDatePreview("amh");
                    }}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "primary.main",
                      userSelect: "none"
                    }}
                  >
                    {datePreview}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step4.PreviousChurchPastorInformation")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.pastor_full_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.phone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.pastor_phone_number}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step4.ReasonForResignation")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {fullMemberData?.reason_for_resignation}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step4.LetterOfResignation")}
            </Typography>
            {_.isEmpty(fullMemberData?.letter_of_resignation) ? (
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                No letter uploaded
              </Typography>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    `${IMAGE_URL}/${fullMemberData?.letter_of_resignation}`,
                    "_blank"
                  );
                }}
              >
                View Letter
              </Button>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

const SalvationProfile = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const [datePreview, setDatePreview] = useState("amh");

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.FormerBelief")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.former_belief}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.HowDidYouAcceptJesusChrist")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.how_you_accept_christ}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.SalvationDate")}
          </Typography>
          <Stack direction="row" spacing={1}>
            {fullMemberData?.salvation_date ? (
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {datePreview === "eng"
                  ? getGregorianDate(fullMemberData?.salvation_date)
                  : getEthiopianDate(fullMemberData?.salvation_date)}
              </Typography>
            ) : null}
            {fullMemberData?.salvation_date && (
              <Typography
                onClick={() => {
                  if (datePreview === "amh") setDatePreview("eng");
                  if (datePreview === "eng") setDatePreview("amh");
                }}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "primary.main",
                  userSelect: "none"
                }}
              >
                {datePreview}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.AreYouBaptized")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.is_baptized === 1 ? "Yes" : "No"}
          </Typography>
        </Stack>
        {fullMemberData?.is_baptized === 1 && (
          <>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step5.BaptizedChurch")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.baptism_church}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step5.DateOfBaptism")}
              </Typography>
              <Stack direction="row" spacing={1}>
                {fullMemberData?.baptism_date ? (
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {datePreview === "eng"
                      ? getGregorianDate(fullMemberData?.baptism_date)
                      : getEthiopianDate(fullMemberData?.baptism_date)}
                  </Typography>
                ) : null}
                {fullMemberData?.baptism_date && (
                  <Typography
                    onClick={() => {
                      if (datePreview === "amh") setDatePreview("eng");
                      if (datePreview === "eng") setDatePreview("amh");
                    }}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "primary.main",
                      userSelect: "none"
                    }}
                  >
                    {datePreview}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.IsThereHomeCellInYourChurch")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.is_home_cell === 1 ? "Yes" : "No"}
          </Typography>
        </Stack>
        {fullMemberData?.is_home_cell === 1 && (
          <>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step5.HomeCellYouEngagedIn")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.home_cell_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step5.HomeCellCoordinator")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {fullMemberData?.home_cell_cordinator}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
      {fullMemberData?.is_home_cell === 1 && (
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.DateYouStartEngaging")}
          </Typography>
          <Stack direction="row" spacing={1}>
            {fullMemberData?.date_joined_home_cell ? (
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {datePreview === "eng"
                  ? getGregorianDate(fullMemberData?.date_joined_home_cell)
                  : getEthiopianDate(fullMemberData?.date_joined_home_cell)}
              </Typography>
            ) : null}
            {fullMemberData?.date_joined_home_cell && (
              <Typography
                onClick={() => {
                  if (datePreview === "amh") setDatePreview("eng");
                  if (datePreview === "eng") setDatePreview("amh");
                }}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "primary.main",
                  userSelect: "none"
                }}
              >
                {datePreview}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const CurrentChurchEngagementInfo = () => {
  const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const [datePreview, setDatePreview] = useState("amh");

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>
          {t("addMembers.step5.MembershipDate")}
        </Typography>
        <Stack direction="row" spacing={1}>
          {fullMemberData?.membership_date ? (
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {datePreview === "eng"
                ? getGregorianDate(fullMemberData?.membership_date)
                : getEthiopianDate(fullMemberData?.membership_date)}
            </Typography>
          ) : null}
          {fullMemberData?.membership_date && (
            <Typography
              onClick={() => {
                if (datePreview === "amh") setDatePreview("eng");
                if (datePreview === "eng") setDatePreview("amh");
              }}
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                cursor: "pointer",
                color: "primary.main",
                userSelect: "none"
              }}
            >
              {datePreview}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.YourCurrentMinistry")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.current_ministry}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.TheDateYouStartEngaging")}
          </Typography>
          <Stack direction="row" spacing={1}>
            {fullMemberData?.date_joined_current_ministry ? (
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {datePreview === "eng"
                  ? getGregorianDate(
                      fullMemberData?.date_joined_current_ministry
                    )
                  : getEthiopianDate(
                      fullMemberData?.date_joined_current_ministry
                    )}
              </Typography>
            ) : null}
            {fullMemberData?.date_joined_current_ministry && (
              <Typography
                onClick={() => {
                  if (datePreview === "amh") setDatePreview("eng");
                  if (datePreview === "eng") setDatePreview("amh");
                }}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "primary.main",
                  userSelect: "none"
                }}
              >
                {datePreview}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.YourRoleInTheMinistry")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.role_in_current_ministry}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.MinistryPreferences")}
          </Typography>
          {fullMemberData?.ministry_preference?.map(preference => (
            <Typography
              key={preference.ministry}
              sx={{ color: "primary.main", fontWeight: 700 }}
            >
              {preference.ministry}
            </Typography>
          ))}
        </Stack>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step5.GiftsAndTalents")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {fullMemberData?.gifts_and_talents}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const navigationLookup = {
  Profile: <PersonalProfile />,
  Address: <Address />,
  "Emergency Contact": <EmergencyContact />,
  "Academic and Job Status": <AcademicAndJobStatus />,
  "Marital Status": <MaritalStatus />,
  "Child Info": <ChildInfo />,
  "Previous Church info": <PreviousChurchInfo />,
  "Current Church": <SalvationProfile />,
  "Engagement Info": <CurrentChurchEngagementInfo />
};

const ProfileMenu = () => {
  // const { t } = useTranslation();
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const terminateMutation = useTerminateMember();
  const updateMemberMutation = useUpdateMember();
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const { t } = useTranslation();

  return (
    <Box sx={{ minWidth: "168px", overflow: "hidden", borderRadius: "5px" }}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        {t("members.profile.ProfileSetting")}
      </Box>
      <Stack
        sx={{
          backgroundColor: "white",
          py: "9px",
          px: "12px"
        }}
        spacing={1}
      >
        {fullMemberData?.status === "active" && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              py: 1,
              px: 2,
              cursor: "pointer"
            }}
            onClick={() =>
              terminateMutation.mutate({
                churchId: selectedChurch?.id,
                memberId: fullMemberData?.id
              })
            }
          >
            <DoNotDisturbOutlined />
            <Typography variant="caption">
              {t("members.profile.TerminateMember")}
            </Typography>
          </Stack>
        )}
        {fullMemberData?.status === "inactive" && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              py: 1,
              px: 2,
              cursor: "pointer"
            }}
            onClick={() =>
              updateMemberMutation.mutate({
                churchId: selectedChurch?.id,
                memberId: fullMemberData?.id,
                memberData: {
                  status: "active"
                }
              })
            }
          >
            <UndoOutlined />
            <Typography variant="caption">Reinstate member</Typography>
          </Stack>
        )}
        {fullMemberData?.status === "reject" && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              py: 1,
              px: 2,
              cursor: "pointer"
            }}
            onClick={() =>
              updateMemberMutation.mutate({
                churchId: selectedChurch?.id,
                memberId: fullMemberData?.id,
                memberData: {
                  status: "pending"
                }
              })
            }
          >
            <UndoOutlined />
            <Typography variant="caption">Reinstate member</Typography>
          </Stack>
        )}
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            backgroundColor: "#FCD1D1",
            color: "#F44949",
            py: 1,
            px: 2,
            cursor: "pointer"
          }}
          onClick={openDeleteModal}
        >
          <DeleteOutline />
          <Typography variant="caption">
            {t("members.profile.DeleteMember")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
