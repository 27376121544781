import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useExpenseStore, useRejectSettlement } from "features/finances";
import { useParams } from "react-router-dom";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Textarea = styled.textarea`
  resize: none;
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

export function RejectionReasonModal({ data }) {
  const rejectionReasonOpen = useExpenseStore(
    state => state.rejectionReasonOpen
  );
  const closeRejectionReasonModal = useExpenseStore(
    state => state.closeRejectionReasonModal
  );
  const rejectSettlementMutation = useRejectSettlement();
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  return (
    <BaseModal
      title="Reason for rejection"
      isOpen={rejectionReasonOpen}
      onClose={closeRejectionReasonModal}
      minHeight="200px"
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeRejectionReasonModal}
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={() => {
              rejectSettlementMutation.mutate({
                churchId: selectedChurch?.id,
                expenseId: id,
                settlementId: data?.id
              });
            }}
          >
            Submit
          </Button>
        </Stack>
      }
    >
      <Field>
        <Textarea
          id="description"
          placeholder="Write your rejection here"
          rows={4}
        />
      </Field>
    </BaseModal>
  );
}
