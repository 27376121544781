import styled from "@emotion/styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CreatePortModal,
  PortManagementPanel,
  SmsStatusNavbar
} from "../components";

const Container = styled.div`
  width: 100%;
  padding: 32px;
  background: white;
  grid-column: 2 / span 2;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MessageSettings = () => {
  const [activeTab, setActiveTab] = useState("port");
  const { t } = useTranslation();

  return (
    <Container>
      <CreatePortModal />
      <SmsStatusNavbar
        tabs={[{ name: t("message.settings.PortManagement"), key: "port" }]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === "port" && <PortManagementPanel />}
    </Container>
  );
};
