import { useState } from "react";

export const useFileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const openFileDialog = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.addEventListener("change", handleFileChange);
    input.click();
  };

  const resetFile = () => {
    setSelectedFile(null);
  };

  return [selectedFile, openFileDialog, resetFile];
};
