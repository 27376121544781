import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getZones = ({ regionId }) => {
  return axios.get(`/addresses/regions/${regionId}/zones`);
};

export const useZones = ({ regionId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["zones", regionId],
    queryFn: () => getZones({ regionId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
