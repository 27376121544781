import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const Container = styled.div`
  height: 100%;
  max-height: 913px;
  max-width: 274px;
  background: ${({ theme }) => theme.palette.secondary.main};
  padding-bottom: 146px;
`;

const Title = styled.h3`
  padding: 32px 19px;
  margin-bottom: 125px;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MenuItem = styled.li`
  padding: 10px 40px;
  background: ${({ active }) => active && "white"};
  font-weight: ${({ active }) => (active ? 500 : 400)};
  display: flex;
  gap: 8px;
  align-items: center;
`;

const RouteLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`;

// const Indicator = styled.div`
//   height: 16px;
//   width: 16px;
//   background: ${({ color, theme }) =>
//     color ? theme.palette[color].main : theme.palette.primary.main};
//   color: ${({ theme }) => theme.palette.primary.contrastText};
//   display: grid;
//   place-items: center;
//   font-size: 8px;
//   border-radius: 50%;
// `;

const menuItems = [
  { name: "message.newMessage.NewMessage", path: "/messages/new" },
  { name: "message.smsStatus.SmsStatus", path: "/messages/status" },
  { name: "message.allMessage.AllMessage", path: "/messages/all" },
  {
    name: "message.scheduledMessage.ScheduledMessage",
    path: "/messages/scheduled"
  },
  { name: "message.sentMessages.SentMessages", path: "/messages/sent" },
  { name: "message.inbox.Inbox", path: "/messages/inbox" },
  { name: "message.draftMessage.DraftMessage", path: "/messages/draft" },
  { name: "message.template.Template", path: "/messages/template" },
  { name: "message.settings.Settings", path: "/messages/settings" }
];

// const statusMapping = {
//   Draft: "error"
// };

export const MessageSidebar = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t("message.messageBoard.MessageBoard")}</Title>
      <Menu>
        {menuItems.map(menuItem => (
          <RouteLink
            key={menuItem.name}
            to={
              menuItem.path !== "/messages/status" ? menuItem.path : undefined
            }
            end
            disabled={menuItem.path === "/messages/status"}
          >
            <MenuItem active={pathname === menuItem.path}>
              <span>{t(menuItem.name)}</span>
              {/* {["Scheduled Message", "Inbox", "Draft"].includes(
                menuItem.name
              ) && (
                <Indicator color={statusMapping[menuItem.name]}>14</Indicator>
              )} */}
            </MenuItem>
          </RouteLink>
        ))}
      </Menu>
    </Container>
  );
};
