import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getBudgetYears = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/budget-years`);
};

export const useBudgetYears = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["budget-years", churchId],
    queryFn: () => getBudgetYears({ churchId })
  });
};
