import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";
import _ from "lodash";

const filterString = filters => {
  return filters.join("&");
};

export const getMessages = ({ churchId, filters }) => {
  if (_.isEmpty(filters)) {
    return axios.get(`/churches/${churchId}/messages`);
  }

  return axios.get(`/churches/${churchId}/messages?${filterString(filters)}`);
};

export const useMessages = ({ churchId, filters, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["messages", filters],
    queryFn: () => getMessages({ churchId, filters })
  });
};
