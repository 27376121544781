import { useState } from "react";
import { ExpenseTable, UpdateExpenseModal } from "../components";

export const Expense = () => {
  const [expense, setExpense] = useState(null);

  return (
    <>
      <UpdateExpenseModal data={expense} />
      <ExpenseTable onEdit={record => setExpense(record)} />
    </>
  );
};
