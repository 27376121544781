import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Notification, PhoneInputField, TextField } from "components/UI";
import i18next from "i18next";
import _ from "lodash";
import { useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCentralStore } from "stores";
import { useChangePassword, useUpdateProfile } from "../api";
import { changePasswordSchema, profileSchema } from "../validation";

const Container = styled(Stack)``;

export function ViewUserProfile() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const auth = useAuthUser();
  const passwordMutation = useChangePassword();
  const profileMutation = useUpdateProfile();
  const { t } = useTranslation();

  const { handleSubmit, reset } = useForm({
    resolver: yupResolver(changePasswordSchema)
  });

  const {
    control,
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    formState: { errors: errorsProfile }
  } = useForm({
    resolver: yupResolver(profileSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("users.userProfile.userProfile"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  useEffect(() => {
    setHeaderBackground(false);

    return () => setHeaderBackground(true);
  }, []);

  useEffect(() => {
    if (passwordMutation.isSuccess) {
      reset();
    }
  }, [passwordMutation.isSuccess]);

  const handleChangePassword = data => {
    passwordMutation.mutate({
      passwordData: _.omit(data, ["confirm_password"])
    });
  };

  const handleUpdateProfile = data => {
    const userData = _.omit(data, ["email", "phone"]);

    profileMutation.mutate({
      userData
    });
  };

  return (
    <Box>
      <Notification />
      <Stack spacing={4} sx={{ mt: 4 }}>
        <Grid container spacing={8}>
          <Grid item xs>
            <Stack
              component="form"
              onSubmit={handleSubmitProfile(handleUpdateProfile)}
              spacing={2}
            >
              <TextField
                label={t("users.userProfile.firstName")}
                name="first_name"
                error={!!errorsProfile.first_name?.message}
                helperText={errorsProfile.first_name?.message}
                register={registerProfile}
                defaultValue={auth().first_name}
                disabled={true}
              />
              <TextField
                label={t("users.userProfile.lastName")}
                name="last_name"
                error={!!errorsProfile.lastName?.message}
                helperText={errorsProfile.lastName?.message}
                register={registerProfile}
                defaultValue={auth().last_name}
                disabled={true}
              />
              <TextField
                label={t("users.userProfile.emailAddress")}
                name="email"
                error={!!errorsProfile.email?.message}
                helperText={errorsProfile.email?.message}
                register={registerProfile}
                autoComplete="email"
                defaultValue={auth().email}
                disabled={true}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t("users.userProfile.phoneNumber")}
                    variant="filled"
                    error={!!errorsProfile.phone?.message}
                    helperText={errorsProfile.phone?.message}
                    {...field}
                    disabled={true}
                  />
                )}
                defaultValue={auth().phone}
              />
              <TextField
                label={t("users.userProfile.roleInChurch")}
                name="role"
                error={!!errorsProfile.role?.message}
                helperText={errorsProfile.role?.message}
                register={registerProfile}
                defaultValue=""
                disabled={true}
              />
            </Stack>
          </Grid>
          <Grid item xs>
            <Container
              component="form"
              spacing={2}
              onSubmit={handleSubmit(handleChangePassword)}
            >
              <Stack alignItems="flex-start" spacing={1}>
                <Typography variant="subtitle2">User Role</Typography>
                <Chip
                  label="Admin"
                  sx={{ backgroundColor: "#40B3E0", color: "white" }}
                />
              </Stack>
              <UserLogs />
            </Container>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

const UserLogs = () => (
  <Box
    sx={{
      overflow: "hidden"
    }}
  >
    <Box
      sx={{
        p: 2,
        backgroundColor: "primary.main",
        color: "primary.contrastText"
      }}
    >
      User Logs
    </Box>
    <Stack
      sx={{
        p: 1,
        backgroundColor: "neutral.main"
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Action</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Date & Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>Added Member</TableCell>
            <TableCell>Deleted member id @2314</TableCell>
            <TableCell>12/09/2023, 3:15 PM</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  </Box>
);
