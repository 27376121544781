import styled from "@emotion/styled";
import { Groups } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { Alert, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { StatInfoCard } from "features/dashboard";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import { useUsersPaginated } from "../api";
import {
  CreateRoleModal,
  InviteUserModal,
  UsersLogTable,
  UsersRoleTable,
  UsersTable,
  UsersTableHeader
} from "../components";
import { useUsersStore } from "../stores";

const Wrapper = styled.div`
  padding-top: 32px;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-block: 1rem 2rem;
`;

export function Users() {
  const [tabValue, setTabValue] = useState(0);
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const openUsersInviteModal = useUsersStore(
    state => state.openUsersInviteModal
  );
  const navigate = useNavigate();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  useTitle("System Admins");

  const usersPaginatedQuery = useUsersPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10
  });

  useEffect(() => {
    setHeaderTitle(t("systemAdmins.systemAdmins.SystemAdmins"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  return (
    <Wrapper>
      <Alert message={"dashboard.common.demoMessage"} />
      <Box>
        <Notification />
        <InviteUserModal />
        <CreateRoleModal />
        <StatGrid>
          <StatInfoCard
            title={t("systemAdmins.systemAdmins.TotalUser")}
            stat={usersPaginatedQuery?.data?.result?.total || 0}
            icon={<Groups />}
            color="#82DBD8"
            background="#82DBD899"
          />
        </StatGrid>
        <Stack spacing={2}>
          {tabValue !== 2 && (
            <Button
              type="submit"
              variant="contained"
              sx={{ alignSelf: "flex-end" }}
              onClick={openUsersInviteModal}
            >
              {t("systemAdmins.systemAdmins.InviteUser")}
            </Button>
          )}
          {tabValue === 2 && (
            <Button
              type="submit"
              variant="contained"
              sx={{ alignSelf: "flex-end" }}
              onClick={() => navigate("/roles/create")}
            >
              {t("systemAdmins.systemAdmins.CreateNewRole")}
            </Button>
          )}
          <Box>
            <UsersTableHeader tabValue={tabValue} setTabValue={setTabValue} />
            {tabValue === 0 ? <UsersTable /> : null}
            {tabValue === 1 ? <UsersLogTable data={[]} /> : null}
            {tabValue === 2 ? <UsersRoleTable /> : null}
          </Box>
        </Stack>
      </Box>
    </Wrapper>
  );
}
