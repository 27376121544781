import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPermissions = ({ name }) => {
  return axios.get(`/permissions?name=${name}`);
};

export const usePermissions = ({ name, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["permissions", name],
    queryFn: () => getPermissions({ name })
  });
};
