import { Avatar, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSystemLogsPaginated } from "../api";

export const UsersLogTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const { data, isLoading } = useSystemLogsPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize
  });

  const columns = [
    {
      field: "user",
      headerName: t("systemAdmins.logs.User"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row.image_url}`}
              alt={row?.user?.first_name}
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>
              {row?.user?.first_name} {row?.user?.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "date_and_time",
      headerName: t("systemAdmins.logs.DateAndTime"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span>{moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss A")}</span>
        );
      }
    },
    {
      field: "event",
      headerName: t("systemAdmins.logs.Event"),
      flex: 1
    },
    {
      field: "description",
      headerName: t("systemAdmins.logs.Details"),
      flex: 2,
      minWidth: 280
    }
  ];

  return (
    <DataGrid
      autoHeight
      rows={data?.result?.data || []}
      rowCount={data?.result?.total || 0}
      loading={isLoading}
      columns={columns}
      pagination
      rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
      density="comfortable"
      getRowId={row => row.id}
      disableColumnMenu
      disableSelectionOnClick
      paginationMode="server"
      pageSize={pageSize}
      onPageChange={newPage => setPage(newPage)}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      localeText={{
        noRowsLabel: t("teams.teamProfile.NoRows"),
        MuiTablePagination: {
          labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
        }
      }}
    />
  );
};
