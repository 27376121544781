import { Avatar, Button, Grid, Stack, Typography } from "@mui/material";
import { Radio, Select, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { IMAGE_URL } from "config";
import { ADDRESSES } from "features/members";
import _ from "lodash";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCities, useCountries, useRegions, useZones } from "../../api";
import { useMembersStore } from "../../stores";

export const KidPersonalProfileSection = ({
  register,
  errors,
  isEditMode,
  setValue,
  watch,
  control
}) => {
  const [kidProfileImage, setKidProfileImage] = useState(null);
  const kidData = useMembersStore(state => state.kidData);
  const { t } = useTranslation();
  const countriesQuery = useCountries();
  const regionsQuery = useRegions({
    countryId: watch("birth_country_id"),
    config: {
      enabled: !!watch("birth_country_id")
    }
  });
  const zonesQuery = useZones({
    regionId: watch("birth_region_id"),
    config: {
      enabled: !!watch("birth_region_id")
    }
  });
  const citiesQuery = useCities({
    zoneId: watch("birth_zone_id"),
    config: {
      enabled: !!watch("birth_zone_id")
    }
  });

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];

    if (!_.isNil(fileUploaded)) {
      setKidProfileImage(fileUploaded);
      setValue("image_kid", fileUploaded);
    }
  };

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="body1">
            {t("addMembers.step1.PersonalProfile")}
          </Typography>
          <Avatar
            variant="rounded"
            src={
              kidProfileImage
                ? URL.createObjectURL(kidProfileImage)
                : isEditMode
                ? `${IMAGE_URL}/${kidData?.profile_picture_kid}`
                : ""
            }
            alt=""
            sx={{
              width: {
                md: 150,
                xl: 250
              },
              height: {
                md: 150,
                xl: 250
              }
            }}
          />
          <Stack alignItems="center">
            <input
              accept="image/*"
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              hidden
            />
            <Typography variant="subtitle2">
              {t("addMembers.step1.UploadPhoto")}
            </Typography>
            <Button size="large" variant="contained" onClick={handleClick}>
              {t("ui.dropzone.browse")}
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.firstName")}
            name="first_name"
            error={!!errors.first_name?.message}
            helperText={errors.first_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.fatherName")}
            name="father_name"
            error={!!errors.father_name?.message}
            helperText={errors.father_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.grandFatherName")}
            name="last_name"
            error={!!errors.last_name?.message}
            helperText={errors.last_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <Radio
            label={t("form.common.gender")}
            name="gender"
            buttons={[
              {
                radioLabel: t("form.common.male"),
                value: "male",
                id: "gender_male"
              },
              {
                radioLabel: t("form.common.female"),
                value: "female",
                id: "gender_female"
              }
            ]}
            row={true}
            register={register}
            error={!!errors.gender?.message}
            helperText={errors.gender?.message}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            isLabelBold={0}
            label={t("addMembers.step3.Nationality")}
            name="nationality_id"
            options={
              countriesQuery.data?.result?.map(country => ({
                optionLabel: country?.nationality,
                value: country?.id
              })) || []
            }
            register={register}
            error={!!errors.nationality_id?.message}
            helperText={errors.nationality_id?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {t("addMembers.step1.Birthplace")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.country")}
            name="birth_country_id"
            options={
              countriesQuery.data?.result?.map(country => ({
                optionLabel: country?.name,
                value: country?.id
              })) || []
            }
            register={register}
            error={!!errors.birth_country_id?.message}
            helperText={errors.birth_country_id?.message}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.region")}
            name={`birth_region_id`}
            options={
              regionsQuery.data?.result?.map(region => ({
                optionLabel: region?.name,
                value: region?.id
              })) || []
            }
            register={register}
            error={!!errors.birth_region_id?.message}
            helperText={errors.birth_region_id?.message}
            onChange={e => {
              setValue("birth_region_id", e.target.value);

              if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                setValue("birth_zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID);
                setValue("birth_city_id", ADDRESSES.ADDIS_ABABA.CITY_ID);
              } else {
                setValue("zone_id", "");
                setValue("city_id", "");
              }
            }}
          />
        </Grid>
        {watch("birth_region_id") !== ADDRESSES.ADDIS_ABABA.REGION_ID && (
          <>
            <Grid item xs={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.zone")}
                name={`birth_zone_id`}
                options={
                  zonesQuery.data?.result?.map(zone => ({
                    optionLabel: zone?.name,
                    value: zone?.id
                  })) || []
                }
                register={register}
                error={!!errors.birth_zone_id?.message}
                helperText={errors.birth_zone_id?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.city")}
                name={`birth_city_id`}
                options={
                  citiesQuery.data?.result?.map(city => ({
                    optionLabel: city?.name,
                    value: city?.id
                  })) || []
                }
                register={register}
                error={!!errors.birth_city_id?.message}
                helperText={errors.birth_city_id?.message}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Controller
            name="date_of_birth"
            control={control}
            render={({ field }) => (
              <DateField
                label={t("form.common.dateOfBirth")}
                name={field.name}
                error={!!errors.date_of_birth?.message}
                helperText={errors.date_of_birth?.message}
                {...field}
                disableFutureDates
              />
            )}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {t("addMembers.step1.Language")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("addMembers.step1.MotherLanguage")}
            name="mother_language"
            error={!!errors.mother_language?.message}
            helperText={errors.mother_language?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("addMembers.step1.SecondLanguage")}
            name="second_language"
            error={!!errors.second_language?.message}
            helperText={errors.second_language?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={6}>
          <Select
            label={t("addMembers.step2.SelectAcademicStatus")}
            name="academic_status"
            options={[
              {
                optionLabel: t("addMembers.step2.Illiterate"),
                value: "Unable to read or write"
              },
              { optionLabel: "Pre KG", value: "pre-kg" },
              { optionLabel: "KG", value: "kg" },
              { optionLabel: "Upper KG", value: "upper-kg" },
              { optionLabel: "1-6", value: "1-6" },
              { optionLabel: "7-10", value: "7-10" },
              { optionLabel: "11-12", value: "11-12" }
            ]}
            register={register}
            error={!!errors.academic_status?.message}
            helperText={errors.academic_status?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Status"
            name="status"
            options={[
              { optionLabel: t("kids.addKid.Kid"), value: "kid" },
              { optionLabel: t("kids.addKid.Teenage"), value: "teenage" },
              { optionLabel: t("kids.addKid.Youth"), value: "youth" }
            ]}
            register={register}
            error={!!errors.status?.message}
            helperText={errors.status?.message}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
