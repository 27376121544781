import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMessagesStore } from "../store";

export const editMessage = ({ churchId, messageId, messageData }) => {
  return axios.patch(
    `/churches/${churchId}/send-messages/${messageId}`,
    messageData
  );
};

export const useEditMessage = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeEditMessageModal =
    useMessagesStore.getState().closeEditMessageModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("messages");
      queryClient.invalidateQueries("send-messages");
      setNotification({
        type: "success",
        message: "Message updated successfully"
      });

      closeEditMessageModal();
    },
    ...config,
    mutationFn: editMessage
  });
};
