import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getBudgetYearsPaginated = ({ churchId, page, pageSize }) => {
  return axios.get(
    `/churches/${churchId}/budget-years-paginated?page=${page}&per_page=${pageSize}`
  );
};

export const useBudgetYearsPaginated = ({
  churchId,
  config,
  page,
  pageSize
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["budget-years-paginated", churchId, page, pageSize],
    queryFn: () => getBudgetYearsPaginated({ churchId, page, pageSize })
  });
};
