import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMemberGrowth } from "../api";
import { getChartLabels, getChartValues } from "../utils";
import { Toolbar } from "./Toolbar";
import { LineChart } from "./charts/LineChart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 36px;
`;

const SectionTitle = styled.h4`
  margin: 0;
`;

const Graph = styled.div`
  margin-top: 40px;
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LegendAside = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LegendBox = styled.div`
  min-width: 200px;
  max-width: 200px;
  min-height: 70px;
  border-radius: 4px;
  padding: 18px 14px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;

  h2,
  h4 {
    margin: 0;
    padding: 0;
  }
`;

const GraphFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendIndicator = styled.div`
  width: 24px;
  height: 24px;
  background: ${({ color }) => color};
`;

export const MembersSuperView = () => {
  return (
    <Container>
      <MemberGrowthStatusCard />
    </Container>
  );
};

const MemberGrowthStatusCard = () => {
  const [labels, setLabels] = useState([]);
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [resignedMembers, setResignedMembers] = useState([]);
  const [selectedQueryPeriod, setSelectedQueryPeriod] = useState(null);
  const { t } = useTranslation();

  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const memberGrowthQuery = useMemberGrowth({
    churchId: selectedChurch?.id,
    fromDate: selectedQueryPeriod?.fromDate,
    toDate: selectedQueryPeriod?.toDate,
    period: selectedQueryPeriod?.period,
    config: {
      enabled: !!selectedQueryPeriod?.toDate,
      onSuccess: response => {
        setRegisteredMembers(getChartValues(response.registered_members));
        setResignedMembers(getChartValues(response.resigned_members));
        setLabels(getChartLabels(response.resigned_members));
      }
    }
  });

  const handlePeriod = selectedPeriod => {
    setSelectedQueryPeriod(selectedPeriod);
  };

  return (
    <Section>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <SectionTitle>{t("dashboard.member.MemberGrowthStatus")}</SectionTitle>
        <Toolbar onChange={handlePeriod} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={8}>
        <Graph>
          <LineChart
            labels={labels}
            data={[
              {
                name: t("dashboard.member.MembersAdded"),
                data: registeredMembers
              },
              {
                name: t("dashboard.member.MembersResigned"),
                data: resignedMembers
              }
            ]}
          />
          <GraphFooter>
            <Legend>
              <LegendIndicator color="#FBD8B0" />
              <h5>{t("dashboard.member.MembersResigned")}</h5>
            </Legend>
            <Legend>
              <LegendIndicator color="#74DFD5" />
              <h5>{t("dashboard.member.MembersAdded")}</h5>
            </Legend>
          </GraphFooter>
        </Graph>
        <LegendAside>
          <LegendBox color="#74DFD5">
            <h4>{t("dashboard.member.MembersAdded")}</h4>
            <h2>{memberGrowthQuery.data?.total_registered_members}</h2>
          </LegendBox>
          <LegendBox color="#FBD8B0">
            <h4>{t("dashboard.member.MembersResigned")}</h4>
            <h2>{memberGrowthQuery.data?.total_resigned_members}</h2>
          </LegendBox>
        </LegendAside>
      </Stack>
    </Section>
  );
};
