import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getExpense = ({ churchId, expenseId }) => {
  return axios.get(`/churches/${churchId}/expenses/${expenseId}`);
};

export const useExpense = ({ churchId, expenseId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["expenses", churchId, expenseId],
    queryFn: () => getExpense({ churchId, expenseId })
  });
};
