import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import fileDownload from "js-file-download";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { viewExportService } from "../api";
import { PDFViewer } from "../components";
import { TABLES, TAB_NAMES, TAB_TO_TABLE_NAMES } from "../constants";
import { useMembersStore } from "../stores";
import { excludeKidColumns } from "../utils/kidColumns";
import { excludeMemberColumns } from "../utils/memberColumns";
import { excludePartnerColumns } from "../utils/partnerColumns";

const EditButton = styled(Button)`
  color: #5b5b5b;
  background: none;
  font-weight: 500;

  &:hover {
    background: none;
  }
`;

const typeMapping = {
  [TAB_NAMES.MEMBERS]: "members",
  [TAB_NAMES.APPLICATION_REQUESTS]: "pending-members",
  [TAB_NAMES.INACTIVE_MEMBERS]: "inactive-members",
  [TAB_NAMES.KIDS]: "kids",
  [TAB_NAMES.PARTNERS]: "partners"
};

export const ViewData = () => {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = Number(searchParams.get("tab")) || TAB_NAMES.MEMBERS;
  const format = searchParams.get("format") || "pdf";
  const orientation = searchParams.get("orientation") || "portrait";
  const columnsVisible = useMembersStore(state => state.columnsVisible);
  const kidColumnsVisible = useMembersStore(state => state.kidColumnsVisible);
  const partnerColumnsVisible = useMembersStore(
    state => state.partnerColumnsVisible
  );
  const queries = useMembersStore(state => state.queries);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [pdfData, setPdfData] = useState("");

  const getSelectedColumns = tab => {
    if (TAB_TO_TABLE_NAMES[tab] === TABLES.MEMBERS) {
      return Object.keys(columnsVisible).filter(
        key => !excludeMemberColumns.includes(key) && columnsVisible[key]
      );
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.KIDS) {
      return Object.keys(kidColumnsVisible).filter(
        key => !excludeKidColumns.includes(key) && kidColumnsVisible[key]
      );
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.PARTNERS) {
      return Object.keys(partnerColumnsVisible).filter(
        key =>
          !excludePartnerColumns.includes(key) && partnerColumnsVisible[key]
      );
    }
  };

  const fetchData = async () => {
    const response = await viewExportService({
      type: typeMapping[tab],
      churchId: selectedChurch?.id,
      format: format,
      orientation: orientation,
      columns: getSelectedColumns(tab),
      queries: queries
    });

    setPdfData(response.data);
  };

  useEffect(() => {
    setHeaderTitle("View export data");

    return () => setHeaderTitle("");
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownload = () => {
    fileDownload(
      pdfData,
      `${typeMapping[tab]}_${moment().format("DD-MM-YYYY-hh-mm-A")}.${format}`
    );
  };

  return (
    <>
      <Notification />
      <Stack direction="row" justifyContent="center" spacing={2}>
        <EditButton flavor="neutral" onClick={() => navigate("/members")}>
          Edit
        </EditButton>
        <Button flavor="primary" onClick={handleDownload}>
          Download
        </Button>
      </Stack>
      <PDFViewer pdfUrl={pdfData} />
    </>
  );
};
