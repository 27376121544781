import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#3dadb7",
        contrastText: "#ffffff"
      },
      secondary: {
        main: "#dff5f5",
        contrastText: "#3dadb7"
      },
      social: {
        main: "#ffffff",
        contrastText: "#000000"
      },
      neutral: {
        main: "rgba(138, 138, 138, 0.1)",
        contrastText: "#3dadb7"
      },
      success: {
        main: "#50DC6C",
        contrastText: "#ffffff"
      },
      info: {
        main: "#3186EA",
        contrastText: "#ffffff"
      },
      warning: {
        main: "#FEC022",
        contrastText: "#ffffff"
      },
      error: {
        main: "#FC5758",
        contrastText: "#ffffff"
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root.Mui-disabled": {
              backgroundColor: "#DFF5F5"
            }
          }
        }
      },

      MuiButton: {
        defaultProps: {
          disableElevation: true
        }
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },

      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: "none",
            marginTop: "1rem",

            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F9F9F9",
              borderRadius: "10px 10px 0px 0px",
              color: "#3CADB7",
              border: "none"
            },

            "& .MuiDataGrid-iconSeparator": {
              display: "none"
            },

            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#F9F9F9",
              borderRadius: "10px",
              marginTop: "1rem",
              border: "none"
            },

            "& .MuiDataGrid-cell:focus-within": {
              outline: "none"
            }
          }
        }
      },

      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              paddingBlock: "1px"
            }
          }
        }
      }
    },
    typography: {
      fontFamily: ["poppins", "Arial", "sans-serif"].join(","),
      button: {
        textTransform: "none"
      }
    }
  })
);
