import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import EtDatePicker from "mui-ethiopian-datepicker";
import { forwardRef } from "react";

const StyledBackgroundInputField = styled(EtDatePicker)(
  ({ theme, neutral, rounded }) => ({
    "& .MuiInputBase-root": {
      borderRadius: rounded ? "4rem" : "0.5rem",
      backgroundColor: `${
        neutral ? theme.palette.neutral.main : theme.palette.secondary.main
      }`
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `none`
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `none`
      }
    }
  })
);

const StyledBorderedInputField = styled(EtDatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "0.5rem",
    "& fieldset": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: `${theme.palette.primary.main}`
    },
    "&:hover fieldset": {
      borderColor: `${theme.palette.primary.main}`
    },
    "&.Mui-focused fieldset": {
      borderColor: `${theme.palette.primary.main}`
    }
  }
}));

export const DateField = forwardRef(
  (
    {
      label,
      isLabelBold,
      hasNoBg,
      isPlain,
      width = "100%",
      required,
      disablePastDates,
      disableFutureDates,
      ...rest
    },
    ref
  ) => {
    return (
      <Stack width={width}>
        {!!label && (
          <Typography
            variant="subtitle2"
            component="label"
            htmlFor={name}
            sx={{
              fontWeight: isLabelBold && 700,
              "&:after": {
                content: required ? '" *"' : '""',
                color: "red"
              }
            }}
          >
            {label}
          </Typography>
        )}
        {hasNoBg ? (
          <StyledBorderedInputField
            id={name}
            name={name}
            size="small"
            variant={isPlain && "standard"}
            inputProps={{
              "aria-label": label,
              ...(disablePastDates && {
                min: moment().format("YYYY-MM-DD")
              }),
              ...(disableFutureDates && {
                max: moment().format("YYYY-MM-DD")
              })
            }}
            {...rest}
            inputRef={ref}
          />
        ) : (
          <StyledBackgroundInputField
            id={name}
            name={name}
            size="small"
            inputProps={{
              "aria-label": label,
              ...(disablePastDates && {
                min: moment().format("YYYY-MM-DD")
              }),
              ...(disableFutureDates && {
                max: moment().format("YYYY-MM-DD")
              })
            }}
            {...rest}
            inputRef={ref}
          />
        )}
      </Stack>
    );
  }
);

DateField.displayName = "DateField";
