import styled from "@emotion/styled";
import { Grid, Stack, Typography } from "@mui/material";
import { Checkbox, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ministryPreferences from "../../data/ministryPreferences.json";

const Collapsible = styled.details`
  cursor: pointer;
`;

export const CurrentChurchEngagementInfoSection = ({
  register,
  errors,
  isEditMode,
  control
}) => {
  const { t } = useTranslation();

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step5.MinistryEngagementInformation")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={4}>
          <TextField
            label={t("addMembers.step5.YourCurrentMinistry")}
            name="current_ministry"
            error={!!errors.current_ministry?.message}
            helperText={errors.current_ministry?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="date_joined_current_ministry"
            control={control}
            render={({ field }) => (
              <DateField
                label={t("addMembers.step5.TheDateYouStartEngaging")}
                name="date_joined_current_ministry"
                error={!!errors.date_joined_current_ministry?.message}
                helperText={errors.date_joined_current_ministry?.message}
                {...field}
                disableFutureDates
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("addMembers.step5.YourRoleInTheMinistry")}
            name="role_in_current_ministry"
            error={!!errors.role_in_current_ministry?.message}
            helperText={errors.role_in_current_ministry?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <span>{t("addMembers.step5.GiftsAndTalents")}</span>
            <TextField
              label=""
              hasNoBg={true}
              isPlain={true}
              name="gifts_and_talents"
              register={register}
              error={!!errors.gifts_and_talents?.message}
              helperText={errors.gifts_and_talents?.message}
            />
          </Stack>
        </Grid>
        <Grid item xs={8} />
      </Grid>
      <Grid item xs={3} my={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step5.MinistryPreferences")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2} my={3}>
        <Grid item xs={12}>
          {ministryPreferences.map(ministryPreference => (
            <Collapsible key={ministryPreference.name} open={isEditMode}>
              <summary>{t(ministryPreference.name)}</summary>
              <Checkbox
                name="ministry_preference"
                buttons={ministryPreference.options.map(option => ({
                  id: option.name,
                  checkboxLabel: t(option.name),
                  value: option.value
                }))}
                cols={2}
                register={register}
                error={!!errors.ministry_preference?.message}
                helperText={errors.ministry_preference?.message}
              />
            </Collapsible>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
