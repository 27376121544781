import ReactApexChart from "react-apexcharts";

export const PieChart = ({
  labels,
  data,
  colors = ["#FBD8B0", "#74DFD5"],
  showLegend = true,
  height = "auto"
}) => {
  return (
    <ReactApexChart
      options={{
        colors: colors,
        chart: {
          type: "pie"
        },
        labels: labels,
        legend: {
          show: showLegend,
          position: "left"
        },
        yaxis: {
          labels: {
            formatter: value => value.toFixed(0)
          }
        }
      }}
      series={data}
      type="pie"
      height={height}
    />
  );
};
