import {
  CheckEmail,
  CreateAccount,
  ForgotPassword,
  NewPassword,
  SignIn,
  Verification
} from "features/auth";
import { CreateUserAccount } from "features/users";
import { AuthLayout } from "layouts";
import { Navigate } from "react-router-dom";

export const publicRoutes = [
  {
    path: "/auth/*",
    element: <AuthLayout />,
    children: [
      { path: "check-email", element: <CheckEmail /> },
      { path: "create-account", element: <CreateAccount /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password", element: <NewPassword /> },
      { path: "sign-in", element: <SignIn /> },
      { path: "verification", element: <Verification /> },
      { path: "*", element: <Navigate to="/" /> }
    ]
  },
  {
    path: "/users/*",
    element: <AuthLayout />,
    children: [
      { path: "create", element: <CreateUserAccount /> },
      { path: "*", element: <Navigate to="/" /> }
    ]
  }
];
