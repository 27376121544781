import { matchIsValidTel } from "mui-tel-input";
import * as yup from "yup";

export const createChurchSchema = yup.object().shape(
  {
    name: yup.string().required("Church name is required").trim(),
    email: yup.string().notRequired().email("Church email is not valid"),
    phone: yup.string().when("phone", {
      is: phone => !!phone,
      then: yup
        .string()
        .test("isPhoneValid", "Phone is not valid", value =>
          matchIsValidTel(value)
        ),
      otherwise: yup.string().notRequired()
    }),
    image: yup
      .mixed()
      .test(
        "required",
        "Church Logo is required",
        value => value && value.length
      )
      .test("imageSize", "Image file size must be less than 1 MB", value => {
        if (!value) return true;

        const imageSize = value[0].size / Math.pow(1024, 2);

        return imageSize <= 1;
      }),
    p_o_box: yup.string().when("p_o_box", {
      is: pobox => !!pobox,
      then: yup
        .string()
        .matches(/^[0-9]+$/, "Church pobox can't contain characters"),
      otherwise: yup.string().notRequired()
    }),
    facebook_link: yup.string().when("facebook_link", {
      is: facebookLink => !!facebookLink,
      then: yup.string(),
      otherwise: yup.string().notRequired()
    }),
    instagram_link: yup.string().when("instagram_link", {
      is: instagramLink => !!instagramLink,
      then: yup.string(),
      otherwise: yup.string().notRequired()
    }),
    youtube_link: yup.string().when("youtube_link", {
      is: youtubeLink => !!youtubeLink,
      then: yup.string(),
      otherwise: yup.string().notRequired()
    }),
    website: yup.string().when("website", {
      is: websiteLink => !!websiteLink,
      then: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter a correct link"
        ),
      otherwise: yup.string().notRequired()
    }),
    country_id: yup.string().notRequired(),
    region_id: yup.string().notRequired(),
    zone_id: yup.string().notRequired(),
    city_id: yup.string().notRequired(),
    sub_city_id: yup.string().notRequired(),
    woreda_id: yup.string().notRequired(),
    kebele: yup.string().notRequired(),
    house_number: yup.string().notRequired(),
    specific_location: yup.string().notRequired(),
    description: yup.string().notRequired(),
    leader_first_name: yup.string().notRequired().trim(),
    leader_last_name: yup.string().notRequired().trim(),
    leader_email: yup.string().notRequired().trim().email("Email is not valid"),
    leader_phone: yup.string().when("leader_phone", {
      is: leader_phone => !!leader_phone,
      then: yup
        .string()
        .test("isPhoneValid", "Phone is not valid", value =>
          matchIsValidTel(value)
        ),
      otherwise: yup.string().notRequired()
    }),
    leader_image: yup
      .mixed()
      .notRequired()
      .test("imageSize", "Image file size must be less than 1 MB", value => {
        if (!value) return true;

        const imageSize = value.size / Math.pow(1024, 2);

        return imageSize <= 1;
      }),
    acceptTerms: yup.boolean().oneOf([true], "Terms and conditions is required")
  },
  [
    ["p_o_box", "p_o_box"],
    ["facebook_link", "facebook_link"],
    ["instagram_link", "instagram_link"],
    ["youtube_link", "youtube_link"],
    ["website", "website"],
    ["phone", "phone"],
    ["leader_phone", "leader_phone"]
  ]
);

export const updateChurchSchema = yup.object().shape(
  {
    name: yup.string().required("Church name is required"),
    email: yup
      .string()
      .notRequired()
      .email("Church email is not valid")
      .nullable(true),
    phone: yup.string().when("phone", {
      is: phone => !!phone,
      then: yup
        .string()
        .test("isPhoneValid", "Phone is not valid", value =>
          matchIsValidTel(value)
        ),
      otherwise: yup.string().notRequired()
    }),
    website: yup.string().when("website", {
      is: websiteLink => !!websiteLink,
      then: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter a correct link"
        ),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    p_o_box: yup.string().when("p_o_box", {
      is: pobox => !!pobox,
      then: yup
        .string()
        .matches(/^[0-9]+$/, "Church pobox can't contain characters"),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    denomination_id: yup.string().notRequired().nullable(true),
    country_id: yup.string().notRequired().nullable(true),
    region_id: yup.string().notRequired().nullable(true),
    zone_id: yup.string().notRequired().nullable(true),
    city_id: yup.string().notRequired().nullable(true),
    sub_city_id: yup.string().notRequired().nullable(true),
    woreda_id: yup.string().notRequired().nullable(true),
    kebele: yup.string().notRequired().nullable(true),
    house_number: yup.string().notRequired().nullable(true),
    specific_location: yup.string().notRequired().nullable(true),
    facebook_link: yup.string().when("facebook_link", {
      is: facebookLink => !!facebookLink,
      then: yup.string(),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    instagram_link: yup.string().when("instagram_link", {
      is: instagramLink => !!instagramLink,
      then: yup.string(),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    youtube_link: yup.string().when("youtube_link", {
      is: youtubeLink => !!youtubeLink,
      then: yup.string(),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    description: yup.string().notRequired().nullable(true),
    leader_first_name: yup.string().notRequired().nullable(true),
    leader_last_name: yup.string().notRequired().nullable(true),
    leader_email: yup
      .string()
      .notRequired()
      .email("Email is not valid")
      .nullable(true),
    leader_phone: yup.string().when("leader_phone", {
      is: leader_phone => !!leader_phone,
      then: yup
        .string()
        .test("isPhoneValid", "Phone is not valid", value =>
          matchIsValidTel(value)
        ),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    leader_image: yup
      .mixed()
      .notRequired()
      .test("imageSize", "Image file size must be less than 1 MB", value => {
        if (!value) return true;

        const imageSize = value.size / Math.pow(1024, 2);

        return imageSize <= 1;
      })
  },
  [
    ["p_o_box", "p_o_box"],
    ["facebook_link", "facebook_link"],
    ["instagram_link", "instagram_link"],
    ["youtube_link", "youtube_link"],
    ["website", "website"],
    ["leader_phone", "leader_phone"],
    ["phone", "phone"]
  ]
);
