import { Add, Remove } from "@mui/icons-material";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { Radio, Select, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import {
  ADDRESSES,
  useMembersStore,
  usePublicCities,
  usePublicCountries,
  usePublicRegions,
  usePublicZones
} from "features/members";
import { Fragment, useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const ChildInfoSection = ({
  register,
  errors,
  watch,
  control,
  setValue
}) => {
  const { t } = useTranslation();
  const countriesQuery = usePublicCountries();
  const memberData = useMembersStore(state => state.memberData);

  const newChildField = {
    first_name: "",
    father_name: "",
    last_name: "",
    date_of_birth: "",
    nationality_id: "",
    gender: "",
    relation_type: "",
    mother_name: "",
    mother_father_name: "",
    mother_last_name: "",
    mother_country: "",
    mother_region: "",
    mother_city: "",
    is_believer: "1",
    is_member: "0",
    member_id: "",
    birth_country_id: "66",
    birth_region_id: "",
    birth_zone_id: "",
    birth_city_id: "",
    is_kid: "0"
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "children"
  });

  return (
    <Grid
      direction={{
        xs: "column",
        md: "row"
      }}
      container
      columnSpacing={12}
    >
      <Grid item xs={3} />
      <Grid container item xs={9} spacing={2} columns={{ xs: 2, md: 12 }}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <Grid item xs={2} md={4}>
              <Radio
                label={t("addMembers.step3.IsChildBeliever")}
                name={`children.${index}.is_believer`}
                buttons={[
                  {
                    radioLabel: t("form.common.yes"),
                    value: "1",
                    id: "child_believer_yes"
                  },
                  {
                    radioLabel: t("form.common.no"),
                    value: "0",
                    id: "child_believer_no"
                  }
                ]}
                row={true}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={8}>
              {watch(`children.${index}.is_believer`) === "0" && (
                <TextField
                  label={t("addMembers.step3.ChildReligion")}
                  name={`children.${index}.religion`}
                  error={!!errors.children?.[index]?.religion?.message}
                  helperText={errors.children?.[index]?.religion?.message}
                  register={register}
                />
              )}
            </Grid>
            <Grid item xs={2} md={12}>
              {watch(`children.${index}.is_believer`) === "1" && (
                <Radio
                  label={t("addMembers.step3.IsChildAMember")}
                  name={`children.${index}.is_member`}
                  buttons={[
                    {
                      radioLabel: t("form.common.yes"),
                      value: "1",
                      id: "child_member_yes"
                    },
                    {
                      radioLabel: t("form.common.no"),
                      value: "0",
                      id: "child_member_no"
                    }
                  ]}
                  row={true}
                  register={register}
                />
              )}
            </Grid>
            <Grid item xs={2} md={6}>
              {watch(`children.${index}.is_believer`) === "1" &&
                watch(`children.${index}.is_member`) === "0" && (
                  <Radio
                    label={t("addMembers.step3.IsKid")}
                    name={`children.${index}.is_kid`}
                    buttons={[
                      {
                        radioLabel: t("form.common.yes"),
                        value: "1",
                        id: "child_kid_yes"
                      },
                      {
                        radioLabel: t("form.common.no"),
                        value: "0",
                        id: "child_kid_no"
                      }
                    ]}
                    row={true}
                    register={register}
                  />
                )}
            </Grid>
            <Grid item xs={6}>
              {watch(`children.${index}.is_believer`) === "1" &&
                watch(`children.${index}.is_member`) === "0" &&
                watch(`children.${index}.is_kid`) === "0" && (
                  <TextField
                    label={t("addMembers.step3.ChildChurchName")}
                    name={`children.${index}.church_name`}
                    error={!!errors.children?.[index]?.church_name?.message}
                    helperText={errors.children?.[index]?.church_name?.message}
                    register={register}
                  />
                )}
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("form.common.firstName")}
                name={`children.${index}.first_name`}
                error={!!errors.children?.[index]?.first_name?.message}
                helperText={errors.children?.[index]?.first_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("form.common.fatherName")}
                name={`children.${index}.father_name`}
                error={!!errors.children?.[index]?.father_name?.message}
                helperText={errors.children?.[index]?.father_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("form.common.grandFatherName")}
                name={`children.${index}.last_name`}
                error={!!errors.children?.[index]?.last_name?.message}
                helperText={errors.children?.[index]?.last_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={3}>
              <Controller
                name={`children.${index}.date_of_birth`}
                control={control}
                render={({ field }) => (
                  <DateField
                    label={t("form.common.dateOfBirth")}
                    name={`children.${index}.date_of_birth`}
                    error={!!errors.children?.[index]?.date_of_birth?.message}
                    helperText={
                      errors.children?.[index]?.date_of_birth?.message
                    }
                    {...field}
                    disableFutureDates
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} md={3}>
              <Select
                isLabelBold={0}
                label={t("addMembers.step3.Nationality")}
                name={`children.${index}.nationality_id`}
                options={
                  countriesQuery.data?.result?.map(country => ({
                    optionLabel: country?.nationality,
                    value: country?.id
                  })) || []
                }
                register={register}
                error={!!errors.children?.[index]?.nationality_id?.message}
                helperText={errors.children?.[index]?.nationality_id?.message}
              />
            </Grid>
            <Grid item xs={2} md={3}>
              <Radio
                label={t("form.common.gender")}
                name={`children.${index}.gender`}
                buttons={[
                  {
                    radioLabel: t("form.common.male"),
                    value: "male",
                    id: "child_male"
                  },
                  {
                    radioLabel: t("form.common.female"),
                    value: "female",
                    id: "child_female"
                  }
                ]}
                row={true}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={3}>
              <Select
                label={t("addMembers.step3.RelationshipWithChild")}
                name={`children.${index}.relation_type`}
                options={[
                  { optionLabel: "Father", value: "father" },
                  { optionLabel: "Mother", value: "mother" },
                  { optionLabel: "Guardian", value: "guardian" }
                ]}
                register={register}
                error={!!errors.children?.[index]?.relation_type?.message}
                helperText={errors.children?.[index]?.relation_type?.message}
                onChange={event => {
                  const selectedValue = event.target.value;

                  if (selectedValue === "mother") {
                    setValue(
                      `children.${index}.mother_name`,
                      memberData.first_name ?? ""
                    );

                    setValue(
                      `children.${index}.mother_father_name`,
                      memberData.father_name ?? ""
                    );

                    setValue(
                      `children.${index}.mother_last_name`,
                      memberData.last_name ?? ""
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={2} md={12}>
              <Typography variant="subtitle2">
                {t("addMembers.step1.MotherName")}
              </Typography>
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("form.common.firstName")}
                name={`children.${index}.mother_name`}
                error={!!errors.children?.[index]?.mother_name?.message}
                helperText={errors.children?.[index]?.mother_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("form.common.fatherName")}
                name={`children.${index}.mother_father_name`}
                error={!!errors.children?.[index]?.mother_father_name?.message}
                helperText={
                  errors.children?.[index]?.mother_father_name?.message
                }
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("form.common.grandFatherName")}
                name={`children.${index}.mother_last_name`}
                error={!!errors.children?.[index]?.mother_last_name?.message}
                helperText={errors.children?.[index]?.mother_last_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={12}>
              <Typography variant="subtitle2">
                {t("addMembers.step1.Birthplace")}
              </Typography>
            </Grid>
            <BirthPlaceAddressing
              t={t}
              index={index}
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
            <Grid item xs={2} md={12}>
              <Stack alignItems="flex-end">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  onClick={() => remove(index)}
                >
                  <Avatar
                    sx={{
                      backgroundColor: "secondary.main",
                      width: "48px",
                      height: "48px",
                      cursor: "pointer"
                    }}
                  >
                    <Remove color="primary" />
                  </Avatar>
                  <Typography variant="body1">
                    {t("addMembers.step3.RemoveChild")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={2} md={12}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={1}
            onClick={() => append(newChildField)}
          >
            <Avatar
              sx={{
                backgroundColor: "primary.main",
                width: "48px",
                height: "48px",
                cursor: "pointer"
              }}
            >
              <Add color="white" />
            </Avatar>
            <Typography variant="body1">
              {t("addMembers.step3.AddChild")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BirthPlaceAddressing = ({
  index,
  t,
  watch,
  register,
  errors,
  setValue
}) => {
  const countriesQuery = usePublicCountries();
  const regionsQuery = usePublicRegions({
    countryId: watch(`children.${index}.birth_country_id`),
    config: {
      enabled: !!watch(`children.${index}.birth_country_id`)
    }
  });
  const zonesQuery = usePublicZones({
    regionId: watch(`children.${index}.birth_region_id`),
    config: {
      enabled: !!watch(`children.${index}.birth_region_id`)
    }
  });
  const citiesQuery = usePublicCities({
    zoneId: watch(`children.${index}.birth_zone_id`),
    config: {
      enabled: !!watch(`children.${index}.birth_zone_id`)
    }
  });

  useEffect(() => {
    if (countriesQuery.data) {
      setValue(`children.${index}.birth_country_id`, "66");
    }
  }, [countriesQuery.data]);

  return (
    <>
      <Grid item xs={2} md={3}>
        <Select
          isLabelBold={0}
          label={t("form.common.country")}
          name={`children.${index}.birth_country_id`}
          options={
            countriesQuery.data?.result?.map(country => ({
              optionLabel: country?.name,
              value: country?.id
            })) || []
          }
          register={register}
          error={!!errors.children?.[index]?.birth_country_id?.message}
          helperText={errors.children?.[index]?.birth_country_id?.message}
        />
      </Grid>
      <Grid item xs={2} md={3}>
        <Select
          isLabelBold={0}
          label={t("form.common.region")}
          name={`children.${index}.birth_region_id`}
          options={
            regionsQuery.data?.result?.map(region => ({
              optionLabel: region?.name,
              value: region?.id
            })) || []
          }
          register={register}
          error={!!errors.children?.[index]?.birth_region_id?.message}
          helperText={errors.children?.[index]?.birth_region_id?.message}
          onChange={e => {
            setValue(`children.${index}.birth_region_id`, e.target.value, {
              shouldValidate: true
            });

            if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
              setValue(
                `children.${index}.birth_zone_id`,
                ADDRESSES.ADDIS_ABABA.ZONE_ID,
                {
                  shouldValidate: true
                }
              );
              setValue(
                `children.${index}.birth_city_id`,
                ADDRESSES.ADDIS_ABABA.CITY_ID,
                {
                  shouldValidate: true
                }
              );
            } else {
              setValue(`children.${index}.birth_zone_id`, "", {
                shouldValidate: true
              });
              setValue(`children.${index}.birth_city_id`, "", {
                shouldValidate: true
              });
            }
          }}
        />
      </Grid>
      {watch(`children.${index}.birth_region_id`) !==
        ADDRESSES.ADDIS_ABABA.REGION_ID && (
        <>
          <Grid item xs={2} md={3}>
            <Select
              isLabelBold={0}
              label={t("form.common.zone")}
              name={`children.${index}.birth_zone_id`}
              options={
                zonesQuery.data?.result?.map(zone => ({
                  optionLabel: zone?.name,
                  value: zone?.id
                })) || []
              }
              register={register}
              error={!!errors.birth_zone_id?.message}
              helperText={errors.birth_zone_id?.message}
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <Select
              isLabelBold={0}
              label={t("form.common.city")}
              name={`children.${index}.birth_city_id`}
              options={
                citiesQuery.data?.result?.map(city => ({
                  optionLabel: city?.name,
                  value: city?.id
                })) || []
              }
              register={register}
              error={!!errors.birth_city_id?.message}
              helperText={errors.birth_city_id?.message}
            />
          </Grid>
        </>
      )}
    </>
  );
};
