import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const updateKid = ({ churchId, kidData, kidId }) => {
  return axios.post(
    `/churches/${churchId}/children/${kidId}?_method=PATCH`,
    kidData
  );
};

export const useUpdateKid = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const setKidData = useMembersStore.getState().setKidData;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: data => {
      setKidData(null);
      queryClient.invalidateQueries(["kid", `${data.result.id}`]);
      queryClient.invalidateQueries(["kids"]);
      setNotification({
        type: "success",
        message: "Kid updated successfully!"
      });
      navigate("/members");
    },
    ...config,
    mutationFn: updateKid
  });
};
