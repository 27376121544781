import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const deleteEmployee = ({ churchId, employeeId }) => {
  return axios.delete(`/churches/${churchId}/employees/${employeeId}`);
};

export const useDeleteEmployee = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["employees"]);

      setNotification({
        type: "success",
        message: "Employee deleted successfully!"
      });
    },
    ...config,
    mutationFn: deleteEmployee
  });
};
