import { Box, Modal, Stack, Typography } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLinkSpouse } from "../api";
import { useMembersStore } from "../stores";

export function SpouseMatchModal() {
  const { t } = useTranslation();
  const spouseMatchedModalOpen = useMembersStore(
    state => state.spouseMatchedModalOpen
  );
  const closeSpouseMatchedModal = useMembersStore(
    state => state.closeSpouseMatchedModal
  );
  const matchedSpouseData = useMembersStore(state => state.matchedSpouseData);
  const setMatchedSpouseData = useMembersStore(
    state => state.setMatchedSpouseData
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { id } = useParams();

  const closeModal = () => {
    setMatchedSpouseData(null);
    closeSpouseMatchedModal();
  };

  const linkSpouseMutation = useLinkSpouse();

  return (
    <Modal open={spouseMatchedModalOpen} onClose={closeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          Matched Spouse
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={3}
          component="form"
        >
          <Typography>
            Name:{" "}
            {`${matchedSpouseData?.first_name} ${matchedSpouseData?.father_name} ${matchedSpouseData?.last_name}`}
          </Typography>
          <Typography>Gender: {matchedSpouseData?.gender}</Typography>
          <Typography>Phone number: {matchedSpouseData?.phone}</Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              flavor="secondary"
              onClick={closeModal}
            >
              {t("form.common.cancel")}
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                linkSpouseMutation.mutate({
                  churchId: selectedChurch?.id,
                  memberId: id,
                  spouseId: matchedSpouseData?.id
                });
              }}
            >
              Approve
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
