import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const paymentRequisition = ({
  churchId,
  requisitionData,
  requisitionId
}) => {
  return axios.patch(
    `/churches/${churchId}/requisitions/${requisitionId}/payment`,
    requisitionData
  );
};

export const usePaymentRequisition = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateRequisitionModal =
    useFinanceSettingsStore.getState().closeUpdateRequisitionModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["requisitions"]);
      queryClient.invalidateQueries(["requisitions-paginated"]);
      setNotification({
        type: "success",
        message: "Requisition paid successfully!"
      });
      closeUpdateRequisitionModal();
    },
    ...config,
    mutationFn: paymentRequisition
  });
};
