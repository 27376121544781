import styled from "@emotion/styled";
import { AttachFile, Inbox, MoreVert, Send } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { Select } from "components/UI";
import { useState } from "react";
import { useMessagesStore } from "../store";
import { GroupMessageStatusModal } from "./GroupMessageStatusModal";
import { SendOptions } from "./SendOptions";

const Container = styled.div`
  width: 100%;
  background: #f3f3f3;
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-height: 913px;
`;

const Header = styled.div`
  padding: 13px 31px;
  background: ${({ theme }) => theme.palette.primary.main};
  color: white;

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
`;

const Compose = styled.div`
  background: #e7e7e7;
  display: flex;
  padding: 17px 29px;
  min-height: 110px;
  align-items: center;
  gap: 10px;
`;

const MessageInput = styled.textarea`
  flex: 1;
  resize: none;
  min-height: 46px;
  border: none;
  outline: none;
  background: inherit;
  font: inherit;
  align-self: stretch;
`;

const Messages = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  overflow-y: auto;
`;

const Message = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
`;

const MessageBubble = styled.div`
  width: 332px;
  font-size: 12px;
  line-height: 18px;
  padding: 15px 17px;
  background: white;
  border-radius: 10px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

const Time = styled.span`
  align-self: flex-end;
  font-weight: 500;
`;

const LinkAction = styled.button`
  all: unset;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  padding: 4px;
`;

const messages = [
  {
    id: 53,
    name: "Helen Tamru Maru",
    image: "https://source.unsplash.com/random",
    text: "Lorem ipsum dolor sit amet,",
    type: "Text Message",
    port: "SMS",
    status: "Scheduled",
    timestamp: "1/1/23, 9:00 PM",
    recipient_type: "individual"
  },
  {
    id: 13,
    name: "Lemma Tamru Maru",
    image: "https://source.unsplash.com/random",
    text: "Lorem ipsum dolor sit amet,",
    type: "Text Message",
    port: "SMS",
    status: "Failed",
    timestamp: "1/1/23, 9:00 PM",
    recipient_type: "group"
  }
];

export const RecentMessages = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const recipients = useMessagesStore(state => state.recipients);
  const setMessage = useMessagesStore(state => state.setMessage);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const openGroupMessageStatusModal = useMessagesStore(
    state => state.openGroupMessageStatusModal
  );

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <Container>
      <GroupMessageStatusModal />
      <Header>
        <Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <h3>Select Port</h3>
            <Select
              defaultValue="sms"
              options={[
                { optionLabel: "SMS", value: "sms" },
                { optionLabel: "WhatsApp", value: "whatsapp" },
                { optionLabel: "Telegram", value: "telegram" }
              ]}
              register={() => {}}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <h3>To: </h3>
            {!!recipients.size && (
              <AvatarGroup
                max={5}
                sx={{
                  "& .MuiAvatarGroup-avatar": {
                    width: 24,
                    height: 24,
                    fontSize: 12
                  }
                }}
              >
                {[...recipients.values()].map(selectedItem => (
                  <Avatar
                    key={selectedItem.id}
                    src={selectedItem.image}
                    alt={selectedItem.name}
                    sx={{
                      width: 24,
                      height: 24
                    }}
                  />
                ))}
              </AvatarGroup>
            )}
          </Stack>
        </Stack>
      </Header>
      <Messages>
        {messages.map(message => (
          <Message key={message.id}>
            <Avatar>N</Avatar>
            <Stack alignItems="center" spacing={1}>
              <Stack alignItems="center" sx={{ maxWidth: "332px" }}>
                {message.status === "Scheduled" && (
                  <Typography variant="caption">
                    Scheduled for December 27
                  </Typography>
                )}
                {message.recipient_type === "group" && (
                  <>
                    <Typography variant="caption">Mass Text</Typography>
                    <Typography variant="caption">
                      Each recipient will get an sms message from you replies
                      will appear in individual conversations
                    </Typography>
                  </>
                )}
              </Stack>
              <MessageBubble
                onClick={
                  message.recipient_type === "group"
                    ? openGroupMessageStatusModal
                    : null
                }
              >
                {message.status === "Scheduled" && (
                  <IconButton
                    aria-label="settings"
                    sx={{ position: "absolute", top: 0, right: 2 }}
                    color="primary"
                    onClick={handleClick2}
                  >
                    <MoreVert />
                  </IconButton>
                )}
                <p>
                  Lorem ipsum dolor sit amet consectetur. Fermentum pharetra
                  massa cras sit purus euismod purus dictum vitae. Platea
                  tincidunt libero amet vitae aenean integer. Vehicula ac quis
                  sodales ultricies et. Et quam molestie donec maecenas.
                </p>
                <Time>9:51 AM</Time>
              </MessageBubble>
            </Stack>
          </Message>
        ))}
      </Messages>
      <Compose>
        <MessageInput
          onChange={e =>
            setMessage({
              id: Math.floor(Math.random() * 100),
              status: "sent",
              message: e.target.value
            })
          }
        />
        <IconButton color="primary" onClick={handleClick}>
          <Send />
        </IconButton>
        <IconButton color="primary">
          <AttachFile />
        </IconButton>
        <IconButton color="primary">
          <Inbox />
        </IconButton>
      </Compose>
      <Popover
        anchorEl={anchorEl}
        id="send-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            borderRadius: "10px",
            backgroundColor: "primary.main"
          }
        }}
      >
        <SendOptions />
      </Popover>
      <Popover
        anchorEl={anchorEl2}
        id="account-menu"
        open={open2}
        onClose={handleClose2}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <ScheduledDropdownMenu />
      </Popover>
    </Container>
  );
};

const ScheduledDropdownMenu = () => {
  return (
    <Stack
      sx={{
        backgroundColor: "white",
        px: "17px",
        py: "11px"
      }}
      spacing={1}
      alignItems="flex-start"
    >
      <LinkAction>Edit</LinkAction>
      <LinkAction>Reschedule</LinkAction>
      <LinkAction>Send now</LinkAction>
    </Stack>
  );
};
