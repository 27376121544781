import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPublicZones = ({ regionId }) => {
  return axios.get(`/public/addresses/regions/${regionId}/zones`);
};

export const usePublicZones = ({ regionId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["public-zones", regionId],
    queryFn: () => getPublicZones({ regionId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
