import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const createMember = ({ churchId, memberData }) => {
  return axios.post(`/churches/${churchId}/members`, memberData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const useCreateMember = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const setProfileImage = useMembersStore.getState().setProfileImage;
  const setIdImage = useMembersStore.getState().setIdImage;
  const resetAddMemberForm = useMembersStore.getState().resetAddMemberForm;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("members");
      setNotification({
        type: "success",
        message:
          "Member created successfully! The church ministers will review the application and will approve it shortly."
      });
      setProfileImage(null);
      setIdImage(null);
      resetAddMemberForm();
      navigate("/members?t=requests");
    },
    ...config,
    mutationFn: createMember
  });
};
