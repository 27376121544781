import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import { Avatar, InputAdornment } from "@mui/material";
import { Checkbox, TextField } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import {
  FILTER_OPERATIONS,
  MEMBER_STATES,
  useMembers,
  usePartners
} from "features/members";
import { useTeams } from "features/teams";
import Fuse from "fuse.js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsInfoCircle, BsX } from "react-icons/bs";
import { useMessagesStore } from "../store";

const Container = styled.div`
  height: 100%;
  max-height: 913px;
  width: 420px;
  background: white;
  padding: 22px 44px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Tabs = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 32px;
`;

const Tab = styled.li`
  padding: 10px;
  background: ${({ active, theme }) => active && theme.palette.primary.main};
  color: ${({ active }) => (active ? "white" : "#ABABAB")};
  border-radius: 5px;
  margin-bottom: 9px;
  cursor: pointer;
`;

const Alert = styled.div`
  padding: 11px 19px;
  background-color: ${({ bg }) => bg};
  border: 1.5px solid ${({ borderColor }) => borderColor};
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  max-width: 750px;
  font-size: 10px;
  line-height: 15px;

  .startIcon {
    min-height: 24px;
    min-width: 24px;
    background-color: ${({ iconBg }) => iconBg};
    display: grid;
    place-items: center;
    font-size: 14px;
    border-radius: 100%;
    color: #ffffff;
  }

  .endIcon {
    min-height: 24px;
    min-width: 24px;
    background-color: #ffffff;
    display: grid;
    place-items: center;
    border-radius: 100%;
    margin-left: auto;
    cursor: pointer;
  }
`;

const Contacts = styled(Tabs)`
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

const Contact = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const tabs = [
  { name: "message.newMessage.Members", value: "members" },
  { name: "message.newMessage.Teams", value: "teams" },
  { name: "message.newMessage.Partners", value: "partners" }
];

const translationMapping = {
  members: "message.newMessage.SearchMembers",
  teams: "message.newMessage.SearchTeams",
  partners: "message.newMessage.SearchPartners"
};

export const MessageControls = () => {
  const [activeTab, setActiveTab] = useState("members");
  const recipients = useMessagesStore(state => state.recipients);
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const removeRecipient = useMessagesStore(state => state.removeRecipient);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const addTeam = useMessagesStore(state => state.addTeam);
  const removeTeam = useMessagesStore(state => state.removeTeam);
  const addPartner = useMessagesStore(state => state.addPartner);
  const removePartner = useMessagesStore(state => state.removePartner);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const membersQuery = useMembers({
    churchId: selectedChurch?.id,
    queries: {
      filter: {
        status: [
          {
            operator: FILTER_OPERATIONS.EQUAL,
            value: MEMBER_STATES.ACTIVE
          }
        ]
      }
    }
  });

  const teamsQuery = useTeams({
    churchId: selectedChurch?.id
  });

  const partnersQuery = usePartners({
    churchId: selectedChurch?.id
  });

  const [members, setMembers] = useState([]);
  const [originalMembersList, setOriginalMembersList] = useState([]);

  const [teams, setTeams] = useState([]);
  const [originalTeamsList, setOriginalTeamsList] = useState([]);

  const [partners, setPartners] = useState([]);
  const [originalPartnersList, setOriginalPartnersList] = useState([]);

  useEffect(() => {
    if (membersQuery.data) {
      setMembers(membersQuery.data.result);
      setOriginalMembersList(membersQuery.data.result);
    }
  }, [membersQuery.data]);

  useEffect(() => {
    if (teamsQuery.data) {
      setTeams(teamsQuery.data.result);
      setOriginalTeamsList(teamsQuery.data.result);
    }
  }, [teamsQuery.data]);

  useEffect(() => {
    if (partnersQuery.data) {
      setPartners(partnersQuery.data.result);
      setOriginalPartnersList(partnersQuery.data.result);
    }
  }, [partnersQuery.data]);

  const search = type => {
    if (type === "members") {
      const fuse = new Fuse(originalMembersList, {
        threshold: 0.3,
        keys: ["first_name"]
      });

      if (_.isEmpty(searchTerm)) {
        setMembers(originalMembersList);
        return;
      }

      const searchResults = fuse.search(searchTerm);

      if (_.isEmpty(searchResults)) {
        setMembers([]);
      } else {
        setMembers([
          ..._.map(fuse.search(searchTerm), foundTerm => foundTerm.item)
        ]);
      }
    }

    if (type === "teams") {
      const fuse = new Fuse(originalTeamsList, {
        threshold: 0.3,
        keys: ["name"]
      });

      if (_.isEmpty(searchTerm)) {
        setTeams(originalTeamsList);
        return;
      }

      const searchResults = fuse.search(searchTerm);

      if (_.isEmpty(searchResults)) {
        setTeams([]);
      } else {
        setTeams([
          ..._.map(fuse.search(searchTerm), foundTerm => foundTerm.item)
        ]);
      }
    }

    if (type === "partners") {
      const fuse = new Fuse(originalPartnersList, {
        threshold: 0.3,
        keys: ["first_name"]
      });

      if (_.isEmpty(searchTerm)) {
        setPartners(originalPartnersList);
        return;
      }

      const searchResults = fuse.search(searchTerm);

      if (_.isEmpty(searchResults)) {
        setPartners([]);
      } else {
        setPartners([
          ..._.map(fuse.search(searchTerm), foundTerm => foundTerm.item)
        ]);
      }
    }
  };

  return (
    <Container>
      <Tabs>
        {tabs.map(tabItem => (
          <Tab
            key={tabItem.name}
            active={tabItem.value === activeTab}
            onClick={() => setActiveTab(tabItem.value)}
          >
            {t(tabItem.name)}
          </Tab>
        ))}
      </Tabs>
      <TextField
        neutral={1}
        placeholder={t(`${translationMapping[activeTab]}`)}
        register={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
        onChange={e => setSearchTerm(e.target.value)}
        onKeyUp={() => search(activeTab)}
      />
      {_.isEmpty(recipients) && (
        <Alert iconBg="#3186EA" bg="#D8E6F9" borderColor="#B4CDEF">
          <span className="startIcon">
            <BsInfoCircle />
          </span>
          {t("message.newMessage.FirstSelect")}
          <span className="endIcon">
            <BsX />
          </span>
        </Alert>
      )}
      <Contacts>
        {activeTab === "members" &&
          members.map(member => (
            <Contact key={member.id}>
              <Checkbox
                name={member.id}
                buttons={[
                  {
                    checkboxLabel: "",
                    value: true,
                    id: member.id,
                    checked: recipients.has(
                      `${member.id}_${member.first_name}_${member.last_name}`
                    ),
                    onChange: e => {
                      if (e.target.checked) {
                        addRecipient(
                          `${member.id}_${member.first_name}_${member.last_name}`,
                          member
                        );
                        addMember(member.id, { id: member.id });
                      } else {
                        removeRecipient(
                          `${member.id}_${member.first_name}_${member.last_name}`
                        );
                        removeMember(member.id);
                      }
                    }
                  }
                ]}
                error=""
                helperText=""
                register={() => {}}
              />
              <Avatar
                alt={member.first_name}
                src={`${IMAGE_URL}/${member.image_url}`}
                sx={{ width: 48, height: 48 }}
              />
              <span>
                {member.first_name} {member.father_name}
              </span>
            </Contact>
          ))}

        {activeTab === "teams" &&
          teams.map(team => {
            if (team.members_count === 0) return null;

            return (
              <Contact key={team.id}>
                <Checkbox
                  name={team.id}
                  buttons={[
                    {
                      checkboxLabel: "",
                      value: true,
                      id: team.id,
                      checked: recipients.has(`${team.id}_${team.name}`),
                      onChange: e => {
                        if (e.target.checked) {
                          addRecipient(`${team.id}_${team.name}`, team);
                          addTeam(team.id, { id: team.id });
                        } else {
                          removeRecipient(`${team.id}_${team.name}`);
                          removeTeam(team.id);
                        }
                      }
                    }
                  ]}
                  error=""
                  helperText=""
                  register={() => {}}
                />
                <Avatar
                  alt={team.name}
                  src={`${IMAGE_URL}/${team.image_url}`}
                  sx={{ width: 48, height: 48 }}
                />
                <span>{team.name}</span>
              </Contact>
            );
          })}

        {activeTab === "partners" &&
          partners.map(partner => (
            <Contact key={partner.id}>
              <Checkbox
                name={partner.id}
                buttons={[
                  {
                    checkboxLabel: "",
                    value: true,
                    id: partner.id,
                    checked: recipients.has(
                      `${partner.id}_${partner.first_name}_${partner.last_name}`
                    ),
                    onChange: e => {
                      if (e.target.checked) {
                        addRecipient(
                          `${partner.id}_${partner.first_name}_${partner.last_name}`,
                          partner
                        );
                        addPartner(partner.id, { id: partner.id });
                      } else {
                        removeRecipient(
                          `${partner.id}_${partner.first_name}_${partner.last_name}`
                        );
                        removePartner(partner.id);
                      }
                    }
                  }
                ]}
                error=""
                helperText=""
                register={() => {}}
              />
              <Avatar
                alt={partner.first_name}
                src={`${IMAGE_URL}/${partner.image_url}`}
                sx={{ width: 48, height: 48 }}
              />
              <span>
                {partner.first_name} {partner.last_name}
              </span>
            </Contact>
          ))}
      </Contacts>
    </Container>
  );
};
