import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { CurrencyCircleDollar } from "@phosphor-icons/react";

const Container = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: ${({ color }) => color};
  color: #fff;
  box-shadow: 10px 10px 20px 0px rgba(138, 138, 138, 0.15);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div``;

const Icon = styled.div`
  background-color: #fff;
  color: ${({ color }) => color};
  display: grid;
  place-items: center;
  border-radius: 4px;
  height: 24px;
  width: 24px;
`;

const Stat = styled.div`
  font-weight: 900;
  font-size: 40px;
`;

export const StatCard = ({
  title,
  icon = <CurrencyCircleDollar size={18} />,
  color = "#7FA457",
  stat
}) => {
  return (
    <Container color={color}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Title>{title}</Title>
        <Icon color={color}>{icon}</Icon>
      </Stack>
      <Stat>{Number(stat).toLocaleString()}</Stat>
    </Container>
  );
};
