import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import { Stack } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

export const EmergencyContactForm = ({ register, errors, control }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Field>
          <Label htmlFor="emergency_contact_title">
            {t("HR.EmergencyContact.Salutation")}
          </Label>
          <Input
            id="emergency_contact_title"
            {...register("emergency_contact_title")}
          />
          <ErrorMessage
            errors={errors}
            name="emergency_contact_title"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="emergency_contact_first_name">
            {t("HR.EmergencyContact.FirstName")}
          </Label>
          <Input
            id="emergency_contact_first_name"
            {...register("emergency_contact_first_name")}
          />
          <ErrorMessage
            errors={errors}
            name="emergency_contact_first_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="emergency_contact_middle_name">
            {t("HR.EmergencyContact.MiddleName")}
          </Label>
          <Input
            id="emergency_contact_middle_name"
            {...register("emergency_contact_middle_name")}
          />
          <ErrorMessage
            errors={errors}
            name="emergency_contact_middle_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="emergency_contact_last_name">
            {t("HR.EmergencyContact.LastName")}
          </Label>
          <Input
            id="emergency_contact_last_name"
            {...register("emergency_contact_last_name")}
          />
          <ErrorMessage
            errors={errors}
            name="emergency_contact_last_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="relationship_to_employee">
          {t("HR.EmergencyContact.Relationship")}
        </Label>
        <Input
          id="relationship_to_employee"
          {...register("relationship_to_employee")}
        />
        <ErrorMessage
          errors={errors}
          name="relationship_to_employee"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="emergency_contact_phone">
          {t("HR.EmergencyContact.PhoneNumber")}
        </Label>
        <Controller
          name="emergency_contact_phone"
          control={control}
          render={({ field }) => (
            <MuiTelInput
              forceCallingCode
              defaultCountry="ET"
              disableFormatting
              size="small"
              inputProps={{
                maxLength: 13
              }}
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="emergency_contact_phone"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="emergency_contact_address">
          {t("HR.EmergencyContact.Address")}
        </Label>
        <Input
          id="emergency_contact_address"
          {...register("emergency_contact_address")}
        />
        <ErrorMessage
          errors={errors}
          name="emergency_contact_address"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
    </Stack>
  );
};
