import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

const DatePickerWrapper = styled.div`
  & .react-datepicker__header {
    background: ${({ theme }) => theme.palette.primary.main};

    & .react-datepicker__current-month {
      color: #ffffff;
    }

    & .react-datepicker__day-name {
      color: #ffffff;
    }
  }

  & .react-datepicker__navigation-icon::before {
    border-color: #ffffff;
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--in-range {
    background: ${({ theme }) => theme.palette.primary.main};
    color: #ffffff;
  }
`;

const DatePicker = styled(ReactDatePicker)`
  border-radius: 5px;
  border: 1px solid #9ca3af;
  padding: 8px 20px;
  cursor: pointer;
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
  outline: none;
  background: transparent;
`;

const IntervalSelector = styled.select`
  border-radius: 5px;
  border: 1px solid #9ca3af;
  padding: 8px 20px;
  cursor: pointer;
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
  outline: none;
  background: transparent;
`;

export const Toolbar = ({ onChange }) => {
  const [startDate, setStartDate] = useState(moment().startOf("year").toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState("monthly");
  const { t } = useTranslation();

  const onPeriodChange = event => {
    setPeriod(event.target.value);
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const onDateChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    onChange({
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: endDate ? moment(endDate).format("YYYY-MM-DD") : endDate,
      period: period
    });
  }, [startDate, endDate, period]);

  return (
    <Stack direction="row" alignItems="center" gap="10px">
      <DatePickerWrapper>
        <DatePicker
          showPopperArrow={false}
          showIcon={true}
          selected={startDate}
          onChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          todayButton="Today"
          {...(period === "daily" && {
            minDate: moment().subtract(7, "days").toDate(),
            maxDate: moment().toDate()
          })}
          {...(period === "weekly" && {
            minDate: moment().startOf("month").toDate(),
            maxDate: moment().endOf("month").toDate()
          })}
          {...(period === "monthly" && { dateFormat: "MM/yyyy" })}
          {...(period === "monthly" && { showMonthYearPicker: true })}
          {...(period === "quarterly" && { dateFormat: "yyyy, QQQ" })}
          {...(period === "quarterly" && { showQuarterYearPicker: true })}
          {...(period === "annually" && { dateFormat: "yyyy" })}
          {...(period === "annually" && { showYearPicker: true })}
        />
      </DatePickerWrapper>
      <IntervalSelector value={period} onChange={onPeriodChange}>
        <option value="daily">{t("dashboard.common.Daily")}</option>
        <option value="weekly">{t("dashboard.common.Weekly")}</option>
        <option value="monthly">{t("dashboard.common.Monthly")}</option>
        <option value="quarterly">{t("dashboard.common.Quarterly")}</option>
        <option value="annually">{t("dashboard.common.Annually")}</option>
      </IntervalSelector>
    </Stack>
  );
};
