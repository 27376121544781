import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useExpenseStore,
  useUpdateExpenseTypes,
  usedeleteExpenseTypes
} from "features/finances";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const SmallText = styled.span`
  color: #8e8e8e;
  font-size: 10px;
  font-weight: 500;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Textarea = styled.textarea`
  resize: none;
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const expenseTypeSchema = yup.object({
  name: yup.string().required("Expense type name is required"),
  description: yup.string().required("Expense type description is required")
});

export function UpdateExpenseTypeModal({ data }) {
  const updateExpenseTypeOpen = useExpenseStore(
    state => state.updateExpenseTypeOpen
  );
  const closeUpdateExpenseTypeModal = useExpenseStore(
    state => state.closeUpdateExpenseTypeModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const updateExpenseTypeMutation = useUpdateExpenseTypes();
  const deleteExpenseTypeMutation = usedeleteExpenseTypes();

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      name: data?.name,
      description: data?.description
    },
    resolver: yupResolver(expenseTypeSchema)
  });

  useEffect(() => {
    if (data) {
      setValue("name", data?.name);
      setValue("description", data?.description);
    }
  }, [data]);

  const onSubmit = values => {
    updateExpenseTypeMutation.mutate(
      {
        churchId: selectedChurch?.id,
        expenseTypeId: data?.id,
        expenseTypeData: values
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Edit expense type"
      isOpen={updateExpenseTypeOpen}
      onClose={closeUpdateExpenseTypeModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            flavor="neutral"
            size="small"
            onClick={() => {
              deleteExpenseTypeMutation.mutate({
                churchId: selectedChurch?.id,
                expenseTypeId: data?.id
              });
            }}
          >
            Delete
          </Button>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button flavor="white" size="small">
              Cancel
            </Button>
            <Button size="small" onClick={handleSubmit(onSubmit)}>
              Save Changes
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="name">Expense Type</Label>
        <Input id="name" {...register("name")} />
      </Field>
      <Field>
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          rows={10}
          {...register("description")}
          maxLength={50}
        />
      </Field>
      <SmallText>No more than 50 characters</SmallText>
    </BaseModal>
  );
}
