import styled from "@emotion/styled";
import { Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  ChatCircleDots,
  MicrosoftTeamsLogo,
  Money,
  UsersFour
} from "@phosphor-icons/react";
import SMSSentPattern from "assets/images/sms-sent.png";
import SystemUserPattern from "assets/images/system-user.png";
import TotalBalancePattern from "assets/images/total-balance.png";
import TotalMembersRegisteredPattern from "assets/images/total-members-registered.png";
import TotalTeamsPattern from "assets/images/total-teams.png";
import { Notification } from "components/UI";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCentralStore } from "stores";
import { useSuperAdminStats } from "../api";
import { StatInfoCard } from "../components";

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 142px;
  border-radius: 10px;
  background: url(${TotalMembersRegisteredPattern}), #3cadb7;
  background-blend-mode: soft-light;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
`;

const BannerTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  color: white;
`;

const BannerStat = styled.div`
  font-size: 100px;
  font-weight: 700;
  color: white;
`;

export function SuperAdminDashboard() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const [dashboardView, setDashboardView] = useState("members");
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const superAdminStatsQuery = useSuperAdminStats({
    page: page,
    pageSize: pageSize
  });

  useEffect(() => {
    setHeaderTitle("Super Admin Dashboard");

    return () => setHeaderTitle("");
  }, []);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "teams_count", headerName: "Teams Count", flex: 1 },
    {
      field: "registered_members_count",
      headerName: "Registered Members Count",
      flex: 1
    },
    { field: "sent_messages_count", headerName: "Sent Messages Count", flex: 1 }
  ];

  return (
    <Stack py={4} spacing={4}>
      <Notification />
      <StatGrid>
        <StatInfoCard
          title="Users Subscribed"
          stat={superAdminStatsQuery?.data?.stats?.users_subscribed ?? 0}
          icon={<UsersFour size={24} />}
          color="#E39337"
          background={`url(${SystemUserPattern}) #E3933766`}
          onClick={() => setDashboardView("users")}
          active={dashboardView === "users"}
        />
        <StatInfoCard
          title="Denomination Created"
          stat={superAdminStatsQuery.data?.stats?.denomination_created ?? 0}
          icon={<MicrosoftTeamsLogo size={24} />}
          color="#65A6A0"
          background={`url(${TotalTeamsPattern}) #65A6A066`}
          onClick={() => setDashboardView("denominations")}
          active={dashboardView === "denominations"}
        />
        <StatInfoCard
          title="Churches Created"
          stat={superAdminStatsQuery?.data?.stats?.churches_created ?? 0}
          icon={<Money size={24} />}
          color="#7FA457"
          background={`url(${TotalBalancePattern}) #7FA45766`}
          onClick={() => setDashboardView("churches")}
          active={dashboardView === "churches"}
        />
        <StatInfoCard
          title="SMS Sent"
          stat={superAdminStatsQuery?.data?.stats?.sms_sent ?? 0}
          icon={<ChatCircleDots size={24} />}
          color="#018ABE"
          background={`url(${SMSSentPattern}) #018ABE66`}
          onClick={() => setDashboardView("messages")}
          active={dashboardView === "messages"}
        />
      </StatGrid>
      <Banner>
        <BannerTitle>
          Total members
          <br /> registered
        </BannerTitle>
        <BannerStat>
          {superAdminStatsQuery?.data?.stats?.total_registered_members ?? 0}
        </BannerStat>
      </Banner>
      <Paper
        sx={{
          py: 1,
          px: 2
        }}
      >
        <DataGrid
          autoHeight
          rows={superAdminStatsQuery?.data?.churches_info?.data || []}
          rowCount={superAdminStatsQuery?.data?.churches_info?.total || 0}
          loading={superAdminStatsQuery?.isLoading}
          columns={columns}
          pagination
          rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          density="comfortable"
          getRowId={row => row.name}
          disableColumnMenu
          disableSelectionOnClick
          paginationMode="server"
          pageSize={pageSize}
          onPageChange={newPage => setPage(newPage + 1)}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          localeText={{
            noRowsLabel: t("teams.teamProfile.NoRows"),
            MuiTablePagination: {
              labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
            }
          }}
        />
      </Paper>
    </Stack>
  );
}
