import { useQuery } from "@tanstack/react-query";
import { QueryBuilder } from "features/members";
import { axios } from "lib/axios";

export const getBudgetGroupsPaginated = ({
  churchId,
  page,
  pageSize,
  queries
}) => {
  const queryBuilder = new QueryBuilder();

  queryBuilder.pagination(page, pageSize);

  if (queries.filter) {
    queryBuilder.filter(queries.filter);
  }

  if (queries.search) {
    queryBuilder.search(queries.search);
  }

  if (queries.sort) {
    queryBuilder.sort(queries.sort);
  }

  const queryString = queryBuilder.build();

  return axios.get(
    `/churches/${churchId}/group-budgets-paginated${queryString}`
  );
};

export const useBudgetGroupsPaginated = ({
  churchId,
  page,
  pageSize,
  queries = {},
  config
} = {}) => {
  return useQuery({
    ...config,
    queryKey: [
      "budget-groups",
      page,
      pageSize,
      churchId,
      JSON.stringify(queries)
    ],
    queryFn: () =>
      getBudgetGroupsPaginated({ churchId, page, pageSize, queries })
  });
};
