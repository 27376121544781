import * as yup from "yup";
import "yup-phone";

export const inviteSchema = yup.object({
  email: yup.string().required("Email is required").email("Email is not valid"),
  role_id: yup.string().required("Role is required")
});

export const profileSchema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email("Email is not valid"),
  phone: yup.string().phone("ET", true, "Phone is not valid")
});

export const changePasswordSchema = yup.object({
  current_password: yup
    .string()
    .required("Password is required")
    .min(8, "Password should contain 8 characters")
    .matches(
      /[a-z]+/,
      "Password should contain at least one lowercase character"
    )
    .matches(
      /[A-Z]+/,
      "Password should contain at least one uppercase character"
    )
    .matches(/\d+/, "Password should contain at least one number"),
  new_password: yup
    .string()
    .required("Password is required")
    .min(8, "Password should contain 8 characters")
    .matches(
      /[a-z]+/,
      "Password should contain at least one lowercase character"
    )
    .matches(
      /[A-Z]+/,
      "Password should contain at least one uppercase character"
    )
    .matches(/\d+/, "Password should contain at least one number"),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
});

export const roleSchema = yup
  .object({
    role_name: yup.string().required("Name is required"),
    role_description: yup.string().required("Description is required")
  })
  .required();
