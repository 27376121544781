import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const updateUserStatus = ({ churchId, userId, status }) => {
  return axios.put(`/churches/${churchId}/users/${userId}/status`, { status });
};

export const useUpdateUserStatus = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("users");

      setNotification({
        type: "success",
        message: "Status updated successfully!"
      });
    },
    ...config,
    mutationFn: updateUserStatus
  });
};
