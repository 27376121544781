import styled from "@emotion/styled";
import { Snackbar } from "@mui/material";
import { BsCheckCircle, BsInfoCircle, BsX, BsXCircle } from "react-icons/bs";
import { useCentralStore } from "stores";

const notificationStyleTypes = {
  success: {
    title: "Congratulations",
    icon: <BsCheckCircle />,
    iconBackgroundColor: "#50dc6c",
    backgroundColor: "#f1f8f4",
    borderColor: "#cfe8d3"
  },
  error: {
    title: "Something went wrong",
    icon: <BsXCircle />,
    iconBackgroundColor: "#FC5758",
    backgroundColor: "#FAEEEB",
    borderColor: "#EFD0C7"
  },
  info: {
    title: "Did you know?",
    icon: <BsInfoCircle />,
    iconBackgroundColor: "#3186EA",
    backgroundColor: "#D8E6F9",
    borderColor: "#B4CDEF"
  }
};

const StyledAlert = styled.div`
  padding: 16px;
  background-color: ${props => props.bg};
  border: 1.5px solid ${props => props.borderColor};
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  max-width: 750px;

  .startIcon {
    min-height: 48px;
    min-width: 48px;
    background-color: ${props => props.iconBg};
    display: grid;
    place-items: center;
    font-size: 24px;
    border-radius: 100%;
    color: #ffffff;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .endIcon {
    min-height: 24px;
    min-width: 24px;
    background-color: #ffffff;
    display: grid;
    place-items: center;
    border-radius: 100%;
    margin-left: auto;
    cursor: pointer;
  }

  h3 {
    margin: 0;
  }

  p {
    color: #838282;
    margin: 0;
  }
`;

export function Notification() {
  const {
    notificationVisible,
    notificationType,
    notificationMessage,
    notificationTitle,
    dismissNotification
  } = useCentralStore();

  return (
    <Snackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={notificationVisible}
      onClose={dismissNotification}
    >
      <StyledAlert
        bg={notificationStyleTypes[notificationType]["backgroundColor"]}
        iconBg={notificationStyleTypes[notificationType]["iconBackgroundColor"]}
        borderColor={notificationStyleTypes[notificationType]["borderColor"]}
      >
        <span className="startIcon">
          {notificationStyleTypes[notificationType]["icon"]}
        </span>
        <div>
          <h3>
            {notificationTitle ||
              notificationStyleTypes[notificationType]["title"]}
          </h3>
          <p>{notificationMessage}</p>
        </div>
        <span className="endIcon" onClick={dismissNotification}>
          <BsX />
        </span>
      </StyledAlert>
    </Snackbar>
  );
}
