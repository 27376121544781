export const getChartValues = data => {
  const result = [];

  for (const year in data) {
    if (typeof data[year] === "object") {
      for (const month in data[year]) {
        result.push(data[year][month]);
      }
    } else {
      result.push(`${data[year]}`);
    }
  }

  return result;
};
