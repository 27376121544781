import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const approveSettlement = ({ churchId, expenseId, settlementId }) => {
  return axios.patch(
    `/churches/${churchId}/expenses/${expenseId}/settlements/${settlementId}/approve`
  );
};

export const useApproveSettlement = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("settlements");

      setNotification({
        type: "success",
        message: "Settlement approved successfully!"
      });
    },
    ...config,
    mutationFn: approveSettlement
  });
};
