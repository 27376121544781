import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getChartOfAccounts = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/chart-of-accounts`);
};

export const useChartOfAccounts = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["chart-accounts", churchId],
    queryFn: () => getChartOfAccounts({ churchId })
  });
};
