import { Button, Grid, Stack, Typography } from "@mui/material";
import { PhoneInputField, Radio, Select, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { useMembersStore } from "features/members";
import { useRef } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const PreviousChurchInfoSection = ({
  register,
  errors,
  control,
  watch
}) => {
  const { t } = useTranslation();
  const hiddenLetterFileInput = useRef(null);
  const letterResignation = useMembersStore(
    state => state.memberData.letter_of_resignation
  );
  const setLetterImage = useMembersStore(state => state.setLetterImage);

  return (
    <Grid
      direction={{
        xs: "column",
        md: "row"
      }}
      container
      columnSpacing={12}
    >
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step4.PreviousChurchPastorInformation")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2} columns={{ xs: 2, md: 12 }}>
        <Grid item xs={2} md={12}>
          <Radio
            label={t("addMembers.step4.AreYouTransferredFromOtherChurch")}
            name="transfer_from_other_church"
            buttons={[
              {
                radioLabel: t("form.common.yes"),
                value: "1",
                id: "transferred_yes"
              },
              {
                radioLabel: t("form.common.no"),
                value: "0",
                id: "transferred_no"
              }
            ]}
            row={true}
            register={register}
            error={!!errors.transfer_from_other_church?.message}
            helperText={errors.transfer_from_other_church?.message}
          />
        </Grid>
        {watch("transfer_from_other_church") === "1" && (
          <>
            <Grid item xs={2} md={6}>
              <TextField
                label={t("addMembers.step4.FormerChurchName")}
                name="previous_church_name"
                error={!!errors.previous_church_name?.message}
                helperText={errors.previous_church_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={6}>
              <Controller
                name="date_of_resignation"
                control={control}
                render={({ field }) => (
                  <DateField
                    label={t("addMembers.step4.DateOfResignation")}
                    name="date_of_resignation"
                    error={!!errors.date_of_resignation?.message}
                    helperText={errors.date_of_resignation?.message}
                    {...field}
                    disableFutureDates
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} md={12}>
              <Typography variant="subtitle2">
                {t("addMembers.step4.PreviousChurchPastorInformation")}
              </Typography>
            </Grid>
            <Grid item xs={2} md={4}>
              <Select
                label={t("addMembers.step1.Title")}
                name="pastor_title"
                options={[
                  {
                    optionLabel: "Mr",
                    value: "Mr"
                  },
                  {
                    optionLabel: "Mrs",
                    value: "Mrs"
                  },
                  {
                    optionLabel: "Ms",
                    value: "Ms"
                  },
                  {
                    optionLabel: "Miss",
                    value: "Miss"
                  },
                  {
                    optionLabel: "Dr",
                    value: "Dr"
                  },
                  {
                    optionLabel: "Pastor",
                    value: "Pastor"
                  },
                  {
                    optionLabel: "Evangelist",
                    value: "Evangelist"
                  },
                  {
                    optionLabel: "Prophet",
                    value: "Prophet"
                  },
                  {
                    optionLabel: "Reverend",
                    value: "Reverend"
                  }
                ]}
                error={!!errors.pastor_title?.message}
                helperText={errors.pastor_title?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("form.common.fullName")}
                name="pastor_full_name"
                error={!!errors.pastor_full_name?.message}
                helperText={errors.pastor_full_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <Controller
                name="pastor_phone_number"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t("form.common.phone")}
                    variant="filled"
                    error={!!errors.pastor_phone_number?.message}
                    helperText={errors.pastor_phone_number?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} md={12}>
              <Radio
                label={t("addMembers.step4.ReasonForResignation")}
                name="reason_for_resignation"
                buttons={[
                  {
                    id: "Due to change on residential address",
                    radioLabel: t("addMembers.step4.ResidenceChange"),
                    value: "Due to change on residential address",
                    disabled: false
                  },
                  {
                    id: "Due to job location transfer",
                    radioLabel: t("addMembers.step4.DueToJobTransfer"),
                    value: "Due to job location transfer",
                    disabled: false
                  },
                  {
                    id: "Due to personal affairs",
                    radioLabel: t("addMembers.step4.PersonalAffairs"),
                    value: "Due to personal affairs",
                    disabled: false
                  },
                  {
                    id: "Due to conflict",
                    radioLabel: t("addMembers.step4.DueToConflict"),
                    value: "Due to conflict",
                    disabled: false
                  },
                  {
                    id: "Due to marriage",
                    radioLabel: t("addMembers.step4.DueToMarriage"),
                    value: "Due to marriage",
                    disabled: false
                  },
                  {
                    id: "Due to academic reason",
                    radioLabel: t("addMembers.step4.AcademicReason"),
                    value: "Due to academic reason",
                    disabled: false
                  },
                  {
                    id: "Other",
                    radioLabel: t("addMembers.step4.Other"),
                    value: "Other"
                  }
                ]}
                cols={2}
                error={!!errors.reason_for_resignation?.message}
                helperText={errors.reason_for_resignation?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={6}>
              <TextField
                isLabelBold={false}
                isPlain={true}
                hasNoBg={true}
                id="other"
                label={t("addMembers.step4.Other")}
                name="reason_for_resignation_other"
                error={!!errors.reason_for_resignation_other?.message}
                helperText={errors.reason_for_resignation_other?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={12}>
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <Stack alignItems="flex-start">
                  <input
                    accept="image/*, .doc, .docx, .pdf"
                    type="file"
                    ref={hiddenLetterFileInput}
                    onChange={() => {
                      const fileUploaded = event.target.files[0];
                      setLetterImage(fileUploaded);
                    }}
                    hidden
                  />
                  <Typography variant="subtitle2">
                    {t("addMembers.step4.LetterOfResignation")}
                  </Typography>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => {
                      hiddenLetterFileInput.current.click();
                    }}
                  >
                    {t("addMembers.step4.UploadLetterOfResignation")}
                  </Button>
                </Stack>
                <span>{letterResignation?.name}</span>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
