import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getGeneralStats = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/dashboard/stats`);
};

export const useGeneralStats = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["general-stats", churchId],
    queryFn: () => getGeneralStats({ churchId }),
    select: response => response.result,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0
  });
};
