import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Stack } from "@mui/material";
import { Button, Select, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInviteUser, useRoles } from "../api";
import { useUsersStore } from "../stores";
import { inviteSchema } from "../validation";

export function InviteUserModal() {
  const usersInviteModalOpen = useUsersStore(
    state => state.usersInviteModalOpen
  );
  const closeUsersInviteModal = useUsersStore(
    state => state.closeUsersInviteModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const inviteMutation = useInviteUser();
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);

  const { data } = useRoles({
    churchId: selectedChurch?.id
  });

  useEffect(() => {
    if (data) {
      const rolesResponse = data?.result?.map(role => ({
        optionLabel: role?.name,
        value: role?.id
      }));

      setRoles(rolesResponse);
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset
  } = useForm({
    defaultValues: {
      email: "",
      role_id: ""
    },
    resolver: yupResolver(inviteSchema)
  });

  useEffect(() => {
    if (inviteMutation.isSuccess) {
      reset({ ...defaultValues });
    }

    return () => {};
  }, [inviteMutation.isSuccess]);

  const onSubmit = values => {
    inviteMutation.mutate({
      churchId: selectedChurch?.id,
      userData: values
    });
  };

  return (
    <Modal open={usersInviteModalOpen} onClose={closeUsersInviteModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 4,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("systemAdmins.inviteUsers.InviteUsers")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing={2}>
            <TextField
              isLabelBold={true}
              label={t("systemAdmins.inviteUsers.Email")}
              name="email"
              error={!!errors.email?.message}
              helperText={errors.email?.message}
              register={register}
              autoComplete="off"
            />
            <Select
              isLabelBold={true}
              label={t("systemAdmins.inviteUsers.Role")}
              name="role_id"
              options={roles}
              register={register}
              error={!!errors.role_id?.message}
              helperText={errors.role_id?.message}
            />
          </Stack>
          <Button
            type="submit"
            size="large"
            variant="contained"
            fullWidth
            sx={{ mt: 4 }}
            disabled={inviteMutation.isLoading}
          >
            {t("systemAdmins.inviteUsers.SendInvitation")}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
