import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { Banner } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineIdcard } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsChatText, BsPrinter } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { useMember } from "../api";
import { useMembersStore } from "../stores";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-inline: 24px;
  gap: 24px;
  align-items: center;
`;

const WhiteButton = styled(Button)`
  background: white;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const FixedChip = styled(Chip)`
  max-width: 160px;
`;

export function MemberProfile() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const setFullMemberData = useMembersStore(state => state.setFullMemberData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);

  useMember({
    churchId: selectedChurch?.id,
    memberId: id,
    config: {
      onSuccess: response => {
        setFullMemberData(response.result);
      }
    }
  });

  useEffect(() => {
    setHeaderTitle("Member Profile");
    return () => setHeaderTitle("");
  }, []);

  useEffect(() => {
    setHeaderBackground(false);
    return () => setHeaderBackground(true);
  }, []);

  return (
    <Box>
      <Banner>
        <Container>
          <Avatar
            sx={{
              width: "180px",
              height: "180px",
              border: "10px solid",
              borderColor: "white"
            }}
            src={`${IMAGE_URL}/${fullMemberData?.profile_image_url}`}
            alt={fullMemberData?.first_name}
          />
          <Stack spacing={2}>
            <Stack>
              <Typography>{t("form.common.name")}</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {fullMemberData?.first_name} {fullMemberData?.father_name}{" "}
                {fullMemberData?.last_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography>{t("form.common.gender")}</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {fullMemberData?.gender}
              </Typography>
            </Stack>
            <Stack>
              <Typography>{t("form.common.phone")}</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {fullMemberData?.phone}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Stack>
              <Typography>{t("form.common.email")}</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {fullMemberData?.email}
              </Typography>
            </Stack>
            <Stack>
              <Typography>{t("form.common.age")}</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {fullMemberData?.age}
              </Typography>
            </Stack>
            <Stack>
              <Typography>{t("form.common.dateOfBirth")}</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {fullMemberData?.date_of_birth}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={1} alignSelf="flex-start">
            <Typography>{t("members.profile.ActiveTeams")}</Typography>
            {fullMemberData?.teams?.map(team => (
              <FixedChip key={team?.id} label={team?.name} color="primary" />
            ))}
          </Stack>
          <Stack spacing={1}>
            <WhiteButton
              variant="contained"
              startIcon={<BsChatText />}
              onClick={() => {
                clearRecipients();
                removeMember(fullMemberData.id);
                addRecipient(
                  `${fullMemberData?.id}_${fullMemberData?.first_name}_${fullMemberData?.last_name}`,
                  fullMemberData
                );
                addMember(fullMemberData.id, { id: fullMemberData.id });
                navigate("/messages");
              }}
            >
              {t("members.profile.SendMessage")}
            </WhiteButton>
            <WhiteButton variant="contained" startIcon={<BsPrinter />}>
              {t("members.profile.Print")}
            </WhiteButton>
            <WhiteButton variant="contained" startIcon={<AiOutlineIdcard />}>
              {t("members.profile.CreateID")}
            </WhiteButton>
            <WhiteButton
              variant="contained"
              startIcon={<BiEdit />}
              onClick={() => navigate(`/members/${fullMemberData?.id}/update`)}
            >
              {t("members.profile.EditMember")}
            </WhiteButton>
          </Stack>
        </Container>
      </Banner>
      <Box sx={{ backgroundColor: "neutral.main", my: 4, py: 3, px: 3 }}>
        <PersonalProfile data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <Address data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <EmergencyContact data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <AcademicAndJobStatus data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <MaritalStatus data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <ChildAndDependencies data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <ChildInfo data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <PreviousChurchInfo data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <SalvationProfile data={fullMemberData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <CurrentChurchEngagementInfo data={fullMemberData} />
      </Box>
    </Box>
  );
}

const PersonalProfile = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step1.PersonalProfile")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step1.Nationality")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.nationality}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step1.IdType")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.id_type}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step1.IdNumber")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.id_number}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step1.Birthplace")}
            </Typography>
            <Grid container columnSpacing={2}>
              <Grid item xs={4}>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.country")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {data?.country}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.region")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {data?.region}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.city")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {data?.city}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack>
            <Button variant="contained">{t("members.profile.ViewID")}</Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const Address = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("form.common.address")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack spacing={2}>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step1.SocialMediaLink")}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.social_media_link}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.country")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.current_country}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.region")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.current_region}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.zone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.current_zone}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.city")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.current_city}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.subcity")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.current_sub_city}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.woreda")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.current_woreda}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.kebele")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.current_kebele}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.houseNumber")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.house_number}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.specificLocation")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.specific_location}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const EmergencyContact = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step1.EmergencyContact")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.firstName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.emergency_contact_first_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.fatherName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.emergency_contact_father_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.grandFatherName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.emergency_contact_last_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.phone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.emergency_contact_phone}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step1.Relationship")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.emergency_contact_relationship}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("form.common.address")}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.country")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.emergency_contact_country}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.region")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.emergency_contact_region}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.city")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.emergency_contact_city}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const AcademicAndJobStatus = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step2.AcademicStatus")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack direction="row" spacing={2}>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step2.AcademicStatus")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.academic_status}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step2.JobStatus")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.job_status}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step2.ProfessionalSkills")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.professional_skill}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const MaritalStatus = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step3.MaritalStatus")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.MaritalStatus")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.marital_status}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.MaritalDate")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.marital_date}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.IsSpouseAMember")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.spouse_member === "1" ? "Yes" : "No"}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.SpouseName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.spouse_first_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.SpouseFatherName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.spouse_father_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.SpouseGrandfatherName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.spouse_last_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.SpousePhoneNumber")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.spouse_phone_number}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>Spouse Church ID</Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.spouse_church_id}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const ChildAndDependencies = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step3.ChildAndDependencies")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step3.HaveChildrenOrDependencies")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.child_and_dependencies === "1" ? "Yes" : "No"}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

const ChildInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step3.ChildInfo")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.FirstName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.child_first_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.fatherName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.child_father_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.grandFatherName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.child_last_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.gender")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.child_gender}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.dateOfBirth")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.child_date_of_birth}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.PlaceOfBirth")}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.country")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {data?.child_birth_country}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.region")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {data?.child_birth_region}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("form.common.city")}
                  </Typography>
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {data?.child_birth_city}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.Nationality")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.child_nationality}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.IsChildAMember")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.is_child_member}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.ChildChurchID")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.child_church_id}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step3.RelationshipWithChild")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.relationship_with_child}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const PreviousChurchInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step4.PreviousChurchInfo")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step4.FormerChurchName")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.previous_church_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step4.Name")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.pastor_full_name}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step4.PhoneNumber")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.pastor_phone_number}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 500 }}>
                {t("addMembers.step4.ReasonForResignation")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.reason_for_resignation}
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="flex-start">
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step4.LetterOfResignation")}
            </Typography>
            <Button variant="contained">
              {t("members.profile.DownloadDoc")}
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const SalvationProfile = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step5.SalvationProfile")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step5.SalvationDate")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.salvation_date}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step5.SalvationChurch")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.salvation_church}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step5.AreYouBaptized")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.is_baptized === "1" ? "Yes" : "No"}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step5.BaptizedChurch")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.baptism_church}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step5.DateOfBaptism")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.baptism_date}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const CurrentChurchEngagementInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("addMembers.step5.CurrentChurchEngagementInfo")}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step5.MembershipDate")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.membership_date}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 500 }}>
              {t("addMembers.step5.MinistryPreferences")}
            </Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.ministry_preference}
            </Typography>
          </Stack>
          <Stack>
            <Typography>{t("addMembers.step5.GiftsAndTalents")}</Typography>
            <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
              {data?.gifts_and_talents}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};
