import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getUsersPaginated = ({ churchId, page, pageSize }) => {
  return axios.get(
    `/churches/${churchId}/users-paginated?page=${page}&per_page=${pageSize}`
  );
};

export const useUsersPaginated = ({
  churchId,
  page,
  pageSize,
  config
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["users", page, pageSize, churchId],
    queryFn: () => getUsersPaginated({ churchId, page, pageSize })
  });
};
