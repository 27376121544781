import axios from "axios";
import { API_URL } from "config";
import printJS from "print-js";
import { useCentralStore } from "stores";
import { getToken } from "utils/getToken";

export const printMemberForm = ({ churchId }) => {
  const url = `${API_URL}/churches/${churchId}/members-form`;

  axios({
    method: "get",
    url: url,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  })
    .then(response => {
      printJS(URL.createObjectURL(new Blob([response.data])));
    })
    .catch(() => {
      const setNotification = useCentralStore.getState().setNotification;
      setNotification({
        type: "error",
        message: "Unable to print member form"
      });
    });
};
