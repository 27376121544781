import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";

export const createChurch = ({ churchData }) => {
  return axios.post(`/churches`, churchData);
};

export const useCreateChurch = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("churches");
      setNotification({
        type: "success",
        message: "Church created successfully!"
      });
      navigate("/churches");
    },
    ...config,
    mutationFn: createChurch
  });
};
