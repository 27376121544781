import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";

export const updateOtherProfile = ({ churchId, userId, userData }) => {
  return axios.put(`/churches/${churchId}/users/${userId}/profile`, userData);
};

export const useUpdateOtherProfile = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("users");

      setNotification({
        type: "success",
        message: "Profile updated successfully!"
      });

      navigate("/users");
    },
    ...config,
    mutationFn: updateOtherProfile
  });
};
