import { yupResolver } from "@hookform/resolvers/yup";
import { MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { Trash } from "@phosphor-icons/react";
import { LoadingScreen } from "components/LoadingScreen";
import {
  Banner,
  DeleteModal,
  Notification,
  PhoneInputField,
  Select,
  TextField,
  Upload
} from "components/UI";
import { GOOGLE_API_KEY, IMAGE_URL } from "config";
import { useDenominationStore, useDenominations } from "features/denominations";
import {
  ADDRESSES,
  MemberDataManipulator,
  useCities,
  useCountries,
  useRegions,
  useSubcities,
  useWoredas,
  useZones
} from "features/members";
import i18next from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import * as yup from "yup";
import { useDeleteChurch, useUpdateChurch } from "../api";
import { useChurchStore } from "../store";
import { updateChurchSchema } from "../validation";

export function ChurchProfile() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const closeDeleteModal = useCentralStore(state => state.closeDeleteModal);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const selectedDenomination = useDenominationStore(
    state => state.selectedDenomination
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const churchDeleteMutation = useDeleteChurch();
  const churchUpdateMutation = useUpdateChurch();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useTitle("Church Profile");

  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    watch,
    setValue,
    control
  } = useForm({
    defaultValues: _.pick(
      selectedChurch,
      yup.reach(updateChurchSchema)["_nodes"]
    ),
    resolver: yupResolver(updateChurchSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("churches.createChurch.churchProfile"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  useEffect(() => {
    setHeaderBackground(false);

    return () => setHeaderBackground(true);
  }, []);

  const denominationsQuery = useDenominations();

  const countriesQuery = useCountries();

  useEffect(() => {
    if (countriesQuery.data) {
      setValue("country_id", String(selectedChurch?.country_id));
    }
  }, [countriesQuery.data]);

  const regionsQuery = useRegions({
    countryId: watch("country_id"),
    config: {
      enabled: !!watch("country_id")
    }
  });

  useEffect(() => {
    if (regionsQuery.data) {
      setValue("region_id", String(selectedChurch?.region_id));
    }
  }, [regionsQuery.data]);

  const zonesQuery = useZones({
    regionId: watch("region_id"),
    config: {
      enabled: !!watch("region_id")
    }
  });

  useEffect(() => {
    if (zonesQuery.data) {
      setValue("zone_id", String(selectedChurch?.zone_id));
    }
  }, [zonesQuery.data]);

  const citiesQuery = useCities({
    zoneId: watch("zone_id"),
    config: {
      enabled: !!watch("zone_id")
    }
  });

  useEffect(() => {
    if (citiesQuery.data) {
      setValue("city_id", String(selectedChurch?.city_id));
    }
  }, [citiesQuery.data]);

  const subcitiesQuery = useSubcities({
    cityId: watch("city_id"),
    config: {
      enabled: !!watch("city_id")
    }
  });

  useEffect(() => {
    if (subcitiesQuery.data) {
      setValue("sub_city_id", String(selectedChurch?.sub_city_id));
    }
  }, [subcitiesQuery.data]);

  const woredasQuery = useWoredas({
    subcityId: watch("sub_city_id"),
    config: {
      enabled: !!watch("sub_city_id")
    }
  });

  useEffect(() => {
    if (woredasQuery.data) {
      setValue("woreda_id", String(selectedChurch?.woreda_id));
    }
  }, [woredasQuery.data]);

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: place => {
      setValue("latitude", place.geometry.location.lat());
      setValue("longitude", place.geometry.location.lng());
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: "et" }
    }
  });

  const onSubmit = values => {
    const cleanChurchData = {
      ...values
    };

    const churchDataManipulator = new MemberDataManipulator();

    churchDataManipulator.removeEmptyValues(cleanChurchData);

    const churchFormData =
      churchDataManipulator.convertObjectToFormData(cleanChurchData);

    churchUpdateMutation.mutate({
      churchData: churchFormData,
      churchId: selectedChurch.id
    });
  };

  if (countriesQuery.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <Notification />
      <DeleteModal
        title="Delete Church"
        onDelete={() => {
          churchDeleteMutation.mutate({
            churchId: selectedChurch.id
          });
          closeDeleteModal();
        }}
        name={selectedChurch?.name}
      />
      <Banner>
        <Stack direction="row" alignItems="center" spacing={4} sx={{ pl: 4 }}>
          <Avatar
            src={`${IMAGE_URL}/${selectedChurch?.leader_image_url}`}
            alt={selectedChurch?.leader_first_name}
            sx={{
              width: "180px",
              height: "180px",
              border: "10px solid",
              borderColor: "white"
            }}
          />
          <Stack spacing={1}>
            <Stack direction="row" spacing={2}>
              <Typography>{t("churches.profile.SeniorPastor")}:</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {selectedChurch?.leader_first_name}{" "}
                {selectedChurch?.leader_last_name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography>{t("churches.createChurch.email")}:</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {selectedChurch?.leader_email}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography>{t("churches.createChurch.phone")}:</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {selectedChurch?.leader_phone}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <IconButton
          onClick={handleClick}
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            color: "white"
          }}
        >
          <MoreVert />
        </IconButton>
      </Banner>
      <Popover
        anchorEl={anchorEl}
        id="photo-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <ChurchMenu />
      </Popover>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={4}
        sx={{ mt: 4 }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            label={t("churches.createChurch.churchName")}
            name="name"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            register={register}
            isLabelBold={true}
          />

          <TextField
            label={t("churches.createChurch.churchEmailAddress")}
            name="email"
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            register={register}
            isLabelBold={true}
          />

          <TextField
            label={t("form.common.phone")}
            name="phone"
            error={!!errors.phone?.message}
            helperText={errors.phone?.message}
            register={register}
            isLabelBold={true}
          />

          <TextField
            label={t("churches.createChurch.churchWebsite")}
            name="website"
            error={!!errors.website?.message}
            helperText={errors.website?.message}
            register={register}
            isLabelBold={true}
          />

          <TextField
            label={t("churches.createChurch.churchPOBox")}
            name="p_o_box"
            error={!!errors.p_o_box?.message}
            helperText={errors.p_o_box?.message}
            register={register}
            isLabelBold={true}
          />
        </Stack>
        <Select
          isLabelBold={1}
          label={t("churches.profile.Denomination")}
          name="denomination_id"
          options={
            denominationsQuery.data?.result?.map(denomination => ({
              optionLabel: denomination?.name,
              value: denomination?.id
            })) || []
          }
          register={register}
          error={!!errors.denomination_id?.message}
          helperText={errors.denomination_id?.message}
        />
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("churches.createChurch.churchLeaderInfo")}
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6}>
              <TextField
                label={t("churches.createChurch.firstName")}
                name="leader_first_name"
                error={!!errors.leader_first_name?.message}
                helperText={errors.leader_first_name?.message}
                register={register}
                isLabelBold={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("churches.createChurch.lastName")}
                name="leader_last_name"
                error={!!errors.leader_last_name?.message}
                helperText={errors.leader_last_name?.message}
                register={register}
                isLabelBold={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("churches.createChurch.email")}
                name="leader_email"
                error={!!errors.leader_email?.message}
                helperText={errors.leader_email?.message}
                register={register}
                autoComplete="email"
                isLabelBold={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="leader_phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    variant="filled"
                    label={t("churches.createChurch.phone")}
                    error={!!errors.leader_phone?.message}
                    helperText={errors.leader_phone?.message}
                    isLabelBold={true}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="leader_image"
                control={control}
                render={({ field }) => (
                  <Upload
                    title="Upload photo"
                    buttonTitle="Browse"
                    error={!!errors.leader_image?.message}
                    helperText={errors.leader_image?.message}
                    onChange={field.onChange}
                    preview={`${IMAGE_URL}/${selectedChurch?.leader_image_url}`}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("churches.createChurch.churchAddress")}
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.country")}
                name={`country_id`}
                options={
                  countriesQuery.data?.result?.map(country => ({
                    optionLabel: country?.name,
                    value: country?.id
                  })) || []
                }
                register={register}
                error={!!errors.country_id?.message}
                helperText={errors.country_id?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.region")}
                name={`region_id`}
                options={
                  regionsQuery.data?.result?.map(region => ({
                    optionLabel: region?.name,
                    value: region?.id
                  })) || []
                }
                register={register}
                error={!!errors.region_id?.message}
                helperText={errors.region_id?.message}
                onChange={e => {
                  setValue("region_id", e.target.value, {
                    shouldValidate: true
                  });

                  if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                    setValue("zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID, {
                      shouldValidate: true
                    });
                    setValue("city_id", ADDRESSES.ADDIS_ABABA.CITY_ID, {
                      shouldValidate: true
                    });
                  } else {
                    setValue("zone_id", "", {
                      shouldValidate: true
                    });
                    setValue("city_id", "", {
                      shouldValidate: true
                    });
                  }
                }}
              />
            </Grid>
            {watch("region_id") !== ADDRESSES.ADDIS_ABABA.REGION_ID && (
              <>
                <Grid item xs={3}>
                  <Select
                    isLabelBold={1}
                    label={t("form.common.zone")}
                    name={`zone_id`}
                    options={
                      zonesQuery.data?.result?.map(zone => ({
                        optionLabel: zone?.name,
                        value: zone?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.zone_id?.message}
                    helperText={errors.zone_id?.message}
                    disabled={
                      watch("region_id") === ADDRESSES.ADDIS_ABABA.REGION_ID
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    isLabelBold={1}
                    label={t("form.common.city")}
                    name={`city_id`}
                    options={
                      citiesQuery.data?.result?.map(city => ({
                        optionLabel: city?.name,
                        value: city?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.city_id?.message}
                    helperText={errors.city_id?.message}
                    disabled={
                      watch("region_id") === ADDRESSES.ADDIS_ABABA.REGION_ID
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.subcity")}
                name={`sub_city_id`}
                options={
                  subcitiesQuery.data?.result?.map(subcity => ({
                    optionLabel: subcity?.name,
                    value: subcity?.id
                  })) || []
                }
                register={register}
                error={!!errors.sub_city_id?.message}
                helperText={errors.sub_city_id?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.woreda")}
                name={`woreda_id`}
                options={
                  woredasQuery.data?.result?.map(woreda => ({
                    optionLabel: woreda?.name,
                    value: woreda?.id
                  })) || []
                }
                register={register}
                error={!!errors.woreda_id?.message}
                helperText={errors.woreda_id?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("churches.createChurch.kebele")}
                name="kebele"
                error={!!errors.kebele?.message}
                helperText={errors.kebele?.message}
                register={register}
                isLabelBold={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("churches.createChurch.houseNumber")}
                name="house_number"
                error={!!errors.house_number?.message}
                helperText={errors.house_number?.message}
                register={register}
                isLabelBold={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("churches.createChurch.specificLocation")}
                name="specific_location"
                error={!!errors.specific_location?.message}
                helperText={errors.specific_location?.message}
                register={register}
                isLabelBold={true}
                ref={placesRef}
                placeholder=""
              />
            </Grid>
          </Grid>
        </Box>
        <Stack spacing={1}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("churches.createChurch.churchSocialMedia")}
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              isPlain={true}
              name="facebook_link"
              error={!!errors.facebook_link?.message}
              helperText={errors.facebook_link?.message}
              register={register}
              isLabelBold={true}
            />

            <TextField
              isPlain={true}
              name="instagram_link"
              error={!!errors.instagram_link?.message}
              helperText={errors.instagram_link?.message}
              register={register}
              isLabelBold={true}
            />

            <TextField
              isPlain={true}
              name="youtube_link"
              error={!!errors.youtube_link?.message}
              helperText={errors.youtube_link?.message}
              register={register}
              isLabelBold={true}
            />
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("churches.profile.Description")}
          </Typography>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              backgroundColor: "#DFF5F5",
              borderRadius: "5px",
              px: 4,
              py: 2,
              minHeight: 200
            }}
          >
            <Box sx={{ pr: 2, flex: 1 }}>
              <TextField
                label={t("churches.profile.AboutChurch")}
                name="description"
                error={!!errors.description?.message}
                helperText={errors.description?.message}
                register={register}
                isLabelBold={true}
              />
            </Box>
            <Box sx={{ pl: 2, flex: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t("churches.profile.AboutDenomination")}
              </Typography>
              <Typography variant="body2">
                {selectedDenomination?.description}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            color="neutral"
            size="large"
            variant="contained"
            onClick={() => {
              reset({ ...defaultValues });
            }}
            disabled={churchUpdateMutation.isLoading}
          >
            {t("form.common.cancel")}
          </Button>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={churchUpdateMutation.isLoading}
          >
            {t("form.common.submit")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

const ChurchMenu = () => {
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        backgroundColor: "white",
        p: 1
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          py: 1,
          px: 2,
          cursor: "pointer",
          color: "#F44949",
          "&:hover": {
            backgroundColor: "#FCD1D1"
          }
        }}
        onClick={openDeleteModal}
      >
        <Trash size={16} />
        <Typography variant="caption">{t("form.common.delete")}</Typography>
      </Stack>
    </Stack>
  );
};
