import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Stepper = styled.div`
  display: flex;
  gap: 20px;
  line-height: 1;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    gap: 10px;
  }`}
`;

const Step = styled.span`
  display: inline-block;
  position: relative;
  padding: 0.5em 3em;
  background-color: #d7d7d7;
  color: #ffffff;
  white-space: nowrap;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    border-width: 1em 0.25em 1em 0.5em;
    border-style: solid;
    border-color: #d7d7d7 #d7d7d7 #d7d7d7 transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    border-width: 1em 0.25em 1em 0.5em;
    border-style: solid;
    border-color: transparent transparent transparent #d7d7d7;
  }

  &.active {
    color: #ffffff;
    background-color: #3dadb7;
    border-color: transparent transparent transparent #3dadb7;
  }

  &.active::before {
    border-color: #3dadb7 #3dadb7 #3dadb7 transparent;
  }

  &.active::after {
    border-color: transparent transparent transparent #3dadb7;
  }

  ${({ theme }) => `${theme.breakpoints.down("md")} {
      font-size: 12px;
      padding: 0.5em 1em;
  }`}

  &.disable {
    cursor: not-allowed;
  }
`;

export function Steppers({ activeStep, onClick }) {
  const { t } = useTranslation();

  return (
    <Stepper>
      <Step
        className={
          activeStep === 0 ? "active" : activeStep < 0 ? "disable" : ""
        }
        onClick={() => onClick(0)}
      >
        {t("addMembers.step1.Step1")}
      </Step>
      <Step
        className={
          activeStep === 1 ? "active" : activeStep < 1 ? "disable" : ""
        }
        onClick={() => onClick(1)}
      >
        {t("addMembers.step2.Step2")}
      </Step>
      <Step
        className={
          activeStep === 2 ? "active" : activeStep < 2 ? "disable" : ""
        }
        onClick={() => onClick(2)}
      >
        {t("addMembers.step3.Step3")}
      </Step>
      <Step
        className={
          activeStep === 3 ? "active" : activeStep < 3 ? "disable" : ""
        }
        onClick={() => onClick(3)}
      >
        {t("addMembers.step4.Step4")}
      </Step>
      <Step
        className={
          activeStep === 4 ? "active" : activeStep < 4 ? "disable" : ""
        }
        onClick={() => onClick(4)}
      >
        {t("addMembers.step5.Step5")}
      </Step>
    </Stepper>
  );
}
