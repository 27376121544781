import { Stack, Typography } from "@mui/material";
import { Button, Notification } from "components/UI";
import { useEffect, useState } from "react";
import { AcquisitionForm } from "../components/Forms/AcquisitionForm";
import { BasicInfoForm } from "../components/Forms/BasicInfoForm";
import { ComplianceForm } from "../components/Forms/ComplianceForm";
import { FinancialInfoForm } from "../components/Forms/FinancialInfoForm";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { useChurchStore } from "features/churches";
import { MemberDataManipulator } from "features/members";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsset, useDeleteAsset, useUpdateAsset } from "../api";

export const AssetProfile = () => {
  const [activeNav, setActiveNav] = useState("basic");
  const [asset, setAsset] = useState(null);
  const [documents, setDocuments] = useState([]);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { id } = useParams();
  const navigate = useNavigate();

  const updateAssetMutation = useUpdateAsset();
  const deleteAssetMutation = useDeleteAsset();

  const { t } = useTranslation();

  const { data } = useAsset({
    churchId: selectedChurch?.id,
    assetId: id
  });

  useEffect(() => {
    if (data) {
      setAsset(data.result);
    }
  }, [data]);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit
  } = useForm({
    defaultValues: {
      name: "",
      type: "",
      description: "",
      quantity: "",
      acquisition_method: "",
      acquisition_date: "",
      purchase_price: "",
      vendor: "",
      assigned_to: "",
      depreciation_method: "",
      location: "",
      status: "",
      condition: "",
      depreciation_rate: "",
      book_value: "",
      maintenance_schedule: "",
      last_maintenance_date: "",
      warranty_expiry_date: "",
      documents: null,
      compliance_status: "",
      notes: ""
    },
    mode: "onBlur"
  });

  useEffect(() => {
    if (asset) {
      reset({
        name: asset?.name,
        type: asset?.type,
        description: asset?.description,
        quantity: asset?.quantity,
        acquisition_method: asset?.acquisition_method,
        acquisition_date: asset?.acquisition_date,
        purchase_price: asset?.purchase_price,
        vendor: asset?.vendor,
        assigned_to: asset?.assigned_to,
        depreciation_method: asset?.depreciation_method,
        location: asset?.location,
        status: asset?.status,
        condition: asset?.condition,
        depreciation_rate: asset?.depreciation_rate,
        book_value: asset?.book_value,
        maintenance_schedule: asset?.maintenance_schedule,
        last_maintenance_date: asset?.last_maintenance_date,
        warranty_expiry_date: asset?.warranty_expiry_date,
        documents: null,
        compliance_status: asset?.compliance_status,
        notes: asset?.notes
      });
    }
  }, [asset]);

  const onSubmit = values => {
    const cleanAssetData = {
      ...values
    };

    const assetDataManipulator = new MemberDataManipulator();

    assetDataManipulator.removeEmptyValues(cleanAssetData);

    assetDataManipulator.convertValuesToDates(cleanAssetData, [
      "acquisition_date",
      "maintenance_schedule",
      "last_maintenance_date",
      "warranty_expiry_date"
    ]);

    const assetFormData =
      assetDataManipulator.convertObjectToFormData(cleanAssetData);

    if (documents.length !== 0) {
      for (const [index, document] of documents.entries()) {
        assetFormData.append(`document[${index}]`, document);
      }
      assetFormData.delete("documents");
    } else {
      assetFormData.delete("documents");
    }

    updateAssetMutation.mutate({
      churchId: selectedChurch?.id,
      assetId: asset?.id,
      assetData: assetFormData
    });
  };

  return (
    <Stack py={4} spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={700}>Profile Detail</Typography>
        <Button
          style={{ backgroundColor: "#FC5758", borderColor: "#FC5758" }}
          onClick={() =>
            deleteAssetMutation.mutate(
              {
                churchId: selectedChurch?.id,
                assetId: id
              },
              {
                onSuccess: () => {
                  navigate(`/assets`);
                }
              }
            )
          }
        >
          Delete Asset
        </Button>
      </Stack>
      <Stack direction="row">
        <Stack
          pr={4}
          sx={{ borderRight: "1px solid rgba(226, 232, 240, 1)" }}
          spacing={1}
          flexBasis="25%"
        >
          <Typography
            sx={{
              bgcolor: activeNav === "basic" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("basic")}
          >
            {t("Asset.AssetRegistration.Basic asset information")}
          </Typography>
          <Typography
            sx={{
              bgcolor:
                activeNav === "acquisition" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("acquisition")}
          >
            {t("Asset.AssetRegistration.Acquisition, Location and Assignment")}
          </Typography>
          <Typography
            sx={{
              bgcolor: activeNav === "finance" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("finance")}
          >
            {t(
              "Asset.AssetRegistration.Status, Condition and Financial Information"
            )}
          </Typography>
          <Typography
            sx={{
              bgcolor:
                activeNav === "documentation" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("documentation")}
          >
            {t(
              "Asset.AssetRegistration.Maintenance, Warranty, Documentation and Compliance"
            )}
          </Typography>
          <Typography
            sx={{
              bgcolor: activeNav === "audit" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("audit")}
          >
            Audit and History
          </Typography>
        </Stack>
        <Stack pl={2} flexBasis="60%">
          {activeNav === "basic" && (
            <BasicInfoForm register={register} errors={errors} />
          )}
          {activeNav === "acquisition" && (
            <AcquisitionForm register={register} errors={errors} />
          )}
          {activeNav === "finance" && (
            <FinancialInfoForm register={register} errors={errors} />
          )}
          {activeNav === "documentation" && (
            <ComplianceForm
              register={register}
              errors={errors}
              documents={documents}
              setDocuments={setDocuments}
            />
          )}
          {activeNav === "audit" && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Action</TableCell>
                  <TableCell align="center">Taken By</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Date & Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Change Name</TableCell>
                  <TableCell>User name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>12/09/2023, 3:15 PM</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}

          <Stack alignItems="flex-end" mt={3}>
            <Button onClick={handleSubmit(onSubmit)}>Update changes</Button>
          </Stack>
        </Stack>
      </Stack>
      <Notification />
    </Stack>
  );
};
