import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMessagesStore } from "../store";

export const updatePort = ({ churchId, portData, portId }) => {
  return axios.patch(`/churches/${churchId}/message-ports/${portId}`, portData);
};

export const useUpdatePort = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdatePortModal = useMessagesStore.getState().closeUpdatePortModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["ports"]);
      setNotification({
        type: "success",
        message: "Port updated successfully!"
      });

      closeUpdatePortModal();
    },
    ...config,
    mutationFn: updatePort
  });
};
