import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TABLES, TAB_NAMES, TAB_TO_TABLE_NAMES } from "../constants";
import { extraKidColumnsVisibility } from "../utils/kidColumns";
import { extraMemberColumnsVisibility } from "../utils/memberColumns";
import { extraPartnerColumnsVisibility } from "../utils/partnerColumns";

const initialMemberData = {
  title: "",
  first_name: "",
  father_name: "",
  last_name: "",
  gender: "",
  nationality_id: "66",
  mother_first_name: "",
  mother_father_name: "",
  mother_grand_father_name: "",
  country_id: "66",
  region_id: "",
  zone_id: "",
  city_id: "",
  date_of_birth: "",
  id_type: "",
  id_number: "",
  languages: [
    {
      language: ""
    }
  ],
  phone: "",
  has_own_phone: "1",
  office_number: "",
  email: "",
  po_box: "",
  social_media_links: [
    {
      link: ""
    }
  ],
  profile_image: null,
  id_image: null,
  current_country_id: "66",
  current_region_id: "",
  current_zone_id: "",
  current_city_id: "",
  current_sub_city_id: "",
  current_woreda_id: "",
  current_kebele: "",
  house_number: "",
  specific_location: "",
  emergency_contact_first_name: "",
  emergency_contact_father_name: "",
  emergency_contact_last_name: "",
  emergency_contact_phone: "",
  emergency_contact_relationship: "",
  emergency_contact_country_id: "66",
  emergency_contact_region_id: "",
  emergency_contact_zone_id: "",
  emergency_contact_city_id: "",
  education_and_skills: [
    {
      academic_status: "",
      professional_skill: ""
    }
  ],
  job_status: "",
  custom_job_status: "",
  profession: "",
  work_place: "",
  monthly_income: "",
  marital_status: "",
  marital_date: "",
  type_of_marriage: "",
  is_spouse_believer: "1",
  spouse_religion: "",
  is_spouse_member: "0",
  spouse_member: "",
  spouse_church_id: "",
  spouse_first_name: "",
  spouse_father_name: "",
  spouse_last_name: "",
  spouse_phone_number: "",
  spouse_date_of_birth: "",
  spouse_mother_name: "",
  spouse_mother_father_name: "",
  spouse_mother_grand_father_name: "",
  spouse_mother_date_of_birth: "",
  spouse_professional_skill: "",
  spouse_office_name: "",
  spouse_church_name: "",
  child_and_dependencies: "",
  children: [
    {
      first_name: "",
      father_name: "",
      last_name: "",
      date_of_birth: "",
      nationality_id: "",
      gender: "",
      relationship: "",
      mother_name: "",
      mother_father_name: "",
      mother_last_name: "",
      mother_country_id: "66",
      mother_region_id: "",
      mother_city_id: "",
      is_believer: "1",
      religion: "",
      church_name: "",
      is_member: "0",
      member_id: "",
      is_kid: "0"
    }
  ],
  child_first_name: "",
  child_father_name: "",
  child_last_name: "",
  child_date_of_birth: "",
  child_nationality: "",
  child_gender: "",
  child_birth_country: "66",
  child_birth_region: "",
  child_birth_city: "",
  child_church_id: "",
  relationship_with_child: "",
  transfer_from_other_church: "",
  previous_church_name: "",
  date_of_resignation: "",
  pastor_title: "",
  pastor_full_name: "",
  pastor_phone_number: "",
  reason_for_resignation: "",
  letter_of_resignation: null,
  former_belief: "",
  how_you_accept_christ: "",
  salvation_date: "",
  is_baptized: "",
  baptism_church: "",
  baptism_date: "",
  is_home_cell: "",
  home_cell_name: "",
  home_cell_cordinator: "",
  date_joined_home_cell: "",
  membership_date: "",
  current_ministry: "",
  date_joined_current_ministry: "",
  role_in_current_ministry: "",
  gifts_and_talents: "",
  ministry_preference: "",
  salvation_church: ""
};

const createMembersSlice = set => ({
  memberData: initialMemberData,
  setMemberData: memberFormData =>
    set(({ memberData }) => ({
      memberData: { ...memberData, ...memberFormData }
    })),
  resetAddMemberForm: () =>
    set({
      memberData: initialMemberData
    }),
  setProfileImage: image =>
    set(state => ({
      memberData: { ...state.memberData, profile_image: image }
    })),
  setIdImage: image =>
    set(state => ({
      memberData: { ...state.memberData, id_image: image }
    })),
  setLetterImage: image =>
    set(state => ({
      memberData: { ...state.memberData, letter_of_resignation: image }
    }))
});

const memberOperationsSlice = set => ({
  addTeamModalOpen: false,
  memberToAssignTeam: "",
  queries: {},
  terminateMemberModalOpen: false,
  exportModalOpen: false,
  importModalOpen: false,
  applicationFormModalOpen: false,
  spouseMatchedModalOpen: false,
  matchedSpouseData: null,
  currentTab: TAB_NAMES.APPLICATION_REQUESTS,
  openAddTeamModal: () => set({ addTeamModalOpen: true }),
  closeAddTeamModal: () => set({ addTeamModalOpen: false }),
  openTerminateMemberModal: () => set({ terminateMemberModalOpen: true }),
  closeTerminateMemberModal: () => set({ terminateMemberModalOpen: false }),
  openSpouseMatchedModal: () => set({ spouseMatchedModalOpen: true }),
  closeSpouseMatchedModal: () => set({ spouseMatchedModalOpen: false }),
  setMatchedSpouseData: matchedSpouseData => set({ matchedSpouseData }),
  setMemberToAssignTeam: memberId =>
    set({
      memberToAssignTeam: memberId
    }),
  setQueries: queries =>
    set({
      queries
    }),
  openExportModal: () => set({ exportModalOpen: true }),
  closeExportModal: () => set({ exportModalOpen: false }),
  openImportModal: () => set({ importModalOpen: true }),
  closeImportModal: () => set({ importModalOpen: false }),
  openApplicationFormModal: () => set({ applicationFormModalOpen: true }),
  closeApplicationFormModal: () => set({ applicationFormModalOpen: false }),
  setCurrentTab: tab => set({ currentTab: tab })
});

const createMemberPreviewSlice = set => ({
  fullMemberData: null,
  memberPreviewOpen: false,
  memberPreviewData: null,
  setMemberPreviewData: memberData =>
    set({ memberPreviewData: memberData, memberPreviewOpen: true }),
  setFullMemberData: memberData => set({ fullMemberData: memberData }),
  closeMemberPreview: () =>
    set({ memberPreviewData: null, memberPreviewOpen: false })
});

const createPartnersSlice = set => ({
  addPartnerModalOpen: false,
  updatePartnerModalOpen: false,
  partnerPreviewData: null,
  partnerPreviewOpen: false,
  setPartnerPreviewData: partnerData =>
    set({ partnerPreviewData: partnerData, partnerPreviewOpen: true }),
  openAddPartnerModal: () => set({ addPartnerModalOpen: true }),
  closeAddPartnerModal: () => set({ addPartnerModalOpen: false }),
  openUpdatePartnerModal: () => set({ updatePartnerModalOpen: true }),
  closeUpdatePartnerModal: () => set({ updatePartnerModalOpen: false }),
  closePartnerPreview: () =>
    set({ partnerPreviewData: null, partnerPreviewOpen: false })
});

const createKidSlice = set => ({
  kidData: null,
  setKidData: kidData => set({ kidData })
});

const columnVisibilitySlice = set => ({
  columnsVisibilityModalOpen: false,
  columnsVisible: {
    gender: true,
    team: true,
    email: true,
    operation: true,
    ...extraMemberColumnsVisibility
  },
  kidColumnsVisible: {
    gender: true,
    phone: true,
    operation: true,
    ...extraKidColumnsVisibility
  },
  partnerColumnsVisible: {
    gender: true,
    phone: true,
    email: true,
    operation: true,
    ...extraPartnerColumnsVisibility
  },
  openColumnsVisibilityModal: () => set({ columnsVisibilityModalOpen: true }),
  closeColumnsVisibilityModal: () => set({ columnsVisibilityModalOpen: false }),
  setColumnsVisibility: columnsVisible =>
    set({
      columnsVisible
    }),
  setColumnVisibility: (tab, column, isVisible) => {
    if (TAB_TO_TABLE_NAMES[tab] === TABLES.MEMBERS) {
      return set(prevState => ({
        columnsVisible: {
          ...prevState.columnsVisible,
          [column]: isVisible
        }
      }));
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.KIDS) {
      return set(prevState => ({
        kidColumnsVisible: {
          ...prevState.kidColumnsVisible,
          [column]: isVisible
        }
      }));
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.PARTNERS) {
      return set(prevState => ({
        partnerColumnsVisible: {
          ...prevState.partnerColumnsVisible,
          [column]: isVisible
        }
      }));
    }
  },
  setAllColumnsVisible: tab => {
    if (TAB_TO_TABLE_NAMES[tab] === TABLES.MEMBERS) {
      const allShownColumns = Object.assign(
        {},
        extraMemberColumnsVisibility,
        Object.fromEntries(
          Object.keys(extraMemberColumnsVisibility).map(key => [key, true])
        )
      );

      return set({
        columnsVisible: {
          gender: true,
          team: true,
          email: true,
          operation: true,
          ...allShownColumns
        }
      });
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.KIDS) {
      const allShownColumns = Object.assign(
        {},
        extraKidColumnsVisibility,
        Object.fromEntries(
          Object.keys(extraKidColumnsVisibility).map(key => [key, true])
        )
      );

      return set({
        kidColumnsVisible: {
          gender: true,
          phone: true,
          operation: true,
          ...allShownColumns
        }
      });
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.PARTNERS) {
      const allShownColumns = Object.assign(
        {},
        extraPartnerColumnsVisibility,
        Object.fromEntries(
          Object.keys(extraPartnerColumnsVisibility).map(key => [key, true])
        )
      );

      return set({
        partnerColumnsVisible: {
          gender: true,
          phone: true,
          email: true,
          operation: true,
          ...allShownColumns
        }
      });
    }
  },
  resetColumnsVisibility: tab => {
    if (TAB_TO_TABLE_NAMES[tab] === TABLES.MEMBERS) {
      return set({
        columnsVisible: {
          gender: true,
          team: true,
          email: true,
          operation: true,
          ...extraMemberColumnsVisibility
        }
      });
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.KIDS) {
      return set({
        kidColumnsVisible: {
          gender: true,
          phone: true,
          operation: true,
          ...extraKidColumnsVisibility
        }
      });
    }

    if (TAB_TO_TABLE_NAMES[tab] === TABLES.PARTNERS) {
      return set({
        partnerColumnsVisible: {
          gender: true,
          phone: true,
          email: true,
          operation: true,
          ...extraPartnerColumnsVisibility
        }
      });
    }
  }
});

export const useMembersStore = create(
  devtools(
    (...a) => ({
      ...createMembersSlice(...a),
      ...createMemberPreviewSlice(...a),
      ...createPartnersSlice(...a),
      ...memberOperationsSlice(...a),
      ...createKidSlice(...a),
      ...columnVisibilitySlice(...a)
    }),
    {
      name: "members-store"
    }
  )
);
