import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const deleteExpense = ({ churchId, expenseId }) => {
  return axios.delete(`/churches/${churchId}/expenses/${expenseId}`);
};

export const useDeleteExpense = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closeUpdateExpenseModal =
    useExpenseStore.getState().closeUpdateExpenseModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["expenses"]);
      setNotification({
        type: "success",
        message: "Expense deleted successfully!"
      });
      closeDeleteModal();
      closeUpdateExpenseModal();
    },
    ...config,
    mutationFn: deleteExpense
  });
};
