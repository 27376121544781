import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getWoredas = ({ subcityId }) => {
  return axios.get(`/addresses/sub_cities/${subcityId}/woredas`);
};

export const useWoredas = ({ subcityId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["woredas", subcityId],
    queryFn: () => getWoredas({ subcityId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
