import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useExpenseTypes } from "../api";
import { CreateExpenseTypeModal, UpdateExpenseTypeModal } from "../components";
import { useExpenseStore } from "../store";

const Container = styled.div`
  padding-block: 38px;
  display: grid;
  grid-template-columns: 206px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "menu content";
  gap: 16px;
`;

const Menu = styled.div`
  min-height: 100vh;
  border-radius: 4px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
`;

const MenuTitle = styled.div`
  border-bottom: 1px solid #cbd5e1;
  background: #e6f7f8;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 13px;
`;

const MenuItems = styled.div`
  flex: 1;
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 12px;
`;

const MenuItem = styled.div`
  padding: 10px 16px;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.palette.primary.main : "transparent"};
  border-radius: 4px;
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "#000"};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 700px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;

  th {
    padding-bottom: 14px;
    border-bottom: 1px solid #d4d4d4;
  }

  & td {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
  }
`;

export const ExpenseSettings = () => {
  const [activeTab, setActiveTab] = useState("type");
  const [expenseType, setExpenseType] = useState(null);
  const openCreateExpenseTypeModal = useExpenseStore(
    state => state.openCreateExpenseTypeModal
  );
  const openUpdateExpenseTypeModal = useExpenseStore(
    state => state.openUpdateExpenseTypeModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const expenseTypesQuery = useExpenseTypes({
    churchId: selectedChurch?.id
  });

  console.log(expenseType);

  return (
    <Container>
      <CreateExpenseTypeModal />
      <UpdateExpenseTypeModal data={expenseType} />
      <Notification />
      <Menu>
        <MenuTitle>Expense Setting</MenuTitle>
        <MenuItems>
          <MenuItem
            active={activeTab === "type"}
            onClick={() => setActiveTab("type")}
          >
            Expense Type
          </MenuItem>
        </MenuItems>
      </Menu>
      <Content>
        <Stack alignItems="flex-end">
          {activeTab === "type" && (
            <Button
              style={{
                fontSize: "14px"
              }}
              onClick={openCreateExpenseTypeModal}
            >
              Create Expense Type
            </Button>
          )}
        </Stack>
        <TableContainer>
          {activeTab === "type" && (
            <Table>
              <thead>
                <tr>
                  <th>Type Name</th>
                  <th>Description type</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {expenseTypesQuery.data?.result?.map(expenseType => (
                  <tr key={expenseType.id}>
                    <td>{expenseType.name}</td>
                    <td>{expenseType.description}</td>
                    <td>
                      <Button
                        style={{
                          fontSize: "12px"
                        }}
                        onClick={() => {
                          setExpenseType(expenseType);
                          openUpdateExpenseTypeModal();
                        }}
                      >
                        Update
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
      </Content>
    </Container>
  );
};
