import { Box, Stack } from "@mui/material";
import { Radio } from "components/UI";
import { useChurchStore } from "features/churches";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAssignTeamMember } from "../api";

export function TeamMembersTableDropdownMenu({
  memberId,
  memberPosition,
  handleClose
}) {
  const { id } = useParams();
  const assignMutation = useAssignTeamMember();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  const assignPosition = position => {
    assignMutation.mutate(
      {
        churchId: selectedChurch?.id,
        teamId: id,
        memberId: memberId,
        memberData: {
          role: position
        }
      },
      {
        onSuccess: () => {
          handleClose();
        }
      }
    );
  };

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        {t("teams.assignLeaders.AssignLeaders")}
      </Box>
      <Stack
        sx={{
          minWidth: "168px",
          minHeight: "120px",
          backgroundColor: "white",
          p: 2
        }}
        justifyContent="space-between"
      >
        <Radio
          isLabelEmpty={true}
          name="role"
          buttons={[
            {
              radioLabel: t("teams.assignLeaders.Member"),
              value: "MEMBER",
              id: "member",
              onChange: val => assignPosition(val.target.value),
              checked: memberPosition === "MEMBER"
            },
            {
              radioLabel: t("teams.assignLeaders.Leader"),
              value: "LEADER",
              id: "leader",
              onChange: val => assignPosition(val.target.value),
              checked: memberPosition === "LEADER"
            },
            {
              radioLabel: t("teams.assignLeaders.ViceLeader"),
              value: "VICE_LEADER",
              id: "vice leader",
              onChange: val => assignPosition(val.target.value),
              checked: memberPosition === "VICE_LEADER"
            },
            {
              radioLabel: t("teams.assignLeaders.Secretary"),
              value: "SECRETARY",
              id: "secretary",
              onChange: val => assignPosition(val.target.value),
              checked: memberPosition === "SECRETARY"
            }
          ]}
          row={false}
          register={() => {}}
        />
      </Stack>
    </Box>
  );
}
