import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useTeamsStore } from "../stores";

export const createTeam = ({ churchId, teamData }) => {
  return axios.post(`/churches/${churchId}/teams`, teamData);
};

export const useCreateTeam = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateTeamModal = useTeamsStore.getState().closeCreateTeamModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("teams");
      queryClient.invalidateQueries("team_types");
      setNotification({
        type: "success",
        message: "Team created successfully!"
      });
      closeCreateTeamModal();
    },
    ...config,
    mutationFn: createTeam
  });
};
