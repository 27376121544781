const extraColumns = ["address", "birth_date"];
const uniqueExtraColumns = [...new Set(extraColumns)];

export const extraPartnerColumns = uniqueExtraColumns.map(column => ({
  field: column,
  headerName: column
    .replaceAll("_", " ")
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" "),
  flex: 1,
  minWidth: 120
}));

export const extraPartnerColumnsVisibility = {};
extraPartnerColumns.forEach(({ field }) => {
  extraPartnerColumnsVisibility[field] = false;
});

export const excludePartnerColumns = ["operation"];

const MAX_NUMBER_OF_COLUMNS_THRESHOLD = 7;
export const isExceedingThreshold = (
  columnsVisibility,
  maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS_THRESHOLD
) => {
  return (
    Object.values(columnsVisibility).filter(Boolean).length > maxNumberOfColumns
  );
};
