import styled from "@emotion/styled";
import { InputAdornment, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  DotsThree,
  Funnel,
  GearSix,
  MagnifyingGlass
} from "@phosphor-icons/react";
import { Button, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { useRevenueStore, useRevenuesPaginated } from "features/finances";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

const PrimaryActions = styled.div`
  display: flex;
  gap: 8px;
`;

const SecondaryActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchIcon = styled(MagnifyingGlass)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const FilterIcon = styled(Funnel)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const RevenueSettingButton = styled.button`
  outline: none;
  border: none;
  background: none;
  color: #789;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-weight: 500;
`;

const MoreSettingButton = styled.button`
  outline: none;
  border: 1px solid #c0c8d0;
  background: none;
  cursor: pointer;
  width: 32px;
  border-radius: 2px;
`;

export const RevenueTable = ({ onEdit }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const openRecordRevenueModal = useRevenueStore(
    state => state.openRecordRevenueModal
  );
  const openUpdateRevenueModal = useRevenueStore(
    state => state.openUpdateRevenueModal
  );
  const navigate = useNavigate();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const revenueQuery = useRevenuesPaginated({
    churchId: selectedChurch?.id,
    page,
    pageSize
  });

  const columns = [
    {
      field: "id",
      headerName: "Revenue ID",
      flex: 1
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span>
            {row.full_name
              ? row.full_name
              : `${row.member?.first_name} ${row.member?.last_name}`}
          </span>
        );
      }
    },
    {
      field: "receipt_number",
      headerName: "Receipt Number",
      flex: 1
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: ({ row }) => {
        return <span>{row.revenue_type.name}</span>;
      }
    },
    {
      field: "method",
      headerName: "Method",
      flex: 1,
      renderCell: ({ row }) => {
        return <span>{row.revenue_method.name}</span>;
      }
    },
    {
      field: "date",
      headerName: "Transaction date",
      flex: 1,
      renderCell: ({ row }) => {
        return <span>{moment(row.received_date).format("DD-MM-YYYY")}</span>;
      }
    }
  ];

  return (
    <Container>
      <Stack alignItems="flex-start" spacing={4}>
        <Header>
          <Title>Revenue Table</Title>
          <PrimaryActions>
            <RevenueSettingButton
              onClick={() => navigate("/finances/temp/revenue/settings")}
            >
              <GearSix size={16} />
              <span>Revenue Setting</span>
            </RevenueSettingButton>
            <Button onClick={openRecordRevenueModal}>Record Revenue</Button>
            <MoreSettingButton>
              <DotsThree size={16} />
            </MoreSettingButton>
          </PrimaryActions>
        </Header>
        <SecondaryActions>
          <TextField
            neutral={1}
            placeholder="Search revenue"
            register={() => {}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={16} />
                </InputAdornment>
              )
            }}
            autoComplete="off"
          />
          <FilterIcon size={16} />
        </SecondaryActions>
      </Stack>
      <DataGrid
        autoHeight
        rows={revenueQuery.data?.result?.data || []}
        rowCount={revenueQuery.data?.result?.total || 0}
        loading={revenueQuery.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
        onRowClick={({ row }) => {
          onEdit(row);
          openUpdateRevenueModal();
        }}
      />
    </Container>
  );
};
