import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { Avatar, Modal } from "@mui/material";
import { IMAGE_URL } from "config";
import { useTranslation } from "react-i18next";
import { useMessagesStore } from "../store";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: 10px;
  height: 557px;
  min-width: 448px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Header = styled.div`
  padding: 30px;
  background: #e6e6e6;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Content = styled.div`
  background: white;
  padding: 48px 68px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;

  h4 {
    all: unset;
  }
`;

const CloseButton = styled.div`
  background: ${({ theme }) => theme.palette.neutral.main};
  color: ${({ theme }) => theme.palette.error.main};
  border-radius: 50%;
  min-height: 23px;
  min-width: 23px;
  display: grid;
  place-items: center;
  margin-left: auto;
  cursor: pointer;
`;

const Info = styled.div`
  padding: 30px;
  background: white;
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
`;

export const SelectedRecipientsModal = ({
  recipientsOpen,
  setRecipientsOpen
}) => {
  const recipients = useMessagesStore(state => state.recipients);
  const removeRecipient = useMessagesStore(state => state.removeRecipient);
  const { t } = useTranslation();

  return (
    <Modal open={recipientsOpen} onClose={() => setRecipientsOpen(false)}>
      <Container>
        <Header>
          <h4>{t("message.newMessage.SelectedMembers")}</h4>
        </Header>
        <Content>
          {[...recipients.values()].map(selectedContact => (
            <Contact key={selectedContact.id}>
              <Avatar
                src={`${IMAGE_URL}/${selectedContact.image_url}`}
                alt={selectedContact.name}
                sx={{ width: 44, height: 44 }}
              />
              <h4>
                {selectedContact.first_name
                  ? `${selectedContact.first_name} ${selectedContact.last_name}`
                  : selectedContact.name}
              </h4>
              <CloseButton
                onClick={() => {
                  removeRecipient(
                    `${selectedContact.id}_${selectedContact.first_name}_${selectedContact.last_name}`
                  );
                  removeRecipient(
                    `${selectedContact.id}_${selectedContact.name}`
                  );
                }}
              >
                <Close sx={{ fontSize: 14 }} />
              </CloseButton>
            </Contact>
          ))}
        </Content>
        <Info>
          <h4>
            {recipients.size} {t("message.newMessage.Selected")}
          </h4>
        </Info>
      </Container>
    </Modal>
  );
};
