import { Grid, Typography } from "@mui/material";
import { Radio, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const UnionInfoSection = ({ register, errors, watch, control }) => {
  const { t } = useTranslation();

  return (
    <Grid
      direction={{
        xs: "column",
        md: "row"
      }}
      container
      columnSpacing={12}
    >
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step5.UnionInformation")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2} columns={{ xs: 2, md: 12 }}>
        <Grid item xs={2} md={12}>
          <Radio
            label={t("addMembers.step5.IsThereHomeCellInYourChurch")}
            name="is_home_cell"
            buttons={[
              {
                radioLabel: t("form.common.yes"),
                value: "1",
                id: "home_cell_yes"
              },
              {
                radioLabel: t("form.common.no"),
                value: "0",
                id: "home_cell_no"
              }
            ]}
            row={true}
            register={register}
            error={!!errors.is_home_cell?.message}
            helperText={errors.is_home_cell?.message}
          />
        </Grid>
        {watch("is_home_cell") === "1" && (
          <>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("addMembers.step5.HomeCellYouEngagedIn")}
                name="home_cell_name"
                error={!!errors.home_cell_name?.message}
                helperText={errors.home_cell_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <TextField
                label={t("addMembers.step5.HomeCellCoordinator")}
                name="home_cell_cordinator"
                error={!!errors.home_cell_cordinator?.message}
                helperText={errors.home_cell_cordinator?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <Controller
                name="date_joined_home_cell"
                control={control}
                render={({ field }) => (
                  <DateField
                    label={t("addMembers.step5.DateYouStartEngaging")}
                    name="date_joined_home_cell"
                    error={!!errors.date_joined_home_cell?.message}
                    helperText={errors.date_joined_home_cell?.message}
                    {...field}
                    disableFutureDates
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
