import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getRoles = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/roles`);
};

export const useRoles = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["roles", churchId],
    queryFn: () => getRoles({ churchId })
  });
};
