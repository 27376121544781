import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { XCircle } from "@phosphor-icons/react";
import { Button } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useExpenseStore, useUpdateSettlement } from "features/finances";
import { useFileUpload } from "hooks";
import { has } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const PhotoPreview = styled.div`
  width: 158px;
  height: 192px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f1f5f9;
  padding: 16px;
`;

const Photo = styled.div`
  position: relative;
`;

const PhotoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CloseIcon = styled(XCircle)`
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
`;

const SmallText = styled.span`
  color: #f54e49;
  font-size: 10px;
  font-weight: 500;
`;

const settlementSchema = yup.object({
  reason: yup.string(),
  amount: yup.number()
});

export function UpdateAdvanceSettlementModal({ data }) {
  const updateAdvanceSettlementOpen = useExpenseStore(
    state => state.updateAdvanceSettlementOpen
  );
  const closeUpdateAdvanceSettlementModal = useExpenseStore(
    state => state.closeUpdateAdvanceSettlementModal
  );
  const updateSettlementMutation = useUpdateSettlement();
  const [selectedFile, openFileDialog, resetFile] = useFileUpload();
  const [photoPreview, setPhotoPreview] = useState("");
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { id } = useParams();

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      reason: data?.reason,
      amount: data?.amount
    },
    resolver: yupResolver(settlementSchema)
  });

  useEffect(() => {
    if (data) {
      setValue("reason", data?.reason);
      setValue("amount", data?.amount);
    }
  }, [data]);

  useEffect(() => {
    if (selectedFile) {
      setPhotoPreview(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  const convertObjectToFormData = object => {
    const formData = new FormData();

    for (const key in object) {
      if (has(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };

  const onSubmit = values => {
    const settlementData = {
      reason: values.reason,
      amount: values.amount
    };

    if (selectedFile) {
      settlementData["receipt_photo"] = selectedFile;
    }

    const settlementFormData = convertObjectToFormData(settlementData);

    updateSettlementMutation.mutate(
      {
        churchId: selectedChurch?.id,
        expenseId: id,
        settlementId: data?.id,
        settlementData: settlementFormData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Expense report"
      isOpen={updateAdvanceSettlementOpen}
      onClose={closeUpdateAdvanceSettlementModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeUpdateAdvanceSettlementModal}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Save changes
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="reason">Reason</Label>
        <Input id="reason" {...register("reason")} />
      </Field>
      <Field>
        <Label htmlFor="amount">Amount</Label>
        <Input id="amount" {...register("amount")} />
      </Field>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Label htmlFor="uploadPhoto">Receipt Photo</Label>
        <Button id="uploadPhoto" flavor="outlined" onClick={openFileDialog}>
          Upload photo
        </Button>
      </Stack>
      {photoPreview ||
        (data?.receipt_photo && (
          <PhotoPreview>
            <Photo>
              <PhotoImage
                src={photoPreview || `${IMAGE_URL}/${data?.receipt_photo}`}
                alt=""
              />
              {photoPreview && (
                <CloseIcon
                  size={16}
                  weight="fill"
                  color="#F87171"
                  onClick={() => {
                    resetFile();
                    setPhotoPreview("");
                  }}
                />
              )}
            </Photo>
          </PhotoPreview>
        ))}
      {data?.reason_for_rejection && (
        <Field>
          <Label htmlFor="reasonForRejection">Reason for Rejection</Label>
          <SmallText>{data?.reason_for_rejection}</SmallText>
        </Field>
      )}
    </BaseModal>
  );
}
