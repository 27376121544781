import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getRevenuesPaginated = ({ churchId, page, pageSize }) => {
  return axios.get(
    `/churches/${churchId}/revenue-paginated?page=${page}&per_page=${pageSize}`
  );
};

export const useRevenuesPaginated = ({
  churchId,
  page,
  pageSize,
  config
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["revenues", churchId, page, pageSize],
    queryFn: () => getRevenuesPaginated({ churchId, page, pageSize })
  });
};
