import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const createChartOfAccount = ({ churchId, chartOfAccountData }) => {
  return axios.post(
    `/churches/${churchId}/chart-of-accounts`,
    chartOfAccountData
  );
};

export const useCreateChartOfAccount = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateChartOfAccountModal =
    useFinanceSettingsStore.getState().closeCreateChartOfAccountModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("chart-accounts");
      setNotification({
        type: "success",
        message: "Chart of account created successfully!"
      });
      closeCreateChartOfAccountModal();
    },
    ...config,
    mutationFn: createChartOfAccount
  });
};
