import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import _ from "lodash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${({ containerStyle }) => containerStyle}
`;

// const Label = styled.label`
//   font-weight: ${props => (props.labelBold ? "bold" : "normal")};
//   visibility: ${props => (props.empty ? "hidden" : "visible")};
// `;

const CheckboxButtons = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${props => `repeat(${props.cols}, 1fr)`};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }

  a {
    color: inherit;
  }
`;

const HelperText = styled.span`
  padding: 0 0.75rem;
  font-size: 0.75rem;
  color: ${props =>
    props.error ? props.theme.palette.error.main : "darkgray"};
`;

const CheckboxInput = styled.input`
  appearance: none;
  --webkit-appearance: none;
  border: 0.125rem solid #3dadb7;
  border-radius: 0.25rem;
  height: 1.2rem;
  width: 1.2rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  cursor: pointer;

  &:checked::before {
    content: "✓";
    display: block;
    font-size: 1em;
    color: #3dadb7;
  }

  &:checked {
    background: #3dadb766;
  }
`;

export const Checkbox = ({
  label,
  buttons,
  isLabelBold,
  isLabelEmpty,
  name,
  error,
  helperText,
  register,
  cols,
  containerStyle
}) => {
  return (
    <Container containerStyle={containerStyle}>
      {!isLabelEmpty && (
        <Typography
          variant="subtitle2"
          component="label"
          htmlFor={name}
          sx={{ fontWeight: isLabelBold && 700 }}
        >
          {label}
        </Typography>
      )}
      {/* <Label htmlFor={name} labelBold={isLabelBold} empty={isLabelEmpty}>
        {label}
      </Label> */}
      <CheckboxButtons cols={cols}>
        {buttons.map(({ value, checkboxLabel, ...rest }) => (
          <CheckboxWrapper key={value}>
            <CheckboxInput
              id={rest.id}
              type="checkbox"
              name={name}
              value={value}
              {...register(name)}
              {...rest}
            />
            <label
              htmlFor={rest.id}
              dangerouslySetInnerHTML={{ __html: checkboxLabel }}
            />
          </CheckboxWrapper>
        ))}
      </CheckboxButtons>
      <HelperText error={error}>{_.capitalize(helperText)}</HelperText>
    </Container>
  );
};
