import { Box } from "@mui/material";
import { Alert, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import { useTeamTypes, useTeams } from "../api";
import {
  CreateTeamModal,
  CreateTeamTypesModal,
  EmptyTeams,
  TeamsView
} from "../components";

export function Teams() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const teamsQuery = useTeams({
    churchId: selectedChurch?.id
  });
  const teamTypesQuery = useTeamTypes({
    churchId: selectedChurch?.id
  });
  const { t } = useTranslation();

  useTitle("Teams");

  useEffect(() => {
    setHeaderTitle(t("sidebar.teams"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  let teamsView;
  if (teamsQuery?.data?.result?.length > 0) {
    teamsView = <TeamsView teamTypes={teamTypesQuery?.data?.result} />;
  } else {
    teamsView = <EmptyTeams />;
  }

  return (
    <Box sx={{ pt: 3 }}>
      <Alert message={"dashboard.common.demoMessage"} />
      <Notification />
      <CreateTeamModal />
      <CreateTeamTypesModal />
      {teamsView}
    </Box>
  );
}
