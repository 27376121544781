import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPublicSubcities = ({ cityId }) => {
  return axios.get(`/public/addresses/cities/${cityId}/sub_cities`);
};

export const usePublicSubcities = ({ cityId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["public-subcities", cityId],
    queryFn: () => getPublicSubcities({ cityId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
