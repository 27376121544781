import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const updateMember = ({ churchId, memberData, memberId }) => {
  return axios.post(
    `/churches/${churchId}/members/${memberId}?_method=PATCH`,
    memberData
  );
};

export const useUpdateMember = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const setFullMemberData = useMembersStore.getState().setFullMemberData;
  const setProfileImage = useMembersStore.getState().setProfileImage;
  const setIdImage = useMembersStore.getState().setIdImage;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: data => {
      setFullMemberData(data.result);
      queryClient.invalidateQueries(["member", `${data.result.id}`]);
      queryClient.invalidateQueries(["members"]);
      setNotification({
        type: "success",
        message: "Member updated successfully!"
      });
      setProfileImage(null);
      setIdImage(null);
      navigate("/members");
    },
    ...config,
    mutationFn: updateMember
  });
};
