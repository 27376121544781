import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Container = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 16px;
`;

const Item = styled.li`
  padding: 8px 16px;
  background: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "#F1F3F5"};
  color: ${({ active }) => (active ? "white" : "#92A0AD")};
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  cursor: pointer;
`;

export const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {tabs.map(tabItem => (
        <Item
          key={tabItem.name}
          active={tabItem.name === activeTab}
          onClick={() => setActiveTab(tabItem.name)}
        >
          {t(tabItem.translation)}
        </Item>
      ))}
    </Container>
  );
};
