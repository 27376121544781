// Get three names from a list of addresses
export const getAddressNames = addresses => {
  let names = [];

  for (const value of Object.values(addresses)) {
    names = [
      ...names,
      ...value.map(address => address.first_name || address.name)
    ];
  }

  if (names.length > 3) {
    return [...names.slice(0, 3), "..."].join(", ");
  }

  return names.slice(0, 3).join(", ");
};
