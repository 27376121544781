import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Badge,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { IMAGE_URL } from "config";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDenominationStore } from "../store";

export function DenominationThumbnail({
  id,
  image,
  name,
  denomination,
  ...props
}) {
  const selectedDenomination = useDenominationStore(
    state => state.selectedDenomination
  );
  const selectDenomination = useDenominationStore(
    state => state.selectDenomination
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      alignItems="center"
      spacing={1}
      {...props}
      sx={{ overflow: "hidden", cursor: "pointer" }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "black"
            }}
            onClick={e => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        }
      >
        <Avatar
          sx={{
            width: {
              xs: 50,
              sm: 80,
              lg: 100
            },
            height: {
              xs: 50,
              sm: 80,
              lg: 100
            },
            border: `${
              !_.isNil(selectedDenomination) &&
              _.isEqual(id, selectedDenomination.id)
                ? "5px solid"
                : ""
            }`,
            borderColor: theme => theme.palette.primary.main
          }}
          src={`${IMAGE_URL}/${image}`}
          alt="Image"
        />
      </Badge>
      <Typography
        noWrap={true}
        sx={{
          textAlign: "center",
          fontWeight: `${
            !_.isNil(selectedDenomination) &&
            _.isEqual(id, selectedDenomination.id)
              ? 700
              : 500
          }`
        }}
      >
        {name}
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Typography
          onClick={() => {
            selectDenomination(denomination);
            navigate(`/denominations/${id}`);
          }}
          sx={{
            py: 1,
            px: 2,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#EEEEEE"
            }
          }}
        >
          {t("denominations.createDenomination.denominationProfile")}
        </Typography>
      </Popover>
    </Stack>
  );
}
