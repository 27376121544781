import { Stack } from "@mui/material";
import { TextField } from "components/UI";
import { useTranslation } from "react-i18next";

export const BasicInfoForm = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <TextField
        label={t("Asset.Asset.AssetName")}
        name="name"
        error={!!errors.name?.message}
        helperText={errors.name?.message}
        register={register}
      />
      <TextField
        label={t("Asset.Asset.AssetType")}
        name="type"
        error={!!errors.type?.message}
        helperText={errors.type?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Qualitative Description")}
        name="description"
        error={!!errors.qualitative_description?.message}
        helperText={errors.qualitative_description?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Quantity")}
        name="quantity"
        error={!!errors.quantity?.message}
        helperText={errors.quantity?.message}
        register={register}
      />
    </Stack>
  );
};
