import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import {
  BackgroundInputField,
  Button,
  Loading,
  Notification
} from "components/UI";
import Fuse from "fuse.js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import { useDenominations } from "../api";
import { DenominationThumbnail } from "../components";
import { useDenominationStore } from "../store";

const GridWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export function Denominations() {
  const denominationsQuery = useDenominations();
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const selectedDenomination = useDenominationStore(
    state => state.selectedDenomination
  );
  const selectDenomination = useDenominationStore(
    state => state.selectDenomination
  );
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [denominationsList, setDenominationsList] = useState([]);
  const [filteredDenominationsList, setFilteredDenominationsList] = useState(
    []
  );
  const { t } = useTranslation();

  useTitle("Denominations");

  useEffect(() => {
    setHeaderTitle("");

    return () => setHeaderTitle("");
  }, []);

  useEffect(() => {
    selectDenomination(null);
  }, []);

  useEffect(() => {
    if (denominationsQuery.isSuccess) {
      setDenominationsList(denominationsQuery.data.result);
    }

    return () => setDenominationsList([]);
  }, [denominationsQuery.isSuccess, denominationsQuery.data]);

  useEffect(() => {
    if (denominationsQuery.isSuccess) {
      setFilteredDenominationsList(denominationsQuery.data.result);
    }

    return () => setFilteredDenominationsList([]);
  }, [denominationsQuery.isSuccess, denominationsQuery.data]);

  const searchDenomination = () => {
    const fuse = new Fuse(denominationsList, {
      threshold: 0.3,
      keys: ["name"]
    });

    if (_.isEmpty(searchTerm)) {
      setFilteredDenominationsList(denominationsList);
      return;
    }

    const searchResults = fuse.search(searchTerm);

    if (_.isEmpty(searchResults)) {
      setFilteredDenominationsList([]);
    } else {
      setFilteredDenominationsList([
        ..._.map(fuse.search(searchTerm), foundTerm => foundTerm.item)
      ]);
    }
  };

  return (
    <Box py={{ xs: 4, lg: 6 }} sx={{ my: "auto" }}>
      <Notification />
      <Stack
        alignItems="center"
        textAlign="center"
        spacing={4}
        px={{ xs: 4, lg: 8 }}
      >
        <Stack spacing={3}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("denominations.denominations.welcome")}, {authUser()?.first_name}{" "}
            {authUser()?.last_name}
          </Typography>
          <Typography variant="body2">
            {t("denominations.denominations.welcomeDescription")}
          </Typography>
          <Stack justifyContent="center" direction="row">
            <BackgroundInputField
              placeholder={t("denominations.denominations.searchDenomination")}
              sx={{
                backgroundColor: "neutral.main",
                width: { xs: "100%", lg: "400px" }
              }}
              neutral={1}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              onKeyUp={searchDenomination}
              inputProps={{ "aria-label": "search" }}
              autoComplete="off"
            />
            <Button
              variant="contained"
              endIcon={<Search />}
              onClick={searchDenomination}
            >
              {t("denominations.denominations.search")}
            </Button>
          </Stack>
          <Typography sx={{ fontWeight: 500 }}>
            {t("denominations.denominations.selectDescription")}
          </Typography>
        </Stack>
        {denominationsQuery.isLoading ? (
          <Loading />
        ) : (
          <GridWrapper>
            {filteredDenominationsList.map(denomination => (
              <DenominationThumbnail
                key={denomination.id}
                id={denomination.id}
                image={denomination.image_url}
                name={denomination.name}
                denomination={denomination}
                onClick={() => selectDenomination(denomination)}
              />
            ))}
          </GridWrapper>
        )}
        <Stack
          justifyContent="flex-end"
          sx={{ width: "100%" }}
          direction="row"
          spacing={2}
        >
          <Button
            size="large"
            variant="contained"
            flavor="secondary"
            onClick={() => navigate("/churches/create")}
          >
            {t("denominations.denominations.skip")}
          </Button>
          <Button
            size="large"
            variant="contained"
            flavor="primary"
            disabled={_.isNil(selectedDenomination)}
            onClick={() => navigate("/churches/create")}
          >
            {t("denominations.denominations.next")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
