import { matchIsValidTel } from "mui-tel-input";
import * as yup from "yup";

export const createDenominationSchema = yup.object().shape(
  {
    name: yup.string().required("Denomination name is required"),
    acronyms: yup.string().notRequired(),
    phone: yup.string().when("phone", {
      is: phone => !!phone,
      then: yup
        .string()
        .test("isPhoneValid", "Phone is not valid", value =>
          matchIsValidTel(value)
        ),
      otherwise: yup.string().notRequired()
    }),
    image: yup
      .mixed()
      .test(
        "required",
        "Denomination Logo is required",
        value => value && value.length
      )
      .test("imageSize", "Image file size must be less than 1 MB", value => {
        if (!value) return true;

        const imageSize = value[0].size / Math.pow(1024, 2);

        return imageSize <= 1;
      }),
    p_o_box: yup.string().when("p_o_box", {
      is: pobox => !!pobox,
      then: yup
        .string()
        .matches(/^[0-9]+$/, "Denomination pobox can't contain characters"),
      otherwise: yup.string().notRequired()
    }),
    country_id: yup.string().notRequired(),
    region_id: yup.string().notRequired(),
    zone_id: yup.string().notRequired(),
    city_id: yup.string().notRequired(),
    sub_city_id: yup.string().notRequired(),
    woreda_id: yup.string().notRequired(),
    kebele: yup.string().notRequired(),
    house_number: yup.string().notRequired(),
    specific_location: yup.string().notRequired(),
    description: yup.string().notRequired(),
    acceptTerms: yup.boolean().oneOf([true], "Terms and conditions is required")
  },
  [
    ["phone", "phone"],
    ["p_o_box", "p_o_box"]
  ]
);

export const updateDenominationSchema = yup.object().shape(
  {
    name: yup.string().required("Denomination name is required"),
    acronyms: yup.string().notRequired().nullable(true),
    phone: yup.string().when("phone", {
      is: phone => !!phone,
      then: yup
        .string()
        .test("isPhoneValid", "Phone is not valid", value =>
          matchIsValidTel(value)
        ),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    p_o_box: yup.string().when("p_o_box", {
      is: pobox => !!pobox,
      then: yup
        .string()
        .matches(/^[0-9]+$/, "Denomination pobox can't contain characters"),
      otherwise: yup.string().notRequired().nullable(true)
    }),
    country_id: yup.string().notRequired().nullable(true),
    region_id: yup.string().notRequired().nullable(true),
    zone_id: yup.string().notRequired().nullable(true),
    city_id: yup.string().notRequired().nullable(true),
    sub_city_id: yup.string().notRequired().nullable(true),
    woreda_id: yup.string().notRequired().nullable(true),
    kebele: yup.string().notRequired().nullable(true),
    house_number: yup.string().notRequired().nullable(true),
    specific_location: yup.string().notRequired().nullable(true),
    description: yup.string().notRequired().nullable(true)
  },
  [
    ["phone", "phone"],
    ["p_o_box", "p_o_box"]
  ]
);
