import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useApproveRequisition,
  useExpenseStore,
  useRejectRequisition
} from "features/finances";
import moment from "moment";
import { useState } from "react";
import { BaseModal } from "./BaseModal";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Amount = styled.div`
  border: 0.73px solid #b3b3b3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const AmountInput = styled.input`
  height: 40px;
  border: none;
  outline: none;
  padding-right: 8px;
  flex: 1;
`;

const BudgetInformation = styled.div`
  padding: 4px 8px;
  border-radius: 2px;
  background: #e6f7f8;
  font-weight: 600;
  font-size: 10px;
  min-width: 150px;
`;

const StatusContainer = styled.div`
  padding: 8px 16px;
  border: 0.73px solid #b3b3b3;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Status = styled.div`
  border-radius: 2px;
  background: ${({ color }) => color};
  color: #ffffff;
  padding: 4px 8px;
  text-transform: capitalize;
  font-size: 10px;
`;

const InformationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
`;

const Information = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    color: #778899;
    flex: 1;
  }
`;

export function ApproverViewModal({ data }) {
  const requisitionFlowModalOpen = useExpenseStore(
    state => state.requisitionFlowModalOpen
  );
  const closeRequisitionFlowModal = useExpenseStore(
    state => state.closeRequisitionFlowModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const approveMutation = useApproveRequisition();
  const rejectMutation = useRejectRequisition();
  const [isReasonActive, setIsReasonActive] = useState(false);
  const [reasonForRejection, setReasonForRejection] = useState("");

  const statusColors = {
    not_approved: "#8E8E8E",
    approved: "#50C40D",
    rejected: "#F4352F"
  };

  return (
    <BaseModal
      title="Requisition"
      isOpen={requisitionFlowModalOpen}
      onClose={closeRequisitionFlowModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {!isReasonActive && (
            <Button
              flavor="white"
              size="small"
              onClick={closeRequisitionFlowModal}
            >
              Cancel
            </Button>
          )}
          {isReasonActive && (
            <Button
              flavor="white"
              size="small"
              onClick={() => setIsReasonActive(false)}
            >
              Cancel
            </Button>
          )}
          {!isReasonActive && (
            <Button
              size="small"
              onClick={() =>
                approveMutation.mutate({
                  churchId: selectedChurch?.id,
                  requisitionId: data?.id,
                  requisitionData: {}
                })
              }
            >
              Approve
            </Button>
          )}
          {!isReasonActive && (
            <Button size="small" onClick={() => setIsReasonActive(true)}>
              Reject
            </Button>
          )}
          {isReasonActive && (
            <Button
              onClick={() =>
                rejectMutation.mutate({
                  churchId: selectedChurch?.id,
                  requisitionId: data?.id,
                  requisitionData: {
                    reason_for_rejection: reasonForRejection
                  }
                })
              }
            >
              Send
            </Button>
          )}
        </Stack>
      }
    >
      <Form>
        <Field>
          <Label htmlFor="fullName">Requested by</Label>
          <Input
            id="fullName"
            defaultValue={`${data?.user?.first_name} ${data?.user?.last_name}`}
            disabled
          />
        </Field>
        <Field>
          <Label htmlFor="fullName">Amount</Label>
          <Amount>
            <AmountInput defaultValue={data?.amount} disabled />
            <BudgetInformation>Available Budget: 0</BudgetInformation>
          </Amount>
        </Field>
        <Field>
          <Label htmlFor="fullName">Department</Label>
          <Input id="fullName" defaultValue={data?.team?.name} disabled />
        </Field>
        <Field>
          <Label htmlFor="fullName">Account</Label>
          <Input
            id="fullName"
            defaultValue={data?.chart_of_account?.account_name?.name}
            disabled
          />
        </Field>
        <Field>
          <Label htmlFor="fullName">Category</Label>
          <Input
            id="fullName"
            defaultValue={data?.expense_type?.name}
            disabled
          />
        </Field>
        <Field>
          <Label htmlFor="reason">Reason</Label>
          <Input id="reason" defaultValue={data?.reason} disabled />
        </Field>
        <Field>
          <Label>Status</Label>
          <StatusContainer>
            <Status
              color={statusColors[data?.requisition_status?.toLowerCase()]}
            >
              {data?.requisition_status}
            </Status>
            <InformationList>
              {data?.approvers?.map((approver, approverIndex) => (
                <Information key={approver.id}>
                  {approverIndex === 0 && <span>Current Approver</span>}
                  {approverIndex === 1 && <span>Next Approver</span>}
                  {approverIndex === 2 && <span>Final Approver</span>}
                  <strong>
                    {approver.approver?.first_name}{" "}
                    {approver.approver?.last_name}
                  </strong>
                </Information>
              ))}
            </InformationList>
          </StatusContainer>
        </Field>
        <Field>
          <Label htmlFor="fullName">Due date</Label>
          <Input
            id="fullName"
            type="date"
            defaultValue={moment(data?.due_date).format("YYYY-MM-DD")}
            disabled
          />
        </Field>
        {isReasonActive && (
          <Field>
            <Label htmlFor="reason">Reason for rejection</Label>
            <Input
              id="reason"
              value={reasonForRejection}
              onChange={e => setReasonForRejection(e.target.value)}
            />
          </Field>
        )}
      </Form>
    </BaseModal>
  );
}
