import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import _ from "lodash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const RadioButtons = styled.div`
  display: flex;
  flex-direction: ${props => (props.row ? "row" : "column")};
  gap: 1rem;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }
`;

const HelperText = styled.span`
  padding: 0 0.75rem;
  font-size: 0.75rem;
  color: ${props =>
    props.error ? props.theme.palette.error.main : "darkgray"};
`;

const RadioInput = styled.input`
  appearance: none;
  --webkit-appearance: none;
  border: 2px solid #3dadb7;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  cursor: pointer;

  &:checked::before {
    content: "";
    display: block;
    height: 80%;
    width: 80%;
    background-color: #3dadb7;
    border-radius: 50%;
  }
`;

export const Radio = ({
  label,
  buttons,
  isLabelBold,
  isLabelEmpty,
  name,
  error,
  helperText,
  row,
  register,
  required
}) => {
  return (
    <Container>
      {!isLabelEmpty && (
        <Typography
          variant="subtitle2"
          component="label"
          htmlFor={name}
          sx={{
            fontWeight: isLabelBold && 700,
            "&:after": {
              content: required ? '" *"' : '""',
              color: "red"
            }
          }}
        >
          {label}
        </Typography>
      )}
      <RadioButtons row={row}>
        {buttons.map(({ value, radioLabel, labelStyle, ...rest }) => (
          <RadioWrapper key={value}>
            <RadioInput
              id={rest.id}
              type="radio"
              name={name}
              value={value}
              {...register(name)}
              {...rest}
            />
            <label htmlFor={rest.id} style={labelStyle}>
              {radioLabel}
            </label>
          </RadioWrapper>
        ))}
      </RadioButtons>
      <HelperText error={error}>{_.capitalize(helperText)}</HelperText>
    </Container>
  );
};
