import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import {
  Banner,
  DeleteModal,
  Notification,
  Select,
  TextField
} from "components/UI";
import { GOOGLE_API_KEY } from "config";
import {
  ADDRESSES,
  useCities,
  useCountries,
  useRegions,
  useSubcities,
  useWoredas,
  useZones
} from "features/members";
import i18next from "i18next";
import _ from "lodash";
import { useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { usePlacesWidget } from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import * as yup from "yup";
import { useDeleteDenomination, useUpdateDenomination } from "../api";
import { useDenominationStore } from "../store";
import { updateDenominationSchema } from "../validation";

export function DenominationProfile() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const selectedDenomination = useDenominationStore(
    state => state.selectedDenomination
  );
  const denominationMutation = useUpdateDenomination();
  const denominationDeleteMutation = useDeleteDenomination();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const auth = useAuthUser();
  const userId = auth()?.id;
  const denominationCreatorId = selectedDenomination?.created_by?.id;
  const isCreatedByUser = userId === denominationCreatorId;

  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    watch,
    setValue
  } = useForm({
    defaultValues: _.pick(
      selectedDenomination,
      yup.reach(updateDenominationSchema)["_nodes"]
    ),
    resolver: yupResolver(updateDenominationSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("denominations.createDenomination.denominationProfile"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  useEffect(() => {
    setHeaderBackground(false);

    return () => setHeaderBackground(true);
  }, []);

  const countriesQuery = useCountries();

  useEffect(() => {
    if (countriesQuery.data) {
      setValue("country_id", String(selectedDenomination?.country_id));
    }
  }, [countriesQuery.data]);

  const regionsQuery = useRegions({
    countryId: watch("country_id"),
    config: {
      enabled: !!watch("country_id")
    }
  });

  useEffect(() => {
    if (regionsQuery.data) {
      setValue("region_id", String(selectedDenomination?.region_id));
    }
  }, [regionsQuery.data]);

  const zonesQuery = useZones({
    regionId: watch("region_id"),
    config: {
      enabled: !!watch("region_id")
    }
  });

  useEffect(() => {
    if (zonesQuery.data) {
      setValue("zone_id", String(selectedDenomination?.zone_id));
    }
  }, [zonesQuery.data]);

  const citiesQuery = useCities({
    zoneId: watch("zone_id"),
    config: {
      enabled: !!watch("zone_id")
    }
  });

  useEffect(() => {
    if (citiesQuery.data) {
      setValue("city_id", String(selectedDenomination?.city_id));
    }
  }, [citiesQuery.data]);

  const subcitiesQuery = useSubcities({
    cityId: watch("city_id"),
    config: {
      enabled: !!watch("city_id")
    }
  });

  useEffect(() => {
    if (subcitiesQuery.data) {
      setValue("sub_city_id", String(selectedDenomination?.sub_city_id));
    }
  }, [subcitiesQuery.data]);

  const woredasQuery = useWoredas({
    subcityId: watch("sub_city_id"),
    config: {
      enabled: !!watch("sub_city_id")
    }
  });

  useEffect(() => {
    if (woredasQuery.data) {
      setValue("woreda_id", String(selectedDenomination?.woreda_id));
    }
  }, [woredasQuery.data]);

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: place => {
      setValue("latitude", place.geometry.location.lat());
      setValue("longitude", place.geometry.location.lng());
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: "et" }
    }
  });

  const onSubmit = values => {
    denominationMutation.mutate({
      denominationData: values,
      denominationId: selectedDenomination.id
    });
  };

  return (
    <Box>
      <Notification />
      <DeleteModal
        name={selectedDenomination?.name}
        onDelete={() => {
          denominationDeleteMutation.mutate({
            denominationId: selectedDenomination?.id
          });
        }}
      />
      <Banner />
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={4}
        sx={{ mt: 4 }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            label={t("denominations.createDenomination.denominationName")}
            name="name"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            register={register}
            isLabelBold={true}
            disabled={!isCreatedByUser}
          />

          <TextField
            label={t("denominations.createDenomination.denominationAcronym")}
            name="acronyms"
            error={!!errors.acronyms?.message}
            helperText={errors.acronyms?.message}
            register={register}
            isLabelBold={true}
            disabled={!isCreatedByUser}
          />

          <TextField
            label={t("denominations.createDenomination.denominationPOBox")}
            name="p_o_box"
            error={!!errors.p_o_box?.message}
            helperText={errors.p_o_box?.message}
            register={register}
            isLabelBold={true}
            disabled={!isCreatedByUser}
          />
        </Stack>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("denominations.createDenomination.denominationHQAddress")}
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.country")}
                name={`country_id`}
                options={
                  countriesQuery.data?.result?.map(country => ({
                    optionLabel: country?.name,
                    value: country?.id
                  })) || []
                }
                register={register}
                error={!!errors.country_id?.message}
                helperText={errors.country_id?.message}
                disabled={!isCreatedByUser}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.region")}
                name={`region_id`}
                options={
                  regionsQuery.data?.result?.map(region => ({
                    optionLabel: region?.name,
                    value: region?.id
                  })) || []
                }
                register={register}
                error={!!errors.region_id?.message}
                helperText={errors.region_id?.message}
                onChange={e => {
                  setValue("region_id", e.target.value, {
                    shouldValidate: true
                  });

                  if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                    setValue("zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID, {
                      shouldValidate: true
                    });
                    setValue("city_id", ADDRESSES.ADDIS_ABABA.CITY_ID, {
                      shouldValidate: true
                    });
                  } else {
                    setValue("zone_id", "", {
                      shouldValidate: true
                    });
                    setValue("city_id", "", {
                      shouldValidate: true
                    });
                  }
                }}
                disabled={!isCreatedByUser}
              />
            </Grid>
            {watch("region_id") !== ADDRESSES.ADDIS_ABABA.REGION_ID && (
              <>
                <Grid item xs={3}>
                  <Select
                    isLabelBold={1}
                    label={t("form.common.zone")}
                    name={`zone_id`}
                    options={
                      zonesQuery.data?.result?.map(zone => ({
                        optionLabel: zone?.name,
                        value: zone?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.zone_id?.message}
                    helperText={errors.zone_id?.message}
                    disabled={
                      watch("region_id") === ADDRESSES.ADDIS_ABABA.REGION_ID ||
                      !isCreatedByUser
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    isLabelBold={1}
                    label={t("form.common.city")}
                    name={`city_id`}
                    options={
                      citiesQuery.data?.result?.map(city => ({
                        optionLabel: city?.name,
                        value: city?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.city_id?.message}
                    helperText={errors.city_id?.message}
                    disabled={
                      watch("region_id") === ADDRESSES.ADDIS_ABABA.REGION_ID ||
                      !isCreatedByUser
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.subcity")}
                name={`sub_city_id`}
                options={
                  subcitiesQuery.data?.result?.map(subcity => ({
                    optionLabel: subcity?.name,
                    value: subcity?.id
                  })) || []
                }
                register={register}
                error={!!errors.sub_city_id?.message}
                helperText={errors.sub_city_id?.message}
                disabled={!isCreatedByUser}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                isLabelBold={1}
                label={t("form.common.woreda")}
                name={`woreda_id`}
                options={
                  woredasQuery.data?.result?.map(woreda => ({
                    optionLabel: woreda?.name,
                    value: woreda?.id
                  })) || []
                }
                register={register}
                error={!!errors.woreda_id?.message}
                helperText={errors.woreda_id?.message}
                disabled={!isCreatedByUser}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("churches.createChurch.kebele")}
                name="kebele"
                error={!!errors.kebele?.message}
                helperText={errors.kebele?.message}
                register={register}
                isLabelBold={true}
                disabled={!isCreatedByUser}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("churches.createChurch.houseNumber")}
                name="house_number"
                error={!!errors.house_number?.message}
                helperText={errors.house_number?.message}
                register={register}
                isLabelBold={true}
                disabled={!isCreatedByUser}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("churches.createChurch.specificLocation")}
                name="specific_location"
                error={!!errors.specific_location?.message}
                helperText={errors.specific_location?.message}
                register={register}
                isLabelBold={true}
                ref={placesRef}
                placeholder=""
                disabled={!isCreatedByUser}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack spacing={1}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("churches.profile.Description")}
          </Typography>
          <Stack
            sx={{
              backgroundColor: "#DFF5F5",
              borderRadius: "5px",
              px: 4,
              py: 2
            }}
          >
            <TextField
              label={t("churches.profile.AboutDenomination")}
              name="description"
              error={!!errors.description?.message}
              helperText={errors.description?.message}
              register={register}
              isLabelBold={true}
              disabled={!isCreatedByUser}
            />
          </Stack>
        </Stack>
        {isCreatedByUser && (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={denominationMutation.isLoading}
            >
              {t("form.common.submit")}
            </Button>
            <Button
              color="neutral"
              size="large"
              variant="contained"
              onClick={() => {
                reset({ ...defaultValues });
                navigate(-1);
              }}
              disabled={denominationMutation.isLoading}
            >
              {t("form.common.cancel")}
            </Button>
            <Button
              color="error"
              size="large"
              variant="text"
              onClick={openDeleteModal}
              disabled={denominationMutation.isLoading}
            >
              {t("form.common.delete")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
