import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

export const JobForm = ({ register, errors }) => {
  const EMPLOYMENT_TYPE = [
    "FULL-TIME",
    "PART-TIME",
    "TEMPORARY",
    "CONTRACT",
    "FREELANCE",
    "INTERNSHIP",
    "SEASONAL",
    "REMOTE",
    "PERMANENT",
    "FLEXIBLE HOURS",
    "COMMISSION-BASED",
    "PROJECT-BASED",
    "JOB-SHARING",
    "VOLUNTEER",
    "ON-CALL",
    "CASUAL",
    "GIG ECONOMY",
    "ZERO-HOURS CONTRACT",
    "APPRENTICESHIP",
    "CO-OP (COOPERATIVE)"
  ];

  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Field>
        <Label htmlFor="position">{t("HR.Job.Position")}</Label>
        <Input id="position" {...register("position")} />
        <ErrorMessage
          errors={errors}
          name="position"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="employment_date">{t("HR.Job.EmploymentDate")}</Label>
        <Input
          id="employment_date"
          {...register("employment_date")}
          type="date"
        />
        <ErrorMessage
          errors={errors}
          name="employment_date"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="employment_type">{t("HR.Job.EmploymentType")}</Label>
        <Select id="employment_type" {...register("employment_type")}>
          {EMPLOYMENT_TYPE.map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
        <ErrorMessage
          errors={errors}
          name="employment_type"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
    </Stack>
  );
};
