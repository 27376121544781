import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

export const CompensationForm = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Field>
        <Label htmlFor="gross_salary">{t("HR.Compensation.GrossSalary")}</Label>
        <Input id="gross_salary" {...register("gross_salary")} />
        <ErrorMessage
          errors={errors}
          name="gross_salary"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="overtime">{t("HR.Compensation.Overtime")}</Label>
          <Input id="overtime" {...register("overtime")} />
          <ErrorMessage
            errors={errors}
            name="overtime"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="tax">{t("HR.Compensation.Tax")}</Label>
          <Input id="tax" {...register("tax")} />
          <ErrorMessage
            errors={errors}
            name="tax"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="transport_allowance">
            {t("HR.Compensation.TransportAllowance")}
          </Label>
          <Input
            id="transport_allowance"
            {...register("transport_allowance")}
          />
          <ErrorMessage
            errors={errors}
            name="transport_allowance"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="credit">{t("HR.Compensation.Credit")}</Label>
          <Input id="credit" {...register("credit")} />
          <ErrorMessage
            errors={errors}
            name="credit"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="house_allowance">
            {t("HR.Compensation.HouseAllowance")}
          </Label>
          <Input id="house_allowance" {...register("house_allowance")} />
          <ErrorMessage
            errors={errors}
            name="house_allowance"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="pension">{t("HR.Compensation.Pension")}</Label>
          <Input id="pension" {...register("pension")} />
          <ErrorMessage
            errors={errors}
            name="pension"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="communication_allowance">
            {t("HR.Compensation.CommunicationAllowance")}
          </Label>
          <Input
            id="communication_allowance"
            {...register("communication_allowance")}
          />
          <ErrorMessage
            errors={errors}
            name="communication_allowance"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="provident_fund">
            {t("HR.Compensation.ProvidentFund")}
          </Label>
          <Input id="provident_fund" {...register("provident_fund")} />
          <ErrorMessage
            errors={errors}
            name="provident_fund"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="bonus">{t("HR.Compensation.Bonus")}</Label>
          <Input id="bonus" {...register("bonus")} />
          <ErrorMessage
            errors={errors}
            name="bonus"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="penalty">{t("HR.Compensation.Penalty")}</Label>
          <Input id="penalty" {...register("penalty")} />
          <ErrorMessage
            errors={errors}
            name="penalty"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
    </Stack>
  );
};
