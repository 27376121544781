import styled from "@emotion/styled";
import { Divider, Stack, Typography } from "@mui/material";
import { ChatCircleDots } from "@phosphor-icons/react";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useMessageGrowth,
  useMessagesPerTeam,
  useMessagingStats
} from "../api";
import { getChartLabels, getChartValues } from "../utils";
import { SectionBar } from "./SectionBar";
import { Toolbar } from "./Toolbar";
import { BarChart } from "./charts/BarChart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 22px;
`;

const SectionTitle = styled.h4`
  margin: 0;
`;

const FlatCard = styled.div`
  background: #f3f4f6;
  border-radius: 6px;
  flex: 1;
  border-left: 22px solid #018abe;
  padding: 11px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendIndicator = styled.div`
  width: 24px;
  height: 24px;
  background: ${({ color }) => color};
`;

const TeamMessagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 32px;
`;

export const MessagesView = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [messageSent, setMessageSent] = useState([]);
  const [messageReceived, setMessageReceived] = useState([]);
  const [labels, setLabels] = useState([]);
  const [selectedQueryPeriod, setSelectedQueryPeriod] = useState(null);

  const { t } = useTranslation();

  const messagingStatsQuery = useMessagingStats({
    churchId: selectedChurch?.id
  });

  const messagesPerTeamQuery = useMessagesPerTeam({
    churchId: selectedChurch?.id
  });

  useMessageGrowth({
    churchId: selectedChurch?.id,
    fromDate: selectedQueryPeriod?.fromDate,
    toDate: selectedQueryPeriod?.toDate,
    period: selectedQueryPeriod?.period,
    config: {
      enabled: !!selectedQueryPeriod?.toDate,
      onSuccess: response => {
        setMessageSent(getChartValues(response.sent_message));
        setMessageReceived(getChartValues(response.received_message));
        setLabels(getChartLabels(response.received_message));
      }
    }
  });

  const handlePeriod = selectedPeriod => {
    setSelectedQueryPeriod(selectedPeriod);
  };

  return (
    <Container>
      <SectionBar
        icon={<ChatCircleDots size={24} />}
        title={t("dashboard.message.MessageData")}
        color="#018ABE"
      />

      <Section>
        <Stack spacing={4}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <FlatCard>
              <Stack spacing={1}>
                <Stack
                  sx={{
                    alignSelf: "flex-end",
                    background: "white",
                    borderRadius: "4px",
                    p: 1,
                    color: "#018ABE"
                  }}
                >
                  <ChatCircleDots size={24} />
                </Stack>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("dashboard.message.TotalSentMessages")}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: "#018ABE" }}
                >
                  {messagingStatsQuery.data?.messages_sent_to_church ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
            <FlatCard>
              <Stack spacing={1}>
                <Stack
                  sx={{
                    alignSelf: "flex-end",
                    background: "white",
                    borderRadius: "4px",
                    p: 1,
                    color: "#018ABE"
                  }}
                >
                  <ChatCircleDots size={24} />
                </Stack>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("dashboard.message.TotalReceivedMessages")}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: "#018ABE" }}
                >
                  {messagingStatsQuery.data?.messages_received ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
            <FlatCard>
              <Stack spacing={1}>
                <Stack
                  sx={{
                    alignSelf: "flex-end",
                    background: "white",
                    borderRadius: "4px",
                    p: 1,
                    color: "#018ABE"
                  }}
                >
                  <ChatCircleDots size={24} />
                </Stack>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("dashboard.message.TotalGroupedMessages")}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: "#018ABE" }}
                >
                  {messagingStatsQuery.data?.group_messages_sent ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
            <FlatCard>
              <Stack spacing={1}>
                <Stack
                  sx={{
                    alignSelf: "flex-end",
                    background: "white",
                    borderRadius: "4px",
                    p: 1,
                    color: "#018ABE"
                  }}
                >
                  <ChatCircleDots size={24} />
                </Stack>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("dashboard.message.TotalTeamMessages")}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: "#018ABE" }}
                >
                  {messagingStatsQuery.data?.team_messages_sent ?? 0}
                </Typography>
              </Stack>
            </FlatCard>
          </Stack>
          <Divider />
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <SectionTitle>
                {t("dashboard.message.MessageStatus")}
              </SectionTitle>
              <Toolbar onChange={handlePeriod} />
            </Stack>
            <Stack>
              <BarChart
                labels={labels}
                data={[
                  {
                    name: t("dashboard.message.Sent"),
                    data: messageSent
                  },
                  {
                    name: t("dashboard.message.Received"),
                    data: messageReceived
                  }
                ]}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                <Legend>
                  <LegendIndicator color="#FBD8B0" />
                  <h5>{t("dashboard.message.Sent")}</h5>
                </Legend>
                <Legend>
                  <LegendIndicator color="#74DFD5" />
                  <h5>{t("dashboard.message.Received")}</h5>
                </Legend>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Section>

      <Section>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SectionTitle>{t("dashboard.message.MessagePerTeam")}</SectionTitle>
        </Stack>
        <TeamMessagesGrid>
          {messagesPerTeamQuery.isSuccess &&
            Object.keys(messagesPerTeamQuery.data)?.map(teamName => (
              <Stack
                key={teamName}
                spacing={2}
                sx={{ background: "#E8F3F9", borderRadius: 1 }}
              >
                <Typography
                  sx={{ fontWeight: 700, padding: "15px 23px 0 23px" }}
                >
                  {teamName}
                </Typography>
                <Divider sx={{ background: "#A2CFE4" }} />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ padding: "0 23px 30px 23px" }}
                  spacing={5}
                >
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {t("dashboard.message.TotalMessageSent")}
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 700 }}>
                    {messagesPerTeamQuery.data?.[teamName]?.[
                      "messagesSentToTeam"
                    ] ?? 0}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ padding: "0 23px 30px 23px" }}
                  spacing={5}
                >
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {t("dashboard.message.TotalMembersReached")}
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 700 }}>
                    {messagesPerTeamQuery.data?.[teamName]?.[
                      "membersReached"
                    ] ?? 0}
                  </Typography>
                </Stack>
              </Stack>
            ))}
        </TeamMessagesGrid>
      </Section>
    </Container>
  );
};
