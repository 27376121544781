import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Stack,
  Typography
} from "@mui/material";
import {
  BorderedInputField,
  Button,
  Dropzone,
  ErrorMessage,
  Notification,
  PhoneInputField,
  Select,
  TextField
} from "components/UI";
import { GOOGLE_API_KEY } from "config";
import {
  ADDRESSES,
  useCities,
  useCountries,
  useRegions,
  useSubcities,
  useWoredas,
  useZones
} from "features/members";
import _ from "lodash";
import { useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCentralStore } from "stores";
import { removeEmptyValues } from "utils/removeEmptyValues";
import { useCreateDenomination } from "../api";
import { createDenominationSchema } from "../validation";

export function CreateDenomination() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const denominationMutation = useCreateDenomination();
  const { t } = useTranslation();

  const {
    register,
    unregister,
    watch,
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors, defaultValues }
  } = useForm({
    defaultValues: {
      name: "",
      acronyms: "",
      phone: "",
      p_o_box: "",
      country_id: "",
      region_id: "",
      zone_id: "",
      city_id: "",
      sub_city_id: "",
      woreda_id: "",
      kebele: "",
      house_number: "",
      specific_location: "",
      description: "",
      acceptTerms: false
    },
    resolver: yupResolver(createDenominationSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("denominations.createDenomination.createDenomination"));

    return () => {
      setHeaderTitle("");
    };
  }, []);

  useEffect(() => {
    setHeaderBackground(true);

    return () => setHeaderBackground(true);
  }, []);

  useEffect(() => {
    if (denominationMutation.isSuccess) {
      reset({ ...defaultValues });
    }

    return () => {};
  }, [denominationMutation.isSuccess]);

  const countriesQuery = useCountries();
  const regionsQuery = useRegions({
    countryId: watch("country_id"),
    config: {
      enabled: !!watch("country_id")
    }
  });
  const zonesQuery = useZones({
    regionId: watch("region_id"),
    config: {
      enabled: !!watch("region_id")
    }
  });
  const citiesQuery = useCities({
    zoneId: watch("zone_id"),
    config: {
      enabled: !!watch("zone_id")
    }
  });
  const subcitiesQuery = useSubcities({
    cityId: watch("city_id"),
    config: {
      enabled: !!watch("city_id")
    }
  });
  const woredasQuery = useWoredas({
    subcityId: watch("sub_city_id"),
    config: {
      enabled: !!watch("sub_city_id")
    }
  });

  const onSubmit = values => {
    const data = { ...values, image: values.image[0] };
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    removeEmptyValues(formData);

    denominationMutation.mutate({
      denominationData: formData
    });
  };

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: place => {
      setValue("latitude", place.geometry.location.lat());
      setValue("longitude", place.geometry.location.lng());
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: "et" }
    }
  });

  return (
    <Box p={{ xs: 4, lg: 6 }}>
      <Notification />
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={6}>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {t("denominations.createDenomination.denominationProfile")}
          </Typography>
          <Stack direction="row" spacing={4}>
            <Stack justifyContent="space-between" sx={{ flex: 1 }}>
              <TextField
                hasNoBg={true}
                label={t("denominations.createDenomination.denominationName")}
                name="name"
                error={
                  !!errors.name?.message ||
                  !_.isEmpty(denominationMutation.error?.errors?.name)
                }
                helperText={
                  errors.name?.message || (
                    <ErrorMessage
                      messages={denominationMutation.error?.errors?.name}
                    />
                  )
                }
                register={register}
              />
              <TextField
                hasNoBg={true}
                label={t(
                  "denominations.createDenomination.denominationAcronym"
                )}
                name="acronyms"
                error={
                  !!errors.acronyms?.message ||
                  !_.isEmpty(denominationMutation.error?.errors?.acronyms)
                }
                helperText={
                  errors.acronyms?.message || (
                    <ErrorMessage
                      messages={denominationMutation.error?.errors?.acronyms}
                    />
                  )
                }
                register={register}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t(
                      "denominations.createDenomination.denominationPhone"
                    )}
                    error={
                      !!errors.phone?.message ||
                      !_.isEmpty(denominationMutation.error?.errors?.phone)
                    }
                    helperText={
                      errors.phone?.message || (
                        <ErrorMessage
                          messages={denominationMutation.error?.errors?.phone}
                        />
                      )
                    }
                    {...field}
                  />
                )}
              />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <Stack>
                <Typography
                  variant="subtitle2"
                  component="label"
                  htmlFor="image"
                >
                  {t("denominations.createDenomination.denominationLogo")}
                </Typography>
                <Dropzone
                  id="image"
                  name="image"
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  setValue={setValue}
                  error={!!errors.image?.message}
                  helperText={errors.image?.message}
                />
              </Stack>
              <TextField
                hasNoBg={true}
                label={t("denominations.createDenomination.denominationPOBox")}
                name="p_o_box"
                error={
                  !!errors.p_o_box?.message ||
                  !_.isEmpty(denominationMutation.error?.errors?.p_o_box)
                }
                helperText={
                  errors.p_o_box?.message || (
                    <ErrorMessage
                      messages={denominationMutation.error?.errors?.p_o_box}
                    />
                  )
                }
                register={register}
              />
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {t("denominations.createDenomination.denominationHQAddress")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Select
                hasNoBg={true}
                isLabelBold={0}
                label={t("form.common.country")}
                name={`country_id`}
                options={
                  countriesQuery.data?.result?.map(country => ({
                    optionLabel: country?.name,
                    value: country?.id
                  })) || []
                }
                register={register}
                error={!!errors.country_id?.message}
                helperText={errors.country_id?.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                hasNoBg={true}
                isLabelBold={0}
                label={t("form.common.region")}
                name={`region_id`}
                options={
                  regionsQuery.data?.result?.map(region => ({
                    optionLabel: region?.name,
                    value: region?.id
                  })) || []
                }
                register={register}
                error={!!errors.region_id?.message}
                helperText={errors.region_id?.message}
                onChange={e => {
                  setValue("region_id", e.target.value, {
                    shouldValidate: true
                  });

                  if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                    setValue("zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID, {
                      shouldValidate: true
                    });
                    setValue("city_id", ADDRESSES.ADDIS_ABABA.CITY_ID, {
                      shouldValidate: true
                    });
                  } else {
                    setValue("zone_id", "", {
                      shouldValidate: true
                    });
                    setValue("city_id", "", {
                      shouldValidate: true
                    });
                  }
                }}
              />
            </Grid>
            {watch("region_id") !== ADDRESSES.ADDIS_ABABA.REGION_ID && (
              <>
                <Grid item xs={4}>
                  <Select
                    hasNoBg={true}
                    isLabelBold={0}
                    label={t("form.common.zone")}
                    name={`zone_id`}
                    options={
                      zonesQuery.data?.result?.map(zone => ({
                        optionLabel: zone?.name,
                        value: zone?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.zone_id?.message}
                    helperText={errors.zone_id?.message}
                    disabled={
                      watch("region_id") === ADDRESSES.ADDIS_ABABA.REGION_ID
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    hasNoBg={true}
                    isLabelBold={0}
                    label={t("form.common.city")}
                    name={`city_id`}
                    options={
                      citiesQuery.data?.result?.map(city => ({
                        optionLabel: city?.name,
                        value: city?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.city_id?.message}
                    helperText={errors.city_id?.message}
                    disabled={
                      watch("region_id") === ADDRESSES.ADDIS_ABABA.REGION_ID
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <Select
                hasNoBg={true}
                isLabelBold={0}
                label={t("form.common.subcity")}
                name={`sub_city_id`}
                options={
                  subcitiesQuery.data?.result?.map(subcity => ({
                    optionLabel: subcity?.name,
                    value: subcity?.id
                  })) || []
                }
                register={register}
                error={!!errors.sub_city_id?.message}
                helperText={errors.sub_city_id?.message}
              />
            </Grid>
            <Grid container item xs={4} spacing={1}>
              <Grid item xs>
                <Select
                  hasNoBg={true}
                  isLabelBold={0}
                  label={t("form.common.woreda")}
                  name={`woreda_id`}
                  options={
                    woredasQuery.data?.result?.map(woreda => ({
                      optionLabel: woreda?.name,
                      value: woreda?.id
                    })) || []
                  }
                  register={register}
                  error={!!errors.woreda_id?.message}
                  helperText={errors.woreda_id?.message}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  hasNoBg={true}
                  label={t("denominations.createDenomination.kebele")}
                  name="kebele"
                  error={
                    !!errors.kebele?.message ||
                    !_.isEmpty(denominationMutation.error?.errors?.kebele)
                  }
                  helperText={
                    errors.kebele?.message || (
                      <ErrorMessage
                        messages={denominationMutation.error?.errors?.kebele}
                      />
                    )
                  }
                  register={register}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                hasNoBg={true}
                label={t("denominations.createDenomination.houseNumber")}
                name="house_number"
                error={
                  !!errors.house_number?.message ||
                  !_.isEmpty(denominationMutation.error?.errors?.house_number)
                }
                helperText={
                  errors.house_number?.message || (
                    <ErrorMessage
                      messages={
                        denominationMutation.error?.errors?.house_number
                      }
                    />
                  )
                }
                register={register}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                hasNoBg={true}
                label={t("denominations.createDenomination.specificLocation")}
                name="specific_location"
                error={
                  !!errors.specific_location?.message ||
                  !_.isEmpty(
                    denominationMutation.error?.errors?.specific_location
                  )
                }
                helperText={
                  errors.specific_location?.message || (
                    <ErrorMessage
                      messages={
                        denominationMutation.error?.errors?.specific_location
                      }
                    />
                  )
                }
                register={register}
                ref={placesRef}
                placeholder=""
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography
            component="label"
            htmlFor="description"
            variant="h6"
            sx={{ mb: 1 }}
          >
            {t("denominations.createDenomination.denominationDescription")}
          </Typography>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <BorderedInputField
                id="description"
                size="small"
                autoComplete="off"
                multiline
                minRows={4}
                fullWidth
                inputProps={{
                  maxLength: 200
                }}
                {...field}
                error={
                  !!errors.description?.message ||
                  !_.isEmpty(denominationMutation.error?.errors?.description)
                }
                helperText={
                  `${t(
                    "denominations.createDenomination.denominationDescriptionHelperText"
                  )} - ${field.value.length}/200` ||
                  errors.description?.message || (
                    <ErrorMessage
                      messages={denominationMutation.error?.errors?.description}
                    />
                  )
                }
              />
            )}
          />
        </Box>
        <Stack alignItems="center">
          <FormControlLabel
            control={
              <Controller
                name="acceptTerms"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    color="primary"
                  />
                )}
              />
            }
            label={
              <Typography variant="body1">
                {t("denominations.createDenomination.agree")}{" "}
                <Link href="#" color="inherit">
                  {t("denominations.createDenomination.termsAndConditions")}
                </Link>
              </Typography>
            }
          />
          {!!errors.acceptTerms?.message && (
            <FormHelperText error>{errors.acceptTerms?.message}</FormHelperText>
          )}
          <Button
            type="submit"
            size="large"
            variant="contained"
            sx={{ mt: 3 }}
            disabled={denominationMutation.isLoading}
          >
            {t("denominations.createDenomination.createDenomination")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
