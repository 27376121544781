import { FilterAltOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { Button, Notification, Select, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import { useAddTeamMember } from "features/teams";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import {
  AddTeamModal,
  ApplicationRequestsTable,
  ColumnsVisibilityModal,
  ExportModal,
  InactiveMembersTable,
  KidsTable,
  MembersTable,
  MembersTableHeader,
  PartnersTable
} from "../components";
import { FILTER_OPERATIONS, MEMBER_STATES, TAB_NAMES } from "../constants";
import kidsFilterFields from "../data/kidsFilterFields.json";
import membersFilterFields from "../data/membersFilterFields.json";
import partnersFilterFields from "../data/partnersFilterFields.json";
import { useMembersStore } from "../stores";

const filterOperators = [
  { optionLabel: "=", value: FILTER_OPERATIONS.EQUAL, forType: "number" },
  { optionLabel: "!=", value: FILTER_OPERATIONS.NOT_EQUAL, forType: "number" },
  { optionLabel: "<", value: FILTER_OPERATIONS.LESS_THAN, forType: "number" },
  {
    optionLabel: ">",
    value: FILTER_OPERATIONS.GREATER_THAN,
    forType: "number"
  },
  {
    optionLabel: "<=",
    value: FILTER_OPERATIONS.LESS_THAN_OR_EQUAL,
    forType: "number"
  },
  {
    optionLabel: ">=",
    value: FILTER_OPERATIONS.GREATER_THAN_OR_EQUAL,
    forType: "number"
  },
  { optionLabel: "in", value: FILTER_OPERATIONS.IN, forType: "string" },
  {
    optionLabel: "between",
    value: FILTER_OPERATIONS.BETWEEN,
    forType: "string"
  },
  { optionLabel: "like", value: FILTER_OPERATIONS.LIKE, forType: "string" }
];

const generateID = () => {
  return Math.floor(Math.random() * Date.now());
};

export function FilterMembers() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const [filters, setFilters] = useState([
    {
      id: generateID(),
      field: "",
      operator: "",
      value: "",
      isRemovable: false
    }
  ]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [computedFilterOperators, setComputedFilterOperators] =
    useState(filterOperators);
  const setQueries = useMembersStore(state => state.setQueries);
  const openExportModal = useMembersStore(state => state.openExportModal);
  const currentTab = useMembersStore(state => state.currentTab);
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const navigate = useNavigate();
  const openAddTeamModal = useMembersStore(state => state.openAddTeamModal);
  const closeAddTeamModal = useMembersStore(state => state.closeAddTeamModal);
  const addTeamMember = useAddTeamMember();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  useEffect(() => {
    setHeaderTitle("Filter Members");

    return () => setHeaderTitle("");
  }, []);

  // useEffect(() => {
  //   setQueries({
  //     filter: {
  //       status: "active"
  //     }
  //   });

  //   return () => setQueries({});
  // }, []);

  const getFields = () => {
    if (
      currentTab === TAB_NAMES.MEMBERS ||
      currentTab === TAB_NAMES.APPLICATION_REQUESTS ||
      currentTab === TAB_NAMES.INACTIVE_MEMBERS
    ) {
      return membersFilterFields;
    }

    if (currentTab === TAB_NAMES.KIDS) {
      return kidsFilterFields;
    }

    if (currentTab === TAB_NAMES.PARTNERS) {
      return partnersFilterFields;
    }
  };

  const clearFilters = () => {
    if (currentTab === TAB_NAMES.MEMBERS) {
      setQueries({
        filter: {
          status: [
            {
              operator: FILTER_OPERATIONS.EQUAL,
              value: MEMBER_STATES.ACTIVE
            }
          ]
        }
      });
    }

    if (currentTab === TAB_NAMES.APPLICATION_REQUESTS) {
      setQueries({
        filter: {
          status: [
            {
              operator: FILTER_OPERATIONS.NOT_EQUAL,
              value: MEMBER_STATES.ACTIVE
            },
            {
              operator: FILTER_OPERATIONS.NOT_EQUAL,
              value: MEMBER_STATES.INACTIVE
            }
          ]
        }
      });
    }

    if (currentTab === TAB_NAMES.INACTIVE_MEMBERS) {
      setQueries({
        filter: {
          status: [
            {
              operator: FILTER_OPERATIONS.EQUAL,
              value: MEMBER_STATES.INACTIVE
            }
          ]
        }
      });
    }

    if (currentTab === TAB_NAMES.KIDS) {
      setQueries({
        filter: {}
      });
    }

    if (currentTab === TAB_NAMES.PARTNERS) {
      setQueries({});
    }
  };

  const computeFilterOperators = filterField => {
    const newFilterOperators = filterOperators.filter(filterOperator => {
      return (
        filterOperator.forType ===
        getFields()[_.toLower(filterField).replace(" ", "_")]
      );
    });

    setComputedFilterOperators(newFilterOperators);
  };

  const messageSelections = () => {
    clearRecipients();

    for (const member of selectedMembers) {
      removeMember(member.id);
      addRecipient(
        `${member.id}_${member?.first_name}_${member?.last_name}`,
        member
      );
      addMember(member.id, { id: member.id });
      navigate("/messages");
    }
  };

  const groupSelections = teamId => {
    const selectedMembersId = selectedMembers.map(
      selectedMember => selectedMember.id
    );

    addTeamMember.mutate(
      {
        churchId: selectedChurch?.id,
        teamId: teamId,
        memberData: {
          member_ids: selectedMembersId
        }
      },
      {
        onSuccess: () => {
          setSelectedMembers([]);
          closeAddTeamModal();
        }
      }
    );
  };

  return (
    <Box sx={{ py: 4 }}>
      <Notification />
      <AddTeamModal onSelect={teamId => groupSelections(teamId)} />
      <ColumnsVisibilityModal />
      <ExportModal />
      <Stack spacing={2}>
        <MembersTableHeader isFilterMode={true} />
        <Stack spacing={2}>
          <Stack spacing={2}>
            {filters.map((filter, index) => (
              <Stack
                key={filter.id}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <FilterAltOutlined />
                <Select
                  options={_.keys(getFields()).map(field => ({
                    optionLabel: _.startCase(_.toLower(field)),
                    value: field
                  }))}
                  register={() => {}}
                  onChange={e => {
                    filter.field = e.target.value;

                    computeFilterOperators(filter.field);
                  }}
                />
                <Select
                  options={computedFilterOperators}
                  register={() => {}}
                  onChange={e => {
                    filter.operator = e.target.value;
                  }}
                />
                <TextField
                  width="auto"
                  register={() => {}}
                  onChange={e => {
                    filter.value = e.target.value;
                  }}
                />
                {filter.isRemovable && (
                  <Button
                    variant="contained"
                    color="secondary"
                    flavor="outlined"
                    onClick={() => {
                      const currentFilters = [...filters];
                      currentFilters.splice(index, 1);
                      setFilters(currentFilters);
                    }}
                  >
                    Remove Filter
                  </Button>
                )}
              </Stack>
            ))}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              flavor="secondary"
              onClick={() =>
                setFilters([
                  ...filters,
                  {
                    id: generateID(),
                    field: "",
                    operator: "",
                    value: "",
                    isRemovable: true
                  }
                ])
              }
            >
              Add Filter
            </Button>
            <Button
              variant="contained"
              color="secondary"
              flavor="outlined"
              onClick={() => {
                clearFilters();

                setFilters([
                  {
                    id: generateID(),
                    field: "",
                    operator: "",
                    value: "",
                    isRemovable: false
                  }
                ]);
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const appliedFilters = {};

                filters.forEach(filter => {
                  appliedFilters[filter["field"]] = [
                    {
                      operator: filter["operator"],
                      value: filter["value"]
                    }
                  ];
                });

                setQueries({ filter: appliedFilters });
              }}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" color="primary" onClick={openExportModal}>
            Export Members
          </Button>
          {selectedMembers.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={openAddTeamModal}
            >
              Group Selection
            </Button>
          )}
          {selectedMembers.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={messageSelections}
            >
              Message Selection
            </Button>
          )}
        </Stack>
        {currentTab === TAB_NAMES.MEMBERS && (
          <MembersTable
            isFilterMode={true}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
          />
        )}
        {currentTab === TAB_NAMES.APPLICATION_REQUESTS && (
          <ApplicationRequestsTable isFilterMode={true} />
        )}
        {currentTab === TAB_NAMES.INACTIVE_MEMBERS && (
          <InactiveMembersTable isFilterMode={true} />
        )}
        {currentTab === TAB_NAMES.KIDS && <KidsTable isFilterMode={true} />}
        {currentTab === TAB_NAMES.PARTNERS && (
          <PartnersTable isFilterMode={true} />
        )}
      </Stack>
    </Box>
  );
}
