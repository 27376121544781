import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const deleteSettlement = ({ churchId, expenseId, settlementId }) => {
  return axios.delete(
    `/churches/${churchId}/expenses/${expenseId}/settlements/${settlementId}`
  );
};

export const useDeleteSettlement = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateAdvanceSettlementModal = useExpenseStore(
    state => state.closeUpdateAdvanceSettlementModal
  );

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("settlements");

      setNotification({
        type: "success",
        message: "Settlement deleted successfully!"
      });

      closeUpdateAdvanceSettlementModal();
    },
    ...config,
    mutationFn: deleteSettlement
  });
};
