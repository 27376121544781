export const getChartLabels = data => {
  const result = [];

  for (const year in data) {
    if (typeof data[year] === "object") {
      for (const month in data[year]) {
        result.push(`${year}, ${month}`);
      }
    } else {
      result.push(`${year}`);
    }
  }
  return result;
};
