import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack } from "@mui/icons-material";
import { Alert, Divider, Stack } from "@mui/material";
import { Button } from "components/UI";
import {
  MemberDataManipulator,
  currentChurchEngagementSchema,
  useCreatePublicMember,
  useMembersStore
} from "features/members";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSteps } from "react-step-builder";
import { ErrorModal, ThankYouModal } from ".";
import {
  CurrentChurchEngagementInfoSection,
  SalvationProfileSection,
  UnionInfoSection
} from "./sections";

export function PublicCurrentChurchEngagementForm() {
  const { t } = useTranslation();
  const { prev } = useSteps();
  const { formId } = useParams();
  const memberData = useMembersStore(state => state.memberData);
  const memberMutation = useCreatePublicMember();
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const setMemberData = useMembersStore(state => state.setMemberData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control
  } = useForm({
    defaultValues: {
      former_belief: memberData.former_belief ? memberData.former_belief : "",
      how_you_accept_christ: memberData.how_you_accept_christ
        ? memberData.how_you_accept_christ
        : "",
      salvation_date: memberData.salvation_date
        ? memberData.salvation_date
        : null,
      is_baptized: memberData.is_baptized ? memberData.is_baptized : "",
      salvation_church: memberData.salvation_church
        ? memberData.salvation_church
        : "",
      baptism_church: memberData.baptism_church
        ? memberData.baptism_church
        : "",
      baptism_date: memberData.baptism_date ? memberData.baptism_date : null,
      is_home_cell: memberData.is_home_cell ? memberData.is_home_cell : "",
      home_cell_name: memberData.home_cell_name
        ? memberData.home_cell_name
        : "",
      home_cell_cordinator: memberData.home_cell_cordinator
        ? memberData.home_cell_cordinator
        : "",
      date_joined_home_cell: memberData.date_joined_home_cell
        ? memberData.date_joined_home_cell
        : null,
      current_ministry: memberData.current_ministry
        ? memberData.current_ministry
        : "",
      date_joined_current_ministry: memberData.date_joined_current_ministry
        ? memberData.date_joined_current_ministry
        : null,
      role_in_current_ministry: memberData.role_in_current_ministry
        ? memberData.role_in_current_ministry
        : "",
      membership_date: memberData.membership_date
        ? memberData.membership_date
        : null,
      gifts_and_talents: memberData.gifts_and_talents
        ? memberData.gifts_and_talents
        : "",
      ministry_preference: memberData.ministry_preference
        ? memberData.ministry_preference
        : []
    },
    resolver: yupResolver(currentChurchEngagementSchema)
  });

  const onSubmit = data => {
    setMemberData(data);

    const { profile_image, id_image, letter_of_resignation } = memberData;

    const cleanMemberData = {
      ...memberData,
      ...data
    };

    const memberDataManipulator = new MemberDataManipulator();

    memberDataManipulator.convertValuesToDates(cleanMemberData, [
      "baptism_date",
      "date_of_birth",
      "marital_date",
      "membership_date",
      "salvation_date"
    ]);

    memberDataManipulator.convertValuesToShortDates(cleanMemberData, [
      "date_joined_home_cell",
      "date_joined_current_ministry",
      "spouse_date_of_birth",
      "date_of_resignation"
    ]);

    if (cleanMemberData.child_and_dependencies === "1") {
      let childrenData = _.cloneDeep(cleanMemberData.children);

      childrenData.forEach(child => {
        memberDataManipulator.removeEmptyValues(child);

        memberDataManipulator.convertValuesToNumbers(child, [
          "nationality_id",
          "is_believer",
          "is_member",
          "birth_country_id",
          "birth_region_id",
          "birth_zone_id",
          "birth_city_id"
        ]);

        memberDataManipulator.convertValuesToShortDates(child, [
          "date_of_birth"
        ]);
      });

      cleanMemberData.children = childrenData;

      memberDataManipulator.formatArrayStructure(cleanMemberData, ["children"]);
    } else {
      memberDataManipulator.removeFromObject(cleanMemberData, ["children"]);
    }

    if (
      cleanMemberData.job_status === "student" ||
      cleanMemberData.job_status === "unemployed" ||
      cleanMemberData.job_status === "housewife"
    ) {
      memberDataManipulator.removeFromObject(cleanMemberData, [
        "profession",
        "work_place",
        "monthly_income"
      ]);
    }

    if (cleanMemberData.marital_status !== "married") {
      memberDataManipulator.removeFromObject(cleanMemberData, [
        "marital_date",
        "type_of_marriage",
        "is_spouse_believer",
        "is_spouse_member",
        "spouse_church_name",
        "spouse_first_name",
        "spouse_father_name",
        "spouse_last_name",
        "spouse_phone_number",
        "spouse_date_of_birth",
        "spouse_mother_name",
        "spouse_mother_father_name",
        "spouse_mother_grand_father_name",
        "spouse_professional_skill",
        "spouse_office_name"
      ]);
    }

    if (cleanMemberData.transfer_from_other_church !== "1") {
      memberDataManipulator.removeFromObject(cleanMemberData, [
        "previous_church_name",
        "date_of_resignation",
        "pastor_title",
        "pastor_full_name",
        "pastor_phone_number",
        "reason_for_resignation",
        "reason_for_resignation_other"
      ]);
    } else {
      if (
        cleanMemberData.reason_for_resignation === "Other" &&
        cleanMemberData.reason_for_resignation_other
      ) {
        cleanMemberData.reason_for_resignation =
          cleanMemberData.reason_for_resignation_other;

        delete cleanMemberData.reason_for_resignation_other;
      }
    }

    memberDataManipulator.removeEmptyValues(cleanMemberData);

    memberDataManipulator.formatArrayStructure(cleanMemberData, [
      "education_and_skills"
    ]);

    memberDataManipulator.formatStringArray(cleanMemberData, ["languages"]);

    memberDataManipulator.formatStringArray(cleanMemberData, [
      "social_media_links"
    ]);

    const memberFormData =
      memberDataManipulator.convertObjectToFormData(cleanMemberData);

    if (!_.isNil(cleanMemberData.ministry_preference)) {
      cleanMemberData.ministry_preference.forEach((ministry, index) => {
        memberFormData.append(
          `ministry_preference[${index}][ministry]`,
          ministry
        );
        memberFormData.append(`ministry_preference[${index}][preference]`, 1);
      });

      memberFormData.delete("ministry_preference");
    }

    if (profile_image) {
      memberFormData.append("profile_image", profile_image);
    }

    if (id_image) {
      memberFormData.append("id_image", id_image);
    }

    if (letter_of_resignation) {
      memberFormData.append("letter_of_resignation", letter_of_resignation);
    }

    memberMutation.mutate(
      {
        formId: formId,
        memberData: memberFormData
      },
      {
        onSuccess: () => {
          setIsThankYouModalOpen(true);
        },
        onError: () => {
          setIsErrorModalOpen(true);
        }
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ThankYouModal isOpen={isThankYouModalOpen} />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SalvationProfileSection
          register={register}
          errors={errors}
          watch={watch}
          control={control}
        />
        <Divider sx={{ my: 3 }} />
        <UnionInfoSection
          register={register}
          errors={errors}
          watch={watch}
          control={control}
        />
        <Divider sx={{ my: 3 }} />
        <CurrentChurchEngagementInfoSection
          register={register}
          errors={errors}
          control={control}
        />
        {memberMutation.isError && (
          <Alert variant="filled" severity="error">
            {typeof memberMutation.error?.errors === "string" ? (
              <div>{memberMutation.error?.errors}</div>
            ) : (
              Object.values(memberMutation.error?.errors)?.map(err => (
                <div key={err}>{err}</div>
              ))
            )}
          </Alert>
        )}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ mt: 8 }}
        >
          <Button
            type="button"
            variant="contained"
            startIcon={<ArrowBack />}
            disabled={memberMutation.isLoading}
            onClick={prev}
          >
            {t("form.common.back")}
          </Button>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={memberMutation.isLoading}
          >
            {t("form.common.submit")}
          </Button>
        </Stack>
      </form>
    </>
  );
}
