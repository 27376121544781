import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useChurchStore = create(
  devtools(
    persist(
      set => ({
        selectedChurch: null,
        selectChurch: church => set({ selectedChurch: church })
      }),
      {
        name: "church"
      }
    ),
    {
      name: "church-store"
    }
  )
);
