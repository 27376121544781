import { Stack } from "@mui/material";
import { Select, TextField } from "components/UI";
import { useTranslation } from "react-i18next";

export const AcquisitionForm = ({ register, errors }) => {
  const ACQUISITION_METHOD = ["RENT", "GIFT", "PURCHASE"];

  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Select
        isLabelBold={0}
        label={t("Asset.AssetRegistration.Acquisition Method")}
        name="acquisition_method"
        options={ACQUISITION_METHOD.map(method => ({
          optionLabel: method,
          value: method
        }))}
        register={register}
        error={!!errors.acquisition_method?.message}
        helperText={errors.acquisition_method?.message}
      />
      <TextField
        label={t("Asset.AssetRegistration.Acquisition / Purchase Date")}
        name="acquisition_date"
        error={!!errors.acquisition_date?.message}
        helperText={errors.acquisition_date?.message}
        register={register}
        type="date"
      />
      <TextField
        label={t("Asset.AssetRegistration.PurchasePrice")}
        name="purchase_price"
        error={!!errors.purchase_price?.message}
        helperText={errors.purchase_price?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Giver / Vendor")}
        name="vendor"
        error={!!errors.vendor?.message}
        helperText={errors.vendor?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Location")}
        name="location"
        error={!!errors.location?.message}
        helperText={errors.location?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Assigned to")}
        name="assigned_to"
        error={!!errors.assigned_to?.message}
        helperText={errors.assigned_to?.message}
        register={register}
      />
    </Stack>
  );
};
