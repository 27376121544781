import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";

export const createKid = ({ churchId, kidData }) => {
  return axios.post(`/churches/${churchId}/children`, kidData);
};

export const useCreateKid = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("kids");
      setNotification({
        type: "success",
        message: "Kid created successfully!"
      });
      navigate("/members");
    },
    ...config,
    mutationFn: createKid
  });
};
