import styled from "@emotion/styled";
import {
  Groups,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
  Search
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { Button, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import Fuse from "fuse.js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteTeamTypeNote, TeamCard, UpdateTeamTypesModal } from ".";
import { useTeamsStore } from "../stores";

const TitleBar = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  padding: 1rem;
  border-radius: 5px;
`;

const TeamsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

  ${({ theme }) => `${theme.breakpoints.up("sm")} {
    grid-template-columns: repeat(2, 1fr);
  }`}

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    grid-template-columns: repeat(3, 1fr);
  }`}
`;

export const TeamsView = ({ teamTypes }) => {
  const openCreateTeamModal = useTeamsStore(state => state.openCreateTeamModal);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTeamTypesList, setFilteredTeamTypesList] = useState(teamTypes);
  const { t } = useTranslation();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  useEffect(() => {
    if (teamTypes) {
      setFilteredTeamTypesList(teamTypes);
    }
  }, [teamTypes]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const searchTeams = () => {
    const fuse = new Fuse(teamTypes, {
      threshold: 0.3,
      keys: ["teams.name"]
    });

    if (_.isEmpty(searchTerm)) {
      setFilteredTeamTypesList(teamTypes);
      return;
    }

    const searchResults = fuse.search(searchTerm);

    if (_.isEmpty(searchResults)) {
      setFilteredTeamTypesList([]);
    } else {
      setFilteredTeamTypesList([
        ..._.map(fuse.search(searchTerm), foundTerm => foundTerm.item)
      ]);
    }
  };

  return (
    <Stack spacing={3} mt={3}>
      <UpdateTeamTypesModal />
      <DeleteTeamTypeNote />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            neutral={1}
            placeholder={t("teams.teams.SearchTeams")}
            register={() => {}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            autoComplete="off"
            onChange={e => setSearchTerm(e.target.value)}
            onKeyUp={searchTeams}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            startIcon={<Groups />}
            onClick={openCreateTeamModal}
          >
            {t("teams.teams.CreateTeam")}
          </Button>
        </Stack>
      </Stack>
      <TitleBar sx={{ mb: 2 }}>
        <Typography fontWeight="500" variant="subtitle2">
          {t("teams.teams.TeamTypeInChurch")}
        </Typography>
      </TitleBar>
      {filteredTeamTypesList?.map(teamType => (
        <TeamAccordion
          key={teamType}
          initialOpen={true}
          teams={teamType.teams.filter(
            team => team.church_id === selectedChurch?.id
          )}
          handleClick={handleClick}
          teamType={teamType}
        />
      ))}
      <Popover
        anchorEl={anchorEl}
        id="photo-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <TeamsMenu />
      </Popover>
    </Stack>
  );
};

const TeamAccordion = ({
  initialOpen = false,
  teams,
  teamType,
  handleClick
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const setCurrentTeamType = useTeamsStore(state => state.setCurrentTeamType);

  const publicTeamTypes = ["Cell Group", "Ministry"];
  const isMenuHidden = publicTeamTypes.includes(teamType?.name);

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography fontWeight="700" variant="subtitle2">
          {teamType?.name}
        </Typography>
        <IconButton>
          {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Stack>
      {isOpen && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography variant="body2">{teamType?.description}</Typography>
            {!isMenuHidden && (
              <IconButton
                color="primary"
                onClick={e => {
                  setCurrentTeamType(teamType);
                  handleClick(e);
                }}
              >
                <MoreVert />
              </IconButton>
            )}
          </Stack>
          <TeamsGrid>
            {teams?.map(team => (
              <TeamCard key={team.id} teamData={team} />
            ))}
          </TeamsGrid>
        </>
      )}
    </Stack>
  );
};

const TeamsMenu = () => {
  const openUpdateTeamTypeModal = useTeamsStore(
    state => state.openUpdateTeamTypeModal
  );
  const openDeleteTeamTypeModal = useTeamsStore(
    state => state.openDeleteTeamTypeModal
  );
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "200px", overflow: "hidden", borderRadius: "5px" }}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        {t("teams.teams.Update")}
      </Box>
      <Stack
        sx={{
          backgroundColor: "white",
          p: 2
        }}
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "#EEEEEE", py: 1, px: 2, cursor: "pointer" }}
          onClick={openUpdateTeamTypeModal}
        >
          <Typography variant="caption">
            {t("teams.teams.UpdateTeamType")}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: "#FCD1D1",
            color: "#F44949",
            py: 1,
            px: 2,
            cursor: "pointer"
          }}
          onClick={openDeleteTeamTypeModal}
        >
          <Typography variant="caption">
            {t("teams.teams.DeleteTeamType")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
