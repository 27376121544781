import { Chat, Edit } from "@mui/icons-material";
import { Avatar, IconButton, Popover, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteModal } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDeleteTeamMember, usePreviousTeamMembersPaginated } from "../api";
import { useTeamsStore } from "../stores";
import { TeamMembersTableEditDropdownMenu } from "./TeamMembersTableEditDropdownMenu";

export const PreviousMembersTable = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [memberId, setMemberId] = useState("");
  const [memberName, setMemberName] = useState("");
  const deleteMutation = useDeleteTeamMember();
  const searchQuery = useTeamsStore(state => state.searchQuery);

  const editOpen = Boolean(editAnchorEl);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const previousTeamMembersPaginatedQuery = usePreviousTeamMembersPaginated({
    churchId: selectedChurch?.id,
    teamId: id,
    page: page,
    pageSize: pageSize,
    search: searchQuery
  });

  const handleEditClick = (event, id, name) => {
    setEditAnchorEl(event.currentTarget);
    setMemberId(id);
    setMemberName(name);
  };

  const handleEditClose = () => {
    setEditAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: t("teams.teamProfile.Name"),
      minWidth: 280,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row?.profile_image_url}`}
              alt={row?.first_name}
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>
              {row?.first_name} {row?.father_name} {row?.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "gender",
      headerName: t("teams.teamProfile.Gender"),
      flex: 1
    },
    {
      field: "resignationDate",
      headerName: "Resignation Date",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {moment(row.team_member?.resigned_date).format("DD-MM-YYYY")}
          </Typography>
        );
      }
    },
    {
      field: "phone",
      headerName: t("teams.teamProfile.Phone"),
      minWidth: 150,
      flex: 1
    },
    {
      field: "email",
      headerName: t("teams.teamProfile.Email"),
      flex: 2
    },
    {
      field: "operation",
      headerName: t("teams.teamProfile.Operation"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton>
              <Chat />
            </IconButton>
            <IconButton
              onClick={event => {
                handleEditClick(event, row.id, row.first_name);
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      },
      flex: 2
    }
  ];

  return (
    <>
      <DeleteModal
        name={memberName}
        onDelete={() => {
          deleteMutation.mutate({
            churchId: selectedChurch?.id,
            teamId: id,
            memberId: memberId
          });
        }}
      />
      <DataGrid
        autoHeight
        rows={previousTeamMembersPaginatedQuery?.data?.result?.data || []}
        rowCount={previousTeamMembersPaginatedQuery?.data?.result?.total || 0}
        loading={previousTeamMembersPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
      <Popover
        anchorEl={editAnchorEl}
        id="account-menu"
        open={editOpen}
        onClose={handleEditClose}
        onClick={handleEditClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <TeamMembersTableEditDropdownMenu memberId={memberId} />
      </Popover>
    </>
  );
};
