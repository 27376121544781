import styled from "@emotion/styled";
import { Add, MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { DeleteModal, Divider, Notification } from "components/UI";
import { IMAGE_URL } from "config";
import { useUser } from "features/users";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import { useAclStore } from "stores/aclStore";
import { useChurches, useDeleteChurch } from "../api";
import { useChurchStore } from "../store";

const ChurchesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 2rem 0;

  ${({ theme }) => `${theme.breakpoints.up("sm")} {
    grid-template-columns: repeat(2, 1fr);
  }`}

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    grid-template-columns: repeat(3, 1fr);
  }`}

  ${({ theme }) => `${theme.breakpoints.up("lg")} {
    grid-template-columns: repeat(4, 1fr);
  }`}
`;

const Thumbnail = styled(Box)`
  background: ${({ theme }) => theme.palette.neutral.main};
  border-radius: 20px;
  padding: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: grid;
  place-items: center;

  ${({ theme }) => `${theme.breakpoints.up("sm")} {
    min-width: 200px;
    min-height: 200px;
  }`}
`;

const ThumbnailNoBg = styled(Box)`
  border-radius: 20px;
  padding: 40px;
  cursor: pointer;
  text-align: center;
`;

export function Churches() {
  const navigate = useNavigate();
  const churchesQuery = useChurches({
    filter: {
      my_churches: "1"
    }
  });
  const invitedChurchesQuery = useChurches({
    filter: {
      other_churches: "1"
    }
  });
  const selectChurch = useChurchStore(state => state.selectChurch);
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setRole = useAclStore(state => state.setRole);
  const setPermissions = useAclStore(state => state.setPermissions);
  const { t } = useTranslation();
  const [settingAnchorEl, setSettingAnchorEl] = useState(null);
  const settingOpen = Boolean(settingAnchorEl);
  const [selectedChurch, setSelectedChurch] = useState(null);
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const deleteChurchMutation = useDeleteChurch();

  const auth = useAuthUser();
  const loggedInUser = auth();

  const { data } = useUser({
    churchId: selectedChurch?.id,
    userId: loggedInUser?.id,
    config: {
      enabled: Boolean(selectedChurch)
    }
  });

  useEffect(() => {
    if (data) {
      setRole(data.result?.role?.name);
      setPermissions(data.result?.role?.permissions);
      navigate("/");
    }
  }, [data]);

  useTitle("Churches");

  const handleSettingOpen = (event, church) => {
    setSettingAnchorEl(event.currentTarget);
    setSelectedChurch(church);
  };

  const handleSettingClose = () => {
    setSettingAnchorEl(null);
    setSelectedChurch(null);
  };

  let churchesList;
  if (churchesQuery.data) {
    churchesList = churchesQuery.data?.result?.map(church => (
      <Thumbnail key={church.id}>
        <IconButton
          aria-label="settings"
          sx={{ position: "absolute", top: 0, right: 2 }}
          color="primary"
          onClick={event => handleSettingOpen(event, church)}
        >
          <MoreVert />
        </IconButton>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={1}
          onClick={() => {
            selectChurch(church);
            setSelectedChurch(church);
          }}
        >
          <Avatar
            sx={{
              width: "80px",
              height: "80px",
              border: "6px solid",
              borderColor: "primary.main",
              cursor: "pointer"
            }}
            src={`${IMAGE_URL}/${church.image_url}`}
            alt={church.name}
          />
          <Typography
            variant="button"
            sx={{
              cursor: "pointer",
              textTransform: "uppercase",
              fontWeight: 700
            }}
          >
            {church.name}
          </Typography>
        </Stack>
      </Thumbnail>
    ));
  }

  let invitedChurchesList;
  if (invitedChurchesQuery.data) {
    invitedChurchesList = invitedChurchesQuery.data?.result?.map(church => (
      <Thumbnail key={church.id}>
        <IconButton
          aria-label="settings"
          sx={{ position: "absolute", top: 0, right: 2 }}
          color="primary"
        >
          <MoreVert />
        </IconButton>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={1}
          onClick={() => {
            selectChurch(church);
            setSelectedChurch(church);
          }}
        >
          <Avatar
            sx={{
              width: "80px",
              height: "80px",
              border: "6px solid",
              borderColor: "primary.main",
              cursor: "pointer"
            }}
            src={`${IMAGE_URL}/${church.image_url}`}
            alt={church.name}
          />
          <Typography
            variant="button"
            sx={{
              cursor: "pointer",
              textTransform: "uppercase",
              fontWeight: 700
            }}
          >
            {church.name}
          </Typography>
        </Stack>
      </Thumbnail>
    ));
  }

  useEffect(() => {
    setHeaderTitle("");

    return () => setHeaderTitle("");
  }, []);

  return (
    <Box px={{ xs: 2, sm: 4 }} py={{ xs: 4 }}>
      <Notification />
      <DeleteModal
        name={selectedChurch?.name}
        onDelete={() => {
          deleteChurchMutation.mutate(
            {
              churchId: selectedChurch?.id
            },
            {
              onSuccess: () => {
                handleSettingClose();
              }
            }
          );
        }}
      />
      <Divider
        label={t("churches.churches.createdByYou")}
        position="left"
        variant="primary"
        weight="bold"
      />
      <ChurchesGrid>
        {churchesList}
        <ThumbnailNoBg onClick={() => navigate("/denominations")}>
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Avatar
              sx={{
                backgroundColor: "secondary.main",
                width: "80px",
                height: "80px",
                cursor: "pointer"
              }}
            >
              <Add color="primary" />
            </Avatar>
            <Typography
              variant="button"
              sx={{ textTransform: "uppercase", fontWeight: 700 }}
            >
              {t("churches.churches.createChurch")}
            </Typography>
          </Stack>
        </ThumbnailNoBg>
      </ChurchesGrid>
      <Divider
        label={t("churches.churches.invitedByOthers")}
        position="left"
        variant="primary"
        weight="bold"
      />
      <ChurchesGrid>{invitedChurchesList}</ChurchesGrid>

      <Popover
        anchorEl={settingAnchorEl}
        id="account-menu"
        open={settingOpen}
        onClose={handleSettingClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 1
        }}
      >
        <Box>
          <Stack
            sx={{
              minWidth: "200px",
              backgroundColor: "white",
              p: 1
            }}
            justifyContent="space-between"
          >
            <Typography
              sx={{
                cursor: "pointer",
                p: 1,
                "&:hover": {
                  backgroundColor: "#EEEEEE"
                }
              }}
              onClick={() => {
                selectChurch(selectedChurch);
                navigate(`/churches/${selectedChurch?.id}`);
              }}
            >
              Edit church
            </Typography>
            <Typography
              sx={{
                color: "error.main",
                cursor: "pointer",
                p: 1,
                "&:hover": {
                  backgroundColor: "#EEEEEE"
                }
              }}
              onClick={openDeleteModal}
            >
              Delete church
            </Typography>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
}
