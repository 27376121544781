import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useBankStore = create(
  devtools(
    set => ({
      createBankAccountOpen: false,
      updateBankAccountOpen: false,
      openCreateBankAccountModal: () => set({ createBankAccountOpen: true }),
      closeCreateBankAccountModal: () => set({ createBankAccountOpen: false }),
      openUpdateBankAccountModal: () => set({ updateBankAccountOpen: true }),
      closeUpdateBankAccountModal: () => set({ updateBankAccountOpen: false })
    }),
    {
      name: "bank-store"
    }
  )
);
