import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack
} from "@mui/material";
import { Upload } from "components/UI";
import { useCountries } from "features/members";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

export const PersonalInfoForm = ({ register, errors, control, setValue }) => {
  const { t } = useTranslation();

  const countriesQuery = useCountries();

  useEffect(() => {
    if (countriesQuery.data) {
      setValue("nationality_id", "66");
    }
  }, [countriesQuery.data]);

  return (
    <Stack spacing={2}>
      <Field>
        <Label htmlFor="employee_id">{t("HR.PersonalInfo.Employee ID")}</Label>
        <Input id="employee_id" {...register("employee_id")} />
        <ErrorMessage
          errors={errors}
          name="employee_id"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Field>
          <Label htmlFor="title">{t("HR.PersonalInfo.Salutation")}</Label>
          <Input id="title" {...register("title")} />
          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="first_name">{t("HR.PersonalInfo.FirstName")}</Label>
          <Input id="first_name" {...register("first_name")} />
          <ErrorMessage
            errors={errors}
            name="first_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="middle_name">{t("HR.PersonalInfo.MiddleName")}</Label>
          <Input id="middle_name" {...register("middle_name")} />
          <ErrorMessage
            errors={errors}
            name="middle_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="last_name">{t("HR.PersonalInfo.LastName")}</Label>
          <Input id="last_name" {...register("last_name")} />
          <ErrorMessage
            errors={errors}
            name="last_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="gender">{t("form.common.gender")}</Label>
        <Select id="gender" {...register("gender")}>
          <option value="FEMALE">{t("form.common.female")}</option>
          <option value="MALE">{t("form.common.male")}</option>
        </Select>
        <ErrorMessage
          errors={errors}
          name="gender"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="date_of_birth">
          {t("HR.PersonalInfo.DateOfBirth")}
        </Label>
        <Input id="date_of_birth" {...register("date_of_birth")} type="date" />
        <ErrorMessage
          errors={errors}
          name="date_of_birth"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="nationality_id">
          {t("HR.PersonalInfo.Nationality")}
        </Label>
        <Select id="nationality_id" {...register("nationality_id")}>
          {countriesQuery.data?.result?.map(country => (
            <option key={country.id} value={country.id}>
              {country.nationality}
            </option>
          ))}
        </Select>
        <ErrorMessage
          errors={errors}
          name="nationality_id"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <FormControl>
        <FormLabel sx={{ fontSize: "12px" }}>
          {t("HR.PersonalInfo.MaritalStatus")}
        </FormLabel>
        <Controller
          control={control}
          name="marital_status"
          render={({ field }) => (
            <RadioGroup row {...field}>
              <FormControlLabel
                value="SINGLE"
                control={<Radio size="small" />}
                label={t("addMembers.step3.Single")}
              />
              <FormControlLabel
                value="MARRIED"
                control={<Radio size="small" />}
                label={t("addMembers.step3.Married")}
              />
              <FormControlLabel
                value="DIVORCED"
                control={<Radio size="small" />}
                label={t("addMembers.step3.Divorced")}
              />
              <FormControlLabel
                value="WIDOWED"
                control={<Radio size="small" />}
                label={t("addMembers.step3.Widowed")}
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <Controller
        name="employee_photo"
        control={control}
        render={({ field }) => (
          <Upload
            title={t("addMembers.step1.UploadPhoto")}
            buttonTitle={t("ui.dropzone.browse")}
            error={!!errors.employee_photo?.message}
            helperText={errors.employee_photo?.message}
            onChange={field.onChange}
          />
        )}
      />
    </Stack>
  );
};
