import styled from "@emotion/styled";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Stack, Typography } from "@mui/material";
import { Button } from "components/UI";
import { useErrorBoundary } from "react-error-boundary";

const ErrorContainer = styled.div`
  min-height: 100vh;
  display: grid;
  place-items: center;
  background-color: white;
  color: black;
  padding: 16px 32px;
`;

const ErrorText = styled.p`
  text-align: justify;
  max-width: 900px;
  line-height: 32px;
`;

export const Error = () => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <ErrorContainer>
      <Stack alignItems="center" spacing={2}>
        <HeartBrokenIcon color="error" fontSize="large" />
        <Typography variant="h4" fontWeight={700} textAlign="center">
          Something went wrong
        </Typography>
        <ErrorText>
          Sorry for the inconvenience, but it appears that the system ran into
          problems. Try to refresh the page. If the problem persists feel free
          to contact us for further assistance in resolving the issue.
        </ErrorText>
        <Button startIcon={<RefreshIcon />} onClick={resetBoundary}>
          Refresh
        </Button>
      </Stack>
    </ErrorContainer>
  );
};
