import { Visibility } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useChurchStore } from "features/churches";
import { useEmployeesPaginated } from "features/hr";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export const EmployeesTable = () => {
  const { t } = useTranslation();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [page, setPage] = useState(Number(queryParams.page) || 1);
  const [pageSize, setPageSize] = useState(Number(queryParams.pageSize) || 10);

  const navigate = useNavigate();

  useEffect(() => {
    const query = queryString.stringify({ page, pageSize });
    navigate(`?${query}`, { replace: true });
  }, [page, pageSize, navigate]);

  const employeesPaginatedQuery = useEmployeesPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize
  });

  const columns = [
    {
      field: "employee_id",
      headerName: t("HR.HR.EmployeeID"),
      flex: 1
    },
    {
      field: "name",
      headerName: t("HR.HR.Name"),
      flex: 2,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {row.first_name} {row.middle_name} {row.last_name}
        </Typography>
      )
    },
    {
      field: "gross_salary",
      headerName: t("HR.HR.Gross"),
      flex: 1
    },
    {
      field: "penalty",
      headerName: t("HR.HR.Deduction"),
      flex: 1
    },
    {
      field: "bonus",
      headerName: t("HR.HR.Benefit"),
      flex: 1
    },
    {
      field: "net_salary",
      headerName: t("HR.HR.Net"),
      flex: 1
    },
    {
      field: "status",
      headerName: t("Asset.Asset.Status"),
      flex: 1
    },
    {
      field: "created_at",
      headerName: t("HR.HR.Date"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography variant="body2">
            {moment(row.created_at).format("DD-MM-YYYY")}
          </Typography>
        );
      }
    },
    {
      field: "operation",
      headerName: t("systemAdmins.roles.Operations"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {/* <IconButton>
              <Edit />
            </IconButton> */}
            <IconButton
              onClick={() => {
                navigate(`/hr/${row.id}`);
              }}
            >
              <Visibility />
            </IconButton>
          </Stack>
        );
      },
      flex: 1
    }
  ];

  return (
    <>
      {/* <DeleteModal
        name={role?.name}
        onDelete={() => {
          deleteMutation.mutate({
            churchId: selectedChurch?.id,
            roleId: role?.id
          });
        }}
      /> */}
      <DataGrid
        autoHeight
        rows={employeesPaginatedQuery?.data?.result?.data || []}
        rowCount={employeesPaginatedQuery?.data?.result?.total || 0}
        loading={employeesPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
    </>
  );
};
