import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPublicRegions = ({ countryId }) => {
  return axios.get(`/public/addresses/countries/${countryId}/regions`);
};

export const usePublicRegions = ({ countryId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["public-regions", countryId],
    queryFn: () => getPublicRegions({ countryId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
