import axios from "axios";
import { API_URL } from "config";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { getToken } from "utils/getToken";

export const importService = async ({
  type = "members",
  churchId,
  data,
  onProgress
}) => {
  try {
    const response = await axios({
      method: "post",
      url: `${API_URL}/churches/${churchId}/${type}-import`,
      data: data,
      headers: {
        Authorization: getToken()
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        if (onProgress) {
          onProgress(percentCompleted);
        }
      }
    });

    if (type === "children") {
      queryClient.invalidateQueries("kids");
    } else {
      queryClient.invalidateQueries(type);
    }

    return response.data;
  } catch (error) {
    const setNotification = useCentralStore.getState().setNotification;

    if (
      error?.response?.data?.errors?.includes("unauthorized") ||
      error?.response?.status === 403
    ) {
      setNotification({
        type: "error",
        title: "Unauthorized",
        message: "You are unauthorized from performing this action."
      });
    } else {
      setNotification({
        type: "error",
        message: "Unable to import data"
      });
    }
  }
};
