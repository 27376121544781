import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import { Button } from "components/UI";
import { useMembersStore } from "features/members";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import { maritalStatusSchema } from "./schema";
import {
  ChildAndDependencySection,
  ChildInfoSection,
  MaritalStatusSection
} from "./sections";

export function PublicMaritalStatusForm() {
  const { t } = useTranslation();
  const { prev, next } = useSteps();
  const memberData = useMembersStore(state => state.memberData);
  const setMemberData = useMembersStore(state => state.setMemberData);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    control
  } = useForm({
    defaultValues: {
      marital_status: memberData.marital_status
        ? memberData.marital_status
        : "",
      marital_date: memberData.marital_date ? memberData.marital_date : null,
      type_of_marriage: memberData.type_of_marriage
        ? memberData.type_of_marriage
        : "",
      is_spouse_believer: memberData.is_spouse_believer
        ? memberData.is_spouse_believer
        : "1",
      spouse_religion: memberData.spouse_religion
        ? memberData.spouse_religion
        : "",
      is_spouse_member: memberData.is_spouse_member
        ? memberData.is_spouse_member
        : "0",
      spouse_member: memberData.spouse_member ? memberData.spouse_member : "",
      spouse_church_id: memberData.spouse_church_id
        ? memberData.spouse_church_id
        : "",
      spouse_first_name: memberData.spouse_first_name
        ? memberData.spouse_first_name
        : "",
      spouse_father_name: memberData.spouse_father_name
        ? memberData.spouse_father_name
        : "",
      spouse_last_name: memberData.spouse_last_name
        ? memberData.spouse_last_name
        : "",
      spouse_phone_number: memberData.spouse_phone_number
        ? memberData.spouse_phone_number
        : "",
      spouse_date_of_birth: memberData.spouse_date_of_birth
        ? memberData.spouse_date_of_birth
        : null,
      spouse_mother_name: memberData.spouse_mother_name
        ? memberData.spouse_mother_name
        : "",
      spouse_mother_father_name: memberData.spouse_mother_father_name
        ? memberData.spouse_mother_father_name
        : "",
      spouse_mother_grand_father_name:
        memberData.spouse_mother_grand_father_name
          ? memberData.spouse_mother_grand_father_name
          : "",
      spouse_professional_skill: memberData.spouse_professional_skill
        ? memberData.spouse_professional_skill
        : "",
      spouse_office_name: memberData.spouse_office_name
        ? memberData.spouse_office_name
        : "",
      spouse_church_name: memberData.spouse_church_name
        ? memberData.spouse_church_name
        : "",
      child_and_dependencies: memberData.child_and_dependencies
        ? memberData.child_and_dependencies
        : "",
      children: memberData.children
        ? memberData.children
        : [
            {
              first_name: "",
              father_name: "",
              last_name: "",
              date_of_birth: null,
              nationality_id: "",
              gender: "",
              relationship: "",
              mother_name: "",
              mother_father_name: "",
              mother_last_name: "",
              mother_country_id: "",
              mother_region_id: "",
              mother_city_id: "",
              is_believer: "1",
              religion: "",
              church_name: "",
              is_member: "0",
              member_id: "",
              is_kid: "0"
            }
          ]
    },
    resolver: yupResolver(maritalStatusSchema)
  });

  const onSubmit = data => {
    setMemberData(data);
    next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MaritalStatusSection
        register={register}
        errors={errors}
        watch={watch}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <ChildAndDependencySection register={register} errors={errors} />
      <Box sx={{ my: 3 }} />
      {watch("child_and_dependencies") === "1" && (
        <ChildInfoSection
          register={register}
          errors={errors}
          watch={watch}
          control={control}
          setValue={setValue}
        />
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 8 }}
      >
        <Button
          type="submit"
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={prev}
        >
          {t("form.common.back")}
        </Button>
        <Button type="submit" variant="contained" endIcon={<ArrowForward />}>
          {t("form.common.next")}
        </Button>
      </Stack>
    </form>
  );
}
