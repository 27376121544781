import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const createApprover = ({ churchId, approverData }) => {
  return axios.post(`/churches/${churchId}/approvers`, approverData);
};

export const useCreateApprover = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateApproverModal =
    useFinanceSettingsStore.getState().closeCreateApproverModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("approvers");

      setNotification({
        type: "success",
        message: "Approver created successfully!"
      });

      closeCreateApproverModal();
    },
    ...config,
    mutationFn: createApprover
  });
};
