import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useDeleteChartOfAccount,
  useFinanceSettingsStore,
  useUpdateChartOfAccount
} from "features/finances";
import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const HelperText = styled.span`
  font-size: 10px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

const chartOfAccountSchema = yup.object({
  accountNumber: yup.string().required("Account Number is required"),
  accountName: yup.string().required("Account Name is required"),
  accountType: yup.string().required("Account Type is required"),
  description: yup.string().required("Description is required"),
  beginningBalance: yup.number().required("Beginning Balance is required"),
  beginningBalanceDate: yup
    .string()
    .required("Beginning Balance Date is required")
  // accountStatus: yup.string()
});

export function UpdateChartOfAccountModal({ data }) {
  const updateChartOfAccountOpen = useFinanceSettingsStore(
    state => state.updateChartOfAccountOpen
  );
  const closeUpdateChartOfAccountModal = useFinanceSettingsStore(
    state => state.closeUpdateChartOfAccountModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const updateChartOfAccountMutation = useUpdateChartOfAccount();
  const deleteChartOfAccountMutation = useDeleteChartOfAccount();

  const accountTypes = [
    "BANK",
    "ASSET",
    "LIABILITY",
    "EQUITY",
    "INCOME",
    "EXPENSE"
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      accountNumber: data?.account_number,
      accountName: data?.account_name,
      accountType: data?.account_type,
      description: data?.description,
      beginningBalance: data?.beginning_balance,
      beginningBalanceDate: moment(data?.beginning_balance_date).format(
        "YYYY-MM-DD"
      )
      // accountStatus: data?.account_status
    },
    resolver: yupResolver(chartOfAccountSchema)
  });

  useEffect(() => {
    if (data) {
      setValue("accountNumber", data?.account_number);
      setValue("accountName", data?.account_name);
      setValue("accountType", data?.account_type);
      setValue("description", data?.description);
      setValue("beginningBalance", data?.beginning_balance);
      setValue(
        "beginningBalanceDate",
        moment(data?.beginning_balance_date).format("YYYY-MM-DD")
      );
      // setValue("accountStatus", data?.account_status);
    }
  }, [data]);

  const onSubmit = values => {
    updateChartOfAccountMutation.mutate(
      {
        churchId: selectedChurch?.id,
        chartOfAccountId: data?.id,
        chartOfAccountData: {
          account_number: values.accountNumber,
          account_name: values.accountName,
          account_type: values.accountType,
          description: values.description,
          beginning_balance: values.beginningBalance,
          beginning_balance_date: values.beginningBalanceDate
          // account_status: values.accountStatus
        }
      },
      {
        onSuccess: () => {
          reset();
        },
        onError: response => {
          console.log(response.errors);
        }
      }
    );
  };

  return (
    <BaseModal
      title="Account"
      isOpen={updateChartOfAccountOpen}
      onClose={closeUpdateChartOfAccountModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            flavor="neutral"
            size="small"
            onClick={() => {
              deleteChartOfAccountMutation.mutate({
                churchId: selectedChurch?.id,
                chartOfAccountId: data?.id
              });
            }}
          >
            Delete
          </Button>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button flavor="white" size="small">
              Cancel
            </Button>
            <Button size="small" onClick={handleSubmit(onSubmit)}>
              Save Changes
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="accountNumber">Account Number</Label>
        <Input id="accountNumber" {...register("accountNumber")} />
        <ErrorMessage
          errors={errors}
          name="accountNumber"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="accountName">Account Name</Label>
        <Input id="accountName" {...register("accountName")} />
      </Field>
      <Field>
        <Label htmlFor="accountType">Account Type</Label>
        <Select id="accountType" {...register("accountType")}>
          {accountTypes.map(accountType => (
            <option key={accountType} value={accountType}>
              {accountType}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="description">Description</Label>
        <Input id="description" {...register("description")} />
      </Field>
      <Field>
        <Label htmlFor="beginningBalance">Beginning Balance</Label>
        <Input
          id="beginningBalance"
          type="number"
          {...register("beginningBalance")}
        />
      </Field>
      <Field>
        <Label htmlFor="beginningBalanceDate">Beginning Balance Date</Label>
        <Input
          id="beginningBalanceDate"
          type="date"
          {...register("beginningBalanceDate")}
        />
      </Field>
      {/* <Field>
        <Label htmlFor="accountStatus">Account Status</Label>
        <Select id="accountStatus" {...register("accountStatus")}>
          <option value="ACTIVE">ACTIVE</option>
          <option value="INACTIVE">INACTIVE</option>
        </Select>
      </Field> */}
    </BaseModal>
  );
}
