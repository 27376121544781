import { AssetProfile, Assets } from "features/assets";
import {
  ChurchLayout,
  ChurchProfile,
  ChurchProfileLayout,
  Churches,
  CreateChurch
} from "features/churches";
import {
  Dashboard,
  DashboardLayout,
  SuperAdminDashboard
} from "features/dashboard";
import {
  CreateDenomination,
  DenominationProfile,
  DenominationProfileLayout,
  Denominations
} from "features/denominations";
import {
  Bank,
  BankSettings,
  Budget,
  BudgetDetails,
  ChequeReconcile,
  Expense,
  ExpenseSettings,
  ExpenseSettlement,
  ExpenseSettlementApproval,
  FinanceSettings,
  Finances,
  FinancesLayout,
  Overview,
  Payroll,
  Report,
  Requisition,
  Revenue,
  RevenueSettings
} from "features/finances";
import { HR, HRProfile } from "features/hr";
import {
  AddKid,
  AddMember,
  FilterMembers,
  IDCreation,
  KidProfile,
  KidUpdate,
  MemberProfileV2,
  MemberUpdate,
  Members,
  ViewData
} from "features/members";
import {
  AllMessages,
  Drafts,
  Inboxes,
  MessageSettings,
  Messages,
  NewMessage,
  ScheduledMessages,
  SentMessages,
  SmsStatus,
  Templates
} from "features/messages";
import { Settings } from "features/settings";
import { Team, Teams } from "features/teams";
import {
  CreateNewRole,
  EditRole,
  EditUserProfile,
  UserProfile,
  UserProfileLayout,
  Users,
  ViewUserProfile
} from "features/users";
import { MainLayout } from "layouts";
import { Navigate } from "react-router-dom";

export const protectedRoutes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "finances", element: <Finances /> },
      { path: "members/view", element: <ViewData /> },
      { path: "members/:id", element: <MemberProfileV2 /> },
      { path: "members/:id/id", element: <IDCreation /> },
      { path: "kids/add", element: <AddKid /> },
      { path: "kids/:id", element: <KidProfile /> },
      { path: "kids/:id/update", element: <KidUpdate /> }
    ]
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "members", element: <Members /> },
      { path: "teams", element: <Teams /> },
      { path: "users", element: <Users /> },
      { path: "roles/create", element: <CreateNewRole /> },
      { path: "roles/:id", element: <EditRole /> },
      { path: "settings", element: <Settings /> },
      { path: "i3iwz2xNLNF8frehczgD", element: <SuperAdminDashboard /> },
      { path: "hr", element: <HR /> },
      { path: "hr/:id", element: <HRProfile /> },
      { path: "assets", element: <Assets /> },
      { path: "assets/:id", element: <AssetProfile /> }
    ]
  },
  {
    path: "/",
    element: <MainLayout hasBack={false} />,
    children: [
      {
        path: "messages/*",
        element: <Messages />,
        children: [
          { path: "all", element: <AllMessages /> },
          { path: "all/:id", element: <AllMessages /> },
          { path: "draft", element: <Drafts /> },
          { path: "draft/:id", element: <Drafts /> },
          { path: "inbox", element: <Inboxes /> },
          { path: "inbox/:id", element: <Inboxes /> },
          { path: "new", element: <NewMessage /> },
          { path: "scheduled", element: <ScheduledMessages /> },
          { path: "scheduled/:id", element: <ScheduledMessages /> },
          { path: "sent", element: <SentMessages /> },
          { path: "sent/:id", element: <SentMessages /> },
          { path: "status", element: <SmsStatus /> },
          { path: "template", element: <Templates /> },
          { path: "settings", element: <MessageSettings /> },
          { path: "*", element: <Navigate to="./new" /> }
        ]
      }
    ]
  },
  {
    path: "/",
    element: <UserProfileLayout />,
    children: [
      { path: "profile", element: <UserProfile /> },
      {
        path: "users/:id",
        element: <ViewUserProfile />
      },
      {
        path: "users/:id/update",
        element: <EditUserProfile />
      }
    ]
  },
  {
    path: "/denominations",
    element: <ChurchLayout hasBack={true} />,
    children: [
      { path: "", element: <Denominations /> },
      { path: "create", element: <CreateDenomination /> }
    ]
  },
  {
    path: "/denominations",
    element: <DenominationProfileLayout />,
    children: [{ path: ":id", element: <DenominationProfile /> }]
  },
  {
    path: "/churches",
    element: <ChurchLayout />,
    children: [{ path: "", element: <Churches /> }]
  },
  {
    path: "/churches",
    element: <ChurchLayout hasBack={true} />,
    children: [{ path: "create", element: <CreateChurch /> }]
  },
  {
    path: "/churches",
    element: <ChurchProfileLayout />,
    children: [{ path: ":id", element: <ChurchProfile /> }]
  },
  {
    path: "/members",
    element: <MainLayout />,
    children: [
      { path: "add", element: <AddMember /> },
      // { path: ":id", element: <MemberProfile /> },
      { path: ":id/update", element: <MemberUpdate /> },
      { path: "filter", element: <FilterMembers /> }
    ]
  },
  {
    path: "/teams",
    element: <MainLayout />,
    children: [{ path: ":id", element: <Team /> }]
  },
  {
    path: "/finances/temp",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <FinancesLayout />,
        children: [
          { path: "overview", element: <Overview /> },
          { path: "revenue", element: <Revenue /> },
          { path: "expense", element: <Expense /> },
          { path: "expense/requisition", element: <Requisition /> },
          { path: "payroll", element: <Payroll /> },
          { path: "budget", element: <Budget /> },
          { path: "budget/:id", element: <BudgetDetails /> },
          { path: "report", element: <Report /> },
          { path: "bank", element: <Bank /> },
          { path: "setting", element: <FinanceSettings /> }
        ]
      },
      { path: "revenue/settings", element: <RevenueSettings /> },
      { path: "revenue/cheque-reconcile", element: <ChequeReconcile /> },
      { path: "expense/settings", element: <ExpenseSettings /> },
      {
        path: "expense/:id/settlement",
        element: <ExpenseSettlement />
      },
      {
        path: "expense/:id/settlement/approval",
        element: <ExpenseSettlementApproval />
      },
      { path: "bank/settings", element: <BankSettings /> }
    ]
  }
];
