import styled from "@emotion/styled";
import { Box, Modal, Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BsEnvelope,
  BsInfoCircle,
  BsTelegram,
  BsWhatsapp,
  BsXSquare
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton
} from "react-share";
import { useCopyToClipboard } from "react-use";
import { printKidForm, printMemberForm } from "../api";
import { useMembersStore } from "../stores";

const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  padding: 0 20px;

  .startIcon {
    min-height: 24px;
    min-width: 24px;
    background-color: #3186ea;
    display: grid;
    place-items: center;
    font-size: 14px;
    border-radius: 100%;
    color: #ffffff;
  }
`;

const CloseIcon = styled(BsXSquare)`
  color: ${({ theme }) => theme.palette.primary.main};
  height: 22px;
  width: 22px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
`;

const Subtitle = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
`;

const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const SocialIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #f8f8f8;
  display: grid;
  place-items: center;
  border: 1px solid ${({ selected }) => (selected ? "#00B0B9" : "#f8f8f8")};
`;

const SocialName = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #8e8e8e;
`;

const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  padding-block: 9px;
  padding-inline: 13px 6px;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
`;

const URLText = styled.span`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  flex: 1;
`;

const VIEWS = {
  DEFAULT: "default",
  FORM: "form"
};

export function ApplicationFormModal() {
  const applicationFormModalOpen = useMembersStore(
    state => state.applicationFormModalOpen
  );
  const closeApplicationFormModal = useMembersStore(
    state => state.closeApplicationFormModal
  );
  const [views, setViews] = useState(VIEWS.DEFAULT);
  const { t } = useTranslation();

  const TITLES = {
    [VIEWS.DEFAULT]: t("members.applicationForm.Title"),
    [VIEWS.FORM]: t("members.applicationForm.ShareTitle")
  };

  useEffect(() => {
    if (!applicationFormModalOpen) {
      setViews(VIEWS.DEFAULT);
    }
  }, [applicationFormModalOpen]);

  return (
    <Modal open={applicationFormModalOpen} onClose={closeApplicationFormModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "530px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            py: 2,
            px: 1,
            backgroundColor: "white",
            color: "black",
            borderBottom: "1px solid #E9E9E9"
          }}
        >
          <InformationContainer>
            <span className="startIcon">
              <BsInfoCircle />
            </span>
            <span>{TITLES[views]}</span>
            <CloseIcon onClick={closeApplicationFormModal} />
          </InformationContainer>
        </Box>
        {views === VIEWS.DEFAULT && <OptionsView setViews={setViews} />}
        {views === VIEWS.FORM && <GeneratedFormView />}
      </Box>
    </Modal>
  );
}

const OptionsView = ({ setViews }) => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const closeApplicationFormModal = useMembersStore(
    state => state.closeApplicationFormModal
  );

  const handleAddMember = () => {
    navigate("/members/add");
    closeApplicationFormModal();
  };

  const handlePrintMemberForm = () => {
    printMemberForm({
      churchId: selectedChurch?.id
    });
  };

  const handlePrintKidForm = () => {
    printKidForm({
      churchId: selectedChurch?.id
    });
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        p: 10,
        backgroundColor: "white"
      }}
      spacing={2}
    >
      <Stack spacing={2} sx={{ width: "210px" }}>
        <Button onClick={handleAddMember}>
          {t("members.members.addMember")}
        </Button>
        <Button onClick={handlePrintMemberForm}>
          {t("members.applicationForm.PrintMemberForm")}
        </Button>
        <Button onClick={handlePrintKidForm}>
          {t("members.applicationForm.PrintKidForm")}
        </Button>
        <Button onClick={() => setViews(VIEWS.FORM)}>
          {t("members.applicationForm.GenerateFormLink")}
        </Button>
      </Stack>
    </Stack>
  );
};

const GeneratedFormView = () => {
  const [selectedSocial, setSelectedSocial] = useState("");
  const [copiedLink, copyLink] = useCopyToClipboard();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const host = window.location.host;
  const protocol = window.location.protocol;
  const URL = `${protocol}//${host}/forms/${selectedChurch?.id}/${selectedChurch?.meta?.code}`;
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        p: 3,
        backgroundColor: "white"
      }}
      spacing={3}
    >
      <Stack spacing={1}>
        <Subtitle>{t("members.applicationForm.ShareThis")}</Subtitle>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Social onClick={() => setSelectedSocial("Email")}>
            <EmailShareButton url={URL}>
              <SocialIcon selected={selectedSocial === "Email"}>
                <BsEnvelope />
              </SocialIcon>
            </EmailShareButton>
            <SocialName>{t("members.applicationForm.Email")}</SocialName>
          </Social>
          <Social onClick={() => setSelectedSocial("Telegram")}>
            <TelegramShareButton url={URL}>
              <SocialIcon selected={selectedSocial === "Telegram"}>
                <BsTelegram />
              </SocialIcon>
            </TelegramShareButton>
            <SocialName>{t("members.applicationForm.Telegram")}</SocialName>
          </Social>
          <Social onClick={() => setSelectedSocial("WhatsApp")}>
            <WhatsappShareButton url={URL}>
              <SocialIcon selected={selectedSocial === "WhatsApp"}>
                <BsWhatsapp />
              </SocialIcon>
            </WhatsappShareButton>
            <SocialName>{t("members.applicationForm.WhatsApp")}</SocialName>
          </Social>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Subtitle>{t("members.applicationForm.CopyLink")}</Subtitle>
        <CopyContainer>
          <URLText>{URL}</URLText>
          <Button onClick={() => copyLink(URL)}>
            {copiedLink.value
              ? t("members.applicationForm.Copied")
              : t("members.applicationForm.Copy")}
          </Button>
        </CopyContainer>
      </Stack>
    </Stack>
  );
};
