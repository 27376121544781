import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPublicCities = ({ zoneId }) => {
  return axios.get(`/public/addresses/zones/${zoneId}/cities`);
};

export const usePublicCities = ({ zoneId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["public-cities", zoneId],
    queryFn: () => getPublicCities({ zoneId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
