import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useAssetsStore } from "../store";

export const createAsset = ({ churchId, assetData }) => {
  return axios.post(`/churches/${churchId}/assets`, assetData);
};

export const useCreateAsset = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeAddAssetModal = useAssetsStore.getState().closeAddAssetModal;
  const setAddAssetFormActiveStep =
    useAssetsStore.getState().setAddAssetFormActiveStep;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("assets");

      setNotification({
        type: "success",
        message: "Asset created successfully!"
      });

      closeAddAssetModal();
      setAddAssetFormActiveStep(1);
    },
    ...config,
    mutationFn: createAsset
  });
};
