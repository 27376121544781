import _ from "lodash";

export const removeEmptyValues = formData => {
  const data = Object.fromEntries(formData);

  for (const key in data) {
    if (
      _.isEmpty(data[key]) ||
      _.isNil(data[key]) ||
      _.isEqual(data[key], "null") ||
      _.isEqual(data[key], "undefined")
    ) {
      delete data[key];
      formData.delete(key);
    }
  }
};
