import { Edit } from "@mui/icons-material";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useKidsPaginated } from "../api";
import { useMembersStore } from "../stores";
import { extraKidColumns } from "../utils/kidColumns";

export const KidsTable = ({ isFilterMode = false }) => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const queries = useMembersStore(state => state.queries);
  const setQueries = useMembersStore(state => state.setQueries);
  const setKidData = useMembersStore(state => state.setKidData);
  const kidColumnsVisible = useMembersStore(state => state.kidColumnsVisible);

  const kidsPaginatedQuery = useKidsPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize,
    queries: queries
  });

  const columns = [
    {
      field: "first_name",
      headerName: t("members.membersTable.name"),
      minWidth: 350,
      flex: 4,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row.profile_picture_kid}`}
              alt={_.capitalize(row.first_name)}
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>
              {row.first_name} {row.father_name} {row.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "gender",
      headerName: t("members.membersTable.gender"),
      flex: 1,
      minWidth: 120
    },
    {
      field: "age",
      headerName: t("members.kidsTable.Age"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.date_of_birth &&
              moment().diff(moment(row?.date_of_birth), "years")}
          </Typography>
        );
      },
      minWidth: 120
    },
    {
      field: "parent",
      headerName: t("members.kidsTable.Parent"),
      flex: 6,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack>
            <Typography variant="caption">
              {row.primary_first_name} {row.primary_father_name}{" "}
              {row.primary_last_name}
            </Typography>
            <Typography variant="caption">
              {row.secondary_first_name} {row.secondary_father_name}{" "}
              {row.secondary_last_name}
            </Typography>
          </Stack>
        );
      },
      minWidth: 120
    },
    {
      field: "phone",
      headerName: t("members.membersTable.phone"),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack>
            <Typography variant="caption">{row.primary_phone}</Typography>
            <Typography variant="caption">{row.secondary_phone}</Typography>
          </Stack>
        );
      }
    },
    {
      field: "operation",
      headerName: t("members.membersTable.operation"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setKidData(row);
                navigate(`/kids/${row.id}/update`);
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      },
      flex: 1,
      minWidth: 120
    },
    ...extraKidColumns
  ];

  const handleSortModelChange = async sortModel => {
    const field = sortModel[0]?.field;
    const sort = sortModel[0]?.sort;

    if (field || sort) {
      if (field === "age") {
        setQueries({
          ...queries,
          sort: {
            date_of_birth: sort
          }
        });
      } else {
        setQueries({
          ...queries,
          sort: {
            [field]: sort
          }
        });
      }
    } else {
      setQueries({
        filter: {}
      });
    }
  };

  return (
    <>
      <DataGrid
        autoHeight
        rows={kidsPaginatedQuery?.data?.result?.data || []}
        rowCount={kidsPaginatedQuery?.data?.result?.total || 0}
        loading={kidsPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection={isFilterMode}
        onRowClick={({ row }) => {
          setKidData(row);
          navigate(`/kids/${row.id}`);
        }}
        paginationMode="server"
        sortingMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage + 1)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
        columnVisibilityModel={kidColumnsVisible}
      />
    </>
  );
};
