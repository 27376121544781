import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import DatePicker from "react-datepicker";

const PickerWrapper = styled.div`
  & .react-datepicker__header {
    background: ${({ theme }) => theme.palette.secondary.main};
  }

  & .react-datepicker__current-month {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const Picker = styled(DatePicker)`
  background: ${({ theme }) => theme.palette.secondary.main};
  color: #000000;
  font: inherit;
  letter-spacing: inherit;
  border: none;
  margin: 0;
  min-width: 0;
  padding: 8.5px 14px;
  border-radius: 8px;
  outline: none;
`;

export const DateInputField = ({
  label,
  isLabelBold,
  error,
  helperText,
  value,
  onChange,
  ...rest
}) => {
  return (
    <Stack width="100%">
      {!!label && (
        <Typography
          variant="subtitle2"
          component="label"
          sx={{ fontWeight: isLabelBold && 700 }}
        >
          {label}
        </Typography>
      )}
      <PickerWrapper>
        <Picker
          showIcon
          selected={value}
          onChange={onChange}
          showPopperArrow={false}
          {...rest}
        />
      </PickerWrapper>
      {error && (
        <Typography
          variant="caption"
          color="error"
          sx={{ padding: "0 0.75rem" }}
        >
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};
