import { Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Stack
} from "@mui/material";
import { BackgroundInputField } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useAddTeamMember, useTeams } from "features/teams";
import Fuse from "fuse.js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FixedSizeList } from "react-window";
import { useMembersStore } from "../stores";

export function AddTeamModal({ onSelect }) {
  const addTeamModalOpen = useMembersStore(state => state.addTeamModalOpen);
  const closeAddTeamModal = useMembersStore(state => state.closeAddTeamModal);
  const memberToAssignTeam = useMembersStore(state => state.memberToAssignTeam);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const addTeamMember = useAddTeamMember();
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const teamsQuery = useTeams({
    churchId: selectedChurch?.id
  });
  const [searchTerm, setSearchTerm] = useState("");
  const setMemberToAssignTeam = useMembersStore(
    state => state.setMemberToAssignTeam
  );
  const { pathname } = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    setTeams(teamsQuery?.data?.result);
    setFilteredTeams(teamsQuery?.data?.result);
  }, [teamsQuery.data]);

  const addTeam = teamId => {
    if (pathname.includes("/filter")) {
      onSelect(teamId);
    } else {
      addTeamMember.mutate(
        {
          churchId: selectedChurch?.id,
          teamId: teamId,
          memberData: {
            member_ids: [memberToAssignTeam]
          }
        },
        {
          onSuccess: () => {
            closeAddTeamModal();
            setMemberToAssignTeam("");
          }
        }
      );
    }
  };

  const searchTeams = () => {
    const fuse = new Fuse(teams, {
      threshold: 0.3,
      keys: ["name"]
    });

    if (_.isEmpty(searchTerm)) {
      setFilteredTeams(teams);
      return;
    }

    const searchResults = fuse.search(searchTerm);

    if (_.isEmpty(searchResults)) {
      setFilteredTeams([]);
    } else {
      setFilteredTeams([
        ..._.map(fuse.search(searchTerm), foundTerm => foundTerm.item)
      ]);
    }
  };

  return (
    <Modal open={addTeamModalOpen} onClose={closeAddTeamModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 4,
          width: "800px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("teams.teamProfile.AddTeamMember")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
        >
          <BackgroundInputField
            variant="outlined"
            placeholder={t("teams.teams.SearchTeams")}
            sx={{
              backgroundColor: "neutral.main"
            }}
            neutral={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            onKeyUp={searchTeams}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <FixedSizeList
            height={400}
            itemSize={60}
            itemData={filteredTeams}
            itemCount={filteredTeams?.length || 0}
            overscanCount={5}
          >
            {props => {
              const { data, index, style } = props;

              return (
                <ListItem style={style} key={index} sx={{ p: 0 }}>
                  <ListItemButton
                    sx={{ px: 4 }}
                    onClick={() => addTeam(data[index]["id"])}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={`${IMAGE_URL}/${data[index]["image_url"]}`}
                        alt={data[index]["name"]}
                        sx={{
                          outline: "2px solid",
                          outlineColor: "primary.main"
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={data[index]["name"]} />
                  </ListItemButton>
                </ListItem>
              );
            }}
          </FixedSizeList>
        </Stack>
      </Box>
    </Modal>
  );
}
