import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import {
  Button,
  LanguageSelector,
  Notification,
  TextField
} from "components/UI";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../api";
import { newPasswordSchema } from "../validation";

export function NewPassword() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: ""
    },
    resolver: yupResolver(newPasswordSchema)
  });

  const onSubmit = async data => {
    setLoading(true);

    const newPassword = {
      reset_token: searchParams.get("reset_token"),
      email: searchParams.get("email"),
      password: data.password
    };

    try {
      await resetPassword(newPassword);

      setLoading(false);

      navigate("/auth/sign-in");
    } catch (error) {
      setLoading(false);

      setMessage(error.response?.data?.message || error.message);
    }
  };

  return (
    <Box sx={{ my: "auto" }} px={{ xs: 4, md: 6, lg: 12, xl: 22 }}>
      <Notification />
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", mb: 7, textAlign: "center" }}
      >
        {t("auth.newPassword.createNewPassword")}
      </Typography>
      <Stack
        spacing={4}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 4 }}
      >
        <Stack spacing={2}>
          <TextField
            label={t("auth.newPassword.email")}
            name="email"
            register={() => {}}
            disabled={true}
            defaultValue={searchParams.get("email")}
          />
          <TextField
            label={t("auth.newPassword.newPassword")}
            name="password"
            type="password"
            error={!!errors.password?.message}
            helperText={t(errors.password?.message)}
            register={register}
          />
          <TextField
            label={t("auth.newPassword.confirmPassword")}
            name="confirmPassword"
            type="password"
            error={!!errors.confirmPassword?.message}
            helperText={t(errors.confirmPassword?.message)}
            register={register}
          />
        </Stack>
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle2">{message}</Typography>
        </Box>
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          sx={{ mt: 6 }}
          disabled={loading}
        >
          {t("auth.newPassword.createPassword")}
        </Button>
      </Stack>
      <Stack alignItems="center" mt={4}>
        <LanguageSelector />
      </Stack>
    </Box>
  );
}
