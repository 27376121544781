import { Box } from "@mui/material";
import { Notification } from "components/UI";
import { useEffect } from "react";
import { useCentralStore } from "stores";
import { KidForm } from "../components";

export function KidUpdate() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);

  useEffect(() => {
    setHeaderTitle("Kid update");

    return () => {
      setHeaderTitle("");
    };
  }, []);

  return (
    <Box sx={{ px: 6, pt: 6, pb: 4 }}>
      <Notification />
      <KidForm isEditMode={true} />
    </Box>
  );
}
