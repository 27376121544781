import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getUser = ({ churchId, userId }) => {
  return axios.get(`/churches/${churchId}/users/${userId}`);
};

export const useUser = ({ churchId, userId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["user", userId, churchId],
    queryFn: () => getUser({ churchId, userId })
  });
};
