import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPortTypes = () => {
  return axios.get(`/message_port_types`);
};

export const usePortTypes = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["port-types"],
    queryFn: getPortTypes
  });
};
