import styled from "@emotion/styled";
import { DeleteOutline, Search } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  InputAdornment,
  Stack,
  Typography
} from "@mui/material";
import { Checkbox, DeleteModal, TextField } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUnmount } from "react-use";
import { useCentralStore } from "stores";
import { getAddressNames } from "utils/getAddressNames";
import { useDeleteMessages, useDeleteSendMessages } from "../api";
import { useMessagesStore } from "../store";

const Container = styled.div`
  height: 100%;
  max-height: 913px;
  max-width: 479px;
  background: white;
  padding: 22px 44px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Tabs = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 32px;
`;

const Contacts = styled(Tabs)`
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

const Contact = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;
  background: ${({ active }) => (active ? "#F3F3F3" : "white")};
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4`
  margin: 0;
  padding: 0;
`;

export const MessageList = ({ title, messages }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const addRecipient = useMessagesStore(state => state.addRecipient);
  const setPortId = useMessagesStore(state => state.setPortId);
  const addMember = useMessagesStore(state => state.addMember);
  const addTeam = useMessagesStore(state => state.addTeam);
  const addPartner = useMessagesStore(state => state.addPartner);
  const clearMembers = useMessagesStore(state => state.clearMembers);
  const clearTeams = useMessagesStore(state => state.clearTeams);
  const clearPartners = useMessagesStore(state => state.clearPartners);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const setMessage = useMessagesStore(state => state.setMessage);

  const [messagesToDelete, setMessagesToDelete] = useState(new Map());
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const closeDeleteModal = useCentralStore(state => state.closeDeleteModal);
  const messageDeleteMutation = useDeleteMessages();
  const sendMessageDeleteMutation = useDeleteSendMessages();

  const clearChatPanel = () => {
    clearMembers();
    clearTeams();
    clearPartners();
    clearRecipients();
    setPortId("");
  };

  const getMessageHistory = () => {
    clearChatPanel();

    const selectedMessage = messages?.find(
      message => message.id === Number(id)
    );

    setPortId(selectedMessage?.message_port_id);

    if (title.includes("Draft")) {
      setMessage(selectedMessage?.message);
    }

    if (["All Messages", "Inbox"].includes(title)) {
      if (selectedMessage?.meta_data?.address_type === "member") {
        addMember(selectedMessage?.meta_data?.member?.id, {
          id: selectedMessage?.meta_data?.member?.id
        });
        addRecipient(
          `${selectedMessage?.meta_data?.member?.id}_${selectedMessage?.meta_data?.member?.first_name}_${selectedMessage?.meta_data?.member?.last_name}`,
          selectedMessage?.meta_data?.member
        );
      }

      if (selectedMessage?.meta_data?.address_type === "team") {
        addTeam(selectedMessage?.meta_data?.team?.id, {
          id: selectedMessage?.meta_data?.team?.id
        });
        addRecipient(
          `${selectedMessage?.meta_data?.team?.id}_${selectedMessage?.meta_data?.team?.name}`,
          selectedMessage?.meta_data?.team
        );
      }

      if (selectedMessage?.meta_data?.address_type === "partner") {
        addPartner(selectedMessage?.meta_data?.partner?.id, {
          id: selectedMessage?.meta_data?.partner?.id
        });
        addRecipient(
          `${selectedMessage?.meta_data?.partner?.id}_${selectedMessage?.meta_data?.partner?.first_name}_${selectedMessage?.meta_data?.partner?.last_name}`,
          selectedMessage?.meta_data?.partner
        );
      }
    } else {
      if (selectedMessage?.addresses?.members) {
        for (let member of selectedMessage.addresses.members) {
          addMember(member?.id, { id: member?.id });
          addRecipient(
            `${member?.id}_${member?.first_name}_${member?.last_name}`,
            member
          );
        }
      }

      if (selectedMessage?.addresses?.teams) {
        for (let team of selectedMessage.addresses.teams) {
          addTeam(team?.id, { id: team?.id });
          addRecipient(`${team?.id}_${team?.name}`, team);
        }
      }

      if (selectedMessage?.addresses?.partners) {
        for (let partner of selectedMessage.addresses.partners) {
          addPartner(partner?.id, { id: partner?.id });
          addRecipient(
            `${partner?.id}_${partner?.first_name}_${partner?.last_name}`,
            partner
          );
        }
      }
    }
  };

  const getProfileImage = message => {
    if (["All Messages", "Inbox"].includes(title)) {
      return (
        <Avatar
          alt={
            message?.meta_data?.[message?.meta_data?.address_type]?.first_name
          }
          src={`${IMAGE_URL}/${
            message?.meta_data?.[message?.meta_data?.address_type]?.image_url
          }`}
          sx={{ width: 48, height: 48 }}
        />
      );
    }

    return <Avatar alt="" src="" sx={{ width: 48, height: 48 }} />;
  };

  const getProfileName = message => {
    if (["All Messages", "Inbox"].includes(title)) {
      return `${
        message?.meta_data[message?.meta_data?.address_type]?.first_name
      } ${message?.meta_data[message?.meta_data?.address_type]?.last_name}`;
    }

    return getAddressNames(message?.addresses);
  };

  useEffect(() => {
    getMessageHistory();
  }, [id]);

  useUnmount(() => {
    clearChatPanel();

    if (title.includes("Draft")) {
      setMessage("");
    }
  });

  return (
    <Container>
      <DeleteModal
        title="Delete Message"
        onDelete={() => {
          if (["All Messages", "Inbox"].includes(title)) {
            messageDeleteMutation.mutate({
              churchId: selectedChurch?.id,
              messageIds: [...messagesToDelete.keys()]
            });
          } else {
            sendMessageDeleteMutation.mutate({
              churchId: selectedChurch?.id,
              messageIds: [...messagesToDelete.keys()]
            });
          }

          closeDeleteModal();

          const selectedMessages = new Map(messagesToDelete);
          selectedMessages.clear();
          setMessagesToDelete(selectedMessages);
        }}
        name="the selected messages"
      />

      <Header>
        <Title>{title}</Title>
        <IconButton
          color="error"
          onClick={openDeleteModal}
          disabled={!messagesToDelete.size}
        >
          <DeleteOutline />
        </IconButton>
      </Header>
      <TextField
        neutral={1}
        placeholder="Search messages"
        register={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
      <Contacts>
        {messages?.map(message => (
          <Contact
            active={message?.id == id}
            key={message?.id}
            onClick={() => {
              if (title.includes("Draft")) {
                setMessage(message?.message);
              }

              navigate(`./../${message?.id}`);
            }}
          >
            <Checkbox
              name=""
              buttons={[
                {
                  checkboxLabel: "",
                  value: true,
                  id: "remember",
                  checked: messagesToDelete.has(message.id),
                  onChange: e => {
                    const selectedMessages = new Map(messagesToDelete);

                    if (e.target.checked) {
                      selectedMessages.set(message.id, message);
                    } else {
                      selectedMessages.delete(message.id);
                    }

                    setMessagesToDelete(selectedMessages);
                  },
                  onClick: e => {
                    e.stopPropagation();
                  }
                }
              ]}
              error=""
              helperText=""
              register={() => {}}
            />
            <Stack
              direction="row"
              alignItems="flex-start"
              gap={1}
              sx={{ width: "100%" }}
            >
              {getProfileImage(message)}
              <Stack>
                {getProfileName(message)}
                <Stack direction="row" alignItems="center">
                  {message?.status === "Draft" && (
                    <Typography variant="caption" color="error">
                      {message?.status}:
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    sx={{ maxWidth: "150px" }}
                    noWrap
                  >
                    {message?.message}
                  </Typography>
                </Stack>
              </Stack>
              <Typography variant="caption" sx={{ marginLeft: "auto" }}>
                {moment(message?.created_at).calendar(null, {
                  sameDay: "h:mm A",
                  nextWeek: "dddd",
                  lastDay: "ddd",
                  lastWeek: "ddd DD",
                  sameElse: "DD/MM/YY"
                })}
              </Typography>
            </Stack>
          </Contact>
        ))}
      </Contacts>
    </Container>
  );
};
