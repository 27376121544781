import styled from "@emotion/styled";
import { Box, Modal, Stack, Typography } from "@mui/material";
import Envelope from "assets/images/envelope.svg";
import { Button } from "components/UI";

const DoneButton = styled(Button)`
  padding: 8px 57px;
`;

export function ThankYouModal({ isOpen }) {
  return (
    <Modal open={isOpen} onClose={() => window.location.reload()}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "800px",
          height: "535px",
          overflow: "hidden"
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100%",
            backgroundColor: "white"
          }}
          spacing="35px"
        >
          <img src={Envelope} alt="Envelope" />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h4" color="primary.main" fontWeight={700}>
              Thank You
            </Typography>
            <Typography>You will be notified when approved.</Typography>
          </Stack>
          <DoneButton onClick={() => window.location.reload()}>Done</DoneButton>
        </Stack>
      </Box>
    </Modal>
  );
}
