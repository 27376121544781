import { Stack, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { forwardRef } from "react";

const styles = {
  filled: {
    width: "100%",
    borderRadius: "5px",
    "& .MuiInputBase-root": {
      backgroundColor: "secondary.main"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `none`
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `none`
      }
    }
  },
  outlined: {
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "primary.main"
      },
      "&:hover fieldset": {
        borderColor: "primary.main"
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.main"
      }
    }
  }
};

export const PhoneInputField = forwardRef(
  (
    {
      label,
      isLabelBold,
      variant = "outlined",
      error,
      helperText,
      required,
      ...rest
    },
    ref
  ) => {
    return (
      <Stack width="100%">
        {!!label && (
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: isLabelBold && 700,
              "&:after": {
                content: required ? '" *"' : '""',
                color: "red"
              }
            }}
          >
            {label}
          </Typography>
        )}
        <MuiTelInput
          error={error}
          helperText={helperText}
          forceCallingCode
          defaultCountry="ET"
          disableFormatting
          size="small"
          sx={styles[variant]}
          inputRef={ref}
          inputProps={{
            maxLength: 13
          }}
          {...rest}
        />
      </Stack>
    );
  }
);

PhoneInputField.displayName = "PhoneInputField";
