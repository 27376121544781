import styled from "@emotion/styled";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Stack } from "@mui/material";
import {
  Button,
  PhoneInputField,
  Radio,
  TextField,
  Upload
} from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { useChurchStore } from "features/churches";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreatePartner } from "../api";
import { useMembersStore } from "../stores";
import { createPartnerSchema } from "../validation";
import { formatISO } from "date-fns";

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 24px;
  align-items: flex-end;
`;

export function AddPartnerModal() {
  const addPartnerModalOpen = useMembersStore(
    state => state.addPartnerModalOpen
  );
  const closeAddPartnerModal = useMembersStore(
    state => state.closeAddPartnerModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const partnerMutation = useCreatePartner();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      gender: "",
      birth_date: null,
      phone: "",
      email: "",
      address: "",
      image: null
    },
    resolver: yupResolver(createPartnerSchema)
  });

  useEffect(() => {
    if (partnerMutation.isSuccess) {
      reset({ ...defaultValues });
    }
  }, [partnerMutation.isSuccess]);

  const onSubmit = data => {
    const partnerData = {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      birth_date: formatISO(data.birth_date),
      phone: data.phone,
      email: data.email,
      address: data.address
    };

    if (data.image) {
      partnerData["image"] = data.image;
    }

    const formData = new FormData();

    for (let key in partnerData) {
      formData.append(key, partnerData[key]);
    }

    partnerMutation.mutate({
      churchId: selectedChurch?.id,
      partnerData: formData
    });
  };

  return (
    <>
      <DevTool control={control} />
      <Modal open={addPartnerModalOpen} onClose={closeAddPartnerModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: 2,
            width: "880px",
            overflow: "hidden"
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "primary.main",
              color: "primary.contrastText"
            }}
          >
            {t("members.partners.AddPartner")}
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              pb: 6,
              px: 10,
              backgroundColor: "white"
            }}
            spacing={2}
          >
            <FormGrid>
              <TextField
                label={t("form.common.firstName")}
                name="first_name"
                error={!!errors.first_name?.message}
                helperText={errors.first_name?.message}
                register={register}
                required
              />
              <TextField
                label={t("form.common.fatherName")}
                name="last_name"
                error={!!errors.last_name?.message}
                helperText={errors.last_name?.message}
                register={register}
                required
              />
              <Radio
                label={t("form.common.gender")}
                name="gender"
                buttons={[
                  {
                    radioLabel: t("form.common.male"),
                    value: "male",
                    id: "male"
                  },
                  {
                    radioLabel: t("form.common.female"),
                    value: "female",
                    id: "female"
                  }
                ]}
                row={true}
                register={register}
                error={!!errors.gender?.message}
                helperText={errors.gender?.message}
                required
              />
              <Controller
                name="birth_date"
                control={control}
                render={({ field }) => (
                  <DateField
                    label={t("form.common.dateOfBirth")}
                    name="birth_date"
                    error={!!errors.birth_date?.message}
                    helperText={errors.birth_date?.message}
                    {...field}
                    required
                    disableFutureDates
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t("form.common.phone")}
                    variant="filled"
                    error={!!errors.phone?.message}
                    helperText={errors.phone?.message}
                    {...field}
                    required
                  />
                )}
              />
              <TextField
                label={t("form.common.email")}
                name="email"
                error={!!errors.email?.message}
                helperText={errors.email?.message}
                register={register}
              />
              <TextField
                label={t("form.common.address")}
                name="address"
                error={!!errors.address?.message}
                helperText={errors.address?.message}
                register={register}
              />
              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <Upload
                    title={t("addMembers.step1.UploadPhoto")}
                    buttonTitle="Browse"
                    error={!!errors.image?.message}
                    helperText={errors.image?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormGrid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ pt: 6 }}
            >
              <Button
                size="large"
                variant="contained"
                disabled={partnerMutation.isLoading}
                color="secondary"
                flavor="secondary"
                onClick={() => {
                  reset();
                  closeAddPartnerModal();
                }}
              >
                {t("form.common.cancel")}
              </Button>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={partnerMutation.isLoading}
              >
                {t("form.common.submit")}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
