import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Modal, Stack } from "@mui/material";
import { TextField } from "components/UI";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useMessagesStore } from "../store";

const messageSchema = yup
  .object({
    message: yup.string().required("Message is required")
  })
  .required();

export function EditMessageModal({ initialMessage, isLoading, onUpdate }) {
  const editMessageModalOpen = useMessagesStore(
    state => state.editMessageModalOpen
  );
  const closeEditMessageModal = useMessagesStore(
    state => state.closeEditMessageModal
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(messageSchema)
  });

  useEffect(() => {
    if (initialMessage) {
      reset({ message: initialMessage });
    }
  }, [initialMessage]);

  const onSubmit = data => {
    onUpdate(data);
  };

  return (
    <Modal open={editMessageModalOpen} onClose={closeEditMessageModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          Edit message
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label="Message"
            name="message"
            error={!!errors.message?.message}
            helperText={errors.message?.message}
            register={register}
            multiline
            minRows={4}
            fullWidth
            isLabelBold={1}
            autoComplete="off"
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={closeEditMessageModal}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={isLoading}
            >
              Edit
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
