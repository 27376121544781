import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getDenominations = () => {
  return axios.get(`/denominations`);
};

export const useDenominations = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["denominations"],
    queryFn: getDenominations
  });
};
