import styled from "@emotion/styled";
import {
  Chat,
  DeleteOutline,
  EditOutlined,
  MoreVert,
  Phone
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { DeleteModal } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import i18next from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { getEthiopianDate } from "utils/getEthiopianDate";
import { getGregorianDate } from "utils/getGregorianDate";
import { exportProfile, printProfile, useDeleteKid, useKid } from "../api";
import { useMembersStore } from "../stores";

const BasicInfoSection = styled.div`
  padding: 20px;
  border-radius: 8px;
  background: white;
  background: linear-gradient(90deg, #72d0d3 0%, #218596 103.53%);
  min-height: 272px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
  align-items: center;
`;

const WhiteButton = styled(Button)`
  background: white;
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 100px;

  &:hover {
    background: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const LinkButton = styled.button`
  border: none;
  outline: none;
  background: none;
  color: white;
  border-bottom: 1px solid white;
  cursor: pointer;
`;

export function KidProfile() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const setHeaderBackground = useCentralStore(
    state => state.setHeaderBackground
  );
  const kidData = useMembersStore(state => state.kidData);
  const setKidData = useMembersStore(state => state.setKidData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const kidDeleteMutation = useDeleteKid();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const kidQuery = useKid({
    churchId: selectedChurch?.id,
    kidId: id
  });

  useEffect(() => {
    if (kidQuery.data) {
      setKidData(kidQuery.data.result);
    }
  }, [kidQuery.data]);

  useEffect(() => {
    return () => {
      setKidData(null);
    };
  }, []);

  useEffect(() => {
    setHeaderTitle(t("kids.profile.KidProfile"));

    return () => {
      setHeaderTitle("");
    };
  }, [i18next.language]);

  useEffect(() => {
    setHeaderBackground(false);
    return () => setHeaderBackground(true);
  }, []);

  const handleExport = () => {
    exportProfile({
      type: "kid",
      churchId: selectedChurch?.id,
      id: id,
      format: "pdf",
      orientation: "landscape"
    });
  };

  const handlePrint = () => {
    printProfile({
      type: "kid",
      churchId: selectedChurch?.id,
      id: id,
      format: "pdf",
      orientation: "landscape"
    });
  };

  return (
    <Box px={4}>
      <DeleteModal
        title={t("form.common.delete")}
        name={kidData?.first_name}
        onDelete={() => {
          kidDeleteMutation.mutate({
            churchId: selectedChurch?.id,
            kidId: id
          });
        }}
      />
      <BasicInfoSection>
        <IconButton
          aria-label="more"
          sx={{
            color: "white",
            position: "absolute",
            top: "32px",
            right: "32px"
          }}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack alignItems="center" spacing={1}>
            <Avatar
              sx={{
                width: "204px",
                height: "204px",
                border: "5px solid",
                borderColor: "white"
              }}
              src={`${IMAGE_URL}/${kidData?.profile_picture_kid}`}
              alt={kidData?.first_name}
            />
            <Stack spacing={1}>
              <Typography
                variant="h4"
                sx={{ fontWeight: 700 }}
                alignSelf="center"
              >
                {kidData?.first_name} {kidData?.father_name}{" "}
                {kidData?.last_name}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                alignSelf="center"
                spacing={2}
              >
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <Typography variant="subtitle2">
                    {t("form.common.gender")}:
                  </Typography>
                  <Typography sx={{ fontWeight: 700 }}>
                    {kidData?.gender}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <Typography variant="subtitle2">
                    {t("form.common.age")}:
                  </Typography>
                  <Typography sx={{ fontWeight: 700 }}>
                    {kidData?.date_of_birth &&
                      moment().diff(moment(kidData?.date_of_birth), "years")}
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ background: "white" }} />
              <Stack
                direction="row"
                alignItems="center"
                alignSelf="center"
                spacing={5}
              >
                <Stack>
                  <Typography variant="subtitle2">
                    {t("kids.profile.ParentPhone")}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Phone />
                    <Stack alignItems="center">
                      <Typography variant="caption" sx={{ fontWeight: 700 }}>
                        {kidData?.primary_phone}
                      </Typography>
                      <Typography variant="caption" sx={{ fontWeight: 700 }}>
                        {kidData?.secondary_phone}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <WhiteButton
                  variant="contained"
                  onClick={() => {
                    clearRecipients();

                    kidData?.parents?.forEach(parent => {
                      removeMember(parent.member_id);
                      addRecipient(
                        `${parent?.member_id}_${parent?.member?.first_name}_${parent?.member?.last_name}`,
                        parent?.member
                      );
                      addMember(parent.member_id, { id: parent.member_id });
                    });

                    navigate("/messages");
                  }}
                  startIcon={<Chat />}
                >
                  {t("kids.profile.MessageParent")}
                </WhiteButton>
                <LinkButton onClick={handleExport}>
                  {t("kids.profile.ExportProfile")}
                </LinkButton>
                <LinkButton onClick={handlePrint}>
                  {t("kids.profile.PrintProfile")}
                </LinkButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </BasicInfoSection>
      <Box sx={{ my: 4 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 700, mb: 1 }}>
          {t("kids.profile.KidProfile")}
        </Typography>
        <PersonalProfile data={kidData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <Typography variant="subtitle2" sx={{ fontWeight: 700, mb: 1 }}>
          {t("kids.addKid.FamilyInformation")}
        </Typography>
        <FamilyInformation data={kidData} />
        <Divider sx={{ my: 3, backgroundColor: "primary.main" }} />
        <Typography variant="subtitle2" sx={{ fontWeight: 700, mb: 1 }}>
          {t("form.common.address")}
        </Typography>
        <Address data={kidData} />
      </Box>
      <Popover
        anchorEl={anchorEl}
        id="profile-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <ProfileMenu />
      </Popover>
    </Box>
  );
}

const PersonalProfile = ({ data }) => {
  const { t } = useTranslation();
  const [datePreview, setDatePreview] = useState("amh");

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Nationality")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.country?.nationality}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.dateOfBirth")}
          </Typography>
          <Stack direction="row" spacing={1}>
            {data?.date_of_birth ? (
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {datePreview === "eng"
                  ? getGregorianDate(data?.date_of_birth)
                  : getEthiopianDate(data?.date_of_birth)}
              </Typography>
            ) : null}
            {data?.date_of_birth ? (
              <Typography
                onClick={() => {
                  if (datePreview === "amh") setDatePreview("eng");
                  if (datePreview === "eng") setDatePreview("amh");
                }}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "primary.main",
                  userSelect: "none"
                }}
              >
                {datePreview}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Language")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.mother_language}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Country")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.birth_country?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.Region")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.birth_region?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step1.City")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.birth_city?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("addMembers.step2.AcademicStatus")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.academic_status}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const FamilyInformation = ({ data }) => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={2}>
      <Stack spacing={2} flex={1}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("kids.addKid.Parent1Information")}
        </Typography>
        {data?.is_primary ? (
          <>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.fullName")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.parents?.[0]?.member?.first_name}{" "}
                  {data?.parents?.[0]?.member?.father_name}{" "}
                  {data?.parents?.[0]?.member?.last_name}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.RelationshipWithChild")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.parents?.[0]?.relation_type}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.AttendingChurch")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.primary_attending_church ?? data?.parents?.[0]
                    ? selectedChurch?.name
                    : ""}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.Religion")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.primary_religion ?? data?.parents?.[0]
                    ? "Protestant"
                    : ""}
                </Typography>
              </Stack>
            </Stack>
            <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.phone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.parents?.[0]?.member?.phone}
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.fullName")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.primary_first_name} {data?.primary_father_name}{" "}
                  {data?.primary_last_name}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.RelationshipWithChild")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.primary_relationship}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.AttendingChurch")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.primary_attending_church}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.Religion")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.primary_religion}
                </Typography>
              </Stack>
            </Stack>
            <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.phone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.primary_phone}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
      <Stack spacing={2} flex={1}>
        <Typography sx={{ fontWeight: 700 }}>
          {t("kids.addKid.Parent2Information")}
        </Typography>
        {data?.is_secondary ? (
          <>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.fullName")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {
                    data?.parents?.[data?.parents.length - 1]?.member
                      ?.first_name
                  }{" "}
                  {
                    data?.parents?.[data?.parents.length - 1]?.member
                      ?.father_name
                  }{" "}
                  {data?.parents?.[data?.parents.length - 1]?.member?.last_name}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.RelationshipWithChild")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.parents?.[data?.parents.length - 1]?.relation_type}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.AttendingChurch")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.secondary_attending_church ??
                  data?.parents?.[data?.parents.length - 1]
                    ? selectedChurch?.name
                    : ""}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.Religion")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.secondary_religion ??
                  data?.parents?.[data?.parents.length - 1]
                    ? "Protestant"
                    : ""}
                </Typography>
              </Stack>
            </Stack>
            <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.phone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.parents?.[data?.parents.length - 1]?.member?.phone}
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("form.common.fullName")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.secondary_first_name} {data?.secondary_father_name}{" "}
                  {data?.secondary_last_name}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("addMembers.step3.RelationshipWithChild")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.secondary_relationship}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.AttendingChurch")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.secondary_attending_church}
                </Typography>
              </Stack>
              <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {t("kids.profile.Religion")}
                </Typography>
                <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                  {data?.secondary_religion}
                </Typography>
              </Stack>
            </Stack>
            <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
              <Typography sx={{ fontWeight: 500 }}>
                {t("form.common.phone")}
              </Typography>
              <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                {data?.secondary_phone}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const Address = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.country")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.country?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.region")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.region?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.zone")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.zone?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.city")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.city?.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.subcity")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.subcity?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.woreda")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.woreda?.name}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.kebele")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.kebele}
          </Typography>
        </Stack>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.houseNumber")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.house_number}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack p={2} sx={{ backgroundColor: "neutral.main", flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {t("form.common.specificLocation")}
          </Typography>
          <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
            {data?.specific_location}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ProfileMenu = () => {
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Box sx={{ minWidth: "168px", overflow: "hidden", borderRadius: "5px" }}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        {t("kids.profile.ProfileSetting")}
      </Box>
      <Stack
        sx={{
          backgroundColor: "white",
          py: "9px",
          px: "12px"
        }}
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            color: "primary.main",
            backgroundColor: "secondary.main",
            py: 1,
            px: 2,
            cursor: "pointer"
          }}
          onClick={() => {
            navigate(`/kids/${id}/update`);
          }}
        >
          <EditOutlined />
          <Typography variant="caption">
            {t("kids.profile.EditProfile")}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            backgroundColor: "#FCD1D1",
            color: "#F44949",
            py: 1,
            px: 2,
            cursor: "pointer"
          }}
          onClick={openDeleteModal}
        >
          <DeleteOutline />
          <Typography variant="caption">
            {t("kids.profile.DeleteProfile")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
