import { Box, Button as MuiButton, Stack, Typography } from "@mui/material";
import { Button, LanguageSelector, Notification } from "components/UI";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function CheckEmail() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ my: "auto" }} px={{ xs: 4, md: 6, lg: 12, xl: 22 }}>
      <Notification />
      <Typography
        variant="h4"
        sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}
      >
        {t("auth.checkEmail.checkEmail")}
      </Typography>
      <Typography component="h2" variant="body1" sx={{ textAlign: "center" }}>
        {t("auth.checkEmail.description")}
      </Typography>
      <Stack alignItems="center" spacing={2} sx={{ mt: 4 }}>
        <Button
          type="submit"
          size="large"
          fullWidth
          flavor="primary"
          sx={{ mt: 3 }}
          onClick={() => window.open("mailto:")}
        >
          {t("auth.checkEmail.openEmailApp")}
        </Button>
        <MuiButton variant="text" onClick={() => navigate("/auth/sign-in")}>
          {t("auth.forgotPassword.backToLogin")}
        </MuiButton>
      </Stack>
      <Stack alignItems="center" mt={4}>
        <LanguageSelector />
      </Stack>
    </Box>
  );
}
