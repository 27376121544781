import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { MainSidebar, MobileDrawer } from "components/Sidebars";
import { Header } from "components/UI";
import { useChurchStore } from "features/churches";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Sidebar = styled.div`
  flex-basis: 18%;
  display: none;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    display: block;
  }`}
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    flex-basis: 50%;
  }`}
`;

export function DashboardLayout({ sidebar = <MainSidebar /> }) {
  const navigate = useNavigate();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  useEffect(() => {
    if (!selectedChurch) {
      navigate("/churches");
    }
  }, [selectedChurch]);

  return (
    <Container>
      <MobileDrawer />
      <Sidebar>{sidebar}</Sidebar>
      <Content>
        <Header />
        <Box sx={{ background: "#F3F4F6" }}>
          <Outlet />
        </Box>
      </Content>
    </Container>
  );
}
