import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useDeleteBudgetYear,
  useFinanceSettingsStore,
  useUpdateBudgetYear
} from "features/finances";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const approverSchema = yup.object({
  approver: yup.string(),
  approverLevel: yup.string(),
  approvalLimitPerRequest: yup.number(),
  approvalLimitPerMonth: yup.number()
});

export function UpdateBudgetYearModal({ data }) {
  const updateBudgetYearOpen = useFinanceSettingsStore(
    state => state.updateBudgetYearOpen
  );
  const closeUpdateBudgetYearModal = useFinanceSettingsStore(
    state => state.closeUpdateBudgetYearModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const updateBudgetYearMutation = useUpdateBudgetYear();
  const deleteBudgetYearMutation = useDeleteBudgetYear();

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      title: data?.title,
      startDate: data?.start_date,
      endDate: data?.end_date,
      description: data?.description
    },
    resolver: yupResolver(approverSchema)
  });

  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      setValue("startDate", data.start_date);
      setValue("endDate", data.end_date);
      setValue("description", data.description);
    }
  }, [data]);

  const onSubmit = values => {
    const budgetYearData = {
      title: values.title,
      start_date: values.startDate,
      end_date: values.endDate,
      description: values.description
    };

    updateBudgetYearMutation.mutate(
      {
        churchId: selectedChurch?.id,
        budgetYearId: data?.id,
        budgetYearData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Update Budget Year"
      isOpen={updateBudgetYearOpen}
      onClose={closeUpdateBudgetYearModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            flavor="neutral"
            size="small"
            onClick={() => {
              deleteBudgetYearMutation.mutate({
                churchId: selectedChurch?.id,
                budgetYearId: data?.id
              });
            }}
          >
            Delete
          </Button>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button flavor="white" size="small">
              Cancel
            </Button>
            <Button size="small" onClick={handleSubmit(onSubmit)}>
              Save changes
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="name">Title</Label>
        <Input {...register("title")} />
      </Field>
      <Field>
        <Label htmlFor="approverLevel">Start Date</Label>
        <Input {...register("startDate")} type="date" />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerRequest">End Date</Label>
        <Input {...register("endDate")} type="date" />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerMonth">Description</Label>
        <Input {...register("description")} />
      </Field>
    </BaseModal>
  );
}
