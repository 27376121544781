import { useQuery } from "@tanstack/react-query";
import { QueryBuilder } from "features/members";
import { axios } from "lib/axios";

export const getPreviousTeamMembersPaginated = ({
  churchId,
  teamId,
  page,
  pageSize,
  search
}) => {
  const queryBuilder = new QueryBuilder();

  queryBuilder.pagination(page, pageSize);

  let queryString = queryBuilder.build();

  queryString += `&is_resigned=1`;

  if (search) {
    queryString += `&search=${search}`;
  }

  return axios.get(
    `/churches/${churchId}/teams/${teamId}/members-paginated${queryString}`
  );
};

export const usePreviousTeamMembersPaginated = ({
  churchId,
  teamId,
  page,
  pageSize,
  config,
  search = ""
} = {}) => {
  return useQuery({
    ...config,
    queryKey: [
      "previous_team_members",
      page,
      pageSize,
      churchId,
      teamId,
      search
    ],
    queryFn: () =>
      getPreviousTeamMembersPaginated({
        churchId,
        teamId,
        page,
        pageSize,
        search
      })
  });
};
