import { Visibility } from "@mui/icons-material";
import { Chip, IconButton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useAssetsPaginated } from "features/assets";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const statusColors = {
  ACTIVE: "success",
  INACTIVE: "error",
  UNDER_REPAIR: "warning",
  DISPOSED: "default"
};

const conditionColors = {
  NEW: "success",
  GOOD: "default",
  FAIR: "warning",
  POOR: "error"
};

export const AssetsTable = () => {
  const { t } = useTranslation();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();

  const assetsPaginatedQuery = useAssetsPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize
  });

  const columns = [
    {
      field: "asset_id",
      headerName: t("Asset.Asset.AssetID"),
      flex: 1
    },
    {
      field: "name",
      headerName: t("Asset.Asset.AssetName"),
      flex: 1
    },
    {
      field: "type",
      headerName: t("Asset.Asset.AssetType"),
      flex: 1
    },
    {
      field: "quantity",
      headerName: t("Asset.Asset.Quantity"),
      flex: 1
    },
    {
      field: "status",
      headerName: t("Asset.Asset.Status"),
      flex: 1,
      renderCell: ({ row }) => (
        <Chip label={row.status} color={statusColors[row.status]} />
      )
    },
    {
      field: "condition",
      headerName: t("Asset.Asset.Condition"),
      flex: 1,
      renderCell: ({ row }) => (
        <Chip label={row.condition} color={conditionColors[row.status]} />
      )
    },
    {
      field: "operation",
      headerName: t("systemAdmins.roles.Operations"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {/* <IconButton>
              <Edit />
            </IconButton> */}
            <IconButton
              onClick={() => {
                navigate(`/assets/${row.id}`);
              }}
            >
              <Visibility />
            </IconButton>
          </Stack>
        );
      },
      flex: 1
    }
  ];

  return (
    <>
      <DataGrid
        autoHeight
        rows={assetsPaginatedQuery?.data?.result?.data || []}
        rowCount={assetsPaginatedQuery?.data?.result?.total || 0}
        loading={assetsPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
    </>
  );
};
