import styled from "@emotion/styled";
import { Edit } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useChartOfAccountNamesPaginated,
  useFinanceSettingsStore
} from "features/finances";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateChartOfAccountNameModal } from "../Modals";

const Container = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

const PrimaryActions = styled.div`
  display: flex;
  gap: 8px;
`;

export const ChartOfAccountNameTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [chartOfAccountName, setChartOfAccountName] = useState(null);
  const openCreateChartOfAccountNameModal = useFinanceSettingsStore(
    state => state.openCreateChartOfAccountNameModal
  );
  const openUpdateChartOfAccountNameModal = useFinanceSettingsStore(
    state => state.openUpdateChartOfAccountNameModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const chartOfAccountNamesQuery = useChartOfAccountNamesPaginated({
    churchId: selectedChurch?.id,
    page,
    pageSize
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) => {
        if (Number(row.id) >= 1 && Number(row.id) <= 61) return null;

        return (
          <Stack direction="row">
            <IconButton
              onClick={() => {
                setChartOfAccountName(row);
                openUpdateChartOfAccountNameModal();
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      }
    }
  ];

  return (
    <Container>
      <UpdateChartOfAccountNameModal data={chartOfAccountName} />
      <Stack alignItems="flex-start" spacing={4}>
        <Header>
          <Title>Chart Of Account Names</Title>
          <PrimaryActions>
            <Button onClick={openCreateChartOfAccountNameModal}>
              New Chart Of Account Name
            </Button>
          </PrimaryActions>
        </Header>
      </Stack>
      <DataGrid
        autoHeight
        rows={chartOfAccountNamesQuery.data?.result?.data || []}
        rowCount={chartOfAccountNamesQuery.data?.result?.total || 0}
        loading={false}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
    </Container>
  );
};
