import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const BackgroundInputField = styled(TextField)(({ theme, neutral }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "0.5rem",
    backgroundColor: `${
      neutral ? theme.palette.neutral.main : theme.palette.secondary.main
    }`
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `none`
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `none`
    }
  }
}));
