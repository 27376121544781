import styled from "@emotion/styled";
import { CalendarMonthOutlined, Message } from "@mui/icons-material";
import { Avatar, Stack, Typography } from "@mui/material";
import { Button } from "components/UI";
import ReactECharts from "echarts-for-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  min-height: 913px;
  background: white;
  grid-column: 2 / span 2;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;

const StatCard = styled.div`
  min-height: 185px;
  background: yellow;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  color: white;
  overflow: hidden;

  h1,
  h3 {
    margin: 0;
    padding: 0;
  }
`;

const IconCircle = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: red;
  display: grid;
  place-items: center;
  color: white;
  align-self: flex-end;
`;

const StatCardTop = styled.div`
  background: #afcdf2;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 16px;
`;

const StatCardBottom = styled.div`
  background: #134077;
  padding: 16px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;

  h4 {
    margin: 0;
    padding: 0;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  thead {
    background: #b1e9ff;
  }

  thead th {
    padding: 16px 0;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr td {
    padding: 10px 32px;
  }

  tbody tr:hover {
    background: #f3f3f3;
    border-radius: 5px;
  }

  tbody tr:nth-of-type(odd) {
    background: #f6f6f6;
  }
`;

const Graph = styled.div``;

const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GraphFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendIndicator = styled.div`
  width: 24px;
  height: 24px;
  background: ${({ color }) => color};
`;

const StatusButton = styled.button`
  all: unset;
  text-decoration: underline;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const PreviousButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TabPills = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Pill = styled.div`
  padding: 6px 34px;
  display: grid;
  place-items: center;
  background: ${({ theme, active }) =>
    active ? theme.palette.primary.main : "#f1f1f1"};
  border-radius: 25px;
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.contrastText : "#000000"};
  cursor: pointer;
`;

const StatusTableContainer = styled(TableContainer)`
  gap: 24px;
  background: #f9f9f9;
  padding: 10px;
`;

const StatusTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;

  thead {
    background: white;
    font-style: italic;
  }

  thead th {
    padding: 16px 0;
  }

  tbody tr {
    cursor: pointer;
    background: white;
    margin-bottom: 10px;
  }

  tbody tr td {
    padding: 10px 32px;
  }
`;

const options = {
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"]
  },
  yAxis: {
    type: "value"
  },
  series: [
    {
      name: "Received",
      data: [124, 4, 87, 170, 22, 81, 42],
      type: "line",
      smooth: false,
      color: "#65A6A0"
    },
    {
      name: "Sent",
      data: [15, 47, 98, 20, 62, 14, 88],
      type: "line",
      smooth: false,
      color: "#70D7FF"
    }
  ],
  tooltip: {
    trigger: "axis"
  }
};

const messages = [
  {
    id: 53,
    name: "Fares Choir",
    image: "https://source.unsplash.com/random",
    numberOfSMS: 24,
    numberOfSent: 24,
    numberOfDelivered: 18,
    numberOfFailed: 6,
    type: "Text Message",
    port: "SMS",
    status: "Sent",
    sentDate: "03-06-2022",
    timestamp: "1/1/23, 9:00 PM"
  },
  {
    id: 58,
    name: "Prayer",
    image: "https://source.unsplash.com/random",
    numberOfSMS: 32,
    numberOfSent: 24,
    numberOfDelivered: 18,
    numberOfFailed: 6,
    type: "Text Message",
    port: "SMS",
    status: "Sent",
    sentDate: "03-06-2022",
    timestamp: "1/1/23, 9:00 PM"
  }
];

export const SmsStatusPanel = () => {
  const [activeTab, setActiveTab] = useState("group");
  const { t } = useTranslation();

  return (
    <Container>
      <StatGrid>
        <StatInfoCard
          title={t("message.smsStatus.TotalMessageSent")}
          stat="236,200"
        />
        <StatInfoCard
          title={t("message.smsStatus.TotalMessageSent")}
          stat="236,200"
        />
        <StatInfoCard
          title={t("message.smsStatus.TotalMessageSent")}
          stat="236,200"
        />
      </StatGrid>
      <Graph>
        <GraphHeader>
          <h4>{t("message.smsStatus.SentAndReceivedMessage")}</h4>
        </GraphHeader>
        <ReactECharts option={options} />
        <GraphFooter>
          <Legend>
            <LegendIndicator color="#65A6A0" />
            <h5>{t("message.smsStatus.ReceivedMessage")}</h5>
          </Legend>
          <Legend>
            <LegendIndicator color="#70D7FF" />
            <h5>{t("message.smsStatus.SentMessage")}</h5>
          </Legend>
        </GraphFooter>
      </Graph>
      <StatusTableContainer>
        <TableHeader>
          <h3>{t("message.smsStatus.CurrentMessageStatus")}</h3>
          <PreviousButton>
            <span>{t("message.smsStatus.Previous")}</span>
            <span>
              <CalendarMonthOutlined />
            </span>
          </PreviousButton>
        </TableHeader>
        <TabPills>
          <Pill
            active={activeTab === "individual"}
            onClick={() => setActiveTab("individual")}
          >
            {t("message.smsStatus.Individual")}
          </Pill>
          <Pill
            active={activeTab === "group"}
            onClick={() => setActiveTab("group")}
          >
            {t("message.smsStatus.Group")}
          </Pill>
        </TabPills>
        <StatusTable>
          <thead>
            <tr>
              <th>{t("message.smsStatus.Conversations")}</th>
              <th>{t("message.smsStatus.Sent")}</th>
              <th>{t("message.smsStatus.Delivered")}</th>
              <th>{t("message.smsStatus.Failed")}</th>
            </tr>
          </thead>
          <tbody>
            {messages.map(message => (
              <tr key={message}>
                <td>
                  <Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography textAlign="left">
                        {t("message.smsStatus.To")}:
                      </Typography>
                      <Typography textAlign="left">{message.name}</Typography>
                    </Stack>
                    <Typography textAlign="left" variant="caption">
                      {message.sentDate}
                    </Typography>
                  </Stack>
                </td>
                <td>{message.numberOfSent}</td>
                <td>{message.numberOfDelivered}</td>
                <td>{message.numberOfFailed}</td>
              </tr>
            ))}
          </tbody>
        </StatusTable>
      </StatusTableContainer>
      <TableContainer>
        <h4>{t("message.smsStatus.SmsSentPerGroup")}</h4>
        <Table>
          <thead>
            <tr>
              <th>{t("message.smsStatus.GroupName")}</th>
              <th>{t("message.smsStatus.NumberOfSMS")}</th>
              <th>{t("message.smsStatus.SentDate")}</th>
              <th>{t("message.smsStatus.Status")}</th>
            </tr>
          </thead>
          <tbody>
            {messages.map(message => (
              <tr key={message}>
                <td>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar
                      alt={message.name}
                      src={message.image}
                      sx={{ width: 48, height: 48 }}
                    />
                    <Stack>
                      <Typography textAlign="left">{message.name}</Typography>
                    </Stack>
                  </Stack>
                </td>
                <td>{message.numberOfSMS}</td>
                <td>{message.sentDate}</td>
                <td>
                  <StatusButton>
                    {t("message.smsStatus.CheckStatus")}
                  </StatusButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const StatInfoCard = ({ title, stat }) => (
  <StatCard>
    <StatCardTop>
      <IconCircle>
        <Message />
      </IconCircle>
      <h1>{stat}</h1>
    </StatCardTop>
    <StatCardBottom>
      <h3>{title}</h3>
    </StatCardBottom>
  </StatCard>
);
