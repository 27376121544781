import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMessagesStore } from "../store";

export const updateTemplate = ({ churchId, templateData, templateId }) => {
  return axios.patch(
    `/churches/${churchId}/message-templates/${templateId}`,
    templateData
  );
};

export const useUpdateTemplate = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateTemplateModal =
    useMessagesStore.getState().closeUpdateTemplateModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["templates"]);
      setNotification({
        type: "success",
        message: "Template updated successfully!"
      });

      closeUpdateTemplateModal();
    },
    ...config,
    mutationFn: updateTemplate
  });
};
