import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getKid = ({ churchId, kidId }) => {
  return axios.get(`/churches/${churchId}/children/${kidId}`);
};

export const useKid = ({ churchId, kidId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["kid", kidId],
    queryFn: () => getKid({ churchId, kidId })
  });
};
