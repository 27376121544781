import styled from "@emotion/styled";
import i18next from "i18next";
import { useState } from "react";

const supportedLanguages = [
  {
    name: "English",
    locale: "en"
  },
  {
    name: "አማርኛ",
    locale: "am"
  }
];

const Languages = styled.select`
  border: none;
  outline: none;
  background: none;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  cursor: pointer;
`;

const Language = styled.option``;

export const LanguageSelector = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  return (
    <Languages
      value={currentLanguage}
      onChange={e => {
        i18next.changeLanguage(e.target.value);
        setCurrentLanguage(e.target.value);
      }}
    >
      {supportedLanguages.map(supportedLanguage => (
        <Language key={supportedLanguage.name} value={supportedLanguage.locale}>
          {supportedLanguage.name}
        </Language>
      ))}
    </Languages>
  );
};
