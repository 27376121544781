import { Box, Grid } from "@mui/material";
import { Header } from "components/UI";
import { Outlet } from "react-router-dom";
import { DenominationProfileSidebar } from "./DenominationProfileSidebar";

export function DenominationProfileLayout() {
  return (
    <Grid container component="main" sx={{ minHeight: "100vh" }}>
      <Grid item xs={false} sm={4} md={2}>
        <DenominationProfileSidebar />
      </Grid>
      <Grid item xs={12} sm={8} md={10}>
        <Header />
        <Box
          sx={{
            pb: 6,
            px: 4,
            my: "auto"
          }}
        >
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
}
