import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useTeamsStore } from "../stores";

export const updateTeamType = ({ churchId, teamTypeData, teamTypeId }) => {
  return axios.patch(
    `/churches/${churchId}/team-types/${teamTypeId}`,
    teamTypeData
  );
};

export const useUpdateTeamType = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateTeamTypeModal =
    useTeamsStore.getState().closeUpdateTeamTypeModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("team_types");
      setNotification({
        type: "success",
        message: "Team type updated successfully!"
      });
      closeUpdateTeamTypeModal();
    },
    ...config,
    mutationFn: updateTeamType
  });
};
