import styled from "@emotion/styled";
import _ from "lodash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Label = styled.label`
  font-weight: ${props => (props.labelBold ? "bold" : "normal")};
  visibility: ${props => (props.empty ? "hidden" : "visible")};
`;

const TextField = styled.input`
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: ${props => (props.startIcon ? "1.5rem" : "1rem")};
  padding-right: ${props => (props.endIcon ? "1.5rem" : "1rem")};
  outline: none;
  border-width: ${props => (props.plain ? "0 0 0.125rem 0" : "0.125rem")};
  border-style: solid;
  border-color: ${props =>
    props.bg
      ? "transparent"
      : props.error
      ? props.theme.palette.error.main
      : props.theme.palette.primary.main};
  border-radius: ${props =>
    props.plain ? "0 0 0.125rem 0" : props.rounded ? "4rem" : "0.5rem"};
  background: ${props =>
    props.bg
      ? props.bgColor
        ? props.bgColor
        : props.theme.palette.secondary.main
      : "white"};

  &[type="radio"] {
    margin: 0;
    margin-right: 0.25rem;
    width: 1rem;
    height: 1rem;
  }

  &[type="checkbox"] {
    margin: 0;
    margin-right: 0.25rem;
    width: 1rem;
    height: 1rem;
  }
`;

const TextFieldWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StartIcon = styled.span`
  position: absolute;
  left: 0.5rem;
  display: flex;
  align-items: center;
  height: 1rem;
  width: 1rem;

  & > * {
    height: 100%;
    width: 100%;
  }
`;

const EndIcon = styled.span`
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
  height: 1rem;
  width: 1rem;

  & > * {
    height: 100%;
    width: 100%;
  }
`;

const HelperText = styled.span`
  padding: 0 0.75rem;
  font-size: 0.75rem;
  color: ${props =>
    props.error ? props.theme.palette.error.main : "darkgray"};
`;

export const InputField = ({
  label,
  name,
  hasBg,
  isPlain,
  isLabelBold,
  isLabelEmpty,
  rounded,
  bgColor,
  startIcon,
  endIcon,
  error,
  helperText,
  register,
  ...rest
}) => {
  return (
    <Container>
      <Label htmlFor={name} labelBold={isLabelBold} empty={isLabelEmpty}>
        {label}
      </Label>
      <TextFieldWrapper>
        <StartIcon>{startIcon}</StartIcon>
        <TextField
          bg={hasBg}
          bgColor={bgColor}
          plain={isPlain}
          rounded={rounded}
          startIcon={startIcon}
          endIcon={endIcon}
          {...register(name)}
          {...rest}
        />
        <EndIcon>{endIcon}</EndIcon>
      </TextFieldWrapper>
      <HelperText error={error}>{_.capitalize(helperText)}</HelperText>
    </Container>
  );
};
