import { useChurchStore } from "features/churches";
import { useParams } from "react-router-dom";
import { useSendMessages } from "../api";
import { ChatPanel, MessageList, SentMessagesPanel } from "../components";
import { useStoredMessagesStore } from "../store";

export const SentMessages = () => {
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const storedMessages = useStoredMessagesStore(state => state.storedMessages);
  const storeMessages = useStoredMessagesStore(state => state.storeMessages);
  const sentQuery = useSendMessages({
    churchId: selectedChurch?.id,
    filters: ["filters[is_sent]=1", "sort[created_at]=DESC"]
  });

  if (id) {
    storeMessages(sentQuery?.data?.result);

    return (
      <>
        <MessageList title="Sent Messages" messages={storedMessages} />
        <ChatPanel />
      </>
    );
  }

  return <SentMessagesPanel />;
};
