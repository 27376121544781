import { Chat } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import { LoadingScreen } from "components/LoadingScreen";
import { Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { exportService, useTeam, useTeamMembers } from "../api";
import {
  AddMemberModal,
  CreateTeamModal,
  DeleteNote,
  PotentialMembersTable,
  PreviousMembersTable,
  TeamMembersTable,
  TeamPhoto,
  TeamsTableHeader
} from "../components";
import { UpdateTeamModal } from "../components/UpdateTeamModal";
import { useTeamsStore } from "../stores";

export const Team = () => {
  const openAddTeamMemberModal = useTeamsStore(
    state => state.openAddTeamMemberModal
  );
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const teamQuery = useTeam({
    churchId: selectedChurch?.id,
    teamId: id
  });
  const teamMembersQuery = useTeamMembers({
    churchId: selectedChurch?.id,
    teamId: id
  });
  const [teamData, setTeamData] = useState(null);
  const { t } = useTranslation();
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const addTeam = useMessagesStore(state => state.addTeam);
  const removeTeam = useMessagesStore(state => state.removeTeam);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const navigate = useNavigate();
  const currentTab = useTeamsStore(state => state.currentTab);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setHeaderTitle(teamData?.name);

    return () => setHeaderTitle("");
  }, [teamData]);

  useEffect(() => {
    setTeamData(teamQuery?.data?.result);

    return () => setTeamData(null);
  }, [teamQuery.data]);

  const messageTeam = () => {
    const teamMembers = teamMembersQuery?.data?.result || [];

    if (teamMembers.length > 0) {
      clearRecipients();
      removeTeam(teamData.id);
      addRecipient(`${teamData.id}_${teamData.name}`, teamData);
      addTeam(teamData.id, { id: teamData.id });
      navigate("/messages");
    }
  };

  const messageTeamLeaders = () => {
    const teamMembers = teamMembersQuery?.data?.result || [];

    if (teamMembers.length > 0) {
      const teamLeaders = teamMembers.filter(
        teamMember => teamMember.team_member.role !== "MEMBER"
      );

      if (teamLeaders.length > 0) {
        clearRecipients();
        for (const teamLeader of teamLeaders) {
          removeMember(teamLeader.id);
          addRecipient(
            `${teamLeader?.id}_${teamLeader?.first_name}_${teamLeader?.last_name}`,
            teamLeader
          );
          addMember(teamLeader.id, { id: teamLeader.id });
          navigate("/messages");
        }
      }
    }
  };

  const handleExport = (format = "xlsx") => {
    exportService({
      churchId: selectedChurch?.id,
      teamId: id,
      isResigned: currentTab === "previousMembers" ? 1 : 0,
      format: format
    });

    handleClose();
  };

  if (teamQuery.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <AddMemberModal />
      <CreateTeamModal />
      <UpdateTeamModal />
      <DeleteNote name={teamData?.name} />
      <Notification />
      <TeamPhoto name={teamData?.name} />
      <Stack
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "primary.main" }}
          />
        }
        my={6}
      >
        <Stack sx={{ flex: 1, px: 2 }} spacing={2}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("teams.teamProfile.TeamProfile")}
          </Typography>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography>{t("teams.teamProfile.ParentTeam")}</Typography>
              <Typography fontWeight="700" color="primary">
                {teamData?.parent
                  ? _.capitalize(teamData?.parent?.name)
                  : "None"}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography>{t("teams.teamProfile.TypeOfTeam")}</Typography>
              <Typography fontWeight="700" color="primary">
                {_.capitalize(teamData?.team_type?.name)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography>{t("teams.teamProfile.TotalMembers")}</Typography>
              <Typography fontWeight="700" color="primary">
                {teamData?.members_count}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ flex: 2, px: 2 }} spacing={2}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("teams.teamProfile.AboutTheTeam")}
          </Typography>
          <Typography variant="body2">{teamData?.description}</Typography>
        </Stack>
        <Stack
          sx={{ flex: 1, px: 2 }}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            startIcon={<Chat />}
            sx={{ borderRadius: "50px" }}
            onClick={messageTeam}
            disabled={teamMembersQuery?.data?.result.length === 0}
          >
            {t("teams.teamProfile.SendMessageForTeam")}
          </Button>
          <Button
            variant="contained"
            startIcon={<Chat />}
            sx={{ borderRadius: "50px" }}
            onClick={messageTeamLeaders}
            disabled={teamMembersQuery?.data?.result.length === 0}
          >
            {t("teams.teamProfile.SendMessageForLeaders")}
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ alignSelf: "flex-end" }}
        >
          <Button variant="contained" onClick={openAddTeamMemberModal}>
            {t("teams.teamProfile.AddTeamMember")}
          </Button>
          <Button variant="outlined" onClick={handleClick}>
            {t("teams.teamProfile.ExportMember")}
          </Button>
          <Menu
            id="export-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleExport("xlsx")}>Excel</MenuItem>
            <MenuItem onClick={() => handleExport("pdf")}>PDF</MenuItem>
          </Menu>
        </Stack>
        <TeamsTableHeader />
        {currentTab === "members" && <TeamMembersTable />}
        {currentTab === "previousMembers" && <PreviousMembersTable />}
        {currentTab === "potentialMembers" && (
          <PotentialMembersTable data={[]} />
        )}
      </Stack>
    </Box>
  );
};
