import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useChurchStore } from "../store";

export const updateChurch = ({ churchData, churchId }) => {
  return axios.post(`/churches/${churchId}?_method=PATCH`, churchData);
};

export const useUpdateChurch = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const selectChurch = useChurchStore.getState().selectChurch;

  return useMutation({
    onSuccess: response => {
      queryClient.invalidateQueries("churches");
      setNotification({
        type: "success",
        message: "Church updated successfully!"
      });

      selectChurch(response.result);
    },
    ...config,
    mutationFn: updateChurch
  });
};
