import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const updateEmployee = ({ churchId, employeeId, employeeData }) => {
  return axios.post(
    `/churches/${churchId}/employees/${employeeId}?_method=PATCH`,
    employeeData
  );
};

export const useUpdateEmployee = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("employees");
      queryClient.invalidateQueries("employee");

      setNotification({
        type: "success",
        message: "Employee updated successfully!"
      });
    },
    ...config,
    mutationFn: updateEmployee
  });
};
