import { yupResolver } from "@hookform/resolvers/yup";
import { EmailOutlined } from "@mui/icons-material";
import { Box, Button as MuiButton, Stack, Typography } from "@mui/material";
import {
  BackgroundInputField,
  Button,
  LanguageSelector,
  Notification
} from "components/UI";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { forgotPasswordWithEmail } from "../api";
import { forgotPasswordSchema } from "../validation";

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      email: ""
    },
    resolver: yupResolver(forgotPasswordSchema)
  });

  const onSubmit = async values => {
    setLoading(true);

    try {
      await forgotPasswordWithEmail(values);

      setLoading(false);

      navigate("/auth/check-email");
    } catch (error) {
      setLoading(false);

      setError("email", {
        type: "custom",
        message: error?.errors?.email
      });
    }
  };

  return (
    <Box sx={{ my: "auto" }} px={{ xs: 4, md: 6, lg: 12, xl: 22 }}>
      <Notification />
      <Typography
        variant="h4"
        sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}
      >
        {t("auth.forgotPassword.forgotPassword")}
      </Typography>
      <Typography component="h2" variant="body1" sx={{ textAlign: "center" }}>
        {t("auth.forgotPassword.description")}
      </Typography>
      <Box component="form" sx={{ mt: 4 }} onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="flex-end" gap="4px">
          <EmailOutlined />{" "}
          <label htmlFor="email">{t("auth.forgotPassword.emailAddress")}</label>
        </Box>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <BackgroundInputField
              fullWidth
              id="email"
              autoComplete="email"
              autoFocus
              size="small"
              {...field}
              error={!!errors.email?.message}
              helperText={t(errors.email?.message)}
            />
          )}
        />
        <Stack alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Button
            type="submit"
            size="large"
            fullWidth
            flavor="primary"
            disabled={loading}
          >
            {t("auth.forgotPassword.sendInstructions")}
          </Button>
          <MuiButton
            variant="text"
            disabled={loading}
            onClick={() => navigate(-1)}
          >
            {t("auth.forgotPassword.backToLogin")}
          </MuiButton>
        </Stack>
      </Box>
      <Stack alignItems="center" mt={4}>
        <LanguageSelector />
      </Stack>
    </Box>
  );
}
