import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import { useParams } from "react-router-dom";
import { useUserLogs } from "../api";

export const UserLogs = () => {
  const isOwnProfile = !location.pathname.includes("users");
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const auth = useAuthUser();
  const { id } = useParams();

  const { data } = useUserLogs({
    churchId: selectedChurch?.id,
    userId: isOwnProfile ? auth().id : id
  });

  return (
    <Box
      sx={{
        overflow: "hidden"
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "secondary.main",
          color: "primary.main",
          fontWeight: 700
        }}
      >
        User Logs in {selectedChurch?.name}
      </Box>
      <Stack
        sx={{
          p: 1,
          backgroundColor: "neutral.main"
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Date & Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.result?.map(log => (
              <TableRow key={log.id}>
                <TableCell>{log.event}</TableCell>
                <TableCell>{log.description}</TableCell>
                <TableCell>
                  {moment(log?.created_at).format("YYYY-MM-DD hh:mm:ss A")}
                </TableCell>
              </TableRow>
            ))}
            {data?.result?.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  No logs
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Stack>
    </Box>
  );
};
