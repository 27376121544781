import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useCreateChartOfAccount,
  useFinanceSettingsStore
} from "features/finances";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

const chartOfAccountSchema = yup.object({
  accountNumber: yup.string().required("Account Number is required"),
  accountName: yup.string().required("Account Name is required"),
  accountType: yup.string().required("Account Type is required"),
  description: yup.string().required("Description is required"),
  beginningBalance: yup.number().required("Beginning Balance is required"),
  beginningBalanceDate: yup
    .string()
    .required("Beginning Balance Date is required")
});

export function CreateChartOfAccountModal() {
  const createChartOfAccountOpen = useFinanceSettingsStore(
    state => state.createChartOfAccountOpen
  );
  const closeCreateChartOfAccountModal = useFinanceSettingsStore(
    state => state.closeCreateChartOfAccountModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const createChartOfAccountMutation = useCreateChartOfAccount();

  const accountTypes = [
    "BANK",
    "ASSET",
    "LIABILITY",
    "EQUITY",
    "INCOME",
    "EXPENSE"
  ];

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      accountNumber: "",
      accountName: "",
      accountType: "",
      description: "",
      beginningBalance: 0,
      beginningBalanceDate: ""
    },
    resolver: yupResolver(chartOfAccountSchema)
  });

  const onSubmit = data => {
    createChartOfAccountMutation.mutate(
      {
        churchId: selectedChurch?.id,
        chartOfAccountData: {
          account_number: data.accountNumber,
          account_name: data.accountName,
          account_type: data.accountType,
          description: data.description,
          beginning_balance: data.beginningBalance,
          beginning_balance_date: data.beginningBalanceDate
        }
      },
      {
        onSuccess: () => {
          reset();
        },
        onError: response => {
          setError("accountNumber", {
            type: "custom",
            message: response.errors?.account_number
          });
        }
      }
    );
  };

  return (
    <BaseModal
      title="Account"
      isOpen={createChartOfAccountOpen}
      onClose={closeCreateChartOfAccountModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeCreateChartOfAccountModal}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="accountNumber">Account Number</Label>
        <Input id="accountNumber" {...register("accountNumber")} />
        <ErrorMessage
          errors={errors}
          name="accountNumber"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="accountName">Account Name</Label>
        <Input id="accountName" {...register("accountName")} />
      </Field>
      <Field>
        <Label htmlFor="accountType">Account Type</Label>
        <Select id="accountType" {...register("accountType")}>
          {accountTypes.map(accountType => (
            <option key={accountType} value={accountType}>
              {accountType}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="description">Description</Label>
        <Input id="description" {...register("description")} />
      </Field>
      <Field>
        <Label htmlFor="beginningBalance">Beginning Balance</Label>
        <Input
          id="beginningBalance"
          type="number"
          {...register("beginningBalance")}
        />
      </Field>
      <Field>
        <Label htmlFor="beginningBalanceDate">Beginning Balance Date</Label>
        <Input
          id="beginningBalanceDate"
          type="date"
          {...register("beginningBalanceDate")}
        />
      </Field>
    </BaseModal>
  );
}
