import { Grid, Typography } from "@mui/material";
import { Select, TextField } from "components/UI";
import { GOOGLE_API_KEY } from "config";
import { ADDRESSES } from "features/members";
import { usePlacesWidget } from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import {
  useCities,
  useCountries,
  useRegions,
  useSubcities,
  useWoredas,
  useZones
} from "../../api";

export const KidAddressSection = ({ register, errors, watch, setValue }) => {
  const { t } = useTranslation();

  const countriesQuery = useCountries();
  const regionsQuery = useRegions({
    countryId: watch("country_id"),
    config: {
      enabled: !!watch("country_id")
    }
  });
  const zonesQuery = useZones({
    regionId: watch("region_id"),
    config: {
      enabled: !!watch("region_id")
    }
  });
  const citiesQuery = useCities({
    zoneId: watch("zone_id"),
    config: {
      enabled: !!watch("zone_id")
    }
  });
  const subcitiesQuery = useSubcities({
    cityId: watch("city_id"),
    config: {
      enabled: !!watch("city_id")
    }
  });
  const woredasQuery = useWoredas({
    subcityId: watch("subcity_id"),
    config: {
      enabled: !!watch("subcity_id")
    }
  });

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: place => {
      setValue("latitude", place.geometry.location.lat());
      setValue("longitude", place.geometry.location.lng());
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: "et" }
    }
  });

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">{t("form.common.address")}</Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.country")}
            name={`country_id`}
            options={
              countriesQuery.data?.result?.map(country => ({
                optionLabel: country?.name,
                value: country?.id
              })) || []
            }
            register={register}
            error={!!errors.country_id?.message}
            helperText={errors.country_id?.message}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.region")}
            name={`region_id`}
            options={
              regionsQuery.data?.result?.map(region => ({
                optionLabel: region?.name,
                value: region?.id
              })) || []
            }
            register={register}
            error={!!errors.region_id?.message}
            helperText={errors.region_id?.message}
            onChange={e => {
              setValue("region_id", e.target.value);

              if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                setValue("zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID);
                setValue("city_id", ADDRESSES.ADDIS_ABABA.CITY_ID);
              } else {
                setValue("zone_id", "");
                setValue("city_id", "");
              }
            }}
          />
        </Grid>
        {watch("region_id") !== ADDRESSES.ADDIS_ABABA.REGION_ID && (
          <>
            <Grid item xs={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.zone")}
                name={`zone_id`}
                options={
                  zonesQuery.data?.result?.map(zone => ({
                    optionLabel: zone?.name,
                    value: zone?.id
                  })) || []
                }
                register={register}
                error={!!errors.zone_id?.message}
                helperText={errors.zone_id?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.city")}
                name={`city_id`}
                options={
                  citiesQuery.data?.result?.map(city => ({
                    optionLabel: city?.name,
                    value: city?.id
                  })) || []
                }
                register={register}
                error={!!errors.city_id?.message}
                helperText={errors.city_id?.message}
              />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.subcity")}
            name={`subcity_id`}
            options={
              subcitiesQuery.data?.result?.map(subcity => ({
                optionLabel: subcity?.name,
                value: subcity?.id
              })) || []
            }
            register={register}
            error={!!errors.subcity_id?.message}
            helperText={errors.subcity_id?.message}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.woreda")}
            name={`woreda_id`}
            options={
              woredasQuery.data?.result?.map(woreda => ({
                optionLabel: woreda?.name,
                value: woreda?.id
              })) || []
            }
            register={register}
            error={!!errors.woreda_id?.message}
            helperText={errors.woreda_id?.message}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t("form.common.kebele")}
            name="kebele"
            error={!!errors.kebele?.message}
            helperText={errors.kebele?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t("form.common.houseNumber")}
            name="house_number"
            error={!!errors.house_number?.message}
            helperText={errors.house_number?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("form.common.specificLocation")}
            name="specific_location"
            error={!!errors.specific_location?.message}
            helperText={errors.specific_location?.message}
            register={register}
            ref={placesRef}
            placeholder=""
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
