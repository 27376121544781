import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import { useMembersStore } from "../stores";
import { previousChurchInfoSchema } from "../validation";
import { PreviousChurchInfoSection } from "./sections";

export function PreviousChurchInfoForm({ isEditMode }) {
  const memberData = useMembersStore(state => state.memberData);
  const setMemberData = useMembersStore(state => state.setMemberData);
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const { t } = useTranslation();
  const { next, prev } = useSteps();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      transfer_from_other_church: memberData.transfer_from_other_church
        ? memberData.transfer_from_other_church
        : "",
      previous_church_name: memberData.previous_church_name
        ? memberData.previous_church_name
        : "",
      date_of_resignation: memberData.date_of_resignation
        ? memberData.date_of_resignation
        : null,
      pastor_title: memberData.pastor_title ? memberData.pastor_title : "",
      pastor_full_name: memberData.pastor_full_name
        ? memberData.pastor_full_name
        : "",
      pastor_phone_number: memberData.pastor_phone_number
        ? memberData.pastor_phone_number
        : "",
      reason_for_resignation: memberData.reason_for_resignation
        ? memberData.reason_for_resignation
        : ""
    },
    resolver: yupResolver(previousChurchInfoSchema)
  });

  const onSubmit = data => {
    setMemberData(data);
    next();
  };

  useEffect(() => {
    if (isEditMode) {
      const fieldKeys = Object.keys(fullMemberData);

      for (let fieldKey of fieldKeys) {
        setValue(fieldKey, fullMemberData[fieldKey]);
      }
    }
  }, [isEditMode]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DevTool control={control} />
      <PreviousChurchInfoSection
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        setValue={setValue}
        isEditMode={isEditMode}
      />
      {isEditMode ? (
        <Box sx={{ mt: 6 }} />
      ) : (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ mt: 8 }}
        >
          <Button
            type="submit"
            variant="contained"
            startIcon={<ArrowBack />}
            onClick={prev}
          >
            {t("form.common.back")}
          </Button>
          <Button type="submit" variant="contained" endIcon={<ArrowForward />}>
            {t("form.common.next")}
          </Button>
        </Stack>
      )}
    </form>
  );
}
