import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getAsset = ({ churchId, assetId }) => {
  return axios.get(`/churches/${churchId}/assets/${assetId}`);
};

export const useAsset = ({ churchId, assetId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["asset", assetId],
    queryFn: () => getAsset({ churchId, assetId })
  });
};
