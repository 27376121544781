import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const updateRevenueMethod = ({
  churchId,
  revenueMethodData,
  revenueMethodId
}) => {
  return axios.patch(
    `/churches/${churchId}/revenue-methods/${revenueMethodId}`,
    revenueMethodData
  );
};

export const useUpdateRevenueMethod = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateRevenueMethodModal =
    useRevenueStore.getState().closeUpdateRevenueMethodModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["revenue-methods"]);
      setNotification({
        type: "success",
        message: "Revenue method updated successfully!"
      });
      closeUpdateRevenueMethodModal();
    },
    ...config,
    mutationFn: updateRevenueMethod
  });
};
