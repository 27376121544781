import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";

export const deleteMember = ({ churchId, memberId }) => {
  return axios.delete(`/churches/${churchId}/members/${memberId}`);
};

export const useDeleteMember = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["members"]);
      setNotification({
        type: "success",
        message: "Member deleted successfully!"
      });
      closeDeleteModal();
      navigate("/members");
    },
    ...config,
    mutationFn: deleteMember
  });
};
