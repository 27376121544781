import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const deleteRevenue = ({ churchId, revenueId }) => {
  return axios.delete(`/churches/${churchId}/revenue/${revenueId}`);
};

export const useDeleteRevenue = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closeUpdateRevenueModal =
    useRevenueStore.getState().closeUpdateRevenueModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["revenues"]);
      setNotification({
        type: "success",
        message: "Revenue deleted successfully!"
      });
      closeDeleteModal();
      closeUpdateRevenueModal();
    },
    ...config,
    mutationFn: deleteRevenue
  });
};
