import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPartners = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/partners`);
};

export const usePartners = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["partners", churchId],
    queryFn: () => getPartners({ churchId })
  });
};
