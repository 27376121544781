import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Link,
  Stack,
  Typography
} from "@mui/material";
import { BackgroundInputField, SocialButton } from "components/UI";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Controller, useForm } from "react-hook-form";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";

const createAccountSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email is not valid"),
    phone: yup
      .string()
      .required("Phone is required")
      .test("is-phone-valid", "Phone is not valid", val =>
        matchIsValidTel(val)
      ),
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    acceptTerms: yup.boolean().oneOf([true], "Terms and conditions is required")
  })
  .required();

export function CreateUserAccount() {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      acceptTerms: false
    },
    resolver: yupResolver(createAccountSchema)
  });

  const onSubmit = data => {
    console.log(data);
  };

  return (
    <Box px={{ xs: 4, md: 6, lg: 12 }}>
      <Typography component="h1" variant="h6" sx={{ fontWeight: "bold" }}>
        Create Account
      </Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 4 }}
        spacing={1}
      >
        <Stack direction="row" spacing={2}>
          <Stack sx={{ flex: 1 }}>
            <label htmlFor="firstName">First Name</label>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <BackgroundInputField
                  id="firstName"
                  autoFocus
                  size="small"
                  {...field}
                  error={!!errors.firstName?.message}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <label htmlFor="lastName">Last Name</label>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <BackgroundInputField
                  id="lastName"
                  size="small"
                  {...field}
                  error={!!errors.lastName?.message}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack>
          <label htmlFor="email">Email address</label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <BackgroundInputField
                id="email"
                autoComplete="email"
                size="small"
                {...field}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            )}
          />
        </Stack>
        <Stack>
          <label htmlFor="phone">Phone Number</label>
          <Controller
            name="phone"
            control={control}
            rules={{ validate: matchIsValidTel }}
            render={({ field }) => (
              <MuiTelInput
                id="phone"
                {...field}
                error={!!errors.phone?.message}
                helperText={errors.phone?.message}
                forceCallingCode
                defaultCountry="ET"
                disableFormatting
                sx={{
                  borderRadius: "5px",
                  "& .MuiInputBase-root": {
                    backgroundColor: "secondary.main"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: `none`
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: `none`
                    }
                  }
                }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" alignItems="flex-end" spacing={6}>
          <Stack>
            <span>Upload a photo</span>
            <Button type="submit" size="large" variant="contained">
              Browse
            </Button>
          </Stack>
          <Stack>
            <Avatar
              variant="rounded"
              src=""
              alt="Image"
              sx={{ width: 100, height: 100 }}
            />
          </Stack>
        </Stack>
        <Stack>
          <label htmlFor="password">New Password</label>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <BackgroundInputField
                id="password"
                size="small"
                {...field}
                error={!!errors.password?.message}
                helperText={errors.password?.message}
                type="password"
                autoComplete="current-password"
              />
            )}
          />
        </Stack>
        <Stack>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <BackgroundInputField
                id="confirmPassword"
                size="small"
                {...field}
                error={!!errors.confirmPassword?.message}
                helperText={errors.confirmPassword?.message}
                type="password"
                autoComplete="current-password"
              />
            )}
          />
        </Stack>
        <Stack alignItems="center">
          <FormControlLabel
            label={
              <Typography variant="body1">
                I agree to the{" "}
                <Link href="#" color="inherit">
                  terms & conditions
                </Link>
              </Typography>
            }
            control={
              <Controller
                name="acceptTerms"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    color="primary"
                  />
                )}
              />
            }
          />
          {!!errors.acceptTerms?.message && (
            <FormHelperText error>{errors.acceptTerms?.message}</FormHelperText>
          )}
        </Stack>
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Create account
        </Button>
        <Typography variant="body1">
          Do you have an account?{" "}
          <Link component={RouterLink} to="/auth/sign-in" color="inherit">
            Sign in
          </Link>
        </Typography>
        <Divider sx={{ mt: 4, mb: 2 }}>Or</Divider>
        <Stack direction="row" spacing={2}>
          <SocialButton icon={<FcGoogle />}>Sign in with Google</SocialButton>
          <SocialButton icon={<BsFacebook color="#039BE5" />}>
            Sign in with Facebook
          </SocialButton>
        </Stack>
      </Stack>
    </Box>
  );
}
