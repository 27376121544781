import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getTeam = ({ churchId, teamId }) => {
  return axios.get(`/churches/${churchId}/teams/${teamId}`);
};

export const useTeam = ({ churchId, teamId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["team", teamId, churchId],
    queryFn: () => getTeam({ churchId, teamId })
  });
};
