import styled from "@emotion/styled";

const NativeCheckbox = styled.input`
  appearance: none;
  --webkit-appearance: none;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.palette.secondary.main};
  border: 0.125rem solid
    ${({ theme, bgColor }) =>
      bgColor ? bgColor : theme.palette.secondary.main};
  width: 2.5rem;
  height: 1.25rem;
  border-radius: 100px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    background-color: #828282;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    position: absolute;
    left: 0.125rem;
    transition: all 0.4s;
  }

  &:checked {
    background: ${({ theme, bgColor }) =>
      bgColor ? bgColor : theme.palette.secondary.main};
    border-color: ${({ theme, bgColor }) =>
      bgColor ? bgColor : theme.palette.secondary.main};
  }

  &:checked::before {
    background: ${({ theme }) => theme.palette.primary.main};
    left: calc(100% - 1rem);
  }
`;

const ToggleLabel = styled.label`
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  gap: 0.5rem;
  font-family: StabilGrotesk, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  ${({ disabled }) =>
    disabled &&
    `
      color: gray !important;
      cursor: not-allowed;
   `}
`;

export const Toggle = ({ label, disabled, bgColor, ...rest }) => {
  return (
    <ToggleLabel disabled={disabled}>
      <NativeCheckbox
        type="checkbox"
        disabled={disabled}
        bgColor={bgColor}
        {...rest}
      />
      {label}
    </ToggleLabel>
  );
};
