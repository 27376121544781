const extraColumns = [
  "date_of_birth",
  "country_id",
  "region_id",
  "city_id",
  "id_type",
  "id_number",
  "social_media_links",
  "languages",
  "current_country_id",
  "current_region_id",
  "current_zone_id",
  "current_city_id",
  "current_sub_city_id",
  "current_woreda_id",
  "current_kebele",
  "house_number",
  "specific_location",
  "emergency_contact_first_name",
  "emergency_contact_father_name",
  "emergency_contact_last_name",
  "emergency_contact_relationship",
  "emergency_contact_country_id",
  "emergency_contact_region_id",
  "emergency_contact_city_id",
  "education_and_skills",
  "job_status",
  "profession",
  "work_place",
  "monthly_income",
  "marital_status",
  "marital_date",
  "spouse_church_name",
  "spouse_first_name",
  "spouse_father_name",
  "spouse_last_name",
  "spouse_phone_number",
  "child_and_dependencies",
  "relationship_with_child",
  "previous_church_name",
  "pastor_full_name",
  "pastor_phone_number",
  "reason_for_resignation",
  "salvation_date",
  "salvation_church",
  "is_baptized",
  "baptism_church",
  "baptism_date",
  "membership_date",
  "ministry_preference",
  "gifts_and_talents",
  "mother_first_name",
  "mother_father_name",
  "mother_grand_father_name",
  "spouse_religion",
  "spouse_mother_name",
  "spouse_mother_father_name",
  "spouse_mother_grand_father_name",
  "spouse_mother_date_of_birth",
  "is_spouse_believer",
  "is_spouse_member",
  "office_number",
  "po_box",
  "is_home_cell",
  "home_cell_name",
  "home_cell_cordinator",
  "date_joined_home_cell",
  "current_ministry",
  "date_joined_current_ministry",
  "role_in_current_ministry",
  "date_of_resignation",
  "former_belief",
  "how_you_accept_christ"
];
const uniqueExtraColumns = [...new Set(extraColumns)];

export const extraMemberColumns = uniqueExtraColumns.map(column => {
  if (column === "country_id") {
    return {
      field: column,
      headerName: "Country",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.birth_country?.name}</span>;
      }
    };
  }

  if (column === "region_id") {
    return {
      field: column,
      headerName: "Region",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.birth_region?.name}</span>;
      }
    };
  }

  if (column === "city_id") {
    return {
      field: column,
      headerName: "City",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.birth_city?.name}</span>;
      }
    };
  }

  if (column === "current_country_id") {
    return {
      field: column,
      headerName: "Current Country",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.current_country?.name}</span>;
      }
    };
  }

  if (column === "current_region_id") {
    return {
      field: column,
      headerName: "Current Region",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.current_region?.name}</span>;
      }
    };
  }

  if (column === "current_zone_id") {
    return {
      field: column,
      headerName: "Current Zone",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.current_zone?.name}</span>;
      }
    };
  }

  if (column === "current_city_id") {
    return {
      field: column,
      headerName: "Current City",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.current_city?.name}</span>;
      }
    };
  }

  if (column === "current_sub_city_id") {
    return {
      field: column,
      headerName: "Current Subcity",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.current_sub_city?.name}</span>;
      }
    };
  }

  if (column === "current_woreda_id") {
    return {
      field: column,
      headerName: "Current Woreda",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.current_woreda?.name}</span>;
      }
    };
  }

  if (column === "emergency_contact_country_id") {
    return {
      field: column,
      headerName: "Emergency Contact Country",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.emergency_contact_country?.name}</span>;
      }
    };
  }

  if (column === "emergency_contact_region_id") {
    return {
      field: column,
      headerName: "Emergency Contact Region",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.emergency_contact_region?.name}</span>;
      }
    };
  }

  if (column === "emergency_contact_city_id") {
    return {
      field: column,
      headerName: "Emergency Contact City",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row.emergency_contact_city?.name}</span>;
      }
    };
  }

  if (column === "ministry_preference") {
    return {
      field: column,
      headerName: "Ministry Preference",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        const preferences = row.ministry_preference?.map(
          ministryPreference => ministryPreference.ministry
        );

        return <span>{preferences.join(", ")}</span>;
      }
    };
  }

  if (column === "education_and_skills") {
    return {
      field: column,
      headerName: "Education and Skills",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        const skills = row.education_and_skills?.map(
          educationAndSkills =>
            `${educationAndSkills.academic_status} - ${educationAndSkills.professional_skill}`
        );

        return <span>{skills.join(", ")}</span>;
      }
    };
  }

  if (
    [
      "is_spouse_believer",
      "is_spouse_member",
      "child_and_dependencies",
      "is_believer",
      "is_spouse_believer",
      "is_member",
      "is_spouse_member",
      "transfer_from_other_church",
      "is_baptized",
      "is_home_cell"
    ].includes(column)
  ) {
    return {
      field: column,
      headerName: column
        .replaceAll("_", " ")
        .toLowerCase()
        .split(" ")
        .map((word, index) => {
          if (index !== 0 && word === "id") {
            return word.replace("id", "");
          }

          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ")
        .trim(),
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{row?.column === 1 ? "Yes" : "No"}</span>;
      }
    };
  }

  return {
    field: column,
    headerName: column
      .replaceAll("_", " ")
      .toLowerCase()
      .split(" ")
      .map((word, index) => {
        if (index !== 0 && word === "id") {
          return word.replace("id", "");
        }

        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ")
      .trim(),
    flex: 1,
    minWidth: 120
  };
});

export const extraMemberColumnsVisibility = {};
extraMemberColumns.forEach(({ field }) => {
  extraMemberColumnsVisibility[field] = false;
});

export const excludeMemberColumns = ["operation", "team", "email"];

const MAX_NUMBER_OF_COLUMNS_THRESHOLD = 7;
export const isExceedingThreshold = (
  columnsVisibility,
  maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS_THRESHOLD
) => {
  return (
    Object.values(columnsVisibility).filter(Boolean).length > maxNumberOfColumns
  );
};
