import styled from "@emotion/styled";

const Container = styled.div`
  border-radius: 5px;
  padding: 8px;
  color: ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  gap: 27px;
  margin-bottom: 40px;
`;

const Tab = styled.div`
  border-radius: 8px;
  font-weight: 500;
  padding: 15px;
  background: ${({ active }) => active && "white"};
  cursor: pointer;
`;

export const SmsStatusNavbar = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <Container>
      {tabs.map(tab => (
        <Tab
          key={tab.key}
          active={activeTab === tab.key}
          onClick={() => setActiveTab(tab.key)}
        >
          {tab.name}
        </Tab>
      ))}
    </Container>
  );
};
