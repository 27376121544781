import styled from "@emotion/styled";
// import { ArrowRightAlt } from "@mui/icons-material";
// import { useTranslation } from "react-i18next";

const Bar = styled.div`
  display: flex;
  padding: 15px 25px 15px 15px;
  align-items: center;
  justify-content: space-between;
  background: ${({ background }) => background};
  color: #ffffff;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  display: grid;
  place-items: center;
  color: ${({ color }) => color};
`;

const Title = styled.h5`
  margin: 0;
`;

// const ReportButton = styled.button`
//   border: 1px solid #ffffff;
//   border-radius: 4px;
//   background: transparent;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   color: #ffffff;
//   padding: 4px 16px;
//   cursor: pointer;

//   span {
//     margin-right: 8px;
//   }
// `;

export const SectionBar = ({ icon, title, color }) => {
  // const { t } = useTranslation();

  return (
    <Bar background={color}>
      <Wrapper>
        <IconCircle color={color}>{icon}</IconCircle>
        <Title>{title}</Title>
      </Wrapper>
      {/* <ReportButton>
        <span>{t("dashboard.member.CustomReport")}</span>
        <ArrowRightAlt />
      </ReportButton> */}
    </Bar>
  );
};
