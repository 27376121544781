import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { useCentralStore } from "stores";

export const changePassword = ({ passwordData }) => {
  return axios.post(`/user/change-credentials/`, passwordData);
};

export const useChangePassword = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      setNotification({
        type: "success",
        message: "Password changed successfully!"
      });
    },
    ...config,
    mutationFn: changePassword
  });
};
