import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { QueryBuilder } from "../utils/queryBuilder";

export const getMembersPaginated = ({ churchId, page, pageSize, queries }) => {
  const queryBuilder = new QueryBuilder();

  queryBuilder.pagination(page, pageSize);

  if (queries.filter) {
    queryBuilder.filter(queries.filter);
  }

  if (queries.search) {
    queryBuilder.search(queries.search);
  }

  if (queries.sort) {
    queryBuilder.sort(queries.sort);
  }

  const queryString = queryBuilder.build();

  return axios.get(`/churches/${churchId}/members-paginated${queryString}`);
};

export const useMembersPaginated = ({
  churchId,
  page,
  pageSize,
  queries = {},
  config
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["members", page, pageSize, churchId, JSON.stringify(queries)],
    queryFn: () => getMembersPaginated({ churchId, page, pageSize, queries })
  });
};
