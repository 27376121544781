import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const createRevenueMethod = ({ churchId, revenueMethodData }) => {
  return axios.post(`/churches/${churchId}/revenue-methods`, revenueMethodData);
};

export const useCreateRevenueMethod = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateRevenueMethodModal =
    useRevenueStore.getState().closeCreateRevenueMethodModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("revenue-methods");
      setNotification({
        type: "success",
        message: "Revenue method created successfully!"
      });
      closeCreateRevenueMethodModal();
    },
    ...config,
    mutationFn: createRevenueMethod
  });
};
