import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Stack } from "@mui/material";
import { Button, TextField } from "components/UI";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMessagesStore } from "../store";
import { templateMessageSchema } from "../validation";

export function UpdateTemplateModal({ templateData, isLoading, onUpdate }) {
  const updateTemplateModalOpen = useMessagesStore(
    state => state.updateTemplateModalOpen
  );
  const closeUpdateTemplateModal = useMessagesStore(
    state => state.closeUpdateTemplateModal
  );
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(templateMessageSchema)
  });

  useEffect(() => {
    if (templateData) {
      reset({ name: templateData.name, message: templateData.message });
    }
  }, [templateData]);

  const onSubmit = data => {
    onUpdate(data);
  };

  return (
    <Modal open={updateTemplateModalOpen} onClose={closeUpdateTemplateModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("message.template.UpdateTemplate")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label={t("message.template.MessageTitle")}
            name="name"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            register={register}
            isLabelBold={1}
            autoComplete="off"
          />
          <TextField
            label={t("message.template.Message")}
            name="message"
            error={!!errors.message?.message}
            helperText={errors.message?.message}
            register={register}
            multiline
            minRows={4}
            fullWidth
            isLabelBold={1}
            autoComplete="off"
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              flavor="secondary"
              onClick={closeUpdateTemplateModal}
              disabled={isLoading}
            >
              {t("form.common.cancel")}
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={isLoading}
            >
              {t("message.template.UpdateTemplate")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
