import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const deleteChartOfAccount = ({ churchId, chartOfAccountId }) => {
  return axios.delete(
    `/churches/${churchId}/chart-of-accounts/${chartOfAccountId}`
  );
};

export const useDeleteChartOfAccount = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closeUpdateChartOfAccountModal =
    useFinanceSettingsStore.getState().closeUpdateChartOfAccountModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["chart-accounts"]);
      setNotification({
        type: "success",
        message: "Chart of account deleted successfully!"
      });
      closeDeleteModal();
      closeUpdateChartOfAccountModal();
    },
    ...config,
    mutationFn: deleteChartOfAccount
  });
};
