import { PhotoCamera } from "@mui/icons-material";
import { Avatar, Badge, Box, Stack, Typography } from "@mui/material";
import SidebarPhoto from "assets/images/Photo.png";
import { IMAGE_URL } from "config";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateChurch } from "../api";
import { useChurchStore } from "../store";

export function ChurchProfileSidebar() {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();
  const churchUpdateMutation = useUpdateChurch();
  const [photo, setPhoto] = useState();

  const handleChange = event => {
    const formData = new FormData();
    const fileUploaded = event.target.files[0];
    formData.append("image", fileUploaded);
    churchUpdateMutation.mutate({
      churchData: formData,
      churchId: selectedChurch.id
    });
    setPhoto(fileUploaded);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${SidebarPhoto})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        py: 8,
        display: "flex",
        flexDirection: "column",
        color: "white",
        height: "100%",
        justifyContent: "space-between"
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Stack direction="row" justifyContent="center">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={
              <Avatar
                color="neutral"
                aria-label="upload picture"
                component="label"
                sx={{
                  color: "primary.main",
                  backgroundColor: "white",
                  cursor: "pointer"
                }}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                />
                <PhotoCamera />
              </Avatar>
            }
          >
            <Avatar
              src={
                photo
                  ? URL.createObjectURL(photo)
                  : `${IMAGE_URL}/${selectedChurch?.image_url}`
              }
              alt={selectedChurch?.name}
              sx={{
                width: 160,
                height: 160,
                outline: "6px solid white"
              }}
            />
          </Badge>
        </Stack>
        <Typography variant="h6" textAlign="center">
          {selectedChurch?.name}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 500, textAlign: "center" }}
        >
          {t("churches.profile.Denomination")}:{" "}
          {selectedChurch?.denomination?.name}
        </Typography>
        <Typography variant="caption" textAlign="center">
          {t("denominations.profile.CreatedDate")}{" "}
          {moment(selectedChurch?.created_at).format("DD/MM/YYYY")}
        </Typography>
      </Stack>
    </Box>
  );
}
