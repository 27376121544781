import styled from "@emotion/styled";
import { DeleteOutline } from "@mui/icons-material";
import { Button, DeleteModal } from "components/UI";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCentralStore } from "stores";
import { useDeletePort, usePorts } from "../api";
import { useMessagesStore } from "../store";
import { UpdatePortModal } from "./UpdatePortModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 700px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding-bottom: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  & td {
    text-align: center;
    padding: 10px 0;
  }
`;

const DeleteButton = styled(Button)`
  color: red;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const CenteredTd = styled.td`
  display: grid;
  place-items: center;
`;

export const PortManagementPanel = () => {
  const openCreatePortModal = useMessagesStore(
    state => state.openCreatePortModal
  );
  const openUpdatePortModal = useMessagesStore(
    state => state.openUpdatePortModal
  );
  const [portData, setPortData] = useState();
  const [selectedPort, setSelectedPort] = useState();
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const closeDeleteModal = useCentralStore(state => state.closeDeleteModal);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const portsQuery = usePorts({
    churchId: selectedChurch?.id,
    onSuccess: response => {
      console.log({ response });
    }
  });
  const portDeleteMutation = useDeletePort();
  const { t } = useTranslation();
  const defaultMessagePortName = "Default Message Port";

  return (
    <Container>
      <UpdatePortModal portData={portData} />
      <DeleteModal
        name={selectedPort?.name}
        onDelete={() => {
          portDeleteMutation.mutate({
            churchId: selectedChurch?.id,
            portId: selectedPort?.id
          });
          closeDeleteModal();
        }}
      />
      <div>
        <Button onClick={openCreatePortModal}>
          {t("message.settings.CreateMessagePort")}
        </Button>
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>{t("message.settings.PortManagement")}</th>
              <th>{t("message.settings.PortDetail")}</th>
              <th>{t("message.settings.UpdatePort")}</th>
              <th>{t("message.settings.DeletePort")}</th>
            </tr>
          </thead>
          <tbody>
            {portsQuery.data?.result?.map(port => (
              <tr key={port.id}>
                <td>{port?.name}</td>
                <td>{port?.message_port_type?.name}</td>
                <td>
                  {port.name !== defaultMessagePortName && (
                    <Button
                      flavor="neutral"
                      onClick={() => {
                        setPortData(port);
                        openUpdatePortModal();
                      }}
                    >
                      {t("message.settings.UpdatePort")}
                    </Button>
                  )}
                </td>
                <CenteredTd>
                  {port.name !== defaultMessagePortName && (
                    <DeleteButton
                      flavor="neutral"
                      onClick={() => {
                        setSelectedPort(port);
                        openDeleteModal();
                      }}
                    >
                      <DeleteOutline />
                      <span>{t("message.settings.DeletePort")}</span>
                    </DeleteButton>
                  )}
                </CenteredTd>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
