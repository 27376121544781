import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getRegions = ({ countryId }) => {
  return axios.get(`/addresses/countries/${countryId}/regions`);
};

export const useRegions = ({ countryId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["regions", countryId],
    queryFn: () => getRegions({ countryId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
