import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useRevenueStore } from "features/finances";
import { useState } from "react";
import { BaseModal } from "./BaseModal";

const SmallText = styled.span`
  color: #8e8e8e;
  font-size: 10px;
  font-weight: 500;
`;

const SmallInformationText = styled.span`
  color: #3186ea;
  font-size: 8px;
  font-weight: 500;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

export function UpdateChequeModal() {
  const updateChequeOpen = useRevenueStore(state => state.updateChequeOpen);
  const closeUpdateChequeModal = useRevenueStore(
    state => state.closeUpdateChequeModal
  );
  const [method, setMethod] = useState("cheque");

  return (
    <BaseModal
      title="Cheque update"
      isOpen={updateChequeOpen}
      onClose={closeUpdateChequeModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button flavor="neutral" size="small">
            Delete
          </Button>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button flavor="white" size="small">
              Cancel
            </Button>
            <Button size="small">Save changes</Button>
          </Stack>
        </Stack>
      }
    >
      <SmallText>Transaction Number RT-23322213</SmallText>
      <Field>
        <Label htmlFor="fullName">Full Name</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Revenue type or reason</Label>
        <Select id="fullName">
          <option>Tithe</option>
          <option>Gift</option>
          <option>Project Contribution</option>
          <option>Offering</option>
          <option>First Fruit</option>
          <option>Service Fee</option>
          <option>Sales</option>
          <option>Event Registration</option>
          <option>Other</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="fullName">Method</Label>
        <Select
          id="fullName"
          value={method}
          onChange={e => setMethod(e.target.value)}
        >
          <option value="cash">Cash</option>
          <option value="cheque">Cheque</option>
          <option value="bankTransfer">Bank Transfer</option>
          <option value="mobileTransfer">Mobile Transfer</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="fullName">Account</Label>
        <Input id="fullName" />
      </Field>
      {method === "cheque" && (
        <SmallInformationText>
          Check Selected: After Saving Please Reconcile and Approve under check
          reconcile menu. Recorded Revenue will not be added until approved.
        </SmallInformationText>
      )}
      {method === "cash" && (
        <Field>
          <Label htmlFor="fullName">Receipt Number</Label>
          <Input id="fullName" />
        </Field>
      )}
      {method === "cheque" && (
        <>
          <Field>
            <Label htmlFor="fullName">Check Number</Label>
            <Input id="fullName" />
          </Field>
          <Field>
            <Label htmlFor="fullName">Bank</Label>
            <Input id="fullName" />
          </Field>
          <Field>
            <Label htmlFor="fullName">Account Holder Full Name</Label>
            <Input id="fullName" />
          </Field>
          <Field>
            <Label htmlFor="fullName">Account Number</Label>
            <Input id="fullName" />
          </Field>
          <Field>
            <Label htmlFor="fullName">Phone Number</Label>
            <Input id="fullName" />
          </Field>
        </>
      )}
      {method === "bankTransfer" && (
        <>
          <Field>
            <Label htmlFor="fullName">Bank Name</Label>
            <Input id="fullName" />
          </Field>
          <Field>
            <Label htmlFor="fullName">Reference Number</Label>
            <Input id="fullName" />
          </Field>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Label htmlFor="uploadPhoto">Bank slip attachment</Label>
            <Button id="uploadPhoto" flavor="outlined">
              Upload photo
            </Button>
          </Stack>
        </>
      )}
      {method === "mobileTransfer" && (
        <>
          <Field>
            <Label htmlFor="fullName">Bank Name</Label>
            <Input id="fullName" />
          </Field>
          <Field>
            <Label htmlFor="fullName">Reference Number</Label>
            <Input id="fullName" />
          </Field>
        </>
      )}
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="fullName">Amount</Label>
          <Input id="fullName" />
        </Field>
        <Field>
          <Label htmlFor="fullName">Currency</Label>
          <Select id="fullName">
            <option>ETB</option>
          </Select>
        </Field>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="fullName">Received date</Label>
          <Input id="fullName" type="date" />
        </Field>
        <Field>
          <Label htmlFor="fullName">Recorded date and time</Label>
          <Input id="fullName" type="datetime-local" />
        </Field>
      </Stack>
      {method === "cheque" && (
        <Stack direction="row" spacing={2}>
          <Field>
            <Label htmlFor="fullName">Due date</Label>
            <Input id="fullName" type="date" />
          </Field>
          <Field>
            <Label htmlFor="fullName">Received date</Label>
            <Input id="fullName" type="date" />
          </Field>
        </Stack>
      )}
    </BaseModal>
  );
}
