import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useActivateApprover,
  useDeactivateApprover,
  useDeleteApprover,
  useFinanceSettingsStore,
  useUpdateApprover
} from "features/finances";
import { useUsers } from "features/users";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const approverSchema = yup.object({
  approver: yup.string(),
  approverLevel: yup.string(),
  approvalLimitPerRequest: yup.number(),
  approvalLimitPerMonth: yup.number()
});

export function UpdateApproverModal({ data }) {
  const updateApproverOpen = useFinanceSettingsStore(
    state => state.updateApproverOpen
  );
  const closeUpdateApproverModal = useFinanceSettingsStore(
    state => state.closeUpdateApproverModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const usersQuery = useUsers({
    churchId: selectedChurch?.id
  });
  const updateApproverMutation = useUpdateApprover();
  const deleteApproverMutation = useDeleteApprover();
  const deactivateApproverMutation = useDeactivateApprover();
  const activateApproverMutation = useActivateApprover();

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      approver: data?.approver_id,
      approverLevel: data?.approver_level,
      approvalLimitPerRequest: data?.approval_limit_per_request,
      approvalLimitPerMonth: data?.approval_limit_per_month
    },
    resolver: yupResolver(approverSchema)
  });

  useEffect(() => {
    if (data) {
      setValue("approver", data?.approver_id);
      setValue("approverLevel", data?.approver_level);
      setValue("approvalLimitPerRequest", data?.approval_limit_per_request);
      setValue("approvalLimitPerMonth", data?.approval_limit_per_month);
    }
  }, [data]);

  const onSubmit = values => {
    const approverData = {
      approver_id: values.approver,
      approval_limit_per_request: values.approvalLimitPerRequest,
      approval_limit_per_month: values.approvalLimitPerMonth,
      approver_level: values.approverLevel
    };

    updateApproverMutation.mutate(
      {
        churchId: selectedChurch?.id,
        approverData,
        approverId: data?.id
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Update Approver"
      isOpen={updateApproverOpen}
      onClose={closeUpdateApproverModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" justifyContent="flex-start" spacing={2}>
            <Button
              flavor="neutral"
              size="small"
              onClick={() => {
                deleteApproverMutation.mutate({
                  churchId: selectedChurch?.id,
                  approverId: data?.id
                });
              }}
            >
              Delete
            </Button>
            {data?.approver_status === "ACTIVE" ? (
              <Button
                flavor="neutral"
                size="small"
                onClick={() => {
                  deactivateApproverMutation.mutate({
                    churchId: selectedChurch?.id,
                    approverId: data?.id,
                    approverData: {}
                  });
                }}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                flavor="neutral"
                size="small"
                onClick={() => {
                  activateApproverMutation.mutate({
                    churchId: selectedChurch?.id,
                    approverId: data?.id,
                    approverData: {}
                  });
                }}
              >
                Activate
              </Button>
            )}
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              flavor="white"
              size="small"
              onClick={closeUpdateApproverModal}
            >
              Cancel
            </Button>
            <Button size="small" onClick={handleSubmit(onSubmit)}>
              Update Approver
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="name">Approver Name</Label>
        <Select id="name" {...register("approver")}>
          {usersQuery.data?.result?.map(user => (
            <option key={user.id} value={user.id}>
              {user.first_name} {user.last_name}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="approverLevel">Approval Level</Label>
        <Select id="approverLevel" {...register("approverLevel")}>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerRequest">
          Maximum Approve per request in ETB
        </Label>
        <Input
          id="approvalLimitPerRequest"
          type="number"
          {...register("approvalLimitPerRequest")}
        />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerMonth">
          Maximum Approve per month in ETB
        </Label>
        <Input
          id="approvalLimitPerMonth"
          type="number"
          {...register("approvalLimitPerMonth")}
        />
      </Field>
    </BaseModal>
  );
}
