import styled from "@emotion/styled";
import { Inbox, MoreVert, Send } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { Select } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useEditMessage,
  usePorts,
  useResendMessage,
  useSendMessages
} from "../api";
import { useMessagesStore } from "../store";
import { EditMessageModal } from "./EditMessageModal";
import { EmptyState } from "./EmptyState";
import { RescheduleOptions } from "./RescheduleOptions";
import { SelectedRecipientsModal } from "./SelectedRecipientsModal";
import { SendOptions } from "./SendOptions";

const Container = styled.div`
  width: 100%;
  background: #f3f3f3;
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-height: 913px;
`;

const Header = styled.div`
  padding: 13px 31px;
  background: ${({ theme }) => theme.palette.primary.main};
  color: white;

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
`;

const Compose = styled.div`
  background: #e7e7e7;
  display: flex;
  padding: 17px 29px;
  min-height: 110px;
  align-items: center;
  gap: 10px;
`;

const MessageInput = styled.textarea`
  flex: 1;
  resize: none;
  min-height: 46px;
  border: none;
  outline: none;
  background: inherit;
  font: inherit;
  align-self: stretch;
`;

const Messages = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`;

const Message = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
`;

const MessageBubble = styled.div`
  width: 332px;
  font-size: 12px;
  line-height: 18px;
  padding: 20px 17px;
  background: white;
  border-radius: 10px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

const Time = styled.span`
  font-size: 10px;
  align-self: flex-end;
  font-weight: 500;
  position: absolute;
  bottom: 0.3rem;
  right: 1rem;
`;

const LinkAction = styled.button`
  all: unset;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  padding: 4px;
`;

export const ChatPanel = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const message = useMessagesStore(state => state.message);
  const recipients = useMessagesStore(state => state.recipients);
  const messages = useMessagesStore(state => state.messages);
  const members = useMessagesStore(state => state.members);
  const partners = useMessagesStore(state => state.partners);
  const teams = useMessagesStore(state => state.teams);
  const setMessage = useMessagesStore(state => state.setMessage);
  const serverErrors = useMessagesStore(state => state.serverErrors);
  const setServerErrors = useMessagesStore(state => state.setServerErrors);
  const [recipientsOpen, setRecipientsOpen] = useState(false);
  const [portOptions, setPortOptions] = useState([]);
  const messagesRef = useRef(null);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const portId = useMessagesStore(state => state.portId);
  const setPortId = useMessagesStore(state => state.setPortId);
  const openSelectTemplateModal = useMessagesStore(
    state => state.openSelectTemplateModal
  );
  const [filters, setFilters] = useState([]);
  const portSelectorContainer = useRef(null);

  const [selectedMessage, setSelectedMessage] = useState();
  const { t } = useTranslation();

  const portsQuery = usePorts({
    churchId: selectedChurch?.id
  });

  const sendMessagesQuery = useSendMessages({
    churchId: selectedChurch?.id,
    filters: filters
  });

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClick = event => {
    if (recipients.size) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (message, event) => {
    setSelectedMessage(message);
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    messagesRef.current?.children[messages.length - 1]?.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    const portOptions = [];

    if (portsQuery.isSuccess) {
      for (let port of portsQuery.data.result) {
        portOptions.push({
          optionLabel: port.name,
          value: port.id
        });
      }
      setPortOptions(portOptions);
    }
  }, [portsQuery.isSuccess]);

  useEffect(() => {
    const queries = [];

    if (partners.size) {
      for (let partnerId of [...partners.keys()]) {
        queries.push(`filters[addresses->partners][id]=${partnerId}`);
      }
    }

    if (teams.size) {
      for (let teamId of [...teams.keys()]) {
        queries.push(`filters[addresses->teams][id]=${teamId}`);
      }
    }

    if (members.size) {
      for (let memberId of [...members.keys()]) {
        queries.push(`filters[addresses->members][id]=${memberId}`);
      }
    }

    if (portId) {
      queries.push(`filters[message_port_id]=${portId}`);
    }

    setFilters(queries);
  }, [members, partners, teams, portId]);

  useEffect(() => {
    if (!_.isNil(serverErrors?.message_port_id)) {
      portSelectorContainer.current.scrollIntoView({
        block: "center"
      });
    }
  }, [serverErrors]);

  return (
    <Container>
      <SelectedRecipientsModal
        recipientsOpen={recipientsOpen}
        setRecipientsOpen={setRecipientsOpen}
      />
      <Header>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            ref={portSelectorContainer}
          >
            <h3>{t("message.newMessage.SelectPort")}</h3>
            {portsQuery.data && (
              <Select
                options={portOptions}
                register={() => {}}
                value={portId}
                onChange={e => {
                  setPortId(e.target.value);

                  setServerErrors(_.omit(serverErrors, "message_port_id"));
                }}
                error={!_.isNil(serverErrors?.message_port_id)}
                helperText={
                  !_.isNil(serverErrors?.message_port_id)
                    ? "Please select a port"
                    : ""
                }
              />
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => setRecipientsOpen(true)}
            sx={{ cursor: "pointer" }}
          >
            <h3>{t("message.newMessage.To")}: </h3>
            {!!recipients.size && (
              <AvatarGroup
                max={5}
                sx={{
                  "& .MuiAvatarGroup-avatar": {
                    width: 24,
                    height: 24,
                    fontSize: 12
                  }
                }}
              >
                {[...recipients.values()].map(selectedItem => (
                  <Avatar
                    key={selectedItem.id}
                    src={`${IMAGE_URL}/${selectedItem.image_url}`}
                    alt={
                      selectedItem.first_name
                        ? selectedItem.first_name
                        : selectedItem.name
                    }
                    sx={{
                      width: 24,
                      height: 24
                    }}
                  />
                ))}
              </AvatarGroup>
            )}
          </Stack>
        </Stack>
      </Header>
      <Messages ref={messagesRef}>
        {_.isEmpty(recipients) ? (
          <EmptyState />
        ) : (
          sendMessagesQuery?.data?.result?.map(message => (
            <Message key={message.id}>
              <Avatar
                alt={message.first_name}
                src={`${IMAGE_URL}/${message.image_url}`}
              />
              <Stack alignItems="center">
                {message?.is_scheduled === 1 && (
                  <Typography variant="caption">
                    Scheduled for{" "}
                    {moment
                      .utc(message?.date_time)
                      .tz("Africa/Addis_Ababa")
                      .format("MMMM DD, h:mm A")}
                  </Typography>
                )}
                <MessageBubble>
                  {message?.is_scheduled === 1 && (
                    <IconButton
                      aria-label="settings"
                      sx={{ position: "absolute", top: 0, right: 2 }}
                      color="primary"
                      onClick={e => handleClick2(message, e)}
                    >
                      <MoreVert />
                    </IconButton>
                  )}

                  <p>{message?.message}</p>
                  <Time>{moment(message?.created_at).format("h:mm A")}</Time>
                </MessageBubble>
              </Stack>
            </Message>
          ))
        )}
      </Messages>
      <Compose>
        <MessageInput
          placeholder={t("message.newMessage.TypeMessage")}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <IconButton color="primary" onClick={handleClick}>
          <Send />
        </IconButton>
        {/* <IconButton color="primary">
          <AttachFile />
        </IconButton> */}
        <IconButton color="primary" onClick={openSelectTemplateModal}>
          <Inbox />
        </IconButton>
      </Compose>
      <Popover
        anchorEl={anchorEl}
        id="send-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            borderRadius: "10px",
            backgroundColor: "white"
          }
        }}
      >
        <SendOptions />
      </Popover>
      <Popover
        anchorEl={anchorEl2}
        id="scheduled-menu"
        open={open2}
        onClose={handleClose2}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <ScheduledDropdownMenu message={selectedMessage} />
      </Popover>
    </Container>
  );
};

export const ScheduledDropdownMenu = ({ message }) => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const resendMessageMutation = useResendMessage();
  const editMessageMutation = useEditMessage();
  const openEditMessageModal = useMessagesStore(
    state => state.openEditMessageModal
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      sx={{
        backgroundColor: "white",
        px: "17px",
        py: "11px"
      }}
      spacing={1}
      alignItems="flex-start"
    >
      <EditMessageModal
        initialMessage={message?.message}
        isLoading={editMessageMutation.isLoading}
        onUpdate={editedMessage => {
          editMessageMutation.mutate({
            churchId: selectedChurch?.id,
            messageId: message?.id,
            messageData: {
              message: editedMessage.message
            }
          });
        }}
      />
      <Popover
        anchorEl={anchorEl}
        id="send-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            borderRadius: "10px",
            backgroundColor: "white"
          }
        }}
      >
        <RescheduleOptions message={message} />
      </Popover>
      <LinkAction onClick={openEditMessageModal}>Edit</LinkAction>
      <LinkAction onClick={handleClick}>Reschedule</LinkAction>
      <LinkAction
        onClick={() => {
          resendMessageMutation.mutate({
            churchId: selectedChurch?.id,
            messageId: message?.id
          });
        }}
      >
        {t("message.newMessage.SendNow")}
      </LinkAction>
    </Stack>
  );
};
