import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { LoadingScreen } from "components/LoadingScreen";
import { Button, Notification } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { MemberDataManipulator } from "features/members";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteEmployee, useEmployee, useUpdateEmployee } from "../api";
import { AddressForm } from "../components/Forms/AddressForm";
import { BankForm } from "../components/Forms/BankForm";
import { CompensationForm } from "../components/Forms/CompensationForm";
import { EmergencyContactForm } from "../components/Forms/EmergencyContactForm";
import { JobForm } from "../components/Forms/JobForm";
import { PersonalInfoForm } from "../components/Forms/PersonalInfoForm";
import { employeeSchema } from "../validation";

export const HRProfile = () => {
  const [activeNav, setActiveNav] = useState("personal");
  const [employee, setEmployee] = useState(null);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { id } = useParams();
  const navigate = useNavigate();

  const updateEmployeeMutation = useUpdateEmployee();
  const deleteEmployeeMutation = useDeleteEmployee();

  const { data, isLoading } = useEmployee({
    churchId: selectedChurch?.id,
    employeeId: id
  });

  useEffect(() => {
    if (data) {
      setEmployee(data.result);
    }
  }, [data]);

  const {
    register,
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      employee_id: "",
      title: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      date_of_birth: "",
      nationality_id: "",
      marital_status: "",
      employee_photo: null,
      phone_number: "",
      email: "",
      address: "",
      position: "",
      employment_date: "",
      employment_type: "",
      salary_bank_name: "",
      salary_bank_account_number: "",
      pf_bank_name: "",
      pf_bank_account_number: "",
      social_id_number: "",
      tin_number: "",
      gross_salary: "",
      overtime: "",
      transport_allowance: "",
      house_allowance: "",
      communication_allowance: "",
      bonus: "",
      tax: "",
      credit: "",
      pension: "",
      provident_fund: "",
      penalty: "",
      emergency_contact_title: "",
      emergency_contact_first_name: "",
      emergency_contact_last_name: "",
      emergency_contact_middle_name: "",
      relationship_to_employee: "",
      emergency_contact_phone: "",
      emergency_contact_address: ""
    },
    mode: "onBlur",
    resolver: yupResolver(employeeSchema)
  });

  useEffect(() => {
    if (employee) {
      reset({
        employee_id: employee?.employee_id,
        title: employee?.title,
        first_name: employee?.first_name,
        middle_name: employee?.middle_name,
        last_name: employee?.last_name,
        gender: employee?.gender,
        date_of_birth: employee?.date_of_birth,
        nationality_id: employee?.nationality_id,
        marital_status: employee?.marital_status,
        employee_photo: null,
        phone_number: employee?.phone_number,
        email: employee?.email,
        address: employee?.address,
        position: employee?.position,
        employment_date: employee?.employment_date,
        employment_type: employee?.employment_type,
        salary_bank_name: employee?.salary_bank_name,
        salary_bank_account_number: employee?.salary_bank_account_number,
        pf_bank_name: employee?.pf_bank_name,
        pf_bank_account_number: employee?.pf_bank_account_number,
        social_id_number: employee?.social_id_number,
        tin_number: employee?.tin_number,
        gross_salary: employee?.gross_salary,
        overtime: employee?.overtime,
        transport_allowance: employee?.transport_allowance,
        house_allowance: employee?.house_allowance,
        communication_allowance: employee?.communication_allowance,
        bonus: employee?.bonus,
        tax: employee?.tax,
        credit: employee?.credit,
        pension: employee?.pension,
        provident_fund: employee?.provident_fund,
        penalty: employee?.penalty,
        emergency_contact_title: employee?.emergency_contact_title,
        emergency_contact_first_name: employee?.emergency_contact_first_name,
        emergency_contact_last_name: employee?.emergency_contact_last_name,
        emergency_contact_middle_name: employee?.emergency_contact_middle_name,
        relationship_to_employee: employee?.relationship_to_employee,
        emergency_contact_phone: employee?.emergency_contact_phone,
        emergency_contact_address: employee?.emergency_contact_address
      });
    }
  }, [employee]);

  const onSubmit = values => {
    const cleanEmployeeData = {
      ...values
    };

    const employeeDataManipulator = new MemberDataManipulator();

    employeeDataManipulator.removeEmptyValues(cleanEmployeeData);

    employeeDataManipulator.convertValuesToDates(cleanEmployeeData, [
      "date_of_birth",
      "employment_date"
    ]);

    const employeeFormData =
      employeeDataManipulator.convertObjectToFormData(cleanEmployeeData);

    if (values.employee_photo) {
      employeeFormData.append("employee_photo", values.employee_photo);
    }

    updateEmployeeMutation.mutate({
      churchId: selectedChurch?.id,
      employeeId: employee?.id,
      employeeData: employeeFormData
    });
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Stack py={4} spacing={4}>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "rgba(248, 248, 248, 1)"
        }}
      >
        <Avatar
          variant="square"
          src={`${IMAGE_URL}/${employee?.employee_photo}`}
          alt={employee?.first_name}
          sx={{
            height: "auto",
            width: 214
          }}
        />
        <Stack p={2} flex={1} spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight={700}>
              {employee?.first_name} {employee?.middle_name}{" "}
              {employee?.last_name}
            </Typography>
            <Button
              style={{ backgroundColor: "#FC5758", borderColor: "#FC5758" }}
              onClick={() =>
                deleteEmployeeMutation.mutate(
                  {
                    churchId: selectedChurch?.id,
                    employeeId: id
                  },
                  {
                    onSuccess: () => {
                      navigate(`/hr`);
                    }
                  }
                )
              }
            >
              Delete
            </Button>
          </Stack>
          <Typography variant="subtitle1" color="primary.main">
            {employee?.position}
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridTemplateRows="1fr"
          >
            <Stack spacing={0.5}>
              <Typography>Employee ID: {employee?.employee_id}</Typography>
              <Typography>Phone number: {employee?.phone_number}</Typography>
              <Typography>Email: {employee?.email}</Typography>
            </Stack>
            <Stack spacing={0.5}>
              <Typography>Date of Birth: {employee?.date_of_birth}</Typography>
              <Typography>
                Marital Status: {employee?.marital_status}
              </Typography>
              <Typography>
                Nationality: {employee?.nationality?.nationality}
              </Typography>
            </Stack>
          </Box>
          <Stack alignItems="flex-start">
            <Button flavor="outlined">Send Message</Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row">
        <Stack
          pr={4}
          sx={{ borderRight: "1px solid rgba(226, 232, 240, 1)" }}
          spacing={1}
        >
          <Typography
            sx={{
              bgcolor:
                activeNav === "personal" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("personal")}
          >
            Personal information
          </Typography>
          <Typography
            sx={{
              bgcolor: activeNav === "address" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("address")}
          >
            Address
          </Typography>
          <Typography
            sx={{
              bgcolor: activeNav === "job" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("job")}
          >
            Job
          </Typography>
          <Typography
            sx={{
              bgcolor: activeNav === "bank" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("bank")}
          >
            Bank and TIN
          </Typography>
          <Typography
            sx={{
              bgcolor:
                activeNav === "compensation" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("compensation")}
          >
            Compensations
          </Typography>
          <Typography
            sx={{
              bgcolor:
                activeNav === "emergency" ? "neutral.main" : "transparent",
              p: 2,
              cursor: "pointer"
            }}
            onClick={() => setActiveNav("emergency")}
          >
            Emergency contact
          </Typography>
        </Stack>
        <Stack pl={2} flexBasis="60%">
          {activeNav === "personal" && (
            <PersonalInfoForm
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
            />
          )}
          {activeNav === "address" && (
            <AddressForm
              register={register}
              errors={errors}
              control={control}
            />
          )}
          {activeNav === "job" && (
            <JobForm register={register} errors={errors} />
          )}
          {activeNav === "bank" && (
            <BankForm register={register} errors={errors} />
          )}
          {activeNav === "compensation" && (
            <CompensationForm register={register} errors={errors} />
          )}
          {activeNav === "emergency" && (
            <EmergencyContactForm
              register={register}
              errors={errors}
              control={control}
            />
          )}

          <Stack alignItems="flex-end" mt={3}>
            <Button onClick={handleSubmit(onSubmit)}>Update changes</Button>
          </Stack>
        </Stack>
      </Stack>
      <Notification />
    </Stack>
  );
};
