import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
// import { useRevenueStore } from "../store";

export const createRevenue = ({ churchId, revenueData }) => {
  return axios.post(`/churches/${churchId}/revenue`, revenueData);
};

export const useCreateRevenue = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  // const closeRecordRevenueModal = useRevenueStore(
  //   state => state.closeRecordRevenueModal
  // );

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("revenues");

      setNotification({
        type: "success",
        message: "Revenue created successfully!"
      });

      // closeRecordRevenueModal();
    },
    ...config,
    mutationFn: createRevenue
  });
};
