import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getSettlements = ({ churchId, expenseId }) => {
  return axios.get(`/churches/${churchId}/expenses/${expenseId}/settlements`);
};

export const useSettlements = ({ churchId, expenseId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["settlements", churchId, expenseId],
    queryFn: () => getSettlements({ churchId, expenseId })
  });
};
