import styled from "@emotion/styled";
import { Box } from "@mui/material";
import {
  ChatCircleDots,
  MicrosoftTeamsLogo,
  // Money,
  UserCircle,
  UsersFour
} from "@phosphor-icons/react";
import SMSSentPattern from "assets/images/sms-sent.png";
import SystemUserPattern from "assets/images/system-user.png";
// import TotalBalancePattern from "assets/images/total-balance.png";
import TotalMembersPattern from "assets/images/total-members.png";
import TotalTeamsPattern from "assets/images/total-teams.png";
import { Alert, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import { useGeneralStats } from "../api";
import {
  MembersView,
  MessagesView,
  StatInfoCard,
  SystemAdminsView,
  TeamsView
} from "../components";

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 32px;
`;

export function Dashboard() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const [dashboardView, setDashboardView] = useState("members");
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  useTitle("Dashboard");

  const generalStatsQuery = useGeneralStats({
    churchId: selectedChurch?.id
  });

  useEffect(() => {
    setHeaderTitle("Dashboard");

    return () => setHeaderTitle("");
  }, []);

  return (
    <Box p={4}>
      <Notification />
      <Box mb={4}>
        <Alert message={"dashboard.common.demoMessage"} />
      </Box>
      <StatGrid>
        <StatInfoCard
          title={t("dashboard.dashboard.TotalMembers")}
          stat={generalStatsQuery?.data?.total_active_members ?? 0}
          icon={<UsersFour size={24} />}
          color="#134077"
          background={`url(${TotalMembersPattern}) #13407766`}
          onClick={() => setDashboardView("members")}
          active={dashboardView === "members"}
        />
        <StatInfoCard
          title={t("dashboard.dashboard.TotalTeams")}
          stat={generalStatsQuery.data?.total_teams ?? 0}
          icon={<MicrosoftTeamsLogo size={24} />}
          color="#65A6A0"
          background={`url(${TotalTeamsPattern}) #65A6A066`}
          onClick={() => setDashboardView("teams")}
          active={dashboardView === "teams"}
        />
        <StatInfoCard
          title={t("dashboard.dashboard.TotalSentMessages")}
          stat={generalStatsQuery?.data?.total_messagesSent ?? 0}
          icon={<ChatCircleDots size={24} />}
          color="#018ABE"
          background={`url(${SMSSentPattern}) #018ABE66`}
          onClick={() => setDashboardView("messages")}
          active={dashboardView === "messages"}
        />
        {/* <StatInfoCard
          title={t("dashboard.dashboard.TotalBalance")}
          stat="0"
          icon={<Money size={24} />}
          color="#7FA457"
          background={`url(${TotalBalancePattern}) #7FA45766`}
        /> */}
        <StatInfoCard
          title={t("dashboard.dashboard.SystemUsers")}
          stat={generalStatsQuery?.data?.total_users ?? 0}
          icon={<UserCircle size={24} />}
          color="#E39337"
          background={`url(${SystemUserPattern}) #E3933766`}
          onClick={() => setDashboardView("systemAdmins")}
          active={dashboardView === "systemAdmins"}
        />
      </StatGrid>
      {dashboardView === "members" && <MembersView />}
      {dashboardView === "teams" && <TeamsView />}
      {dashboardView === "messages" && <MessagesView />}
      {dashboardView === "systemAdmins" && <SystemAdminsView />}
    </Box>
  );
}
