import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const resendMessage = ({ churchId, messageId }) => {
  return axios.get(`/churches/${churchId}/send-message/${messageId}`);
};

export const useResendMessage = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("messages");
      queryClient.invalidateQueries("send-messages");
      setNotification({
        type: "success",
        message: "Message sent successfully"
      });
    },
    ...config,
    mutationFn: resendMessage
  });
};
