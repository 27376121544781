import { PhotoCamera } from "@mui/icons-material";
import { Avatar, Badge, Box, Stack, Typography } from "@mui/material";
import SidebarPhoto from "assets/images/Photo.png";
import { IMAGE_URL } from "config";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useDenominationStore } from "../store";

export function DenominationProfileSidebar() {
  const selectedDenomination = useDenominationStore(
    state => state.selectedDenomination
  );
  const { t } = useTranslation();

  const auth = useAuthUser();
  const userId = auth()?.id;
  const denominationCreatorId = selectedDenomination?.created_by?.id;
  const isCreatedByUser = userId === denominationCreatorId;

  return (
    <Box
      sx={{
        backgroundImage: `url(${SidebarPhoto})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        px: 2,
        py: 8,
        display: "flex",
        flexDirection: "column",
        color: "white",
        height: "100%"
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Stack direction="row" justifyContent="center">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={
              isCreatedByUser ? (
                <Avatar
                  color="neutral"
                  aria-label="upload picture"
                  component="label"
                  sx={{ color: "primary.main", backgroundColor: "white" }}
                >
                  <input hidden accept="image/*" type="file" />
                  <PhotoCamera />
                </Avatar>
              ) : null
            }
          >
            <Avatar
              alt={selectedDenomination?.name}
              src={`${IMAGE_URL}/${selectedDenomination?.image_url}`}
              sx={{
                width: 160,
                height: 160,
                outline: "6px solid white"
              }}
            />
          </Badge>
        </Stack>
        <Typography variant="h6" textAlign="center">
          {selectedDenomination?.name}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {t("denominations.profile.DenominationAccount")}
        </Typography>
        <Typography variant="caption">
          {t("denominations.profile.CreatedDate")}{" "}
          {moment(selectedDenomination?.created_at).format("DD/MM/YYYY")}
        </Typography>
      </Stack>
    </Box>
  );
}
