import axios from "axios";
import { API_URL } from "config";
import printJS from "print-js";
import { useCentralStore } from "stores";
import { getToken } from "utils/getToken";
import { QueryBuilder } from "../utils/queryBuilder";

export const printProfile = ({ type, churchId, id, format, orientation }) => {
  const queryBuilder = new QueryBuilder();

  queryBuilder.append("format", format);
  queryBuilder.append("orientation", orientation);

  const queryString = queryBuilder.build();

  let url = "";
  if (type === "member") {
    url = `${API_URL}/churches/${churchId}/members/${id}/export${queryString}`;
  } else if (type === "kid") {
    url = `${API_URL}/churches/${churchId}/children/${id}/export${queryString}`;
  } else if (type === "partner") {
    url = `${API_URL}/churches/${churchId}/partners/${id}/export${queryString}`;
  }

  axios({
    method: "get",
    url: url,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  })
    .then(response => {
      printJS(URL.createObjectURL(new Blob([response.data])));
    })
    .catch(() => {
      const setNotification = useCentralStore.getState().setNotification;
      setNotification({ type: "error", message: "Unable to print data" });
    });
};
