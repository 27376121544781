import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const StatCard = styled.div`
  min-height: 185px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  color: white;
  overflow: hidden;
  cursor: ${({ clickable }) => clickable && "pointer"};
  box-shadow: ${({ color, active }) =>
    active ? `0px 10px 20px 0px ${color}` : "none"};

  h1,
  h3 {
    margin: 0;
    padding: 0;
  }
`;

const IconCircle = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: ${({ color }) => color};
  display: grid;
  place-items: center;
  color: white;
  align-self: flex-end;
`;

const StatCardTop = styled.div`
  background: ${({ background }) => background};
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 16px;
`;

const StatCardBottom = styled.div`
  background: ${({ color }) => color};
  padding: 16px;
`;

export const StatInfoCard = ({
  icon,
  title,
  subtitle,
  stat,
  color,
  background,
  onClick,
  active,
  clickable = true
}) => (
  <StatCard
    onClick={onClick}
    color={color}
    active={active}
    clickable={clickable}
  >
    <StatCardTop background={background}>
      <IconCircle color={color}>{icon}</IconCircle>
      <h1>{stat}</h1>
    </StatCardTop>
    <StatCardBottom color={color}>
      <h3>{title}</h3>
      {subtitle && <Typography variant="caption">{subtitle}</Typography>}
    </StatCardBottom>
  </StatCard>
);
