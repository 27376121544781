import styled from "@emotion/styled";
import { DeleteOutline, Search } from "@mui/icons-material";
import { Avatar, InputAdornment, Stack, Typography } from "@mui/material";
import { Button, Checkbox, DeleteModal, TextField } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useDeleteMessages, useMessages } from "../api";

const Container = styled.div`
  width: 100%;
  padding: 51px 37px 51px 44px;
  background: white;
  grid-column: 2 / span 2;
  overflow: hidden;
`;

const Title = styled.h3``;

const Subtitle = styled.h5`
  color: #929292;
  font-weight: 300;
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 700px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding-bottom: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
    font-size: 14px;
  }

  & td {
    text-align: center;
    padding: 10px;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background: #f3f3f3;
    border-radius: 5px;
  }
`;

const DeleteButton = styled(Button)`
  color: red;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const CancelButton = styled.button`
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #000000;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

// const statusMapping = {
//   sent: "secondary",
//   delivered: "success",
//   draft: "warning",
//   failed: "error",
//   scheduled: "warning"
// };

export const AllMessagesPanel = () => {
  const navigate = useNavigate();
  const [messagesToDelete, setMessagesToDelete] = useState(new Map());
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const messagesQuery = useMessages({
    churchId: selectedChurch?.id,
    filters: ["group_by[address]=1", "sort[created_at]=DESC"]
  });
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const closeDeleteModal = useCentralStore(state => state.closeDeleteModal);
  const messageDeleteMutation = useDeleteMessages();
  const { t } = useTranslation();

  let allMessagesView;

  if (messagesQuery.data) {
    allMessagesView = messagesQuery.data.result.map(message => (
      <tr
        key={message.id}
        onClick={() => navigate(`/messages/all/${message.id}`)}
      >
        <td>
          <Checkbox
            name=""
            buttons={[
              {
                checkboxLabel: "",
                value: true,
                id: "remember",
                checked: messagesToDelete.has(message.id),
                onChange: e => {
                  const selectedMessages = new Map(messagesToDelete);

                  if (e.target.checked) {
                    selectedMessages.set(message.id, message);
                  } else {
                    selectedMessages.delete(message.id);
                  }

                  setMessagesToDelete(selectedMessages);
                },
                onClick: e => {
                  e.stopPropagation();
                }
              }
            ]}
            error=""
            helperText=""
            register={() => {}}
            containerStyle={{ alignItems: "center" }}
          />
        </td>
        <td>
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar
              alt={
                message?.meta_data[message?.meta_data?.address_type]?.first_name
              }
              src={`${IMAGE_URL}/${
                message?.meta_data[message?.meta_data?.address_type]?.image_url
              }`}
              sx={{ width: 48, height: 48 }}
            />
            <Stack>
              <Typography variant="subtitle2" textAlign="left">
                {
                  message?.meta_data[message?.meta_data?.address_type]
                    ?.first_name
                }{" "}
                {
                  message?.meta_data[message?.meta_data?.address_type]
                    ?.last_name
                }
              </Typography>
              <Typography
                sx={{ maxWidth: "150px" }}
                variant="caption"
                textAlign="left"
                noWrap
              >
                {message?.message}
              </Typography>
            </Stack>
          </Stack>
        </td>
        <td>
          <Typography variant="caption">
            {t("messages.common.TextMessage")}
          </Typography>
        </td>
        <td>
          <Typography variant="caption">
            {message?.message_port?.name}
          </Typography>
        </td>
        <td>
          <Typography variant="caption">{message?.message_type}</Typography>
        </td>
        <td>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            {/* <Chip
              label={
                <Typography variant="caption">
                  {message?.message_type}
                </Typography>
              }
              color={statusMapping[message?.status?.toLowerCase()]}
            /> */}
            <Typography variant="caption">
              {moment(message?.created_at).format("D/M/YY, h:mm A")}
            </Typography>
          </Stack>
        </td>
      </tr>
    ));
  }

  return (
    <Container>
      <DeleteModal
        title={t("message.allMessage.DeleteMessage")}
        onDelete={() => {
          messageDeleteMutation.mutate({
            churchId: selectedChurch.id,
            messageIds: [...messagesToDelete.keys()]
          });
          closeDeleteModal();

          const selectedMessages = new Map(messagesToDelete);
          selectedMessages.clear();
          setMessagesToDelete(selectedMessages);
        }}
        name={t("messages.common.SelectedMessages")}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Title>{t("message.allMessage.AllMessages")}</Title>

        <Stack direction="row" spacing={2}>
          {!!messagesToDelete.size && (
            <CancelButton
              flavor="neutral"
              onClick={() => {
                const selectedMessages = new Map(messagesToDelete);
                selectedMessages.clear();
                setMessagesToDelete(selectedMessages);
              }}
            >
              <DeleteOutline />
              <span>{t("systemAdmins.createNewRole.Cancel")}</span>
            </CancelButton>
          )}
          <DeleteButton
            flavor="neutral"
            disabled={!messagesToDelete.size}
            onClick={openDeleteModal}
          >
            <DeleteOutline />
            <span>{t("message.allMessage.DeleteMessage")}</span>
          </DeleteButton>
        </Stack>
      </Stack>
      <TextField
        neutral={1}
        placeholder={t("message.allMessage.SearchMessages")}
        register={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
      <Subtitle>{t("message.allMessage.RecentMessages")}</Subtitle>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>{t("message.allMessage.Conversations")}</th>
              <th>{t("message.allMessage.Type")}</th>
              <th>{t("message.allMessage.Port")}</th>
              <th>{t("message.allMessage.Status")}</th>
              <th>{t("message.allMessage.Date")}</th>
            </tr>
          </thead>
          <tbody>{allMessagesView}</tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
