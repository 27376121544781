import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useDenominationStore } from "../store";

export const deleteDenomination = ({ denominationId }) => {
  return axios.delete(`/denominations/${denominationId}`);
};

export const useDeleteDenomination = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const selectDenomination = useDenominationStore.getState().selectDenomination;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["denominations"]);
      setNotification({
        type: "success",
        message: "Denomination deleted successfully!"
      });
      selectDenomination(null);
      closeDeleteModal();
      navigate("/denominations");
    },
    ...config,
    mutationFn: deleteDenomination
  });
};
