import { Box } from "@mui/material";
import SidebarPhoto from "assets/images/Photo.png";

export function Banner({ children }) {
  return (
    <Box
      sx={{
        height: "260px",
        backgroundImage: `url(${SidebarPhoto})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "5px 0 0 5px",
        display: "flex",
        alignItems: "center",
        color: "white",
        mr: -4,
        position: "relative"
      }}
    >
      {children}
    </Box>
  );
}
