import { Visibility } from "@mui/icons-material";
import { Avatar, Chip, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMembersPaginated } from "../api";
import { FILTER_OPERATIONS, MEMBER_STATES } from "../constants";
import { useMembersStore } from "../stores";
import { extraMemberColumns } from "../utils/memberColumns";

const statusColors = {
  pending: "success",
  viewed: "warning",
  reject: "error"
};

export const ApplicationRequestsTable = ({ isFilterMode = false }) => {
  const setMemberPreviewData = useMembersStore(
    state => state.setMemberPreviewData
  );
  const setFullMemberData = useMembersStore(state => state.setFullMemberData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const timer = useRef();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const queries = useMembersStore(state => state.queries);
  const setQueries = useMembersStore(state => state.setQueries);
  const columnsVisible = useMembersStore(state => state.columnsVisible);

  const membersPaginatedQuery = useMembersPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize,
    queries: queries
  });

  const columns = [
    {
      field: "first_name",
      headerName: t("members.membersTable.name"),
      flex: 2,
      minWidth: 120,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row.profile_image_url}`}
              alt={_.capitalize(row.first_name)}
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>
              {row.first_name} {row.father_name} {row.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "gender",
      headerName: t("members.membersTable.gender"),
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return (
          <Typography variant="body2" textTransform="capitalize">
            {row.gender}
          </Typography>
        );
      }
    },
    {
      field: "phone",
      headerName: t("members.membersTable.phone"),
      flex: 1,
      minWidth: 120
    },
    {
      field: "email",
      headerName: t("members.membersTable.email"),
      flex: 1,
      minWidth: 120
    },
    {
      field: "status",
      headerName: t("message.inbox.Status"),
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return (
          <Chip
            label={<Typography variant="caption">{row.status}</Typography>}
            color={statusColors[row.status]}
          />
        );
      }
    },
    {
      field: "created_at",
      headerName: t("members.applicationTable.RequestDate"),
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return <span>{moment(row.created_at).format("DD-MM-YYYY")}</span>;
      }
    },
    {
      field: "operation",
      headerName: t("members.membersTable.operation"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setFullMemberData(row);
                navigate(`/members/${row.id}`);
              }}
            >
              <Visibility />
            </IconButton>
          </Stack>
        );
      },
      flex: 1,
      minWidth: 120
    },
    ...extraMemberColumns
  ];

  const handleSortModelChange = async sortModel => {
    const field = sortModel[0]?.field;
    const sort = sortModel[0]?.sort;

    if (field || sort) {
      setQueries({
        ...queries,
        sort: {
          [field]: sort
        }
      });
    } else {
      setQueries({
        filter: {
          status: [
            {
              operator: FILTER_OPERATIONS.NOT_EQUAL,
              value: MEMBER_STATES.ACTIVE
            },
            {
              operator: FILTER_OPERATIONS.NOT_EQUAL,
              value: MEMBER_STATES.INACTIVE
            }
          ]
        }
      });
    }
  };

  return (
    <>
      <DataGrid
        autoHeight
        rows={membersPaginatedQuery?.data?.result?.data || []}
        rowCount={membersPaginatedQuery?.data?.result?.total || 0}
        loading={membersPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection={isFilterMode}
        onRowClick={({ row }, event) => {
          clearTimeout(timer.current);

          if (event.detail === 1) {
            timer.current = setTimeout(() => setMemberPreviewData(row), 500);
          }

          if (event.detail === 2) {
            setFullMemberData(row);
            navigate(`/members/${row.id}`);
          }
        }}
        paginationMode="server"
        sortingMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage + 1)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
        columnVisibilityModel={columnsVisible}
      />
    </>
  );
};
