import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useExpenseStore = create(
  devtools(
    set => ({
      recordExpenseModalOpen: false,
      updateExpenseModalOpen: false,
      createExpenseTypeOpen: false,
      updateExpenseTypeOpen: false,
      createRequisitionOpen: false,
      rejectionReasonOpen: false,
      createAdvanceSettlementOpen: false,
      updateAdvanceSettlementOpen: false,
      makePaymentOpen: false,
      requisitionFlowModalOpen: false,
      openRecordExpenseModal: () => set({ recordExpenseModalOpen: true }),
      closeRecordExpenseModal: () => set({ recordExpenseModalOpen: false }),
      openCreateExpenseTypeModal: () => set({ createExpenseTypeOpen: true }),
      closeCreateExpenseTypeModal: () => set({ createExpenseTypeOpen: false }),
      openUpdateExpenseTypeModal: () => set({ updateExpenseTypeOpen: true }),
      closeUpdateExpenseTypeModal: () => set({ updateExpenseTypeOpen: false }),
      openCreateRequisitionModal: () => set({ createRequisitionOpen: true }),
      closeCreateRequisitionModal: () => set({ createRequisitionOpen: false }),
      openRejectionReasonModal: () => set({ rejectionReasonOpen: true }),
      closeRejectionReasonModal: () => set({ rejectionReasonOpen: false }),
      openCreateAdvanceSettlementModal: () =>
        set({ createAdvanceSettlementOpen: true }),
      closeCreateAdvanceSettlementModal: () =>
        set({ createAdvanceSettlementOpen: false }),
      openUpdateExpenseModal: () => set({ updateExpenseModalOpen: true }),
      closeUpdateExpenseModal: () => set({ updateExpenseModalOpen: false }),
      openUpdateAdvanceSettlementModal: () =>
        set({ updateAdvanceSettlementOpen: true }),
      closeUpdateAdvanceSettlementModal: () =>
        set({ updateAdvanceSettlementOpen: false }),
      openMakePaymentModal: () => set({ makePaymentOpen: true }),
      closeMakePaymentModal: () => set({ makePaymentOpen: false }),
      openRequisitionFlowModal: () => set({ requisitionFlowModalOpen: true }),
      closeRequisitionFlowModal: () => set({ requisitionFlowModalOpen: false })
    }),
    {
      name: "expense-store"
    }
  )
);
