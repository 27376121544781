import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { BackgroundInputField, Notification, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import i18next from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useCreateRole, usePermissions } from "../api";
import { PermissionBlock } from "../components";
import { roleSchema } from "../validation";

const Container = styled.div`
  min-height: 100vh;
  margin-block: 29px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
`;

const RoleCard = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 35px 27px;
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  grid-column: span 2;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleBar = styled.div`
  background: #d1d5db;
  border-radius: 4px;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  padding: 0 60px;

  h4 {
    margin: 0;
    padding: 0;
  }

  & > * {
    flex: 1;
  }
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  all: unset;
  background: transparent;
  border-radius: 4px;
  padding: 10px 20px;
  color: black;
  cursor: pointer;
  font-weight: 600;
`;

const CreateButton = styled.button`
  all: unset;
  background: ${({ theme, disabled }) =>
    disabled ? theme.palette.neutral.main : theme.palette.primary.main};
  border-radius: 4px;
  padding: 10px 55px;
  color: ${({ disabled }) => (disabled ? "lightgrey" : "white")};
  cursor: pointer;
  font-weight: 600;
`;

export function CreateNewRole() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const createRoleMutation = useCreateRole();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [permissionBlocks, setPermissionBlocks] = useState([]);

  const { data } = usePermissions({
    name: ""
  });

  useEffect(() => {
    if (data) {
      const response = data?.result;

      const blocks = _(response)
        .groupBy("category")
        .map((permissions, block) => {
          return {
            name: block,
            permissions
          };
        })
        .value();

      setPermissionBlocks(blocks);
    }
  }, [data]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      role_name: "",
      role_description: ""
    },
    resolver: yupResolver(roleSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("systemAdmins.createNewRole.RoleManagement"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  const onSubmit = values => {
    const data = {
      name: values.role_name,
      description: values.role_description,
      permissions: selectedPermissions
    };

    createRoleMutation.mutate({
      churchId: selectedChurch?.id,
      roleData: data
    });
  };

  return (
    <Container>
      <Notification />
      <TopSection>
        <RoleCard>
          <TextField
            label={t("systemAdmins.createNewRole.RoleName")}
            name="role_name"
            error={!!errors.role_name?.message}
            helperText={errors.role_name?.message}
            register={register}
            neutral={1}
          />
          <Stack>
            <Typography variant="subtitle2" component="label">
              {t("systemAdmins.createNewRole.RoleDescription")}
            </Typography>
            <Controller
              name="role_description"
              control={control}
              render={({ field }) => (
                <BackgroundInputField
                  id="description"
                  size="small"
                  autoComplete="off"
                  multiline
                  minRows={4}
                  fullWidth
                  inputProps={{
                    maxLength: 100
                  }}
                  {...field}
                  error={!!errors.role_description?.message}
                  helperText={`${t(
                    "systemAdmins.createNewRole.CharacterLimit"
                  )} - ${field.value.length}/100`}
                  neutral={1}
                />
              )}
            />
          </Stack>
        </RoleCard>
      </TopSection>
      <BottomSection>
        <TitleBar>
          <h4>{t("systemAdmins.createNewRole.PermissionType")}</h4>
          <h4>{t("systemAdmins.createNewRole.Action")}</h4>
        </TitleBar>
        {permissionBlocks.map(permissionBlock => (
          <PermissionBlock
            key={permissionBlock.name}
            name={permissionBlock.name}
            permissions={permissionBlock.permissions}
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          />
        ))}
      </BottomSection>
      <Operations>
        <CancelButton
          disabled={createRoleMutation.isLoading}
          onClick={() => navigate(-1)}
        >
          {t("systemAdmins.createNewRole.Cancel")}
        </CancelButton>
        <CreateButton
          disabled={
            selectedPermissions?.length < 1 || createRoleMutation.isLoading
          }
          onClick={handleSubmit(onSubmit)}
        >
          {t("systemAdmins.createNewRole.CreateNewRole")}
        </CreateButton>
      </Operations>
    </Container>
  );
}
