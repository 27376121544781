import { Chat, Edit, TouchApp } from "@mui/icons-material";
import {
  Avatar,
  Chip,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TeamMembersTableDropdownMenu } from "./TeamMembersTableDropdownMenu";

export const PotentialMembersTable = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: t("teams.teamProfile.Name"),
      minWidth: 280,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={row.image}
              alt="Image"
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>{row.name}</Typography>
          </Stack>
        );
      }
    },
    {
      field: "gender",
      headerName: t("teams.teamProfile.Gender"),
      flex: 1
    },
    {
      field: "role",
      headerName: t("teams.teamProfile.Role"),
      flex: 1,
      renderCell: ({ value }) => {
        return <Chip label={value} color="primary" />;
      }
    },
    {
      field: "phone",
      headerName: t("teams.teamProfile.Phone"),
      minWidth: 150,
      flex: 1
    },
    {
      field: "email",
      headerName: t("teams.teamProfile.Email"),
      flex: 2
    },
    {
      field: "operation",
      headerName: t("teams.teamProfile.Operation"),
      sortable: false,
      renderCell: () => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton>
              <Chat />
            </IconButton>
            <IconButton onClick={handleClick}>
              <Edit />
            </IconButton>
            <IconButton>
              <TouchApp />
            </IconButton>
          </Stack>
        );
      },
      flex: 2
    }
  ];

  return (
    <>
      <DataGrid
        autoHeight
        rows={data}
        columns={columns}
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <TeamMembersTableDropdownMenu />
      </Popover>
    </>
  );
};
