import { MuiOtpInput } from "mui-one-time-password-input";

export const OtpInput = ({ value, onChange }) => {
  return (
    <MuiOtpInput
      TextFieldsProps={{
        autoComplete: "off"
      }}
      value={value}
      onChange={onChange}
      sx={{
        width: "256px",
        mb: 8,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: `primary.main`
          },
          "&:hover fieldset": {
            borderColor: `primary.main`
          },
          "&.Mui-focused fieldset": {
            borderColor: `primary.main`
          }
        }
      }}
    />
  );
};
