import { create } from "zustand";
import { devtools } from "zustand/middleware";

const createAuthSlice = set => ({
  authToken: "",
  authUser: null,
  setAuthToken: token => set({ authToken: token }),
  setAuthUser: user => set({ authUser: user })
});

const createHeaderSlice = set => ({
  headerTitle: "",
  headerHasBackground: true,
  setHeaderTitle: pageTitle => set({ headerTitle: pageTitle }),
  setHeaderBackground: hasBackground =>
    set({ headerHasBackground: hasBackground })
});

const createNotificationSlice = set => ({
  notificationVisible: false,
  notificationType: "info",
  notificationMessage: "",
  notificationTitle: "",
  setNotification: ({ type = "info", message, title = "" }) =>
    set({
      notificationVisible: true,
      notificationType: type,
      notificationMessage: message,
      notificationTitle: title
    }),
  dismissNotification: () =>
    set({
      notificationVisible: false,
      notificationType: "info",
      notificationMessage: "",
      notificationTitle: ""
    })
});

const createDrawerSlice = set => ({
  drawerOpen: false,
  openDrawer: () =>
    set({
      drawerOpen: true
    }),
  closeDrawer: () =>
    set({
      drawerOpen: false
    })
});

const createDeleteModalSlice = set => ({
  deleteModalOpen: false,
  openDeleteModal: () => set({ deleteModalOpen: true }),
  closeDeleteModal: () => set({ deleteModalOpen: false })
});

export const useCentralStore = create(
  devtools(
    (...a) => ({
      ...createAuthSlice(...a),
      ...createHeaderSlice(...a),
      ...createNotificationSlice(...a),
      ...createDrawerSlice(...a),
      ...createDeleteModalSlice(...a)
    }),
    {
      name: "central-store"
    }
  )
);
