import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getNegaritCampaigns = ({ API_KEY }) => {
  return axios.get(
    `https://api.negarit.net/api/api_request/campaigns?API_KEY=${API_KEY}`
  );
};

export const useNegaritCampaigns = ({ API_KEY, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["negarit-campaigns"],
    queryFn: () => getNegaritCampaigns({ API_KEY })
  });
};
