import styled from "@emotion/styled";
import { Loading } from "components/UI";

const Container = styled.div`
  min-height: 100vh;
  display: grid;
  place-items: center;
`;

export const LoadingScreen = () => {
  return (
    <Container>
      <Loading />
    </Container>
  );
};
