import { ChatPanel, MessageControls } from "../components";

export const NewMessage = () => {
  return (
    <>
      <MessageControls />
      <ChatPanel />
    </>
  );
};
