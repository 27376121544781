import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/Phone";
import { Avatar, Stack, Typography } from "@mui/material";
import IDBack from "assets/images/id-back.png";
import IDFront from "assets/images/id-front.png";
import { Button } from "components/UI";
import { IMAGE_URL } from "config";
import convert from "convert-length";
import { useChurchStore } from "features/churches";
import { saveAs } from "file-saver";
import { toBlob } from "html-to-image";
import moment from "moment";
import { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { getEthiopianDate } from "utils/getEthiopianDate";
import { useMember } from "../api";
import { useMembersStore } from "../stores";

export const IDCreation = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const setFullMemberData = useMembersStore(state => state.setFullMemberData);

  const { id } = useParams();

  useMember({
    churchId: selectedChurch?.id,
    memberId: id,
    config: {
      onSuccess: response => {
        setFullMemberData(response.result);
      }
    }
  });

  return (
    <Stack minHeight="100vh" alignItems="center" sx={{ px: 6, pt: 6, pb: 4 }}>
      <Stack spacing={4}>
        <IDCardFront />
        <IDCardBack />
      </Stack>
    </Stack>
  );
};

const IDCardFront = () => {
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const frontCardRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const generateImage = () => {
    setIsLoading(true);

    toBlob(frontCardRef.current, {
      canvasWidth: convert(8.573, "cm", "px", { pixelsPerInch: 300 }),
      canvasHeight: convert(5.398, "cm", "px", { pixelsPerInch: 300 }),
      cacheBust: true
    })
      .then(blob => {
        saveAs(blob, `Front ID Card - ${fullMemberData?.id}.png`);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Stack spacing={3}>
      <Stack
        sx={{
          backgroundImage: `url(${
            selectedChurch.id_card_front_template
              ? `${IMAGE_URL}/${selectedChurch.id_card_front_template}`
              : IDFront
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          minWidth: "650px",
          maxWidth: "650px",
          minHeight: "350px",
          maxHeight: "350px",
          borderRadius: "8px",
          position: "relative",
          boxShadow: "0 0 15px 0 rgba(0,0,0,0.5)",
          p: 3
        }}
        spacing={3}
        ref={frontCardRef}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            variant="rounded"
            sx={{
              width: "70px",
              height: "70px"
            }}
            src={`${IMAGE_URL}/${selectedChurch?.image_url}`}
            alt={fullMemberData?.first_name}
          />
          <Stack
            alignItems="center"
            flex={1}
            justifySelf="center"
            spacing={0.5}
          >
            {selectedChurch?.denomination && (
              <Typography fontWeight={900}>
                {selectedChurch?.denomination?.name}
              </Typography>
            )}
            <Typography fontWeight={900}>{selectedChurch?.name}</Typography>
            <Stack direction="row" alignItems="baseline" spacing={1}>
              {selectedChurch?.phone && (
                <Stack direction="row" spacing={0.5}>
                  <PhoneIcon fontSize="8px" color="primary" />
                  <Typography variant="caption" fontSize="10px">
                    {selectedChurch?.phone}
                  </Typography>
                </Stack>
              )}
              {selectedChurch?.email && (
                <Stack direction="row" spacing={0.5}>
                  <EmailIcon fontSize="8px" color="primary" />
                  <Typography variant="caption" fontSize="10px">
                    {selectedChurch?.email}
                  </Typography>
                </Stack>
              )}
              {selectedChurch?.website && (
                <Stack direction="row" spacing={0.5}>
                  <LanguageIcon fontSize="8px" color="primary" />
                  <Typography variant="caption" fontSize="10px">
                    {selectedChurch?.website}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Typography variant="caption" color="primary.main">
              የአባልነት መታወቂያ
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
          spacing={3}
        >
          <Stack spacing={3}>
            <TextLine
              title="Name"
              titleAm="ስም"
              data={`${fullMemberData?.first_name} ${fullMemberData?.father_name} ${fullMemberData?.last_name}`}
            />
            <Stack direction="row" spacing={4}>
              <Stack spacing={3}>
                <TextLine
                  title="Gender"
                  titleAm="ጾታ"
                  data={fullMemberData?.gender}
                />
                <TextLine
                  title="Membership Date"
                  titleAm="የአባልነት ቀን"
                  data={
                    fullMemberData?.membership_date
                      ? `${getEthiopianDate(
                          fullMemberData?.membership_date
                        )} EC`
                      : ""
                  }
                />
              </Stack>
              <Stack spacing={3}>
                <TextLine
                  title="Birth Date"
                  titleAm="የልደት ቀን"
                  data={
                    fullMemberData?.date_of_birth
                      ? `${getEthiopianDate(fullMemberData?.date_of_birth)} EC`
                      : ""
                  }
                />
                <TextLine
                  title="ID Number"
                  titleAm="መታወቂያ ቁጥር"
                  data={fullMemberData?.id}
                />
              </Stack>
            </Stack>
          </Stack>
          <Avatar
            variant="rounded"
            sx={{
              width: "140px",
              height: "140px"
            }}
            src={`${IMAGE_URL}/${fullMemberData?.profile_image_url}`}
            alt={fullMemberData?.first_name}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <QRCode
            value={`${fullMemberData?.first_name} ${fullMemberData?.father_name} is a member of ${selectedChurch?.name}`}
            size={50}
            style={{ border: "4px solid white" }}
          />
        </Stack>
      </Stack>
      <Button
        style={{ alignSelf: "center" }}
        onClick={generateImage}
        disabled={isLoading}
      >
        Download
      </Button>
    </Stack>
  );
};

const IDCardBack = () => {
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const backCardRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const generateImage = () => {
    setIsLoading(true);

    toBlob(backCardRef.current, {
      canvasWidth: convert(8.573, "cm", "px", { pixelsPerInch: 300 }),
      canvasHeight: convert(5.398, "cm", "px", { pixelsPerInch: 300 })
    })
      .then(blob => {
        saveAs(blob, `Back ID Card - ${fullMemberData?.id}.png`);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Stack spacing={3}>
      <Stack
        sx={{
          backgroundImage: `url(${
            selectedChurch.id_card_back_template
              ? `${IMAGE_URL}/${selectedChurch.id_card_back_template}`
              : IDBack
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          minWidth: "650px",
          maxWidth: "650px",
          minHeight: "350px",
          maxHeight: "350px",
          borderRadius: "8px",
          position: "relative",
          boxShadow: "0 0 15px 0 rgba(0,0,0,0.5)",
          p: 3
        }}
        ref={backCardRef}
        spacing={3}
      >
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <TextLine
            title="Country"
            titleAm="ሀገር"
            data={fullMemberData?.current_country?.name}
          />
          <TextLine
            title="Region"
            titleAm="ክልል"
            data={fullMemberData?.current_region?.name}
          />
          <TextLine
            title="Zone"
            titleAm="ዞን"
            data={fullMemberData?.current_zone?.name}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <TextLine
            title="City"
            titleAm="ከተማ"
            data={fullMemberData?.current_city?.name}
          />
          <TextLine
            title="Subcity"
            titleAm="ክፍለ ከተማ"
            data={fullMemberData?.current_sub_city?.name}
          />
          <TextLine
            title="Woreda"
            titleAm="ወረዳ"
            data={fullMemberData?.current_woreda?.name}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <TextLine
            title="Kebele"
            titleAm="ቀበሌ"
            data={fullMemberData?.current_kebele}
          />
          <TextLine
            title="House Number"
            titleAm="የቤት ቁጥር"
            data={fullMemberData?.house_number}
          />
          <TextLine
            title="Date Of Issue"
            titleAm="የተሰጠበት ቀን"
            data={`${getEthiopianDate(moment().format())} EC`}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={3}>
          <TextLine
            title="Phone Number"
            titleAm="ስልክ ቁጥር"
            data={fullMemberData?.phone}
          />
        </Stack>
        <Stack alignItems="center" spacing={1}>
          <Typography variant="caption" fontSize="10px" textAlign="center">
            የዚህ መታወቂያ ባለቤት የቤተክርስትያናችን አባል መሆናቸውን እያረጋገጥን ይህም መታወቂያ <br />
            የሚያገለግለው ከተሰጠበት ቀን ጀምሮ ለአምስት አመታት ብቻ ነው። መሸኛሲወስዱ መታወቂያው ይመልሱ።
          </Typography>
          <Typography variant="caption" fontSize="10px" textAlign="center">
            The owner of this ID is certified by members our church,
            <br /> and this ID is valid only for five years from the date of
            issue.
          </Typography>
        </Stack>
      </Stack>
      <Button
        style={{ alignSelf: "center" }}
        onClick={generateImage}
        disabled={isLoading}
      >
        Download
      </Button>
    </Stack>
  );
};

const TextLine = ({ title, titleAm, data }) => {
  return (
    <Stack
      alignItems="baseline"
      direction="row"
      minWidth="150px"
      height="24px"
      spacing={1}
    >
      <Stack>
        <Typography variant="caption" whiteSpace="nowrap">
          {titleAm}
        </Typography>
        <Typography variant="caption" whiteSpace="nowrap">
          {title}
        </Typography>
      </Stack>
      <Typography
        variant="body2"
        flex={1}
        sx={{
          borderBottom: "1px solid black",
          textTransform: "capitalize"
        }}
        whiteSpace="nowrap"
      >
        {data}
      </Typography>
    </Stack>
  );
};
