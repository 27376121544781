import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

export const BankForm = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="salary_bank_name">
            {t("HR.BankandTin.Salary Bank Name")}
          </Label>
          <Input id="salary_bank_name" {...register("salary_bank_name")} />
          <ErrorMessage
            errors={errors}
            name="salary_bank_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="salary_bank_account_number">
            {t("HR.BankandTin.SalaryBankAccount")}
          </Label>
          <Input
            id="salary_bank_account_number"
            {...register("salary_bank_account_number")}
          />
          <ErrorMessage
            errors={errors}
            name="salary_bank_account_number"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="pf_bank_name">{t("HR.BankandTin.PFBankName")}</Label>
          <Input id="pf_bank_name" {...register("pf_bank_name")} />
          <ErrorMessage
            errors={errors}
            name="pf_bank_name"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
        <Field>
          <Label htmlFor="pf_bank_account_number">
            {t("HR.BankandTin.PFBankAccount")}
          </Label>
          <Input
            id="pf_bank_account_number"
            {...register("pf_bank_account_number")}
          />
          <ErrorMessage
            errors={errors}
            name="pf_bank_account_number"
            render={({ message }) => <HelperText error>{message}</HelperText>}
          />
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="social_id_number">
          {t("HR.BankandTin.SocialIdentificationNumber")}
        </Label>
        <Input id="social_id_number" {...register("social_id_number")} />
        <ErrorMessage
          errors={errors}
          name="social_id_number"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="tin_number">
          {t("HR.BankandTin.TaxIdentificationNumber")}
        </Label>
        <Input id="tin_number" {...register("tin_number")} />
        <ErrorMessage
          errors={errors}
          name="tin_number"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
    </Stack>
  );
};
