import ReactApexChart from "react-apexcharts";

export const LineChart = ({
  labels,
  data,
  colors = ["#FBD8B0", "#74DFD5"]
}) => {
  return (
    <ReactApexChart
      options={{
        chart: {
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
          strokeDashArray: 4
        },
        xaxis: {
          categories: labels,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false,
            formatter: value => value.toFixed(0)
          }
        },
        colors: colors,
        legend: {
          show: false
        }
      }}
      series={data}
      type="line"
      height={350}
    />
  );
};
