import { Stack, Typography } from "@mui/material";
import { PencilLine } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

export function UsersTableDropdownMenu({ userId }) {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        minWidth: "200px",
        backgroundColor: "white",
        px: 1,
        py: 2,
        gap: 1
      }}
    >
      {/* <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          p: 1,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "neutral.main"
          }
        }}
      >
        <ChatCircleDots size={24} color="#00B0B9" />
        <Typography variant="subtitle2">Send Message</Typography>
      </Stack> */}
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          p: 1,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "neutral.main"
          }
        }}
        onClick={() => {
          navigate(`/users/${userId}/update`);
        }}
      >
        <PencilLine size={24} color="#00B0B9" />
        <Typography variant="subtitle2">Edit Profile</Typography>
      </Stack>
    </Stack>
  );
}
