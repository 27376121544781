import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { XCircle } from "@phosphor-icons/react";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useCreateSettlement, useExpenseStore } from "features/finances";
import { useFileUpload } from "hooks";
import { has } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const PhotoPreview = styled.div`
  width: 158px;
  height: 192px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f1f5f9;
  padding: 16px;
`;

const Photo = styled.div`
  position: relative;
`;

const PhotoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CloseIcon = styled(XCircle)`
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
`;

const settlementSchema = yup.object({
  reason: yup.string(),
  amount: yup.number()
});

export function CreateAdvanceSettlementModal() {
  const createAdvanceSettlementOpen = useExpenseStore(
    state => state.createAdvanceSettlementOpen
  );
  const closeCreateAdvanceSettlementModal = useExpenseStore(
    state => state.closeCreateAdvanceSettlementModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const createSettlementMutation = useCreateSettlement();
  const [selectedFile, openFileDialog, resetFile] = useFileUpload();
  const { id } = useParams();
  const [photoPreview, setPhotoPreview] = useState("");

  useEffect(() => {
    if (selectedFile) {
      setPhotoPreview(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      reason: "",
      amount: 0
    },
    resolver: yupResolver(settlementSchema)
  });

  const convertObjectToFormData = object => {
    const formData = new FormData();

    for (const key in object) {
      if (has(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };

  const onSubmit = values => {
    const settlementData = {
      reason: values.reason,
      amount: values.amount
    };

    if (selectedFile) {
      settlementData["receipt_photo"] = selectedFile;
    }

    const settlementFormData = convertObjectToFormData(settlementData);

    createSettlementMutation.mutate(
      {
        churchId: selectedChurch?.id,
        expenseId: id,
        settlementData: settlementFormData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Expense report"
      isOpen={createAdvanceSettlementOpen}
      onClose={closeCreateAdvanceSettlementModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeCreateAdvanceSettlementModal}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="reason">Reason</Label>
        <Input id="reason" {...register("reason")} />
      </Field>
      <Field>
        <Label htmlFor="amount">Amount</Label>
        <Input id="amount" type="number" {...register("amount")} />
      </Field>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Label htmlFor="uploadPhoto">Receipt Photo</Label>
        <Button id="uploadPhoto" flavor="outlined" onClick={openFileDialog}>
          Upload photo
        </Button>
      </Stack>
      {photoPreview && (
        <PhotoPreview>
          <Photo>
            <PhotoImage src={photoPreview} alt="photo preview" />
            <CloseIcon
              size={16}
              weight="fill"
              color="#F87171"
              onClick={() => {
                resetFile();
                setPhotoPreview("");
              }}
            />
          </Photo>
        </PhotoPreview>
      )}
    </BaseModal>
  );
}
