import styled from "@emotion/styled";
import { Box, Modal, Stack } from "@mui/material";
import { BsXSquare } from "react-icons/bs";

const CloseIcon = styled(BsXSquare)`
  color: #ffffff;
  height: 22px;
  width: 22px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
`;

export function BaseModal({
  children,
  isOpen,
  onClose,
  title,
  footer,
  width,
  minHeight
}) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: `${width ? width : "450px"}`,
          overflow: "hidden"
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          <span>{title}</span>
          <CloseIcon onClick={onClose} />
        </Stack>
        <Stack
          sx={{
            backgroundColor: "white",
            minHeight: `${minHeight ? minHeight : "400px"}`,
            maxHeight: "700px",
            overflowY: "auto"
          }}
          p={2}
          spacing={4}
        >
          <Stack flex={1} spacing={1}>
            {children}
          </Stack>
          {footer}
        </Stack>
      </Box>
    </Modal>
  );
}
