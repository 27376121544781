import styled from "@emotion/styled";
import { CaretDown } from "@phosphor-icons/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useExpenseStore, useRevenueStore } from "../store";

const Container = styled.div`
  background-color: #fafafa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-area: sidemenu;
`;

const ItemWrapper = styled.div``;

const Item = styled.div`
  padding: 8px;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "#fafafa"};
  color: ${({ active }) => (active ? "#fff" : "#000")};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
`;

const Subitem = styled.div`
  padding: 8px 16px;
  color: #000;
  cursor: pointer;
  font-size: 12px;
`;

export const Menu = () => {
  const openRecordRevenueModal = useRevenueStore(
    state => state.openRecordRevenueModal
  );
  const openRecordExpenseModal = useExpenseStore(
    state => state.openRecordExpenseModal
  );

  const items = [
    {
      name: "Overview",
      translation: "Overview",
      url: "/finances/temp/overview"
    },
    {
      name: "Revenue",
      translation: "Revenue",
      url: "/finances/temp/revenue",
      subItems: [
        {
          name: "Record Revenue",
          translation: "Record Revenue",
          onClick: () => openRecordRevenueModal()
        },
        {
          name: "Revenue Setting",
          translation: "Revenue Setting",
          onClick: () => navigate("/finances/temp/revenue/settings")
        },
        {
          name: "Cheque Reconcile",
          translation: "Cheque Reconcile",
          onClick: () => navigate("/finances/temp/revenue/cheque-reconcile")
        }
      ]
    },
    {
      name: "Expense",
      translation: "Expense",
      url: "/finances/temp/expense",
      subItems: [
        {
          name: "Record Expense",
          translation: "Record Expense",
          onClick: () => openRecordExpenseModal()
        },
        {
          name: "Expense Setting",
          translation: "Expense Setting",
          onClick: () => navigate("/finances/temp/expense/settings")
        },
        {
          name: "Requisition",
          translation: "Requisition",
          onClick: () => navigate("/finances/temp/expense/requisition")
        }
      ]
    },
    {
      name: "Payroll",
      translation: "Payroll",
      url: "/finances/temp/payroll",
      subItems: []
    },
    { name: "Budget", translation: "Budget", url: "/finances/temp/budget" },
    { name: "Report", translation: "Report", url: "/finances/temp/report" },
    { name: "Bank", translation: "Bank", url: "/finances/temp/bank" },
    { name: "Setting", translation: "Setting", url: "/finances/temp/setting" }
  ];
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Container>
      {items.map(item => (
        <ItemWrapper key={item.name}>
          <Item
            active={pathname.includes(item.url)}
            onClick={() => navigate(item.url)}
          >
            <span>{item.translation}</span>
            {item.subItems && <CaretDown size={12} />}
          </Item>
          {pathname.includes(item.url) &&
            item.subItems?.map(subitem => (
              <Subitem key={subitem.name} onClick={subitem.onClick}>
                {subitem.translation}
              </Subitem>
            ))}
        </ItemWrapper>
      ))}
    </Container>
  );
};
