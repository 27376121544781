import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useChartOfAccounts,
  useCreateRevenue,
  useRevenueMethods,
  useRevenueStore,
  useRevenueTypes
} from "features/finances";
import { useMembers } from "features/members";
import { useFileUpload } from "hooks";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { convertObjectToFormData } from "utils/convertObjectToFormData";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const SmallInformationText = styled.span`
  color: #3186ea;
  font-size: 8px;
  font-weight: 500;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  flex: 1;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
  flex: 1;
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  margin-right: 16px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

const PreviewThumbnail = styled.img`
  height: 80px;
  weight: 80px;
`;

const revenueSchema = yup.object({
  name: yup.string(),
  revenueType: yup.string(),
  account: yup.string(),
  description: yup.string(),
  method: yup.number(),
  receiptNumber: yup.string(),
  chequeNumber: yup.string(),
  accountHolderName: yup.string(),
  accountNumber: yup.string(),
  phoneNumber: yup.string(),
  referenceNumber: yup.string(),
  bankName: yup.string(),
  amount: yup.number(),
  currency: yup.string(),
  receivedDate: yup.string(),
  cashReceivedDate: yup.string(),
  dueDate: yup.string()
});

export function RecordRevenueModal() {
  const recordRevenueModalOpen = useRevenueStore(
    state => state.recordRevenueModalOpen
  );
  const closeRecordRevenueModal = useRevenueStore(
    state => state.closeRecordRevenueModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const revenueTypesQuery = useRevenueTypes({
    churchId: selectedChurch?.id
  });
  const revenueMethodsQuery = useRevenueMethods({
    churchId: selectedChurch?.id
  });
  const chartOfAccountsQuery = useChartOfAccounts({
    churchId: selectedChurch?.id
  });
  const membersQuery = useMembers({
    churchId: selectedChurch?.id,
    queries: {}
  });
  const createRevenueMutation = useCreateRevenue();
  const [isMember, setIsMember] = useState(false);
  const [selectedFile, openFileDialog, resetFile] = useFileUpload();

  const { register, watch, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      name: "",
      revenueType: "",
      account: "",
      description: "",
      method: "",
      receiptNumber: "",
      chequeNumber: "",
      accountHolderName: "",
      accountNumber: "",
      phoneNumber: "",
      referenceNumber: "",
      bankName: "",
      amount: "",
      currency: "",
      receivedDate: "",
      cashReceivedDate: "",
      dueDate: ""
    },
    resolver: yupResolver(revenueSchema)
  });

  const method = watch("method");

  const onSubmitAndNew = values => {
    const revenueData = {
      chart_of_account_id: values.account ?? "",
      amount: values.amount ?? "",
      description: values.description ?? "",
      receipt_number: values.receiptNumber ?? "",
      cheque_number: values.chequeNumber ?? "",
      account_holder_name: values.accountHolderName ?? "",
      account_number: values.accountNumber ?? "",
      phone_number: values.phoneNumber ?? "",
      received_date: values.receivedDate ?? "",
      cheque_due_date: values.dueDate ?? "",
      cash_received_date: values.cashReceivedDate ?? "",
      transaction_number: values.referenceNumber ?? "",
      reference_number: values.referenceNumber ?? "",
      bank_name: values.bankName ?? "",
      revenue_type_id: values.revenueType ?? "",
      revenue_method_id: values.method ?? "",
      church_id: selectedChurch?.id,
      member_id: isMember ? values.name : "",
      full_name: !isMember ? values.name : ""
    };

    if (selectedFile) {
      revenueData["bank_slip_attachment"] = selectedFile;
    }

    const revenueFormData = convertObjectToFormData(revenueData);

    createRevenueMutation.mutate(
      {
        churchId: selectedChurch?.id,
        revenueData: revenueFormData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  const onSubmitAndExit = values => {
    const revenueData = {
      chart_of_account_id: values.account ?? "",
      amount: values.amount ?? "",
      description: values.description ?? "",
      receipt_number: values.receiptNumber ?? "",
      cheque_number: values.chequeNumber ?? "",
      account_holder_name: values.accountHolderName ?? "",
      account_number: values.accountNumber ?? "",
      phone_number: values.phoneNumber ?? "",
      received_date: values.receivedDate ?? "",
      cheque_due_date: values.dueDate ?? "",
      cash_received_date: values.cashReceivedDate ?? "",
      transaction_number: values.referenceNumber ?? "",
      reference_number: values.referenceNumber ?? "",
      bank_name: values.bankName ?? "",
      revenue_type_id: values.revenueType ?? "",
      revenue_method_id: values.method ?? "",
      church_id: selectedChurch?.id,
      member_id: isMember ? values.name : "",
      full_name: !isMember ? values.name : ""
    };

    if (selectedFile) {
      revenueData["bank_slip_attachment"] = selectedFile;
    }

    const revenueFormData = convertObjectToFormData(revenueData);

    createRevenueMutation.mutate(
      {
        churchId: selectedChurch?.id,
        revenueData: revenueFormData
      },
      {
        onSuccess: () => {
          closeRecordRevenueModal();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Record Revenue"
      isOpen={recordRevenueModalOpen}
      onClose={closeRecordRevenueModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button size="small" onClick={handleSubmit(onSubmitAndNew)}>
            Save and New
          </Button>
          <Button size="small">Save and Print</Button>
          <Button size="small" onClick={handleSubmit(onSubmitAndExit)}>
            Save and Exit
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="fullName">Full Name</Label>
        <Stack direction="row" alignItems="center">
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={e => {
                setIsMember(e.target.checked);
                setValue("name", "");
              }}
            />
            Is Member
          </CheckboxLabel>
          {isMember && (
            <Select id="fullName" {...register("name")}>
              {membersQuery.data?.result?.map(member => (
                <option key={member.id} value={member.id}>
                  {member.first_name} {member.father_name} {member.last_name}
                </option>
              ))}
            </Select>
          )}
          {!isMember && <Input id="fullName" {...register("name")} />}
        </Stack>
      </Field>
      <Field>
        <Label htmlFor="revenueType">Revenue type or reason</Label>
        <Select id="revenueType" {...register("revenueType")}>
          {revenueTypesQuery.data?.result?.map(revenueType => (
            <option key={revenueType.id} value={revenueType.id}>
              {revenueType.name}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="account">Account</Label>
        <Select id="account" {...register("account")}>
          {chartOfAccountsQuery.data?.result?.map(chartOfAccount => (
            <option key={chartOfAccount.id} value={chartOfAccount.id}>
              {chartOfAccount.account_name}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="description">Description</Label>
        <Input id="description" {...register("description")} />
      </Field>
      <Field>
        <Label htmlFor="method">Method</Label>
        <Select id="method" {...register("method")}>
          {revenueMethodsQuery.data?.result?.map(revenueMethod => (
            <option key={revenueMethod.id} value={revenueMethod.id}>
              {revenueMethod.name}
            </option>
          ))}
        </Select>
      </Field>
      {method === "2" && (
        <SmallInformationText>
          Check Selected: After Saving Please Reconcile and Approve under check
          reconcile menu. Recorded Revenue will not be added until approved.
        </SmallInformationText>
      )}
      {method === "1" && (
        <Field>
          <Label htmlFor="receiptNumber">Receipt Number</Label>
          <Input id="receiptNumber" {...register("receiptNumber")} />
        </Field>
      )}
      {method === "2" && (
        <>
          <Field>
            <Label htmlFor="chequeNumber">Cheque Number</Label>
            <Input id="chequeNumber" {...register("chequeNumber")} />
          </Field>
          <Field>
            <Label htmlFor="bankName">Bank</Label>
            <Input id="bankName" {...register("bankName")} />
          </Field>
          <Field>
            <Label htmlFor="accountHolderName">Account Holder Full Name</Label>
            <Input id="accountHolderName" {...register("accountHolderName")} />
          </Field>
          <Field>
            <Label htmlFor="accountNumber">Account Number</Label>
            <Input id="accountNumber" {...register("accountNumber")} />
          </Field>
          <Field>
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Input id="phoneNumber" {...register("phoneNumber")} />
          </Field>
        </>
      )}
      {method === "3" && (
        <>
          <Field>
            <Label htmlFor="bankName">Bank Name</Label>
            <Input id="bankName" {...register("bankName")} />
          </Field>
          <Field>
            <Label htmlFor="referenceNumber">Reference Number</Label>
            <Input id="referenceNumber" {...register("referenceNumber")} />
          </Field>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Label htmlFor="uploadPhoto">Bank slip attachment</Label>
            {!selectedFile && (
              <Button
                id="uploadPhoto"
                flavor="outlined"
                onClick={openFileDialog}
              >
                Upload photo
              </Button>
            )}
            {selectedFile && (
              <PreviewThumbnail
                src={URL.createObjectURL(selectedFile)}
                alt="Preview"
              />
            )}
            {selectedFile && (
              <IconButton onClick={resetFile}>
                <Delete />
              </IconButton>
            )}
          </Stack>
        </>
      )}
      {method === "4" && (
        <>
          <Field>
            <Label htmlFor="bankName">Bank Name</Label>
            <Input id="bankName" {...register("bankName")} />
          </Field>
          <Field>
            <Label htmlFor="referenceNumber">Reference Number</Label>
            <Input id="referenceNumber" {...register("referenceNumber")} />
          </Field>
        </>
      )}
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="amount">Amount</Label>
          <Input id="amount" type="number" {...register("amount")} />
        </Field>
        <Field>
          <Label htmlFor="currency">Currency</Label>
          <Select id="currency" {...register("currency")}>
            <option>ETB</option>
          </Select>
        </Field>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="receivedDate">Received date</Label>
          <Input id="receivedDate" type="date" {...register("receivedDate")} />
        </Field>
      </Stack>
      {method === "2" && (
        <Stack direction="row" spacing={2}>
          <Field>
            <Label htmlFor="dueDate">Due date</Label>
            <Input id="dueDate" type="date" {...register("dueDate")} />
          </Field>
          <Field>
            <Label htmlFor="cashReceivedDate">Cash Received date</Label>
            <Input
              id="cashReceivedDate"
              type="date"
              {...register("cashReceivedDate")}
            />
          </Field>
        </Stack>
      )}
    </BaseModal>
  );
}
