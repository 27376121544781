import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useCreateApprover, useFinanceSettingsStore } from "features/finances";
import { useUsers } from "features/users";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const approverSchema = yup.object({
  approver: yup.string(),
  approverLevel: yup.string(),
  approvalLimitPerRequest: yup.number(),
  approvalLimitPerMonth: yup.number()
});

export function CreateApproverModal() {
  const createApproverOpen = useFinanceSettingsStore(
    state => state.createApproverOpen
  );
  const closeCreateApproverModal = useFinanceSettingsStore(
    state => state.closeCreateApproverModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const usersQuery = useUsers({
    churchId: selectedChurch?.id
  });
  const createApproverMutation = useCreateApprover();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      approver: "",
      approverLevel: "",
      approvalLimitPerRequest: 0,
      approvalLimitPerMonth: 0
    },
    resolver: yupResolver(approverSchema)
  });

  const onSubmit = values => {
    const approverData = {
      approver_id: values.approver,
      approval_limit_per_request: values.approvalLimitPerRequest,
      approval_limit_per_month: values.approvalLimitPerMonth,
      approver_level: values.approverLevel
    };

    createApproverMutation.mutate(
      {
        churchId: selectedChurch?.id,
        approverData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Create Approver"
      isOpen={createApproverOpen}
      onClose={closeCreateApproverModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeCreateApproverModal}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Create Approver
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="name">Approver Name</Label>
        <Select id="name" {...register("approver")}>
          {usersQuery.data?.result?.map(user => (
            <option key={user.id} value={user.id}>
              {user.first_name} {user.last_name}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="approverLevel">Approval Level</Label>
        <Select id="approverLevel" {...register("approverLevel")}>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
        </Select>
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerRequest">
          Maximum Approve per request in ETB
        </Label>
        <Input
          id="approvalLimitPerRequest"
          type="number"
          {...register("approvalLimitPerRequest")}
        />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerMonth">
          Maximum Approve per month in ETB
        </Label>
        <Input
          id="approvalLimitPerMonth"
          type="number"
          {...register("approvalLimitPerMonth")}
        />
      </Field>
    </BaseModal>
  );
}
