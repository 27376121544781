import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button } from "components/UI";

const Container = styled.div`
  border-radius: 8px;
  background: #e6f7f8;
  display: grid;
  place-items: center;
  padding: 34px 80px;
`;

const Icon = styled.div`
  color: #000;
`;

export const ActionCard = ({ icon, name, onAction }) => {
  return (
    <Container>
      <Stack alignItems="center" gap={3}>
        <Icon>{icon}</Icon>
        <Button onClick={onAction}>{name}</Button>
      </Stack>
    </Container>
  );
};
