import { BudgetTable, SetBudgetModal } from "../components";

export const Budget = () => {
  return (
    <>
      <SetBudgetModal />
      <BudgetTable />
    </>
  );
};
