import { useState } from "react";
import {
  RecordRevenueModal,
  RevenueTable,
  UpdateRevenueModal
} from "../components";

export const Revenue = () => {
  const [revenueRecord, setRevenueRecord] = useState(null);

  return (
    <>
      <RecordRevenueModal />
      <UpdateRevenueModal data={revenueRecord} />
      <RevenueTable onEdit={record => setRevenueRecord(record)} />
    </>
  );
};
