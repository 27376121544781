import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useStoredMessagesStore = create(
  devtools(
    persist(
      set => ({
        storedMessages: [],
        storeMessages: messages => set({ storedMessages: messages })
      }),
      {
        name: "messages"
      }
    ),
    {
      name: "stored-messages-store"
    }
  )
);
