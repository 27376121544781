import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useTeamsStore } from "../stores";

export const createTeamType = ({ churchId, teamTypeData }) => {
  return axios.post(`/churches/${churchId}/team-types`, teamTypeData);
};

export const useCreateTeamType = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateTeamTypeModal =
    useTeamsStore.getState().closeCreateTeamTypeModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("team_types");
      setNotification({
        type: "success",
        message: "Team type created successfully!"
      });
      closeCreateTeamTypeModal();
    },
    ...config,
    mutationFn: createTeamType
  });
};
