import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getRevenueMethods = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/revenue-methods`);
};

export const useRevenueMethods = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["revenue-methods", churchId],
    queryFn: () => getRevenueMethods({ churchId })
  });
};
