import styled from "@emotion/styled";
import { Divider, Popover, Stack, Typography } from "@mui/material";
import {
  Coins,
  DotsThreeOutlineVertical,
  PencilLine,
  TrashSimple,
  X
} from "@phosphor-icons/react";
import { Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteSettlement, useExpense, useSettlements } from "../api";
import {
  CreateAdvanceSettlementModal,
  UpdateAdvanceSettlementModal
} from "../components";
import { useExpenseStore } from "../store";

const Container = styled.div`
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f5f9;
  padding: 18px 20px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;

  th {
    padding-block: 16px;
    background: #e6f7f8;
    font-weight: 400;
  }

  & td {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
  }
`;

const Banner = styled.div`
  border-radius: 2px;
  background: #f8bc77;
  width: 391px;
  height: 83px;
  color: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const BannerTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

const Amount = styled.span`
  font-size: 12px;
`;

const Figure = styled.span`
  font-size: 32px;
  font-weight: 700;
`;

const InformationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  columns: 2;
  font-size: 12px;
  width: 50%;
`;

const Information = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    color: #778899;
    flex: 1;
  }
`;

const MoreIcon = styled(DotsThreeOutlineVertical)`
  cursor: pointer;
`;

export const ExpenseSettlement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const openCreateAdvanceSettlementModal = useExpenseStore(
    state => state.openCreateAdvanceSettlementModal
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const expenseQuery = useExpense({
    churchId: selectedChurch?.id,
    expenseId: id
  });
  const settlementsQuery = useSettlements({
    churchId: selectedChurch?.id,
    expenseId: id
  });
  const [settlement, setSettlement] = useState(null);

  const handleClick = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSettlement(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <CreateAdvanceSettlementModal />
      <UpdateAdvanceSettlementModal data={settlement} />
      <Notification />
      <Header>
        <Title>Expense Settlement</Title>
        <CloseButton onClick={() => navigate(`/finances/temp/revenue`)}>
          <span>Close</span>
          <X size={16} />
        </CloseButton>
      </Header>
      <Banner>
        <Stack flex={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <BannerTitle>Advance to clear</BannerTitle>
            <Coins
              size={32}
              style={{
                mixBlendMode: "soft-light"
              }}
            />
          </Stack>
          <Amount>
            <Figure>
              {expenseQuery.data?.result?.requisition?.amount ||
                expenseQuery.data?.result?.requisition_record?.amount}
            </Figure>{" "}
            ETB
          </Amount>
        </Stack>
      </Banner>
      <InformationList>
        <Information>
          <span>Requestor Full Name</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.user?.name ||
              expenseQuery.data?.result?.requisition_record?.user?.name}
          </strong>
        </Information>
        <Information>
          <span>Department</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.team?.name ||
              expenseQuery.data?.result?.requisition_record?.team?.name}
          </strong>
        </Information>
        <Information>
          <span>Amount</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.amount ||
              expenseQuery.data?.result?.requisition_record?.amount}{" "}
            ETB
          </strong>
        </Information>
        <Information>
          <span>Reason</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.reason ||
              expenseQuery.data?.result?.requisition_record?.reason}
          </strong>
        </Information>
        <Information>
          <span>Category</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.expense_type?.name ||
              expenseQuery.data?.result?.requisition_record?.expense_type?.name}
          </strong>
        </Information>
        <Information>
          <span>Account</span>
          <strong>
            {expenseQuery.data?.result?.requisition?.chart_of_account
              ?.account_name?.name ||
              expenseQuery.data?.result?.requisition_record?.chart_of_account
                ?.account_name?.name}
          </strong>
        </Information>
      </InformationList>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Title>Expenses</Title>
        <Button size="small" onClick={openCreateAdvanceSettlementModal}>
          Add Settlement
        </Button>
      </Stack>
      <Table>
        <thead>
          <tr>
            <th>Reason</th>
            <th>Amount</th>
            <th>Receipt Photo</th>
            <th>Date</th>
            <th>Next Approver</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {settlementsQuery.data?.result?.map(settlement => (
            <tr key={settlement.id}>
              <td>{settlement.reason}</td>
              <td>{settlement.amount}</td>
              <td>
                <Button
                  flavor="white"
                  style={{
                    fontSize: "12px"
                  }}
                >
                  View Receipt
                </Button>
              </td>
              <td>{moment(settlement.created_at).format("DD-MM-YYYY")}</td>
              <td>Tsega Abebe Lemma</td>
              <td>
                <MoreIcon
                  size={24}
                  weight="fill"
                  onClick={e => handleClick(e, settlement)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <ExpensesDropdown data={settlement} />
      </Popover>
    </Container>
  );
};

const ExpensesDropdown = ({ data }) => {
  const openUpdateAdvanceSettlementModal = useExpenseStore(
    state => state.openUpdateAdvanceSettlementModal
  );
  const deleteSettlementMutation = useDeleteSettlement();
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  console.log(data);

  const deleteSettlement = () => {
    deleteSettlementMutation.mutate({
      churchId: selectedChurch?.id,
      expenseId: data?.expense_id,
      settlementId: data?.id
    });
  };

  return (
    <Stack
      sx={{
        minWidth: "200px",
        backgroundColor: "white",
        p: 1,
        gap: 1
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          p: 1,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "neutral.main"
          }
        }}
        onClick={openUpdateAdvanceSettlementModal}
      >
        <PencilLine size={16} color="#000" />
        <Typography variant="subtitle2">Edit</Typography>
      </Stack>
      <Divider />
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          p: 1,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "neutral.main"
          }
        }}
        onClick={deleteSettlement}
      >
        <TrashSimple size={16} color="#000" />
        <Typography variant="subtitle2">Delete</Typography>
      </Stack>
    </Stack>
  );
};
