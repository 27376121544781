import styled from "@emotion/styled";
import { Grid, Stack } from "@mui/material";
import { UsersFour } from "@phosphor-icons/react";
import { useChurchStore } from "features/churches";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import {
  BsBriefcase,
  BsJournal,
  BsJournalBookmark,
  BsLightbulb
} from "react-icons/bs";
import { useMemberGrowth, useMemberStats, usePartnerGrowth } from "../api";
import { getChartLabels, getChartValues } from "../utils";
import { SectionBar } from "./SectionBar";
import { Toolbar } from "./Toolbar";
import { BarChart } from "./charts/BarChart";
import { LineChart } from "./charts/LineChart";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 36px;
`;

const SectionTitle = styled.h4`
  margin: 0;
`;

const GenderSection = styled(Section)`
  display: flex;
  background: #ffffff;
  padding: 22px 24px;
`;

const GenderGraph = styled.div`
  flex: 1;
  padding: 17px 25px;
`;

const MaritalGraph = styled.div`
  flex: 1;
  background-color: #f3f4f6;
  padding: 17px 25px;
  border-radius: 8px;
`;

const Graph = styled.div`
  margin-top: 40px;
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LegendAside = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LegendBox = styled.div`
  min-width: 200px;
  max-width: 200px;
  min-height: 70px;
  border-radius: 4px;
  padding: 18px 14px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;

  h2,
  h4 {
    margin: 0;
    padding: 0;
  }
`;

const PartnerLegendBox = styled(LegendBox)`
  min-width: 300px;
  max-width: 300px;
  min-height: 150px;
  color: ${({ color }) => color};
  background-color: ${({ background }) => background};
  padding: 18px 21px;
  flex-direction: ${({ direction }) => direction};

  h2 {
    font-size: 48px;
  }

  h4 {
    font-size: 24px;
    align-self: ${({ align }) => align};
  }
`;

const GraphFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendIndicator = styled.div`
  width: 24px;
  height: 24px;
  background: ${({ color }) => color};
`;

const PieGraphGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const AgeInfoCard = styled.div`
  background: #f9fafb;
  border-radius: 4px;
  padding-block: 15px;
  border-left: 12px solid #134077;
  min-width: 220px;
`;

const MemberInfoCard = styled.div`
  background: #f3f4f6;
  border-radius: 4px;
  padding-block: 18px;
  border-left: 21px solid ${({ color }) => color};
  min-width: 220px;
  padding-inline: 15px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  h4 {
    margin: 0;
    padding: 0;
    font-size: 32px;
  }
`;

const generatePieChart = (data = []) => {
  return {
    series: data,
    options: {
      colors: ["#74DFD5", "#FBD8B0"],
      chart: {
        type: "pie"
      },
      labels: ["Male", "Female"]
    }
  };
};

const generateRadialChart = (value, label, color = "#74DFD5") => {
  return {
    series: [value],
    options: {
      colors: [color],
      chart: {
        height: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          borderRadius: 4
        }
      },
      labels: [label]
    }
  };
};

export const MembersView = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SectionBar
        icon={<UsersFour size={24} />}
        title={t("dashboard.member.MembersData")}
        color="#134077"
      />
      <MemberGrowthStatusCard />
      <GenderMaritalStatusCard />
      <AgeClassificationCard />
      <MemberJobStatusCard />
      <PartnersGrowthStatusCard />
    </Container>
  );
};

const MemberGrowthStatusCard = () => {
  const [labels, setLabels] = useState([]);
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [resignedMembers, setResignedMembers] = useState([]);
  const [selectedQueryPeriod, setSelectedQueryPeriod] = useState(null);
  const { t } = useTranslation();

  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const memberGrowthQuery = useMemberGrowth({
    churchId: selectedChurch?.id,
    fromDate: selectedQueryPeriod?.fromDate,
    toDate: selectedQueryPeriod?.toDate,
    period: selectedQueryPeriod?.period,
    config: {
      enabled: !!selectedQueryPeriod?.toDate,
      onSuccess: response => {
        setRegisteredMembers(getChartValues(response.registered_members));
        setResignedMembers(getChartValues(response.resigned_members));
        setLabels(getChartLabels(response.resigned_members));
      }
    }
  });

  const handlePeriod = selectedPeriod => {
    setSelectedQueryPeriod(selectedPeriod);
  };

  return (
    <Section>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <SectionTitle>{t("dashboard.member.MemberGrowthStatus")}</SectionTitle>
        <Toolbar onChange={handlePeriod} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={8}>
        <Graph>
          <LineChart
            labels={labels}
            data={[
              {
                name: t("dashboard.member.MembersResigned"),
                data: resignedMembers
              },
              {
                name: t("dashboard.member.MembersAdded"),
                data: registeredMembers
              }
            ]}
          />
          <GraphFooter>
            <Legend>
              <LegendIndicator color="#FBD8B0" />
              <h5>{t("dashboard.member.MembersResigned")}</h5>
            </Legend>
            <Legend>
              <LegendIndicator color="#74DFD5" />
              <h5>{t("dashboard.member.MembersAdded")}</h5>
            </Legend>
          </GraphFooter>
        </Graph>
        <LegendAside>
          <LegendBox color="#74DFD5">
            <h4>{t("dashboard.member.MembersAdded")}</h4>
            <h2>{memberGrowthQuery.data?.total_registered_members}</h2>
          </LegendBox>
          <LegendBox color="#FBD8B0">
            <h4>{t("dashboard.member.MembersResigned")}</h4>
            <h2>{memberGrowthQuery.data?.total_resigned_members}</h2>
          </LegendBox>
        </LegendAside>
      </Stack>
    </Section>
  );
};

const GenderMaritalStatusCard = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  const memberStatsQuery = useMemberStats({
    churchId: selectedChurch?.id
  });

  return (
    <GenderSection>
      <GenderGraph>
        <SectionTitle>{t("dashboard.member.Gender")}</SectionTitle>
        <ReactApexChart
          options={generatePieChart().options}
          series={
            generatePieChart([
              memberStatsQuery.data?.gender_status?.male_count ?? 0,
              memberStatsQuery.data?.gender_status?.female_count ?? 0
            ]).series
          }
          type="pie"
        />
      </GenderGraph>
      <MaritalGraph>
        <SectionTitle>{t("dashboard.member.MaritalStatus")}</SectionTitle>
        <PieGraphGrid>
          <ReactApexChart
            options={
              generateRadialChart(56, t("dashboard.member.Single"), "#74DFD5")
                .options
            }
            series={
              generateRadialChart(
                memberStatsQuery.data?.marital_status?.single_percentage ?? 0,
                "Single",
                "#74DFD5"
              ).series
            }
            type="radialBar"
            height={200}
          />
          <ReactApexChart
            options={
              generateRadialChart(44, t("dashboard.member.Married"), "#53BA63")
                .options
            }
            series={
              generateRadialChart(
                memberStatsQuery.data?.marital_status?.married_percentage ?? 0,
                "Married",
                "#53BA63"
              ).series
            }
            type="radialBar"
            height={200}
          />
          <ReactApexChart
            options={
              generateRadialChart(56, t("dashboard.member.Divorced"), "#DF7474")
                .options
            }
            series={
              generateRadialChart(
                memberStatsQuery.data?.marital_status?.divorced_percentage ?? 0,
                "Divorced",
                "#DF7474"
              ).series
            }
            type="radialBar"
            height={200}
          />
          <ReactApexChart
            options={
              generateRadialChart(44, t("dashboard.member.Widowed"), "#F1B0FB")
                .options
            }
            series={
              generateRadialChart(
                memberStatsQuery.data?.marital_status?.widowed_percentage ?? 0,
                "Widowed",
                "#F1B0FB"
              ).series
            }
            type="radialBar"
            height={200}
          />
        </PieGraphGrid>
      </MaritalGraph>
    </GenderSection>
  );
};

const AgeClassificationCard = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [maleData, setMaleData] = useState([]);
  const [femaleData, setFemaleData] = useState([]);
  const { t } = useTranslation();

  const memberStatsQuery = useMemberStats({
    churchId: selectedChurch?.id
  });

  useEffect(() => {
    if (memberStatsQuery.data) {
      setMaleData([
        memberStatsQuery.data.age_classification.children.male_count,
        memberStatsQuery.data.age_classification.teens.male_count,
        memberStatsQuery.data.age_classification.youth.male_count,
        memberStatsQuery.data.age_classification.adults.male_count,
        memberStatsQuery.data.age_classification.elders.male_count
      ]);

      setFemaleData([
        memberStatsQuery.data.age_classification.children.female_count,
        memberStatsQuery.data.age_classification.teens.female_count,
        memberStatsQuery.data.age_classification.youth.female_count,
        memberStatsQuery.data.age_classification.adults.female_count,
        memberStatsQuery.data.age_classification.elders.female_count
      ]);
    }
  }, [memberStatsQuery.data]);

  return (
    <Section>
      <SectionTitle>{t(t("dashboard.member.AgeClassification"))}</SectionTitle>
      <Stack direction="row" alignItems="center" gap={4}>
        <Graph>
          <BarChart
            labels={["1-12", "13-19", "20-35", "36-60", "60+"]}
            data={[
              {
                name: "Female",
                data: femaleData
              },
              {
                name: "Male",
                data: maleData
              }
            ]}
          />
        </Graph>
        <LegendAside>
          <Legend>
            <LegendIndicator color="#74DFD5" />
            <h5>{t("dashboard.member.Male")}</h5>
          </Legend>
          <Legend>
            <LegendIndicator color="#FBD8B0" />
            <h5>{t("dashboard.member.Female")}</h5>
          </Legend>
        </LegendAside>
      </Stack>
      <Grid container spacing={2} sx={{ my: 3 }}>
        <Grid item xs={4}>
          <AgeInfoCard>
            <Stack px="15px">
              <Stack direction="row" justifyContent="space-between">
                <span>{t("dashboard.member.Children")}</span>
                <span>Age 1-12</span>
              </Stack>
              <Stack>
                <span>
                  <strong>
                    {memberStatsQuery.data?.age_classification?.children
                      ?.total_percentage ?? 0}
                    %
                  </strong>{" "}
                  {memberStatsQuery.data?.age_classification?.children
                    ?.total_count ?? 0}{" "}
                  {t("dashboard.member.Members")}
                </span>
              </Stack>
            </Stack>
          </AgeInfoCard>
        </Grid>
        <Grid item xs={4}>
          <AgeInfoCard>
            <Stack px="15px">
              <Stack direction="row" justifyContent="space-between">
                <span>{t("dashboard.member.Teens")}</span>
                <span>Age 13-19</span>
              </Stack>
              <Stack>
                <span>
                  <strong>
                    {memberStatsQuery.data?.age_classification?.teens
                      ?.total_percentage ?? 0}
                    %
                  </strong>{" "}
                  {memberStatsQuery.data?.age_classification?.teens
                    ?.total_count ?? 0}{" "}
                  {t("dashboard.member.Members")}
                </span>
              </Stack>
            </Stack>
          </AgeInfoCard>
        </Grid>
        <Grid item xs={4}>
          <AgeInfoCard>
            <Stack px="15px">
              <Stack direction="row" justifyContent="space-between">
                <span>{t("dashboard.member.Youth")}</span>
                <span>Age 20-35</span>
              </Stack>
              <Stack>
                <span>
                  <strong>
                    {memberStatsQuery.data?.age_classification?.youth
                      ?.total_percentage ?? 0}
                    %
                  </strong>{" "}
                  {memberStatsQuery.data?.age_classification?.youth
                    ?.total_count ?? 0}{" "}
                  {t("dashboard.member.Members")}
                </span>
              </Stack>
            </Stack>
          </AgeInfoCard>
        </Grid>
        <Grid item xs={4}>
          <AgeInfoCard>
            <Stack px="15px">
              <Stack direction="row" justifyContent="space-between">
                <span>{t("dashboard.member.Adult")}</span>
                <span>Age 36-60</span>
              </Stack>
              <Stack>
                <span>
                  <strong>
                    {memberStatsQuery.data?.age_classification?.adults
                      ?.total_percentage ?? 0}
                    %
                  </strong>{" "}
                  {memberStatsQuery.data?.age_classification?.adults
                    ?.total_count ?? 0}{" "}
                  {t("dashboard.member.Members")}
                </span>
              </Stack>
            </Stack>
          </AgeInfoCard>
        </Grid>
        <Grid item xs={4}>
          <AgeInfoCard>
            <Stack px="15px">
              <Stack direction="row" justifyContent="space-between">
                <span>{t("dashboard.member.Elders")}</span>
                <span>Age 60+</span>
              </Stack>
              <Stack>
                <span>
                  <strong>
                    {memberStatsQuery.data?.age_classification?.elders
                      ?.total_percentage ?? 0}
                    %
                  </strong>{" "}
                  {memberStatsQuery.data?.age_classification?.elders
                    ?.total_count ?? 0}{" "}
                  {t("dashboard.member.Members")}
                </span>
              </Stack>
            </Stack>
          </AgeInfoCard>
        </Grid>
      </Grid>
    </Section>
  );
};

const MemberJobStatusCard = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  const memberStatsQuery = useMemberStats({
    churchId: selectedChurch?.id
  });

  return (
    <Section>
      <SectionTitle>{t("dashboard.member.MembersJobStatus")}</SectionTitle>
      <Grid container spacing={2} sx={{ my: 3 }}>
        <Grid item xs={3}>
          <MemberInfoCard color="#74DFD5">
            <Stack direction="row" alignItems="center" spacing={1}>
              <BsJournalBookmark />
              <span>{t("dashboard.member.Student")}</span>
            </Stack>
            <h4>{memberStatsQuery.data?.job_status?.student_count ?? 0}</h4>
          </MemberInfoCard>
        </Grid>
        <Grid item xs={3}>
          <MemberInfoCard color="#F0B0FB">
            <Stack direction="row" alignItems="center" spacing={1}>
              <BsBriefcase />
              <span>{t("dashboard.member.Employed")}</span>
            </Stack>
            <h4>
              {Number(memberStatsQuery.data?.job_status?.government_count) +
                Number(
                  memberStatsQuery.data?.job_status?.non_government_count
                ) +
                Number(
                  memberStatsQuery.data?.job_status?.private_company_count
                )}
            </h4>
          </MemberInfoCard>
        </Grid>
        <Grid item xs={3}>
          <MemberInfoCard color="#DF7474">
            <Stack direction="row" alignItems="center" spacing={1}>
              <BsJournal />
              <span>{t("dashboard.member.Unemployed")}</span>
            </Stack>
            <h4>
              {Number(memberStatsQuery.data?.job_status?.unemployed_count) +
                Number(memberStatsQuery.data?.job_status?.housewife_count)}
            </h4>
          </MemberInfoCard>
        </Grid>
        <Grid item xs={3}>
          <MemberInfoCard color="#53BA63">
            <Stack direction="row" alignItems="center" spacing={1}>
              <BsLightbulb />
              <span>{t("dashboard.member.PrivateBusiness")}</span>
            </Stack>
            <h4>
              {memberStatsQuery.data?.job_status?.private_business_count ?? 0}
            </h4>
          </MemberInfoCard>
        </Grid>
      </Grid>
    </Section>
  );
};

const PartnersGrowthStatusCard = () => {
  const [labels, setLabels] = useState([]);
  const [partnersGrowthStatus, setPartnersGrowthStatus] = useState([]);
  const [selectedQueryPeriod, setSelectedQueryPeriod] = useState(null);
  const [partnersGenderPercentage, setPartnersGenderPercentage] = useState([]);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  const partnerGrowthQuery = usePartnerGrowth({
    churchId: selectedChurch?.id,
    fromDate: selectedQueryPeriod?.fromDate,
    toDate: selectedQueryPeriod?.toDate,
    period: selectedQueryPeriod?.period,
    config: {
      enabled: !!selectedQueryPeriod?.toDate,
      onSuccess: response => {
        setPartnersGrowthStatus(getChartValues(response.partner_growth_data));
        setLabels(getChartLabels(response.partner_growth_data));
        setPartnersGenderPercentage([
          response.gender_percentage?.male_percentage,
          response.gender_percentage?.female_percentage
        ]);
      }
    }
  });

  const handlePeriod = selectedPeriod => {
    setSelectedQueryPeriod(selectedPeriod);
  };

  return (
    <Section style={{ background: "#AFCDF2" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={4}
      >
        <SectionTitle>
          {t("dashboard.member.PartnersGrowthStatus")}
        </SectionTitle>
        <Toolbar onChange={handlePeriod} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={4}>
        <Graph>
          <LineChart
            labels={labels}
            data={[
              {
                name: "Partners",
                data: partnersGrowthStatus
              }
            ]}
            colors={["#134077"]}
          />
        </Graph>
        <LegendAside>
          <PartnerLegendBox color="#134077" background="#ffffff">
            <h4>{t("dashboard.member.TotalPartners")}</h4>
            <h2>{partnerGrowthQuery.data?.total_partners}</h2>
          </PartnerLegendBox>
          <PartnerLegendBox
            color="#000000"
            background="#ffffff"
            direction="column"
            align="flex-start"
          >
            <SectionTitle>{t("dashboard.member.Gender")}</SectionTitle>
            <ReactApexChart
              options={{
                colors: ["#74DFD5", "#FBD8B0"],
                chart: {
                  type: "pie"
                },
                labels: [
                  t("dashboard.member.Male"),
                  t("dashboard.member.Female")
                ],
                legend: {
                  position: "left"
                }
              }}
              series={partnersGenderPercentage}
              type="pie"
            />
          </PartnerLegendBox>
        </LegendAside>
      </Stack>
    </Section>
  );
};
