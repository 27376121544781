import { Chat, Edit, TouchApp } from "@mui/icons-material";
import {
  Avatar,
  Chip,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteModal } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteTeamMember, useTeamMembersPaginated } from "../api";
import { useTeamsStore } from "../stores";
import { TeamMembersTableDropdownMenu } from "./TeamMembersTableDropdownMenu";
import { TeamMembersTableEditDropdownMenu } from "./TeamMembersTableEditDropdownMenu";

export const TeamMembersTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [memberId, setMemberId] = useState("");
  const [memberName, setMemberName] = useState("");
  const [memberPosition, setMemberPosition] = useState("");
  const open = Boolean(anchorEl);
  const editOpen = Boolean(editAnchorEl);
  const deleteMutation = useDeleteTeamMember();
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();
  const searchQuery = useTeamsStore(state => state.searchQuery);
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const teamMembersPaginatedQuery = useTeamMembersPaginated({
    churchId: selectedChurch?.id,
    teamId: id,
    page: page,
    pageSize: pageSize,
    search: searchQuery
  });

  const handleEditClick = (event, id, name) => {
    setEditAnchorEl(event.currentTarget);
    setMemberId(id);
    setMemberName(name);
  };

  const handleEditClose = () => {
    setEditAnchorEl(null);
  };

  const handleClick = (memberData, event) => {
    setAnchorEl(event.currentTarget);
    setMemberId(memberData.id);
    setMemberPosition(memberData.team_member.role);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMemberId("");
  };

  const columns = [
    {
      field: "name",
      headerName: t("teams.teamProfile.Name"),
      minWidth: 280,
      flex: 2,
      valueGetter: params => params.row.first_name,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row?.profile_image_url}`}
              alt={row?.first_name}
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>
              {row?.first_name} {row?.father_name} {row?.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "gender",
      headerName: t("teams.teamProfile.Gender"),
      flex: 1
    },
    {
      field: "role",
      headerName: t("teams.teamProfile.Role"),
      flex: 1,
      renderCell: ({ row }) => {
        if (!row?.team_member?.role) {
          return null;
        }
        return (
          <Chip
            label={row?.team_member?.role?.replace(/_/g, " ")}
            color="primary"
          />
        );
      }
    },
    {
      field: "phone",
      headerName: t("teams.teamProfile.Phone"),
      minWidth: 150,
      flex: 1
    },
    {
      field: "email",
      headerName: t("teams.teamProfile.Email"),
      flex: 2
    },
    {
      field: "operation",
      headerName: t("teams.teamProfile.Operation"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                clearRecipients();
                removeMember(row.id);
                addRecipient(
                  `${row.id}_${row.first_name}_${row.last_name}`,
                  row
                );
                addMember(row.id, { id: row.id });
                navigate("/messages");
              }}
            >
              <Chat />
            </IconButton>
            <IconButton
              onClick={event => {
                handleEditClick(event, row.id, row.first_name);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton onClick={event => handleClick(row, event)}>
              <TouchApp />
            </IconButton>
          </Stack>
        );
      },
      flex: 2
    }
  ];

  return (
    <>
      <DeleteModal
        name={memberName}
        onDelete={() => {
          deleteMutation.mutate({
            churchId: selectedChurch?.id,
            teamId: id,
            memberId: memberId
          });
        }}
      />
      <DataGrid
        autoHeight
        rows={teamMembersPaginatedQuery?.data?.result?.data || []}
        rowCount={teamMembersPaginatedQuery?.data?.result?.total || 0}
        loading={teamMembersPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage + 1)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <TeamMembersTableDropdownMenu
          memberId={memberId}
          memberPosition={memberPosition}
          handleClose={handleClose}
        />
      </Popover>
      <Popover
        anchorEl={editAnchorEl}
        id="account-menu"
        open={editOpen}
        onClose={handleEditClose}
        onClick={handleEditClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <TeamMembersTableEditDropdownMenu memberId={memberId} />
      </Popover>
    </>
  );
};
