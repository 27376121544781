import { useQuery } from "@tanstack/react-query";
import { QueryBuilder } from "features/members";
import { axios } from "lib/axios";

export const getTeamMembersPaginated = ({
  churchId,
  teamId,
  page,
  pageSize,
  search
}) => {
  const queryBuilder = new QueryBuilder();

  queryBuilder.pagination(page, pageSize);

  let queryString = queryBuilder.build();

  if (search) {
    queryString += `&search=${search}`;
  }

  return axios.get(
    `/churches/${churchId}/teams/${teamId}/members-paginated${queryString}`
  );
};

export const useTeamMembersPaginated = ({
  churchId,
  teamId,
  page,
  pageSize,
  config,
  search = ""
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["team_members", page, pageSize, churchId, teamId, search],
    queryFn: () =>
      getTeamMembersPaginated({ churchId, teamId, page, pageSize, search })
  });
};
