import moment from "moment/moment";
import { matchIsValidTel } from "mui-tel-input";
import * as yup from "yup";

export const personalProfileSchema = yup.object({
  title: yup.string().required("Title is required").nullable(true),
  first_name: yup.string().required("First name is required").nullable(true),
  father_name: yup.string().required("Father name is required").nullable(true),
  last_name: yup.string().required("Last name is required").nullable(true),
  gender: yup.string().required("Gender is required").nullable(true),
  nationality_id: yup
    .string()
    .required("Nationality is required")
    .nullable(true),
  mother_first_name: yup.string().notRequired().nullable(true),
  mother_father_name: yup.string().notRequired().nullable(true),
  mother_grand_father_name: yup.string().notRequired().nullable(true),
  country_id: yup.string().notRequired().nullable(true),
  region_id: yup.string().notRequired().nullable(true),
  zone_id: yup.string().notRequired().nullable(true),
  city_id: yup.string().notRequired().nullable(true),
  date_of_birth: yup
    .date()
    .required("Date of birth is required")
    .test("isBeforeToday", "Date is not valid", function (value) {
      const now = new Date();
      return moment(value).isBefore(now);
    })
    .nullable(true),
  id_type: yup.string().notRequired().nullable(true),
  id_number: yup.string().notRequired().nullable(true),
  phone: yup
    .string()
    .required("Phone number is required")
    .test("isPhoneValid", "Phone is not valid", value => matchIsValidTel(value))
    .nullable(true),
  has_own_phone: yup.string().notRequired().default("1"),
  office_number: yup.string().notRequired().nullable(true),
  email: yup.string().notRequired().nullable(true),
  po_box: yup.string().notRequired().nullable(true),
  social_media_link: yup.string().notRequired().nullable(true),
  current_country_id: yup
    .string()
    .required("Current country is required")
    .nullable(true),
  current_region_id: yup.string().notRequired().nullable(true),
  current_zone_id: yup.string().notRequired().nullable(true),
  current_city_id: yup.string().notRequired().nullable(true),
  current_sub_city_id: yup.string().notRequired().nullable(true),
  current_woreda_id: yup.string().notRequired().nullable(true),
  current_kebele: yup.string().notRequired().nullable(true),
  house_number: yup.string().notRequired().nullable(true),
  specific_location: yup
    .string()
    .required("Specific location is required")
    .nullable(true),
  emergency_contact_first_name: yup
    .string()
    .required("First name is required")
    .nullable(true),
  emergency_contact_father_name: yup
    .string()
    .required("Father name is required")
    .nullable(true),
  emergency_contact_last_name: yup.string().notRequired().nullable(true),
  emergency_contact_phone: yup
    .string()
    .required("Phone number is required")
    .test("isPhoneValid", "Phone is not valid", value => matchIsValidTel(value))
    .nullable(true),
  emergency_contact_relationship: yup
    .string()
    .required("Relationship is required")
    .nullable(true),
  emergency_contact_country_id: yup
    .string()
    .required("Country is required")
    .nullable(true),
  emergency_contact_region_id: yup.string().notRequired().nullable(true),
  emergency_contact_zone_id: yup.string().notRequired().nullable(true),
  emergency_contact_city_id: yup.string().notRequired().nullable(true)
});

export const academicStatusSchema = yup.object({
  education_and_skills: yup.array().nullable(true),
  job_status: yup.string().required("Job status is required").nullable(true),
  custom_job_status: yup.string().notRequired().nullable(true),
  profession: yup.string().notRequired().nullable(true),
  work_place: yup.string().notRequired().nullable(true),
  monthly_income: yup.string().when("job_status", {
    is: jobStatus =>
      jobStatus === "student" ||
      jobStatus === "unemployed" ||
      jobStatus === "housewife",
    then: yup.string().notRequired().nullable(true),
    otherwise: yup.string().notRequired()
  })
});

const childSchema = {
  first_name: yup.string().notRequired(),
  father_name: yup.string().notRequired(),
  last_name: yup.string().notRequired(),
  date_of_birth: yup.date().notRequired(),
  nationality: yup.string().notRequired(),
  gender: yup.string().notRequired(),
  relationship: yup.string().notRequired(),
  mother_name: yup.string().notRequired(),
  mother_father_name: yup.string().notRequired(),
  mother_last_name: yup.string().notRequired(),
  mother_country: yup.string().notRequired(),
  mother_region: yup.string().notRequired(),
  mother_city: yup.string().notRequired(),
  is_believer: yup.string().notRequired(),
  is_member: yup.string().notRequired(),
  member_id: yup.string().notRequired(),
  religion: yup.string().notRequired(),
  church_name: yup.string().notRequired(),
  birth_country_id: yup.string().notRequired(),
  birth_region_id: yup.string().notRequired(),
  birth_zone_id: yup.string().notRequired(),
  birth_city_id: yup.string().notRequired(),
  is_kid: yup.string().notRequired().default("0")
};

export const maritalStatusSchema = yup.object({
  marital_status: yup
    .string()
    .required("Marital status is required")
    .nullable(true),
  marital_date: yup
    .date()
    .when("marital_status", {
      is: "married",
      then: yup
        .date()
        .required("Marital date is required")
        .test("isBeforeToday", "Date is not valid", function (value) {
          const now = new Date();
          return moment(value).isBefore(now);
        }),
      otherwise: yup.date().notRequired().nullable(true)
    })
    .nullable(true),
  type_of_marriage: yup.string().notRequired().nullable(true),
  is_spouse_believer: yup.string().notRequired().nullable(true),
  spouse_religion: yup.string().notRequired().nullable(true),
  is_spouse_member: yup.string().notRequired().nullable(true),
  spouse_member: yup.string().notRequired().nullable(true),
  spouse_church_id: yup.string().notRequired().nullable(true),
  spouse_first_name: yup.string().notRequired().nullable(true),
  spouse_father_name: yup.string().notRequired().nullable(true),
  spouse_last_name: yup.string().notRequired().nullable(true),
  spouse_phone_number: yup.string().notRequired().nullable(true),
  spouse_date_of_birth: yup.date().notRequired().nullable(true),
  spouse_mother_name: yup.string().notRequired().nullable(true),
  spouse_mother_father_name: yup.string().notRequired().nullable(true),
  spouse_mother_grand_father_name: yup.string().notRequired().nullable(true),
  spouse_professional_skill: yup.string().notRequired().nullable(true),
  spouse_office_name: yup.string().notRequired().nullable(true),
  spouse_church_name: yup.string().notRequired().nullable(true),
  child_and_dependencies: yup
    .string()
    .required("Child and dependencies is required")
    .nullable(true),
  children: yup.array().when("child_and_dependencies", {
    is: "yes",
    then: yup.array().of(yup.object().shape(childSchema)),
    otherwise: yup.array().nullable(true)
  })
});

export const previousChurchInfoSchema = yup.object().shape({
  transfer_from_other_church: yup.string().notRequired().nullable(true),
  previous_church_name: yup.string().when("transfer_from_other_church", {
    is: "1",
    then: yup
      .string()
      .required("Previous church name is required")
      .nullable(true),
    otherwise: yup.string().notRequired().nullable(true)
  }),
  date_of_resignation: yup.date().notRequired().nullable(true),
  pastor_title: yup.string().notRequired().nullable(true),
  pastor_full_name: yup.string().when("transfer_from_other_church", {
    is: "1",
    then: yup.string().required("Paster full name is required").nullable(true),
    otherwise: yup.string().notRequired().nullable(true)
  }),
  pastor_phone_number: yup.string().when("transfer_from_other_church", {
    is: "1",
    then: yup
      .string()
      .required("Phone number is required")
      .test("isPhoneValid", "Phone is not valid", value =>
        matchIsValidTel(value)
      )
      .nullable(true),
    otherwise: yup.string().notRequired().nullable(true)
  }),
  reason_for_resignation: yup.string().notRequired().nullable(true)
});

export const currentChurchEngagementSchema = yup.object({
  former_belief: yup.string().notRequired().nullable(true),
  how_you_accept_christ: yup.string().notRequired().nullable(true),
  salvation_date: yup.date().notRequired().nullable(true),
  is_baptized: yup.string().required("Is baptized is required").nullable(true),
  salvation_church: yup.string().notRequired().nullable(true),
  baptism_church: yup.string().when("is_baptized", {
    is: "1",
    then: yup.string().required("Baptism church is required").nullable(true),
    otherwise: yup.string().notRequired().nullable(true)
  }),
  baptism_date: yup.date().when("is_baptized", {
    is: "1",
    then: yup
      .date()
      .required("Baptism date is required")
      .test("isBeforeToday", "Date is not valid", function (value) {
        const now = new Date();
        return moment(value).isBefore(now);
      })
      .nullable(true),
    otherwise: yup.date().notRequired().nullable(true)
  }),
  is_home_cell: yup.string().notRequired().nullable(true),
  home_cell_name: yup.string().notRequired().nullable(true),
  home_cell_cordinator: yup.string().notRequired().nullable(true),
  date_joined_home_cell: yup.date().notRequired().nullable(true),
  current_ministry: yup.string().notRequired().nullable(true),
  date_joined_current_ministry: yup.date().notRequired().nullable(true),
  role_in_current_ministry: yup.string().notRequired().nullable(true),
  membership_date: yup
    .date()
    .required("Membership date is required")
    .nullable(true),
  gifts_and_talents: yup.string().notRequired().nullable(true),
  ministry_preference: yup.array().notRequired().nullable(true)
});
