import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const createBudgetItem = ({ churchId, budgetItemData }) => {
  return axios.post(`/churches/${churchId}/budgets`, budgetItemData);
};

export const useCreateBudgetItem = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeSetBudgetModal =
    useFinanceSettingsStore.getState().closeSetBudgetModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("group-budgets-paginated");

      setNotification({
        type: "success",
        message: "Budget item created successfully!"
      });

      closeSetBudgetModal();
    },
    ...config,
    mutationFn: createBudgetItem
  });
};
