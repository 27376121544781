import { matchIsValidTel } from "mui-tel-input";
import * as yup from "yup";

export const employeeSchema = yup.object().shape(
  {
    employee_id: yup.string().required("Employee ID is required"),
    title: yup.string().notRequired().nullable(),
    first_name: yup.string().notRequired().nullable(),
    last_name: yup.string().notRequired().nullable(),
    middle_name: yup.string().notRequired().nullable(),
    gender: yup.string().notRequired().nullable(),
    date_of_birth: yup.date().notRequired().nullable(),
    nationality_id: yup.string().notRequired().nullable(),
    marital_status: yup.string().notRequired().nullable(),
    phone_number: yup.string().when("phone_number", {
      is: phone => !!phone,
      then: yup
        .string()
        .test("isPhoneValid", "Phone is not valid", value =>
          matchIsValidTel(value)
        ),
      otherwise: yup.string().notRequired().nullable()
    }),
    employee_photo: yup.mixed().notRequired().nullable(),
    // .test(
    //   "imageSize",
    //   "Image file size must be less than 1 MB",
    //   function (value) {
    //     if (!value) return true;

    //     const imageSize = value[0].size / Math.pow(1024, 2);

    //     return imageSize <= 1;
    //   }
    // ),
    email: yup
      .string()
      .notRequired()
      .nullable()
      .trim()
      .email("Email is not valid"),
    address: yup.string().notRequired().nullable().trim(),
    position: yup.string().notRequired().nullable().trim(),
    employment_date: yup.date().notRequired().nullable(),
    employment_type: yup.string().notRequired().nullable(),
    salary_bank_name: yup.string().notRequired().nullable(),
    salary_bank_account_number: yup.string().notRequired().nullable(),
    pf_bank_name: yup.string().notRequired().nullable(),
    pf_bank_account_number: yup.string().notRequired().nullable(),
    social_id_number: yup.string().notRequired().nullable(),
    tin_number: yup.string().notRequired().nullable(),
    gross_salary: yup.string().notRequired().nullable(),
    overtime: yup.string().notRequired().nullable(),
    transport_allowance: yup.string().notRequired().nullable(),
    house_allowance: yup.string().notRequired().nullable(),
    communication_allowance: yup.string().notRequired().nullable(),
    bonus: yup.string().notRequired().nullable(),
    tax: yup.string().notRequired().nullable(),
    credit: yup.string().notRequired().nullable(),
    pension: yup.string().notRequired().nullable(),
    provident_fund: yup.string().notRequired().nullable(),
    penalty: yup.string().notRequired().nullable(),
    emergency_contact_title: yup.string().notRequired().nullable(),
    emergency_contact_first_name: yup.string().notRequired().nullable(),
    emergency_contact_last_name: yup.string().notRequired().nullable(),
    emergency_contact_middle_name: yup.string().notRequired().nullable(),
    relationship_to_employee: yup.string().notRequired().nullable(),
    emergency_contact_phone: yup.string().notRequired().nullable(),
    emergency_contact_address: yup.string().notRequired().nullable()
  },
  [["phone_number", "phone_number"]]
);
