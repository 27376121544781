import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import _ from "lodash";
import { FaUndoAlt } from "react-icons/fa";
import { FixedSizeList } from "react-window";
import { useMessagesStore } from "../store";

const Card = styled.div`
  padding: 40px 20px 24px 20px;
  display: flex;
  gap: 40px;
  align-items: center;
  background: ${({ bg }) => (bg ? bg : "white")};
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const Subtitle = styled.span`
  color: #000000;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  text-transform: uppercase;
`;

const ProgressSplits = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 24px;
  overflow: hidden;
  border-radius: 25px;
`;

const ProgressStatusDelivered = styled.div`
  width: ${props => `${props.value}%`};
  background: ${({ theme }) => theme.palette.success.main};
`;

const ProgressStatusFailed = styled.div`
  width: ${props => `${props.value}%`};
  background: ${({ theme }) => theme.palette.error.main};
`;

const ProgressStatusUnknown = styled.div`
  width: ${props => `${props.value}%`};
  background: #8c8c8c;
`;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontStyle: "italic"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #999"
    }
  }
}));

const messages = [
  {
    id: 53,
    first_name: "Helen",
    father_name: "Tamru",
    last_name: "Maru",
    image: "https://source.unsplash.com/random",
    text: "Lorem ipsum dolor sit amet,",
    type: "Text Message",
    port: "SMS",
    status: "Sent",
    timestamp: "1/1/23, 9:00 PM"
  },
  {
    id: 83,
    first_name: "Brook",
    father_name: "Tamru",
    last_name: "Maru",
    image: "https://source.unsplash.com/random",
    text: "Lorem ipsum dolor sit amet,",
    type: "Text Message",
    port: "SMS",
    status: "Delivered",
    timestamp: "1/1/23, 9:00 PM"
  },
  {
    id: 13,
    first_name: "Lemma",
    father_name: "Tamru",
    last_name: "Maru",
    image: "https://source.unsplash.com/random",
    text: "Lorem ipsum dolor sit amet,",
    type: "Text Message",
    port: "SMS",
    status: "Failed",
    timestamp: "1/1/23, 9:00 PM"
  }
];

const statusMapping = {
  sent: "secondary",
  delivered: "success",
  draft: "warning",
  failed: "error",
  scheduled: "warning"
};

export function GroupMessageStatusModal() {
  const groupMessageStatusModalOpen = useMessagesStore(
    state => state.groupMessageStatusModalOpen
  );
  const closeGroupMessageStatusModal = useMessagesStore(
    state => state.closeGroupMessageStatusModal
  );

  return (
    <Modal
      open={groupMessageStatusModalOpen}
      onClose={closeGroupMessageStatusModal}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 4,
          width: "577px",
          height: "629px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 3,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            fontWeight: 600
          }}
        >
          Group message status
        </Box>
        <Card>
          <ProgressWrapper>
            <ProgressSplits>
              <LightTooltip title="5 Delivered" placement="top" arrow>
                <ProgressStatusDelivered value={50} />
              </LightTooltip>
              <LightTooltip title="3 Failed" placement="top" arrow>
                <ProgressStatusFailed value={30} />
              </LightTooltip>
              <LightTooltip title="2 Unknown" placement="top" arrow>
                <ProgressStatusUnknown value={20} />
              </LightTooltip>
            </ProgressSplits>
            <Subtitle>2400 SMS are sent!</Subtitle>
          </ProgressWrapper>
        </Card>
        <Divider />
        <Stack
          sx={{
            px: 1,
            backgroundColor: "white"
          }}
          spacing={2}
        >
          <FixedSizeList
            height={450}
            itemSize={60}
            itemData={messages}
            itemCount={messages?.length || 0}
            overscanCount={5}
          >
            {props => {
              const { data, index, style } = props;

              return (
                <ListItem
                  secondaryAction={
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {data[index]["status"] === "Failed" && (
                        <IconButton size="small" color="error">
                          <FaUndoAlt />
                        </IconButton>
                      )}
                      <Chip
                        label={
                          <Typography variant="caption">
                            {data[index]["status"]}
                          </Typography>
                        }
                        color={
                          statusMapping[_.lowerCase(data[index]["status"])]
                        }
                      />
                      <Typography variant="caption">
                        {data[index]["timestamp"]}
                      </Typography>
                    </Stack>
                  }
                  style={style}
                  key={index}
                  sx={{ p: 0 }}
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar
                        src={data[index]["image"]}
                        alt="Image"
                        sx={{
                          outline: "2px solid",
                          outlineColor: "primary.main"
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${data[index]["first_name"]} ${data[index]["father_name"]} ${data[index]["last_name"]}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }}
          </FixedSizeList>
        </Stack>
      </Box>
    </Modal>
  );
}
