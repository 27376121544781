import { Box, Button, Stack } from "@mui/material";
import { useCentralStore } from "stores";

export function PartnersTableDropdownMenu() {
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        Delete
      </Box>
      <Stack
        sx={{
          minWidth: "168px",
          backgroundColor: "white",
          p: 2
        }}
        justifyContent="space-between"
      >
        <Button
          size="small"
          variant="outlined"
          color="error"
          onClick={openDeleteModal}
        >
          Delete
        </Button>
      </Stack>
    </Box>
  );
}
