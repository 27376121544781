import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useAssetsStore = create(
  devtools(
    set => ({
      addAssetModalOpen: false,
      addAssetFormActiveStep: 1,
      openAddAssetModal: () => set({ addAssetModalOpen: true }),
      closeAddAssetModal: () => set({ addAssetModalOpen: false }),
      setAddAssetFormActiveStep: step => set({ addAssetFormActiveStep: step })
    }),
    { name: "assets-store" }
  )
);
