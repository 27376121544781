import { Box, Stack, Typography } from "@mui/material";
import { Notification } from "components/UI";
import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import { useCentralStore } from "stores";
import {
  AcademicStatusForm,
  CurrentChurchEngagementForm,
  MaritalStatusForm,
  PersonalProfileForm,
  PreviousChurchInfoForm,
  Steppers
} from "../components";

const stepTitles = {
  1: "addMembers.step1.PersonalProfileAndOther",
  2: "addMembers.step2.AcademicStatus",
  3: "addMembers.step3.MaritalStatusAndChildren",
  4: "addMembers.step4.PreviousChurchPastorInformation",
  5: "addMembers.step5.CurrentChurchEngagement"
};

export function AddMember() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const { t } = useTranslation();

  useEffect(() => {
    setHeaderTitle(t("addMembers.navbar.memberForm"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  return (
    <Box sx={{ px: 6, py: 4 }}>
      <Notification />
      <StepsProvider>
        <StepsHeader />
        <Steps>
          <PersonalProfileForm />
          <AcademicStatusForm />
          <MaritalStatusForm />
          <PreviousChurchInfoForm />
          <CurrentChurchEngagementForm />
        </Steps>
      </StepsProvider>
    </Box>
  );
}

const StepsHeader = () => {
  const { t } = useTranslation();
  const { current, jump } = useSteps();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack alignItems="center" sx={{ mb: 6 }} spacing={1}>
      <Typography>{t(stepTitles[current])}</Typography>
      <Steppers
        activeStep={current - 1}
        onClick={step => {
          const selectedStep = step + 1;

          if (selectedStep < current) {
            jump(step + 1);
          }
        }}
      />
    </Stack>
  );
};
