import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const updateRevenue = ({ churchId, revenueId, revenueData }) => {
  return axios.patch(`/churches/${churchId}/revenue/${revenueId}`, revenueData);
};

export const useUpdateRevenue = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateRevenueModal = useRevenueStore(
    state => state.closeUpdateRevenueModal
  );

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("revenues");

      setNotification({
        type: "success",
        message: "Revenue updated successfully!"
      });

      closeUpdateRevenueModal();
    },
    ...config,
    mutationFn: updateRevenue
  });
};
