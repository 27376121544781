import { Autocomplete, Grid, Typography } from "@mui/material";
import { PhoneInputField, Radio, Select, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { parseISO } from "date-fns";
import { useChurchStore } from "features/churches";
import { FILTER_OPERATIONS, MEMBER_STATES } from "features/members";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import { useMembersPaginated } from "../../api";

export const MaritalStatusSection = ({
  register,
  errors,
  watch,
  control,
  setValue
}) => {
  const { t } = useTranslation();
  const [searchMemberValue, setSearchMemberValue] = useState("");
  const [debouncedSearchMemberValue, setDebouncedSearchMemberValue] =
    useState("");
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  useDebounce(
    () => {
      setDebouncedSearchMemberValue(searchMemberValue);
    },
    200,
    [searchMemberValue]
  );

  const filterMembersQuery = useMembersPaginated({
    config: {
      enabled: !!debouncedSearchMemberValue
    },
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10,
    queries: {
      filter: {
        status: [
          {
            operator: FILTER_OPERATIONS.EQUAL,
            value: MEMBER_STATES.ACTIVE
          }
        ]
      },
      search: {
        first_name: debouncedSearchMemberValue
      }
    }
  });

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step3.MaritalStatus")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={12}>
          <Radio
            label={t("addMembers.step3.MaritalStatus")}
            name="marital_status"
            buttons={[
              {
                radioLabel: t("addMembers.step3.Single"),
                value: "single",
                id: "single"
              },
              {
                radioLabel: t("addMembers.step3.Married"),
                value: "married",
                id: "married"
              },
              {
                radioLabel: t("addMembers.step3.Divorced"),
                value: "divorced",
                id: "divorced"
              },
              {
                radioLabel: t("addMembers.step3.Separated"),
                value: "separated",
                id: "separated"
              },
              {
                radioLabel: t("addMembers.step3.Widowed"),
                value: "widowed",
                id: "widowed"
              }
            ]}
            row={true}
            register={register}
            error={!!errors.marital_status?.message}
            helperText={errors.marital_status?.message}
            required
          />
        </Grid>

        {watch("marital_status") === "married" && (
          <>
            <Grid item xs={4}>
              <Controller
                name="marital_date"
                control={control}
                render={({ field }) => (
                  <DateField
                    label={t("addMembers.step3.MaritalDate")}
                    name="marital_date"
                    error={!!errors.marital_date?.message}
                    helperText={errors.marital_date?.message}
                    {...field}
                    required
                    disableFutureDates
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                label={t("addMembers.step3.TypeOfMarriage")}
                name="type_of_marriage"
                options={[
                  {
                    optionLabel: t("addMembers.step3.CulturalWay"),
                    value: "Cultural way"
                  },
                  {
                    optionLabel: t("addMembers.step3.ChurchCeremony"),
                    value: "Church ceremony"
                  },
                  {
                    optionLabel: t("addMembers.step3.GovernmentOrg"),
                    value: "Government organization"
                  }
                ]}
                register={register}
                error={!!errors.type_of_marriage?.message}
                helperText={errors.type_of_marriage?.message}
              />
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={4}>
              <Radio
                label={t("addMembers.step3.IsSpouseABeliever")}
                name="is_spouse_believer"
                buttons={[
                  {
                    radioLabel: t("form.common.yes"),
                    value: "1",
                    id: "is_believer_yes"
                  },
                  {
                    radioLabel: t("form.common.no"),
                    value: "0",
                    id: "is_believer_no"
                  }
                ]}
                row={true}
                register={register}
                error={!!errors.is_spouse_believer?.message}
                helperText={errors.is_spouse_believer?.message}
              />
            </Grid>
            <Grid item xs={4}>
              {watch("is_spouse_believer") === "0" && (
                <TextField
                  label={t("addMembers.step3.SpouseReligion")}
                  name="spouse_religion"
                  error={!!errors.spouse_religion?.message}
                  helperText={errors.spouse_religion?.message}
                  register={register}
                />
              )}
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={4}>
              {watch("is_spouse_believer") === "1" && (
                <Radio
                  label={t("addMembers.step3.IsSpouseAMember")}
                  name="is_spouse_member"
                  buttons={[
                    {
                      radioLabel: t("form.common.yes"),
                      value: "1",
                      id: "is_member_yes"
                    },
                    {
                      radioLabel: t("form.common.no"),
                      value: "0",
                      id: "is_member_no"
                    }
                  ]}
                  row={true}
                  register={register}
                  error={!!errors.is_spouse_member?.message}
                  helperText={errors.is_spouse_member?.message}
                />
              )}
            </Grid>
            <Grid item xs={8}>
              {watch("is_spouse_believer") === "1" &&
                watch("is_spouse_member") === "1" && (
                  <Autocomplete
                    id="search-member"
                    freeSolo
                    options={filterMembersQuery.data?.result?.data || []}
                    getOptionLabel={option =>
                      `${option.first_name ?? ""} ${option.father_name ?? ""} ${
                        option.last_name ?? ""
                      }`
                    }
                    loading={filterMembersQuery.isLoading}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t("addMembers.step3.SearchByName")}
                        register={() => {}}
                      />
                    )}
                    onInputChange={e => setSearchMemberValue(e.target.value)}
                    onChange={(e, selectedValue) => {
                      setValue(
                        "spouse_first_name",
                        selectedValue.first_name ?? ""
                      );
                      setValue(
                        "spouse_father_name",
                        selectedValue.father_name ?? ""
                      );
                      setValue(
                        "spouse_last_name",
                        selectedValue.last_name ?? ""
                      );
                      setValue(
                        "spouse_phone_number",
                        selectedValue.phone ?? ""
                      );
                      setValue(
                        "spouse_date_of_birth",
                        parseISO(selectedValue.date_of_birth) ?? ""
                      );
                      setValue(
                        "spouse_mother_name",
                        selectedValue.mother_first_name ?? ""
                      );
                      setValue(
                        "spouse_mother_father_name",
                        selectedValue.mother_father_name ?? ""
                      );
                      setValue(
                        "spouse_mother_grand_father_name",
                        selectedValue.mother_grand_father_name ?? ""
                      );
                      setValue(
                        "spouse_professional_skill",
                        selectedValue.profession ?? ""
                      );
                    }}
                  />
                )}
              {watch("is_spouse_believer") === "1" &&
                watch("is_spouse_member") === "0" && (
                  <TextField
                    label={t("addMembers.step3.SpouseChurchName")}
                    name="spouse_church_name"
                    error={!!errors.spouse_church_name?.message}
                    helperText={errors.spouse_church_name?.message}
                    register={register}
                  />
                )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                label={t("form.common.firstName")}
                name="spouse_first_name"
                error={!!errors.spouse_first_name?.message}
                helperText={errors.spouse_first_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("form.common.fatherName")}
                name="spouse_father_name"
                error={!!errors.spouse_father_name?.message}
                helperText={errors.spouse_father_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("form.common.grandFatherName")}
                name="spouse_last_name"
                error={!!errors.spouse_last_name?.message}
                helperText={errors.spouse_last_name?.message}
                register={register}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="spouse_phone_number"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t("form.common.phone")}
                    variant="filled"
                    error={!!errors.spouse_phone_number?.message}
                    helperText={errors.spouse_phone_number?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="spouse_date_of_birth"
                control={control}
                render={({ field }) => (
                  <DateField
                    label={t("form.common.dateOfBirth")}
                    name="spouse_date_of_birth"
                    error={!!errors.spouse_date_of_birth?.message}
                    helperText={errors.spouse_date_of_birth?.message}
                    {...field}
                    disableFutureDates
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {t("addMembers.step3.SpouseMotherName")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("form.common.firstName")}
                name="spouse_mother_name"
                error={!!errors.spouse_mother_name?.message}
                helperText={errors.spouse_mother_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("form.common.fatherName")}
                name="spouse_mother_father_name"
                error={!!errors.spouse_mother_father_name?.message}
                helperText={errors.spouse_mother_father_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("form.common.grandFatherName")}
                name="spouse_mother_grand_father_name"
                error={!!errors.spouse_mother_grand_father_name?.message}
                helperText={errors.spouse_mother_grand_father_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("addMembers.step3.SpouseProfessionalSkills")}
                name="spouse_professional_skill"
                error={!!errors.spouse_professional_skill?.message}
                helperText={errors.spouse_professional_skill?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("addMembers.step3.SpouseOfficeName")}
                name="spouse_office_name"
                error={!!errors.spouse_office_name?.message}
                helperText={errors.spouse_office_name?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4} />
          </>
        )}
      </Grid>
    </Grid>
  );
};
