import { CancelOutlined } from "@mui/icons-material";
import { Avatar, Button, IconButton, Stack, Typography } from "@mui/material";
import { IMAGE_URL } from "config";
import { useMessagesStore } from "features/messages";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { HiChat } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useMembersStore } from "../stores";

export function MemberPreviewCard() {
  const memberPreviewData = useMembersStore(state => state.memberPreviewData);
  const closeMemberPreview = useMembersStore(state => state.closeMemberPreview);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);

  return (
    <Stack
      alignItems="stretch"
      sx={{
        px: 2,
        py: 6,
        position: "relative",
        background:
          "linear-gradient(193.89deg, #82DBD8 -5.16%, #3BACB6 97.91%)",
        borderRadius: 1,
        maxWidth: 350
      }}
      spacing={2}
    >
      <IconButton
        aria-label="settings"
        sx={{ position: "absolute", top: 0, right: 2 }}
        color="error"
        onClick={closeMemberPreview}
      >
        <CancelOutlined />
      </IconButton>
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Avatar
          sx={{
            width: "160px",
            height: "160px",
            border: "6px solid",
            borderColor: "white"
          }}
          src={`${IMAGE_URL}/${memberPreviewData?.profile_image_url}`}
          alt={memberPreviewData?.first_name}
        />
        <Typography variant="h6" color="white" textAlign="center">
          {memberPreviewData?.first_name} {memberPreviewData?.father_name}{" "}
          {memberPreviewData?.last_name}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography variant="subtitle1" color="primary.main">
          {t("form.common.email")}
        </Typography>
        <Typography variant="subtitle1" noWrap>
          {memberPreviewData?.email}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography variant="subtitle1" color="primary.main">
          {t("form.common.phone")}
        </Typography>
        <Typography variant="subtitle1">{memberPreviewData?.phone}</Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography variant="subtitle1" color="primary.main">
          {t("message.template.CreatedDate")}
        </Typography>
        <Typography variant="subtitle1">
          {moment(memberPreviewData?.created_at).format("DD/MM/YYYY")}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        size="large"
        sx={{
          borderRadius: "50px",
          backgroundColor: "white",
          color: "primary.main",
          "&:hover": {
            backgroundColor: "white",
            color: "primary.main"
          },
          alignSelf: "center"
        }}
        onClick={() => {
          clearRecipients();
          removeMember(memberPreviewData.id);
          addRecipient(
            `${memberPreviewData.id}_${memberPreviewData.first_name}_${memberPreviewData.last_name}`,
            memberPreviewData
          );
          addMember(memberPreviewData.id, { id: memberPreviewData.id });
          navigate("/messages");
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <HiChat />{" "}
          <Typography variant="subtitle2">
            {t("members.partners.sendMessage")}
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
}
