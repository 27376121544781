import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getRole = ({ churchId, roleId }) => {
  return axios.get(`/churches/${churchId}/roles/${roleId}`);
};

export const useRole = ({ churchId, roleId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["role", roleId, churchId],
    queryFn: () => getRole({ churchId, roleId })
  });
};
