/* Custom query string builder
Usage example:

Input:

const sampleInput = {
  status: [
    {
    operator: "eq",
    value: "active"
    }
  ]
}

const queryBuilder = new QueryBuilder();
const resultQuery = queryBuilder.filter(sampleInput);

Output:

console.log(resultQuery);
"?filters[0][column]=status&filters[0][operator]=eq&filters[0][value]=active"

*/
export class QueryBuilder {
  constructor() {
    this.queryString = "";
  }

  filter(filterObject) {
    for (const [filterKey, filterValues] of Object.entries(filterObject)) {
      for (const [filterIndex, filterValue] of filterValues.entries()) {
        if (Array.isArray(filterValue.value)) {
          let multipleValuesString = `&filters[${filterIndex}][column]=${filterKey}&filters[${filterIndex}][operator]=${filterValue.operator}`;

          for (const [valueIndex, value] of filterValue.value.entries()) {
            multipleValuesString += `&filters[${filterIndex}][value][${valueIndex}]=${value}`;
          }

          this.queryString += multipleValuesString;
        } else {
          this.queryString += `&filters[${filterIndex}][column]=${filterKey}&filters[${filterIndex}][operator]=${filterValue.operator}&filters[${filterIndex}][value]=${filterValue.value}`;
        }
      }
    }
  }

  search(searchObject) {
    for (const [searchKey, searchValue] of Object.entries(searchObject)) {
      this.queryString += `&search[columns][]=${searchKey}&search[value]=${searchValue}`;
    }
  }

  sort(sortObject) {
    for (const [sortKey, sortValue] of Object.entries(sortObject)) {
      this.queryString += `&sort[${sortKey}]=${sortValue}`;
    }
  }

  groupBy(groupByArray) {
    for (const groupByValue of groupByArray) {
      this.queryString += `&group_by[]=${groupByValue}`;
    }
  }

  pagination(page, pageSize) {
    this.queryString += `&page=${page}`;
    this.queryString += `&per_page=${pageSize}`;
  }

  append(key, value) {
    this.queryString += `&${key}=${value}`;
  }

  reset() {
    this.queryString = "";
  }

  build() {
    return `?${this.queryString.slice(1)}`;
  }
}
