import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useDenominationStore = create(
  devtools(
    persist(
      set => ({
        selectedDenomination: null,
        selectDenomination: denomination =>
          set({ selectedDenomination: denomination })
      }),
      {
        name: "denomination"
      }
    ),
    {
      name: "denomination-store"
    }
  )
);
