import * as yup from "yup";

export const assetSchema = yup.object().shape({
  name: yup.string().required("Asset name is required"),
  type: yup.string().notRequired().nullable(),
  description: yup.string().notRequired().nullable(),
  quantity: yup.string().notRequired().nullable(),
  acquisition_method: yup.string().notRequired().nullable(),
  acquisition_date: yup.date().notRequired().nullable(),
  purchase_price: yup.string().notRequired().nullable(),
  vendor: yup.string().notRequired().nullable(),
  assigned_to: yup.string().notRequired().nullable(),
  depreciation_method: yup.string().notRequired().nullable(),
  location: yup.string().notRequired().nullable(),
  status: yup.string().notRequired().nullable(),
  condition: yup.string().notRequired().nullable(),
  depreciation_rate: yup.string().notRequired().nullable(),
  book_value: yup.string().notRequired().nullable(),
  maintenance_schedule: yup.date().notRequired().nullable(),
  last_maintenance_date: yup.date().notRequired().nullable(),
  warranty_expiry_date: yup.date().notRequired().nullable(),
  documents: yup.string().notRequired().nullable(),
  compliance_status: yup.string().notRequired().nullable(),
  notes: yup.string().notRequired().nullable()
});
