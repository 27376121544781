import styled from "@emotion/styled";
import {
  CalendarMonth,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useChurchStore } from "features/churches";
import moment from "moment-timezone";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEditMessage } from "../api";

const RoundButton = ({ children, ...rest }) => (
  <Button
    sx={{
      fontSize: "12px",
      backgroundColor: "primary.main",
      color: "primary.contrastText",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "primary.main",
        color: "primary.contrastText"
      }
    }}
    {...rest}
  >
    {children}
  </Button>
);

const PickerWrapper = styled.div`
  & .react-datepicker__header {
    background: ${({ theme }) => theme.palette.secondary.main};
  }

  & .react-datepicker__current-month {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const CalendarButton = forwardRef(({ onClick }, ref) => (
  <IconButton color="primary" onClick={onClick} ref={ref}>
    <CalendarMonth />
  </IconButton>
));

CalendarButton.displayName = "CalendarButton";

export function RescheduleOptions({ message }) {
  const [hour, setHour] = useState(
    Number(moment.utc(message?.date_time).tz("Africa/Addis_Ababa").format("h"))
  );
  const [minutes, setMinutes] = useState(
    moment.utc(message?.date_time).tz("Africa/Addis_Ababa").minutes()
  );
  const [amPm, setAmPm] = useState(
    moment.utc(message?.date_time).tz("Africa/Addis_Ababa").format("A")
  );
  const [date, setDate] = useState(new Date(message?.date_time));
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const editMessageMutation = useEditMessage();

  const incrementHour = () => {
    if (hour < 12) {
      setHour(hour + 1);
    }
  };

  const decrementHour = () => {
    if (hour > 1) {
      setHour(hour - 1);
    }
  };

  const incrementMinutes = () => {
    if (minutes < 59) {
      setMinutes(minutes + 1);
    }
  };

  const decrementMinutes = () => {
    if (minutes > 0) {
      setMinutes(minutes - 1);
    }
  };

  const changeAmPm = () => {
    if (amPm === "AM") {
      setAmPm("PM");
    } else {
      setAmPm("AM");
    }
  };

  const scheduleMessage = () => {
    const selectedDateAndTime = `${moment(date).format("YYYY-MM-DD")} ${String(
      hour
    ).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${amPm}`;

    const messageData = {
      date_time: moment(selectedDateAndTime, "YYYY-MM-DD hh:mm A").format(
        "YYYY-MM-DDTHH:mm:ssZ"
      )
    };

    editMessageMutation.mutate({
      churchId: selectedChurch?.id,
      messageId: message?.id,
      messageData
    });
  };

  return (
    <Stack
      sx={{
        borderRadius: "10px",
        backgroundColor: "white",
        px: 2,
        py: 4
      }}
      spacing={1}
    >
      <Typography
        variant="caption"
        color="primary"
        sx={{ textAlign: "center", fontWeight: 600 }}
      >
        Reschedule Message
      </Typography>
      <Stack direction="row" alignItems="center">
        <PickerWrapper>
          <DatePicker
            selected={date}
            onChange={selectedDate => setDate(selectedDate)}
            customInput={<CalendarButton />}
            showPopperArrow={false}
            withPortal
            minDate={moment().toDate()}
          />
        </PickerWrapper>
        <Typography variant="caption">
          {moment(date).format("ddd, MMM DD YYYY")}
        </Typography>
        <Stack alignItems="center">
          <IconButton color="primary" onClick={incrementHour}>
            <KeyboardArrowUp />
          </IconButton>
          <Typography variant="caption">{hour}</Typography>
          <IconButton color="primary" onClick={decrementHour}>
            <KeyboardArrowDown />
          </IconButton>
        </Stack>
        <Stack alignItems="center">
          <IconButton color="primary" onClick={incrementMinutes}>
            <KeyboardArrowUp />
          </IconButton>
          <Typography variant="caption">
            {String(minutes).padStart(2, "0")}
          </Typography>
          <IconButton color="primary" onClick={decrementMinutes}>
            <KeyboardArrowDown />
          </IconButton>
        </Stack>
        <Stack alignItems="center">
          <IconButton color="primary" onClick={changeAmPm}>
            <KeyboardArrowUp />
          </IconButton>
          <Typography variant="caption">{amPm}</Typography>
          <IconButton color="primary" onClick={changeAmPm}>
            <KeyboardArrowDown />
          </IconButton>
        </Stack>
      </Stack>
      <RoundButton onClick={scheduleMessage}>
        Send on {moment(date).format("MMM DD")} at {hour}:
        {String(minutes).padStart(2, "0")} {amPm}
      </RoundButton>
    </Stack>
  );
}
