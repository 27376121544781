import styled from "@emotion/styled";
import { useState } from "react";
import {
  ApproverTable,
  BudgetYearsTable,
  ChartOfAccountTable,
  CreateApproverModal,
  CreateBudgetYearModal,
  CreateChartOfAccountModal,
  CreateChartOfAccountNameModal,
  UpdateApproverModal,
  UpdateChartOfAccountModal
} from "../components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Tab = styled.div`
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : "inherit"};
  font-weight: ${({ active }) => (active ? 500 : 400)};
  background-color: ${({ active, theme }) =>
    active ? theme.palette.neutral.main : "inherit"};
  padding: 8px;
  cursor: pointer;
`;

export const FinanceSettings = () => {
  const [activeTab, setActiveTab] = useState("chartOfAccount");
  const [chartOfAccount, setChartOfAccount] = useState(null);
  const [approverRecord, setApproverRecord] = useState(null);

  const tables = {
    chartOfAccount: (
      <ChartOfAccountTable
        onEdit={chartOfAccount => setChartOfAccount(chartOfAccount)}
      />
    ),
    approver: (
      <ApproverTable onEdit={approver => setApproverRecord(approver)} />
    ),
    budgetYear: <BudgetYearsTable />
  };

  return (
    <Container>
      <CreateChartOfAccountNameModal />
      <CreateChartOfAccountModal />
      <UpdateChartOfAccountModal data={chartOfAccount} />
      <CreateApproverModal />
      <UpdateApproverModal data={approverRecord} />
      <CreateBudgetYearModal />
      <Tabs>
        <Tab
          active={activeTab === "chartOfAccount"}
          onClick={() => setActiveTab("chartOfAccount")}
        >
          Chart Of Account
        </Tab>
        <Tab
          active={activeTab === "approver"}
          onClick={() => setActiveTab("approver")}
        >
          Approver
        </Tab>
        <Tab
          active={activeTab === "budgetYear"}
          onClick={() => setActiveTab("budgetYear")}
        >
          Budget Years
        </Tab>
      </Tabs>
      {tables[activeTab]}
    </Container>
  );
};
