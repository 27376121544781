import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { Radio, Select, TextField } from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import {
  ADDRESSES,
  useMembersStore,
  usePublicCities,
  usePublicCountries,
  usePublicRegions,
  usePublicZones
} from "features/members";
import { useEffect, useRef } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const PersonalProfileSection = ({
  register,
  errors,
  watch,
  setValue,
  control
}) => {
  const profileImage = useMembersStore(state => state.memberData.profile_image);
  const setProfileImage = useMembersStore(state => state.setProfileImage);
  const idImage = useMembersStore(state => state.memberData.id_image);
  const setIdImage = useMembersStore(state => state.setIdImage);
  const { t } = useTranslation();

  const hiddenFileInput = useRef(null);
  const hiddenIDFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleIdClick = () => {
    hiddenIDFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setProfileImage(fileUploaded);
  };

  const handleIdChange = event => {
    const fileUploaded = event.target.files[0];
    setIdImage(fileUploaded);
  };

  const countriesQuery = usePublicCountries();
  const regionsQuery = usePublicRegions({
    countryId: watch("country_id"),
    config: {
      enabled: !!watch("country_id")
    }
  });
  const zonesQuery = usePublicZones({
    regionId: watch("region_id"),
    config: {
      enabled: !!watch("region_id")
    }
  });
  const citiesQuery = usePublicCities({
    zoneId: watch("zone_id"),
    config: {
      enabled: !!watch("zone_id")
    }
  });

  useEffect(() => {
    if (countriesQuery.data) {
      // set default nationality and country to Ethiopia
      setValue("nationality_id", "66");
      setValue("country_id", "66");
    }
  }, [countriesQuery.data]);

  const newLanguageField = {
    language: ""
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "languages"
  });

  return (
    <Grid
      direction={{
        xs: "column",
        md: "row"
      }}
      container
      columnSpacing={12}
    >
      <Grid item xs={3}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="body1">
            {t("addMembers.step1.PersonalProfile")}
          </Typography>
          <Avatar
            variant="rounded"
            src={profileImage ? URL.createObjectURL(profileImage) : ""}
            alt=""
            sx={{
              width: {
                xs: 80,
                md: 150,
                xl: 250
              },
              height: {
                xs: 80,
                md: 150,
                xl: 250
              }
            }}
          />
          <Stack alignItems="center">
            <input
              accept="image/*"
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              hidden
            />
            <Typography variant="subtitle2">
              {t("addMembers.step1.UploadPhoto")}
            </Typography>
            <Button size="large" variant="contained" onClick={handleClick}>
              {t("ui.dropzone.browse")}
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid container item xs={9} spacing={2} columns={{ xs: 2, md: 12 }}>
        <Grid item xs={2} md={3}>
          <TextField
            label={t("addMembers.step1.Title")}
            name="title"
            error={!!errors.title?.message}
            helperText={errors.title?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={2} md={3}>
          <TextField
            label={t("form.common.firstName")}
            name="first_name"
            error={!!errors.first_name?.message}
            helperText={errors.first_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={2} md={3}>
          <TextField
            label={t("form.common.fatherName")}
            name="father_name"
            error={!!errors.father_name?.message}
            helperText={errors.father_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={2} md={3}>
          <TextField
            label={t("form.common.grandFatherName")}
            name="last_name"
            error={!!errors.last_name?.message}
            helperText={errors.last_name?.message}
            register={register}
            required
          />
        </Grid>
        <Grid item xs={2} md={4}>
          <Radio
            label={t("form.common.gender")}
            name="gender"
            buttons={[
              {
                radioLabel: t("form.common.male"),
                value: "male",
                id: "gender_male"
              },
              {
                radioLabel: t("form.common.female"),
                value: "female",
                id: "gender_female"
              }
            ]}
            row={true}
            register={register}
            error={!!errors.gender?.message}
            helperText={errors.gender?.message}
            required
          />
        </Grid>
        <Grid item xs={2} md={8}>
          <Select
            isLabelBold={0}
            label={t("addMembers.step1.Nationality")}
            name="nationality_id"
            options={
              countriesQuery.data?.result?.map(country => ({
                optionLabel: country?.nationality,
                value: country?.id
              })) || []
            }
            register={register}
            error={!!errors.nationality_id?.message}
            helperText={errors.nationality_id?.message}
            required
          />
        </Grid>
        <Grid item xs={2} md={4}>
          <TextField
            label={t("addMembers.step1.MotherFirstName")}
            name="mother_first_name"
            error={!!errors.mother_first_name?.message}
            helperText={errors.mother_first_name?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={2} md={4}>
          <TextField
            label={t("addMembers.step1.MotherFatherName")}
            name="mother_father_name"
            error={!!errors.mother_father_name?.message}
            helperText={errors.mother_father_name?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={2} md={4}>
          <TextField
            label={t("addMembers.step1.MotherGrandfatherName")}
            name="mother_grand_father_name"
            error={!!errors.mother_grand_father_name?.message}
            helperText={errors.mother_grand_father_name?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={2} md={12}>
          <Typography variant="subtitle2">
            {t("addMembers.step1.Birthplace")}
          </Typography>
        </Grid>
        <Grid item xs={2} md={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.country")}
            name="country_id"
            options={
              countriesQuery.data?.result?.map(country => ({
                optionLabel: country?.name,
                value: country?.id
              })) || []
            }
            register={register}
            error={!!errors.country_id?.message}
            helperText={errors.country_id?.message}
          />
        </Grid>
        <Grid item xs={2} md={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.region")}
            name={`region_id`}
            options={
              regionsQuery.data?.result?.map(region => ({
                optionLabel: region?.name,
                value: region?.id
              })) || []
            }
            register={register}
            error={!!errors.region_id?.message}
            helperText={errors.region_id?.message}
            onChange={e => {
              setValue("region_id", e.target.value, {
                shouldValidate: true
              });

              if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                setValue("zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID, {
                  shouldValidate: true
                });
                setValue("city_id", ADDRESSES.ADDIS_ABABA.CITY_ID, {
                  shouldValidate: true
                });
              } else {
                setValue("zone_id", "", {
                  shouldValidate: true
                });
                setValue("city_id", "", {
                  shouldValidate: true
                });
              }
            }}
          />
        </Grid>
        {watch("region_id") !== ADDRESSES.ADDIS_ABABA.REGION_ID && (
          <>
            <Grid item xs={2} md={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.zone")}
                name={`zone_id`}
                options={
                  zonesQuery.data?.result?.map(zone => ({
                    optionLabel: zone?.name,
                    value: zone?.id
                  })) || []
                }
                register={register}
                error={!!errors.zone_id?.message}
                helperText={errors.zone_id?.message}
              />
            </Grid>
            <Grid item xs={2} md={3}>
              <Select
                isLabelBold={0}
                label={t("form.common.city")}
                name={`city_id`}
                options={
                  citiesQuery.data?.result?.map(city => ({
                    optionLabel: city?.name,
                    value: city?.id
                  })) || []
                }
                register={register}
                error={!!errors.city_id?.message}
                helperText={errors.city_id?.message}
              />
            </Grid>
          </>
        )}
        <Grid item xs={2} md={6}>
          <Controller
            name="date_of_birth"
            control={control}
            render={({ field }) => (
              <DateField
                label={t("form.common.dateOfBirth")}
                name={field.name}
                error={!!errors.date_of_birth?.message}
                helperText={errors.date_of_birth?.message}
                {...field}
                required
                disableFutureDates
              />
            )}
          />
        </Grid>
        <Grid item xs={2} md={12}>
          <Typography variant="subtitle2">
            {t("addMembers.step1.IdInformation")}
          </Typography>
        </Grid>
        <Grid item xs={2} md={6}>
          <Select
            label={t("addMembers.step1.IdType")}
            name="id_type"
            options={[
              {
                optionLabel: t("addMembers.step1.GovernmentIssued"),
                value: "Government issued"
              },
              {
                optionLabel: t("addMembers.step1.License"),
                value: "license"
              },
              {
                optionLabel: t("addMembers.step1.Passport"),
                value: "passport"
              }
            ]}
            register={register}
            error={!!errors.id_type?.message}
            helperText={errors.id_type?.message}
          />
        </Grid>
        <Grid item xs={2} md={6}>
          <TextField
            label={t("addMembers.step1.IdNumber")}
            name="id_number"
            error={!!errors.id_number?.message}
            helperText={errors.id_number?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={2} md={12}>
          <Stack direction="row" spacing={1} alignItems="flex-end">
            <Stack alignItems="flex-start">
              <input
                accept="image/*"
                type="file"
                ref={hiddenIDFileInput}
                onChange={handleIdChange}
                hidden
              />
              <Typography variant="subtitle2">
                {t("addMembers.step1.UploadYourId")}
              </Typography>
              <Button size="large" variant="contained" onClick={handleIdClick}>
                {t("ui.dropzone.browse")}
              </Button>
            </Stack>
            <Avatar
              variant="rounded"
              src={idImage ? URL.createObjectURL(idImage) : ""}
              alt="Image"
              sx={{ width: 100, height: 100 }}
            />
          </Stack>
        </Grid>
        <Grid item xs={2} md={12}>
          {fields.map((field, index) => (
            <Stack direction="row" alignItems="center" key={field.id} mb={2}>
              <TextField
                label={t("addMembers.step1.Language")}
                name={`languages.${index}.language`}
                error={!!errors.languages?.[index]?.language?.message}
                helperText={errors.languages?.[index]?.language?.message}
                register={register}
              />
              {index !== 0 && (
                <IconButton
                  sx={{
                    alignSelf: "flex-end"
                  }}
                  onClick={() => remove(index)}
                >
                  <RemoveCircleOutline color="primary" />
                </IconButton>
              )}
            </Stack>
          ))}
        </Grid>
        <Grid
          item
          xs={2}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <IconButton onClick={() => append(newLanguageField)}>
            <AddCircleOutline color="white" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
