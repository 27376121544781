import { CreateTemplateModal, TemplatesPanel } from "../components";

export const Templates = () => {
  return (
    <>
      <CreateTemplateModal />
      <TemplatesPanel />
    </>
  );
};
