import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Stack } from "@mui/material";
import { Button, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateTeamType } from "../api";
import { useTeamsStore } from "../stores";
import { teamTypeSchema } from "../validation";

export function CreateTeamTypesModal() {
  const createTeamTypeModalOpen = useTeamsStore(
    state => state.createTeamTypeModalOpen
  );
  const closeCreateTeamTypeModal = useTeamsStore(
    state => state.closeCreateTeamTypeModal
  );
  const teamTypeMutation = useCreateTeamType();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset
  } = useForm({
    defaultValues: {
      name: "",
      description: ""
    },
    resolver: yupResolver(teamTypeSchema)
  });

  useEffect(() => {
    if (teamTypeMutation.isSuccess) {
      reset({ ...defaultValues });
    }

    return () => {};
  }, [teamTypeMutation.isSuccess]);

  const onSubmit = data => {
    teamTypeMutation.mutate({
      churchId: selectedChurch?.id,
      teamTypeData: data
    });
  };

  return (
    <Modal open={createTeamTypeModalOpen} onClose={closeCreateTeamTypeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("teams.teams.CreateTeamType")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label={t("teams.createTeamType.NameOfTheTeamType")}
            name="name"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            register={register}
            isLabelBold={1}
          />
          <TextField
            label={t("teams.createTeamType.TeamTypeDescription")}
            name="description"
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            register={register}
            multiline
            minRows={4}
            fullWidth
            isLabelBold={1}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              flavor="secondary"
              onClick={closeCreateTeamTypeModal}
              disabled={teamTypeMutation.isLoading}
            >
              {t("form.common.cancel")}
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={teamTypeMutation.isLoading}
            >
              {t("form.common.create")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
