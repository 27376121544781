import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMessagesStore } from "../store";

export const createPort = ({ churchId, portData }) => {
  return axios.post(`/churches/${churchId}/message-ports`, portData);
};

export const useCreatePort = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreatePortModal = useMessagesStore.getState().closeCreatePortModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("ports");
      setNotification({
        type: "success",
        message: "Port created successfully!"
      });
      closeCreatePortModal();
    },
    ...config,
    mutationFn: createPort
  });
};
