import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const deleteExpenseTypes = ({ churchId, expenseTypeId }) => {
  return axios.delete(`/churches/${churchId}/expense-types/${expenseTypeId}`);
};

export const usedeleteExpenseTypes = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closeUpdateExpenseTypeModal =
    useExpenseStore.getState().closeUpdateExpenseTypeModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["expense-types"]);

      setNotification({
        type: "success",
        message: "Expense type deleted successfully!"
      });

      closeDeleteModal();
      closeUpdateExpenseTypeModal();
    },
    ...config,
    mutationFn: deleteExpenseTypes
  });
};
