import styled from "@emotion/styled";
import { Modal, Stack } from "@mui/material";
import { CheckCircle } from "@phosphor-icons/react";
import { Button } from "components/UI";
import { useRevenueStore } from "features/finances";

const Container = styled.div`
  border-radius: 8px;
  background: #fff;
  width: 400px;
  height: 387px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Title = styled.span`
  color: #25ae88;
  font-size: 16px;
  font-weight: 700;
`;

const Message = styled.span`
  text-align: center;
  max-width: 200px;
`;

export const SuccessModal = () => {
  const successModalOpen = useRevenueStore(state => state.successModalOpen);
  const closeSuccessModal = useRevenueStore(state => state.closeSuccessModal);

  return (
    <Modal open={successModalOpen} onClose={closeSuccessModal}>
      <Container>
        <Wrapper>
          <Stack alignItems="center" spacing={1}>
            <CheckCircle size={55} color="#25AE88" weight="fill" />
            <Title>Success!</Title>
          </Stack>
          <Message>Cheque approved and revenue recorded</Message>
          <Button>Done</Button>
        </Wrapper>
      </Container>
    </Modal>
  );
};
