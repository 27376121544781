import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

export function UsersTableHeader({ tabValue, setTabValue }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        color: "white",
        backgroundColor: "primary.main",
        py: 1,
        px: 2,
        borderRadius: "5px"
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={tabValue}
          onChange={(event, value) => setTabValue(value)}
          aria-label="basic tabs example"
          indicatorColor=""
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={t("systemAdmins.systemAdmins.SystemAdmins")}
            sx={{
              color: "white",
              "&.Mui-selected": {
                borderRadius: "5px",
                background: "white",
                color: "primary.main"
              }
            }}
          />
          <Tab
            label={t("systemAdmins.systemAdmins.Logs")}
            sx={{
              color: "white",
              "&.Mui-selected": {
                borderRadius: "5px",
                background: "white",
                color: "primary.main"
              }
            }}
          />
          <Tab
            label={t("systemAdmins.systemAdmins.RoleManagement")}
            sx={{
              color: "white",
              "&.Mui-selected": {
                borderRadius: "5px",
                background: "white",
                color: "primary.main"
              }
            }}
          />
        </Tabs>
      </Stack>
    </Box>
  );
}
