import styled from "@emotion/styled";
import { Avatar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Input = styled.input`
  font-size: 0;
  position: absolute;
  z-index: -9999;
  opacity: 0;
  outline: none;
`;

const Button = styled.label`
  min-width: 64px;
  padding: 8px 22px;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75;
  cursor: pointer;
`;

export const Upload = ({
  title,
  buttonTitle = "Browse",
  error,
  helperText,
  onChange,
  preview = "",
  ...rest
}) => {
  const [photo, setPhoto] = useState(preview);

  useEffect(() => {
    setPhoto(preview);
  }, [preview]);

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <Stack style={{ position: "relative" }}>
          <Input
            type="file"
            id="file-upload"
            onChange={e => {
              if (e.target.files.length > 0) {
                setPhoto(URL.createObjectURL(e.target.files[0]));
                onChange(e.target.files[0]);
              }
            }}
            {...rest}
          />
          <Typography variant="subtitle2">{title}</Typography>
          <Button htmlFor="file-upload">{buttonTitle}</Button>
        </Stack>
        <Avatar
          variant="rounded"
          src={photo}
          alt="Image"
          sx={{ width: 100, height: 100 }}
        />
      </Stack>
      {error && (
        <Typography
          variant="caption"
          color="error"
          sx={{ padding: "0 0.75rem" }}
        >
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};
