import { Stack, Typography } from "@mui/material";
import { useChurchStore, useChurches } from "features/churches";
import { useDenominationStore } from "features/denominations";
import { useStoredMessagesStore } from "features/messages";
import { useUser } from "features/users";
import { useEffect, useState } from "react";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { CiLogout } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useAclStore } from "stores/aclStore";
import { Button } from "../Buttons";
import { Loading } from "../Loading";

const Account = ({ name, active = false, onClick }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "191px",
        backgroundColor: active ? "primary.main" : "secondary.main",
        p: 1,
        borderRadius: 1,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <Typography
        variant="caption"
        sx={{
          color: active ? "primary.contrastText" : "secondary.contrastText",
          fontWeight: 500
        }}
        noWrap
      >
        {name}
      </Typography>
    </Stack>
  );
};

export function AccountMenu() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const churchesQuery = useChurches();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const selectChurch = useChurchStore(state => state.selectChurch);
  const setRole = useAclStore(state => state.setRole);
  const setPermissions = useAclStore(state => state.setPermissions);
  const { t } = useTranslation();
  const selectDenomination = useDenominationStore(
    state => state.selectDenomination
  );
  const storeMessages = useStoredMessagesStore(state => state.storeMessages);
  const [pickedChurch, setPickedChurch] = useState(null);

  const auth = useAuthUser();
  const loggedInUser = auth();

  const { data } = useUser({
    churchId: pickedChurch?.id,
    userId: loggedInUser?.id,
    config: {
      enabled: Boolean(pickedChurch)
    }
  });

  useEffect(() => {
    if (data) {
      setRole(data.result?.role?.name);
      setPermissions(data.result?.role?.permissions);
      navigate("/");
    }
  }, [data]);

  const clearLocalStorageData = () => {
    selectChurch(null);
    selectDenomination(null);
    storeMessages([]);
  };

  const handleLogout = () => {
    signOut();
    clearLocalStorageData();
  };

  return (
    <Stack
      sx={{
        width: "240px",
        backgroundColor: "white",
        p: 3,
        borderRadius: 4
      }}
    >
      <Button
        size="large"
        variant="contained"
        sx={{ borderRadius: 2 }}
        onClick={() => navigate("/profile")}
      >
        {t("ui.accountMenu.updateProfile")}
      </Button>
      <Stack sx={{ mt: 2, mb: 4 }} spacing={1}>
        <Typography variant="caption">
          {t("ui.accountMenu.switchAccount")}
        </Typography>
        <Button
          size="small"
          flavor="white"
          onClick={() => navigate("/churches")}
        >
          {t("ui.accountMenu.goToChurches")}
        </Button>
        {churchesQuery?.isLoading ? (
          <Loading sx={{ alignSelf: "center" }} />
        ) : (
          churchesQuery?.data?.result?.map(church => (
            <Account
              key={church.id}
              name={church.name}
              active={church.id === selectedChurch?.id}
              onClick={() => {
                selectChurch(church);
                setPickedChurch(church);
              }}
            />
          ))
        )}
      </Stack>
      <Button
        size="small"
        variant="contained"
        sx={{ borderRadius: 1 }}
        onClick={handleLogout}
        startIcon={<CiLogout />}
      >
        {t("ui.accountMenu.logout")}
      </Button>
    </Stack>
  );
}
