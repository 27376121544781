import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Stack, Typography } from "@mui/material";
import { BackgroundInputField, Notification, TextField } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import i18next from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { usePermissions, useRole, useUpdateRole } from "../api";
import { PermissionBlock } from "../components";
import { roleSchema } from "../validation";

const Container = styled.div`
  min-height: 100vh;
  margin-block: 29px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 22px;
`;

const RoleCard = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 35px 27px;
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleBar = styled.div`
  background: #d1d5db;
  border-radius: 4px;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  padding: 0 60px;

  h4 {
    margin: 0;
    padding: 0;
  }

  & > * {
    flex: 1;
  }
`;

const UserRolesCard = styled.div`
  width: 412px;
  background: white;
  border-radius: 8px;
  height: 405px;
  box-shadow: -10px 10px 40px -10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const UserRolesCardHeader = styled.div`
  padding: 14px 18px;
  background: ${({ theme }) => theme.palette.primary.main};
  color: white;
  font-weight: 700;

  h5 {
    margin: 0;
    padding: 0;
  }
`;

const UserRolesCardContent = styled.div`
  padding: 22px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  max-height: 377px;
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  all: unset;
  background: transparent;
  border-radius: 4px;
  padding: 10px 20px;
  color: black;
  cursor: pointer;
  font-weight: 600;
`;

const CreateButton = styled.button`
  all: unset;
  background: ${({ theme, disabled }) =>
    disabled ? theme.palette.neutral.main : theme.palette.primary.main};
  border-radius: 4px;
  padding: 10px 55px;
  color: ${({ disabled }) => (disabled ? "lightgrey" : "white")};
  cursor: pointer;
  font-weight: 600;
`;

export function EditRole() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [permissionBlocks, setPermissionBlocks] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionUsers, setPermissionUsers] = useState([]);
  const [roleName, setRoleName] = useState("");
  const updateRoleMutation = useUpdateRole();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { id } = useParams();

  const { data: roleData } = useRole({
    churchId: selectedChurch?.id,
    roleId: id
  });

  useEffect(() => {
    if (roleData) {
      reset({
        role_name: roleData?.result?.name,
        role_description: roleData?.result?.description
      });

      setRoleName(roleData?.result?.name);

      setPermissionUsers(roleData?.result?.users);
    }
  }, [roleData]);

  const { data } = usePermissions({
    name: ""
  });

  useEffect(() => {
    if (data) {
      const response = data?.result;

      const blocks = _(response)
        .groupBy("category")
        .map((permissions, block) => {
          return {
            name: block,
            permissions
          };
        })
        .value();

      setPermissionBlocks(blocks);
    }
  }, [data]);

  useEffect(() => {
    if (permissionBlocks && roleData) {
      const currentPermissions = roleData?.result?.permissions?.map(
        permission => permission.id
      );

      setSelectedPermissions(currentPermissions);
    }
  }, [permissionBlocks, roleData]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      role_name: "",
      role_description: ""
    },
    resolver: yupResolver(roleSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("systemAdmins.createNewRole.RoleManagement"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  const onSubmit = values => {
    const data = {
      name: values.role_name,
      description: values.role_description,
      permissions: selectedPermissions
    };

    updateRoleMutation.mutate({
      churchId: selectedChurch?.id,
      roleId: id,
      roleData: data
    });
  };

  return (
    <Container>
      <Notification />
      <TopSection>
        <RoleCard>
          <TextField
            label={t("systemAdmins.createNewRole.RoleName")}
            name="role_name"
            error={!!errors.role_name?.message}
            helperText={errors.role_name?.message}
            register={register}
            neutral={1}
          />
          <Stack>
            <Typography variant="subtitle2" component="label">
              {t("systemAdmins.createNewRole.RoleDescription")}
            </Typography>
            <Controller
              name="role_description"
              control={control}
              render={({ field }) => (
                <BackgroundInputField
                  id="description"
                  size="small"
                  autoComplete="off"
                  multiline
                  minRows={4}
                  fullWidth
                  inputProps={{
                    maxLength: 100
                  }}
                  {...field}
                  error={!!errors.role_description?.message}
                  helperText={`${t(
                    "systemAdmins.createNewRole.CharacterLimit"
                  )} - ${field.value.length}/100`}
                  neutral={1}
                />
              )}
            />
          </Stack>
        </RoleCard>
        <UserRolesCard>
          <UserRolesCardHeader>
            <h5>{roleName}</h5>
          </UserRolesCardHeader>
          <UserRolesCardContent>
            {permissionUsers.map(user => (
              <UserRow key={user?.id}>
                <Avatar
                  src={`${IMAGE_URL}/${user?.image_url}`}
                  alt={user?.first_name}
                  sx={{
                    height: 40,
                    width: 40,
                    outlineColor: "primary.main",
                    outline: "1px solid"
                  }}
                />
                <Typography variant="body2">{user?.first_name}</Typography>
              </UserRow>
            ))}
          </UserRolesCardContent>
        </UserRolesCard>
      </TopSection>
      <BottomSection>
        <TitleBar>
          <h4>{t("systemAdmins.createNewRole.PermissionType")}</h4>
          <h4>{t("systemAdmins.createNewRole.Action")}</h4>
        </TitleBar>
        {permissionBlocks.map(permissionBlock => (
          <PermissionBlock
            key={permissionBlock.name}
            name={permissionBlock.name}
            permissions={permissionBlock.permissions}
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          />
        ))}
      </BottomSection>
      <Operations>
        <CancelButton
          disabled={updateRoleMutation.isLoading}
          onClick={() => navigate(-1)}
        >
          {t("systemAdmins.createNewRole.Cancel")}
        </CancelButton>
        <CreateButton
          disabled={
            selectedPermissions?.length < 1 || updateRoleMutation.isLoading
          }
          onClick={handleSubmit(onSubmit)}
        >
          {t("form.common.submit")}
        </CreateButton>
      </Operations>
    </Container>
  );
}
