import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getUserAnalytics = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/dashboard/system-user-analytics`);
};

export const useUserAnalytics = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["user-analytics", churchId],
    queryFn: () => getUserAnalytics({ churchId }),
    select: response => response.result,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0
  });
};
