import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const updateBudgetYear = ({
  churchId,
  budgetYearId,
  budgetYearData
}) => {
  return axios.patch(
    `/churches/${churchId}/budget-years/${budgetYearId}`,
    budgetYearData
  );
};

export const useUpdateBudgetYear = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateBudgetYearModal =
    useFinanceSettingsStore.getState().closeUpdateBudgetYearModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("budget-years-paginated");

      setNotification({
        type: "success",
        message: "Budget year updated successfully!"
      });

      closeUpdateBudgetYearModal();
    },
    ...config,
    mutationFn: updateBudgetYear
  });
};
