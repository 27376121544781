import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const updateChartOfAccount = ({
  churchId,
  chartOfAccountData,
  chartOfAccountId
}) => {
  return axios.patch(
    `/churches/${churchId}/chart-of-accounts/${chartOfAccountId}`,
    chartOfAccountData
  );
};

export const useUpdateChartOfAccount = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateChartOfAccountModal =
    useFinanceSettingsStore.getState().closeUpdateChartOfAccountModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["chart-accounts"]);
      setNotification({
        type: "success",
        message: "Chart of account updated successfully!"
      });
      closeUpdateChartOfAccountModal();
    },
    ...config,
    mutationFn: updateChartOfAccount
  });
};
