import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const deleteBudgetYear = ({ churchId, budgetYearId }) => {
  return axios.delete(`/churches/${churchId}/budget-years/${budgetYearId}`);
};

export const useDeleteBudgetYear = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateBudgetYearModal =
    useFinanceSettingsStore.getState().closeUpdateBudgetYearModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("budget-years-paginated");

      setNotification({
        type: "success",
        message: "Budget year deleted successfully!"
      });

      closeUpdateBudgetYearModal();
    },
    ...config,
    mutationFn: deleteBudgetYear
  });
};
