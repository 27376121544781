import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { Button, Radio } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useBudgetYears,
  useCreateBudgetItem,
  // useCreateBudgetYear,
  useFinanceSettingsStore
} from "features/finances";
import { useTeams } from "features/teams";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
  flex: 1;
`;

const setBudgetItemSchema = yup.object({
  budget_year_id: yup.string().required(),
  team_id: yup.string().required(),
  item: yup.string().required(),
  item_gross_amount: yup.number().required(),
  is_split_by_quarters: yup.boolean().required(),
  quarter_1_amount: yup.number().notRequired(),
  quarter_2_amount: yup.number().notRequired(),
  quarter_3_amount: yup.number().notRequired(),
  quarter_4_amount: yup.number().notRequired()
});

export function SetBudgetModal() {
  const setBudgetOpen = useFinanceSettingsStore(state => state.setBudgetOpen);
  const closeSetBudgetModal = useFinanceSettingsStore(
    state => state.closeSetBudgetModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const createBudgetItemMutation = useCreateBudgetItem();
  const teamsQuery = useTeams({
    churchId: selectedChurch?.id
  });
  const budgetYearsQuery = useBudgetYears({
    churchId: selectedChurch?.id
  });

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      budget_year_id: "",
      team_id: "",
      item: "",
      item_gross_amount: 0,
      is_split_by_quarters: true,
      quarter_1_amount: 0,
      quarter_2_amount: 0,
      quarter_3_amount: 0,
      quarter_4_amount: 0
    },
    resolver: yupResolver(setBudgetItemSchema)
  });

  const isSplit = watch("is_split_by_quarters") === "true";

  const onSubmit = values => {
    const budgetItemData = {
      budget_year_id: values.budget_year_id,
      team_id: values.team_id,
      item: values.item,
      item_gross_amount: values.item_gross_amount,
      is_split_by_quarters: values.is_split_by_quarters
    };

    if (values.is_split_by_quarters) {
      budgetItemData["quarter_1_amount"] = values.quarter_1_amount;
      budgetItemData["quarter_2_amount"] = values.quarter_2_amount;
      budgetItemData["quarter_3_amount"] = values.quarter_3_amount;
      budgetItemData["quarter_4_amount"] = values.quarter_4_amount;
    } else {
      budgetItemData["quarter_1_amount"] =
        values.quarter === "quarter_1" ? values.item_gross_amount : 0;
      budgetItemData["quarter_2_amount"] =
        values.quarter === "quarter_2" ? values.item_gross_amount : 0;
      budgetItemData["quarter_3_amount"] =
        values.quarter === "quarter_3" ? values.item_gross_amount : 0;
      budgetItemData["quarter_4_amount"] =
        values.quarter === "quarter_4" ? values.item_gross_amount : 0;
    }

    console.log(budgetItemData);

    createBudgetItemMutation.mutate(
      {
        churchId: selectedChurch?.id,
        budgetItemData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Add Budget Item"
      isOpen={setBudgetOpen}
      onClose={closeSetBudgetModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button flavor="white" size="small" onClick={closeSetBudgetModal}>
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </Stack>
      }
    >
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="name">Selected Department</Label>
          <Select id="department" {...register("team_id")}>
            {teamsQuery.data?.result?.map(team => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </Select>
        </Field>
        <Field>
          <Label htmlFor="name">Budget Year</Label>
          <Select id="department" {...register("budget_year_id")}>
            {budgetYearsQuery.data?.result?.map(budgetYear => (
              <option key={budgetYear.id} value={budgetYear.id}>
                {budgetYear.title}
              </option>
            ))}
          </Select>
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="name">Item</Label>
        <Input {...register("item")} />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerMonth">Gross Amount For Item</Label>
        <Input type="number" {...register("item_gross_amount")} />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerMonth">
          Do you need to split the budget between Quarters
        </Label>
        <Radio
          label=""
          name="is_split_by_quarters"
          buttons={[
            {
              radioLabel: "Yes",
              value: true,
              id: "split_yes",
              labelStyle: {
                fontSize: "12px"
              }
            },
            {
              radioLabel: "No",
              value: false,
              id: "split_no",
              labelStyle: {
                fontSize: "12px"
              }
            }
          ]}
          row={true}
          register={register}
          error={false}
          helperText=""
        />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerMonth">Assign Budget Period</Label>
        <Typography
          variant="caption"
          sx={{
            color: "rgba(167, 167, 167, 1)"
          }}
        >
          You can assign the budget for each quarters based on gross amount.
        </Typography>
        {isSplit && (
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "12px"
                }}
              >
                Quarter 1 (Jan, Feb, Mar)
              </Typography>
              <Input style={{ flex: 1 }} {...register("quarter_1_amount")} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "12px"
                }}
              >
                Quarter 2 (Apr, May, Jun)
              </Typography>
              <Input style={{ flex: 1 }} {...register("quarter_2_amount")} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "12px"
                }}
              >
                Quarter 3 (Jul, Aug, Sep)
              </Typography>
              <Input style={{ flex: 1 }} {...register("quarter_3_amount")} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "12px"
                }}
              >
                Quarter 4 (Oct, Nov, Dec)
              </Typography>
              <Input style={{ flex: 1 }} {...register("quarter_4_amount")} />
            </Stack>
          </>
        )}

        {!isSplit && (
          <Radio
            label=""
            name="quarter"
            buttons={[
              {
                radioLabel: "Quarter 1",
                value: "quarter_1",
                id: "quarter_1",
                labelStyle: {
                  fontSize: "12px"
                }
              },
              {
                radioLabel: "Quarter 2",
                value: "quarter_2",
                id: "quarter_2",
                labelStyle: {
                  fontSize: "12px"
                }
              },
              {
                radioLabel: "Quarter 3",
                value: "quarter_3",
                id: "quarter_3",
                labelStyle: {
                  fontSize: "12px"
                }
              },
              {
                radioLabel: "Quarter 4",
                value: "quarter_4",
                id: "quarter_4",
                labelStyle: {
                  fontSize: "12px"
                }
              }
            ]}
            row={false}
            register={register}
            error={false}
            helperText=""
          />
        )}
      </Field>
    </BaseModal>
  );
}
