import { Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses
} from "@mui/material/CircularProgress";

export const Loading = ({ sx }) => {
  return (
    <Box sx={{ ...sx, position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme => theme.palette.grey[200]
        }}
        size={40}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: theme => theme.palette.primary.main,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round"
          }
        }}
        size={40}
        thickness={4}
      />
    </Box>
  );
};
