import { Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Checkbox,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack
} from "@mui/material";
import { BackgroundInputField, Button } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { FILTER_OPERATIONS, MEMBER_STATES, useMembers } from "features/members";
import Fuse from "fuse.js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FixedSizeList } from "react-window";
import { useAddTeamMember, useTeamMembers } from "../api";
import { useTeamsStore } from "../stores";

export function AddMemberModal() {
  const addTeamMemberModalOpen = useTeamsStore(
    state => state.addTeamMemberModalOpen
  );
  const closeAddTeamMemberModal = useTeamsStore(
    state => state.closeAddTeamMemberModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const addTeamMember = useAddTeamMember();
  const { id } = useParams();
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [alreadyMembers, setAlreadyMembers] = useState([]);

  const teamMembersQuery = useTeamMembers({
    churchId: selectedChurch?.id,
    teamId: id
  });

  useEffect(() => {
    if (teamMembersQuery.data) {
      const alreadyMembersId = [];

      teamMembersQuery.data?.result?.forEach(member => {
        alreadyMembersId.push(member?.id);
      });

      setAlreadyMembers([...alreadyMembersId]);
    }
  }, [teamMembersQuery.data]);

  const membersQuery = useMembers({
    churchId: selectedChurch?.id,
    queries: {
      filter: {
        status: [
          {
            operator: FILTER_OPERATIONS.EQUAL,
            value: MEMBER_STATES.ACTIVE
          }
        ]
      }
    }
  });

  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setMembers(membersQuery?.data?.result);
    setFilteredMembers(membersQuery?.data?.result);
  }, [membersQuery.data]);

  const addMembers = () => {
    addTeamMember.mutate(
      {
        churchId: selectedChurch?.id,
        teamId: id,
        memberData: {
          member_ids: selectedMembers
        }
      },
      {
        onSuccess: () => {
          setSelectedMembers([]);
        }
      }
    );
  };

  const searchMembers = () => {
    const fuse = new Fuse(members, {
      threshold: 0.3,
      keys: ["first_name", "father_name", "last_name"]
    });

    if (_.isEmpty(searchTerm)) {
      setFilteredMembers(members);
      return;
    }

    const searchResults = fuse.search(searchTerm);

    if (_.isEmpty(searchResults)) {
      setFilteredMembers([]);
    } else {
      setFilteredMembers([
        ..._.map(fuse.search(searchTerm), foundTerm => foundTerm.item)
      ]);
    }
  };

  return (
    <Modal open={addTeamMemberModalOpen} onClose={closeAddTeamMemberModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 4,
          width: "800px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("teams.addTeamMember.AddMember")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
        >
          <BackgroundInputField
            variant="outlined"
            placeholder={t("teams.addTeamMember.SearchMemberToAdd")}
            sx={{
              backgroundColor: "neutral.main"
            }}
            neutral={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            onKeyUp={searchMembers}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />

          {/* <Stack direction="row" alignItems="center" spacing={2}>
            <Stack sx={{ flex: 1 }}>
              <FormControl size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "secondary.main",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: `none`
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: `none`
                      }
                    }
                  }}
                >
                  <MenuItem value={10}>Value</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <BackgroundInputField
                margin="none"
                required
                fullWidth
                id="filter"
                name="filter"
                size="small"
              />
            </Stack>
          </Stack> */}
          <FixedSizeList
            height={400}
            itemSize={60}
            itemData={filteredMembers}
            itemCount={filteredMembers?.length || 0}
            overscanCount={5}
          >
            {props => {
              const { data, index, style } = props;

              return (
                <ListItem style={style} key={index} sx={{ p: 0 }}>
                  <ListItemButton
                    onClick={() => {
                      if (!alreadyMembers.includes(data[index]["id"])) {
                        if (!selectedMembers.includes(data[index]["id"])) {
                          setSelectedMembers([
                            ...selectedMembers,
                            data[index]["id"]
                          ]);
                        } else {
                          setSelectedMembers(
                            selectedMembers.filter(
                              memberId => memberId !== data[index]["id"]
                            )
                          );
                        }
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          selectedMembers.includes(data[index]["id"]) ||
                          alreadyMembers.includes(data[index]["id"])
                        }
                        disabled={alreadyMembers.includes(data[index]["id"])}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemAvatar>
                      <Avatar
                        src={`${IMAGE_URL}/${data[index]["profile_image_url"]}`}
                        alt={data[index]["first_name"]}
                        sx={{
                          outline: "2px solid",
                          outlineColor: "primary.main"
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${data[index]["first_name"]} ${data[index]["father_name"]} ${data[index]["last_name"]}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }}
          </FixedSizeList>

          <Button onClick={addMembers} disabled={selectedMembers.length === 0}>
            Add
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
