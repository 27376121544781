import Axios from "axios";
import { API_URL } from "config";
import Cookie from "js-cookie";
import { useCentralStore } from "stores";
import { getToken } from "utils/getToken";

export const axios = Axios.create({
  baseURL: API_URL
});

axios.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
});

axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const setNotification = useCentralStore.getState().setNotification;

    if (error?.response?.status === 401 && Cookie.get("_auth")) {
      Object.keys(Cookie.get()).forEach(function (cookieName) {
        Cookie.remove(cookieName);
      });

      window.location.href = `/auth/sign-in`;
    }

    if (typeof error?.response?.data?.errors === "string") {
      if (
        error?.response?.data?.errors?.includes("unauthorized") ||
        error?.response?.status === 403
      ) {
        setNotification({
          type: "error",
          title: "Unauthorized",
          message: "You are unauthorized from performing this action."
        });
      }

      if (
        !error?.response?.data?.errors?.includes("unauthorized") &&
        error?.response?.status === 500
      ) {
        if (error?.response?.data?.errors) {
          setNotification({
            type: "error",
            message: error?.response?.data?.errors
          });
        } else {
          setNotification({
            type: "error",
            message: "Something went wrong. Please try again later."
          });
        }
      }
    }

    return error.response
      ? Promise.reject(error.response.data)
      : Promise.reject(error.message);
  }
);
