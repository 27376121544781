import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getExpenseTypes = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/expense-types`);
};

export const useExpenseTypes = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["expense-types", churchId],
    queryFn: () => getExpenseTypes({ churchId })
  });
};
