import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getCountries = () => {
  return axios.get(`/addresses/countries`);
};

export const useCountries = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["countries"],
    queryFn: getCountries,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
