import styled from "@emotion/styled";
import { X } from "@phosphor-icons/react";
import { Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReconciles } from "../api";
import { ChequeReconcileModal } from "../components";
import { useRevenueStore } from "../store";

const Container = styled.div`
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f5f9;
  padding: 18px 20px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;

  th {
    padding-block: 16px;
    background: #e6f7f8;
  }

  & td {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
  }
`;

export const ChequeReconcile = () => {
  const navigate = useNavigate();
  const openChequeReconcileModal = useRevenueStore(
    state => state.openChequeReconcileModal
  );
  const openUpdateChequeModal = useRevenueStore(
    state => state.openUpdateChequeModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const reconcilesQuery = useReconciles({
    churchId: selectedChurch?.id
  });
  const [cheque, setCheque] = useState(null);

  return (
    <Container>
      <Notification />
      <ChequeReconcileModal data={cheque} />
      <Header>
        <Title>Undeposited cheques</Title>
        <CloseButton onClick={() => navigate(`/finances/temp/revenue`)}>
          <span>Close</span>
          <X size={16} />
        </CloseButton>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>Fullname</th>
            <th>Transaction Number</th>
            <th>Type</th>
            <th>Cheque Number</th>
            <th>Bank</th>
            <th>Amount</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {reconcilesQuery.data?.result?.map(reconcile => (
            <tr key={reconcile.id} onClick={openUpdateChequeModal}>
              <td>
                {reconcile.full_name ||
                  `${reconcile.member?.first_name} ${reconcile.member?.last_name}`}
              </td>
              <td>{reconcile.transaction_number}</td>
              <td>{reconcile.revenue_type?.name}</td>
              <td>{reconcile.cheque_number}</td>
              <td>{reconcile.bank_name}</td>
              <td>{reconcile.amount} ETB</td>
              <td>
                {moment(reconcile.recorded_date_time).format("DD-MM-YYYY")}
              </td>
              <td>
                <Button
                  flavor="outlined"
                  style={{
                    fontSize: "12px"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    setCheque(reconcile);
                    openChequeReconcileModal();
                  }}
                >
                  Confirm
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
