import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Stack as StackIcon } from "@phosphor-icons/react";
import { Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { StatInfoCard } from "features/dashboard";
import { useTranslation } from "react-i18next";
import { useAssetsPaginated } from "../api";
import { AddAssetModal, AssetsTable, AssetsTableHeader } from "../components";
import { useAssetsStore } from "../store";

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-block: 1rem 2rem;
`;

export const Assets = () => {
  const openAddAssetModal = useAssetsStore(state => state.openAddAssetModal);
  const addAssetModalOpen = useAssetsStore(state => state.addAssetModalOpen);
  const closeAddAssetModal = useAssetsStore(state => state.closeAddAssetModal);

  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const { t } = useTranslation();

  const assetsPaginatedQuery = useAssetsPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10
  });

  return (
    <Stack>
      <StatGrid>
        <StatInfoCard
          title={t("Asset.Asset.TotalAsset")}
          stat={assetsPaginatedQuery.data?.result?.total_assets ?? 0}
          icon={<StackIcon size={24} />}
          color="rgba(0, 176, 185, 1)"
          background="#82DBD899"
        />
        <StatInfoCard
          title={t("Asset.Asset.TotalValueOfAsset")}
          stat={assetsPaginatedQuery.data?.result?.total_asset_value ?? 0}
          icon={<StackIcon size={24} />}
          color="rgba(0, 176, 185, 1)"
          background="#82DBD899"
        />
        <StatInfoCard
          title={t("Asset.Asset.TotalDisposed")}
          stat={assetsPaginatedQuery.data?.result?.total_disposed_assets ?? 0}
          icon={<StackIcon size={24} />}
          color="rgba(0, 176, 185, 1)"
          background="#82DBD899"
        />
      </StatGrid>
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          alignSelf="flex-end"
          spacing={1}
        >
          <Button onClick={openAddAssetModal}>
            {t("Asset.Asset.RegisterAsset")}
          </Button>
          {/* <Button flavor="outlined" disabled>
            Import Assets
          </Button>
          <Button flavor="outlined" disabled>
            Export Assets
          </Button> */}
        </Stack>
        <AssetsTableHeader tabValue={0} setTabValue={() => {}} />
        <AssetsTable />
      </Stack>
      <AddAssetModal open={addAssetModalOpen} onClose={closeAddAssetModal} />
      <Notification />
    </Stack>
  );
};
