import { Typography } from "@mui/material";

export function ErrorMessage({ messages = [] }) {
  const errorsView = messages.map(errorMessage => (
    <Typography variant="caption" component="span" key={errorMessage}>
      {errorMessage}
    </Typography>
  ));

  return errorsView;
}
