import styled from "@emotion/styled";
import photo from "assets/images/Photo.png";
// import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Card = styled.div`
  max-width: 100%;
  min-height: 233px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
`;

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 132px;
`;

const CardContent = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: white;
  flex: 1;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: column;

  span:first-of-type {
    font-size: 22px;
  }

  span:last-of-type {
    font-size: 24px;
    font-weight: 700;
  }
`;

// const CardTag = styled.div`
//   padding: 5px 10px;
//   background-color: #a8f8ff80;
//   border-radius: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 15px;
//   font-weight: 500;
// `;

export const TeamCard = ({ teamData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card onClick={() => navigate(`/teams/${teamData.id}`)}>
      <CardImage src={photo} alt="Image" />
      <CardContent>
        <CardTitle>
          <span>{teamData?.name}</span>
          <span>
            {teamData?.members_count} {t("teams.teams.Members")}
          </span>
        </CardTitle>
        {/* <CardTag>{_.capitalize(teamData?.team_type?.name)}</CardTag> */}
      </CardContent>
    </Card>
  );
};
