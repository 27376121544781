import { useChurchStore } from "features/churches";
import { useParams } from "react-router-dom";
import { useMessages } from "../api";
import {
  AllMessagesPanel,
  ChatPanel,
  DeleteMessageNote,
  MessageList
} from "../components";
import { useStoredMessagesStore } from "../store";

export const AllMessages = () => {
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const storedMessages = useStoredMessagesStore(state => state.storedMessages);
  const storeMessages = useStoredMessagesStore(state => state.storeMessages);
  const messagesQuery = useMessages({
    churchId: selectedChurch?.id
  });

  if (id) {
    storeMessages(messagesQuery?.data?.result);

    return (
      <>
        <MessageList title="All Messages" messages={storedMessages} />
        <ChatPanel />
      </>
    );
  }

  return (
    <>
      <DeleteMessageNote />
      <AllMessagesPanel />
    </>
  );
};
