import { Avatar, Chip, Popover, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DotsThreeVertical } from "@phosphor-icons/react";
import { DeleteModal, Toggle } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useDeleteUser, useUpdateUserStatus, useUsersPaginated } from "../api";
import { UsersTableDropdownMenu } from "./UsersTableDropdownMenu";

export const UsersTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState("");
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const deleteMutation = useDeleteUser();
  const auth = useAuthUser();
  const { t } = useTranslation();
  const userStatusMutation = useUpdateUserStatus();

  const usersPaginatedQuery = useUsersPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize
  });

  const handleClick = (event, id, name) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setUserId(id);
    setUserName(name);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: t("systemAdmins.systemAdmins.Name"),
      minWidth: 280,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row?.image_url}`}
              alt={row?.first_name}
              sx={{ border: "2px solid", borderColor: "primary.main" }}
            />
            <Typography>
              {row?.first_name} {row?.last_name}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "role",
      headerName: t("systemAdmins.systemAdmins.Role"),
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: ({ row }) => {
        return <Chip label={row?.role?.name} color="primary" />;
      }
    },
    {
      field: "status",
      headerName: t("dashboard.systemUser.LastLoggedIn"),
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.latest_user_login
              ? moment(row?.latest_user_login?.logged_in_at).fromNow()
              : "NA"}
          </Typography>
        );
      }
    },
    {
      field: "phone",
      headerName: t("systemAdmins.systemAdmins.Phone"),
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1
    },
    {
      field: "email",
      headerName: t("systemAdmins.systemAdmins.Email"),
      headerAlign: "center",
      align: "center",
      flex: 2
    },
    {
      field: "active",
      headerName: "Active",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ row }) => {
        if (auth().id === row.id) {
          return null;
        }

        return (
          <Toggle
            checked={Boolean(row?.account_users?.[0]?.status)}
            onChange={e => {
              const status = e.target.checked;

              userStatusMutation.mutate({
                churchId: selectedChurch?.id,
                userId: row?.id,
                status
              });
            }}
          />
        );
      },
      flex: 2
    },
    {
      field: "operation",
      headerName: t("systemAdmins.systemAdmins.Operations"),
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ row }) => {
        if (auth().id === row.id) {
          return null;
        }

        return (
          <DotsThreeVertical
            size={24}
            onClick={e => handleClick(e, row.id, row.first_name)}
            style={{ cursor: "pointer" }}
          />
        );
      },
      flex: 2
    }
  ];

  return (
    <>
      <DeleteModal
        name={userName}
        onDelete={() => {
          deleteMutation.mutate({
            churchId: selectedChurch?.id,
            userId: userId
          });
        }}
      />
      <DataGrid
        autoHeight
        rows={usersPaginatedQuery?.data?.result?.data || []}
        rowCount={usersPaginatedQuery?.data?.result?.total || 0}
        loading={usersPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <UsersTableDropdownMenu userId={userId} />
      </Popover>
    </>
  );
};
