import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useEmployeesStore = create(
  devtools(
    set => ({
      addEmployeeModalOpen: false,
      addEmployeeFormActiveStep: 1,
      openAddEmployeeModal: () => set({ addEmployeeModalOpen: true }),
      closeAddEmployeeModal: () => set({ addEmployeeModalOpen: false }),
      setAddEmployeeFormActiveStep: step =>
        set({ addEmployeeFormActiveStep: step })
    }),
    { name: "employees-store" }
  )
);
