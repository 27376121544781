import { ArrowBack, KeyboardArrowDown, Menu } from "@mui/icons-material";
import {
  Avatar,
  Button,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { AccountMenu, LanguageSelector } from "components/UI";
import { IMAGE_URL } from "config";
import { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";

export function Header({ hasBack = true }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const headerTitle = useCentralStore(state => state.headerTitle);
  const headerHasBackground = useCentralStore(
    state => state.headerHasBackground
  );
  const openDrawer = useCentralStore(state => state.openDrawer);
  const navigate = useNavigate();
  const auth = useAuthUser();

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        color: "neutral.contrastText",
        backgroundColor: `${headerHasBackground ? "neutral.main" : ""}`,
        py: {
          xs: 1,
          md: 2
        },
        px: {
          xs: 2,
          md: 4
        }
      }}
    >
      <Stack direction="row" alignItems="center">
        {hasBack && (
          <IconButton aria-label="back" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
        <IconButton
          aria-label="menu"
          onClick={openDrawer}
          sx={{ display: { xs: "inline-flex", md: "none" } }}
        >
          <Menu />
        </IconButton>
        <Typography sx={{ fontWeight: 500 }}>{headerTitle}</Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={{
          xs: 1,
          md: 3
        }}
        alignItems="center"
      >
        {/* <NotificationsNone /> */}
        <LanguageSelector />
        <Typography
          sx={{ color: "#000", display: { xs: "none", md: "block" } }}
        >
          {auth()?.first_name} {auth()?.last_name}
        </Typography>
        <Button
          onClick={handleClick}
          size="small"
          endIcon={<KeyboardArrowDown />}
        >
          <Avatar
            src={auth()?.image_url ? `${IMAGE_URL}/${auth()?.image_url}` : ""}
            alt={auth()?.first_name}
            sx={{ border: "2px solid", borderColor: "primary.main" }}
          />
        </Button>
      </Stack>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2,
          sx: {
            borderRadius: 4,
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <AccountMenu />
      </Popover>
    </Stack>
  );
}
