import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import _ from "lodash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectWrapper = styled.select`
  font-family: inherit;
  font-size: inherit;
  padding: 0.4rem;
  outline: none;
  border: 2px solid ${props => (props.bg ? "transparent" : "#3cadb7")};
  border-radius: 8px;
  background: ${props => (props.bg ? "#DFF5F5" : "white")};
  color: ${props => (props.bg ? "#3cadb7" : "#000000")};

  :disabled {
    cursor: not-allowed;
  }
`;

const HelperText = styled.span`
  padding: 0 0.75rem;
  font-size: 0.75rem;
  color: ${props =>
    props.error ? props.theme.palette.error.main : "darkgray"};
`;

export const Select = ({
  label,
  name,
  isLabelBold,
  isLabelEmpty,
  options,
  error,
  helperText,
  register,
  hasNoBg = false,
  required,
  ...rest
}) => {
  return (
    <Container>
      {!isLabelEmpty && (
        <Typography
          variant="subtitle2"
          component="label"
          htmlFor={name}
          sx={{
            fontWeight: isLabelBold && 700,
            "&:after": {
              content: required ? '" *"' : '""',
              color: "red"
            }
          }}
        >
          {label}
        </Typography>
      )}
      <SelectWrapper
        defaultValue=""
        {...register(name)}
        bg={!hasNoBg}
        {...rest}
      >
        <option value="" disabled hidden></option>
        {options.map(({ optionLabel, value, ...rest }) => (
          <option key={value} value={value} {...rest}>
            {optionLabel}
          </option>
        ))}
      </SelectWrapper>
      {!!helperText && (
        <HelperText error={error}>{_.capitalize(helperText)}</HelperText>
      )}
    </Container>
  );
};
