import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const linkSpouse = ({ churchId, memberId, spouseId }) => {
  return axios.post(
    `/churches/${churchId}/members/${memberId}/link-spouse/${spouseId}`,
    JSON.stringify({})
  );
};

export const useLinkSpouse = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeSpouseMatchedModal =
    useMembersStore.getState().closeSpouseMatchedModal;
  const setMatchedSpouseData = useMembersStore.getState().setMatchedSpouseData;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("members");
      queryClient.invalidateQueries("member");

      setNotification({
        type: "success",
        message: "Spouse relationship approved and linked successfully!"
      });

      setMatchedSpouseData(null);
      closeSpouseMatchedModal();
    },
    ...config,
    mutationFn: linkSpouse
  });
};
