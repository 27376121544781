import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Header } from "components/UI";
import { Outlet } from "react-router-dom";
import { UserProfileSidebar } from "./UserProfileSidebar";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Sidebar = styled.div`
  flex-basis: 25%;
  display: none;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    display: block;
  }`}
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    flex-basis: 50%;
  }`}
`;

export function UserProfileLayout() {
  return (
    <Container>
      <Sidebar>
        <UserProfileSidebar />
      </Sidebar>
      <Content>
        <Header />
        <Box
          sx={{
            pb: 6,
            px: 4
          }}
        >
          <Outlet />
        </Box>
      </Content>
    </Container>
  );
}
