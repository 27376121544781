import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const deleteAsset = ({ churchId, assetId }) => {
  return axios.delete(`/churches/${churchId}/assets/${assetId}`);
};

export const useDeleteAsset = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["assets"]);

      setNotification({
        type: "success",
        message: "Asset deleted successfully!"
      });
    },
    ...config,
    mutationFn: deleteAsset
  });
};
