import { Chat, Edit, TouchApp, Visibility } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useMessagesStore } from "features/messages";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMembersPaginated } from "../api";
import { FILTER_OPERATIONS, MEMBER_STATES } from "../constants";
import { useMembersStore } from "../stores";
import { extraMemberColumns } from "../utils/memberColumns";

export const MembersTable = ({
  isFilterMode = false,
  selectedMembers,
  setSelectedMembers
}) => {
  const setMemberPreviewData = useMembersStore(
    state => state.setMemberPreviewData
  );
  const setFullMemberData = useMembersStore(state => state.setFullMemberData);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const timer = useRef();
  const closeMemberPreview = useMembersStore(state => state.closeMemberPreview);
  const { t } = useTranslation();
  const addRecipient = useMessagesStore(state => state.addRecipient);
  const addMember = useMessagesStore(state => state.addMember);
  const removeMember = useMessagesStore(state => state.removeMember);
  const clearRecipients = useMessagesStore(state => state.clearRecipients);
  const openAddTeamModal = useMembersStore(state => state.openAddTeamModal);
  const setMemberToAssignTeam = useMembersStore(
    state => state.setMemberToAssignTeam
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const queries = useMembersStore(state => state.queries);
  const setQueries = useMembersStore(state => state.setQueries);
  const columnsVisible = useMembersStore(state => state.columnsVisible);

  const membersPaginatedQuery = useMembersPaginated({
    churchId: selectedChurch?.id,
    page: page,
    pageSize: pageSize,
    queries: queries
  });

  useEffect(() => {
    return () => {
      closeMemberPreview();
    };
  }, []);

  const columns = [
    {
      field: "first_name",
      headerName: t("members.membersTable.name"),
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={`${IMAGE_URL}/${row.profile_image_url}`}
              alt={_.capitalize(row.first_name)}
              sx={{ outline: "2px solid", outlineColor: "primary.main" }}
            />
            <Typography>
              {row.first_name} {row.father_name} {row.last_name}
            </Typography>
          </Stack>
        );
      },
      flex: 2,
      minWidth: 120
    },
    {
      field: "gender",
      headerName: t("members.membersTable.gender"),
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => {
        return (
          <Typography variant="body2" textTransform="capitalize">
            {row.gender}
          </Typography>
        );
      }
    },
    {
      field: "team",
      headerName: t("members.membersTable.team"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <AvatarGroup max={4}>
            {row?.teams?.map(team => (
              <Avatar
                sx={{ width: 36, height: 36 }}
                key={team?.id}
                src={`${IMAGE_URL}/${team?.image_url}`}
                alt={team?.name}
              />
            ))}
          </AvatarGroup>
        );
      },
      flex: 1,
      minWidth: 120
    },
    {
      field: "phone",
      headerName: t("members.membersTable.phone"),
      flex: 1,
      minWidth: 120
    },
    {
      field: "email",
      headerName: t("members.membersTable.email"),
      flex: 1.5,
      minWidth: 120
    },
    {
      field: "operation",
      headerName: t("members.membersTable.operation"),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                clearRecipients();
                removeMember(row.id);
                addRecipient(
                  `${row.id}_${row.first_name}_${row.last_name}`,
                  row
                );
                addMember(row.id, { id: row.id });
                navigate("/messages");
              }}
            >
              <Chat />
            </IconButton>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setFullMemberData(row);
                navigate(`/members/${row.id}/update`);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setMemberToAssignTeam(row.id);
                openAddTeamModal();
              }}
            >
              <TouchApp />
            </IconButton>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setFullMemberData(row);
                navigate(`/members/${row.id}`);
              }}
            >
              <Visibility />
            </IconButton>
          </Stack>
        );
      },
      flex: 1.5,
      minWidth: 120
    },
    ...extraMemberColumns
  ];

  const handleSortModelChange = async sortModel => {
    const field = sortModel[0]?.field;
    const sort = sortModel[0]?.sort;

    if (field || sort) {
      setQueries({
        ...queries,
        sort: {
          [field]: sort
        }
      });
    } else {
      setQueries({
        filter: {
          status: [
            {
              operator: FILTER_OPERATIONS.EQUAL,
              value: MEMBER_STATES.ACTIVE
            }
          ]
        }
      });
    }
  };

  return (
    <>
      <DataGrid
        autoHeight
        rows={membersPaginatedQuery?.data?.result?.data || []}
        rowCount={membersPaginatedQuery?.data?.result?.total || 0}
        loading={membersPaginatedQuery?.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection={isFilterMode}
        onSelectionModelChange={selectionModel => {
          const members = membersPaginatedQuery?.data?.result?.data;
          const selectedIDs = new Set(selectionModel);
          const selectedRows = members.filter(member =>
            selectedIDs.has(member.id)
          );
          setSelectedMembers(selectedRows);
        }}
        selectionModel={selectedMembers?.map(
          selectedMember => selectedMember.id
        )}
        onRowClick={({ row }, event) => {
          clearTimeout(timer.current);

          if (event.detail === 1) {
            timer.current = setTimeout(() => setMemberPreviewData(row), 500);
          }

          if (event.detail === 2) {
            setFullMemberData(row);
            navigate(`/members/${row.id}`);
          }
        }}
        paginationMode="server"
        sortingMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage + 1)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
        columnVisibilityModel={columnsVisible}
      />
    </>
  );
};
