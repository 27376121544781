import styled from "@emotion/styled";
import { CancelOutlined, Edit } from "@mui/icons-material";
import { Avatar, Button, IconButton, Stack, Typography } from "@mui/material";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChat } from "react-icons/hi";
import { getEthiopianDate } from "utils/getEthiopianDate";
import { getGregorianDate } from "utils/getGregorianDate";
import { exportProfile, printProfile } from "../api";
import { useMembersStore } from "../stores";

const LinkButton = styled.button`
  border: none;
  outline: none;
  background: none;
  color: white;
  border-bottom: 1px solid white;
  cursor: pointer;
  align-self: center;
`;

export function PartnerPreviewCard() {
  const partnerPreviewData = useMembersStore(state => state.partnerPreviewData);
  const closePartnerPreview = useMembersStore(
    state => state.closePartnerPreview
  );
  const openUpdatePartnerModal = useMembersStore(
    state => state.openUpdatePartnerModal
  );
  const { t } = useTranslation();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [datePreview, setDatePreview] = useState("amh");

  const handleExport = () => {
    exportProfile({
      type: "partner",
      churchId: selectedChurch?.id,
      id: partnerPreviewData?.id,
      format: "pdf",
      orientation: "landscape"
    });
  };

  const handlePrint = () => {
    printProfile({
      type: "partner",
      churchId: selectedChurch?.id,
      id: partnerPreviewData?.id,
      format: "pdf",
      orientation: "landscape"
    });
  };

  return (
    <Stack
      alignItems="stretch"
      sx={{
        px: 2,
        py: 6,
        position: "relative",
        background:
          "linear-gradient(193.89deg, #82DBD8 -5.16%, #3BACB6 97.91%)",
        borderRadius: 1,
        maxWidth: 350
      }}
      spacing={2}
    >
      <IconButton
        aria-label="settings"
        sx={{ position: "absolute", top: 0, right: 2 }}
        color="error"
        onClick={closePartnerPreview}
      >
        <CancelOutlined />
      </IconButton>
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Avatar
          sx={{
            width: "160px",
            height: "160px",
            border: "6px solid",
            borderColor: "white"
          }}
          src={`${IMAGE_URL}/${partnerPreviewData?.image_url}`}
          alt={partnerPreviewData?.first_name}
        />
        <Typography variant="h6" color="white" textAlign="center">
          {partnerPreviewData?.first_name} {partnerPreviewData?.father_name}{" "}
          {partnerPreviewData?.last_name}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
          {t("form.common.gender")}
        </Typography>
        <Typography variant="subtitle1">
          {partnerPreviewData?.gender}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography
          variant="subtitle1"
          color="primary.main"
          fontWeight={500}
          noWrap
        >
          {t("form.common.email")}
        </Typography>
        <Typography variant="subtitle1">{partnerPreviewData?.email}</Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
          {t("form.common.phone")}
        </Typography>
        <Typography variant="subtitle1">{partnerPreviewData?.phone}</Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
          {t("form.common.dateOfBirth")}
        </Typography>
        <Typography variant="subtitle1">
          {datePreview === "eng"
            ? getGregorianDate(partnerPreviewData?.birth_date)
            : getEthiopianDate(partnerPreviewData?.birth_date)}
        </Typography>
        <Typography
          onClick={() => {
            if (datePreview === "amh") setDatePreview("eng");
            if (datePreview === "eng") setDatePreview("amh");
          }}
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            cursor: "pointer",
            color: "primary.main",
            userSelect: "none"
          }}
        >
          {datePreview}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2, borderRadius: "10px" }}
      >
        <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
          {t("form.common.address")}
        </Typography>
        <Typography variant="subtitle1">
          {partnerPreviewData?.address}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Button
          variant="contained"
          size="large"
          sx={{
            borderRadius: "50px",
            backgroundColor: "white",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "white",
              color: "primary.main"
            },
            alignSelf: "center"
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <HiChat />{" "}
            <Typography variant="subtitle2">
              {t("members.partners.sendMessage")}
            </Typography>
          </Stack>
        </Button>
        <Button
          variant="contained"
          size="large"
          sx={{
            borderRadius: "50px",
            backgroundColor: "white",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "white",
              color: "primary.main"
            },
            alignSelf: "center"
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            onClick={openUpdatePartnerModal}
          >
            <Edit />{" "}
            <Typography variant="subtitle2">
              {t("members.partners.editProfile")}
            </Typography>
          </Stack>
        </Button>
        <LinkButton onClick={handleExport}>Export Profile</LinkButton>
        <LinkButton onClick={handlePrint}>Print Profile</LinkButton>
      </Stack>
    </Stack>
  );
}
