import styled from "@emotion/styled";
import { Group, Groups, LocalAtm, Sms } from "@mui/icons-material";
import { Box } from "@mui/material";
import ComingSoon from "assets/images/coming-soon.png";
import { Alert } from "components/UI";
import { SectionBar, StatInfoCard } from "features/dashboard";
import { useEffect } from "react";
import {
  BsArrowDownLeftCircle,
  BsArrowUpRightCircle,
  BsCash,
  BsFillBarChartFill
} from "react-icons/bs";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";

const informations = [
  {
    icon: <BsArrowDownLeftCircle />,
    title: "Revenue",
    points: [
      "Sales",
      "Tithe",
      "Special Gift",
      "Donation",
      "Project contribution"
    ],
    color: "#0DBF3F"
  },
  {
    icon: <BsArrowUpRightCircle />,
    title: "Expense",
    points: ["Payroll", "PT Cash", "Utility Fees"],
    color: "#BD54E1"
  },
  {
    icon: <BsCash />,
    title: "Budget",
    points: [
      "Bank Integration",
      "Ministry Budget",
      "Leadership Budget",
      "Teams Budget",
      "Total church Budget"
    ],
    color: "#54E1D0"
  },
  {
    icon: <BsFillBarChartFill />,
    title: "Finance Report",
    points: [
      "Custom Finance Report",
      "3 Month Finance Report",
      "6 Month  Finance Report",
      "Yearly Finance report"
    ],
    color: "#547CE1"
  }
];

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
`;

const InfoCard = styled.div`
  padding: 18px 14px;
  background: #ffffff;
  border-bottom: 8px solid ${({ color }) => color};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ color }) => (color ? color : "#000000")};
  margin-bottom: 12px;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  color: ${({ color }) => (color ? color : "#000000")};
`;

const Point = styled.span`
  display: block;
  border-bottom: 1px solid #e6f7f8;
  padding-bottom: 4px;
  padding-inline: 4px;
`;

const Banner = styled.img``;

export const Finances = () => {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);

  useTitle("Finances");

  useEffect(() => {
    setHeaderTitle("Finances");

    return () => setHeaderTitle("");
  }, []);

  return (
    <Container>
      <Box>
        <Alert message={"dashboard.common.demoMessage"} />
      </Box>
      <StatGrid>
        <StatInfoCard
          title="Total Members"
          stat="236,200"
          icon={<Group />}
          color="#134077"
          background="#13407766"
        />
        <StatInfoCard
          title="Total Teams"
          stat="68"
          icon={<Groups />}
          color="#65A6A0"
          background="#65A6A066"
        />
        <StatInfoCard
          title="SMS Sent"
          stat="236,200"
          icon={<Sms />}
          color="#018ABE"
          background="#018ABE66"
        />
        <StatInfoCard
          title="Total Balance"
          stat="24,400"
          icon={<Sms />}
          color="#7FA457"
          background="#7FA45766"
        />
        <StatInfoCard
          title="System Users"
          stat="13"
          icon={<Sms />}
          color="#E39337"
          background="#E3933766"
        />
      </StatGrid>
      <SectionBar icon={<LocalAtm />} title="Finance" color="#7FA457" />
      <InfoGrid>
        {informations.map(information => (
          <InfoCard key={information.title} color={information.color}>
            <Header color={information.color}>
              {information.icon}
              <Title color={information.color}>{information.title}</Title>
            </Header>
            {information.points.map(point => (
              <Point key={point}>{point}</Point>
            ))}
          </InfoCard>
        ))}
      </InfoGrid>
      <Banner src={ComingSoon} alt="Coming Soon" />
    </Container>
  );
};
