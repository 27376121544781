import { has } from "lodash";

export const convertObjectToFormData = object => {
  const formData = new FormData();

  for (const key in object) {
    if (has(object, key)) {
      formData.append(key, object[key]);
    }
  }

  return formData;
};
