import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const confirmReconcileCheque = ({ churchId, chequeData, chequeId }) => {
  return axios.patch(
    `/churches/${churchId}/revenue-approve/${chequeId}`,
    chequeData
  );
};

export const useConfirmReconcileCheque = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeChequeReconcileModal =
    useRevenueStore.getState().closeChequeReconcileModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["reconciles"]);

      setNotification({
        type: "success",
        message: "Cheque reconciled successfully!"
      });

      closeChequeReconcileModal();
    },
    ...config,
    mutationFn: confirmReconcileCheque
  });
};
