import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { MainSidebar, MobileDrawer } from "components/Sidebars";
import { Header, MaintenanceBanner } from "components/UI";
import { useChurchStore } from "features/churches";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Sidebar = styled.div`
  flex-basis: 18%;
  display: none;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    display: block;
  }`}
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    flex-basis: 50%;
  }`}
`;

function MainLayout({ sidebar = <MainSidebar />, hasBack = true }) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const isMaintenanceMode = false;

  const exceptionURL = "/i3iwz2xNLNF8frehczgD";

  useEffect(() => {
    if (!selectedChurch && location.pathname !== exceptionURL) {
      navigate("/churches");
    }
  }, [selectedChurch, location]);

  return (
    <Container>
      {isMaintenanceMode && <MaintenanceBanner />}
      <MobileDrawer />
      <Sidebar>{sidebar}</Sidebar>
      <Content>
        <Header hasBack={hasBack} />
        <Box
          sx={{
            pb: 6,
            px: 4
          }}
        >
          <Outlet />
        </Box>
      </Content>
    </Container>
  );
}

export default MainLayout;
