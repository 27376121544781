import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const createRevenueType = ({ churchId, revenueTypeData }) => {
  return axios.post(`/churches/${churchId}/revenue-types`, revenueTypeData);
};

export const useCreateRevenueType = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateRevenueTypeModal =
    useRevenueStore.getState().closeCreateRevenueTypeModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("revenue-types");
      setNotification({
        type: "success",
        message: "Revenue type created successfully!"
      });
      closeCreateRevenueTypeModal();
    },
    ...config,
    mutationFn: createRevenueType
  });
};
