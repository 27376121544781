import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useUsersStore = create(
  devtools(
    set => ({
      usersInviteModalOpen: false,
      createRoleModalOpen: false,
      openUsersInviteModal: () => set({ usersInviteModalOpen: true }),
      closeUsersInviteModal: () => set({ usersInviteModalOpen: false }),
      openCreateRoleModal: () => set({ createRoleModalOpen: true }),
      closeCreateRoleModal: () => set({ createRoleModalOpen: false })
    }),
    { name: "users-store" }
  )
);
