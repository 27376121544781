import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useChartOfAccounts,
  useCreateRequisition,
  useExpenseStore,
  useExpenseTypes
} from "features/finances";
import { useTeams } from "features/teams";
import { useUsers } from "features/users";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  flex: 1;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
  flex: 1;
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  margin-right: 16px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

const requisitionSchema = yup.object({
  name: yup.string(),
  department: yup.string(),
  account: yup.string(),
  dueDate: yup.string(),
  reason: yup.string(),
  expenseType: yup.string()
});

export function CreateRequisitionModal() {
  const createRequisitionOpen = useExpenseStore(
    state => state.createRequisitionOpen
  );
  const closeCreateRequisitionModal = useExpenseStore(
    state => state.closeCreateRequisitionModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const chartOfAccountsQuery = useChartOfAccounts({
    churchId: selectedChurch?.id
  });
  const usersQuery = useUsers({
    churchId: selectedChurch?.id
  });
  const expenseTypesQuery = useExpenseTypes({
    churchId: selectedChurch?.id
  });
  const teamsQuery = useTeams({
    churchId: selectedChurch?.id
  });
  const [isMember, setIsMember] = useState(false);
  const createRequisitionMutation = useCreateRequisition();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: "",
      department: "",
      account: "",
      dueDate: "",
      reason: "",
      expenseType: ""
    },
    resolver: yupResolver(requisitionSchema)
  });

  const onSubmit = values => {
    createRequisitionMutation.mutate({
      churchId: selectedChurch?.id,
      requisitionData: {
        amount: values.amount,
        chart_of_account_id: values.account,
        due_date: values.dueDate,
        reason: values.reason,
        expense_type_id: values.expenseType,
        user_id: values.name,
        team_id: values.department
      }
    });
  };

  return (
    <BaseModal
      title="Create requisition"
      isOpen={createRequisitionOpen}
      onClose={closeCreateRequisitionModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeCreateRequisitionModal}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Request
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="name">Requestor Full Name</Label>
        <Stack direction="row" alignItems="center">
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={e => {
                setIsMember(e.target.checked);
                setValue("name", "");
              }}
            />
            Is Member
          </CheckboxLabel>
          {isMember && (
            <Select id="fullName" {...register("name")}>
              {usersQuery.data?.result?.map(user => (
                <option key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </Select>
          )}
          {!isMember && <Input id="fullName" {...register("name")} />}
        </Stack>
      </Field>
      <Field>
        <Label htmlFor="department">Department</Label>
        <Select id="department" {...register("department")}>
          {teamsQuery.data?.result?.map(team => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </Select>
      </Field>
      <Field>
        <Label htmlFor="account">Account</Label>
        <Select id="account" {...register("account")}>
          {chartOfAccountsQuery.data?.result?.map(chartOfAccount => (
            <option key={chartOfAccount.id} value={chartOfAccount.id}>
              {chartOfAccount.account_name}
            </option>
          ))}
        </Select>
      </Field>
      <Stack direction="row" spacing={2}>
        <Field>
          <Label htmlFor="amount">Amount</Label>
          <Input id="amount" type="number" {...register("amount")} />
        </Field>
        <Field>
          <Label htmlFor="dueDate">Due date</Label>
          <Input id="dueDate" type="date" {...register("dueDate")} />
        </Field>
      </Stack>
      <Field>
        <Label htmlFor="reason">Reason</Label>
        <Input id="reason" {...register("reason")} />
      </Field>
      <Field>
        <Label htmlFor="expenseType">Expense Type</Label>
        <Select id="expenseType" {...register("expenseType")}>
          {expenseTypesQuery.data?.result?.map(expenseType => (
            <option key={expenseType.id} value={expenseType.id}>
              {expenseType.name}
            </option>
          ))}
        </Select>
      </Field>
    </BaseModal>
  );
}
