import { Grid, Typography } from "@mui/material";
import { Radio, Select, TextField } from "components/UI";
import { useTranslation } from "react-i18next";

export const JobStatusSection = ({ register, errors, watch }) => {
  const { t } = useTranslation();

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("addMembers.step2.JobStatus")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={12}>
          <Radio
            label={t("addMembers.step2.FullTimeJob")}
            name="job_status"
            buttons={[
              {
                radioLabel: t("addMembers.step2.Student"),
                value: "student",
                id: "student"
              },
              {
                radioLabel: t("addMembers.step2.Government"),
                value: "government",
                id: "government"
              },
              {
                radioLabel: t("addMembers.step2.NonGovernment"),
                value: "non-government",
                id: "non-government"
              },
              {
                radioLabel: t("addMembers.step2.PrivateCompany"),
                value: "private-company",
                id: "private-company"
              },
              {
                radioLabel: t("addMembers.step2.Private"),
                value: "private",
                id: "private"
              },
              {
                radioLabel: t("addMembers.step2.Unemployed"),
                value: "unemployed",
                id: "unemployed"
              },
              {
                radioLabel: t("addMembers.step2.Housewife"),
                value: "housewife",
                id: "housewife"
              },
              {
                radioLabel: t("addMembers.step2.Retired"),
                value: "retired",
                id: "retired"
              }
            ]}
            row={false}
            register={register}
            error={!!errors.job_status?.message}
            helperText={errors.job_status?.message}
            required
          />
        </Grid>
        {!["unemployed", "student", "housewife"].includes(
          watch("job_status")
        ) && (
          <>
            <Grid item xs={4}>
              <TextField
                label={t("addMembers.step2.Profession")}
                name="profession"
                error={!!errors.profession?.message}
                helperText={errors.profession?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("addMembers.step2.Workplace")}
                name="work_place"
                error={!!errors.work_place?.message}
                helperText={errors.work_place?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={4}>
              {watch("job_status") !== "student" && (
                <Select
                  label={t("addMembers.step2.MonthlyIncomeRange")}
                  name="monthly_income"
                  options={[
                    {
                      optionLabel: "< 600 ETB",
                      value: "< 600 ETB"
                    },
                    {
                      optionLabel: "601 - 1,650 ETB",
                      value: "601 - 1,650 ETB"
                    },
                    {
                      optionLabel: "1,651 - 3,200 ETB",
                      value: "1,651 - 3,200 ETB"
                    },
                    {
                      optionLabel: "3,201 - 5,250 ETB",
                      value: "3,201 - 5,250 ETB"
                    },
                    {
                      optionLabel: "5,251 - 7,800 ETB",
                      value: "5,251 - 7,800 ETB"
                    },
                    {
                      optionLabel: "7,801 - 10,900 ETB",
                      value: "7,801 - 10,900 ETB"
                    },
                    {
                      optionLabel: "10,901 - 15,000 ETB",
                      value: "10,901 - 15,000 ETB"
                    },
                    {
                      optionLabel: "15,001 - 25,000 ETB",
                      value: "15,001 - 25,000 ETB"
                    },
                    {
                      optionLabel: "25,001 - 35,000 ETB",
                      value: "25,001 - 35,000 ETB"
                    },
                    {
                      optionLabel: "35,001 - 45,000 ETB",
                      value: "35,001 - 45,000 ETB"
                    },
                    {
                      optionLabel: "45,001 - 60,000 ETB",
                      value: "45,001 - 60,000 ETB"
                    },
                    {
                      optionLabel: "60,001 - 75,000 ETB",
                      value: "60,001 - 75,000 ETB"
                    },
                    {
                      optionLabel: "> 75,000 ETB",
                      value: "> 75,000 ETB"
                    }
                  ]}
                  error={!!errors.monthly_income?.message}
                  helperText={errors.monthly_income?.message}
                  register={register}
                />
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            label={t("addMembers.step2.PartTimeJob")}
            name="custom_job_status"
            error={!!errors.custom_job_status?.message}
            helperText={errors.custom_job_status?.message}
            register={register}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
