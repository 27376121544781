import { useMutation } from "@tanstack/react-query";
import Cookie from "js-cookie";
import { axios } from "lib/axios";
import moment from "moment";
import { useSignIn } from "react-auth-kit";
import { useCentralStore } from "stores";

export const updateProfile = ({ userData }) => {
  return axios.post(`/user/update?_method=PATCH`, userData);
};

export const useUpdateProfile = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const signIn = useSignIn();

  return useMutation({
    onSuccess: response => {
      setNotification({
        type: "success",
        message: "Profile updated successfully!"
      });

      signIn({
        token: Cookie.get("_auth"),
        expiresIn: moment(Cookie.get("_auth_storage")).minutes(),
        tokenType: "Bearer",
        authState: response.result
      });
    },
    onError: error => {
      console.log(error);
    },
    ...config,
    mutationFn: updateProfile
  });
};
