import axios from "axios";
import { API_URL } from "config";
import fileDownload from "js-file-download";
import moment from "moment";
import { useCentralStore } from "stores";
import { getToken } from "utils/getToken";

export const exportService = ({ churchId, teamId, isResigned = 0, format }) => {
  const exportFormat = format === "xlsx" ? "excel" : "pdf";

  axios({
    method: "get",
    url: `${API_URL}/churches/${churchId}/teams/${teamId}/members-export?is_resigned=${isResigned}&orientation=portrait&format=${exportFormat}`,
    responseType: "blob",
    headers: {
      Authorization: getToken()
    }
  })
    .then(response => {
      fileDownload(
        new Blob([response.data]),
        `team_members_${moment().format("DD-MM-YYYY-hh-mm-A")}.${format}`
      );
    })
    .catch(error => {
      const setNotification = useCentralStore.getState().setNotification;

      if (
        error?.response?.data?.errors?.includes("unauthorized") ||
        error?.response?.status === 403
      ) {
        setNotification({
          type: "error",
          title: "Unauthorized",
          message: "You are unauthorized from performing this action."
        });
      } else {
        setNotification({ type: "error", message: "No team members found" });
      }
    });
};
