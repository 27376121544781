import styled from "@emotion/styled";
import {
  CheckCircle,
  CloudDownload,
  Error,
  Warning
} from "@mui/icons-material";
import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { Button, Loading } from "components/UI";
import { useChurchStore } from "features/churches";
import { useFileUpload } from "hooks";
import fileDownload from "js-file-download";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsInfoCircle, BsXSquare } from "react-icons/bs";
import { downloadTemplateService, importService } from "../api";
import { TAB_NAMES } from "../constants";
import { useMembersStore } from "../stores";

const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;

  .startIcon {
    min-height: 24px;
    min-width: 24px;
    background-color: #3186ea;
    display: grid;
    place-items: center;
    font-size: 14px;
    border-radius: 100%;
    color: #ffffff;
  }
`;

const DownloadButton = styled(Button)`
  color: #000;
  border-color: #000;
  padding-inline: 32px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 24px;
  background-color: #eeeeee;
  border-radius: 2px;
`;

const Progress = styled.div`
  width: ${({ progress }) => (progress ? `${progress}%` : "0%")};
  height: 100%;
  background-color: #3cadb7;
  transition: width 0.5s ease-in-out;
  border-radius: 2px;
`;

const CloseIcon = styled(BsXSquare)`
  color: #ffffff;
  height: 22px;
  width: 22px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
`;

const ErrorDetails = styled.details`
  height: 130px;
  overflow: auto;
  align-self: flex-start;
  width: 100%;
  cursor: pointer;

  summary {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.57;
  }
`;

const importServiceTabMapping = {
  [TAB_NAMES.MEMBERS]: "members",
  [TAB_NAMES.APPLICATION_REQUESTS]: "members",
  [TAB_NAMES.INACTIVE_MEMBERS]: "members",
  [TAB_NAMES.KIDS]: "children",
  [TAB_NAMES.PARTNERS]: "partners"
};

const STATUS = {
  DEFAULT: "default",
  UPLOADING: "uploading",
  PROCESSING: "processing",
  SUCCESS: "success",
  WARNING: "WARNING",
  FAILED: "failed"
};

export function ImportModal() {
  const importModalOpen = useMembersStore(state => state.importModalOpen);
  const closeImportModal = useMembersStore(state => state.closeImportModal);
  const currentTab = useMembersStore(state => state.currentTab);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [selectedFile, openFileDialog, resetFile] = useFileUpload();
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [messageDetails, setMessageDetails] = useState([]);
  const [status, setStatus] = useState(STATUS.FAILED);
  const { t } = useTranslation();

  // reset on modal close
  useEffect(() => {
    if (!importModalOpen) {
      setStatus(STATUS.DEFAULT);
      setMessage("");
      setMessageDetails([]);
      setFileUploadProgress(0);
      resetFile();
    }
  }, [importModalOpen]);

  useEffect(() => {
    const uploadFile = async () => {
      const formData = new FormData();

      if (selectedFile) {
        formData.append("file", selectedFile);

        try {
          setStatus(STATUS.UPLOADING);

          const response = await importService({
            type: importServiceTabMapping[currentTab],
            churchId: selectedChurch?.id,
            data: formData,
            onProgress: progress => {
              setFileUploadProgress(progress);

              if (progress === 100) {
                setStatus(STATUS.PROCESSING);
              }
            }
          });

          if (response.status) {
            setMessage(response.message);

            setStatus(STATUS.SUCCESS);

            setTimeout(() => {
              closeImportModal();
            }, [1000]);
          } else {
            setMessage(response.errors);

            setMessageDetails(response.result);

            if (isImportCompleteFailure(response.errors)) {
              setStatus(STATUS.FAILED);
            } else {
              setStatus(STATUS.WARNING);
            }
          }
        } catch (error) {
          setStatus(STATUS.FAILED);
        }
      }
    };

    uploadFile();
  }, [selectedFile]);

  return (
    <Modal open={importModalOpen} onClose={closeImportModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "550px",
          overflow: "hidden"
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          <span>{t("members.import.Title")}</span>
          <CloseIcon onClick={closeImportModal} />
        </Stack>
        <Stack
          sx={{
            backgroundColor: "white",
            minHeight: "330px"
          }}
          spacing={6}
        >
          {status === STATUS.DEFAULT && (
            <ImportDefaultState openFileDialog={openFileDialog} />
          )}
          {status === STATUS.UPLOADING && (
            <ImportUploadingState progress={fileUploadProgress} />
          )}
          {status === STATUS.PROCESSING && <ImportProcessingState />}
          {status === STATUS.SUCCESS && (
            <ImportSuccessState message={message} />
          )}
          {status === STATUS.WARNING && (
            <ImportWarningState
              message={message}
              messageDetails={messageDetails}
            />
          )}
          {status === STATUS.FAILED && (
            <ImportFailedState
              message={message}
              messageDetails={messageDetails}
            />
          )}
        </Stack>
      </Box>
    </Modal>
  );
}

const ImportDefaultState = ({ openFileDialog }) => {
  const closeImportModal = useMembersStore(state => state.closeImportModal);
  const currentTab = useMembersStore(state => state.currentTab);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [isDownloadPending, setIsDownloadPending] = useState(false);
  const [isDownloadDone, setIsDownloadDone] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const { t } = useTranslation();

  const handleDownloadTemplate = async () => {
    setIsDownloadPending(true);
    setIsDownloadDone(false);
    setIsDownloadError(false);

    try {
      const response = await downloadTemplateService({
        type: importServiceTabMapping[currentTab ?? TAB_NAMES.MEMBERS],
        churchId: selectedChurch?.id
      });

      fileDownload(
        response.data,
        `${
          importServiceTabMapping[currentTab ?? TAB_NAMES.MEMBERS]
        }-template.xlsx`
      );

      setIsDownloadDone(true);
      setIsDownloadError(false);
    } catch (error) {
      setIsDownloadError(true);
    }

    setIsDownloadPending(false);
  };

  return (
    <Stack
      alignItems="flex-end"
      justifyContent="space-between"
      flex={1}
      sx={{ pt: 8, pb: 2, px: 4 }}
    >
      <Stack spacing={2} alignItems="center">
        <InformationContainer>
          <span className="startIcon">
            <BsInfoCircle />
          </span>
          {t("members.import.Description")}
        </InformationContainer>
        <DownloadButton
          flavor="outlined"
          startIcon={
            isDownloadPending ? (
              <CircularProgress size={20} />
            ) : isDownloadDone ? (
              <CheckCircle color="success" />
            ) : (
              <CloudDownload />
            )
          }
          onClick={handleDownloadTemplate}
        >
          {isDownloadPending
            ? `${t("members.import.Downloading")}`
            : isDownloadDone
            ? `${t("members.import.Done")}`
            : `${t("members.import.Download")}`}
        </DownloadButton>
        {isDownloadError && (
          <Typography variant="caption" color="error">
            {t("members.import.DownloadError")}
          </Typography>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button flavor="tertiary" onClick={closeImportModal}>
          {t("members.import.Cancel")}
        </Button>
        <Button flavor="primary" onClick={openFileDialog}>
          {t("members.import.Upload")}
        </Button>
      </Stack>
    </Stack>
  );
};

const ImportUploadingState = ({ progress = 10 }) => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      flex={1}
      spacing={2}
      sx={{ px: 8 }}
    >
      <Typography>
        {t("members.import.Loading")} {progress}%
      </Typography>
      <ProgressContainer>
        <Progress progress={progress} />
      </ProgressContainer>
      <Typography variant="subtitle2">
        {t("members.import.Importing")}
      </Typography>
    </Stack>
  );
};

const ImportProcessingState = () => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      flex={1}
      spacing={2}
      sx={{ px: 8 }}
    >
      <Loading />
      <Typography variant="subtitle2">
        {t("members.import.Processing")}
      </Typography>
    </Stack>
  );
};

const ImportSuccessState = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      flex={1}
      spacing={2}
      sx={{ px: 8 }}
    >
      <CheckCircle color="success" />
      <Typography variant="h6" sx={{ color: "success.main" }}>
        {t("members.import.Success")}!
      </Typography>
      <Typography variant="subtitle2">{message}</Typography>
    </Stack>
  );
};

const ImportWarningState = ({ message, messageDetails }) => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      flex={1}
      spacing={2}
      sx={{ px: 8 }}
    >
      <Warning color="warning" />
      <Typography variant="h6" sx={{ color: "warning.main" }}>
        {t("members.import.Warning")}
      </Typography>
      <Typography variant="subtitle2">{message}</Typography>
      {!_.isEmpty(messageDetails) && (
        <ErrorDetails open>
          <summary>Details</summary>
          <Stack spacing={1}>
            {messageDetails.map(messageDetail => (
              <Typography variant="subtitle2" key={messageDetail}>
                {messageDetail.split(",")[0]}
              </Typography>
            ))}
          </Stack>
        </ErrorDetails>
      )}
    </Stack>
  );
};

const ImportFailedState = ({ message, messageDetails }) => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      flex={1}
      spacing={2}
      sx={{ px: 8 }}
    >
      <Error color="error" />
      <Typography variant="h6" sx={{ color: "error.main" }}>
        {t("members.import.Error")}
      </Typography>
      <Typography variant="subtitle2">{message}</Typography>
      {!_.isEmpty(messageDetails) && (
        <ErrorDetails open>
          <summary>Details</summary>
          <Stack spacing={1}>
            {messageDetails.map(messageDetail => (
              <Typography variant="subtitle2" key={messageDetail}>
                {messageDetail.split(",")[0]}
              </Typography>
            ))}
          </Stack>
        </ErrorDetails>
      )}
    </Stack>
  );
};

const isImportCompleteFailure = message => {
  const words = message.split(" ");
  const firstWord = words[0];
  if (firstWord[0] === "0") {
    return true;
  } else {
    return false;
  }
};
