import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";

export const deleteKid = ({ churchId, kidId }) => {
  return axios.delete(`/churches/${churchId}/children/${kidId}`);
};

export const useDeleteKid = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["kids"]);
      setNotification({
        type: "success",
        message: "Kid deleted successfully!"
      });
      navigate("/members");
      closeDeleteModal();
    },
    ...config,
    mutationFn: deleteKid
  });
};
