import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Stack } from "@mui/material";
import { Button, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateTemplate } from "../api";
import { useMessagesStore } from "../store";
import { templateMessageSchema } from "../validation";

export function CreateTemplateModal() {
  const createTemplateModalOpen = useMessagesStore(
    state => state.createTemplateModalOpen
  );
  const closeCreateTemplateModal = useMessagesStore(
    state => state.closeCreateTemplateModal
  );
  const templateMessage = useMessagesStore(state => state.templateMessage);
  const setTemplateMessage = useMessagesStore(
    state => state.setTemplateMessage
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const templateMutation = useCreateTemplate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset
  } = useForm({
    defaultValues: {
      name: "",
      message: ""
    },
    resolver: yupResolver(templateMessageSchema)
  });

  useEffect(() => {
    if (templateMutation.isSuccess) {
      reset({ ...defaultValues });
    }

    return () => {};
  }, [templateMutation.isSuccess]);

  useEffect(() => {
    reset({ name: "", message: templateMessage });
  }, [templateMessage]);

  useEffect(() => {
    return () => {
      setTemplateMessage("");
    };
  }, []);

  const onSubmit = data => {
    templateMutation.mutate({
      churchId: selectedChurch?.id,
      templateData: data
    });
  };

  return (
    <Modal open={createTemplateModalOpen} onClose={closeCreateTemplateModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "450px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("message.template.CreateMessageTemplate")}
        </Box>
        <Stack
          sx={{
            p: 4,
            backgroundColor: "white"
          }}
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label={t("message.template.MessageTitle")}
            name="name"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            register={register}
            isLabelBold={1}
            autoComplete="off"
          />
          <TextField
            label={t("message.template.Message")}
            name="message"
            error={!!errors.message?.message}
            helperText={errors.message?.message}
            register={register}
            multiline
            minRows={4}
            fullWidth
            isLabelBold={1}
            autoComplete="off"
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              flavor="secondary"
              onClick={closeCreateTemplateModal}
              disabled={templateMutation.isLoading}
            >
              {t("form.common.cancel")}
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={templateMutation.isLoading}
            >
              {t("message.template.CreateMessageTemplate")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
