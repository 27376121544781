import styled from "@emotion/styled";

const StyledDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ variant, theme }) =>
    variant === "primary" ? theme.palette.primary.main : "#000000"};
  font-weight: ${({ weight }) => weight};

  ${({ position }) =>
    position === "center" &&
    `
    &::before,
    &::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid;
        border-color: ${({ variant, theme }) =>
          variant === "primary" ? theme.palette.primary.main : "#000000"};
    }

    &:not(:empty)::before {
        margin-right: 0.5em;
    }

    &:not(:empty)::after {
        margin-left: 0.5em;
    }
  `}

  ${({ position }) =>
    position === "left" &&
    `
    &::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid;
        border-color: ${({ variant, theme }) =>
          variant === "primary" ? theme.palette.primary.main : "#000000"};
    }

    &:not(:empty)::after {
        margin-left: 0.5em;
    }
  `}

  ${({ position }) =>
    position === "right" &&
    `
    &::before {
        content: "";
        flex: 1;
        border-bottom: 1px solid;
        border-color: ${({ variant, theme }) =>
          variant === "primary" ? theme.palette.primary.main : "#000000"};
    }

    &:not(:empty)::before {
        margin-right: 0.5em;
    }
  `}
`;

export const Divider = ({ label, position, variant, weight }) => {
  return (
    <>
      <StyledDivider variant={variant} position={position} weight={weight}>
        {label}
      </StyledDivider>
    </>
  );
};
