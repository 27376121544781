import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getSuperAdminStats = ({ page, pageSize }) => {
  return axios.get(
    `/dashboard/super-admin-stats?page=${page}&per_page=${pageSize}`
  );
};

export const useSuperAdminStats = ({ page, pageSize, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["super-admin-stats", page, pageSize],
    queryFn: () => getSuperAdminStats({ page, pageSize }),
    select: response => response.result,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0
  });
};
