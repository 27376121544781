import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
// import { useExpenseStore } from "../store";

export const createExpense = ({ churchId, expenseData }) => {
  return axios.post(`/churches/${churchId}/requisition-records`, expenseData);
};

export const useCreateExpense = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  // const closeRecordExpenseModal = useExpenseStore(
  //   state => state.closeRecordExpenseModal
  // );

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("expenses");

      setNotification({
        type: "success",
        message: "Expense created successfully!"
      });

      // closeRecordExpenseModal();
    },
    ...config,
    mutationFn: createExpense
  });
};
