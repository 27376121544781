import styled from "@emotion/styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Stack, Typography } from "@mui/material";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBudgetYears } from "../api";
import { BudgetTimelineTable, SetBudgetModal } from "../components";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 25%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Select = styled.select`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
  font-family: inherit;
  flex: 1;
`;

export const BudgetDetails = () => {
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  // const teamsQuery = useTeams({
  //   churchId: selectedChurch?.id
  // });
  const budgetYearsQuery = useBudgetYears({
    churchId: selectedChurch?.id
  });
  const [budgetYear, setBudgetYear] = useState(
    budgetYearsQuery.data?.result?.[0]?.id || ""
  );
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={() => navigate(`/finances/temp/budget`)}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <Typography variant="caption">Back to budget</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        {/* <Field>
          <Label htmlFor="department">Choose Department</Label>
          <Select id="department">
            {teamsQuery.data?.result?.map(team => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </Select>
        </Field> */}
        <Field>
          <Label htmlFor="department">Select Budget Year</Label>
          <Select id="department" onChange={e => setBudgetYear(e.target.value)}>
            {budgetYearsQuery.data?.result?.map(budgetYear => (
              <option key={budgetYear.id} value={budgetYear.id}>
                {budgetYear.title}
              </option>
            ))}
          </Select>
        </Field>
      </Stack>
      <BudgetTimelineOverview />
      <BudgetTimelineTable budgetYear={budgetYear} />
      <SetBudgetModal />
    </Stack>
  );
};

const BudgetTimelineOverview = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={3}
      sx={{
        backgroundColor: "secondary.main"
      }}
    >
      <Stack spacing={2}>
        <Stack>
          <Typography variant="subtitle2" fontWeight="bold">
            Total Budget
          </Typography>
          <Typography variant="caption">
            Total annual budget assigned to choir
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h4" fontWeight="bold" color="primary.main">
            100,000
          </Typography>
          <Typography variant="caption">ETB</Typography>
        </Stack>
      </Stack>
      <Stack>
        <Label>Stats</Label>
      </Stack>
      <Stack>
        <Label>Chart</Label>
      </Stack>
    </Stack>
  );
};
