import styled from "@emotion/styled";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BsImageAlt } from "react-icons/bs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 700px;
  align-items: center;
  justify-content: center;
`;

const Skeletons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const SkeletonsTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ChatSkeletonOne = styled.div`
  width: 130px;
  height: 40px;
  background: #f2f6fb;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ChatSkeletonBars = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
`;

const ChatSkeletonBar = styled.div`
  background: ${({ color }) => (color ? color : "#e1e5f0")};
  width: ${({ width }) => (width ? width : "100%")};
  height: 4px;
`;

const ChatSkeletonTwo = styled.div`
  width: 130px;
  height: 40px;
  background: #d8deed;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  align-items: center;
  align-self: flex-end;
`;

const ChatSkeletonThree = styled.div`
  width: 130px;
  height: 96px;
  background: #f2f6fb;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-self: center;
`;

const ChatSkeletonThreeTop = styled.div`
  display: flex;
  gap: 4px;
`;

const MediaSkeleton = styled.div`
  height: 35px;
  width: 65px;
  background: #3dadb7;
  border-radius: 2px;
  display: grid;
  place-items: center;
  color: white;
`;

const ChatSkeletonThreeBottom = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ChatSkeletonFour = styled.div`
  width: 150px;
  height: 30px;
  background: #d8deed;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ChatSkeletonFourLeft = styled.div`
  width: 88px;
  height: 10px;
  border-radius: 2px;
  background: #ffffff;
`;

const ChatSkeletonFourRight = styled.div`
  width: 30px;
  height: 10px;
  border-radius: 25px;
  background: #7ed8d6;
`;

const Info = styled.h5`
  font-weight: 500;
  color: #757981;
`;

export const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Skeletons>
        <SkeletonsTop>
          <ChatSkeletonOne>
            <Avatar sx={{ width: 24, height: 24 }} />
            <ChatSkeletonBars>
              <ChatSkeletonBar width="50%" />
              <ChatSkeletonBar width="75%" />
              <ChatSkeletonBar />
            </ChatSkeletonBars>
          </ChatSkeletonOne>

          <ChatSkeletonTwo>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                background: "#f2f6fb",
                color: "#d8deed"
              }}
            />
            <ChatSkeletonBars reverse={1}>
              <ChatSkeletonBar />
              <ChatSkeletonBar width="90%" color="white" />
              <ChatSkeletonBar color="white" />
            </ChatSkeletonBars>
          </ChatSkeletonTwo>
        </SkeletonsTop>

        <ChatSkeletonThree>
          <ChatSkeletonThreeTop>
            <Avatar sx={{ width: 24, height: 24 }} />
            <MediaSkeleton>
              <BsImageAlt />
            </MediaSkeleton>
          </ChatSkeletonThreeTop>
          <ChatSkeletonThreeBottom>
            <Avatar sx={{ width: 24, height: 24 }} />
            <ChatSkeletonBars>
              <ChatSkeletonBar />
              <ChatSkeletonBar width="90%" />
              <ChatSkeletonBar width="75%" />
            </ChatSkeletonBars>
          </ChatSkeletonThreeBottom>
        </ChatSkeletonThree>

        <ChatSkeletonFour>
          <ChatSkeletonFourLeft />
          <ChatSkeletonFourRight />
        </ChatSkeletonFour>
      </Skeletons>

      <Info>{t("message.newMessage.StartMessage")}</Info>
    </Container>
  );
};
