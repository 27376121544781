import { PhotoCamera } from "@mui/icons-material";
import { Avatar, Badge, Box, Chip, Stack, Typography } from "@mui/material";
import SidebarPhoto from "assets/images/Photo.png";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useUpdateProfile, useUser } from "../api";

export function UserProfileSidebar() {
  const auth = useAuthUser();
  const [photo, setPhoto] = useState();
  const profileMutation = useUpdateProfile();
  const { t } = useTranslation();
  const location = useLocation();
  const isOwnProfile = !location.pathname.includes("users");
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const { id } = useParams();
  const [user, setUser] = useState(null);

  const { data: userData } = useUser({
    churchId: selectedChurch?.id,
    userId: isOwnProfile ? auth()?.id : id
  });

  useEffect(() => {
    if (userData) {
      setUser(userData?.result);
    }
  }, [userData]);

  const handleChange = event => {
    const formData = new FormData();
    const fileUploaded = event.target.files[0];
    formData.append("image", fileUploaded);
    profileMutation.mutate({
      userData: formData
    });
    setPhoto(fileUploaded);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${SidebarPhoto})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        py: 8,
        px: 6,
        display: "flex",
        flexDirection: "column",
        color: "white",
        height: "100%"
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Stack direction="row" justifyContent="center">
          {isOwnProfile && (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              badgeContent={
                <Avatar
                  color="neutral"
                  aria-label="upload picture"
                  component="label"
                  sx={{
                    color: "primary.main",
                    backgroundColor: "white",
                    cursor: "pointer"
                  }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleChange}
                  />
                  <PhotoCamera />
                </Avatar>
              }
            >
              <Avatar
                alt={auth()?.first_name}
                src={
                  photo
                    ? URL.createObjectURL(photo)
                    : `${IMAGE_URL}/${auth()?.image_url}`
                }
                sx={{
                  width: 160,
                  height: 160,
                  outline: "6px solid white"
                }}
              />
            </Badge>
          )}
          {!isOwnProfile && (
            <Avatar
              alt={user?.first_name}
              src={`${IMAGE_URL}/${user?.image_url}`}
              sx={{
                width: 160,
                height: 160,
                outline: "6px solid white"
              }}
            />
          )}
        </Stack>
        <Typography variant="h6">
          {isOwnProfile ? auth()?.first_name : user?.first_name}{" "}
          {isOwnProfile ? auth()?.last_name : user?.last_name}
        </Typography>
        <Typography variant="subtitle2">
          {!isOwnProfile && (
            <>
              Last seen at{" "}
              {user?.latest_user_login
                ? moment(user?.latest_user_login?.logged_in_at).format("LT")
                : null}
            </>
          )}
        </Typography>
        <Typography variant="subtitle2" alignSelf="flex-start">
          {t("users.userProfile.accountCreatedDate")}{" "}
          {moment(isOwnProfile ? auth()?.created_at : user?.created_at).format(
            "DD/MM/YYYY"
          )}
        </Typography>
        {/* <Stack
          direction="column"
          alignItems="flex-start"
          alignSelf="flex-start"
          spacing={2}
        >
          <Typography variant="subtitle2">
            {t("users.userProfile.roleInChurch")}
          </Typography>
          <Chip
            label="Leader"
            sx={{ backgroundColor: "#FFA500", color: "white" }}
          />
        </Stack> */}
        {!isOwnProfile && (
          <Stack
            direction="column"
            alignItems="flex-start"
            alignSelf="flex-start"
            spacing={2}
          >
            <Typography variant="subtitle2">
              {t("users.userProfile.roleInSystem")}
            </Typography>
            <Chip
              label={_.capitalize(
                isOwnProfile ? user?.role?.name : user?.role?.name
              )}
              color="primary"
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
