import { DevTool } from "@hookform/devtools";
import { Box, Button, Divider, Stack } from "@mui/material";
import { LoadingScreen } from "components/LoadingScreen";
import { Notification } from "components/UI";
import { parseISO } from "date-fns";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { useCountries, useMember, useUpdateMember } from "../api";
import {
  AcademicStatusSection,
  AddressSection,
  ChildAndDependencySection,
  ChildInfoSection,
  CurrentChurchEngagementInfoSection,
  EmergencyContactSection,
  JobStatusSection,
  MaritalStatusSection,
  PersonalProfileSection,
  PreviousChurchInfoSection,
  SalvationProfileSection,
  UnionInfoSection
} from "../components/sections";
import { useMembersStore } from "../stores";
import { MemberDataManipulator } from "../utils/memberDataManipulator";

export function MemberUpdate() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const { id } = useParams();
  const isEditMode = !!id;
  const setFullMemberData = useMembersStore(state => state.setFullMemberData);
  const memberMutation = useUpdateMember();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const countriesQuery = useCountries();
  const profileImage = useMembersStore(state => state.memberData.profile_image);
  const idImage = useMembersStore(state => state.memberData.id_image);
  const letterResignation = useMembersStore(
    state => state.memberData.letter_of_resignation
  );

  const memberQuery = useMember({
    churchId: selectedChurch?.id,
    memberId: id
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue
  } = useForm();

  const getChildMotherFirstName = child => {
    if (child.primary_relationship === "mother") {
      return child.primary_first_name;
    }

    if (child.secondary_relationship === "mother") {
      return child.secondary_first_name;
    }

    return child.mother_name ?? "";
  };

  const getChildMotherFatherName = child => {
    if (child.primary_relationship === "mother") {
      return child.primary_father_name;
    }

    if (child.secondary_relationship === "mother") {
      return child.secondary_father_name;
    }

    return child.mother_father_name ?? "";
  };

  const getChildMotherLastName = child => {
    if (child.primary_relationship === "mother") {
      return child.primary_last_name;
    }

    if (child.secondary_relationship === "mother") {
      return child.secondary_last_name;
    }

    return child.mother_last_name ?? "";
  };

  useEffect(() => {
    if (memberQuery.data) {
      const fullMemberData = memberQuery.data.result;

      setFullMemberData(fullMemberData);

      reset(fullMemberData);

      setValue("is_spouse_believer", `${fullMemberData?.is_spouse_believer}`);
      setValue("is_spouse_member", `${fullMemberData?.is_spouse_member}`);
      setValue("has_own_phone", `${fullMemberData?.has_own_phone}`);

      setValue(
        "date_of_birth",
        fullMemberData?.date_of_birth
          ? parseISO(fullMemberData?.date_of_birth)
          : null
      );

      setValue(
        "marital_date",
        fullMemberData?.marital_date
          ? parseISO(fullMemberData?.marital_date)
          : null
      );

      setValue(
        "spouse_date_of_birth",
        fullMemberData?.spouse_date_of_birth
          ? parseISO(fullMemberData?.spouse_date_of_birth)
          : null
      );

      setValue(
        "salvation_date",
        fullMemberData?.salvation_date
          ? parseISO(fullMemberData?.salvation_date)
          : null
      );

      setValue(
        "membership_date",
        fullMemberData?.membership_date
          ? parseISO(fullMemberData?.membership_date)
          : null
      );

      setValue(
        "baptism_date",
        fullMemberData?.baptism_date
          ? parseISO(fullMemberData?.baptism_date)
          : null
      );

      setValue(
        "date_joined_home_cell",
        fullMemberData?.date_joined_home_cell
          ? parseISO(fullMemberData?.date_joined_home_cell)
          : null
      );

      setValue(
        "date_joined_current_ministry",
        fullMemberData?.date_joined_current_ministry
          ? parseISO(fullMemberData?.date_joined_current_ministry)
          : null
      );

      setValue(
        "date_of_resignation",
        fullMemberData?.date_of_resignation
          ? parseISO(fullMemberData?.date_of_resignation)
          : null
      );

      const preferences = fullMemberData?.ministry_preference?.map(
        preference => preference.ministry
      );

      setValue("ministry_preference", preferences);

      setValue(
        "child_and_dependencies",
        `${Number(fullMemberData?.child_and_dependencies)}`
      );

      setValue("is_baptized", `${Number(fullMemberData?.is_baptized)}`);

      setValue("is_home_cell", `${Number(fullMemberData?.is_home_cell)}`);

      setValue(
        "transfer_from_other_church",
        `${Number(fullMemberData?.transfer_from_other_church)}`
      );

      fullMemberData?.children?.forEach((child, index) => {
        setValue(`children.${index}.id`, `${child.id}`);
        setValue(`children.${index}.is_believer`, `${child.is_believer}`);
        setValue(`children.${index}.is_member`, `${child.is_member}`);
        setValue(
          `children.${index}.relation_type`,
          `${child.member_children?.[0]?.relation_type}`
        );

        // Parse date value
        setValue(
          `children.${index}.date_of_birth`,
          child.date_of_birth ? parseISO(child?.date_of_birth) : null
        );

        setValue(`children.${index}.is_kid`, `${child?.is_kid}`);

        setValue(
          `children.${index}.mother_name`,
          getChildMotherFirstName(child)
        );

        setValue(
          `children.${index}.mother_father_name`,
          getChildMotherFatherName(child)
        );

        setValue(
          `children.${index}.mother_last_name`,
          getChildMotherLastName(child)
        );
      });

      fullMemberData?.education_and_skills?.forEach(
        (education_and_skill, index) => {
          setValue(
            `education_and_skills.${index}.academic_status`,
            `${education_and_skill.academic_status}`
          );
          setValue(
            `education_and_skills.${index}.professional_skill`,
            `${education_and_skill.professional_skill}`
          );
        }
      );

      fullMemberData?.languages?.forEach((language, index) => {
        setValue(`languages.${index}.language`, language);
      });

      fullMemberData?.social_media_links?.forEach((link, index) => {
        setValue(`social_media_links.${index}.link`, link);
      });

      const resignationReasons = [
        "Due to change on residential address",
        "Due to job location transfer",
        "Due to personal affairs",
        "Due to conflict",
        "Due to marriage",
        "Due to academic reason",
        "Other"
      ];

      if (
        !_.isEmpty(fullMemberData?.reason_for_resignation) &&
        !_.includes(resignationReasons, fullMemberData?.reason_for_resignation)
      ) {
        setValue(`reason_for_resignation`, "Other");
        setValue(
          `reason_for_resignation_other`,
          fullMemberData?.reason_for_resignation
        );
      }
    }
  }, [memberQuery.data]);

  useEffect(() => {
    setHeaderTitle("Member Update");

    return () => setHeaderTitle("");
  }, []);

  const onSubmit = data => {
    const cleanMemberData = _.cloneDeep(data);

    const memberDataManipulator = new MemberDataManipulator();

    memberDataManipulator.convertValuesToDates(cleanMemberData, [
      "baptism_date",
      "date_of_birth",
      "marital_date",
      "membership_date",
      "salvation_date"
    ]);

    memberDataManipulator.convertValuesToShortDates(cleanMemberData, [
      "date_joined_home_cell",
      "date_joined_current_ministry",
      "spouse_date_of_birth",
      "date_of_resignation"
    ]);

    if (cleanMemberData.child_and_dependencies === "1") {
      let childrenData = _.cloneDeep(cleanMemberData.children);

      childrenData.forEach(child => {
        memberDataManipulator.removeEmptyValues(child);

        memberDataManipulator.convertValuesToNumbers(child, [
          "nationality_id",
          "is_believer",
          "is_member",
          "birth_country_id",
          "birth_region_id",
          "birth_zone_id",
          "birth_city_id"
        ]);

        memberDataManipulator.convertValuesToShortDates(child, [
          "date_of_birth"
        ]);
      });

      cleanMemberData.children = childrenData;

      memberDataManipulator.formatArrayStructure(cleanMemberData, ["children"]);
    } else {
      memberDataManipulator.removeFromObject(cleanMemberData, ["children"]);
    }

    if (
      cleanMemberData.job_status === "student" ||
      cleanMemberData.job_status === "unemployed"
    ) {
      memberDataManipulator.removeFromObject(cleanMemberData, [
        "profession",
        "work_place",
        "monthly_income"
      ]);
    }

    if (cleanMemberData.marital_status !== "married") {
      memberDataManipulator.removeFromObject(cleanMemberData, [
        "marital_date",
        "type_of_marriage",
        "is_spouse_believer",
        "is_spouse_member",
        "spouse_church_name",
        "spouse_first_name",
        "spouse_father_name",
        "spouse_last_name",
        "spouse_phone_number",
        "spouse_date_of_birth",
        "spouse_mother_name",
        "spouse_mother_father_name",
        "spouse_mother_grand_father_name",
        "spouse_professional_skill",
        "spouse_office_name"
      ]);
    }

    if (cleanMemberData.transfer_from_other_church !== "1") {
      memberDataManipulator.removeFromObject(cleanMemberData, [
        "previous_church_name",
        "date_of_resignation",
        "pastor_title",
        "pastor_full_name",
        "pastor_phone_number",
        "reason_for_resignation",
        "reason_for_resignation_other"
      ]);
    } else {
      if (
        cleanMemberData.reason_for_resignation === "Other" &&
        cleanMemberData.reason_for_resignation_other
      ) {
        cleanMemberData.reason_for_resignation =
          cleanMemberData.reason_for_resignation_other;

        delete cleanMemberData.reason_for_resignation_other;
      }
    }

    memberDataManipulator.removeEmptyValues(cleanMemberData);

    memberDataManipulator.formatArrayStructure(cleanMemberData, [
      "education_and_skills"
    ]);

    memberDataManipulator.formatStringArray(cleanMemberData, ["languages"]);

    memberDataManipulator.formatStringArray(cleanMemberData, [
      "social_media_links"
    ]);

    const memberFormData =
      memberDataManipulator.convertObjectToFormData(cleanMemberData);

    if (!_.isNil(cleanMemberData.ministry_preference)) {
      cleanMemberData.ministry_preference.forEach((ministry, index) => {
        memberFormData.append(
          `ministry_preference[${index}][ministry]`,
          ministry
        );
        memberFormData.append(`ministry_preference[${index}][preference]`, 1);
      });

      memberFormData.delete("ministry_preference");
    }

    if (profileImage) {
      memberFormData.append("profile_image", profileImage);
    }

    if (idImage) {
      memberFormData.append("id_image", idImage);
    }

    if (letterResignation && _.isObject(letterResignation)) {
      memberFormData.append("letter_of_resignation", letterResignation);
    } else {
      memberFormData.delete("letter_of_resignation");
    }

    memberMutation.mutate({
      churchId: selectedChurch?.id,
      memberId: id,
      memberData: memberFormData
    });
  };

  if (countriesQuery.isLoading || memberQuery.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ p: 6 }}>
      <Notification />
      <DevTool control={control} />
      <PersonalProfileSection
        register={register}
        errors={errors}
        isEditMode={isEditMode}
        watch={watch}
        setValue={setValue}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <AddressSection
        register={register}
        errors={errors}
        control={control}
        setValue={setValue}
        watch={watch}
        isEditMode={isEditMode}
      />
      <Divider sx={{ my: 3 }} />
      <EmergencyContactSection
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        setValue={setValue}
        isEditMode={isEditMode}
      />
      <Divider sx={{ my: 3 }} />
      <AcademicStatusSection
        register={register}
        errors={errors}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <JobStatusSection register={register} errors={errors} watch={watch} />
      <Divider sx={{ my: 3 }} />
      <MaritalStatusSection
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <ChildAndDependencySection register={register} errors={errors} />
      {String(watch("child_and_dependencies")) === "1" && (
        <ChildInfoSection
          register={register}
          errors={errors}
          watch={watch}
          control={control}
          setValue={setValue}
          isEditMode={isEditMode}
        />
      )}
      <Divider sx={{ my: 3 }} />
      <PreviousChurchInfoSection
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        isEditMode={isEditMode}
      />
      <Divider sx={{ my: 3 }} />
      <SalvationProfileSection
        register={register}
        errors={errors}
        watch={watch}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <UnionInfoSection
        register={register}
        errors={errors}
        isEditMode={isEditMode}
        watch={watch}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <CurrentChurchEngagementInfoSection
        register={register}
        errors={errors}
        isEditMode={isEditMode}
        control={control}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 8 }}
      >
        <Button
          color="neutral"
          size="large"
          variant="contained"
          disabled={memberMutation.isLoading}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={memberMutation.isLoading}
        >
          Update Changes
        </Button>
      </Stack>
    </Box>
  );
}
