import { Drawer } from "@mui/material";
import { useCentralStore } from "stores";
import { MainSidebar } from "./MainSidebar";

export const MobileDrawer = () => {
  const { drawerOpen, closeDrawer } = useCentralStore();

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={closeDrawer}>
      <MainSidebar />
    </Drawer>
  );
};
