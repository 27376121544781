import styled from "@emotion/styled";
import { Stack, TextField, Typography } from "@mui/material";
import { forwardRef } from "react";

const StyledBorderedInputField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "0.5rem",
    "& fieldset": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: `${theme.palette.primary.main}`
    },
    "&:hover fieldset": {
      borderColor: `${theme.palette.primary.main}`
    },
    "&.Mui-focused fieldset": {
      borderColor: `${theme.palette.primary.main}`
    }
  }
}));

// hasBg: true,
// isPlain: false,
// isLabelBold: true,
// rounded: false,

export const BorderedInputField = forwardRef(
  ({ label, isLabelBold, ...rest }, ref) => {
    return (
      <Stack>
        {!!label && (
          <Typography sx={{ fontWeight: isLabelBold && 700 }}>
            {label}
          </Typography>
        )}
        <StyledBorderedInputField {...rest} ref={ref} />
      </Stack>
    );
  }
);

BorderedInputField.displayName = "BorderedInputField";
