import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useDenominationStore } from "../store";

export const updateDenomination = ({ denominationData, denominationId }) => {
  return axios.patch(`/denominations/${denominationId}`, denominationData);
};

export const useUpdateDenomination = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const selectDenomination = useDenominationStore.getState().selectDenomination;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries(["denomination", data.id]);
      setNotification({
        type: "success",
        message: "Denomination updated successfully!"
      });
      selectDenomination(data.result);
      navigate("/denominations");
    },
    ...config,
    mutationFn: updateDenomination
  });
};
