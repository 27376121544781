import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getMessagingStats = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/dashboard/messages-stats`);
};

export const useMessagingStats = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["messaging-stats", churchId],
    queryFn: () => getMessagingStats({ churchId }),
    select: response => response.result,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0
  });
};
