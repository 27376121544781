import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import {
  ChartLineUp,
  ChatCircleText,
  Gear,
  SquaresFour,
  Stack as StackIcon,
  UserCircle,
  UserGear,
  Users,
  UsersThree
} from "@phosphor-icons/react";
import SidebarPhoto from "assets/images/Photo.png";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useAclStore } from "stores/aclStore";

export function MainSidebar() {
  const navigate = useNavigate();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const role = useAclStore(state => state.role);
  const { t } = useTranslation();

  const sidebarItems = [
    {
      name: t("sidebar.dashboard"),
      icon: <SquaresFour size={24} />,
      path: "/"
    },
    {
      name: t("sidebar.members"),
      icon: <Users size={24} />,
      path: "/members"
    },
    {
      name: t("sidebar.teams"),
      icon: <UsersThree size={24} />,
      path: "/teams"
    },
    {
      name: t("sidebar.message"),
      icon: <ChatCircleText size={24} />,
      path: "/messages"
    },
    {
      name: t("sidebar.finance"),
      icon: <ChartLineUp size={24} />,
      path: "/finances"
    },
    {
      name: t("HR.HR.HR"),
      icon: <UserGear size={24} />,
      path: "/hr"
    },
    {
      name: t("Asset.Asset.Asset"),
      icon: <StackIcon size={24} />,
      path: "/assets"
    },
    {
      name: t("sidebar.systemAdmins"),
      icon: <UserCircle size={24} />,
      path: "/users",
      role: "OWNER"
    },
    {
      name: t("sidebar.settings"),
      icon: <Gear size={24} />,
      path: "/settings"
    }
  ];

  return (
    <Box
      sx={{
        backgroundImage: `url(${SidebarPhoto})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        py: {
          xs: 4,
          md: 6
        },
        display: "flex",
        flexDirection: "column",
        color: "white",
        height: "100%"
      }}
    >
      <Stack alignItems="center" spacing={2} sx={{ mx: { xs: 6, md: 0 } }}>
        <Stack direction="row" justifyContent="center">
          <Avatar
            alt={_.capitalize(selectedChurch?.name)}
            src={`${IMAGE_URL}/${selectedChurch?.image_url}`}
            sx={{
              width: 160,
              height: 160,
              border: "6px solid white"
            }}
          />
        </Stack>
        <Typography variant="h6" textAlign="center" px={1}>
          {_.capitalize(selectedChurch?.name)}
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "white",
              color: "primary.main"
            }
          }}
          onClick={() => navigate(`/churches/${selectedChurch?.id}`)}
        >
          {t("sidebar.viewProfile")}
        </Button>
      </Stack>
      <List sx={{ mt: 4, overflowY: "auto" }}>
        {sidebarItems.map(item => {
          if (item.role && item.role !== role) {
            return null;
          }

          return (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                sx={{
                  pl: 3,
                  "&:hover": {
                    backgroundColor: "white",
                    color: "primary.main"
                  },
                  "&:hover > .MuiListItemIcon-root": {
                    color: "primary.main"
                  },

                  "&.active": {
                    backgroundColor: "white",
                    color: "primary.main",

                    ".MuiListItemIcon-root": {
                      color: "primary.main"
                    }
                  }
                }}
                component={NavLink}
                to={item.path}
                end
              >
                <ListItemIcon sx={{ color: "white", minWidth: "34px" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
