import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getEmployee = ({ churchId, employeeId }) => {
  return axios.get(`/churches/${churchId}/employees/${employeeId}`);
};

export const useEmployee = ({ churchId, employeeId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["employee", employeeId],
    queryFn: () => getEmployee({ churchId, employeeId })
  });
};
