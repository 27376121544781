import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Stack } from "@mui/material";
import {
  Button,
  PhoneInputField,
  Radio,
  TextField,
  Upload
} from "components/UI";
import { DateField } from "components/UI/InputFields/DateField";
import { IMAGE_URL } from "config";
import _ from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useUpdatePartner } from "../api";
import { useMembersStore } from "../stores";
import { updatePartnerSchema } from "../validation";
import { formatISO, parseISO } from "date-fns";

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 24px;
  align-items: flex-end;
`;

export function UpdatePartnerModal() {
  const updatePartnerModalOpen = useMembersStore(
    state => state.updatePartnerModalOpen
  );
  const closeUpdatePartnerModal = useMembersStore(
    state => state.closeUpdatePartnerModal
  );
  const partnerPreviewData = useMembersStore(state => state.partnerPreviewData);
  const partnerMutation = useUpdatePartner();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(updatePartnerSchema)
  });

  useEffect(() => {
    const partnerData = {
      ..._.pick(partnerPreviewData, yup.reach(updatePartnerSchema)["_nodes"]),
      birth_date: parseISO(partnerPreviewData?.birth_date),
      image: null
    };

    reset(partnerData);
  }, [updatePartnerModalOpen]);

  const onSubmit = values => {
    const formData = new FormData();

    if (values.image) {
      const data = { ...values, image: values.image };

      for (let key in data) {
        formData.append(key, data[key]);
      }
    } else {
      const data = _.omit(values, ["image"]);

      for (let key in data) {
        formData.append(key, data[key]);
      }
    }

    formData.set("birth_date", formatISO(values.birth_date));

    partnerMutation.mutate({
      partnerId: partnerPreviewData?.id,
      partnerData: formData
    });
  };

  return (
    <Modal open={updatePartnerModalOpen} onClose={closeUpdatePartnerModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 2,
          width: "880px",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          {t("members.partners.UpdatePartner")}
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            pb: 6,
            px: 10,
            backgroundColor: "white"
          }}
          spacing={2}
        >
          <FormGrid>
            <TextField
              label={t("form.common.firstName")}
              name="first_name"
              error={!!errors.first_name?.message}
              helperText={errors.first_name?.message}
              register={register}
            />
            <TextField
              label={t("form.common.fatherName")}
              name="last_name"
              error={!!errors.last_name?.message}
              helperText={errors.last_name?.message}
              register={register}
            />
            <Radio
              label={t("form.common.gender")}
              name="gender"
              buttons={[
                {
                  radioLabel: t("form.common.male"),
                  value: "male",
                  id: "male"
                },
                {
                  radioLabel: t("form.common.female"),
                  value: "female",
                  id: "female"
                }
              ]}
              row={true}
              register={register}
              error={!!errors.gender?.message}
              helperText={errors.gender?.message}
            />
            <Controller
              name="birth_date"
              control={control}
              render={({ field }) => (
                <DateField
                  label={t("form.common.dateOfBirth")}
                  name="birth_date"
                  error={!!errors.birth_date?.message}
                  helperText={errors.birth_date?.message}
                  {...field}
                  required
                  disableFutureDates
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInputField
                  label={t("form.common.phone")}
                  variant="filled"
                  error={!!errors.phone?.message}
                  helperText={errors.phone?.message}
                  {...field}
                />
              )}
            />
            <TextField
              label={t("form.common.email")}
              name="email"
              error={!!errors.email?.message}
              helperText={errors.email?.message}
              register={register}
            />
            <TextField
              label={t("form.common.address")}
              name="address"
              error={!!errors.address?.message}
              helperText={errors.address?.message}
              register={register}
            />
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <Upload
                  title={t("addMembers.step1.UploadPhoto")}
                  buttonTitle="Browse"
                  error={!!errors.image?.message}
                  helperText={errors.image?.message}
                  onChange={field.onChange}
                  preview={`${IMAGE_URL}/${partnerPreviewData?.image_url}`}
                />
              )}
            />
          </FormGrid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ pt: 6 }}
          >
            <Button
              size="large"
              variant="contained"
              disabled={partnerMutation.isLoading}
              color="secondary"
              flavor="secondary"
              onClick={closeUpdatePartnerModal}
            >
              {t("form.common.cancel")}
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={partnerMutation.isLoading}
            >
              {t("form.common.update")}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
