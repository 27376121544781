import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useCreateBudgetYear,
  useFinanceSettingsStore
} from "features/finances";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const approverSchema = yup.object({
  approver: yup.string(),
  approverLevel: yup.string(),
  approvalLimitPerRequest: yup.number(),
  approvalLimitPerMonth: yup.number()
});

export function CreateBudgetYearModal() {
  const createBudgetYearOpen = useFinanceSettingsStore(
    state => state.createBudgetYearOpen
  );
  const closeCreateBudgetYearModal = useFinanceSettingsStore(
    state => state.closeCreateBudgetYearModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const createBudgetYearMutation = useCreateBudgetYear();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      title: "",
      startDate: "",
      endDate: "",
      description: ""
    },
    resolver: yupResolver(approverSchema)
  });

  const onSubmit = values => {
    const budgetYearData = {
      title: values.title,
      start_date: values.startDate,
      end_date: values.endDate,
      description: values.description
    };

    createBudgetYearMutation.mutate(
      {
        churchId: selectedChurch?.id,
        budgetYearData
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Create Budget Year"
      isOpen={createBudgetYearOpen}
      onClose={closeCreateBudgetYearModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeCreateBudgetYearModal}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Create Budget Year
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="name">Title</Label>
        <Input {...register("title")} />
      </Field>
      <Field>
        <Label htmlFor="approverLevel">Start Date</Label>
        <Input {...register("startDate")} type="date" />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerRequest">End Date</Label>
        <Input {...register("endDate")} type="date" />
      </Field>
      <Field>
        <Label htmlFor="approvalLimitPerMonth">Description</Label>
        <Input {...register("description")} />
      </Field>
    </BaseModal>
  );
}
