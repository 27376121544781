import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForward } from "@mui/icons-material";
import { Divider, Stack } from "@mui/material";
import { Button } from "components/UI";
import { useMembersStore } from "features/members";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import { personalProfileSchema } from "./schema";
import {
  AddressSection,
  EmergencyContactSection,
  PersonalProfileSection
} from "./sections";

export function PublicPersonalProfileForm() {
  const memberData = useMembersStore(state => state.memberData);
  const setMemberData = useMembersStore(state => state.setMemberData);
  const { t } = useTranslation();
  const { next } = useSteps();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      title: memberData.title ? memberData.title : "",
      first_name: memberData.first_name ? memberData.first_name : "",
      father_name: memberData.father_name ? memberData.father_name : "",
      last_name: memberData.last_name ? memberData.last_name : "",
      gender: memberData.gender ? memberData.gender : "",
      nationality_id: memberData.nationality_id
        ? memberData.nationality_id
        : "",
      mother_first_name: memberData.mother_first_name
        ? memberData.mother_first_name
        : "",
      mother_father_name: memberData.mother_father_name
        ? memberData.mother_father_name
        : "",
      mother_grand_father_name: memberData.mother_grand_father_name
        ? memberData.mother_grand_father_name
        : "",
      country_id: memberData.country_id ? memberData.country_id : "",
      region_id: memberData.region_id ? memberData.region_id : "",
      zone_id: memberData.zone_id ? memberData.zone_id : "",
      city_id: memberData.city_id ? memberData.city_id : "",
      date_of_birth: memberData.date_of_birth ? memberData.date_of_birth : null,
      id_type: memberData.id_type ? memberData.id_type : "",
      id_number: memberData.id_number ? memberData.id_number : "",
      languages: memberData.languages
        ? memberData.languages
        : [
            {
              language: ""
            }
          ],
      phone: memberData.phone ? memberData.phone : "",
      has_own_phone: memberData.has_own_phone ? memberData.has_own_phone : "1",
      office_number: memberData.office_number ? memberData.office_number : "",
      email: memberData.email ? memberData.email : "",
      po_box: memberData.po_box ? memberData.po_box : "",
      social_media_links: memberData.social_media_links
        ? memberData.social_media_links
        : [
            {
              link: ""
            }
          ],
      current_country_id: memberData.current_country_id
        ? memberData.current_country_id
        : "",
      current_region_id: memberData.current_region_id
        ? memberData.current_region_id
        : "",
      current_zone_id: memberData.current_zone_id
        ? memberData.current_zone_id
        : "",
      current_city_id: memberData.current_city_id
        ? memberData.current_city_id
        : "",
      current_sub_city_id: memberData.current_sub_city_id
        ? memberData.current_sub_city_id
        : "",
      current_woreda_id: memberData.current_woreda_id
        ? memberData.current_woreda_id
        : "",
      current_kebele: memberData.current_kebele
        ? memberData.current_kebele
        : "",
      house_number: memberData.house_number ? memberData.house_number : "",
      specific_location: memberData.specific_location
        ? memberData.specific_location
        : "",
      emergency_contact_first_name: memberData.emergency_contact_first_name
        ? memberData.emergency_contact_first_name
        : "",
      emergency_contact_father_name: memberData.emergency_contact_father_name
        ? memberData.emergency_contact_father_name
        : "",
      emergency_contact_last_name: memberData.emergency_contact_last_name
        ? memberData.emergency_contact_last_name
        : "",
      emergency_contact_phone: memberData.emergency_contact_phone
        ? memberData.emergency_contact_phone
        : "",
      emergency_contact_relationship: memberData.emergency_contact_relationship
        ? memberData.emergency_contact_relationship
        : "",
      emergency_contact_country_id: memberData.emergency_contact_country_id
        ? memberData.emergency_contact_country_id
        : "",
      emergency_contact_region_id: memberData.emergency_contact_region_id
        ? memberData.emergency_contact_region_id
        : "",
      emergency_contact_zone_id: memberData.emergency_contact_zone_id
        ? memberData.emergency_contact_zone_id
        : "",
      emergency_contact_city_id: memberData.emergency_contact_city_id
        ? memberData.emergency_contact_city_id
        : ""
    },
    resolver: yupResolver(personalProfileSchema)
  });

  const onSubmit = data => {
    setMemberData(data);
    next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PersonalProfileSection
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <AddressSection
        register={register}
        errors={errors}
        control={control}
        setValue={setValue}
        watch={watch}
      />
      <Divider sx={{ my: 3 }} />
      <EmergencyContactSection
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        setValue={setValue}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 8 }}
      >
        <Button type="submit" variant="contained" endIcon={<ArrowForward />}>
          {t("form.common.next")}
        </Button>
      </Stack>
    </form>
  );
}
