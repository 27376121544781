import { Button, Popover, Stack } from "@mui/material";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSendMessage } from "../api";
import { useMessagesStore } from "../store";
import { ScheduleOptions } from "./ScheduleOptions";

const RoundButton = ({ children, ...rest }) => (
  <Button
    sx={{
      backgroundColor: "primary.main",
      color: "primary.contrastText",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "primary.main",
        color: "primary.contrastText"
      }
    }}
    {...rest}
  >
    {children}
  </Button>
);

export function SendOptions() {
  const message = useMessagesStore(state => state.message);
  const [anchorEl, setAnchorEl] = useState(null);
  const portId = useMessagesStore(state => state.portId);
  const isScheduled = useMessagesStore(state => state.isScheduled);
  const isDraft = useMessagesStore(state => state.isDraft);
  const dateTime = useMessagesStore(state => state.dateTime);
  const members = useMessagesStore(state => state.members);
  const teams = useMessagesStore(state => state.teams);
  const partners = useMessagesStore(state => state.partners);
  const setIsScheduled = useMessagesStore(state => state.setIsScheduled);
  const openCreateTemplateModal = useMessagesStore(
    state => state.openCreateTemplateModal
  );
  const setTemplateMessage = useMessagesStore(
    state => state.setTemplateMessage
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const sendMessageMutation = useSendMessage();
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setIsScheduled(1);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsScheduled(0);
    setAnchorEl(null);
  };

  return (
    <Stack
      sx={{
        borderRadius: "10px",
        backgroundColor: "white",
        px: 6,
        py: 4,
        maxWidth: "293px"
      }}
      spacing={1}
    >
      <RoundButton
        onClick={() => {
          const messageData = {
            message_port_id: Number(portId),
            message: message,
            addresses: [
              {
                ...(members.size > 0 && { members: [...members.values()] }),
                ...(teams.size > 0 && { teams: [...teams.values()] }),
                ...(partners.size > 0 && { partners: [...partners.values()] })
              }
            ],
            is_scheduled: isScheduled,
            is_draft: isDraft,
            date_time: dateTime
          };

          sendMessageMutation.mutate({
            churchId: selectedChurch?.id,
            messageData,
            notificationMessage: "Message sent successfully!"
          });
        }}
      >
        {t("message.newMessage.SendNow")}
      </RoundButton>
      <RoundButton onClick={handleClick}>
        {t("message.newMessage.Schedule")}
      </RoundButton>
      <RoundButton
        onClick={() => {
          const messageData = {
            message_port_id: Number(portId),
            message: message,
            addresses: [
              {
                ...(members.size > 0 && { members: [...members.values()] }),
                ...(teams.size > 0 && { teams: [...teams.values()] }),
                ...(partners.size > 0 && { partners: [...partners.values()] })
              }
            ],
            is_scheduled: isScheduled,
            is_draft: 1,
            date_time: dateTime
          };

          sendMessageMutation.mutate({
            churchId: selectedChurch?.id,
            messageData,
            notificationMessage: "Draft saved successfully!"
          });
        }}
      >
        {t("message.newMessage.SaveAsDraft")}
      </RoundButton>
      <RoundButton
        onClick={() => {
          setTemplateMessage(message);
          openCreateTemplateModal();
        }}
      >
        {t("message.newMessage.SaveAsTemplate")}
      </RoundButton>
      <Popover
        anchorEl={anchorEl}
        id="send-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            borderRadius: "10px",
            backgroundColor: "white"
          }
        }}
      >
        <ScheduleOptions />
      </Popover>
    </Stack>
  );
}
