import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Coins } from "@phosphor-icons/react";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useExpenseStore,
  useSchedulePaymentRequisition
} from "features/finances";
import moment from "moment";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BaseModal } from "./BaseModal";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "form overview";
  column-gap: 16px;
`;

const Form = styled.div`
  grid-area: form;
`;

const Overview = styled.div`
  grid-area: overview;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SmallText = styled.span`
  color: #8e8e8e;
  font-size: 10px;
  font-weight: 500;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Amount = styled.div`
  border: 0.73px solid #b3b3b3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const AmountInput = styled.input`
  height: 40px;
  border: none;
  outline: none;
  padding-right: 8px;
  flex: 1;
`;

const BudgetInformation = styled.div`
  padding: 4px 8px;
  border-radius: 2px;
  background: #e6f7f8;
  font-weight: 600;
  font-size: 10px;
  min-width: 150px;
`;

const StatusContainer = styled.div`
  padding: 8px 16px;
  border: 0.73px solid #b3b3b3;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Status = styled.div`
  border-radius: 2px;
  background: ${({ color }) => color};
  color: #ffffff;
  padding: 4px 8px;
  text-transform: capitalize;
  font-size: 10px;
`;

const InformationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
`;

const Information = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    color: #778899;
    flex: 1;
  }
`;

const Banner = styled.div`
  border-radius: 2px;
  background: #7fa457;
  width: 391px;
  height: 83px;
  color: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const BannerTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

const AmountFigure = styled.span`
  font-size: 12px;
`;

const Figure = styled.span`
  font-size: 32px;
  font-weight: 700;
`;

const OverviewInformation = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  filter: drop-shadow(0px 0px 60px rgba(30, 41, 42, 0.1));
  flex: 1;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  margin-bottom: auto;

  th {
    padding-block: 16px;
    background: #e6f7f8;
    font-weight: 400;
  }

  & td {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4;
  }
`;

const TotalInformation = styled.div`
  padding: 8px 16px;
  border-top: 1px solid #d4d4d4;
`;

export function AccountantViewModal({ data }) {
  const requisitionFlowModalOpen = useExpenseStore(
    state => state.requisitionFlowModalOpen
  );
  const closeRequisitionFlowModal = useExpenseStore(
    state => state.closeRequisitionFlowModal
  );
  const openMakePaymentModal = useExpenseStore(
    state => state.openMakePaymentModal
  );
  const [date, setDate] = useState("");
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const schedulePaymentMutation = useSchedulePaymentRequisition();

  const statusColors = {
    "not approved": "#8E8E8E",
    approved: "#50C40D",
    rejected: "#F4352F"
  };

  return (
    <BaseModal
      title="Requisition"
      isOpen={requisitionFlowModalOpen}
      onClose={closeRequisitionFlowModal}
      footer={
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          {/* <Button flavor="neutral" size="small">
            Delete
          </Button> */}
          <div></div>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              flavor="white"
              size="small"
              disabled={schedulePaymentMutation.isLoading}
            >
              Cancel
            </Button>
            <div>
              <DatePicker
                selected={date}
                onChange={selectedDate => setDate(selectedDate)}
                customInput={<SchedulePaymentButton />}
                onCalendarClose={() => {
                  if (date) {
                    schedulePaymentMutation.mutate({
                      churchId: selectedChurch?.id,
                      requisitionId: data?.id,
                      requisitionData: {
                        schedule_at: date.toISOString()
                      }
                    });
                  }
                }}
                minDate={moment().toDate()}
                disabled={schedulePaymentMutation.isLoading}
              />
            </div>
            <Button
              size="small"
              onClick={() => openMakePaymentModal()}
              disabled={schedulePaymentMutation.isLoading}
            >
              Make Payment
            </Button>
          </Stack>
        </Stack>
      }
      width="800px"
    >
      <Container>
        <Form>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <SmallText>Transaction Number {data?.id}</SmallText>
            <Button flavor="outlined">Edit</Button>
          </Stack>
          <Field>
            <Label htmlFor="fullName">Requested by</Label>
            <Input
              id="fullName"
              defaultValue={`${data?.user?.first_name} ${data?.user?.last_name}`}
              disabled
            />
          </Field>
          <Field>
            <Label htmlFor="fullName">Amount</Label>
            <Amount>
              <AmountInput defaultValue={data?.amount} disabled />
              <BudgetInformation>Available Budget: 0</BudgetInformation>
            </Amount>
          </Field>
          <Field>
            <Label htmlFor="fullName">Department</Label>
            <Input id="fullName" defaultValue={data?.team?.name} disabled />
          </Field>
          <Field>
            <Label htmlFor="fullName">Account</Label>
            <Input
              id="fullName"
              defaultValue={data?.chart_of_account?.account_name?.name}
              disabled
            />
          </Field>
          <Field>
            <Label htmlFor="fullName">Category</Label>
            <Input
              id="fullName"
              defaultValue={data?.expense_type?.name}
              disabled
            />
          </Field>
          <Field>
            <Label htmlFor="reason">Reason</Label>
            <Input id="reason" defaultValue={data?.reason} disabled />
          </Field>
          <Field>
            <Label>Status</Label>
            <StatusContainer>
              <Status
                color={statusColors[data?.requisition_status?.toLowerCase()]}
              >
                {data?.requisition_status}
              </Status>
              <InformationList>
                {data?.approvers?.map((approver, approverIndex) => (
                  <Information key={approver.id}>
                    {approverIndex === 0 && <span>Approved</span>}
                    {approverIndex === 1 && <span>Approved</span>}
                    {approverIndex === 2 && <span>Approved</span>}
                    <strong>
                      {approver.approver?.first_name}{" "}
                      {approver.approver?.last_name}
                    </strong>
                  </Information>
                ))}
              </InformationList>
            </StatusContainer>
          </Field>
          <Field>
            <Label htmlFor="fullName">Due date</Label>
            <Input
              id="fullName"
              type="date"
              defaultValue={moment(data?.due_date).format("YYYY-MM-DD")}
              disabled
            />
          </Field>
        </Form>
        <Overview>
          <Banner>
            <Stack flex={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <BannerTitle>Total Balance</BannerTitle>
                <Coins
                  size={32}
                  style={{
                    mixBlendMode: "soft-light"
                  }}
                />
              </Stack>
              <AmountFigure>
                <Figure>25,000</Figure> ETB
              </AmountFigure>
            </Stack>
          </Banner>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "600"
            }}
          >
            Payable Requests
          </div>
          <OverviewInformation>
            <Table>
              <thead>
                <tr>
                  <th>Requested By</th>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>Due Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tsega Abebe Lemma</td>
                  <td>Category</td>
                  <td>5,000</td>
                  <td>03/01/2013</td>
                </tr>
              </tbody>
            </Table>
            <TotalInformation>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <BannerTitle>Total payable amount</BannerTitle>
                <AmountFigure>
                  <Figure
                    style={{
                      fontSize: "16px",
                      color: "#00B0B9"
                    }}
                  >
                    25,000
                  </Figure>{" "}
                  ETB
                </AmountFigure>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <BannerTitle>Amount left after payed</BannerTitle>
                <AmountFigure>
                  <Figure
                    style={{
                      fontSize: "16px",
                      color: "#00B0B9"
                    }}
                  >
                    100,000
                  </Figure>{" "}
                  ETB
                </AmountFigure>
              </Stack>
            </TotalInformation>
          </OverviewInformation>
        </Overview>
      </Container>
    </BaseModal>
  );
}

const SchedulePaymentButton = forwardRef(
  ({ onClick, value, disabled }, ref) => (
    <Button
      size="small"
      flavor="outlined"
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      {value ? value : "Schedule Payment"}
    </Button>
  )
);

SchedulePaymentButton.displayName = "SchedulePaymentButton";
