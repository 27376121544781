import styled from "@emotion/styled";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { LanguageSelector, Loading } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurch } from "features/churches";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import { Steppers } from "../components";
import {
  PublicAcademicStatusForm,
  PublicCurrentChurchEngagementForm,
  PublicMaritalStatusForm,
  PublicPersonalProfileForm,
  PublicPreviousChurchInfoForm,
  TermsOfService
} from "../components/public-form";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`;

const Navbar = styled.div`
  height: 64px;
  background-color: ${({ theme }) => theme.palette.neutral.main};
  display: flex;
  align-items: center;
  padding-inline: 64px;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    padding: 0px 16px;
  }`}
`;

const Title = styled(Typography)`
  ${({ theme }) => `${theme.breakpoints.down("md")} {
    display: none;
  }`}
`;

const LoadingContainer = styled.div`
  min-height: 100vh;
  display: grid;
  place-items: center;
`;

const ErrorContainer = styled(LoadingContainer)``;

const ErrorText = styled.p`
  text-align: justify;
  max-width: 900px;
  line-height: 32px;
`;

const stepTitles = {
  1: "addMembers.step1.PersonalProfileAndOther",
  2: "addMembers.step2.AcademicStatus",
  3: "addMembers.step3.MaritalStatusAndChildren",
  4: "addMembers.step4.PreviousChurchInfo",
  5: "addMembers.step5.CurrentChurchEngagement"
};

export const MemberRegistration = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const { churchId, formId } = useParams();
  const { t } = useTranslation();

  const isCorrectLink = churchFormId => {
    return formId === churchFormId;
  };

  const churchQuery = useChurch({
    churchId: churchId,
    isPublic: true
  });

  if (churchQuery.isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (
    churchQuery.data &&
    (!churchQuery.data.result ||
      !churchQuery.data.result.meta ||
      !isCorrectLink(churchQuery.data.result.meta?.code))
  ) {
    return (
      <ErrorContainer>
        <Stack>
          <Typography variant="h3" fontWeight={700} textAlign="center">
            Broken link
          </Typography>
          <ErrorText>
            Sorry for the inconvenience, but it appears that the link you have
            accessed is currently not functioning correctly. If you believe this
            to be an error, kindly contact your church for further assistance in
            resolving the issue. Thank you for your understanding.
          </ErrorText>
        </Stack>
      </ErrorContainer>
    );
  }

  if (!isAccepted) {
    return (
      <TermsOfService
        setIsAccepted={setIsAccepted}
        logo={churchQuery.data.result.image_url}
        name={churchQuery.data.result.name}
        terms={churchQuery.data.result.terms_and_conditions}
      />
    );
  }

  return (
    <Container>
      <Navbar>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            src={`${IMAGE_URL}/${churchQuery.data.result.image_url}`}
            alt={churchQuery.data.result.name}
            sx={{ border: "2px solid", borderColor: "primary.main" }}
          />
          <Typography fontWeight={700}>
            {churchQuery.data.result.name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" ml="auto" gap={2}>
          <LanguageSelector />
          <Title>{t("members.members.applicationForm")}</Title>
        </Stack>
      </Navbar>
      <Box
        sx={{
          px: {
            xs: 2,
            md: 14
          },
          py: {
            xs: 2,
            md: 4
          },
          overflowX: "hidden"
        }}
      >
        <StepsProvider>
          <StepsHeader />
          <Steps>
            <PublicPersonalProfileForm />
            <PublicAcademicStatusForm />
            <PublicMaritalStatusForm />
            <PublicPreviousChurchInfoForm />
            <PublicCurrentChurchEngagementForm />
          </Steps>
        </StepsProvider>
      </Box>
    </Container>
  );
};

const StepsHeader = () => {
  const { t } = useTranslation();
  const { current, jump } = useSteps();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack alignItems="center" sx={{ mb: 6 }} spacing={1}>
      <Typography>{t(stepTitles[current])}</Typography>
      <Steppers
        activeStep={current - 1}
        onClick={step => {
          const selectedStep = step + 1;

          if (selectedStep < current) {
            jump(step + 1);
          }
        }}
      />
    </Stack>
  );
};
