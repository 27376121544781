import axios from "axios";
import { API_URL } from "config";
import { getToken } from "utils/getToken";

export const downloadTemplateService = ({ type = "members", churchId }) => {
  return axios({
    method: "get",
    url: `${API_URL}/churches/${churchId}/${type}-template`,
    responseType: "blob",
    headers: {
      Authorization: getToken()
    }
  });
};
