import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import { Stack } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : "inherit"};
`;

export const AddressForm = ({ register, errors, control }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Field>
        <Label htmlFor="phone_number">{t("HR.Adress.Phone Number")}</Label>
        <Controller
          name="phone_number"
          control={control}
          render={({ field }) => (
            <MuiTelInput
              forceCallingCode
              defaultCountry="ET"
              disableFormatting
              size="small"
              inputProps={{
                maxLength: 13
              }}
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="phone_number"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>

      <Field>
        <Label htmlFor="email">{t("HR.Adress.Email")}</Label>
        <Input id="email" {...register("email")} />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
      <Field>
        <Label htmlFor="address">{t("HR.Adress.Residential Address")}</Label>
        <Input id="address" {...register("address")} />
        <ErrorMessage
          errors={errors}
          name="address"
          render={({ message }) => <HelperText error>{message}</HelperText>}
        />
      </Field>
    </Stack>
  );
};
