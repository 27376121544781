import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const approveRequisition = ({
  churchId,
  requisitionData,
  requisitionId
}) => {
  return axios.patch(
    `/churches/${churchId}/requisitions/${requisitionId}/approve`,
    requisitionData
  );
};

export const useApproveRequisition = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeRequisitionFlowModal =
    useExpenseStore.getState().closeRequisitionFlowModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["requisitions"]);
      queryClient.invalidateQueries(["requisitions-paginated"]);
      setNotification({
        type: "success",
        message: "Requisition approved successfully!"
      });
      closeRequisitionFlowModal();
    },
    ...config,
    mutationFn: approveRequisition
  });
};
