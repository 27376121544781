import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useExpenseStore } from "../store";

export const updateSettlement = ({
  churchId,
  expenseId,
  settlementId,
  settlementData
}) => {
  return axios.patch(
    `/churches/${churchId}/expenses/${expenseId}/settlements/${settlementId}`,
    settlementData
  );
};

export const useUpdateSettlement = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateAdvanceSettlementModal = useExpenseStore(
    state => state.closeUpdateAdvanceSettlementModal
  );

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("settlements");

      setNotification({
        type: "success",
        message: "Settlement updated successfully!"
      });

      closeUpdateAdvanceSettlementModal();
    },
    ...config,
    mutationFn: updateSettlement
  });
};
