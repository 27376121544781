import ReactApexChart from "react-apexcharts";

export const BarChart = ({ labels, data, colors = ["#FBD8B0", "#74DFD5"] }) => {
  return (
    <ReactApexChart
      options={{
        chart: {
          type: "bar",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: labels,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              fontWeight: 700
            }
          }
        },
        yaxis: {
          labels: {
            show: false,
            formatter: value => value.toFixed(0)
          }
        },
        colors: colors,
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%"
          }
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"]
        }
      }}
      series={data}
      type="bar"
      height={350}
    />
  );
};
