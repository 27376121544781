import styled from "@emotion/styled";
import { Error } from "@mui/icons-material";
import { Avatar, Stack, Typography } from "@mui/material";
import { Button, Divider } from "components/UI";
import { IMAGE_URL } from "config";
import { useState } from "react";

const Container = styled.div`
  margin-top: 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding: 64px 64px 0 64px;
  flex: 1;
  display: flex;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    padding: 16px;
    flex-direction: column;
  }`}
`;

const LeftContent = styled.div`
  padding-right: 46px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 47px;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    align-items: center;
    text-align: center;
    padding: 0px;
    gap: 20px;
  }`}
`;

const RightContent = styled.div`
  flex-basis: 962px;
  background: #ffffff;
  border: 1px solid #e6f7f8;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 62px 48px 0 48px;
  height: 800px;
  overflow-y: auto;
  text-align: justify;
  hyphens: auto;
`;

const Header = styled.div`
  padding-inline: 120px;
  margin-bottom: 48px;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    padding-inline: 0px;
    margin-bottom: 20px;
  }`}
`;

// const Intro = styled.p`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 21px;
//   text-align: center;
//   color: #a7a7a7;
//   text-align: center;
// `;

const Body = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
`;

const Footer = styled.div`
  background: #e6f7f8;
  padding-block: 36px 47px;
  padding-inline: 202px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    padding: 16px 0;
    gap: 8px;
  }`}
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 40px;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    gap: 20px;
    justify-content: center;
  }`}
`;

const LargeButton = styled(Button)`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  padding: 22px 83px;

  ${({ theme }) => `${theme.breakpoints.down("md")} {
    padding: 8px 32px;
    font-size: 12px;
  }`}
`;

export const TermsOfService = ({ setIsAccepted, logo, name, terms }) => {
  const [isDeclined, setIsDeclined] = useState(false);

  return (
    <Container>
      <Content>
        <LeftContent>
          <Stack
            spacing={{
              xs: 2,
              md: 4
            }}
          >
            <Avatar
              sx={{
                width: "150px",
                height: "150px",
                border: "2px solid",
                borderColor: "white"
              }}
              src={`${IMAGE_URL}/${logo}`}
              alt={name}
            />
            <Typography variant="h5" fontWeight={700}>
              {name}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5" fontWeight={700} color="primary.main">
              Welcome
            </Typography>
            <Typography variant="subtitle1" color="primary.main">
              Before filling your data please read this terms of service
              carefully and accept to proceed.
            </Typography>
          </Stack>
        </LeftContent>
        <RightContent>
          <Header>
            <Divider
              label={
                <Typography variant="h5" fontWeight={700}>
                  Terms of service
                </Typography>
              }
              position="center"
              variant="primary"
              weight="bold"
            />
            {/* <Intro>
              Lorem ipsum dolor sit amet consectetur. Eleifend elementum lorem
              ut cras vitae. Molestie aliquet euismod diam pharetra enim tortor.
              Ac sit cursus faucibus donec lorem vulputate dui semper. Non
              aliquam aenean euismod egestas nunc facilisis. Sem eget elit magna
              ligula morbi placerat. Vel sit non velit at.{" "}
            </Intro> */}
          </Header>
          <Body dangerouslySetInnerHTML={{ __html: terms }}></Body>
        </RightContent>
      </Content>
      <Footer>
        <ButtonsContainer>
          <LargeButton flavor="outlined" onClick={() => setIsDeclined(true)}>
            Decline
          </LargeButton>
          <LargeButton onClick={() => setIsAccepted(true)}>Accept</LargeButton>
        </ButtonsContainer>
        {isDeclined && (
          <Stack alignItems="flex-end" p={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Error color="error" />
              <Typography variant="subtitle2">
                In order to proceed to member application form you must accept
                service of terms.
              </Typography>
            </Stack>
          </Stack>
        )}
      </Footer>
    </Container>
  );
};
