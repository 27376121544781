import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPotentialSpouses = ({ churchId, memberId }) => {
  return axios.get(
    `/churches/${churchId}/members/${memberId}/potential-spouses`
  );
};

export const usePotentialSpouses = ({ churchId, memberId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["potential-spouses", memberId],
    queryFn: () => getPotentialSpouses({ churchId, memberId })
  });
};
