import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Link,
  Stack,
  Typography
} from "@mui/material";
import {
  BorderedInputField,
  Button,
  Dropzone,
  ErrorMessage,
  Notification,
  PhoneInputField,
  Select,
  TextField,
  Upload
} from "components/UI";
import { GOOGLE_API_KEY, IMAGE_URL } from "config";
import { useDenominationStore } from "features/denominations";
import {
  ADDRESSES,
  useCities,
  useCountries,
  useRegions,
  useSubcities,
  useWoredas,
  useZones
} from "features/members";
import i18next from "i18next";
import _ from "lodash";
import { useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFacebookSquare, FaInstagramSquare, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useCreateChurch } from "../api";
import { createChurchSchema } from "../validation";

const TitleBar = styled(Box)`
  background-color: #dff5f5;
  padding: 1rem;
  border-radius: 5px;
`;

export function CreateChurch() {
  const navigate = useNavigate();
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const selectedDenomination = useDenominationStore(
    state => state.selectedDenomination
  );
  const churchMutation = useCreateChurch();
  const { t } = useTranslation();

  const {
    register,
    unregister,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors, defaultValues },
    reset
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      p_o_box: "",
      facebook_link: "",
      instagram_link: "",
      youtube_link: "",
      website: "",
      country_id: "",
      region_id: "",
      zone_id: "",
      city_id: "",
      sub_city_id: "",
      woreda_id: "",
      kebele: "",
      house_number: "",
      specific_location: "",
      description: "",
      leader_first_name: "",
      leader_last_name: "",
      leader_email: "",
      leader_phone: "",
      leader_image: null,
      acceptTerms: false
    },
    resolver: yupResolver(createChurchSchema)
  });

  useEffect(() => {
    setHeaderTitle(t("churches.churches.createChurch"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  useEffect(() => {
    if (churchMutation.isSuccess) {
      reset({ ...defaultValues });
    }

    return () => {};
  }, [churchMutation.isSuccess]);

  const countriesQuery = useCountries();
  const regionsQuery = useRegions({
    countryId: watch("country_id"),
    config: {
      enabled: !!watch("country_id")
    }
  });
  const zonesQuery = useZones({
    regionId: watch("region_id"),
    config: {
      enabled: !!watch("region_id")
    }
  });
  const citiesQuery = useCities({
    zoneId: watch("zone_id"),
    config: {
      enabled: !!watch("zone_id")
    }
  });
  const subcitiesQuery = useSubcities({
    cityId: watch("city_id"),
    config: {
      enabled: !!watch("city_id")
    }
  });
  const woredasQuery = useWoredas({
    subcityId: watch("sub_city_id"),
    config: {
      enabled: !!watch("sub_city_id")
    }
  });

  const onSubmit = values => {
    const data = {
      ...values,
      image: values.image[0],
      leader_image: values.leader_image,
      ...(selectedDenomination && { denomination_id: selectedDenomination?.id })
    };

    const formData = new FormData();

    for (let key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    churchMutation.mutate({
      churchData: formData
    });
  };

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: place => {
      setValue("latitude", place.geometry.location.lat());
      setValue("longitude", place.geometry.location.lng());
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: "et" }
    }
  });

  return (
    <Box p={{ xs: 4, lg: 6 }}>
      <Notification />
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={6}>
        <Box>
          <Typography
            component="h1"
            variant="h6"
            sx={{ fontWeight: "bold", mb: 5 }}
          >
            {t("churches.churches.createChurch")}
          </Typography>
          <TitleBar sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                {t("churches.createChurch.churchProfile")}
              </Typography>
              {!_.isNil(selectedDenomination) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  onClick={() =>
                    navigate(`/denominations/${selectedDenomination?.id}`)
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <span>{t("churches.createChurch.denomination")}</span>
                  <span>{selectedDenomination?.name}</span>
                  <Avatar
                    src={`${IMAGE_URL}/${selectedDenomination?.image_url}`}
                    alt={selectedDenomination?.name}
                  />
                </Stack>
              )}
            </Stack>
          </TitleBar>
          <Stack direction="row" spacing={4}>
            <Stack justifyContent="space-between" sx={{ flex: 1 }}>
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.churchName")}
                name="name"
                error={
                  !!errors.name?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.name)
                }
                helperText={
                  errors.name?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.name}
                    />
                  )
                }
                register={register}
                required
              />
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.churchEmailAddress")}
                name="email"
                error={
                  !!errors.email?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.email)
                }
                helperText={
                  errors.email?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.email}
                    />
                  )
                }
                register={register}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t("churches.createChurch.churchPhone")}
                    error={
                      !!errors.phone?.message ||
                      !_.isEmpty(churchMutation.error?.errors?.phone)
                    }
                    helperText={
                      errors.phone?.message || (
                        <ErrorMessage
                          messages={churchMutation.error?.errors?.phone}
                        />
                      )
                    }
                    {...field}
                  />
                )}
              />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <Stack>
                <Typography
                  variant="subtitle2"
                  component="label"
                  htmlFor="image"
                  sx={{
                    "&:after": {
                      content: '" *"',
                      color: "red"
                    }
                  }}
                >
                  {t("churches.createChurch.churchLogo")}
                </Typography>
                <Dropzone
                  id="image"
                  name="image"
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  setValue={setValue}
                  error={!!errors.image?.message}
                  helperText={errors.image?.message}
                  required
                />
              </Stack>
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.churchPOBox")}
                name="p_o_box"
                error={
                  !!errors.p_o_box?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.p_o_box)
                }
                helperText={
                  errors.p_o_box?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.p_o_box}
                    />
                  )
                }
                register={register}
              />
            </Stack>
          </Stack>
        </Box>
        <Box>
          <TitleBar sx={{ mb: 2 }}>
            <Typography variant="h6">
              {t("churches.createChurch.churchSocialMedia")}
            </Typography>
          </TitleBar>
          <Grid container columnSpacing={4}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
              rowGap={1}
            >
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.churchSocialMediaLink")}
                name="facebook_link"
                error={
                  !!errors.facebook_link?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.facebook_link)
                }
                helperText={
                  errors.facebook_link?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.facebook_link}
                    />
                  )
                }
                register={register}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaFacebookSquare color="#1976D2" />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                hasNoBg={true}
                name="instagram_link"
                error={
                  !!errors.instagram_link?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.instagram_link)
                }
                helperText={
                  errors.instagram_link?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.instagram_link}
                    />
                  )
                }
                register={register}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaInstagramSquare color="#D13c99" />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                hasNoBg={true}
                name="youtube_link"
                error={
                  !!errors.youtube_link?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.youtube_link)
                }
                helperText={
                  errors.youtube_link?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.youtube_link}
                    />
                  )
                }
                register={register}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaYoutube color="#ff0000" />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.churchWebsite")}
                name="website"
                error={
                  !!errors.website?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.website)
                }
                helperText={
                  errors.website?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.website}
                    />
                  )
                }
                register={register}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <TitleBar sx={{ mb: 2 }}>
            <Typography variant="h6">
              {t("churches.createChurch.churchAddress")}
            </Typography>
          </TitleBar>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Select
                hasNoBg={true}
                isLabelBold={0}
                label={t("form.common.country")}
                name={`country_id`}
                options={
                  countriesQuery.data?.result?.map(country => ({
                    optionLabel: country?.name,
                    value: country?.id
                  })) || []
                }
                register={register}
                error={!!errors.country_id?.message}
                helperText={errors.country_id?.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                hasNoBg={true}
                isLabelBold={0}
                label={t("form.common.region")}
                name={`region_id`}
                options={
                  regionsQuery.data?.result?.map(region => ({
                    optionLabel: region?.name,
                    value: region?.id
                  })) || []
                }
                register={register}
                error={!!errors.region_id?.message}
                helperText={errors.region_id?.message}
                onChange={e => {
                  setValue("region_id", e.target.value, {
                    shouldValidate: true
                  });

                  if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                    setValue("zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID, {
                      shouldValidate: true
                    });
                    setValue("city_id", ADDRESSES.ADDIS_ABABA.CITY_ID, {
                      shouldValidate: true
                    });
                  } else {
                    setValue("zone_id", "", {
                      shouldValidate: true
                    });
                    setValue("city_id", "", {
                      shouldValidate: true
                    });
                  }
                }}
              />
            </Grid>
            {(watch("region_id") !== ADDRESSES.ADDIS_ABABA.REGION_ID ||
              watch("region_id") !== ADDRESSES.DIRE_DAWA.REGION_ID) && (
              <>
                <Grid item xs={4}>
                  <Select
                    hasNoBg={true}
                    isLabelBold={0}
                    label={t("form.common.zone")}
                    name={`zone_id`}
                    options={
                      zonesQuery.data?.result?.map(zone => ({
                        optionLabel: zone?.name,
                        value: zone?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.zone_id?.message}
                    helperText={errors.zone_id?.message}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    hasNoBg={true}
                    isLabelBold={0}
                    label={t("form.common.city")}
                    name={`city_id`}
                    options={
                      citiesQuery.data?.result?.map(city => ({
                        optionLabel: city?.name,
                        value: city?.id
                      })) || []
                    }
                    register={register}
                    error={!!errors.city_id?.message}
                    helperText={errors.city_id?.message}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <Select
                hasNoBg={true}
                isLabelBold={0}
                label={t("form.common.subcity")}
                name={`sub_city_id`}
                options={
                  subcitiesQuery.data?.result?.map(subcity => ({
                    optionLabel: subcity?.name,
                    value: subcity?.id
                  })) || []
                }
                register={register}
                error={!!errors.sub_city_id?.message}
                helperText={errors.sub_city_id?.message}
              />
            </Grid>
            <Grid container item xs={4} spacing={1}>
              <Grid item xs={6}>
                <Select
                  hasNoBg={true}
                  isLabelBold={0}
                  label={t("form.common.woreda")}
                  name={`woreda_id`}
                  options={
                    woredasQuery.data?.result?.map(woreda => ({
                      optionLabel: woreda?.name,
                      value: woreda?.id
                    })) || []
                  }
                  register={register}
                  error={!!errors.woreda_id?.message}
                  helperText={errors.woreda_id?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  hasNoBg={true}
                  label={t("churches.createChurch.kebele")}
                  name="kebele"
                  error={
                    !!errors.kebele?.message ||
                    !_.isEmpty(churchMutation.error?.errors?.kebele)
                  }
                  helperText={
                    errors.kebele?.message || (
                      <ErrorMessage
                        messages={churchMutation.error?.errors?.kebele}
                      />
                    )
                  }
                  register={register}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.houseNumber")}
                name="house_number"
                error={
                  !!errors.house_number?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.house_number)
                }
                helperText={
                  errors.house_number?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.house_number}
                    />
                  )
                }
                register={register}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.specificLocation")}
                name="specific_location"
                error={
                  !!errors.specific_location?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.specific_location)
                }
                helperText={
                  errors.specific_location?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.specific_location}
                    />
                  )
                }
                register={register}
                ref={placesRef}
                placeholder=""
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  component="label"
                  htmlFor="description"
                  variant="h6"
                  sx={{ mb: 1 }}
                >
                  {t("churches.createChurch.churchDescription")}
                </Typography>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <BorderedInputField
                      id="description"
                      size="small"
                      autoComplete="off"
                      multiline
                      minRows={4}
                      fullWidth
                      inputProps={{
                        maxLength: 200
                      }}
                      {...field}
                      error={
                        !!errors.description?.message ||
                        !_.isEmpty(churchMutation.error?.errors?.description)
                      }
                      helperText={
                        `${t(
                          "churches.createChurch.churchDescriptionHelperText"
                        )} - ${field.value.length}/200` ||
                        errors.description?.message || (
                          <ErrorMessage
                            messages={churchMutation.error?.errors?.description}
                          />
                        )
                      }
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <TitleBar sx={{ mb: 2 }}>
            <Typography variant="h6">
              {t("churches.createChurch.churchLeaderInfo")}
            </Typography>
          </TitleBar>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.firstName")}
                name="leader_first_name"
                error={
                  !!errors.leader_first_name?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.leader_first_name)
                }
                helperText={
                  errors.leader_first_name?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.leader_first_name}
                    />
                  )
                }
                register={register}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.lastName")}
                name="leader_last_name"
                error={
                  !!errors.leader_last_name?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.leader_last_name)
                }
                helperText={
                  errors.leader_last_name?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.leader_last_name}
                    />
                  )
                }
                register={register}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                hasNoBg={true}
                label={t("churches.createChurch.email")}
                name="leader_email"
                error={
                  !!errors.leader_email?.message ||
                  !_.isEmpty(churchMutation.error?.errors?.leader_email)
                }
                helperText={
                  errors.leader_email?.message || (
                    <ErrorMessage
                      messages={churchMutation.error?.errors?.leader_email}
                    />
                  )
                }
                register={register}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="leader_phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputField
                    label={t("churches.createChurch.phone")}
                    error={
                      !!errors.leader_phone?.message ||
                      !_.isEmpty(churchMutation.error?.errors?.leader_phone)
                    }
                    helperText={
                      errors.leader_phone?.message || (
                        <ErrorMessage
                          messages={churchMutation.error?.errors?.leader_phone}
                        />
                      )
                    }
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="leader_image"
                control={control}
                render={({ field }) => (
                  <Upload
                    title="Upload photo"
                    buttonTitle="Browse"
                    error={!!errors.leader_image?.message}
                    helperText={errors.leader_image?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack alignItems="center">
          <FormControlLabel
            control={
              <Controller
                name="acceptTerms"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    color="primary"
                  />
                )}
              />
            }
            label={
              <Typography variant="body1">
                {t("churches.createChurch.agree")}{" "}
                <Link href="#" color="inherit">
                  {t("churches.createChurch.termsAndConditions")}
                </Link>
              </Typography>
            }
          />
          {!!errors.acceptTerms?.message && (
            <FormHelperText error>{errors.acceptTerms?.message}</FormHelperText>
          )}
          <Button
            type="submit"
            size="large"
            sx={{ mt: 3, mb: 2 }}
            disabled={churchMutation.isLoading}
          >
            {t("churches.churches.createChurch")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
