import { Autocomplete, Grid, Typography } from "@mui/material";
import { PhoneInputField, Radio, Select, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { FILTER_OPERATIONS, MEMBER_STATES } from "features/members";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import { useMembersPaginated } from "../../api";

export const KidFamilyInformationSection = ({
  register,
  errors,
  control,
  watch,
  setValue
}) => {
  const { t } = useTranslation();
  const [searchMemberValue, setSearchMemberValue] = useState("");
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [debouncedSearchMemberValue, setDebouncedSearchMemberValue] =
    useState("");

  useDebounce(
    () => {
      setDebouncedSearchMemberValue(searchMemberValue);
    },
    200,
    [searchMemberValue]
  );

  const filterMembersQuery = useMembersPaginated({
    config: {
      enabled: !!debouncedSearchMemberValue
    },
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10,
    queries: {
      filter: {
        status: [
          {
            operator: FILTER_OPERATIONS.EQUAL,
            value: MEMBER_STATES.ACTIVE
          }
        ]
      },
      search: {
        first_name: debouncedSearchMemberValue
      }
    }
  });

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">
          {t("kids.addKid.FamilyInformation")}
        </Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("kids.addKid.Parent1Information")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Radio
            label={t("kids.addKid.IsParentRegistered")}
            name="is_primary"
            buttons={[
              {
                radioLabel: t("form.common.yes"),
                value: "1",
                id: "primary_yes"
              },
              { radioLabel: t("form.common.no"), value: "0", id: "primary_no" }
            ]}
            row={true}
            register={register}
            error={!!errors.is_primary?.message}
            helperText={errors.is_primary?.message}
          />
        </Grid>
        <Grid item xs={8}>
          {watch("is_primary") === "1" && (
            <Autocomplete
              id="search-primary-parent"
              freeSolo
              options={filterMembersQuery.data?.result?.data || []}
              getOptionLabel={option =>
                `${option.first_name ?? ""} ${option.father_name ?? ""} ${
                  option.last_name ?? ""
                }`
              }
              loading={filterMembersQuery.isLoading}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Parent Name"
                  register={() => {}}
                />
              )}
              onInputChange={e => setSearchMemberValue(e.target.value)}
              onChange={(e, selectedValue) => {
                setValue("primary_id", selectedValue.id ?? "");
                setValue("primary_first_name", selectedValue.first_name ?? "");
                setValue(
                  `primary_father_name`,
                  selectedValue.father_name ?? ""
                );
                setValue(`primary_last_name`, selectedValue.last_name ?? "");
                setValue(`primary_phone`, selectedValue.phone ?? "");
              }}
            />
          )}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <TextField
            label={t("form.common.firstName")}
            name="primary_first_name"
            error={!!errors.primary_first_name?.message}
            helperText={errors.primary_first_name?.message}
            register={register}
            disabled={watch("is_primary") === "1"}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.fatherName")}
            name="primary_father_name"
            error={!!errors.primary_father_name?.message}
            helperText={errors.primary_father_name?.message}
            register={register}
            disabled={watch("is_primary") === "1"}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.grandFatherName")}
            name="primary_last_name"
            error={!!errors.primary_last_name?.message}
            helperText={errors.primary_last_name?.message}
            register={register}
            disabled={watch("is_primary") === "1"}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            label={t("kids.addKid.Relationship")}
            name="primary_relationship"
            options={[
              { optionLabel: "Father", value: "father" },
              { optionLabel: "Mother", value: "mother" },
              { optionLabel: "Guardian", value: "guardian" }
            ]}
            register={register}
            error={!!errors.primary_relationship?.message}
            helperText={errors.primary_relationship?.message}
          />
        </Grid>
        {watch("is_primary") === "0" && (
          <Grid item xs={4}>
            <TextField
              label={t("kids.profile.Religion")}
              name="primary_religion"
              error={!!errors.primary_religion?.message}
              helperText={errors.primary_religion?.message}
              register={register}
            />
          </Grid>
        )}
        {watch("is_primary") === "0" && (
          <Grid item xs={4}>
            <TextField
              label={t("kids.profile.AttendingChurch")}
              name="primary_attending_church"
              error={!!errors.primary_attending_church?.message}
              helperText={errors.primary_attending_church?.message}
              register={register}
            />
          </Grid>
        )}
        <Grid item xs={watch("is_primary") === "1" ? 4 : 6}>
          <Controller
            name="primary_phone"
            control={control}
            render={({ field }) => (
              <PhoneInputField
                label={t("form.common.phone")}
                variant="filled"
                error={!!errors.primary_phone?.message}
                helperText={errors.primary_phone?.message}
                {...field}
                disabled={watch("is_primary") === "1"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {t("kids.addKid.Parent2Information")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Radio
            label={t("kids.addKid.IsParentRegistered")}
            name="is_secondary"
            buttons={[
              {
                radioLabel: t("form.common.yes"),
                value: "1",
                id: "secondary_yes"
              },
              {
                radioLabel: t("form.common.no"),
                value: "0",
                id: "secondary_no"
              }
            ]}
            row={true}
            register={register}
            error={!!errors.is_secondary?.message}
            helperText={errors.is_secondary?.message}
          />
        </Grid>
        <Grid item xs={8}>
          {watch("is_secondary") === "1" && (
            <Autocomplete
              id="search-secondary-parent"
              freeSolo
              options={filterMembersQuery.data?.result?.data || []}
              getOptionLabel={option =>
                `${option.first_name ?? ""} ${option.father_name ?? ""} ${
                  option.last_name ?? ""
                }`
              }
              loading={filterMembersQuery.isLoading}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Parent Name"
                  register={() => {}}
                />
              )}
              onInputChange={e => setSearchMemberValue(e.target.value)}
              onChange={(e, selectedValue) => {
                setValue("secondary_id", selectedValue.id ?? "");
                setValue(
                  "secondary_first_name",
                  selectedValue.first_name ?? ""
                );
                setValue(
                  `secondary_father_name`,
                  selectedValue.father_name ?? ""
                );
                setValue(`secondary_last_name`, selectedValue.last_name ?? "");
                setValue(`secondary_phone`, selectedValue.phone ?? "");
              }}
            />
          )}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <TextField
            label={t("form.common.firstName")}
            name="secondary_first_name"
            error={!!errors.secondary_first_name?.message}
            helperText={errors.secondary_first_name?.message}
            register={register}
            disabled={watch("is_secondary") === "1"}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.fatherName")}
            name="secondary_father_name"
            error={!!errors.secondary_father_name?.message}
            helperText={errors.secondary_father_name?.message}
            register={register}
            disabled={watch("is_secondary") === "1"}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("form.common.grandFatherName")}
            name="secondary_last_name"
            error={!!errors.secondary_last_name?.message}
            helperText={errors.secondary_last_name?.message}
            register={register}
            disabled={watch("is_secondary") === "1"}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            label={t("kids.addKid.Relationship")}
            name="secondary_relationship"
            options={[
              { optionLabel: "Father", value: "father" },
              { optionLabel: "Mother", value: "mother" },
              { optionLabel: "Guardian", value: "guardian" }
            ]}
            register={register}
            error={!!errors.secondary_relationship?.message}
            helperText={errors.secondary_relationship?.message}
          />
        </Grid>
        {watch("is_secondary") === "0" && (
          <Grid item xs={4}>
            <TextField
              label="Religion"
              name="secondary_religion"
              error={!!errors.secondary_religion?.message}
              helperText={errors.secondary_religion?.message}
              register={register}
              disabled={watch("is_secondary") === "1"}
            />
          </Grid>
        )}
        {watch("is_secondary") === "0" && (
          <Grid item xs={4}>
            <TextField
              label="Attending Church Name"
              name="secondary_attending_church"
              error={!!errors.secondary_attending_church?.message}
              helperText={errors.secondary_attending_church?.message}
              register={register}
              disabled={watch("is_secondary") === "1"}
            />
          </Grid>
        )}
        <Grid item xs={watch("is_secondary") === "1" ? 4 : 6}>
          <Controller
            name="secondary_phone"
            control={control}
            render={({ field }) => (
              <PhoneInputField
                label={t("form.common.phone")}
                variant="filled"
                error={!!errors.secondary_phone?.message}
                helperText={errors.secondary_phone?.message}
                {...field}
                disabled={watch("is_secondary") === "1"}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
