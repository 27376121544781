import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const updateAsset = ({ churchId, assetId, assetData }) => {
  return axios.post(
    `/churches/${churchId}/assets/${assetId}?_method=PATCH`,
    assetData
  );
};

export const useUpdateAsset = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("assets");
      queryClient.invalidateQueries("asset");

      setNotification({
        type: "success",
        message: "Asset updated successfully!"
      });
    },
    ...config,
    mutationFn: updateAsset
  });
};
