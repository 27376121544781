import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const deleteRevenueType = ({ churchId, revenueTypeId }) => {
  return axios.delete(`/churches/${churchId}/revenue-types/${revenueTypeId}`);
};

export const useDeleteRevenueType = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closeUpdateRevenueTypeModal =
    useRevenueStore.getState().closeUpdateRevenueTypeModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["revenue-types"]);
      setNotification({
        type: "success",
        message: "Revenue type deleted successfully!"
      });
      closeDeleteModal();
      closeUpdateRevenueTypeModal();
    },
    ...config,
    mutationFn: deleteRevenueType
  });
};
