import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getTeamMembers = ({ churchId, teamId }) => {
  return axios.get(`/churches/${churchId}/teams/${teamId}/members`);
};

export const useTeamMembers = ({ churchId, teamId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["team_members", churchId, teamId],
    queryFn: () => getTeamMembers({ churchId, teamId })
  });
};
