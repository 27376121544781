import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { QueryBuilder } from "../utils/queryBuilder";

export const getMembers = ({ churchId, queries }) => {
  const queryBuilder = new QueryBuilder();

  if (queries.filter) {
    queryBuilder.filter(queries.filter);
  }

  if (queries.search) {
    queryBuilder.search(queries.search);
  }

  if (queries.sort) {
    queryBuilder.sort(queries.sort);
  }

  const queryString = queryBuilder.build();

  return axios.get(`/churches/${churchId}/members${queryString}`);
};

export const useMembers = ({ churchId, queries, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["members", churchId, JSON.stringify(queries)],
    queryFn: () => getMembers({ churchId, queries })
  });
};
