import styled from "@emotion/styled";
import { DeleteOutline, MoreHoriz, Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { Button, Checkbox, DeleteModal, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChatText } from "react-icons/bs";
import { useCentralStore } from "stores";
import { useDeleteTemplates, useTemplates, useUpdateTemplate } from "../api";
import { useMessagesStore } from "../store";
import { UpdateTemplateModal } from "./UpdateTemplateModal";

const Container = styled.div`
  width: 100%;
  padding: 51px 37px 51px 44px;
  background: white;
  grid-column: 2 / span 2;
  overflow: hidden;
`;

const Title = styled.h3``;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 700px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding-bottom: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
    font-size: 14px;
  }

  & td {
    text-align: center;
    vertical-align: middle;
    padding: 10px 5px;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background: #f3f3f3;
    border-radius: 5px;
  }
`;

const DeleteButton = styled(Button)`
  color: red;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const CancelButton = styled.button`
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #000000;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};

  & > * {
    width: 24px;
    height: 24px;
  }
`;

export const TemplatesPanel = () => {
  const openCreateTemplateModal = useMessagesStore(
    state => state.openCreateTemplateModal
  );
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const closeDeleteModal = useCentralStore(state => state.closeDeleteModal);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const templatesQuery = useTemplates({
    churchId: selectedChurch?.id
  });
  const [templateToUpdate, setTemplateToUpdate] = useState(null);
  const [templatesToDelete, setTemplatesToDelete] = useState(new Map());
  const templateDeleteMutation = useDeleteTemplates();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const templateUpdateMutation = useUpdateTemplate();
  const { t } = useTranslation();

  const handleClick = (template, event) => {
    setTemplateToUpdate(template);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <UpdateTemplateModal
        templateData={templateToUpdate}
        isLoading={templateUpdateMutation.isLoading}
        onUpdate={data => {
          templateUpdateMutation.mutate({
            churchId: selectedChurch?.id,
            templateId: templateToUpdate?.id,
            templateData: data
          });

          setTemplateToUpdate(null);
        }}
      />
      <DeleteModal
        title={t("message.allMessage.DeleteMessage")}
        onDelete={() => {
          templateDeleteMutation.mutate({
            churchId: selectedChurch.id,
            templateIds: [...templatesToDelete.keys()]
          });
          closeDeleteModal();

          const selectedTemplates = new Map(templatesToDelete);
          selectedTemplates.clear();
          setTemplatesToDelete(selectedTemplates);
        }}
        name={t("messages.common.SelectedMessages")}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Title>{t("message.template.Template")}</Title>

        <Stack direction="row" spacing={2}>
          {!!templatesToDelete.size && (
            <CancelButton
              flavor="neutral"
              onClick={() => {
                const selectedTemplates = new Map(templatesToDelete);
                selectedTemplates.clear();
                setTemplatesToDelete(selectedTemplates);
              }}
            >
              <DeleteOutline />
              <span>{t("systemAdmins.createNewRole.Cancel")}</span>
            </CancelButton>
          )}
          <DeleteButton
            flavor="neutral"
            disabled={!templatesToDelete.size}
            onClick={openDeleteModal}
          >
            <DeleteOutline />
            <span>{t("message.allMessage.DeleteMessage")}</span>
          </DeleteButton>
        </Stack>
      </Stack>
      <TextField
        neutral={1}
        placeholder={t("message.allMessage.SearchMessages")}
        register={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
      <Stack alignItems="flex-end" sx={{ my: "40px" }}>
        <Button onClick={openCreateTemplateModal}>
          {t("message.template.CreateMessageTemplate")}
        </Button>
      </Stack>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>{t("message.template.Template")}</th>
              <th>{t("message.template.CreatedDate")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {templatesQuery.data?.result?.map(message => (
              <tr key={message.id}>
                <td>
                  <Checkbox
                    name=""
                    buttons={[
                      {
                        checkboxLabel: "",
                        value: true,
                        id: "remember",
                        checked: templatesToDelete.has(message.id),
                        onChange: e => {
                          const selectedTemplates = new Map(templatesToDelete);

                          if (e.target.checked) {
                            selectedTemplates.set(message.id, message);
                          } else {
                            selectedTemplates.delete(message.id);
                          }

                          setTemplatesToDelete(selectedTemplates);
                        },
                        onClick: e => {
                          e.stopPropagation();
                        }
                      }
                    ]}
                    error=""
                    helperText=""
                    register={() => {}}
                    containerStyle={{ alignItems: "center" }}
                  />
                </td>
                <td>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Icon>
                      <BsChatText />
                    </Icon>
                    <Stack>
                      <Typography variant="subtitle2" textAlign="left">
                        {message.name}
                      </Typography>
                      <Typography variant="caption" textAlign="left" noWrap>
                        {message.message}
                      </Typography>
                    </Stack>
                  </Stack>
                </td>
                <td>
                  <Typography variant="caption">
                    {moment(message.created_at).format("D/M/YY, h:mm A")}
                  </Typography>
                </td>
                <td>
                  <IconButton onClick={e => handleClick(message, e)}>
                    <MoreHoriz />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <Popover
        anchorEl={anchorEl}
        id="template-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <TemplateMenu />
      </Popover>
    </Container>
  );
};

const TemplateMenu = () => {
  const openUpdateTemplateModal = useMessagesStore(
    state => state.openUpdateTemplateModal
  );
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "200px", overflow: "hidden", borderRadius: "5px" }}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        {t("form.common.update")}
      </Box>
      <Stack
        sx={{
          backgroundColor: "white",
          p: 2
        }}
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "#EEEEEE", py: 1, px: 2, cursor: "pointer" }}
          onClick={openUpdateTemplateModal}
        >
          <Typography variant="caption">
            {t("message.template.UpdateTemplate")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
