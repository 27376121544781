import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useRevenueStore = create(
  devtools(
    set => ({
      recordRevenueModalOpen: false,
      updateRevenueModalOpen: false,
      successModalOpen: false,
      errorModalOpen: false,
      createRevenueTypeOpen: false,
      updateRevenueTypeOpen: false,
      createRevenueMethodOpen: false,
      updateRevenueMethodOpen: false,
      chequeReconcileOpen: false,
      updateChequeOpen: false,
      openRecordRevenueModal: () => set({ recordRevenueModalOpen: true }),
      closeRecordRevenueModal: () => set({ recordRevenueModalOpen: false }),
      openSuccessModal: () => set({ successModalOpen: true }),
      closeSuccessModal: () => set({ successModalOpen: false }),
      openErrorModal: () => set({ errorModalOpen: true }),
      closeErrorModal: () => set({ errorModalOpen: false }),
      openCreateRevenueTypeModal: () => set({ createRevenueTypeOpen: true }),
      closeCreateRevenueTypeModal: () => set({ createRevenueTypeOpen: false }),
      openUpdateRevenueTypeModal: () => set({ updateRevenueTypeOpen: true }),
      closeUpdateRevenueTypeModal: () => set({ updateRevenueTypeOpen: false }),
      openCreateRevenueMethodModal: () =>
        set({ createRevenueMethodOpen: true }),
      closeCreateRevenueMethodModal: () =>
        set({ createRevenueMethodOpen: false }),
      openUpdateRevenueMethodModal: () =>
        set({ updateRevenueMethodOpen: true }),
      closeUpdateRevenueMethodModal: () =>
        set({ updateRevenueMethodOpen: false }),
      openUpdateRevenueModal: () => set({ updateRevenueModalOpen: true }),
      closeUpdateRevenueModal: () => set({ updateRevenueModalOpen: false }),
      openChequeReconcileModal: () => set({ chequeReconcileOpen: true }),
      closeChequeReconcileModal: () => set({ chequeReconcileOpen: false }),
      openUpdateChequeModal: () => set({ updateChequeOpen: true }),
      closeUpdateChequeModal: () => set({ updateChequeOpen: false })
    }),
    {
      name: "revenue-store"
    }
  )
);
