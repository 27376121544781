import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";
import queryString from "query-string";

export const getChurches = ({ filter }) => {
  return axios.get(`/churches?${queryString.stringify(filter)}`);
};

export const useChurches = ({ config, filter } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["churches", filter],
    queryFn: () => getChurches({ filter })
  });
};
