import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import SidebarPhoto from "assets/images/Laptop.webp";
import Logo from "assets/images/logo.svg";
import { Header } from "components/UI";
import { Outlet } from "react-router-dom";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const SidePhoto = styled.div`
  flex-basis: 50%;
  background: linear-gradient(180deg, #82dbd8 1.49%, #3bacb6 45.31%),
    url(${SidebarPhoto});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block: 40px;
  padding-inline: 60px;
  display: none;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
  }`}
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const YotorLogo = styled.img`
  height: 100px;
  width: 100px;
`;

function AuthLayout({ hasHeader }) {
  return (
    <Container>
      <SidePhoto>
        <YotorLogo src={Logo} alt="Yotor logo" />
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "48px", fontWeight: 900, lineHeight: "111%" }}
          color="white"
        >
          Church
          <br /> Management
          <br /> System
          <br />
        </Typography>
        <Typography variant="caption" color="white" mt={2}>
          Beta version
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: "auto" }} color="white">
          ዘመናዊ የቤተክርስቲያን አስተዳደር ስርዓት
        </Typography>
      </SidePhoto>
      <Content>
        {hasHeader && <Header />}
        <Outlet />
      </Content>
    </Container>
  );
}

export default AuthLayout;
