import { Stack } from "@mui/material";
import { Select, TextField } from "components/UI";
import { useTranslation } from "react-i18next";

export const FinancialInfoForm = ({ register, errors }) => {
  const STATUS = ["ACTIVE", "INACTIVE", "UNDER_REPAIR", "DISPOSED"];

  const CONDITION = ["NEW", "GOOD", "FAIR", "POOR"];

  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Select
        isLabelBold={0}
        label={t("Asset.AssetRegistration.Status")}
        name="status"
        options={STATUS.map(status => ({
          optionLabel: status,
          value: status
        }))}
        register={register}
        error={!!errors.status?.message}
        helperText={errors.status?.message}
      />
      <Select
        isLabelBold={0}
        label={t("Asset.AssetRegistration.Condition")}
        name="condition"
        options={CONDITION.map(condition => ({
          optionLabel: condition,
          value: condition
        }))}
        register={register}
        error={!!errors.condition?.message}
        helperText={errors.condition?.message}
      />
      <TextField
        label={t("Asset.AssetRegistration.Depreciation Method")}
        name="depreciation_method"
        error={!!errors.depreciation_method?.message}
        helperText={errors.depreciation_method?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Depreciation Rate")}
        name="depreciation_rate"
        error={!!errors.depreciation_rate?.message}
        helperText={errors.depreciation_rate?.message}
        register={register}
      />
      <TextField
        label={t("Asset.AssetRegistration.Book Value")}
        name="book_value"
        error={!!errors.book_value?.message}
        helperText={errors.book_value?.message}
        register={register}
      />
    </Stack>
  );
};
