import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const deleteApprover = ({ churchId, approverId }) => {
  return axios.delete(`/churches/${churchId}/approvers/${approverId}`);
};

export const useDeleteApprover = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;
  const closeUpdateApproverModal =
    useFinanceSettingsStore.getState().closeUpdateApproverModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["approvers"]);
      setNotification({
        type: "success",
        message: "Approver deleted successfully!"
      });
      closeDeleteModal();
      closeUpdateApproverModal();
    },
    ...config,
    mutationFn: deleteApprover
  });
};
