import { useState } from "react";
import {
  AccountantViewModal,
  ApproverViewModal,
  RequisitionTable
} from "../components";
import { MakePaymentModal } from "../components/Modals/MakePaymentModal";

export const Requisition = () => {
  const [requisition, setRequisition] = useState(null);
  const [modalType, setModalType] = useState("approver");

  return (
    <>
      {modalType === "approver" && <ApproverViewModal data={requisition} />}
      {modalType === "accountant" && <AccountantViewModal data={requisition} />}
      <MakePaymentModal data={requisition} />
      <RequisitionTable
        onStatusAction={(requisition, type) => {
          setRequisition(requisition);
          setModalType(type);
        }}
      />
    </>
  );
};
