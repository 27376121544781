import styled from "@emotion/styled";
import { DeleteOutline, MoreHoriz, Search } from "@mui/icons-material";
import {
  Avatar,
  Chip,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { Button, Checkbox, DeleteModal, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import moment from "moment-timezone";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TbCalendarTime } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { getAddressNames } from "utils/getAddressNames";
import { useDeleteSendMessages, useSendMessages } from "../api";
import { ScheduledDropdownMenu } from "./ChatPanel";

const Container = styled.div`
  width: 100%;
  padding: 51px 37px 51px 44px;
  background: white;
  grid-column: 2 / span 2;
`;

const Title = styled.h3``;

const Subtitle = styled.h5`
  color: #929292;
  font-weight: 300;
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 700px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding-bottom: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
    font-size: 14px;
  }

  & td {
    text-align: center;
    padding: 10px;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background: #f3f3f3;
    border-radius: 5px;
  }
`;

const DeleteButton = styled(Button)`
  color: red;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const CancelButton = styled.button`
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 5px 10px;
  color: #000000;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ScheduledMessagesPanel = () => {
  const navigate = useNavigate();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const scheduledQuery = useSendMessages({
    churchId: selectedChurch?.id,
    filters: ["filters[is_scheduled]=1", "sort[created_at]=DESC"]
  });
  const [messagesToDelete, setMessagesToDelete] = useState(new Map());
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const closeDeleteModal = useCentralStore(state => state.closeDeleteModal);
  const messageDeleteMutation = useDeleteSendMessages();
  const [selectedMessage, setSelectedMessage] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (message, event) => {
    setSelectedMessage(message);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <DeleteModal
        title={t("message.allMessage.DeleteMessage")}
        onDelete={() => {
          messageDeleteMutation.mutate({
            churchId: selectedChurch.id,
            messageIds: [...messagesToDelete.keys()]
          });
          closeDeleteModal();

          const selectedMessages = new Map(messagesToDelete);
          selectedMessages.clear();
          setMessagesToDelete(selectedMessages);
        }}
        name={t("messages.common.SelectedMessages")}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Title>{t("message.scheduledMessage.ScheduledMessage")}</Title>
        <Stack direction="row" spacing={2}>
          {!!messagesToDelete.size && (
            <CancelButton
              flavor="neutral"
              onClick={() => {
                const selectedMessages = new Map(messagesToDelete);
                selectedMessages.clear();
                setMessagesToDelete(selectedMessages);
              }}
            >
              <DeleteOutline />
              <span>{t("systemAdmins.createNewRole.Cancel")}</span>
            </CancelButton>
          )}
          <DeleteButton
            flavor="neutral"
            disabled={!messagesToDelete.size}
            onClick={openDeleteModal}
          >
            <DeleteOutline />
            <span>{t("message.scheduledMessage.DeleteMessage")}</span>
          </DeleteButton>
        </Stack>
      </Stack>
      <TextField
        neutral={1}
        placeholder={t("message.scheduledMessage.SearchMessages")}
        register={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
      <Subtitle>{t("message.scheduledMessage.RecentMessages")}</Subtitle>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>{t("message.scheduledMessage.To")}</th>
              <th>{t("message.scheduledMessage.Type")}</th>
              <th>{t("message.scheduledMessage.Port")}</th>
              <th>{t("message.scheduledMessage.Status")}</th>
              <th>{t("message.scheduledMessage.ScheduledDate")}</th>
            </tr>
          </thead>
          <tbody>
            {scheduledQuery?.data?.result?.map(message => (
              <tr
                key={message.id}
                onClick={() => navigate(`/messages/scheduled/${message.id}`)}
              >
                <td>
                  <Checkbox
                    name=""
                    buttons={[
                      {
                        checkboxLabel: "",
                        value: true,
                        id: "remember",
                        checked: messagesToDelete.has(message.id),
                        onChange: e => {
                          const selectedMessages = new Map(messagesToDelete);

                          if (e.target.checked) {
                            selectedMessages.set(message.id, message);
                          } else {
                            selectedMessages.delete(message.id);
                          }

                          setMessagesToDelete(selectedMessages);
                        },
                        onClick: e => {
                          e.stopPropagation();
                        }
                      }
                    ]}
                    error=""
                    helperText=""
                    register={() => {}}
                  />
                </td>
                <td>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar
                      alt={message.name}
                      src={message.image}
                      sx={{ width: 48, height: 48 }}
                    />
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="subtitle2" textAlign="left">
                          {getAddressNames(message?.addresses)}
                        </Typography>
                        <TbCalendarTime />
                      </Stack>
                      <Typography
                        sx={{ maxWidth: "150px" }}
                        variant="caption"
                        textAlign="left"
                        noWrap
                      >
                        {message?.message}
                      </Typography>
                    </Stack>
                  </Stack>
                </td>
                <td>
                  <Typography variant="caption">
                    {t("messages.common.TextMessage")}
                  </Typography>
                </td>
                <td>
                  <Typography variant="caption">
                    {message?.message_port?.name}
                  </Typography>
                </td>
                <td>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                  >
                    <Chip
                      label={
                        <Typography variant="caption">
                          {t("message.scheduledMessage.Scheduled")}
                        </Typography>
                      }
                      color="warning"
                    />
                    <Typography variant="caption">
                      {moment(message?.created_at).format("D/M/YY, h:mm A")}
                    </Typography>
                  </Stack>
                </td>
                <td>
                  <Typography variant="caption">
                    {moment
                      .utc(message?.date_time)
                      .tz("Africa/Addis_Ababa")
                      .format("D/M/YY, h:mm A")}
                  </Typography>
                </td>
                <td>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      handleClick(message, e);
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <Popover
        anchorEl={anchorEl}
        id="scheduled-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <ScheduledDropdownMenu message={selectedMessage} />
      </Popover>
    </Container>
  );
};
