import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useTeamsStore = create(
  devtools(
    set => ({
      createTeamModalOpen: false,
      updateTeamModalOpen: false,
      createTeamTypeModalOpen: false,
      updateTeamTypeModalOpen: false,
      addTeamMemberModalOpen: false,
      deleteTeamModalOpen: false,
      deleteTeamTypeModalOpen: false,
      currentTeamType: null,
      currentTab: "members",
      searchQuery: "",
      openCreateTeamModal: () => set({ createTeamModalOpen: true }),
      closeCreateTeamModal: () => set({ createTeamModalOpen: false }),
      openUpdateTeamModal: () => set({ updateTeamModalOpen: true }),
      closeUpdateTeamModal: () => set({ updateTeamModalOpen: false }),
      openCreateTeamTypeModal: () => set({ createTeamTypeModalOpen: true }),
      closeCreateTeamTypeModal: () => set({ createTeamTypeModalOpen: false }),
      openUpdateTeamTypeModal: () => set({ updateTeamTypeModalOpen: true }),
      closeUpdateTeamTypeModal: () => set({ updateTeamTypeModalOpen: false }),
      openAddTeamMemberModal: () => set({ addTeamMemberModalOpen: true }),
      closeAddTeamMemberModal: () => set({ addTeamMemberModalOpen: false }),
      openDeleteTeamModal: () => set({ deleteTeamModalOpen: true }),
      closeDeleteTeamModal: () => set({ deleteTeamModalOpen: false }),
      openDeleteTeamTypeModal: () => set({ deleteTeamTypeModalOpen: true }),
      closeDeleteTeamTypeModal: () => set({ deleteTeamTypeModalOpen: false }),
      setCurrentTeamType: teamType => set({ currentTeamType: teamType }),
      setCurrentTab: tab => set({ currentTab: tab }),
      setSearchQuery: searchQuery => set({ searchQuery })
    }),
    { name: "teams-store" }
  )
);
