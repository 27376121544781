import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useFinanceSettingsStore } from "../store";

export const createBudgetYear = ({ churchId, budgetYearData }) => {
  return axios.post(`/churches/${churchId}/budget-years`, budgetYearData);
};

export const useCreateBudgetYear = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeCreateBudgetYearModal =
    useFinanceSettingsStore.getState().closeCreateBudgetYearModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("budget-years-paginated");

      setNotification({
        type: "success",
        message: "Budget year created successfully!"
      });

      closeCreateBudgetYearModal();
    },
    ...config,
    mutationFn: createBudgetYear
  });
};
