import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const updatePartner = ({ partnerData, partnerId }) => {
  return axios.patch(`/partners/${partnerId}`, partnerData);
};

export const useUpdatePartner = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdatePartnerModal =
    useMembersStore.getState().closeUpdatePartnerModal;
  const setPartnerPreviewData =
    useMembersStore.getState().setPartnerPreviewData;

  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries("partners");
      setPartnerPreviewData(data.result);
      setNotification({
        type: "success",
        message: "Partner updated successfully!"
      });
      closeUpdatePartnerModal();
    },
    ...config,
    mutationFn: updatePartner
  });
};
