import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  useCreateChartOfAccountNames,
  useFinanceSettingsStore
} from "features/finances";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BaseModal } from "./BaseModal";

const SmallText = styled.span`
  color: #8e8e8e;
  font-size: 10px;
  font-weight: 500;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Textarea = styled.textarea`
  resize: none;
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  outline: none;
  padding: 8px;
  font-family: inherit;
`;

const chartOfAccountSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required")
});

export function CreateChartOfAccountNameModal() {
  const createChartOfAccountNameOpen = useFinanceSettingsStore(
    state => state.createChartOfAccountNameOpen
  );
  const closeCreateChartOfAccountNameModal = useFinanceSettingsStore(
    state => state.closeCreateChartOfAccountNameModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const createChartOfAccountMutation = useCreateChartOfAccountNames();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      description: ""
    },
    resolver: yupResolver(chartOfAccountSchema)
  });

  const onSubmit = data => {
    createChartOfAccountMutation.mutate(
      {
        churchId: selectedChurch?.id,
        chartOfAccountData: data
      },
      {
        onSuccess: () => {
          reset();
        }
      }
    );
  };

  return (
    <BaseModal
      title="Create chart of account names"
      isOpen={createChartOfAccountNameOpen}
      onClose={closeCreateChartOfAccountNameModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            flavor="white"
            size="small"
            onClick={closeCreateChartOfAccountNameModal}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="name">Name</Label>
        <Input id="name" {...register("name")} />
      </Field>
      <Field>
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          rows={10}
          {...register("description")}
          maxLength={50}
        />
      </Field>
      <SmallText>No more than 50 characters</SmallText>
    </BaseModal>
  );
}
