import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useTeamsStore } from "../stores";

export const updateTeam = ({ churchId, teamData, teamId }) => {
  return axios.patch(`/churches/${churchId}/teams/${teamId}`, teamData);
};

export const useUpdateTeam = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateTeamModal = useTeamsStore.getState().closeUpdateTeamModal;

  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries(["team", `${data.result.id}`]);
      setNotification({
        type: "success",
        message: "Team updated successfully!"
      });
      closeUpdateTeamModal();
    },
    ...config,
    mutationFn: updateTeam
  });
};
