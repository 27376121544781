import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getPublicCountries = () => {
  return axios.get(`/public/addresses/countries`);
};

export const usePublicCountries = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["public-countries"],
    queryFn: getPublicCountries,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always"
  });
};
