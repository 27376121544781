import styled from "@emotion/styled";
import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Popover, Stack, Typography } from "@mui/material";
import { PencilSimpleLine, Trash } from "@phosphor-icons/react";
import TeamPattern from "assets/images/team-pattern.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTeamsStore } from "../stores";

const Container = styled.div`
  height: 246px;
  max-width: 100%;
  position: relative;
  background: url(${TeamPattern});
  border-radius: 20px;
  overflow: hidden;
  display: grid;
  place-items: center;
`;

const TeamName = styled.h1`
  color: white;
  font-size: 64px;
  z-index: 2;
`;

const PhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(61, 173, 183, 0.8);
  z-index: 1;
`;

export const TeamPhoto = ({ name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const bannerColor = "#3DADB7";

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container color={bannerColor}>
      <IconButton
        aria-label="settings"
        sx={{
          position: "absolute",
          top: 6,
          right: 4,
          color: "white",
          zIndex: 2
        }}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <PhotoOverlay />
      <TeamName>{name}</TeamName>
      <Popover
        anchorEl={anchorEl}
        id="photo-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 2
        }}
      >
        <TeamMenu />
      </Popover>
    </Container>
  );
};

const TeamMenu = () => {
  const openDeleteTeamModal = useTeamsStore(state => state.openDeleteTeamModal);
  const openUpdateTeamModal = useTeamsStore(state => state.openUpdateTeamModal);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "200px",
        overflow: "hidden",
        borderRadius: "5px"
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        Team Menu
      </Box>
      <Stack
        sx={{
          minHeight: "120px",
          backgroundColor: "white",
          p: 2
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            py: 1,
            px: 2,
            cursor: "pointer",
            borderBottom: "1px solid #D6D6D6",
            "&:hover": {
              backgroundColor: "#EEEEEE"
            }
          }}
          onClick={openUpdateTeamModal}
        >
          <PencilSimpleLine size={16} />
          <Typography variant="caption">Edit team profile</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            py: 1,
            px: 2,
            cursor: "pointer",
            color: "#F44949",
            "&:hover": {
              backgroundColor: "#FCD1D1"
            }
          }}
          onClick={openDeleteTeamModal}
        >
          <Trash size={16} />
          <Typography variant="caption">
            {t("teams.teamProfile.DeleteATeam")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
