export const tabMapping = {
  0: "members",
  1: "pending-members",
  2: "inactive-members",
  3: "kids",
  4: "partners"
};

export const TABLES = {
  MEMBERS: "members",
  KIDS: "kids",
  PARTNERS: "partners"
};

export const TAB_NAMES = {
  MEMBERS: "members",
  APPLICATION_REQUESTS: "applicationRequests",
  INACTIVE_MEMBERS: "inactiveMembers",
  KIDS: "kids",
  PARTNERS: "partners"
};

export const TAB_TO_TABLE_NAMES = {
  [TAB_NAMES.MEMBERS]: "members",
  [TAB_NAMES.APPLICATION_REQUESTS]: "members",
  [TAB_NAMES.INACTIVE_MEMBERS]: "members",
  [TAB_NAMES.KIDS]: "kids",
  [TAB_NAMES.PARTNERS]: "partners"
};

export const IMPORT_BUTTON_NAMES = {
  [TAB_NAMES.MEMBERS]: "members.members.importMember",
  [TAB_NAMES.APPLICATION_REQUESTS]: "members.members.importMember",
  [TAB_NAMES.INACTIVE_MEMBERS]: "members.members.importMember",
  [TAB_NAMES.KIDS]: "members.kids.importKid",
  [TAB_NAMES.PARTNERS]: "members.partners.importPartner"
};

export const EXPORT_BUTTON_NAMES = {
  [TAB_NAMES.MEMBERS]: "members.members.exportMember",
  [TAB_NAMES.APPLICATION_REQUESTS]: "members.members.exportMember",
  [TAB_NAMES.INACTIVE_MEMBERS]: "members.members.exportMember",
  [TAB_NAMES.KIDS]: "members.kids.exportKid",
  [TAB_NAMES.PARTNERS]: "members.partners.exportPartner"
};

export const SEARCH_PLACEHOLDERS = {
  [TAB_NAMES.MEMBERS]: "members.membersHeader.members",
  [TAB_NAMES.APPLICATION_REQUESTS]: "members.membersHeader.members",
  [TAB_NAMES.INACTIVE_MEMBERS]: "members.membersHeader.members",
  [TAB_NAMES.KIDS]: "members.membersHeader.kids",
  [TAB_NAMES.PARTNERS]: "members.membersHeader.partners"
};

export const MEMBER_STATES = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "pending",
  REJECT: "reject"
};

export const FILTER_OPERATIONS = {
  EQUAL: "eq",
  NOT_EQUAL: "ne",
  GREATER_THAN: "gt",
  LESS_THAN: "lt",
  IN: "in",
  LIKE: "like",
  GREATER_THAN_OR_EQUAL: "gte",
  LESS_THAN_OR_EQUAL: "lte",
  BETWEEN: "between",
  IS_NULL: "is_null",
  IS_NOT_NULL: "is_not_null"
};

export const ADDRESSES = {
  ADDIS_ABABA: {
    REGION_ID: "1",
    ZONE_ID: "1",
    CITY_ID: "1"
  },
  DIRE_DAWA: {
    REGION_ID: "10",
    ZONE_ID: "10",
    CITY_ID: "10"
  }
};

export const RELIGIONS = {
  en: [
    "Atheist",
    "Orthodox",
    "Catholic",
    "Adventist",
    "Muslim",
    "Other traditional beliefs"
  ],
  am: ["ኤቲስት", "ኦርቶዶክስ", "ካቶሊክ", "አድቬንቲስት", "ሙስሊም", "ሌሎች ባህላዊ እምነቶች"]
};
