import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

const filterString = filters => {
  return filters.join("&");
};

export const sendMessages = ({ churchId, filters }) => {
  return axios.get(
    `/churches/${churchId}/send-messages?${filterString(filters)}`
  );
};

export const useSendMessages = ({ churchId, filters, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["send-messages", ...filters],
    queryFn: () => sendMessages({ churchId, filters })
  });
};
