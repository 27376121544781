import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const deleteRole = ({ churchId, roleId }) => {
  return axios.delete(`/churches/${churchId}/roles/${roleId}`);
};

export const useDeleteRole = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeDeleteModal = useCentralStore.getState().closeDeleteModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
      queryClient.invalidateQueries(["users"]);

      setNotification({
        type: "success",
        message: "Role deleted successfully!"
      });

      closeDeleteModal();
    },
    ...config,
    mutationFn: deleteRole
  });
};
