import styled from "@emotion/styled";
import { Group, Groups, PersonOff } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { Alert, Button, Notification } from "components/UI";
import { useChurchStore } from "features/churches";
import { StatInfoCard } from "features/dashboard";
import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { useCentralStore } from "stores";
import {
  useKidsPaginated,
  useMembersPaginated,
  usePartnersPaginated
} from "../api";
import {
  AddPartnerModal,
  AddTeamModal,
  ApplicationFormModal,
  ApplicationRequestsTable,
  ColumnsVisibilityModal,
  EmptyMembers,
  ExportModal,
  ImportModal,
  InactiveMembersTable,
  KidsTable,
  MemberPreviewCard,
  MembersTable,
  MembersTableHeader,
  PartnerPreviewCard,
  PartnersTable,
  UpdatePartnerModal
} from "../components";
import {
  EXPORT_BUTTON_NAMES,
  FILTER_OPERATIONS,
  IMPORT_BUTTON_NAMES,
  MEMBER_STATES,
  TAB_NAMES
} from "../constants";
import { useMembersStore } from "../stores";

const Wrapper = styled.div`
  padding-top: 32px;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-block: 1rem 2rem;
`;

export function Members() {
  const setHeaderTitle = useCentralStore(state => state.setHeaderTitle);
  const memberPreviewOpen = useMembersStore(state => state.memberPreviewOpen);
  const partnerPreviewOpen = useMembersStore(state => state.partnerPreviewOpen);
  const openAddPartnerModal = useMembersStore(
    state => state.openAddPartnerModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const openExportModal = useMembersStore(state => state.openExportModal);
  const openImportModal = useMembersStore(state => state.openImportModal);
  const openApplicationFormModal = useMembersStore(
    state => state.openApplicationFormModal
  );
  const currentTab = useMembersStore(state => state.currentTab);

  useTitle("Members");

  const membersPaginatedQuery = useMembersPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10,
    queries: {
      filter: {
        status: [
          {
            operator: FILTER_OPERATIONS.EQUAL,
            value: MEMBER_STATES.ACTIVE
          }
        ]
      }
    }
  });

  const applicationMembersPaginatedQuery = useMembersPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10,
    queries: {
      filter: {
        status: [
          {
            operator: FILTER_OPERATIONS.NOT_EQUAL,
            value: MEMBER_STATES.ACTIVE
          },
          {
            operator: FILTER_OPERATIONS.NOT_EQUAL,
            value: MEMBER_STATES.INACTIVE
          }
        ]
      }
    }
  });

  const inactiveMembersPaginatedQuery = useMembersPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10,
    queries: {
      filter: {
        status: [
          {
            operator: FILTER_OPERATIONS.EQUAL,
            value: MEMBER_STATES.INACTIVE
          }
        ]
      }
    }
  });

  const kidsPaginatedQuery = useKidsPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10
  });

  const partnersPaginatedQuery = usePartnersPaginated({
    churchId: selectedChurch?.id,
    page: 1,
    pageSize: 10
  });

  useEffect(() => {
    setHeaderTitle(t("sidebar.members"));

    return () => setHeaderTitle("");
  }, [i18next.language]);

  if (
    membersPaginatedQuery?.data?.result?.total === 0 &&
    applicationMembersPaginatedQuery?.data?.result?.total === 0 &&
    inactiveMembersPaginatedQuery?.data?.result?.total === 0 &&
    partnersPaginatedQuery?.data?.result?.total === 0 &&
    kidsPaginatedQuery?.data?.result?.total === 0
  ) {
    return <EmptyMembers />;
  }

  return (
    <Wrapper>
      <Alert message={"dashboard.common.demoMessage"} />
      <Box>
        <AddPartnerModal />
        <UpdatePartnerModal />
        <Notification />
        <AddTeamModal />
        <ExportModal />
        <ImportModal />
        <ColumnsVisibilityModal />
        <ApplicationFormModal />
        {currentTab !== TAB_NAMES.PARTNERS && (
          <StatGrid>
            <StatInfoCard
              title={t("members.card.TotalMembers")}
              subtitle={t("members.card.TotalMembersDescription")}
              stat={membersPaginatedQuery?.data?.result?.total || 0}
              icon={<Group />}
              color="#134077"
              background="#13407766"
              clickable={false}
            />
            <StatInfoCard
              title={t("members.card.TotalApplicationRequests")}
              subtitle={t("members.card.TotalApplicationRequestsDescription")}
              stat={applicationMembersPaginatedQuery?.data?.result?.total || 0}
              icon={<Groups />}
              color="#018ABE"
              background="#018ABE66"
              clickable={false}
            />
            <StatInfoCard
              title={t("members.card.TotalInactiveMembers")}
              subtitle={t("members.card.TotalInactiveMembersDescription")}
              stat={inactiveMembersPaginatedQuery?.data?.result?.total || 0}
              icon={<PersonOff />}
              color="#65A6A0"
              background="#65A6A066"
              clickable={false}
            />
          </StatGrid>
        )}
        {currentTab === TAB_NAMES.PARTNERS && (
          <StatGrid>
            <StatInfoCard
              title={t("members.card.TotalPartners")}
              stat={partnersPaginatedQuery?.data?.result?.total || 0}
              icon={<PersonOff />}
              color="#3CADB7"
              background="#65A6A066"
            />
          </StatGrid>
        )}
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            alignSelf="flex-end"
            spacing={1}
          >
            {currentTab === TAB_NAMES.MEMBERS && (
              <Button flavor="primary" onClick={() => navigate("/members/add")}>
                {t("members.members.addMember")}
              </Button>
            )}
            {currentTab === TAB_NAMES.APPLICATION_REQUESTS && (
              <Button flavor="primary" onClick={openApplicationFormModal}>
                {t("members.members.applicationForm")}
              </Button>
            )}
            {currentTab === TAB_NAMES.KIDS && (
              <Button flavor="primary" onClick={() => navigate("/kids/add")}>
                {t("members.kids.addKid")}
              </Button>
            )}
            {currentTab === TAB_NAMES.PARTNERS && (
              <Button flavor="primary" onClick={openAddPartnerModal}>
                {t("members.partners.AddPartner")}
              </Button>
            )}
            <Button flavor="outlined" onClick={openImportModal}>
              {t(IMPORT_BUTTON_NAMES[currentTab])}
            </Button>
            <Button flavor="outlined" onClick={openExportModal}>
              {t(EXPORT_BUTTON_NAMES[currentTab])}
            </Button>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Box flex={1}>
              <MembersTableHeader />
              {currentTab === TAB_NAMES.MEMBERS && <MembersTable />}
              {currentTab === TAB_NAMES.APPLICATION_REQUESTS && (
                <ApplicationRequestsTable />
              )}
              {currentTab === TAB_NAMES.INACTIVE_MEMBERS && (
                <InactiveMembersTable />
              )}
              {currentTab === TAB_NAMES.KIDS && <KidsTable />}
              {currentTab === TAB_NAMES.PARTNERS && <PartnersTable />}
            </Box>
            {memberPreviewOpen && <MemberPreviewCard />}
            {partnerPreviewOpen && <PartnerPreviewCard />}
          </Stack>
        </Stack>
      </Box>
    </Wrapper>
  );
}
