import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const createPartner = ({ churchId, partnerData }) => {
  return axios.post(`/churches/${churchId}/partners`, partnerData);
};

export const useCreatePartner = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeAddPartnerModal = useMembersStore.getState().closeAddPartnerModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("partners");
      setNotification({
        type: "success",
        message: "Partner created successfully!"
      });
      closeAddPartnerModal();
    },
    ...config,
    mutationFn: createPartner
  });
};
