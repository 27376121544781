import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getUsers = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/users`);
};

export const useUsers = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["users", churchId],
    queryFn: () => getUsers({ churchId })
  });
};
