import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Button } from "components/UI";
import { useBankStore } from "features/finances";
import { BaseModal } from "./BaseModal";

const SmallText = styled.span`
  color: #8e8e8e;
  font-size: 10px;
  font-weight: 500;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Label = styled.label`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  height: 40px;
  outline: none;
  padding: 8px;
`;

const Textarea = styled.textarea`
  resize: none;
  border-radius: 4px;
  border: 0.73px solid #b3b3b3;
  outline: none;
  padding: 8px;
`;

export function CreateBankAccountModal() {
  const createBankAccountOpen = useBankStore(
    state => state.createBankAccountOpen
  );
  const closeCreateBankAccountModal = useBankStore(
    state => state.closeCreateBankAccountModal
  );

  return (
    <BaseModal
      title="Create bank account"
      isOpen={createBankAccountOpen}
      onClose={closeCreateBankAccountModal}
      footer={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button flavor="white" size="small">
            Cancel
          </Button>
          <Button size="small">Create bank account</Button>
        </Stack>
      }
    >
      <Field>
        <Label htmlFor="fullName">Bank Name</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="fullName">Bank Link</Label>
        <Input id="fullName" />
      </Field>
      <Field>
        <Label htmlFor="description">Description</Label>
        <Textarea id="description" rows={10} />
      </Field>
      <SmallText>No more than 50 characters</SmallText>
    </BaseModal>
  );
}
