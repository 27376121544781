import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const mergeChildren = ({ churchId, memberId, spouseId }) => {
  return axios.post(
    `/churches/${churchId}/members/${memberId}/merge-child/${spouseId}`,
    JSON.stringify({})
  );
};

export const useMergeChildren = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("members");
      queryClient.invalidateQueries("member");
      setNotification({
        type: "success",
        message: "Children merged successfully!"
      });
    },
    ...config,
    mutationFn: mergeChildren
  });
};
