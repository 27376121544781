import styled from "@emotion/styled";
import { InputAdornment, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  DotsThree,
  Funnel,
  GearSix,
  MagnifyingGlass
} from "@phosphor-icons/react";
import { Button, TextField } from "components/UI";
import { useChurchStore } from "features/churches";
import { useExpenseStore, useExpensesPaginated } from "features/finances";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

const PrimaryActions = styled.div`
  display: flex;
  gap: 8px;
`;

const SecondaryActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchIcon = styled(MagnifyingGlass)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const FilterIcon = styled(Funnel)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const RevenueSettingButton = styled.button`
  outline: none;
  border: none;
  background: none;
  color: #789;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-weight: 500;
`;

const MoreSettingButton = styled.button`
  outline: none;
  border: 1px solid #c0c8d0;
  background: none;
  cursor: pointer;
  width: 32px;
  border-radius: 2px;
`;

const Status = styled.div`
  border-radius: 2px;
  background: ${({ color }) => color};
  color: #ffffff;
  padding: 4px 8px;
  text-transform: capitalize;
  cursor: pointer;
`;

export const ExpenseTable = ({ onEdit }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const openRecordExpenseModal = useExpenseStore(
    state => state.openRecordExpenseModal
  );
  // const openUpdateExpenseModal = useExpenseStore(
  //   state => state.openUpdateExpenseModal
  // );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const expensesQuery = useExpensesPaginated({
    churchId: selectedChurch?.id,
    page,
    pageSize
  });

  const statusColors = {
    advanced: "#f4ca10",
    on_approval: "#2BBDC5",
    settled: "#50C50D"
  };

  const columns = [
    {
      field: "id",
      headerName: "Expense ID",
      flex: 1
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: ({ row }) => (
        <>{row.requisition_record?.amount || row.requisition?.amount}</>
      )
    },
    {
      field: "chequeNumber",
      headerName: "Cheque Number",
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.requisition_record?.check_number ||
            row.requisition?.cheque_number}
        </>
      )
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      renderCell: ({ row }) => (
        <>{row.requisition_record?.reason || row.requisition?.reason}</>
      )
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: ({ row }) => (
        <>{row.requisition_record?.team?.name || row.requisition?.team?.name}</>
      )
    },
    {
      field: "account",
      headerName: "Account",
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.requisition_record?.chart_of_account?.account_name?.name ||
            row.requisition?.chart_of_account?.account_name?.name}
        </>
      )
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: ({ row }) => (
        <>{moment(row.created_at).format("DD-MM-YYYY")}</>
      )
    },
    {
      field: "expense_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Status
            color={statusColors[row.expense_status.toLowerCase()]}
            onClick={e => {
              e.stopPropagation();
              if (row.expense_status.toLowerCase() === "advanced") {
                navigate(`/finances/temp/expense/${row.id}/settlement`);
              }
            }}
          >
            {row.expense_status}
          </Status>
        );
      }
    }
  ];

  return (
    <Container>
      <Stack alignItems="flex-start" spacing={4}>
        <Header>
          <Title>Expense Table</Title>
          <PrimaryActions>
            <RevenueSettingButton
              onClick={() => navigate("/finances/temp/expense/settings")}
            >
              <GearSix size={16} />
              <span>Expense Setting</span>
            </RevenueSettingButton>
            <Button onClick={openRecordExpenseModal}>Record Expense</Button>
            <MoreSettingButton>
              <DotsThree size={16} />
            </MoreSettingButton>
          </PrimaryActions>
        </Header>
        <SecondaryActions>
          <TextField
            neutral={1}
            placeholder="Search expense"
            register={() => {}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={16} />
                </InputAdornment>
              )
            }}
            autoComplete="off"
          />
          <FilterIcon size={16} />
        </SecondaryActions>
      </Stack>
      <DataGrid
        autoHeight
        rows={expensesQuery.data?.result?.data || []}
        rowCount={expensesQuery.data?.result?.total || 0}
        loading={expensesQuery.isLoading}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
        onRowClick={({ row }) => {
          onEdit(row);
          // openUpdateExpenseModal();
        }}
      />
    </Container>
  );
};
