import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";
import { useRevenueStore } from "../store";

export const updateRevenueType = ({
  churchId,
  revenueTypeData,
  revenueTypeId
}) => {
  return axios.patch(
    `/churches/${churchId}/revenue-types/${revenueTypeId}`,
    revenueTypeData
  );
};

export const useUpdateRevenueType = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const closeUpdateRevenueTypeModal =
    useRevenueStore.getState().closeUpdateRevenueTypeModal;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["revenue-types"]);
      setNotification({
        type: "success",
        message: "Revenue type updated successfully!"
      });
      closeUpdateRevenueTypeModal();
    },
    ...config,
    mutationFn: updateRevenueType
  });
};
