import { Search } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import { useTeamsStore } from "../stores";

export function TeamsTableHeader() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const currentTab = useTeamsStore(state => state.currentTab);
  const setCurrentTab = useTeamsStore(state => state.setCurrentTab);
  const setSearchQuery = useTeamsStore(state => state.setSearchQuery);

  useDebounce(
    () => {
      setDebouncedValue(searchTerm);
    },
    500,
    [searchTerm]
  );

  useEffect(() => {
    if (debouncedValue) {
      setSearchQuery(debouncedValue);
    } else {
      setSearchQuery("");
    }
  }, [debouncedValue]);

  return (
    <Box
      sx={{
        color: "white",
        backgroundColor: "primary.main",
        py: 1,
        px: 2,
        borderRadius: "5px"
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTab}
          onChange={(event, value) => {
            setSearchTerm("");
            setSearchQuery("");
            setCurrentTab(value);
          }}
          aria-label="basic tabs example"
          indicatorColor=""
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={t("teams.teamProfile.Members")}
            sx={{
              color: "white",
              "&.Mui-selected": {
                borderRadius: "5px",
                background: "white",
                color: "primary.main"
              }
            }}
            value="members"
          />
          <Tab
            label={t("teams.teamProfile.PreviousMember")}
            sx={{
              color: "white",
              "&.Mui-selected": {
                borderRadius: "5px",
                background: "white",
                color: "primary.main"
              }
            }}
            value="previousMembers"
          />
          <Tab
            label={t("teams.teamProfile.PotentialMember")}
            sx={{
              color: "white",
              "&.Mui-selected": {
                borderRadius: "5px",
                background: "white",
                color: "primary.main"
              }
            }}
            value="potentialMembers"
          />
        </Tabs>
        <Stack direction="row" spacing={4} alignItems="center">
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder={t("teams.teamProfile.SearchMember")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search color="primary" />
                </InputAdornment>
              ),
              sx: {
                px: 1,
                borderRadius: "50px",
                backgroundColor: "white",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  }
                }
              }
            }}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
