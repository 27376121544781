import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useCentralStore } from "stores";

export const resignTeamMember = ({
  churchId,
  teamId,
  memberId,
  memberData
}) => {
  return axios.patch(
    `/churches/${churchId}/teams/${teamId}/members/${memberId}/resign`,
    memberData
  );
};

export const useResignTeamMember = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("team_members");
      queryClient.invalidateQueries("previous_team_members");
      setNotification({
        type: "success",
        message: "Team member updated successfully!"
      });
    },
    ...config,
    mutationFn: resignTeamMember
  });
};
