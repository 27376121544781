import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormHelperText, Link, Stack, Typography } from "@mui/material";
import {
  Button,
  Checkbox,
  LanguageSelector,
  Notification,
  TextField
} from "components/UI";
import { useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "../api";
import { signInSchema } from "../validation";

export function SignIn() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: "",
      password: ""
    },
    resolver: yupResolver(signInSchema)
  });

  const onSubmit = async credentials => {
    setLoading(true);
    setErrorMessage("");

    try {
      const data = await signInWithEmailAndPassword(credentials);

      signIn({
        token: data.result?.token,
        expiresIn: credentials.rememberMe ? 1440 : 60, // 24 hours or 1 hour (in minutes)
        tokenType: "Bearer",
        authState: data.result?.user
      });

      setLoading(false);

      navigate("/churches");
    } catch (error) {
      setLoading(false);

      setErrorMessage(error.message || error.errors);
    }
  };

  return (
    <Box sx={{ my: "auto" }} px={{ xs: 4, md: 6, lg: 12, xl: 22 }}>
      <Notification />
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
        {t("auth.signIn.signIn")}
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 4 }}>
        {t("auth.signIn.welcome")}
      </Typography>
      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={t("auth.signIn.email")}
          name="email"
          error={!!errors.email?.message}
          helperText={t(errors.email?.message)}
          register={register}
        />
        <TextField
          label={t("auth.signIn.password")}
          name="password"
          error={!!errors.password?.message}
          helperText={t(errors.password?.message)}
          type="password"
          register={register}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3 }}
        >
          <Checkbox
            name="rememberMe"
            buttons={[
              {
                checkboxLabel: `${t("auth.signIn.rememberMe")}`,
                value: true,
                id: "remember"
              }
            ]}
            error={!!errors.rememberMe?.message}
            helperText={errors.rememberMe?.message}
            register={register}
          />
          <Link
            component={RouterLink}
            to="/auth/forgot-password"
            variant="body2"
            color="inherit"
            sx={{ textAlign: "right" }}
          >
            {t("auth.signIn.forgotPassword")}
          </Link>
        </Stack>
        <Button
          type="submit"
          size="large"
          fullWidth
          flavor="primary"
          disabled={loading}
        >
          {t("auth.signIn.signIn")}
        </Button>
        {Boolean(errorMessage) && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
      </Stack>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {t("auth.signIn.dontHaveAccount")}{" "}
        <Link component={RouterLink} to="/auth/create-account" color="inherit">
          {t("auth.signIn.createAccount")}
        </Link>
      </Typography>
      {/* <Divider sx={{ mt: 4, mb: 2 }}>{t("auth.signIn.or")}</Divider> */}
      {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <SocialButton icon={<FcGoogle />}>
          {t("auth.common.signInWithGoogle")}
        </SocialButton>
        <SocialButton icon={<BsFacebook color="#039BE5" />}>
          {t("auth.common.signInWithFacebook")}
        </SocialButton>
      </Stack> */}
      <Stack alignItems="center" mt={4}>
        <LanguageSelector />
      </Stack>
    </Box>
  );
}
