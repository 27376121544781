import { useMutation } from "@tanstack/react-query";
import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useMembersStore } from "../stores";

export const rejectMember = ({ churchId, memberId }) => {
  return axios.post(`/churches/${churchId}/members/${memberId}/reject`);
};

export const useRejectMember = ({ config } = {}) => {
  const setNotification = useCentralStore.getState().setNotification;
  const setFullMemberData = useMembersStore.getState().setFullMemberData;
  const navigate = useNavigate();

  return useMutation({
    onSuccess: data => {
      setFullMemberData(data.result);
      queryClient.invalidateQueries(["member", data.result.id]);
      queryClient.invalidateQueries(["members"]);
      setNotification({
        type: "success",
        message: "Member rejected successfully!"
      });
      navigate("/members");
    },
    ...config,
    mutationFn: rejectMember
  });
};
