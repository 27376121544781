import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEmployeesStore } from "../store";

export const EmployeeModalSteps = () => {
  const addEmployeeFormActiveStep = useEmployeesStore(
    state => state.addEmployeeFormActiveStep
  );

  const { t } = useTranslation();

  const steps = [
    { name: t("HR.AddEmployee.PersonalInfo"), step: 1 },
    { name: t("HR.AddEmployee.Address"), step: 2 },
    { name: t("HR.AddEmployee.Job"), step: 3 },
    { name: t("HR.AddEmployee.BankandTIN"), step: 4 },
    { name: t("HR.AddEmployee.Compensations"), step: 5 },
    { name: t("HR.AddEmployee.EmergencyContact"), step: 6 }
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {steps.map(step => (
        <Stack
          key={step.name}
          alignItems="center"
          justifyContent="center"
          sx={{
            fontWeight: addEmployeeFormActiveStep === step.step ? 700 : 400,
            bgcolor:
              addEmployeeFormActiveStep === step.step
                ? "primary.main"
                : "neutral.main",
            color:
              addEmployeeFormActiveStep === step.step
                ? "primary.contrastText"
                : "neutral.contrastText",
            py: 1,
            px: 4,
            borderRadius: 1,
            fontSize: "14px"
          }}
        >
          {step.name}
        </Stack>
      ))}
    </Stack>
  );
};
