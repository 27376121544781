import styled from "@emotion/styled";
import { Edit } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useChartOfAccounts, useFinanceSettingsStore } from "features/finances";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

const PrimaryActions = styled.div`
  display: flex;
  gap: 8px;
`;

export const ChartOfAccountTable = ({ onEdit }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const openCreateChartOfAccountModal = useFinanceSettingsStore(
    state => state.openCreateChartOfAccountModal
  );
  const openUpdateChartOfAccountModal = useFinanceSettingsStore(
    state => state.openUpdateChartOfAccountModal
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const chartOfAccountsQuery = useChartOfAccounts({
    churchId: selectedChurch?.id
  });

  console.log(page);

  const columns = [
    {
      field: "account_number",
      headerName: "Account Number",
      flex: 1
    },
    {
      field: "account_name",
      headerName: "Account Name",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.account_name}</>;
      }
    },
    {
      field: "account_type",
      headerName: "Account Type",
      flex: 1
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1
    },
    {
      field: "beginning_balance",
      headerName: "Balance",
      flex: 1
    },
    {
      field: "account_status",
      headerName: "Status",
      flex: 1
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row">
            <IconButton
              onClick={() => {
                onEdit(row);
                openUpdateChartOfAccountModal();
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      }
    }
  ];

  return (
    <Container>
      <Stack alignItems="flex-start" spacing={4}>
        <Header>
          <Title>Chart Of Account List</Title>
          <PrimaryActions>
            <Button onClick={openCreateChartOfAccountModal}>New Account</Button>
          </PrimaryActions>
        </Header>
      </Stack>
      <DataGrid
        autoHeight
        rows={chartOfAccountsQuery.data?.result || []}
        rowCount={0}
        loading={false}
        columns={columns}
        pagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        density="comfortable"
        getRowId={row => row.id}
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
    </Container>
  );
};
