import { Error } from "components/Error";
import { useIsAuthenticated } from "react-auth-kit";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useRoutes } from "react-router-dom";
import { commonRoutes } from "./common";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

const AppRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const isLoggedIn = isAuthenticated();
  // const selectedChurch = useChurchStore(state => state.selectedChurch);
  // const { pathname } = useLocation();

  const redirectProtectedRoutes = [
    {
      path: "*",
      element: <Navigate to="/" />
    }
  ];

  const redirectPublicRoutes = [
    {
      path: "*",
      element: <Navigate to="/auth/sign-in" />
    }
  ];

  const routes = isLoggedIn
    ? [...commonRoutes, ...protectedRoutes, ...redirectProtectedRoutes]
    : [...commonRoutes, ...publicRoutes, ...redirectPublicRoutes];

  const pages = useRoutes(routes);

  // TODO: revisit this logic later - temporarily commented out
  // Redirect if church isn't selected
  // if (isLoggedIn && !selectedChurch && pathname !== "/churches") {
  //   return <Navigate to="/churches" />;
  // }

  return <ErrorBoundary FallbackComponent={Error}>{pages}</ErrorBoundary>;
};

export default AppRoutes;
