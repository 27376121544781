import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Divider, Stack } from "@mui/material";
import { Button } from "components/UI";
import { useMembersStore } from "features/members";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import { academicStatusSchema } from "./schema";
import { AcademicStatusSection, JobStatusSection } from "./sections";

export function PublicAcademicStatusForm() {
  const { t } = useTranslation();
  const { prev, next } = useSteps();
  const memberData = useMembersStore(state => state.memberData);
  const setMemberData = useMembersStore(state => state.setMemberData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control
  } = useForm({
    defaultValues: {
      education_and_skills: memberData.education_and_skills
        ? memberData.education_and_skills
        : [
            {
              academic_status: "",
              professional_skill: ""
            }
          ],
      job_status: memberData.job_status ? memberData.job_status : "",
      custom_job_status: memberData.custom_job_status
        ? memberData.custom_job_status
        : "",
      profession: memberData.profession ? memberData.profession : "",
      work_place: memberData.work_place ? memberData.work_place : "",
      monthly_income: memberData.monthly_income ? memberData.monthly_income : ""
    },
    resolver: yupResolver(academicStatusSchema)
  });

  const onSubmit = data => {
    setMemberData(data);
    next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AcademicStatusSection
        register={register}
        errors={errors}
        control={control}
      />
      <Divider sx={{ my: 3 }} />
      <JobStatusSection register={register} errors={errors} watch={watch} />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 8 }}
      >
        <Button
          type="submit"
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={prev}
        >
          {t("form.common.back")}
        </Button>
        <Button type="submit" variant="contained" endIcon={<ArrowForward />}>
          {t("form.common.next")}
        </Button>
      </Stack>
    </form>
  );
}
