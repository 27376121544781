import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getTeamTypes = ({ churchId }) => {
  return axios.get(`/churches/${churchId}/team-types`);
};

export const useTeamTypes = ({ churchId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["team_types", churchId],
    queryFn: () => getTeamTypes({ churchId })
  });
};
