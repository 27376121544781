import styled from "@emotion/styled";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { exportService } from "../api";
import { TAB_NAMES } from "../constants";
import { useMembersStore } from "../stores";
import { excludeKidColumns } from "../utils/kidColumns";
import {
  excludeMemberColumns,
  isExceedingThreshold
} from "../utils/memberColumns";
import { excludePartnerColumns } from "../utils/partnerColumns";

const Dropdown = styled.select`
  border: none;
  outline: none;
  color: #00000;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
`;

const DropdownOption = styled.option`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Label = styled.span`
  color: #8e8e8e;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const Info = styled.span`
  color: #b3b3b3;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const ViewLink = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #00b0b9;
  margin-right: auto;
  cursor: pointer;
`;

const CancelButton = styled(Button)`
  color: #5b5b5b;
  background: none;
  font-weight: 500;

  &:hover {
    background: none;
  }
`;

const typeMapping = {
  [TAB_NAMES.MEMBERS]: "members",
  [TAB_NAMES.APPLICATION_REQUESTS]: "pending-members",
  [TAB_NAMES.INACTIVE_MEMBERS]: "inactive-members",
  [TAB_NAMES.KIDS]: "kids",
  [TAB_NAMES.PARTNERS]: "partners"
};

const tableNames = {
  [TAB_NAMES.MEMBERS]: "Members",
  [TAB_NAMES.APPLICATION_REQUESTS]: "Application Request",
  [TAB_NAMES.INACTIVE_MEMBERS]: "Inactive Members",
  [TAB_NAMES.KIDS]: "Kids",
  [TAB_NAMES.PARTNERS]: "Partners"
};

export function ExportModal() {
  const exportModalOpen = useMembersStore(state => state.exportModalOpen);
  const closeExportModal = useMembersStore(state => state.closeExportModal);
  const navigate = useNavigate();
  const [exportFormat, setExportFormat] = useState("excel");
  const [exportPageOrientation, setExportPageOrientation] =
    useState("portrait");
  const columnsVisible = useMembersStore(state => state.columnsVisible);
  const kidColumnsVisible = useMembersStore(state => state.kidColumnsVisible);
  const partnerColumnsVisible = useMembersStore(
    state => state.partnerColumnsVisible
  );
  const queries = useMembersStore(state => state.queries);
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const currentTab = useMembersStore(state => state.currentTab);

  const getSelectedColumns = () => {
    if (
      currentTab === TAB_NAMES.MEMBERS ||
      currentTab === TAB_NAMES.APPLICATION_REQUESTS ||
      currentTab === TAB_NAMES.INACTIVE_MEMBERS
    ) {
      return Object.keys(columnsVisible).filter(
        key => !excludeMemberColumns.includes(key) && columnsVisible[key]
      );
    }

    if (currentTab === TAB_NAMES.KIDS) {
      return Object.keys(kidColumnsVisible).filter(
        key => !excludeKidColumns.includes(key) && kidColumnsVisible[key]
      );
    }

    if (currentTab === TAB_NAMES.PARTNERS) {
      return Object.keys(partnerColumnsVisible).filter(
        key =>
          !excludePartnerColumns.includes(key) && partnerColumnsVisible[key]
      );
    }
  };

  const handleExportAndView = () => {
    closeExportModal();
    navigate(
      `/members/view?tab=${currentTab}&format=${exportFormat}&orientation=${exportPageOrientation}`
    );
  };

  const handleExport = () => {
    exportService({
      type: typeMapping[currentTab],
      churchId: selectedChurch?.id,
      format: exportFormat,
      orientation: exportPageOrientation,
      columns: getSelectedColumns(),
      queries: queries
    });
  };

  return (
    <Modal open={exportModalOpen} onClose={closeExportModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          borderRadius: 1,
          width: "330px"
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          }}
        >
          Export data
        </Box>
        <Stack
          sx={{
            px: 2,
            py: 3,
            backgroundColor: "white"
          }}
          spacing={4}
        >
          <Stack spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Label>Export format</Label>
              <Dropdown
                value={exportFormat}
                onChange={e => setExportFormat(e.target.value)}
              >
                <DropdownOption value="excel">Excel</DropdownOption>
                <DropdownOption value="pdf">PDF</DropdownOption>
                <DropdownOption value="csv">CSV</DropdownOption>
              </Dropdown>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Label>Selected table</Label>
              <Info>{tableNames[currentTab]}</Info>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Label>Selected row</Label>
              <Info>All</Info>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Label>Page format</Label>
              <Dropdown
                value={exportPageOrientation}
                onChange={e => setExportPageOrientation(e.target.value)}
              >
                <DropdownOption value="portrait">A4 Portrait</DropdownOption>
                <DropdownOption value="landscape">A4 Landscape</DropdownOption>
              </Dropdown>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            {exportFormat === "pdf" &&
              !isExceedingThreshold(columnsVisible) && (
                <ViewLink onClick={handleExportAndView}>View Data</ViewLink>
              )}
            <CancelButton flavor="neutral" onClick={closeExportModal}>
              Cancel
            </CancelButton>
            <Button
              flavor="primary"
              onClick={handleExport}
              disabled={
                exportFormat === "pdf" && isExceedingThreshold(columnsVisible)
              }
            >
              Export
            </Button>
          </Stack>
          {exportFormat === "pdf" && isExceedingThreshold(columnsVisible) && (
            <Typography variant="caption" color="error" textAlign="center">
              Please select only up to 7 columns
            </Typography>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
