import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getChurch = ({ churchId, isPublic }) => {
  if (isPublic) {
    return axios.get(`/church/${churchId}`);
  }

  return axios.get(`/churches/${churchId}`);
};

export const useChurch = ({ churchId, isPublic = false, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["church", churchId],
    queryFn: () => getChurch({ churchId, isPublic })
  });
};
