import { Avatar, Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "components/UI";
import { useTranslation } from "react-i18next";
import {
  BsCalendarPlusFill,
  BsPersonFillAdd,
  BsPersonFillDown
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ApplicationFormModal, ImportModal } from ".";
import { useMembersStore } from "../stores";

const Thumbnail = styled(Box)`
  background: #e6f7f8;
  border-radius: 20px;
  padding: 34px 22px;
  min-width: 246px;

  svg {
    color: #00b0b9;
    height: 32px;
    width: 32px;
  }
`;

const MainButton = styled(Button)`
  padding-inline: 30px;
`;

export function EmptyMembers() {
  const openImportModal = useMembersStore(state => state.openImportModal);
  const openApplicationFormModal = useMembersStore(
    state => state.openApplicationFormModal
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <ImportModal />
      <ApplicationFormModal />
      <Typography variant="subtitle1" mb="40px">
        {t("members.membersEmptyState.StartByRegistering")}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Thumbnail>
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <Avatar
              sx={{
                backgroundColor: "#C0EDEB",
                width: "80px",
                height: "80px"
              }}
            >
              <BsPersonFillAdd />
            </Avatar>
            <MainButton onClick={() => navigate("/members/add")}>
              {t("members.membersEmptyState.AddMember")}
            </MainButton>
          </Stack>
        </Thumbnail>
        <Thumbnail>
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <Avatar
              sx={{
                backgroundColor: "#C0EDEB",
                width: "80px",
                height: "80px"
              }}
            >
              <BsPersonFillDown />
            </Avatar>
            <MainButton onClick={openImportModal}>
              {t("members.membersEmptyState.ImportMember")}
            </MainButton>
          </Stack>
        </Thumbnail>
        <Thumbnail>
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <Avatar
              sx={{
                backgroundColor: "#C0EDEB",
                width: "80px",
                height: "80px"
              }}
            >
              <BsCalendarPlusFill />
            </Avatar>
            <MainButton onClick={openApplicationFormModal}>
              {t("members.membersEmptyState.ApplicationForm")}
            </MainButton>
          </Stack>
        </Thumbnail>
      </Stack>
    </Box>
  );
}
