import styled from "@emotion/styled";
import { LinearProgress, Stack, Typography } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  margin: "auto 0"
}));

export function MessageStatusCard() {
  return (
    <Stack
      sx={{ backgroundColor: "#82DBD8", borderRadius: "10px", px: 4, py: 6 }}
      spacing={2}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: 700, textTransform: "uppercase", color: "white" }}
      >
        Message Status
      </Typography>
      <Stack direction="row" alignItems="stretch" spacing={4} sx={{ px: 4 }}>
        <Stack
          sx={{
            px: 4,
            py: 2,
            backgroundColor: "white",
            color: "primary.main",
            borderRadius: "10px"
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            Message Status
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 700, textAlign: "right" }}>
            8960
          </Typography>
        </Stack>
        <Stack
          sx={{
            px: 4,
            py: 2,
            backgroundColor: "white",
            color: "primary.main",
            borderRadius: "10px"
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            Message Status
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 700, textAlign: "right" }}>
            8960
          </Typography>
        </Stack>
        <Stack
          sx={{
            px: 4,
            py: 2,
            backgroundColor: "white",
            color: "primary.main",
            borderRadius: "10px",
            flex: 1
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            Message Status
          </Typography>
          <BorderLinearProgress variant="determinate" value={50} />
        </Stack>
      </Stack>
    </Stack>
  );
}
