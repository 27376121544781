import { Box, Stack, Typography } from "@mui/material";
import { useChurchStore } from "features/churches";
import { useParams } from "react-router-dom";
import { useCentralStore } from "stores";
import { useResignTeamMember } from "../api";
import { useTeamsStore } from "../stores";

export function TeamMembersTableEditDropdownMenu({ memberId }) {
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);
  const resignMemberMutation = useResignTeamMember();
  const { id } = useParams();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const currentTab = useTeamsStore(state => state.currentTab);

  const resignMember = () => {
    resignMemberMutation.mutate({
      churchId: selectedChurch?.id,
      teamId: id,
      memberId: memberId,
      memberData: {
        is_resigned: 1
      }
    });
  };

  const reinstateMember = () => {
    resignMemberMutation.mutate({
      churchId: selectedChurch?.id,
      teamId: id,
      memberId: memberId,
      memberData: {
        is_resigned: 0
      }
    });
  };

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        }}
      >
        Edit member
      </Box>
      <Stack
        sx={{
          minWidth: "200px",
          backgroundColor: "white",
          p: 2
        }}
        justifyContent="space-between"
      >
        {currentTab === "members" && (
          <Typography
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid #D6D6D6",
              p: 1,
              "&:hover": {
                backgroundColor: "#EEEEEE"
              }
            }}
            onClick={resignMember}
          >
            Resign member
          </Typography>
        )}
        {currentTab === "previousMembers" && (
          <Typography
            sx={{
              cursor: "pointer",
              borderBottom: "1px solid #D6D6D6",
              p: 1,
              "&:hover": {
                backgroundColor: "#EEEEEE"
              }
            }}
            onClick={reinstateMember}
          >
            Reinstate member
          </Typography>
        )}
        <Typography
          sx={{
            cursor: "pointer",
            borderBottom: "1px solid #D6D6D6",
            p: 1,
            "&:hover": {
              backgroundColor: "#EEEEEE"
            }
          }}
          onClick={openDeleteModal}
        >
          Remove member
        </Typography>
      </Stack>
    </Box>
  );
}
