import { Delete, Edit } from "@mui/icons-material";
import { Avatar, AvatarGroup, IconButton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteModal } from "components/UI";
import { IMAGE_URL } from "config";
import { useChurchStore } from "features/churches";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "stores";
import { useDeleteRole, useRoles } from "../api";

export const UsersRoleTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedChurch = useChurchStore(state => state.selectedChurch);
  const [role, setRole] = useState(null);
  const deleteMutation = useDeleteRole();
  const openDeleteModal = useCentralStore(state => state.openDeleteModal);

  const { data } = useRoles({
    churchId: selectedChurch?.id
  });

  const columns = [
    {
      field: "name",
      headerName: t("systemAdmins.roles.RoleName"),
      flex: 1
    },
    {
      field: "users",
      headerName: t("systemAdmins.roles.Users"),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <AvatarGroup max={4}>
            {row?.users?.map(user => (
              <Avatar
                sx={{ border: "2px solid", borderColor: "primary.main" }}
                key={user?.id}
                src={`${IMAGE_URL}/${user?.image_url}`}
                alt={user?.first_name}
              />
            ))}
          </AvatarGroup>
        );
      }
    },
    {
      field: "description",
      headerName: t("systemAdmins.roles.RoleDescription"),
      flex: 2
    },
    {
      field: "operation",
      headerName: t("systemAdmins.roles.Operations"),
      sortable: false,
      renderCell: ({ row }) => {
        const publicRoles = ["OWNER", "ADMIN", "VIEWER"];

        if (publicRoles.includes(row.name)) {
          return null;
        }

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={() => {
                navigate(`/roles/${row.id}`);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setRole(row);
                openDeleteModal();
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        );
      },
      flex: 1
    }
  ];

  return (
    <>
      <DeleteModal
        name={role?.name}
        onDelete={() => {
          deleteMutation.mutate({
            churchId: selectedChurch?.id,
            roleId: role?.id
          });
        }}
      />
      <DataGrid
        autoHeight
        rows={data?.result || []}
        columns={columns}
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        disableSelectionOnClick
        disableColumnMenu
        density="comfortable"
        localeText={{
          noRowsLabel: t("teams.teamProfile.NoRows"),
          MuiTablePagination: {
            labelRowsPerPage: t("teams.teamProfile.RowsPerPage")
          }
        }}
      />
    </>
  );
};
