import { Button } from "@mui/material";

export function SocialButton({ icon, children }) {
  return (
    <Button
      size="small"
      fullWidth
      variant="contained"
      startIcon={icon}
      color="social"
      sx={{
        boxShadow: `2px 2px 4px rgba(0, 0, 0, 0.05)`,
        py: 1,
        px: 2
      }}
    >
      {children}
    </Button>
  );
}
