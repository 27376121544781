import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getMember = ({ churchId, memberId }) => {
  return axios.get(`/churches/${churchId}/members/${memberId}`);
};

export const useMember = ({ churchId, memberId, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["member", memberId],
    queryFn: () => getMember({ churchId, memberId })
  });
};
